import { request, saveAccessToken, saveRefreshToken } from '../request'

const refresh = ({
    refreshToken,
    oldAccessToken,
}: {
    refreshToken: string
    oldAccessToken: string
}) =>
    request<'POST /api/token/refresh'>('POST', `/api/token/refresh`, {
        body: { refreshToken, oldAccessToken },
    })
        .then(saveAccessToken('accessToken'))
        .then(saveRefreshToken('refreshToken'))

export default {
    refresh,
}
