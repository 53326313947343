import { useEffect, useState } from 'react'

import type { Verse } from '../verse'

export function useVerseLoaded(verse: Verse) {
    const [loaded, setLoaded] = useState(verse.loaded)

    useEffect(() => {
        if (loaded) return

        const onLoad = () => setLoaded(true)
        verse.once('load', onLoad)
        return () => {
            verse.off('load', onLoad)
        }
    }, [loaded, verse])

    return loaded
}
