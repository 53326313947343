import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import Events from '../../events'

import { Connect, Substate, SubstateConnector } from './StateConnector'

type AudioZones = Substate<'roomSubstates'>['audioZones']

const putAudioZoneInHash = (item: t.IAudioZone) => {
    rootStore.spatialHash.put(
        item.id,
        'audio-zone',
        item.x - item.width / 2,
        item.y - item.height / 2,
        item.x + item.width / 2,
        item.y + item.height / 2
    )
}

function isLocationRelevant(locationId: string) {
    return (
        locationId === rootStore.commons.avConnectedSpace ||
        locationId === rootStore.commons.viewingSpace
    )
}

function putOrRemoveAudioZone(item: t.IAudioZone) {
    if (isLocationRelevant(item.locationId)) {
        putAudioZoneInHash(item)
    } else {
        rootStore.spatialHash.delete(item.id)
    }
}

/** @group Connectors */
@Connect({ key: 'roomSubstates', substate: 'audioZones' })
export class AudioZoneConnector extends SubstateConnector<
    'roomSubstates',
    'audioZones'
> {
    onAttach(audioZones: AudioZones) {
        audioZones.onAdd = (item) => {
            putOrRemoveAudioZone(item)
            rootStore.audioZones.addAudioZone(item)

            item.onChange = () => {
                putOrRemoveAudioZone(item)
                rootStore.audioZones.updateAudioZone(item)

                this.sharedState.addAudioZone(item)

                if (isLocationRelevant(item.locationId))
                    this.throttledEmitter.emit(
                        Events.AudioZoneChanged,
                        item.id,
                        item
                    )
            }

            this.sharedState.addAudioZone(item)

            if (isLocationRelevant(item.locationId))
                this.events.emit(Events.AudioZoneAdded, item)
        }

        audioZones.onRemove = (audioZone) => {
            rootStore.spatialHash.delete(audioZone.id)
            rootStore.audioZones.removeAudioZone(audioZone.id)

            // TODO: Cancel throttled-emitter so it doesn't emit any pending Change ev
            this.sharedState.removeAudioZone(audioZone)

            if (isLocationRelevant(audioZone.locationId))
                this.events.emit(Events.AudioZoneRemoved, audioZone)
        }
    }

    onDetach(audioZones: AudioZones) {
        audioZones.onAdd = undefined
        audioZones.onRemove = undefined
    }

    onSwitchSpace(audioZones: AudioZones) {
        for (const audioZone of audioZones)
            if (isLocationRelevant(audioZone.locationId))
                putAudioZoneInHash(audioZone)
    }
}
