import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'
import { DeploymentState } from '@teamflow/types'

import Events from '../../events'

import { Connect, SubstateConnector, Substate } from './StateConnector'
import { putOrRemoveFromHash } from './helpers'

type Apps = Substate<'roomSubstates'>['apps']

const putAppInHash = (item: t.IApp) => {
    rootStore.spatialHash.put(
        item.id,
        'app',
        item.x,
        item.y,
        item.x + item.width,
        item.y + item.height
    )
}

/** @group Connectors */
@Connect({ key: 'roomSubstates', substate: 'apps' })
export class AppConnector extends SubstateConnector<'roomSubstates', 'apps'> {
    onAttach(apps: Apps) {
        // Apps can be left over from the previous session!
        if (
            rootStore.commons.deploymentState === DeploymentState.None &&
            !rootStore.commons.reconnecting
        ) {
            rootStore.app.reset()
        }

        apps.onAdd = (item) => {
            putOrRemoveFromHash(item, putAppInHash)
            const config = rootStore.appConfig.getConfig(item.type)
            const app = rootStore.app.addApp(item, config)

            item.onChange = (changes) => {
                putOrRemoveFromHash(item, putAppInHash)

                let didLocationIdChange = false

                changes.forEach((change) => {
                    const { field, value, previousValue } = change

                    if (
                        field === 'locationId' &&
                        typeof previousValue !== 'undefined'
                    )
                        didLocationIdChange = true

                    rootStore.app
                        .getAppById(item.id)
                        ?.updateRespectingLocalUpdate({
                            [field]: value,
                        })
                })

                if (
                    didLocationIdChange ||
                    app.locationId === rootStore.commons.viewingSpace
                ) {
                    this.throttledEmitter.emit(Events.AppChanged, item.id, item)
                    this.throttledEmitter.emit(Events.AppUpdated, item.id, item)
                }
            }

            const fireAppChangedUnthrottled = () => {
                if (item.locationId === rootStore.commons.viewingSpace) {
                    this.events.emit(Events.AppChanged, item.id, item)
                    this.events.emit(Events.AppUpdated, item.id, item)
                }
            }

            item._dataRaw.onAdd = (item, key) => {
                app.updateAppData({ [key]: JSON.parse(item) })
                fireAppChangedUnthrottled()
            }
            item._dataRaw.onChange = (item, key) => {
                app.updateAppData({ [key]: JSON.parse(item) })
                fireAppChangedUnthrottled()
            }
            item._dataRaw.onRemove = (_item, key) => {
                app.deleteAppData(key)
                fireAppChangedUnthrottled()
            }

            this.events.emit(Events.OnAppOpened, item)
            this.events.emit(Events.AppUpdated, item)
        }

        apps.onRemove = (item) => {
            rootStore.spatialHash.delete(item.id)
            rootStore.app.removeApp(item.id)
            // TODO: Cancel throttled-emitter so it doesn't emit any pending Change ev
            this.events.emit(Events.AppUpdated, item)
        }
    }

    onDetach(apps: Apps) {
        apps.onAdd = undefined
        apps.onRemove = undefined
    }

    onSwitchSpace(apps: Apps) {
        const currentSpace = rootStore.commons.viewingSpace

        // Populate apps
        for (const app of apps) {
            if (app.locationId === currentSpace) {
                putAppInHash(app)
                const config = rootStore.appConfig.getConfig(app.type)
                rootStore.app.addApp(app, config)
            }
        }
    }
}
