import { dequal } from 'dequal/lite' // this library is used internally by swr as the default comparison function
import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import useSWR, { mutate } from 'swr'

import { api } from '@teamflow/client-api'
import { getPublicApiUrl } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'
import { RecurringEvent } from '@teamflow/types'

function getTodayStart() {
    const date = new Date()

    date.setHours(0, 0, 0, 0)

    return date.getTime()
}

function getTodayEnd() {
    const date = new Date()

    date.setHours(23, 59, 59, 999)

    return date.getTime()
}

export type UseScheduleResult = [
    t.ICalendarEvent[] | undefined,
    {
        mutate: (events?: any) => void
        revalidate: () => Promise<any>
        loading: boolean
        tokenExpired: boolean
        isValidating: boolean
        authed: boolean
    }
]

export function useSchedule(
    shouldRevalidateOnFocus = false
): UseScheduleResult {
    const preferredCalendar = rootStore.users.globalUser?.preferredCalendar
    const URL =
        preferredCalendar == 'outlook'
            ? getPublicApiUrl('/api/user/calendar/outlookEvents')
            : getPublicApiUrl('/api/user/calendar/events')
    const getUrl = async (_url: string): Promise<Record<string, any>> => {
        let res
        if (preferredCalendar == 'outlook') {
            res = await api.user.calendar.outlookCalendarToday({
                beginUTCTime: getTodayStart(),
                endUTCTime: getTodayEnd(),
            })
        } else {
            res = await api.user.calendar.googleCalendarToday({
                beginUTCTime: getTodayStart(),
                endUTCTime: getTodayEnd(),
            })
        }
        if (res.data) {
            return res.data
        } else {
            return Promise.reject(res.error)
        }
    }

    const [authed, setAuthed] = useState(true)
    const shouldTriggerRevalidation = useRef(false)
    // Fetch rolling 12 hours behind and ahead of events
    const { data, isValidating } = useSWR(
        // warning: text wall incoming
        // Notice how we are using the main mutate from the swr import, instead of the mutate from the hook.
        // This is because we would not be able to call the mutate function returned from the hook itself
        // when authed is false, as the mutate from the hook is bound to the current key passed to the useSWR hook.
        // In our case, it would be null, and then calling mutate would not do anything.
        // To make this work, we have to use the mutate from the swr import and pass the URL key directly.
        // Also notice that we are using a state internal to the hook, as if we had a variable in the module scope, changes
        // to it would not cause the useSWR hook to run again, thus not showing any new data.
        // The only way to avoid this is by having a separate useState to control the authed value, and an useRef to control
        // when we are doing to revalidation, to avoid setting setAuthed back to false, as when we call setAuthed(true) we are going
        // to initially receive data from the swr cache, which in this case would contain the "no access token" message", and
        // if we did not had the ref.current check, we would set authed back to false in the useEffect right below.
        // This is somewhat related to: https://github.com/vercel/swr/issues/576
        //
        // TL;DR: do not call mutate from the useSWR hook if the key is not the one you expect it to be, and do not rely
        //        only on request data to set a state that changes the key itself, as if the data is from the cache, you
        //        there are huge chances you would just set the key back to what it was before, thus not causing an update.
        authed ? URL : null,
        getUrl,
        {
            // Revalidate for custom status sync
            revalidateOnFocus: shouldRevalidateOnFocus,
            // this should return true if both data is the same, false otherwise
            compare(currentData, newData) {
                if (
                    !currentData ||
                    !currentData.events ||
                    !newData ||
                    !newData.events
                )
                    return dequal(currentData, newData) // this is the default comparison function, see: https://github.com/vercel/swr/blob/214d959c0865ccae92419ebfc8dca3a6703cd603/src/utils/config.ts#L72

                const newEvents: t.ICalendarEvent[] = newData.events
                const currentEvents: t.ICalendarEvent[] = currentData.events

                if (newEvents.length !== currentEvents.length) {
                    return false
                }

                // the summary is included with the etag because changing it does not seems to change the etag
                const getKey = (evt: t.ICalendarEvent) =>
                    `${evt.etag}${evt.summary}`

                const eTagUpdatedMap = new Map<string, number>()

                for (const evt of newEvents) {
                    eTagUpdatedMap.set(getKey(evt), 1)
                }

                const hasDifferentEvents = currentEvents.some(
                    (evt) => !eTagUpdatedMap.has(getKey(evt))
                )

                return !hasDifferentEvents
            },
        }
    )

    const loading = !data && isValidating
    const events = data?.events

    const tokenExpired = data?.tokenExpired

    const message = data?.message

    useEffect(() => {
        if (shouldTriggerRevalidation.current && isValidating) {
            shouldTriggerRevalidation.current = false
        }
    }, [isValidating])

    useEffect(() => {
        if (
            !shouldTriggerRevalidation.current &&
            !loading &&
            message &&
            message.includes('No access token found')
        ) {
            setAuthed(false)
        }
    }, [message, loading])

    const mutateFn = useCallback(() => {
        // if we mutated, we need to retry
        shouldTriggerRevalidation.current = true
        setAuthed(true)
        void mutate(URL)
    }, [URL])

    useEffect(() => {
        if (events) {
            rootStore.users.updateSchedule(events)
        }
    }, [events])

    useEffect(() => {
        rootStore.users.setCalendarConnected(authed && !tokenExpired)
    }, [authed, tokenExpired])

    const result = useMemo(
        (): UseScheduleResult => [
            events,
            {
                mutate: mutateFn,
                revalidate: () => mutate(URL),
                loading,
                tokenExpired,
                isValidating,
                authed,
            },
        ],
        [URL, authed, events, isValidating, loading, mutateFn, tokenExpired]
    )

    return result
}

const getConvert = async (url: string): Promise<Record<string, any>> => {
    let res
    if (url.includes('outlookRecurringEvents')) {
        res = await api.user.calendar.outlookRecurringEvents()
    } else {
        res = await api.user.calendar.conversion.get()
    }
    if (res.data) {
        return res.data
    } else {
        return Promise.reject(res.error)
    }
}

export function useConvertableEvents(shouldFetch = true): [
    RecurringEvent[] | undefined | null,
    {
        loading: boolean
        mutate(): void
    }
] {
    const preferredCalendar = rootStore.users.globalUser?.preferredCalendar

    const URL =
        preferredCalendar === 'outlook'
            ? getPublicApiUrl('/api/user/calendar/outlookRecurringEvents')
            : getPublicApiUrl('/api/user/calendar/events')

    const { data } = useSWR(shouldFetch ? URL : null, getConvert)

    const loading = !data

    const mutateFn = useCallback(() => {
        void mutate(URL)
    }, [URL])

    return [
        data?.events,
        {
            loading,
            mutate: mutateFn,
        },
    ]
}
