import React from 'react'

import { Box, BoxProps, Center, Text, TextProps } from '@teamflow/design'

type Props = Omit<BoxProps, 'size'> &
    Pick<TextProps, 'size' | 'weight' | 'align'>

export default function HuddleHeading({
    size = 'textSize32',
    weight = '600',
    align,
    children,
    ...boxProps
}: React.PropsWithChildren<Props>): JSX.Element {
    return (
        <Box marginBottom="space32" paddingTop="space4" {...boxProps}>
            <Center>
                <Text
                    size={size}
                    weight={weight}
                    align={align}
                    selectable={false}
                    __cssOverrides={{
                        lineHeight: 'unset',
                    }}
                >
                    {children}
                </Text>
            </Center>
        </Box>
    )
}
