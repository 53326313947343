import * as Sentry from '@sentry/react'
import { debounce } from 'lodash'

import { api } from '@teamflow/client-api'
import { ID_REFRESH_TOKEN_NAME, ID_TOKEN_NAME, LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { ApiErrorCode, Product } from '@teamflow/types'

/**
 * Load all users from the /api/organization/users endpoint and populate the {@link UserStore}.
 */
export async function loadUserOrgs() {
    const { data: users, error } = await api.organization.users()
    if (users) {
        if (Array.isArray(users)) {
            rootStore.users.addUsers(users)
        }
    } else if (error) {
        LogManager.global.error(error.message || error.code)
    }
}

const LOAD_USERS_DEBOUNCE_WAIT = 2000
const LOAD_USERS_MAX_WAIT = 6000

let loadUsersDebounced: () => Promise<void> | undefined

/**
 * A debounced variation of {@link loadUserOrgs}.
 */
export async function loadUsers() {
    if (!loadUsersDebounced) {
        loadUsersDebounced = debounce(loadUserOrgs, LOAD_USERS_DEBOUNCE_WAIT, {
            leading: true,
            maxWait: LOAD_USERS_MAX_WAIT,
            trailing: true,
        })
    }
    return loadUsersDebounced()
}

/**
 * Fetch the local user from /api/user and populate the local user in {@link UserStore}.
 *
 * <table>
 * <thead>
 *   <th>Field</th>
 *   <th>Description</th>
 * </thead>
 * <tbody>
 *   <tr>
 *     <td>globalUser</td>
 *     <td>
 *         If the user is not present in a workspace,
 *         then the {@link UserStore#globalUser} will be set.
 *     </td>
 *   </tr>
 *   <tr>
 *     <td>localUser</td>
 *     <td>
 *         If the user has logged into a workspace, then the
 *         {@link UserStore#localUser} will be populated.
 *     </td>
 *   </tr>
 *   <tr>
 *     <td>localUserError</td>
 *     <td>
 *         If an authentication failure occurs, then it will be marked as
 *         an error and the local client will be logged out. The page will be torn down and
 *         an "access denied" page will be shown.
 *     </td>
 *   </tr>
 * </tbody>
 * </table>
 */
export async function loadLocalUser() {
    try {
        const { data, error } = await api.user.get()

        if (data?.userOrg?._id) {
            LogManager.tag({ user: data.userOrg._id })
            rootStore.users.updateLocalUser(data.userOrg)

            if (data?.user?._id) {
                LogManager.tag({ identity: data.user._id })
                rootStore.users.updateGlobalUser(data.user)
            }

            // kick users to eventsdot if they log in to an event org in appdot
            if (data.userOrg?.product === Product.Event) {
                const idToken = window.localStorage.getItem(ID_TOKEN_NAME)
                if (idToken && process.env.DEPLOY_ENV === 'production') {
                    window.location.replace(
                        `https://events.teamflowhq.com/redirect?idToken=${idToken}`
                    )
                }
            }
        } else if (data?.user?._id) {
            LogManager.tag({ identity: data.user._id })
            rootStore.users.updateGlobalUser(data.user)
        } else if (error?.code === ApiErrorCode.NotAuthenticated) {
            rootStore.users.setLocalUserError(error.message || 'Not logged in')
            window.localStorage.removeItem(ID_TOKEN_NAME)
            window.localStorage.removeItem(ID_REFRESH_TOKEN_NAME)
        } else {
            throw new Error(error?.message || 'Unknown Error')
        }
        rootStore.users.setHasRequestedLocalUser()
        return data
    } catch (error) {
        const message = (error as Error)?.message ?? 'Load local user failed'
        Sentry.captureException(message)
        LogManager.global.error(message)

        // Only set an error in loading the local user if we haven't loaded it already!
        //
        // This is because refreshUsers() calls loadLocalUser and it could timeout when coming back
        // from sleep.
        if (
            !rootStore.users.hasRequestedLocalUser ||
            !rootStore.users.localUser
        ) {
            rootStore.users.setLocalUserError(message)
            rootStore.users.setHasRequestedLocalUser()
        }

        return { message }
    }
}

/**
 * Fetch a specific user in the workspace and populate the {@link UserStore} with that user.
 *
 * @param id - The userOrg id for the user.
 */
export async function loadUserById(id: string) {
    const { data, error } = await api.organization.user({ id })
    if (data) {
        rootStore.users.addUser(data)
    } else {
        LogManager.global.error(
            `Error loading user ${id} ${error?.code || ''}: ${
                error?.message || ''
            }`
        )
    }
}

export async function refreshUsers() {
    await loadLocalUser()
    await loadUsers()
}
