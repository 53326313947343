import { LogManager, Tile } from '@teamflow/lib'
import * as t from '@teamflow/types'

const VERSE_DB = 'VerseBeta'
const VERSE_VERSION = 2

enum Stores {
    Tile = 'tile',
    Furniture = 'furniture',
}

export interface ITileBlockCache extends t.ITileBlock {
    lastTouched: number
}

export interface IFurnitureCache {
    orgId: string
    updatedAt: number
    locationId: string
    furniture: t.IFurnitureConfig[]
}

const logger = LogManager.createLogger('VerseDB', { critical: true })

/**
 * The frontend database used for caching data. It cannot be instantiated and you must use the global
 * singleton `verseDB` exported from @teamflow/bootstrap.
 *
 * `verseDB` can be null if IndexedDB is not supported and in server-side rendering.
 */
export class VerseDB {
    dbPromise: Promise<IDBDatabase>

    private constructor(
        dbPromise: Promise<IDBDatabase>,
        indexedDB: IDBFactory
    ) {
        // Kind of a hack but this will delete existing database and recreate if encountering an error
        // when opening to prevent potential issues if we ever have to roll back to an earlier DB version
        // indexedDB just throws an error if you try to open an older version and a new version exists already
        this.dbPromise = dbPromise.catch(() => {
            logger.warn({
                action: 'VerseDB@Open',
                message:
                    'Unable to open indexedDB database. Clearing DB and re-opening',
            })
            const deleteDBRequest = indexedDB.deleteDatabase(VERSE_DB)

            const newDBPromise: Promise<IDBDatabase> = new Promise(
                (resolve, reject) => {
                    deleteDBRequest.onsuccess = () => {
                        resolve(VerseDB.getOpenDBRequest(indexedDB))
                    }

                    deleteDBRequest.onerror = (err) => {
                        reject(err)
                    }
                }
            )

            return newDBPromise
        })
    }

    /**
     * Save furniture.
     * @param furnitureCache
     */
    async saveFurniture(furnitureCache: IFurnitureCache) {
        const db = await this.dbPromise
        const transaction = db.transaction([Stores.Furniture], 'readwrite')

        const furnitureStore = transaction.objectStore(Stores.Furniture)
        const furnitureRequest = furnitureStore.put(furnitureCache)

        return new Promise((res, rej) => {
            furnitureRequest.onsuccess = (event) => res(event)
            furnitureRequest.onerror = (error) => rej(error)
        })
    }

    /**
     * Load furniture for the given space.
     *
     * @param orgId
     * @param locationId
     */
    async loadFurniture(
        orgId: string,
        locationId: string
    ): Promise<IFurnitureCache | undefined> {
        const db = await this.dbPromise
        const transaction = db.transaction([Stores.Furniture], 'readonly')

        const furnitureStore = transaction.objectStore(Stores.Furniture)
        const furnitureRequest = furnitureStore.get([orgId, locationId])

        return new Promise((res, rej) => {
            furnitureRequest.onsuccess = () =>
                res(furnitureRequest.result as IFurnitureCache)
            furnitureRequest.onerror = (error) => rej(error)
        })
    }

    /**
     * Load floor tile data.
     */
    async loadFloorTileBlocks(
        orgId: string,
        spaceId: string,
        blockIds: number[]
    ) {
        const db = await this.dbPromise
        const transaction = db.transaction([Stores.Tile], 'readonly')

        const tileStore = transaction.objectStore(Stores.Tile)
        const tileBlockRequests = blockIds
            .map((tileBlockId) => tileStore.get([orgId, spaceId, tileBlockId]))
            .map(
                (request) =>
                    new Promise((resolve) => {
                        request.onerror = () => resolve(null)
                        request.onsuccess = () => resolve(request.result)
                    })
            )

        const blocks = (await Promise.all(
            tileBlockRequests
        )) as ITileBlockCache[]

        return blocks.filter((block) => !!block)
    }

    async saveFloorTileBlocks(
        orgId: string,
        spaceId: string,
        blocks: ITileBlockCache[]
    ): Promise<void> {
        const db = await this.dbPromise
        const transaction = db.transaction([Stores.Tile], 'readwrite')

        const tileStore = transaction.objectStore(Stores.Tile)
        const tileBlockRequests = blocks.map((block) =>
            tileStore.put({
                id: Tile.xy(block.x, block.y),
                orgId,
                spaceId,
                ...block,
            })
        )

        await Promise.all(tileBlockRequests)
    }

    /**
     * Removes all of the tile blocks from the local database.
     *
     * Returns the block ids that were in the database before being cleared
     */
    async removeAllFloorTileBlocks(spaceId: string): Promise<Array<number>> {
        const db = await this.dbPromise
        const transaction = db.transaction([Stores.Tile], 'readwrite')
        const tileStore = transaction.objectStore(Stores.Tile)

        return new Promise((resolve, reject) => {
            const cursorRequest = tileStore.openCursor()
            const removedIds: number[] = []
            cursorRequest.onsuccess = (event: any) => {
                const cursor = event.target.result
                if (cursor) {
                    const block = cursor.value as {
                        id: number
                        spaceId: string
                    }
                    if (block.spaceId === spaceId) {
                        removedIds.push(block.id)
                        cursor.delete()
                    }
                    cursor.continue()
                } else {
                    resolve(removedIds)
                }
            }
            cursorRequest.onerror = reject
        })
    }

    static open(indexedDB: IDBFactory): VerseDB {
        if (VerseDB.INSTANCE) return VerseDB.INSTANCE

        const dbOpenRequest = VerseDB.getOpenDBRequest(indexedDB)

        VerseDB.INSTANCE = new VerseDB(dbOpenRequest, indexedDB)

        return VerseDB.INSTANCE
    }

    private static getOpenDBRequest(
        indexedDB: IDBFactory
    ): Promise<IDBDatabase> {
        const dbOpenRequest = indexedDB.open(VERSE_DB, VERSE_VERSION)
        dbOpenRequest.onupgradeneeded = VerseDB.onUpgradeNeeded
        return new Promise((resolve, reject) => {
            dbOpenRequest.onsuccess = () => resolve(dbOpenRequest.result)
            dbOpenRequest.onerror = reject
        })
    }

    private static INSTANCE?: VerseDB | null = null

    private static onUpgradeNeeded(event: IDBVersionChangeEvent) {
        const db = (event.target as unknown as { result: IDBDatabase }).result

        if (!db.objectStoreNames.contains(Stores.Tile)) {
            db.createObjectStore(Stores.Tile, {
                keyPath: ['orgId', 'spaceId', 'id'],
            })
        }

        if (!db.objectStoreNames.contains(Stores.Furniture)) {
            db.createObjectStore(Stores.Furniture, {
                keyPath: ['orgId', 'locationId'],
            })
        }
    }
}

export const verseDB =
    typeof window !== 'undefined' && window.indexedDB
        ? VerseDB.open(window.indexedDB)
        : null
