import { createRequest } from '../request'

const confirm = createRequest<'POST /api/email/confirm'>(
    'POST',
    '/api/email/confirm'
)

const inviteToFloor = createRequest<'POST /api/email/inviteToFloor'>(
    'POST',
    '/api/email/inviteToFloor'
)

const inviteToOrg = createRequest<'POST /api/email/inviteToOrg'>(
    'POST',
    '/api/email/inviteToOrg'
)

const inviteToPrivateRoom =
    createRequest<'POST /api/email/inviteToPrivateRoom'>(
        'POST',
        '/api/email/inviteToPrivateRoom'
    )

const inviteToRoom = createRequest<'POST /api/email/inviteToRoom'>(
    'POST',
    '/api/email/inviteToRoom'
)

const resetPassword = createRequest<'POST /api/email/passwordReset'>(
    'POST',
    '/api/email/passwordReset'
)

const uninviteFromOrg = createRequest<'POST /api/email/uninviteFromOrg'>(
    'POST',
    '/api/email/uninviteFromOrg'
)

const inviteToProject = createRequest<'POST /api/email/inviteToProject'>(
    'POST',
    '/api/email/inviteToProject'
)

export default {
    confirm,
    inviteToFloor,
    inviteToOrg,
    inviteToPrivateRoom,
    inviteToRoom,
    resetPassword,
    uninviteFromOrg,
    inviteToProject,
}
