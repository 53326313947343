import * as opentelemetry from '@opentelemetry/api'
import {
    Attributes,
    Context,
    Span,
    SpanOptions,
    SpanStatusCode,
} from '@opentelemetry/api'
import {
    // sampling is disabled for now
    ParentBasedSampler,
    TraceIdRatioBasedSampler,
    W3CTraceContextPropagator,
} from '@opentelemetry/core'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load'
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch'
import { LongTaskInstrumentation } from '@opentelemetry/instrumentation-long-task'
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request'
import { Resource } from '@opentelemetry/resources'
import {
    ConsoleSpanExporter,
    SimpleSpanProcessor,
    BatchSpanProcessor,
} from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import {
    SemanticAttributes,
    SemanticResourceAttributes,
} from '@opentelemetry/semantic-conventions'
import { escapeRegExp } from 'lodash'

import rootStore from '@teamflow/store'

// https://opentelemetry.io/docs/instrumentation/js/getting-started/browser/
// https://opentelemetry.io/docs/instrumentation/js/instrumentation/

const DEFAULT_TRANSACTION_SAMPLE_RATIO =
    process.env.DEPLOY_ENV === 'local' ? 1 : 0.4
const ENV_TRANSACTION_SAMPLE_RATIO = parseInt(
    process.env
        .NEXT_PUBLIC_WEB_OPENTELEMETRY_TRANSACTION_SAMPLE_RATIO as string,
    10
)
const TRANSACTION_SAMPLE_RATIO =
    typeof ENV_TRANSACTION_SAMPLE_RATIO === 'number'
        ? ENV_TRANSACTION_SAMPLE_RATIO
        : DEFAULT_TRANSACTION_SAMPLE_RATIO

const serviceEnvMap = {
    production: 'PROD',
    reproduction: 'REPROD',
    staging: 'ST',
    qa: 'QA',
    development: 'DEV',
    // those two are just to make TS happy
    ephemeral: 'EPHEMERAL',
    local: 'LOCAL',
    test: 'TEST',
    'unit-test': 'UNIT TEST',
}

const provider = new WebTracerProvider({
    resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: `[${
            serviceEnvMap[process.env.DEPLOY_ENV] ?? '???'
        }] Huddle Frontend`,
        [SemanticResourceAttributes.SERVICE_VERSION]:
            process.env.NEXT_PUBLIC_COMMIT_SHA &&
            process.env.NEXT_PUBLIC_COMMIT_SHA.slice(0, 10),
    }),
    // sampling is disabled for now
    sampler: process.env.NEXT_PUBLIC_WEB_OPENTELEMETRY_FORCE_SAMPLING
        ? new ParentBasedSampler({
              root: new TraceIdRatioBasedSampler(TRANSACTION_SAMPLE_RATIO),
          })
        : undefined,
})

const metricsEnabledEnvironments = ['staging', 'development', 'qa']

// if you want to test this locally, add the
// NEXT_PUBLIC_WEB_OPENTELEMETRY_FORCE_TRACING=true
// env variable to your server/.env file
const isTracingEnabled =
    (metricsEnabledEnvironments.includes(process.env.DEPLOY_ENV) ||
        (process.env.DEPLOY_ENV === 'local' &&
            !!process.env.NEXT_PUBLIC_WEB_OPENTELEMETRY_FORCE_TRACING)) &&
    process.env.NODE_ENV !== 'test'

// By default when running locally it will use the ConsoleSpanExporter
// You can force it to use the OTLP exporter by adding this env variable:
// NEXT_PUBLIC_WEB_OPENTELEMETRY_FORCE_OTLP_EXPORTER=true
const isOTLPExporterEnabled =
    isTracingEnabled &&
    (process.env.DEPLOY_ENV !== 'local' ||
        !!process.env.NEXT_PUBLIC_WEB_OPENTELEMETRY_FORCE_OTLP_EXPORTER)

if (isOTLPExporterEnabled) {
    const headers = {} as Record<string, unknown>

    // this is only really needed if we are setting the exporter directly
    // to the newrelic collector - usually we don't want to do that, but
    // may be useful for testing
    if (process.env.NEXT_PUBLIC_WEB_OPENTELEMETRY_FORCE_OTLP_EXPORTER_API_KEY) {
        headers['x-api-key'] =
            process.env.NEXT_PUBLIC_WEB_OPENTELEMETRY_FORCE_OTLP_EXPORTER_API_KEY
    }

    const exporter = new OTLPTraceExporter({
        // https://docs.newrelic.com/docs/more-integrations/open-source-telemetry-integrations/opentelemetry/opentelemetry-setup/#direct-export
        url: process.env.NEXT_PUBLIC_WEB_OPENTELEMETRY_COLLECTOR_URL,
        headers,
        concurrencyLimit: 5,
    })
    provider.addSpanProcessor(
        process.env.DEPLOY_ENV === 'local' &&
            process.env
                .NEXT_PUBLIC_WEB_OPENTELEMETRY_FORCE_OTLP_EXPORTER_SIMPLE_PROCESSOR
            ? new SimpleSpanProcessor(exporter)
            : new BatchSpanProcessor(exporter, {
                  maxQueueSize: 2048,
                  maxExportBatchSize: 700,
                  scheduledDelayMillis: 5000,
                  exportTimeoutMillis: 20000,
              })
    )
}

if (isTracingEnabled && !isOTLPExporterEnabled) {
    provider.addSpanProcessor(
        new SimpleSpanProcessor(new ConsoleSpanExporter())
    )
}

const hasInstrumentation = typeof window !== 'undefined' && isTracingEnabled

// Registering instrumentations
if (hasInstrumentation) {
    provider.register({
        // The ZoneContextManager would allow to track async executions
        // But zone.js (the lib it uses internally) only works correctly
        // with ES2015, and we are transpiling to ES2017
        // contextManager: new ZoneContextManager(),
        propagator: new W3CTraceContextPropagator(),
    })

    const cors = [
        // allow API in environments other than prod
        process.env.DEPLOY_ENV !== 'production'
            ? new RegExp(
                  `${escapeRegExp(process.env.NEXT_PUBLIC_TEAMFLOW_API_URL)}.*`
              )
            : null,
    ].filter((f): f is Exclude<typeof f, null> => !!f)

    registerInstrumentations({
        instrumentations: hasInstrumentation
            ? [
                  new DocumentLoadInstrumentation(),
                  new LongTaskInstrumentation({
                      observerCallback: (span) => {
                          addCommonAttributesToSpan(span)
                      },
                      enabled: typeof window !== 'undefined',
                  }),
                  new FetchInstrumentation({
                      applyCustomAttributesOnSpan(span, _fetchEvent) {
                          addCommonAttributesToSpan(span)
                      },
                      propagateTraceHeaderCorsUrls: cors,
                      enabled: typeof window !== 'undefined',
                  }),
                  new XMLHttpRequestInstrumentation({
                      applyCustomAttributesOnSpan(span, _xhr) {
                          addCommonAttributesToSpan(span)
                      },
                      propagateTraceHeaderCorsUrls: cors,
                  }),
              ]
            : [],
    })
}

const addCommonAttributesToSpan = (span: Span) => {
    if (rootStore.users.localUser) {
        span.setAttribute('user.id', rootStore.users.localUser._id)
        span.setAttribute('org.id', rootStore.users.localUser.currentOrgId)
        span.setAttribute('org.slug', rootStore.users.localUser.currentOrgSlug)
    }
    span.setAttribute('location.pathname', window.location.pathname)
}

// This is what we'll access in all instrumentation code

export const tracing = {
    tracer: opentelemetry.trace.getTracer('tf-web-tracer'),

    getSpan(context: Context) {
        return opentelemetry.trace.getSpan(context)
    },

    createSpan(
        spanName: string,
        options: SpanOptions & {
            functionName?: string
            /**
             * The parent tracing context
             */
            tracingContext?: Context
        } = {}
    ) {
        const { tracingContext } = options

        const newSpan = this.tracer.startSpan(spanName, options, tracingContext)
        const parentContext = tracingContext ?? opentelemetry.context.active()
        const newContext = opentelemetry.trace.setSpan(parentContext, newSpan)

        addCommonAttributesToSpan(newSpan)

        return {
            span: newSpan,
            context: newContext,
        }
    },

    createFinishedSpan(
        spanName: string,
        startTime: number,
        endTime: number,
        spanAttributes: Attributes = {}
    ) {
        if (!isTracingEnabled) return

        const { span } = this.createSpan(spanName, {
            startTime,
            attributes: spanAttributes,
        })

        span.end(endTime)
    },

    /**
     *
     * @param spanName
     * @param fn
     * @param options
     * @returns
     */
    async runWithTracing<T>(
        spanName: string,
        fn: (context?: Context) => Promise<T>,
        options: SpanOptions & {
            functionName?: string
            /**
             * The parent tracing context
             */
            tracingContext?: Context
        } = {}
    ): Promise<T> {
        if (!isTracingEnabled) return fn()

        const attributes = options.attributes ?? (options.attributes = {})

        const fnName = fn.name || 'anonymous'

        attributes[SemanticAttributes.CODE_FUNCTION] =
            options?.functionName || fnName

        const { span: newSpan, context: newContext } = this.createSpan(
            spanName,
            options
        )

        try {
            const result = await fn(newContext)
            if (result) newSpan.setStatus({ code: SpanStatusCode.OK })
            return result
        } catch (error) {
            // TODO(do we really need this?)
            const isErrorObj =
                typeof error === 'object' && error instanceof Error
            if (isErrorObj) {
                newSpan.recordException(error)
            }
            newSpan.setStatus({
                code: SpanStatusCode.ERROR,
                message: isErrorObj ? error.message : 'unknown error',
            })
            throw error
        } finally {
            newSpan.end()
        }
    },
}

export { opentelemetry }

if (typeof window !== 'undefined') {
    ;(window as any).tf_tracing = {
        tracing,
        opentelemetry,
    }
}
