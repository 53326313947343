/**
 * @namespace Platform
 */

import { LogManager } from '@teamflow/lib'
import { KeyboardShortcut, PlatformShortcut } from '@teamflow/types'

/** @memberof Platform */
export const Windows = 'Windows'
/** @memberof Platform */
export const macOS = 'MacOS'
/** @memberof Platform */
export const Unix = 'UNIX'
/** @memberof Platform */
export const Linux = 'Linux'
/** @memberof Platform */
export const iOS = 'iOS'
/** @memberof Platform */
export const Android = 'Android'
/** @memberof Platform */
export const Unknown = 'unknown'

export type Platform =
    | 'Windows'
    | 'MacOS'
    | 'UNIX'
    | 'Linux'
    | 'iOS'
    | 'Android'
    | 'unknown'

let evaluatedPlatform: Platform | undefined

/**
 * @function Platform.current
 *
 * Evaluates the current user-agent platform using browser APIs and returns a memoized
 * value.
 */
export function current(): Platform {
    if (!evaluatedPlatform) {
        if (typeof navigator === 'undefined') {
            evaluatedPlatform = Unknown
        } else if (navigator.appVersion.indexOf('Win') != -1) {
            evaluatedPlatform = Windows
        } else if (/iPhone|iPod|iPad/.test(navigator.appVersion)) {
            evaluatedPlatform = iOS
        } else if (navigator.appVersion.indexOf('Android') != -1) {
            evaluatedPlatform = Android
        } else if (navigator.appVersion.indexOf('Mac') != -1) {
            if (
                navigator.platform.includes('MacIntel') &&
                navigator.maxTouchPoints > 2
            ) {
                // iPad
                evaluatedPlatform = iOS
            } else {
                evaluatedPlatform = macOS
            }
        } else if (navigator.appVersion.indexOf('X11') != -1) {
            evaluatedPlatform = Unix
        } else if (navigator.appVersion.indexOf('Linux') != -1) {
            evaluatedPlatform = Linux
        } else {
            evaluatedPlatform = Unknown
        }

        LogManager.tag({ platform: evaluatedPlatform })
    }

    return evaluatedPlatform
}

/**
 * @function Platform.is
 *
 * Evaluates if the current platform is one of the passed options.
 */
export function is(...values: Platform[]): boolean {
    return values.includes(current())
}

let inPhone: boolean | undefined = undefined
let inPhoneWeb: boolean | undefined = undefined

/**
 * @function Platform.isPhoneWebBrowser
 *
 * Memoized evaluation of whether the current platform is a mobile device,
 * but not on the Teamflow mobile app.
 */
export function isPhoneWebBrowser(): boolean {
    if (typeof inPhoneWeb !== 'undefined') {
        return inPhoneWeb
    }
    inPhoneWeb =
        typeof navigator !== 'undefined' &&
        /iPhone|iPod|Android/i.test(navigator?.userAgent)
    return inPhoneWeb
}

/**
 * @function Platform.isPhone
 *
 * Memoized evaluation of whether the current platform is a mobile device.
 */
export function isPhone(): boolean {
    if (typeof inPhone !== 'undefined') {
        return inPhone
    }
    inPhone = isPhoneWebBrowser() || isMobileApp()
    return inPhone
}

let inMobileApp: boolean | undefined = undefined

/**
 * @function Platform.isMobileApp
 *
 * Memoized evaluation of whether the current platform is on the mobile app.
 */
export function isMobileApp(): boolean {
    if (typeof inMobileApp !== 'undefined') {
        return inMobileApp
    }

    const isMobileAppUserAgent =
        typeof navigator !== 'undefined'
            ? /HuddletronMobile/i.test(navigator?.userAgent)
            : false

    inMobileApp =
        (typeof window !== 'undefined'
            ? Boolean(window.localStorage.getItem('inMobileWebView'))
            : false) || isMobileAppUserAgent

    return inMobileApp
}

/** @function Platform.isWindows */
export function isWindows(): boolean {
    return current() === 'Windows'
}

/** @function Platform.isChrome */
export function isChrome(): boolean {
    if (!window || !window.navigator || !window.navigator.userAgent) {
        return false
    }

    // userAgent regex taken from: https://github.com/lancedikson/bowser/blob/master/src/parser-browsers.js
    const match = window.navigator.userAgent.match(/chrome|crios|crmo/i)
    if (!match) {
        return false
    }
    return match.length > 0
}

/** @function Platform.isIphone */
export function isIphone(): boolean {
    return current() === iOS
}

/** @function Platform.isAndroid */
export function isAndroid(): boolean {
    return current() === Android
}

/** @function Platform.isMacOS */
export function isMacOS(): boolean {
    return current() === macOS
}

export function isTfBot(): boolean {
    if (!window || !window.navigator || !window.navigator.userAgent) {
        return false
    }
    return window.navigator.userAgent.indexOf('via TFBot') > -1
}

/**
 * @function Platform.tooltip
 *
 * This transforms a {@link KeyboardShortcut} shortcut to a platform-specific shortcut string to be
 * shown in a tooltip.
 *
 * @param shortcut - The enumerated keyboard shortcut.
 * @return The shortcut to use on the platform the user-agent is installed on.
 */
export function tooltip(shortcut: KeyboardShortcut): PlatformShortcut {
    let modKey = 'meta'
    let windowsKey = 'meta'
    let altKey = 'alt'
    const currentOS = current()

    if (currentOS === 'MacOS') {
        modKey = 'cmd'
        altKey = 'opt'
        windowsKey = 'ctrl'
    } else if (
        currentOS === 'Windows' ||
        currentOS === 'Linux' ||
        currentOS === 'UNIX'
    ) {
        modKey = 'ctrl'
    }

    return shortcut
        .replace(/ctrl|cmd|mod/gi, modKey)
        .replace(/meta/gi, windowsKey)
        .replace(/cmd/g, '⌘')
        .replace(/alt/gi, altKey) as PlatformShortcut
}

/** @function Platform.shortcut */
export function shortcut(shortcut: KeyboardShortcut): PlatformShortcut {
    let modKey = 'meta'
    let windowsKey = 'meta'
    const currentOS = current()

    if (currentOS === 'MacOS') {
        modKey = 'command'
        windowsKey = 'ctrl'
    } else if (
        currentOS === 'Windows' ||
        currentOS === 'Linux' ||
        currentOS === 'UNIX'
    ) {
        modKey = 'ctrl'
    }

    return shortcut
        .replace(/ctrl|cmd|mod/gi, modKey)
        .replace(/meta/gi, windowsKey)
        .replace(/\|/g, '+') as PlatformShortcut
}

export default current
