import * as t from '@teamflow/types'

/**
 * @group Commands
 */
export default class PortalDeleteCommand
    implements
        t.ICommand<{ id: string; locationId: string; contentPolicy: string }>
{
    readonly messageType = t.ClientMessage.PortalDelete

    private id: string
    private locationId: string
    private contentPolicy: string

    /**
     * @param id - The portal's ID
     * @param contentPolicy - What to do if there are apps in the rooms pointed at by the portal.
     */
    constructor(
        id: string,
        locationId: string,
        contentPolicy?: 'transferToFloor' | 'denyRequest' | 'delete'
    ) {
        this.id = id
        this.locationId = locationId
        this.contentPolicy = contentPolicy ?? 'denyRequest'
    }

    execute() {
        return {
            id: this.id,
            locationId: this.locationId,
            contentPolicy: this.contentPolicy,
        }
    }

    /* localExecute() cannot be added because the request to delete a portal
       may be denied. */
}
