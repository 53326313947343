import { request } from '../request'

const complete = (key: string, uploadId: string) =>
    request<'POST /api/meeting-recording/complete'>(
        'POST',
        '/api/meeting-recording/complete',
        {
            body: {
                key,
                uploadId,
            },
        }
    )

const heartbeat = (beatNumber: number, key: string) =>
    request<'PUT /api/meeting-recording/heartbeat/key/:key/beat/:beatNumber'>(
        'PUT',
        `/api/meeting-recording/heartbeat/key/${key}/beat/${beatNumber}`
    )

const init = (key: string, isOnlyAudio: boolean) =>
    request<'POST /api/meeting-recording/init'>(
        'POST',
        '/api/meeting-recording/init',
        {
            body: {
                key,
                isOnlyAudio,
            },
        }
    )

const upload = (
    part: FormData,
    key: string,
    partNumber: number,
    uploadId: string
) =>
    request<'PUT /api/meeting-recording/upload/:uploadId/key/:key/part/:partNumber'>(
        'PUT',
        `/api/meeting-recording/upload/${uploadId}/key/${key}/part/${partNumber}`,
        {
            body: part,
        }
    )

export default {
    complete: complete,
    heartbeat: heartbeat,
    init: init,
    upload: upload,
}
