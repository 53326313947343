import { result, retry } from '@teamflow/lib'
import type { ILogger, JoinOptions } from '@teamflow/types'

import { CustomColyseusClient } from '../customColyseusClient'

import { ConnectionTransition } from './enums'
import { State, StateMachine } from './types'

/** @group Connection States */
export default class FindExistingRoom implements State {
    constructor(
        private readonly client: CustomColyseusClient,
        private readonly logger: ILogger
    ) {}

    async onEnter(machine: StateMachine, options: JoinOptions) {
        const { orgId, roomName = 'huddle' } = options

        if (options.refreshUrl) {
            // keep in mind we do not retry here
            // as having one could increase the time
            // for the manual Reconnect button to appear
            // in the UI during the reconnect process
            // Note: updateEndpoint doesn't throw an error because our api-client is error resilient.
            const { error } = await this.client.updateEndpoint(orgId)

            if (error) {
                this.logger.error(
                    `Unable to get updated endpoint for org: ${orgId}`,
                    { error }
                )

                await machine.runTransition(
                    ConnectionTransition.Failed,
                    options
                )

                return
            }
        }

        if (roomName === 'event-onboarding') {
            // don't need to check since each person will join a
            // separate room
            await machine.runTransition(
                ConnectionTransition.CreateRoom,
                options
            )
            return
        }

        // check for available rooms to see which action to take
        const [error, rooms] = await result(
            retry({
                action: async () => this.client.getAvailableRooms('huddle'),
                times: 3,
            })
        )
        if (error || !rooms) {
            // failed to get room names so cannot continue
            // possibly poor internet
            this.logger.error(`Unable to get existing realtime rooms`, {
                error,
            })
            await machine.runTransition(ConnectionTransition.Failed, options)
        } else {
            // look for an existing room based on roomId aka orgId
            const existingRoom = rooms.find((room) => room.roomId === orgId)

            if (existingRoom) {
                await machine.runTransition(ConnectionTransition.JoinRoom, {
                    existingRoom,
                    options,
                })
            } else {
                await machine.runTransition(
                    ConnectionTransition.CreateRoom,
                    options
                )
            }
        }
    }
}
