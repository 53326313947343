import rootStore from '@teamflow/store'

import Events from '../../events'

import { Connect, Substate, SubstateConnector } from './StateConnector'

type Pins = Substate<'roomSubstates'>['pins']

/** @group Connectors */
@Connect({ key: 'roomSubstates', substate: 'pins' })
export class PinConnector extends SubstateConnector<'roomSubstates', 'pins'> {
    onAttach(pins: Pins): void {
        pins.onAdd = (item) => {
            // Add onChange listener
            item.onChange = () => {
                this.sharedState.addPin(item)

                if (item.locationId === rootStore.commons.viewingSpace)
                    this.throttledEmitter.emit(Events.PinChanged, item.id, item)
            }

            this.sharedState.addPin(item)

            if (item.locationId === rootStore.commons.viewingSpace)
                this.events.emit(Events.PinAdded, item)
        }

        pins.onRemove = (item) => {
            this.sharedState.removePin(item)

            if (item.locationId === rootStore.commons.viewingSpace) {
                this.throttledEmitter.cancel(Events.PinChanged, item.id)
                this.events.emit(Events.PinRemoved, item)
            }
        }
    }

    onDetach(pins: Pins) {
        pins.onAdd = undefined
        pins.onRemove = undefined
    }
}
