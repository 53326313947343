import { makeAutoObservable, observable, computed } from 'mobx'

import { canEnterRoom, hasPermissionToEnterRoom } from '@teamflow/lib'
import { BackgroundPattern, RoomType, RoomAccessOptions } from '@teamflow/types'
import type { BETA_IRoom } from '@teamflow/types'

import type { RootStore } from './rootStore'

const UPDATE_SYMBOL = Symbol('RoomStore')

export class RoomStore {
    /** Map of room identifiers to rooms. */
    readonly roomsById = observable<string, Room>(new Map())

    /**
     * Map of room session identifiers to rooms.
     *
     * When a room is locked or unlocked, its key in this map will change!
     */
    readonly roomsBySessionId = observable<string, Room>(new Map())

    /** Map of rooms sessions with slug as key */
    readonly roomsBySlug = observable<string, Room>(new Map())

    constructor(public readonly rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            favorites: computed.struct,
            accessible: computed.struct,
            list: computed.struct,
            quickSearchData: computed.struct,
        })
    }

    reset() {
        this.roomsById.clear()
        this.roomsBySessionId.clear()
        this.roomsBySlug.clear()
    }

    put(data: IRoom): void {
        let room = this.roomsById.get(data.id)

        if (room) {
            // NOTE: This should be called before mutating the room! The last sessionId
            // is needed!
            this.roomsBySessionId.delete(room.sessionId)

            room.update(data, UPDATE_SYMBOL)
        } else {
            room = new Room(data, this.rootStore)
            this.roomsById.set(data.id, room)
        }

        this.roomsBySessionId.set(room.sessionId, room)
        this.roomsBySlug.set(room.slug, room)
    }

    delete(id: string): void {
        const room = this.roomsById.get(id)

        if (room) {
            this.roomsById.delete(id)
            this.roomsBySessionId.delete(room.sessionId)
        }
    }

    clear(): void {
        this.roomsById.clear()
    }

    /** Unsorted list of available rooms. */
    get list(): readonly Room[] {
        return Array.from(this.roomsById.values())
    }

    get roomsWithoutMeetings(): readonly Room[] {
        return this.list.filter((room) => room.type !== RoomType.Meeting)
    }

    getAccessibleRoom(id: string) {
        if (!id) return
        return this.roomsById.get(id)
    }
    /** Unsorted list of rooms accessible with the local user's permissions. */
    get accessible(): readonly Room[] {
        const localUser = this.rootStore.users.localUser

        if (!localUser) return []

        return this.list.filter((room) => room.accessible)
    }

    /**
     * List of rooms marked as favorite, sorted by their localized name.
     */
    get favorites(): ReadonlyArray<Room> {
        const favoriteRoomIds =
            this.rootStore.users.localUser?.favoriteRoomsIds ?? []

        const favorites = favoriteRoomIds
            .map((id) => this.roomsById.get(id))
            .filter((room): room is Room => !!room)
            .sort(RoomStore.compareRoomByName)

        return favorites
    }

    get joined(): ReadonlyArray<Room> {
        const joinedRoomIds =
            this.rootStore.users.localUser?.joinedRoomsIds ?? []

        const joined = joinedRoomIds
            .map((id) => this.roomsById.get(id))
            .filter((room): room is Room => !!room)
            .sort(RoomStore.compareRoomByName)

        return joined
    }

    get joinedAndAccessible(): ReadonlyArray<Room> {
        return this.joined.filter((room) => room.accessible)
    }

    get quickSearchData(): ReadonlyArray<Pick<Room, 'id' | 'name'>> {
        return this.accessible
            .filter((room) => {
                const user = this.rootStore.users.localUser
                if (user) {
                    const canEnter = canEnterRoom(user, room)
                    return canEnter
                }
                return false
            })
            .map((room) => ({
                id: room.id,
                name: room.name,
            }))
    }

    /**
     * Return unsorted list of empty rooms that are accessible with the local user's permissions
     */
    get accessibleRoomsWithNoUsers(): ReadonlyArray<Room> {
        const participantLocationIds =
            this.rootStore.participants.participantLocationIds

        return this.accessible.filter((room) => {
            return !participantLocationIds.includes(room.sessionId)
        })
    }

    get accessibleRoomsWithUsers(): ReadonlyArray<Room> {
        const participantLocationIds =
            this.rootStore.participants.participantLocationIds

        return this.accessible.filter((room) => {
            return participantLocationIds.includes(room.sessionId)
        })
    }

    get allRoomsWithNoUsers(): ReadonlyArray<Room> {
        const participantLocationIds =
            this.rootStore.participants.participantLocationIds

        return this.list.filter((room) => {
            return !participantLocationIds.includes(room.sessionId)
        })
    }

    get allRoomsWithUsers(): ReadonlyArray<Room> {
        const participantLocationIds =
            this.rootStore.participants.participantLocationIds

        return this.list.filter((room) => {
            return participantLocationIds.includes(room.sessionId)
        })
    }

    get isSpatialAudioEnabled() {
        const { avConnectedSpace } = this.rootStore.commons
        if (!avConnectedSpace) return true
        return this.roomsById.get(avConnectedSpace)?.spatialAudio ?? true
    }

    /**
     * Return whether or not a room name is in use already
     *
     * @param name - The name to check for.
     * @param excludedRoomId - Pass to not check against a specific room.
     */
    roomNameExists(name: string, excludedRoomId?: string): boolean {
        const lowerCaseName = name.toLowerCase()

        for (const room of this.list) {
            if (room?.id === excludedRoomId) {
                continue
            }

            if (lowerCaseName === room.name.toLowerCase()) {
                return true
            }
        }

        return false
    }

    static compareRoomByName(a: IRoom, b: IRoom) {
        return a.name.localeCompare(b.name)
    }
}

export type IRoom = BETA_IRoom

export class Room implements IRoom {
    id = ''
    backgroundImage = ''
    backgroundPattern: BackgroundPattern = BackgroundPattern.Tile
    furnished = false
    inviteCode = ''
    invitedIds: string[] = []
    knockingAllowedUserIds: string[] = []
    knockingEnabled = false
    locked = false
    name = 'unnamed'
    owner = ''
    templateId = ''
    personalOffice = false
    sessionId = ''
    slug = 'unnamed'
    spatialAudio = true
    spatialAudioChangedBy: string | undefined
    startInFullScreenMode = false
    waitlistIds: string[] = []
    whoCanJoin: string = RoomAccessOptions.AnyoneWithLink
    type = RoomType.Room

    constructor(data: IRoom, readonly rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            participants: computed.struct,
        })

        this.id = data.id
        this.update(data, UPDATE_SYMBOL)
    }

    get accessible() {
        const localUser = this.rootStore.users.localUser

        return localUser ? hasPermissionToEnterRoom(localUser, this) : false
    }

    get participants() {
        return this.rootStore.participants.participants.filter(
            (p) => p.locationId === this.sessionId
        )
    }

    get inviteLink() {
        const { data } = this.rootStore.organization
        if (!data) return ''
        return `${process.env.NEXT_PUBLIC_APP_URL}/${data.slug}/${this.slug}-${this.inviteCode}`
    }

    // NOTE: The updateSymbol enforces that update() can only be called by RoomStore. This
    // strict requirement ensures the roomsById, roomsBySessionId maps are consistent.
    update(data: IRoom, updateSymbol: typeof UPDATE_SYMBOL) {
        if (data.id !== this.id)
            throw new Error("Cannot update Room with another room's data")
        if (updateSymbol !== UPDATE_SYMBOL)
            throw new Error('Illegal call: Only RoomStore can update a room!')

        this.backgroundImage = data.backgroundImage
        this.backgroundPattern = data.backgroundPattern
        this.furnished = data.furnished
        this.inviteCode = data.inviteCode
        this.invitedIds = [...data.invitedIds]
        this.knockingAllowedUserIds = [...data.knockingAllowedUserIds]
        this.knockingEnabled = data.knockingEnabled
        this.locked = data.locked
        this.name = data.name
        this.owner = data.owner
        this.templateId = data.templateId
        this.personalOffice = data.personalOffice
        this.sessionId = data.sessionId
        this.slug = data.slug
        this.spatialAudio = data.spatialAudio
        this.spatialAudioChangedBy = data.spatialAudioChangedBy
        this.startInFullScreenMode = data.startInFullScreenMode
        this.waitlistIds = [...data.waitlistIds]
        this.whoCanJoin = data.whoCanJoin
        this.type = data.type
    }
}
