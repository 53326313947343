import { makeAutoObservable, ObservableSet } from 'mobx'

export class SpeakerModeStore {
    speakers: ObservableSet<string> = new ObservableSet<string>()

    constructor() {
        makeAutoObservable(this)
    }

    reset() {
        this.speakers.clear()
    }

    speakerJoined(id: string) {
        this.speakers.add(id)
    }

    speakerLeft(id: string) {
        this.speakers.delete(id)
    }

    get isActive() {
        return this.speakers.size > 0
    }
}
