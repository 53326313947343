import { runInAction } from 'mobx'
import { NextRouter, useRouter } from 'next/router'
import {
    ComponentType,
    CSSProperties,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import { api } from '@teamflow/client-api'
import {
    LEAVE_TIME,
    getRoomInvitePath,
    castRoomSessionToRoom,
    save,
    LogManager,
} from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { ITrackFunc, JoinAccess } from '@teamflow/types'

import { LoadingFunnel, BACK_BUTTON_INSIDE_ROOM } from '../analytics'
import { RealtimeServiceGlobals } from '../globals'
import {
    ScreenLoaderProps,
    VerseProps,
    GuestLoginProps,
    DeniedScreenProps,
    LockedRoomGuestWaitingProps,
    IRouterParams,
} from '../types'

const pageStyle: CSSProperties = {
    width: '100%',
    /* Do not set height = 100%/vh because of Safari including the
toolbar in the height. The verse app canvas is automatically resized
to document.documentElement so the height will still be correct */
    margin: '0px auto',
    backgroundColor: '#f2f2f2',
}

const redirectRoomSlug = async (
    router: NextRouter,
    orgSlug: string,
    roomSlugName: string,
    roomInviteCode?: string
) => {
    let url = getRoomInvitePath(orgSlug, roomSlugName, roomInviteCode)

    // Ensure we don't remove extra URL params like invitation code
    const urlParamsAfterRoomSlug = router.asPath
        .slice(1) // remove extra / at the beginning
        .split('/')
        .slice(2)
        .join('/')

    url += '/' + urlParamsAfterRoomSlug

    await router.replace(url, undefined, { shallow: true })
}

const logger = LogManager.ui.child({
    hook: 'useVisit',
    critical: true,
})

const useVisit = (
    orgSlug: string | undefined,
    roomSlug: string | undefined,
    params: { inviteCode?: string; invitation?: string },
    track: ITrackFunc
) => {
    const router = useRouter()
    const [visitState, setVisitState] = useState<{
        access?: JoinAccess | null
        error: boolean
        done: boolean
    }>({
        error: false,
        done: false,
    })
    const didInit = useRef<boolean>()
    const setAccess = useCallback((access: JoinAccess | null) => {
        setVisitState({
            access,
            error: false,
            done: true,
        })
    }, [])

    useEffect(() => {
        if (!orgSlug) return
        if (didInit.current) return

        didInit.current = true

        void api.preload
            .space({
                orgSlug,
                roomSlug,
                inviteCode: params.inviteCode,
                invitation: params.invitation,
            })
            .then(({ data, error }) => {
                if (!data) {
                    const message = error?.message || 'Something went wrong'
                    throw new Error(message)
                }
                const {
                    user,
                    userOrg,
                    settings,
                    access,
                    floorAccess,
                    organization,
                    organizationTierState,
                    roomSessions,
                    realtime,
                } = data

                if (realtime?.wsUrl) {
                    RealtimeServiceGlobals.wsEndpoint = realtime.wsUrl
                    RealtimeServiceGlobals.httpEndpoint = realtime.httpUrl
                }

                runInAction(() => {
                    if (user) rootStore.users.updateGlobalUser(user)
                    if (userOrg) rootStore.users.updateLocalUser(userOrg)
                    if (settings) {
                        rootStore.settings.update(settings, true)
                        rootStore.settings.setHydrated(true)
                    }

                    if (!rootStore.settings.rememberAVState) {
                        logger.info({
                            action: 'Call@forgetMicCamState',
                        })
                        const {
                            MICROPHONE_DEFAULT_STATE,
                            CAMERA_DEFAULT_STATE,
                        } = rootStore.audioVideo

                        void rootStore.audioVideo.toggleMicEnabled(
                            MICROPHONE_DEFAULT_STATE
                        )
                        void rootStore.audioVideo.toggleCameraEnabled(
                            CAMERA_DEFAULT_STATE
                        )
                    }

                    if (
                        access === JoinAccess.Granted ||
                        access === JoinAccess.Guest
                    ) {
                        rootStore.users.updateLocalFloorAccess(floorAccess)

                        if (organization)
                            rootStore.organization.update(organization)
                        if (organizationTierState)
                            rootStore.tier.update(organizationTierState)
                        if (roomSessions) {
                            const rooms = roomSessions.map(
                                castRoomSessionToRoom
                            )
                            rootStore.organization.setRooms(rooms)
                        }
                        if ('privateOfficeSlug' in data)
                            rootStore.experiments.setPrivateOfficeSlug(
                                data.privateOfficeSlug ?? ''
                            )
                        if ('privateOfficeOnboarding' in data)
                            rootStore.experiments.setIsRecentlyCreatedUser(
                                data.privateOfficeOnboarding ?? false
                            )
                    }

                    track(LoadingFunnel.GotAccessResponse)
                    track(LoadingFunnel.FinishedPotentialRefetchUser)

                    rootStore.users.setHasRequestedLocalUser()
                })

                if (data.roomSlugRedirect) {
                    void redirectRoomSlug(
                        router,
                        orgSlug,
                        data.roomSlugRedirect,
                        params.inviteCode
                    )
                }

                const errorCode = 'errorCode' in data ? data.errorCode : null

                if (errorCode)
                    rootStore.users.setLocalUserError(String(errorCode))

                setVisitState({
                    access,
                    error: !!errorCode,
                    done: true,
                })
            })
            .catch((error) => {
                rootStore.users.setHasRequestedLocalUser()
                rootStore.users.setLocalUserError(
                    'Error fetching visitation information.'
                )
                setVisitState({
                    access: JoinAccess.Denied,
                    error: true,
                    done: true,
                })
                logger.error('Error fetching preload endpoint data', {
                    error,
                })
            })
    }, [orgSlug, params.invitation, params.inviteCode, roomSlug, router, track])

    return {
        ...visitState,
        setAccess,
    }
}

interface Props {
    ScreenLoaderComponent: ComponentType<ScreenLoaderProps>
    VerseComponent: ComponentType<VerseProps>
    GuestLoginComponent: ComponentType<GuestLoginProps>
    DeniedScreenComponent: ComponentType<DeniedScreenProps>
    LockedRoomGuestWaitingComponent: ComponentType<LockedRoomGuestWaitingProps>
    HeadElement: JSX.Element
    track: ITrackFunc
    params: IRouterParams | null
}

function TeamflowComponent({
    ScreenLoaderComponent,
    VerseComponent,
    GuestLoginComponent,
    DeniedScreenComponent,
    LockedRoomGuestWaitingComponent,
    HeadElement,
    track,
    params,
    loadingFinished,
    setLoadingFinished,
}: Props & {
    loadingFinished: boolean
    setLoadingFinished: (value: boolean) => void
}) {
    useEffect(() => {
        track(LoadingFunnel.HitDotDotDotOrg)
    }, [track])

    const router = useRouter()
    const [loadingText, setLoadingText] = useState<string | null>(null)

    const { access, setAccess, done } = useVisit(
        params?.orgSlug,
        params?.roomSlug,
        {
            inviteCode: params?.inviteCode,
            invitation: params?.invitation,
        },
        track
    )

    const user = rootStore.users.localUser
    const loading = rootStore.users.localUserLoading
    const error = rootStore.users.localUserError
    const pendingRoomTeleport = rootStore.commons.pendingRoomTeleport
    const globalUser = rootStore.users.globalUser

    useEffect(() => {
        if (params?.mobileWebView) {
            window.localStorage.setItem('inMobileWebView', 'true')
        }
        if (params?.hideMobileNavigation) {
            window.localStorage.setItem('hideMobileNavigation', 'true')
        }
    }, [params?.hideMobileNavigation, params?.mobileWebView])

    useEffect(() => {
        router.beforePopState(({ url, options }) => {
            if (url === '/[...org]' && options.shallow) {
                track(BACK_BUTTON_INSIDE_ROOM)
                // reset the last leave time to now, as user is not refreshing
                save(LEAVE_TIME, Date.now().toString())
                // someone hit the back button in a room, hard reload the main floor
                window.location.reload()
                return false
            }

            return true
        })
    }, [router, track])

    const userToGuestInfo = useMemo(
        () =>
            globalUser
                ? {
                      firstName: globalUser.firstName,
                      lastName: globalUser.lastName,
                      email: globalUser.email,
                  }
                : undefined,
        [globalUser]
    )

    const screenLoader = useMemo(
        () => <ScreenLoaderComponent text={loadingText} />,
        [ScreenLoaderComponent, loadingText]
    )

    if (!params?.orgSlug || loading || !access || !done) {
        return <>{screenLoader}</>
    }

    if (access === JoinAccess.Guest) {
        return (
            <GuestLoginComponent
                orgSlug={params.orgSlug}
                roomSlug={params.roomSlug}
                inviteCode={params.inviteCode}
                orgDisplayName={
                    rootStore.organization.data?.displayName ?? params.orgSlug
                }
                userToGuestInfo={userToGuestInfo}
                setAccess={setAccess}
                invitation={params.invitation}
            />
        )
    }

    if (access === JoinAccess.Denied || !user || error) {
        return <DeniedScreenComponent error={error} />
    }

    if (access === JoinAccess.Knock) {
        return (
            <LockedRoomGuestWaitingComponent
                userId={user._id}
                orgSlug={params.orgSlug}
                roomSlug={params.roomSlug}
                setAccess={setAccess}
            />
        )
    }

    return (
        <>
            {!loadingFinished && screenLoader}
            <div
                style={pageStyle}
                id="outer-verse"
                data-orgslug={params.orgSlug}
                data-roomslug={params.roomSlug}
                data-invitecode={params.inviteCode}
            >
                {HeadElement}
                <VerseComponent
                    user={user}
                    orgSlug={params.orgSlug}
                    roomSlug={
                        params.roomSlug ?? pendingRoomTeleport?.room.slugName
                    }
                    inviteCode={params.inviteCode}
                    locationX={params.locationX}
                    locationY={params.locationY}
                    setLoadingFinished={setLoadingFinished}
                    setLoadingText={setLoadingText}
                />
            </div>
        </>
    )
}

export function useLoadTeamflow(props: Props): {
    loadingFinished: boolean
    TeamflowComponent: JSX.Element
} {
    const [loadingFinished, setLoadingFinished] = useState(false)

    return {
        loadingFinished,
        TeamflowComponent: (
            <TeamflowComponent
                {...props}
                loadingFinished={loadingFinished}
                setLoadingFinished={setLoadingFinished}
            />
        ),
    }
}
