import { makeAutoObservable, observable } from 'mobx'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

function filterNotificationsById(id: string) {
    return function filterNotification(notification: NotificationPayload) {
        return notification.id !== id
    }
}

/** Notification template to provide for creating new notifications */
export type NotificationTemplate = {
    /** (optional) Id to prevent duplicate notifications */
    id?: string

    /** An optional icon to be displayed at 32px*32px */
    icon?: string | React.ReactElement

    /** Title of the notification */
    title: string

    /** Summary shown in smaller font size below the title */
    summary?: string

    /** User actions available to the user */
    actions: ReadonlyArray<{
        cb?: () => void
        label: string
        href?: string | null
    }>

    /** Optional timeout in milliseconds */
    timeout?: number
    timeoutFn?: () => any
}

/** Notification with additional fields for internal state */
export type NotificationPayload = NotificationTemplate & {
    /** Unique ID for this notification instance. Can be used as a handle. */
    id: string

    /** UTC timestamp of the creation of the notification */
    timestamp: number
}

export class Notifications {
    current: ReadonlyArray<NotificationPayload> = []

    constructor() {
        makeAutoObservable(this, {
            // Only track ref changes
            current: observable.ref,
        })
    }

    reset() {
        this.current = []
    }

    /**
     * Show a notification with the given template
     * @param template
     * @return A handle for the notification
     */
    show(template: NotificationTemplate) {
        const notification: NotificationPayload = {
            id: uuidv4(),
            timestamp: Date.now(),
            ...template,
        }

        if (notification.timeout) {
            setTimeout(() => {
                this.dismiss(notification.id)

                if (notification.timeoutFn) {
                    notification.timeoutFn()
                }
            }, notification.timeout)
        }

        this.current = [
            ...this.current.filter(filterNotificationsById(notification.id)),
            notification,
        ]

        return notification.id
    }

    /** Dismiss a notification using its handle. */
    dismiss(id: string) {
        this.current = this.current.filter(filterNotificationsById(id))
    }

    edit(payload: { id: string } & Partial<NotificationPayload>) {
        this.current = this.current.map((notification) => {
            if (notification.id === payload.id) {
                return {
                    ...notification,
                    ...payload,
                }
            }
            return notification
        })
    }

    hasNotification(id: string) {
        return this.current.some((el) => el.id === id)
    }
}
