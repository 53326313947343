import * as t from '@teamflow/types'

export class ParticipantStatusCommand
    implements t.ICommand<t.IParticipantStatus>
{
    readonly messageType = t.ClientMessage.ParticipantStatus

    private status: string

    constructor(status: string) {
        this.status = status
    }

    execute() {
        return {
            status: this.status,
        }
    }
}

export default ParticipantStatusCommand
