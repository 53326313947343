export default {
    phoneburner: {
        toUsePhoneBurnerWithTeamflowFollowTheseSteps:
            'phoneburner.toUsePhoneBurnerWithTeamflowFollowTheseSteps',
        navigateToThePhoneBurnerWebhooksDashboardAt:
            'phoneburner.navigateToThePhoneBurnerWebhooksDashboardAt',
        copyTheFollowingURLIntoTheCallBeginWebhookInput:
            'phoneburner.copyTheFollowingURLIntoTheCallBeginWebhookInput',
        copyTheFollowingURLIntoTheCallEndsWebhookInput:
            'phoneburner.copyTheFollowingURLIntoTheCallEndsWebhookInput',
        clickSaveChanges: 'phoneburner.clickSaveChanges',
    },
    aircall: {
        toUserAirCallWithTeamflowFollowTheseSteps:
            'aircall.toUserAirCallWithTeamflowFollowTheseSteps',
        haveAnAircallAdminUserSignInToAircallAt:
            'aircall.haveAnAircallAdminUserSignInToAircallAt',
        clickOnIntegrationsAndAPI: 'aircall.clickOnIntegrationsAndAPI',
        clickOnDiscoverIntegrations: 'aircall.clickOnDiscoverIntegrations',
        scrollDownToWebhookInstallIntegration:
            'aircall.scrollDownToWebhookInstallIntegration',
        registerTheWebhookName: 'aircall.registerTheWebhookName',
        makeSureTheCallEventsAreToggled:
            'aircall.makeSureTheCallEventsAreToggled',
        saveTheWebhookAndSaveToken: 'aircall.saveTheWebhookAndSaveToken',
        copyTheTokenAndSaveItInTheFollowingInput:
            'aircall.copyTheTokenAndSaveItInTheFollowingInput',
        copyThatTokenAndSendItToTeamflow:
            'aircall.copyThatTokenAndSendItToTeamflow',
        submitToken: 'aircall.submitToken',
        failedToSaveToken: 'aircall.failedToSaveToken',
        tokenSuccessfullySaved: 'aircall.tokenSuccessfullySaved',
    },
    integrationsPage: {
        connectYourTools: 'integrationsPage.connectYourTools',
        skipThisStep: 'integrationsPage.skipThisStep',
        setupDialer: 'integrationsPage.setupDialer',
        goBack: 'integrationsPage.goBack',
        continue: 'integrationsPage.continue',
        youreAllSet: 'integrationsPage.youreAllSet',
        ifYouHaveAnyIssuesSettingUpTheIntegrationPleaseContactSupportForAssistance:
            'integrationsPage.ifYouHaveAnyIssuesSettingUpTheIntegrationPleaseContactSupportForAssistance',
    },
}
