import * as t from '@teamflow/types'

export default class ChangeStartInFullScreenModeCommand
    implements t.ICommand<t.IChangeStartInFullScreenModeAction>
{
    readonly messageType = t.ClientMessage.ChangeStartInFullScreenMode

    private roomSlugName: string
    private startInFullScreenMode: boolean

    constructor(roomSlugName: string, startInFullScreenMode: boolean) {
        this.roomSlugName = roomSlugName
        this.startInFullScreenMode = startInFullScreenMode
    }

    execute() {
        return {
            roomSlugName: this.roomSlugName,
            startInFullScreenMode: this.startInFullScreenMode,
        }
    }
}
