/* eslint-disable no-inner-declarations */
import { v4 as uuidv4 } from 'uuid'

import { loadLocalUser } from '@teamflow/bootstrap'
import {
    AudioZoneCommand,
    AudioZoneDeleteCommand,
    RoomDeleteCommand,
    ValidateFurnitureCommand,
} from '@teamflow/client-realtime'
import { validation } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { RoomAccessOptions } from '@teamflow/types'

import { createRoom } from '../requests'
import uiStore from '../store/uiStore'
import { Verse } from '../verse'

declare global {
    interface Window {
        tf_debug: { [key: string]: any }
        verse?: Verse
    }
}

type FindArgs = {
    firstName?: string
    lastName?: string
    userId?: string
    participantId?: string
}

export function setUpDebugFunctions() {
    if (
        // disabled on production, enabled on all other envs
        (process.env.NEXT_PUBLIC_APP_ENV !== 'production' ||
            process.env.NEXT_PUBLIC_APP_URL?.includes('reprod')) &&
        typeof window !== 'undefined'
    ) {
        function findUser(findArgs: FindArgs = {}) {
            return rootStore.users.users.find((user) => {
                if (!Object.values(findArgs).length) {
                    return false
                }

                let firstNameMatch = true
                let lastNameMatch = true
                let userIdMatch = true
                let participantIdMatch = true

                if (findArgs.firstName) {
                    firstNameMatch =
                        user.firstName.toLowerCase().trim() ===
                        findArgs.firstName.toLowerCase().trim()
                }

                if (findArgs.lastName) {
                    lastNameMatch =
                        user.lastName.toLowerCase().trim() ===
                        findArgs.lastName.toLowerCase().trim()
                }

                if (findArgs.userId) {
                    userIdMatch =
                        user._id.toLowerCase().trim() ===
                        findArgs.userId.toLowerCase().trim()
                }

                if (findArgs.participantId) {
                    participantIdMatch =
                        user._id.toLowerCase().trim() ===
                        findArgs.participantId.toLowerCase().trim()
                }

                return (
                    firstNameMatch &&
                    lastNameMatch &&
                    userIdMatch &&
                    participantIdMatch
                )
            })
        }

        function findParticipant(findArgs: FindArgs) {
            const userId = findUser(findArgs)?._id
            if (!userId) return
            return rootStore.participants.participants.find(
                (p) => p.id === userId
            )
        }

        function getMedia(findArgs: FindArgs) {
            const participant = findParticipant(findArgs)
            if (!participant) return
            return participant.media
        }

        function getVideoElement(findArgs: FindArgs) {
            const participant = findParticipant(findArgs)
            if (!participant) return
            const el: HTMLVideoElement | null = document.querySelector(
                `[data-testid="tf-local-participant-video-${participant.id}"]`
            )
            return el
        }

        function getAudioElement(findArgs: FindArgs) {
            const user = findUser(findArgs)
            if (!user) return
            const el: HTMLAudioElement | null = document.querySelector(
                `[data-testid="audio-${user._id}"]`
            )
            return el
        }

        function getParticipantAudioTrack(findArgs: FindArgs) {
            const participant = findParticipant(findArgs)
            if (!participant) return
            return participant.media?.audioTrack
        }

        function getParticipantVideoTrack(findArgs: FindArgs) {
            const participant = findParticipant(findArgs)
            if (!participant) return
            return participant.media?.videoTrack
        }

        // Quick debug function to check whether or not the client's furniture is
        // the same as the server's. It just checks all IDs are there as expected
        // not that all objects are equal. Right now this can be used for debugging
        // might be worth in the future having the client check furniture is correct
        // after requesting it if it does not cause performance issues
        function validateFurniture() {
            const verse = window.verse
            if (!verse) {
                throw new Error('Verse not found on window')
            }

            const furniture = verse.realtime.furniture
            const checksum = validation.getArrayChecksum(furniture)
            verse.realtime.dispatch(
                new ValidateFurnitureCommand(
                    checksum,
                    rootStore.commons.viewingSpace
                )
            )
        }

        /**
         * Creates N empty rooms. Used as bulk creation as part of bots/profiling task.
         */
        async function createRooms(amount: number) {
            const verse = window.verse
            if (!verse) {
                throw new Error('Verse not found on window')
            }

            const currentUserId = rootStore.users.localUserId || ''
            const currentOrgId = rootStore.organization.data?._id || ''
            const realtime = verse.realtime
            const whoCanJoin = RoomAccessOptions.AnyoneInCompany
            const templateId = null
            const personalOffice = false

            const roomNames = Array(amount)
                .fill(0)
                .map((_, i) => `room ${i}`)
            for (const roomName of roomNames) {
                const createdRoom = await createRoom(
                    currentUserId,
                    currentOrgId,
                    realtime,
                    roomName,
                    whoCanJoin,
                    templateId,
                    personalOffice
                )
                // eslint-disable-next-line no-console
                console.log('>>> room is created', createdRoom)
            }
            // eslint-disable-next-line no-console
            console.log('>>> all rooms are created')
        }

        function createAZ(
            x: number,
            y: number,
            width = 10,
            height = 10,
            label = ''
        ) {
            const verse = window.verse
            if (!verse) {
                throw new Error('Verse not found on window')
            }

            const id = uuidv4()
            const locationId = verse.spaces.activeLocationId ?? ''
            verse.realtime.dispatch(
                new AudioZoneCommand({
                    id,
                    locationId,
                    x,
                    y,
                    width,
                    height,
                    label,
                })
            )
        }

        /**
         * Creates N audio zones. Used as bulk creation as part of bots/profiling task.
         */
        function createAZs(amount: number, size = 250) {
            // between AZs
            const gap = 50

            // how many AZs per 1 row in a big square
            const rows = Math.ceil(Math.sqrt(amount))

            // sum of sizes of all AZs in 1 row - needed to place AZs around room center
            const totalSize = rows * (size + gap)

            let index = 0
            for (let i = 0; i < rows; i++) {
                for (let j = 0; j < rows; j++) {
                    if (index >= amount - 1) {
                        break
                    }
                    const label = `az ${index++}`
                    const x = j * size + gap - totalSize / 2
                    const y = i * size + gap - totalSize / 2
                    createAZ(x, y, size, size, label)
                }
            }
        }

        /**
         * BE CAREFUL! This one deletes all audio zones in current room!
         */
        function deleteAZsInCurrentRoom() {
            const verse = window.verse
            if (!verse) {
                throw new Error('Verse not found on window')
            }

            const azsInCurrentRoom =
                rootStore.audioZones.findNamedAudioZonesInRoom(
                    rootStore.participants.localParticipantRoomId
                )
            azsInCurrentRoom.forEach((az) => {
                verse.realtime.dispatch(
                    new AudioZoneDeleteCommand(
                        az.id,
                        rootStore.commons.viewingSpace
                    )
                )
            })
        }

        function deleteAllRooms() {
            const verse = window.verse
            if (!verse) {
                throw new Error('Verse not found on window')
            }

            const allRoomsExceptMainFloor =
                rootStore.rooms.allRoomsWithNoUsers.filter((r) => r.id)
            allRoomsExceptMainFloor.forEach((r) => {
                verse.realtime.dispatch(new RoomDeleteCommand(r.id, 'delete'))
            })
        }

        /**
         * Generates N furnitures in current room within current viewport
         */
        async function addRandomFurnitures(amount: number) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const verse = window.verse as Verse
            const furnitureSpace = verse.spaces.findByEntity('furniture')

            if (!furnitureSpace) {
                window.alert('Could not find furniture space')
                return
            }

            const allFurniture = Object.values(uiStore.furniture.catalog)

            if (!allFurniture.length) {
                window.alert('There are no furnitures in the catalog')
                return
            }

            for (let i = 0; i < amount; i++) {
                // eslint-disable-next-line no-console
                console.log(`Adding furniture ${i + 1} of ${amount}`)
                const viewportCenter = verse.getViewportCenter()
                const viewportSize = verse.getVisibleBounds()

                const model =
                    allFurniture[(Math.random() * allFurniture.length) | 0]!

                const id = model.nameSlug ?? model._id

                if (!furnitureSpace) return

                try {
                    const furniture = await furnitureSpace.addFurniture(
                        id,
                        model.rasters[0].image,
                        false,
                        viewportCenter.x +
                            ((Math.random() * viewportSize.width) / 2) *
                                (Math.random() >= 0.5 ? -1 : 1),
                        viewportCenter.y +
                            ((Math.random() * viewportSize.height) / 2) *
                                (Math.random() >= 0.5 ? -1 : 1)
                    )
                    await verse.realtime.furnitureAdded({ id: furniture.id })
                } catch (error) {
                    console.error(
                        `Failed to add furniture ${i + 1} due to error`,
                        {
                            error,
                        }
                    )
                }
            }
        }

        /**
         * Deletes all visible furniture - from current room
         */
        async function deleteAllFurniture() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const verse = window.verse as Verse
            const furnitureSpace = verse.spaces.findByEntity('furniture')

            if (!furnitureSpace) {
                window.alert('Could not find furniture space')
                return
            }
            const allFurnituresIds = [
                ...rootStore.spatialHash['entityData'].entries(),
            ]
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .filter(([_id, spatialObj]) => spatialObj.type === 'furniture')
                .map(([id]) => id)
            await Promise.all(
                allFurnituresIds.map((id) => {
                    return furnitureSpace.deleteFurniture(id)
                })
            )
            // eslint-disable-next-line no-console
            console.log('>>> all furniture deleted')
        }

        window.tf_debug = {}
        window.tf_debug.loadLocalUser = loadLocalUser
        window.tf_debug.findUser = findUser
        window.tf_debug.findParticipant = findParticipant
        window.tf_debug.getMedia = getMedia
        window.tf_debug.getVideoElement = getVideoElement
        window.tf_debug.getAudioElement = getAudioElement
        window.tf_debug.getParticipantAudioTrack = getParticipantAudioTrack
        window.tf_debug.getParticipantVideoTrack = getParticipantVideoTrack
        window.tf_debug.validateFurniture = validateFurniture
        window.tf_debug.createRooms = createRooms
        window.tf_debug.createAZs = createAZs
        window.tf_debug.deleteAZsInCurrentRoom = deleteAZsInCurrentRoom
        window.tf_debug.deleteAllRooms = deleteAllRooms
        window.tf_debug.addRandomFurnitures = addRandomFurnitures
        window.tf_debug.deleteAllFurniture = deleteAllFurniture
    }
}
