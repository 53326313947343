import { DataChange } from '@colyseus/schema'
import _ from 'lodash'

import { AVATAR_RADIUS, LogManager, throttleReduce } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'
import { DeploymentState, IParticipant_Global } from '@teamflow/types'

import Events from '../../events'

import { Connect, StateConnector } from './StateConnector'
import {
    changesAccumulator,
    changesTransformer,
    putOrRemoveFromHashBasedOnSessionId,
    throttleOptions,
    throttleWait,
} from './helpers'

const putParticipantInHash = (item: t.IParticipant) => {
    rootStore.spatialHash.put(
        item.id,
        'participant',
        item.x - AVATAR_RADIUS,
        item.y - AVATAR_RADIUS,
        item.x + AVATAR_RADIUS,
        item.y + AVATAR_RADIUS
    )
}

const logger = LogManager.createLogger('ParticipantGlobalConnector', {
    critical: true, // not critical but almost? i'm not flo - so we're not gonna do that
})

/** @group Connectors */
@Connect({ key: 'participantsGlobal' })
export class ParticipantGlobalConnector extends StateConnector<'participantsGlobal'> {
    onAttach(participantsGlobal: t.IVerseState['participantsGlobal']) {
        // Participants can be left over from the previous session!
        if (
            rootStore.commons.deploymentState === DeploymentState.None &&
            !rootStore.commons.reconnecting
        ) {
            rootStore.participants.clearParticipants()
        }

        participantsGlobal.onAdd = (item) => {
            item.onChange = throttleReduce(
                (changes: DataChange<any>[]) => {
                    // TODO(Shukant): This is a "lockstep" change maker b/w the participant's "locationId"
                    //  and the commons "currentSpace". The need for this was formally introduced in the
                    //  bug story related to whisper mode: https://www.notion.so/teamflowhq/Whisper-mode-getting-enabled-after-exiting-room-b969953103c94b5cbbc71a90ac1b8caf
                    //  ---
                    //  The room session may or may not available by the time your locationId
                    //  changes to that session. That's why this is a "temporary stitch" and will only be fixed
                    //  by killing room sessions.
                    //  ---
                    //  This is only implemented for going to main floor cause the RT never kicks you into a
                    //  room directly + the room session may not be available locally when your locationId to that
                    //  session (KILL ROOM SESSIONS! :))
                    //  ---
                    //  The navigation flow still calls use(MainSpace) on SpaceService and that will re-call switchSpace
                    //  harmlessly. The net benefits of this temporary stitch are twofold:
                    //      1. If the server kicks you out of a room, then the UI will correctly update to the main floor. This
                    //         may happen when a hard disconnect occurs and you need to reknock and get access to the room again.
                    //      2. Loading the UI when going to the main floor is a lil bit faster :)

                    if (
                        item.id === rootStore.users.localUserId &&
                        changes.some(
                            (change) => change.field === 'locationId'
                        ) &&
                        item.locationId === ''
                    ) {
                        logger.warn(
                            'Temporary stitch: Fast switchAvConnectedSpace() to main floor with change in locationId'
                        )
                        // change only the avConnectedSpace and do not change the viewing space, as it might cause
                        // inconsistency because the user could be viewing a different room, later on the navigation
                        // will change the viewing space if it is needed
                        rootStore.commons.switchAvConnectedSpace('')
                    }

                    const participant =
                        rootStore.participants.findParticipantById(item.id)
                    if (!participant) {
                        return
                    }

                    changes.forEach((change) => {
                        const field = change.field as keyof IParticipant_Global
                        const value = change.value
                        if (participant) {
                            participant.update({ [field]: value })
                        }

                        switch (field) {
                            case 'callShard':
                                if (
                                    item.id === this.realtime.userOrganizationId
                                ) {
                                    rootStore.audioVideo.updateShardId(
                                        item.callShard
                                    )
                                }
                                break
                            case 'locationType':
                            case 'locationId':
                                this.events.emit(
                                    Events.ParticipantLocationChanged,
                                    participant
                                )
                                break

                            case 'ready':
                                this.events.emit(
                                    Events.ParticipantReadyChanged,
                                    participant
                                )
                                break
                            // It's not recommended to listen to this event.
                        }
                    })

                    this.events.emit(Events.ParticipantChanged, participant)
                    this.events.emit(Events.ParticipantUpdated, participant)

                    putOrRemoveFromHashBasedOnSessionId(
                        participant,
                        putParticipantInHash
                    )

                    this.throttledEmitter.emit(
                        Events.ParticipantsUpdated,
                        Events.ParticipantsUpdated,
                        this.realtime.participants
                    )
                },
                changesAccumulator,
                changesTransformer,
                throttleWait,
                throttleOptions
            )

            const p = rootStore.participants.put(item)
            this.events.emit(Events.ParticipantAdded, p)
            this.events.emit(Events.ParticipantUpdated, p)
            putOrRemoveFromHashBasedOnSessionId(p, putParticipantInHash)

            this.throttledEmitter.emit(
                Events.ParticipantsUpdated,
                Events.ParticipantsUpdated,
                this.realtime.participants
            )
        }

        participantsGlobal.onRemove = (item) => {
            const participant = rootStore.participants.findParticipantById(
                item.id
            )
            if (!participant) {
                return
            }

            rootStore.participants.removeParticipant(participant.id)
            rootStore.spatialHash.delete(participant.id)

            this.actionQueue.push(() => {
                rootStore.participants.removeParticipant(participant.id)
                this.events.emit(Events.ParticipantRemoved, participant)
                this.events.emit(Events.ParticipantUpdated, participant)

                this.throttledEmitter.emit(
                    Events.ParticipantsUpdated,
                    Events.ParticipantsUpdated,
                    this.realtime.participants
                )
            })
        }
    }

    onDetach(participantsGlobal: t.IVerseState['participantsGlobal']) {
        participantsGlobal.onAdd = undefined
        participantsGlobal.onRemove = undefined
    }

    onSwitchSpace(_: t.IVerseState['participantsGlobal']) {
        const currentSpace = rootStore.commons.viewingSpace
        const currentSessionId =
            currentSpace !== ''
                ? rootStore.rooms.roomsById.get(currentSpace)?.sessionId
                : ''

        // Populate participants
        for (const participant of rootStore.participants.participants)
            if (participant.locationId === currentSessionId)
                putParticipantInHash(participant)
    }
}
