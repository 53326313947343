import {
    memo,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'

import { Box, Button, Icon } from '@teamflow/design'

import {
    ELECTRON_DEFAULT_TITLE_BAR_COLOR,
    ELECTRON_DEFAULT_TITLE_BAR_SYMBOL_COLOR,
    TOP_BAR_HEIGHT,
} from '../../constants'
import { useTitleBarOffsetWidth } from '../../hooks/useTitleBarOffsetWidth'
import {
    ElectronService,
    TitleBarColorOverride,
} from '../../verse/services/ElectronService'

import { NonDraggableElectron } from '../topHeader/RoomToolbar/NonDraggableElectron'

const MENU_X_ADJUSTMENT = -96

const WindowsTitleBarMenu = memo(function WindowsTitleBarMenu() {
    const offsetWidth = useTitleBarOffsetWidth()

    const [iconColor, setIconColor] = useState(
        ELECTRON_DEFAULT_TITLE_BAR_SYMBOL_COLOR
    )
    const [noBackground, setNoBackground] = useState(false)

    useEffect(() => {
        const handleColorChange = ({
            color,
            symbolColor,
        }: TitleBarColorOverride) => {
            setIconColor(symbolColor)
            // if custom color theme, make background transparent (on hover)
            setNoBackground(
                color !== ELECTRON_DEFAULT_TITLE_BAR_COLOR &&
                    symbolColor !== ELECTRON_DEFAULT_TITLE_BAR_SYMBOL_COLOR
            )
        }

        ElectronService.onTitleBarColorChange(handleColorChange)
        return () => ElectronService.offTitleBarColorChange(handleColorChange)
    }, [])

    const handleMenuOpen = useCallback(() => {
        ElectronService.openMainWindowMenu(
            window.innerWidth - offsetWidth + MENU_X_ADJUSTMENT,
            TOP_BAR_HEIGHT
        )
    }, [offsetWidth])

    const menuOverlayStyle = useMemo(
        () => ({
            // top header has 1px bottom border
            height: TOP_BAR_HEIGHT - 1,
            right: offsetWidth,
            zIndex: 1,
        }),
        [offsetWidth]
    )

    const buttonStyle = useMemo(
        () => ({
            background: noBackground ? 'transparent !important' : undefined,
        }),
        [noBackground]
    )

    const iconStyle = useMemo(
        () => ({
            color: iconColor,
        }),
        [iconColor]
    )

    return (
        <Box
            position="absolute"
            top="none"
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingRight="space4"
            __cssOverrides={menuOverlayStyle}
        >
            <NonDraggableElectron>
                <Button
                    kind="text"
                    level="neutral"
                    size="sm"
                    __cssOverrides={buttonStyle}
                    onClick={handleMenuOpen}
                >
                    <Icon name="hamburger" size="size16" style={iconStyle} />
                </Button>
            </NonDraggableElectron>
        </Box>
    )
})

export default function TitleBarMenuWrapper({
    children,
}: PropsWithChildren<{}>) {
    const [showMenu, setShowMenu] = useState(false)

    // don't mount menu component server-side
    useEffect(() => {
        setShowMenu(ElectronService.hasEmbeddedTopBarWindows)
    }, [])

    return (
        <>
            <Box position="relative" width="fill" height="fill">
                {children}
            </Box>
            {showMenu && <WindowsTitleBarMenu />}
        </>
    )
}
