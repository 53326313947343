import { createRequest } from '../request'

const populateOrg = createRequest<'POST /api/debug/populateOrg'>(
    'POST',
    '/api/debug/populateOrg'
)

export default {
    populateOrg,
}
