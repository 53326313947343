import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export default class AppRenameCommand implements t.ICommand<t.IAppRename> {
    readonly messageType = t.ClientMessage.AppRename

    private id = ''
    private title = ''
    private locationId = ''

    constructor(id: string, title: string, locationId: string) {
        this.id = id
        this.title = title
        this.locationId = locationId
    }

    execute() {
        return {
            id: this.id,
            title: this.title,
            locationId: this.locationId,
        }
    }

    localExecute(state: t.IVerseState) {
        rootStore.app.getAppById(this.id)?.setTitle(this.title)

        const app = state.roomSubstates
            .get(this.locationId)
            ?.apps.find((app) => app.id === this.id)
        if (app) {
            app.title = this.title
        }
    }
}
