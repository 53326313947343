import * as Sentry from '@sentry/react'
import { merge } from 'lodash'

import { generateShortUuid, LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { ITrackFunc, TrackPayload, UserAnalyticsTraits } from '@teamflow/types'

export const NORFANG_BOTS_EMAIL_DOMAIN = '@tfbot'

export const BACK_BUTTON_INSIDE_ROOM = 'back_button_inside_room'
export const USER_REFRESHED = 'user_refreshed'

export enum LoadingFunnel {
    HitDotDotDotOrg = 'hit_dot_dot_dot_org',
    HitVerseWrapper = 'hit_verse_wrapper',
    HitGuestLogin = 'hit_guest_login',
    HitGuestKnocking = 'hit_guest_knocking',
    HitAccessDenied = 'hit_access_denied',
    HitDownloadApp = 'hit_download_app',
    HitReconnectToOrg = 'hit_reconnect_to_org',
    GotAccessResponse = 'got_access_response',
    FinishedPotentialRefetchUser = 'finished_potential_refetch_user',
    VerseDynamicLoadingInitiated = 'verse_dynamic_loading_initiated',
    CreateVerseCalled = 'create_verse_called',
    RightBeforeRealtimeConnected = 'right_before_realtime_connected',
    ConnectedToRealtime = 'connected_to_realtime',
    WaitingForRoomJoinOrError = 'waiting_for_room_join_or_error',
    RoomJoinOrErrorComplete = 'room_join_or_error_complete',
    CreateVerseDone = 'create_verse_done',
    UseInitVerseCalled = 'use_init_verse_called',
    ConnectedToDailyCall = 'connected_to_daily_call',
    LoadedFloorOrRoom = 'loaded_floor_or_room',
    ParticipantReady = 'participant_ready',
    LoadingScreenRemoved = 'loading_screen_removed',
    AppcuesFlowStarted = 'appcues_flow_started',
    AppcuesFlowCompleted = 'appcues_flow_completed',
    AppcuesFlowSkipped = 'appcues_flow_skipped',
}

// Note: When updating the signup flow or analytics events relating to signup flow,
// please use the eventWithFlow() method to indicate the analytics Flow it is part of.
// Tracking event names for the signup flow should be appended with the datestring _YYYY_MM_DD
// Please also notify Amir and Chris of any changes to the signup flow and update the Whimsical Signup Flow:
// https://whimsical.com/signup-and-login-flows-with-analytics-events-NE9ngTj6m8eZhWqGYdDUSX

export enum Signup {
    LEGACY_Skip = 'signup_skip',
    LEGACY_ModifyInvitee = 'signup_change_invite_list',
    LEGACY_UnselectAllInvitees = 'signup_unselect_all_invitees',
    LEGACY_SelectAllInvitees = 'signup_select_all_invitees',
    LEGACY_LoginAlreadyOnTeam = 'login_already_on_team',
    LEGACY_InviteSelectGoogle = 'signup_invite_select_with_google',
    LEGACY_InviteWithGoole = 'signup_invite_with_google',
    Completed = 'signup-completed',
    Error = 'signup_error',
    InviteAddTeamEmail = 'invite_add_team_email_2022_07_21',
    InviteCopyLink = 'invite_copy_link_2022_07_21',
    SetupRoom = 'setup_room_created_2022_07_21',
    EmailAndPassword = 'flow_email_and_password_2022_07_21',
    GoogleSSO = 'flow_google_sso_2022_07_21',
    CompanyAlreadyHasTeamflowShown = 'company_already_has_teamflow_shown_2022_07_21',
    JoinAsGuestCompanyAlreadyHasTF = 'join_as_guest_company_already_has_tf_2022_07_21',
    ResendEmailCompanyAlreadyHasTF = 'resend_email_company_already_has_tf_2022_07_21',
    ContactSupportCompanyAlreadyHasTF = 'contact_support_company_already_has_tf_2022_07_21',
    CreatedNewOrg = 'created_new_org_2022_07_21',
    SkipInvite = 'skip_invite_clicked_2022_07_21',
    InvitesSent = 'invites_sent_2022_07_21',
    AVScreenShown = 'av_screen_shown_2022_07_21',
    AVHelpClicked = 'av_help_clicked_2022_07_21',
    PaymentFormShown = 'payment_form_shown_2022_07_21',
    PaymentSubmitted = 'payment_form_submitted_2022_07_21',
    DownloadScreenShown = 'download_screen_shown_2022_07_21',
    DownloadButtonClicked = 'download_button_clicked_2022_07_21',
    DownloadScreenOpenedApp = 'download_screen_opened_app_2022_08_02',
    NoThanksUseBrowser = 'no_thanks_use_browser_2022_07_21',
    InviteScreenShown = 'invite_screen_shown_2022_07_21',
    SetUpNewOrgShown = 'set_up_new_org_shown_2022_07_21',
    SetupRoomScreenShown = 'setup_room_screen_shown_2022_07_21',
    JoinLinkRestOfTeamEmail = 'join_link_from_rest_of_team_email_2022_07_21',
    InviteCreditsModalSkip = 'invite_credits_modal_skip',
    InviteCreditsModalConfirm = 'invite_credits_modal_confirm',
    InviteForCredit = 'invite-for-credit',
    InviteForCreditStart = 'invite-for-credit-start',
    CalendarCreditShow = 'calendar-credit-show',
    CalendarCreditAccept = 'calendar-credit-accept',
    DownloadScreenSkipped = 'verse_download_organization_is_salesfloor_skipped_app_download_2022_11_20',
}

export enum TimeTracking {
    JoinedRoomCall = 'time_tracking_joined_room_call',
    LeftRoomCall = 'time_tracking_left_room_call',
}

export enum TimeTrackingEvents {
    EnterMainFloor = 'enter_main_floor',
    EnterRoom = 'enter_room',
    RejoinCall = 'rejoin_call',
    ExitMainFloor = 'exit_main_floor',
    ExitRoom = 'exit_room',
    HangUp = 'hang_up',
}

const trackOnceMap: { [key: string]: boolean } = {}
trackOnceMap[USER_REFRESHED] = false
trackOnceMap[LoadingFunnel.HitDotDotDotOrg] = false
trackOnceMap[LoadingFunnel.HitVerseWrapper] = false
trackOnceMap[LoadingFunnel.HitGuestLogin] = false
trackOnceMap[LoadingFunnel.HitGuestKnocking] = false
trackOnceMap[LoadingFunnel.HitAccessDenied] = false
trackOnceMap[LoadingFunnel.GotAccessResponse] = false
trackOnceMap[LoadingFunnel.FinishedPotentialRefetchUser] = false
trackOnceMap[LoadingFunnel.VerseDynamicLoadingInitiated] = false
trackOnceMap[LoadingFunnel.CreateVerseCalled] = false
trackOnceMap[LoadingFunnel.RightBeforeRealtimeConnected] = false
trackOnceMap[LoadingFunnel.ConnectedToRealtime] = false
trackOnceMap[LoadingFunnel.WaitingForRoomJoinOrError] = false
trackOnceMap[LoadingFunnel.RoomJoinOrErrorComplete] = false
trackOnceMap[LoadingFunnel.CreateVerseDone] = false
trackOnceMap[LoadingFunnel.UseInitVerseCalled] = false
trackOnceMap[LoadingFunnel.ConnectedToDailyCall] = false
trackOnceMap[LoadingFunnel.LoadedFloorOrRoom] = false
trackOnceMap[LoadingFunnel.ParticipantReady] = false
trackOnceMap[LoadingFunnel.LoadingScreenRemoved] = false
trackOnceMap[Signup.InviteScreenShown] = false
trackOnceMap[Signup.SetupRoomScreenShown] = false
trackOnceMap[Signup.SetUpNewOrgShown] = false
trackOnceMap[Signup.CompanyAlreadyHasTeamflowShown] = false
trackOnceMap[Signup.AVScreenShown] = false
trackOnceMap[Signup.PaymentFormShown] = false
trackOnceMap[Signup.DownloadScreenShown] = false
trackOnceMap[Signup.JoinLinkRestOfTeamEmail] = false

export function eventWithFlow(event: string, flow?: string): string {
    return flow ? `${flow}_${event}` : event
}

// used to filter norfang's events
let email: string | undefined

function isUserNorfangBot(): boolean {
    return email ? email?.includes(NORFANG_BOTS_EMAIL_DOMAIN) : false
}

export function identify(
    userId: string,
    userAttributes?: UserAnalyticsTraits
): void {
    email = userAttributes?.email as string
    // do not send norfang's bots identify
    if (isUserNorfangBot()) {
        return
    }
    window.analytics?.identify(userId, userAttributes)
}

export function page(pageName: string): void {
    window.analytics?.page(pageName)
}

type TrackCallback = (eventName: string, eventPayload?: TrackPayload) => void
const trackCallbacks: TrackCallback[] = []
export function registerTrackEvent(callback: TrackCallback) {
    trackCallbacks.push(callback)

    return () => {
        const idx = trackCallbacks.findIndex((cb) => cb === callback)
        if (idx < 0) {
            return
        }

        trackCallbacks.splice(idx, 1)
    }
}

// tracks events across a user's session, if they reload, new session
// added for data science team to make their lives easier aggregating
const session_id = generateShortUuid()
export const track: ITrackFunc = function track(
    eventName: string,
    eventPayload?: TrackPayload,
    integrations: object = {}
): void {
    if (typeof window === 'undefined') {
        // don't send events during ssr
        return
    }

    // For e2e testing purposes
    if ('tfAnalyticsTracker' in window) {
        window.tfAnalyticsTracker?.events.push({
            eventName,
            eventPayload,
            integrations,
        })
    }

    /**
     * The following code is a sanity check for our reload rate
     */
    // USER_REFRESHED should always come after dotdotdotorg was hit
    if (
        eventName === USER_REFRESHED &&
        !trackOnceMap[LoadingFunnel.HitDotDotDotOrg] &&
        !window.location.pathname.includes('pointers-overlay-window')
    ) {
        Sentry.captureException(
            new Error(`USER_REFRESHED without hitdotdotdotorg`),
            {
                tags: {
                    informative_error: true,
                },
            }
        )
    }
    /**
     * End of reload rate sanity check
     */

    /*
     * Sometimes the track function is being called from a useEffect, producing
     * multiple calls for the same event
     */
    if (eventName in trackOnceMap && trackOnceMap[eventName]) {
        return
    } else if (eventName in trackOnceMap) {
        trackOnceMap[eventName] = true
    }

    for (const callback of trackCallbacks) {
        try {
            callback(eventName, eventPayload)
        } catch (err) {
            LogManager.global.error({
                action: 'analytics@track',
                error: err,
            })
        }
    }

    if (process.env.DEPLOY_ENV !== 'production') {
        LogManager.global.info({
            action: 'analytics@track',
            eventName,
            eventPayload,
            integrations,
        })
    }

    // do not send norfang's bots events
    if (isUserNorfangBot()) {
        return
    }

    const integrationDefaults = { Slack: false }

    const payload = eventPayload ?? {}
    payload.session_id = session_id
    payload.account_id = rootStore.users.globalUser?._id
    payload.user_org_id = rootStore.users.localUserId
    payload.org_id = rootStore.organization.data?._id

    window.analytics?.track(
        eventName,
        {
            ...payload,
            sha: process.env.NEXT_PUBLIC_COMMIT_SHA,
        },
        merge({}, integrationDefaults, integrations)
    )
}
