import { useCallback, useEffect, useState } from 'react'

import { useEvent } from './useEvent'

export function useLive<S, V>(
    reducer: (arg: S) => V,
    event: string | string[],
    object: S,
    deps?: any[]
): V

export function useLive<S, V>(
    reducer: (arg?: S) => V | undefined,
    event: string | string[],
    object?: S,
    deps?: any[]
): V

export function useLive<S, V>(
    reducer: (arg?: S) => V,
    event: string | string[],
    object?: S,
    deps?: any[]
) {
    deps = [object, ...(deps || [])]

    const [value, setValue] = useState(() => reducer(object))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const reducerCb = useCallback(() => setValue(reducer(object)), deps)

    // Update with deps change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(reducerCb, deps)

    useEvent(reducerCb, event, object as any, deps)

    return value
}
