import * as t from '@teamflow/types'

type Payload = {
    presenterId: string
    locationId: string
    appId: string
}

export default class PresentationStartCommand
    implements t.ICommand<Partial<t.IPresentation>>
{
    readonly messageType = t.ClientMessage.PresentationStart

    private payload: Payload

    constructor(payload: Payload) {
        this.payload = payload
    }

    execute() {
        return this.payload
    }
}
