import { LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export default class ParticipantReadyCommand
    implements t.ICertifiedCommand<t.IParticipantReady>
{
    readonly messageType = t.ClientMessage.ParticipantReady
    readonly certified = true

    constructor(private ready = true) {}

    execute() {
        LogManager.global.debug(
            `ParticipantReadyCommand.execute ready = ${this.ready}`
        )
        return { ready: this.ready }
    }

    localExecute(_state: t.IVerseState, userId: string) {
        rootStore.participants
            .findParticipantById(userId)
            ?.update({ ready: this.ready })
    }
}
