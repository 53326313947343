import { AVATAR_RADIUS } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import Events from '../../events'

import { Connect, Substate, SubstateConnector } from './StateConnector'
import { putOrRemoveFromHash } from './helpers'

type SpeakerCircles = Substate<'roomSubstates'>['speakerCircles']

const putSpeakerCircleInHash = (item: t.ISpeakerCircle) => {
    rootStore.spatialHash.put(
        item.id,
        'speaker-circle',
        item.x - AVATAR_RADIUS,
        item.y - AVATAR_RADIUS,
        item.x + AVATAR_RADIUS,
        item.y + AVATAR_RADIUS
    )
}

/** @group Connectors */
@Connect({ key: 'roomSubstates', substate: 'speakerCircles' })
export class SpeakerCircleConnector extends SubstateConnector<
    'roomSubstates',
    'speakerCircles'
> {
    onAttach(speakerCircles: SpeakerCircles): void {
        speakerCircles.onAdd = (item) => {
            putOrRemoveFromHash(item, putSpeakerCircleInHash)

            item.onChange = () => {
                putOrRemoveFromHash(item, putSpeakerCircleInHash)

                this.sharedState.addSpeakerCircle(item)

                if (item.locationId === rootStore.commons.viewingSpace)
                    this.throttledEmitter.emit(
                        Events.SpeakerCircleChanged,
                        item.id,
                        item
                    )
            }

            this.sharedState.addSpeakerCircle(item)
            if (item.locationId === rootStore.commons.viewingSpace)
                this.events.emit(Events.SpeakerCircleAdded, item)
        }

        speakerCircles.onRemove = (speakerCircle) => {
            rootStore.spatialHash.delete(speakerCircle.id)

            this.sharedState.removeSpeakerCircle(speakerCircle)
            if (speakerCircle.locationId === rootStore.commons.viewingSpace)
                this.events.emit(Events.SpeakerCircleRemoved, speakerCircle)
        }
    }

    onDetach(speakerCircles: SpeakerCircles) {
        speakerCircles.onAdd = undefined
        speakerCircles.onRemove = undefined
    }

    onSwitchSpace(speakerCircles: SpeakerCircles) {
        const currentSpace = rootStore.commons.viewingSpace

        // Populate speaker circles
        for (const speakerCircle of speakerCircles)
            if (speakerCircle.locationId === currentSpace)
                putSpeakerCircleInHash(speakerCircle)
    }
}
