import * as t from '@teamflow/types'

type Data = t.PayloadWithLocationId<Partial<t.IAudioZone>>

export default class AudioZoneCommand implements t.ICommand<Data> {
    readonly messageType = t.ClientMessage.AudioZone

    private data: Data

    // locationId required since this is a room substate command
    constructor(data: Data) {
        this.data = data
    }

    execute() {
        return this.data
    }

    // This does not (and should not) have a localExecute to update the local RT state, for reasoning see comment inside the localExecute of SaveChangeCommand
}
