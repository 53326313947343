import { convertDateObjects } from '@teamflow/lib'
import type {
    CreateOrgReqBody,
    ExperimentsCreditsKey,
    IFrontendOrganization,
    InviteType,
    TeamflowRestApiSpecification as ApiSpec,
} from '@teamflow/types'

import { createRequest, request, retry, saveAccessToken } from '../request'

type OrganizationFilter =
    | {
          orgName: string
      }
    | {
          orgId: string
      }

const get = async (args: OrganizationFilter & { creatorEmail?: true }) => {
    const filter = 'orgId' in args ? args.orgId : args.orgName
    const query = new URLSearchParams({
        ...('orgId' in args ? { type: 'id' } : undefined),
        ...('creatorEmail' in args && args.creatorEmail
            ? { creatorEmail: 'true' }
            : undefined),
    }).toString()
    const path = `/api/organization/${filter}${query ? '?' + query : ''}`

    const response = await request<'GET /api/organization/:orgName'>(
        'GET',
        path
    )
    if (response.data?.organization) {
        response.data.organization = convertDateObjects(
            response.data.organization
        )
    }
    return response
}

const exists = ({ orgName }: { orgName: string }) =>
    request<'GET /api/organization/:orgName/exists'>(
        'GET',
        `/api/organization/${orgName}/exists`
    )

const patch = async ({
    orgId,
    updates,
}: {
    orgId: string
    updates: Partial<IFrontendOrganization>
}) => {
    const response = await request<'PATCH /api/organization/:id'>(
        'PATCH',
        `/api/organization/${orgId}`,
        {
            body: updates,
        }
    )
    if (response.data) {
        response.data = convertDateObjects(response.data)
    }
    return response
}

const searchByName = ({ orgName }: { orgName: string }) =>
    request<'GET /api/organization?name'>(
        'GET',
        `/api/organization?name=${orgName}`
    )

const regenInviteLink = ({ orgSlug }: { orgSlug: string }) =>
    request<'POST /api/organization/:orgName/regenerateInviteCode'>(
        'POST',
        `/api/organization/${orgSlug}/regenerateInviteCode`
    )

const tier = {
    get: ({ orgName, apiKey }: { orgName: string; apiKey?: string }) =>
        request<'GET /api/organization/:orgName/tierState'>(
            'GET',
            `/api/organization/${orgName}/tierState`,
            {
                headers: {
                    'x-api-key': apiKey,
                },
            }
        ),
    extendTrial: ({ orgId, length }: { orgId: string; length: number }) =>
        request<'PATCH /api/organization/:id/tierState/extendTrial'>(
            'PATCH',
            `/api/organization/${orgId}/tierState/extendTrial`,
            {
                body: { length },
            }
        ),
    downgrade: ({ orgId, tier = 'free' }: { orgId: string; tier: 'free' }) =>
        request<'PATCH /api/organization/:id/tierState/downgrade'>(
            'PATCH',
            `/api/organization/${orgId}/tierState/downgrade?tier=${tier}`
        ),
    upgrade: ({ orgId, tier = 'seed' }: { orgId: string; tier: string }) =>
        request<'PATCH /api/organization/:id/tierState/upgrade'>(
            'PATCH',
            `/api/organization/${orgId}/tierState/upgrade?tier=${tier}`
        ),
}

const calls = {
    /** Don't use in real app. Will delete daily calls. Safeguarded on API side. */
    clear: ({ adminApiKey, orgId }: { adminApiKey: string; orgId: string }) =>
        request<'DELETE /api/organization/:id/calls'>(
            'DELETE',
            `/api/organization/${orgId}/calls`,
            {
                headers: {
                    'x-api-key': adminApiKey,
                },
            }
        ),
}

const catalog = {
    getFurniture: ({
        category,
        orgId,
    }: ApiSpec['GET /api/organization/catalog/furniture']['request']['query']) => {
        const params = new URLSearchParams()
        if (orgId) params.append('orgId', orgId)
        if (category) params.append('category', category)
        return request<'GET /api/organization/catalog/furniture'>(
            'GET',
            `/api/organization/catalog/furniture?${params.toString()}`
        )
    },
    getByVendor: ({
        vendorId,
    }: ApiSpec['GET /api/organization/catalog/vendor']['request']['query']) =>
        request<'GET /api/organization/catalog/vendor'>(
            'GET',
            `/api/organization/catalog/vendor?vendorId=${vendorId}`
        ),
    getModel: ({ modelId }: { modelId: string }) =>
        request<'GET /api/organization/catalog/model'>(
            'GET',
            `/api/organization/catalog/model?modelId=${modelId}`
        ),
    getAsset: ({
        id,
        orgId,
    }: ApiSpec['GET /api/organization/catalog/asset']['request']['query']) =>
        request<'GET /api/organization/catalog/asset'>(
            'GET',
            `/api/organization/catalog/asset?orgId=${orgId}&id=${id}`
        ),
    add: ({
        orgId,
        item,
    }: {
        orgId: string
        item: ApiSpec['PATCH /api/organization/catalog/add']['request']['body']
    }) =>
        request<'PATCH /api/organization/catalog/add'>(
            'PATCH',
            `/api/organization/catalog/add?orgId=${orgId}`,
            {
                body: item,
            }
        ),
    remove: ({ orgId, modelId }: { orgId: string; modelId: string }) =>
        request<'PATCH /api/organization/catalog/remove'>(
            'PATCH',
            `/api/organization/catalog/remove?orgId=${orgId}&modelId=${modelId}`
        ),
}

const furniture = {
    create: createRequest<'POST /api/organization/furniture'>(
        'POST',
        '/api/organization/furniture'
    ),
    update: ({
        model,
    }: {
        model: ApiSpec['PUT /api/organization/furniture']['request']['body']
    }) =>
        request<'PUT /api/organization/furniture'>(
            'PUT',
            `/api/organization/furniture/${model._id}`,
            {
                body: model,
            }
        ),
    delete: ({ modelId }: { modelId: string }) =>
        request<'DELETE /api/organization/furniture'>(
            'DELETE',
            `/api/organization/furniture/${modelId}`
        ),
}

const deleteCustomBackgrounds = async ({
    orgId,
    url,
}: {
    orgId: string
    url: string
}) => {
    const response =
        await request<'PATCH /api/organization/deleteCustomBackgrounds/:id'>(
            'PATCH',
            `/api/organization/deleteCustomBackgrounds/${orgId}`,
            {
                body: { url },
            }
        )
    if (response.data) {
        response.data = convertDateObjects(response.data)
    }
    return response
}

const getOrganizationUsers = ({ orgId }: { orgId: string }) =>
    request<'GET /api/organization/:id/users'>(
        'GET',
        `/api/organization/${orgId}/users`
    )

const users = createRequest<'GET /api/organization/users'>(
    'GET',
    `/api/organization/users`
)

const user = ({ id }: { id: string }) =>
    retry(
        () =>
            request<'GET /api/organization/user/:id'>(
                'GET',
                `/api/organization/user/${id}`
            ),
        {
            handleAndValidateResponse: (res) => !!res.data,
        }
    )

const create = async (body: CreateOrgReqBody) => {
    const request = createRequest<'POST /api/organization'>(
        'POST',
        '/api/organization'
    )
    const response = await request(body)
    if (response.data?.org) {
        response.data.org = convertDateObjects(response.data.org)
    }
    return response
}

const floorPermissions = ({ orgName }: { orgName: string }) =>
    request<'GET /api/organization/:orgName/floor/permissions'>(
        'GET',
        `/api/organization/${orgName}/floor/permissions`
    ).then(saveAccessToken('idToken'))

const addRoom = ({
    orgId,
    body,
}: {
    orgId: string
    body?: ApiSpec['POST /api/organization/:id/addRoom']['request']['body']
}) =>
    request<'POST /api/organization/:id/addRoom'>(
        'POST',
        `/api/organization/${orgId}/addRoom`,
        { body: body || {} }
    )

const roomSessions = ({
    orgName,
    apiKey,
}: {
    orgName: string
    apiKey: string
}) =>
    request<'GET /api/organization/:id/roomSessions'>(
        'GET',
        `/api/organization/${orgName}/roomSessions`,
        {
            headers: {
                'x-api-key': apiKey,
            },
        }
    )

const rename = async ({
    orgId,
    newName,
}: {
    orgId: string
    newName: string
}) => {
    const response = await request<'PATCH /api/organization/:id/rename'>(
        'PATCH',
        `/api/organization/${orgId}/rename`,
        { body: { name: newName } }
    )
    if (response.data) {
        response.data = convertDateObjects(response.data)
    }
    return response
}

const rooms = ({ orgId }: { orgId: string }) =>
    request<'GET /api/organization/:id/rooms'>(
        'GET',
        `/api/organization/${orgId}/rooms`
    )

const credits = {
    get: ({ orgId }: { orgId: string }) =>
        request<'GET /api/organization/:id/credits'>(
            'GET',
            `/api/organization/${orgId}/credits`
        ),
    award: ({ orgId, key }: { orgId: string; key: ExperimentsCreditsKey }) =>
        request<'POST /api/organization/:id/credits'>(
            'POST',
            `/api/organization/${orgId}/credits`,
            {
                body: {
                    key,
                },
            }
        ),
}

const addTags = ({ orgId, tags }: { orgId: string; tags: string[] }) =>
    request<'POST /api/organization/:id/tags'>(
        'POST',
        `/api/organization/${orgId}/tags`,
        {
            body: { tags },
        }
    )

const privateOffice = ({ orgId }: { orgId: string }) =>
    request<'GET /api/organization/:id/privateOffice'>(
        'GET',
        `/api/organization/${orgId}/privateOffice`
    )

const copyRooms = ({
    srcOrgId,
    destOrgId,
}: {
    srcOrgId: string
    destOrgId: string
}) =>
    request<'PATCH /api/organization/rooms/:srcOrgId/to/:destOrgId'>(
        'PATCH',
        `/api/organization/rooms/${srcOrgId}/to/${destOrgId}`
    )

const invitations = ({
    orgId,
    inviteType,
}: {
    orgId: string
    inviteType?: InviteType
}) =>
    request<'GET /api/organization/:id/invitations?inviteType'>(
        'GET',
        `/api/organization/${orgId}/invitations` +
            (inviteType ? `?inviteType=${inviteType}` : '')
    )

const setOwner = ({ orgId, userId }: { orgId: string; userId: string }) =>
    request<'PATCH /api/organization/:id/owner'>(
        'PATCH',
        `/api/organization/${orgId}/owner`,
        {
            body: {
                newOwnerId: userId,
            },
        }
    )

const setOwnerAdmin = ({ orgId, userId }: { orgId: string; userId: string }) =>
    request<'PATCH /api/organization/:id/owner/admin'>(
        'PATCH',
        `/api/organization/${orgId}/owner/admin`,
        {
            body: {
                newOwnerId: userId,
            },
        }
    )

const integration = {
    justcall: {
        get: ({ orgId }: { orgId: string }) =>
            request<'GET /api/organization/:id/integration/justcall'>(
                'GET',
                `/api/organization/${orgId}/integration/justcall`
            ),
        create: ({
            orgId,
            apiKey,
            apiSecret,
        }: {
            orgId: string
            apiKey: string
            apiSecret: string
        }) =>
            request<'POST /api/organization/:id/integration/justcall'>(
                'POST',
                `/api/organization/${orgId}/integration/justcall`,
                {
                    body: {
                        apiKey,
                        apiSecret,
                    },
                }
            ),
        update: ({
            orgId,
            type,
        }: {
            orgId: string
            type: 'add-user' | 'remove-user'
        }) =>
            request<'PUT /api/organization/:id/integration/justcall'>(
                'PUT',
                `/api/organization/${orgId}/integration/justcall`,
                {
                    body: {
                        type,
                    },
                }
            ),
    },
    aircall: {
        addVerificationToken: ({
            orgId,
            token,
        }: {
            orgId: string
            token: string
        }) =>
            request<'PUT /api/organization/:id/integration/aircall/verification-token'>(
                'PUT',
                `/api/organization/${orgId}/integration/aircall/verification-token`,
                {
                    body: {
                        token,
                    },
                }
            ),
    },
}

export default {
    get,
    exists,
    patch,
    searchByName,
    regenInviteLink,
    tier,
    calls,
    catalog,
    furniture,
    deleteCustomBackgrounds,
    getOrganizationUsers,
    user,
    users,
    create,
    floorPermissions,
    addRoom,
    roomSessions,
    rename,
    rooms,
    credits,
    addTags,
    privateOffice,
    copyRooms,
    invitations,
    setOwner,
    setOwnerAdmin,
    integration,
}
