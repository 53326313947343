import * as Sentry from '@sentry/react'

import { RealtimeServiceGlobals } from '@teamflow/bootstrap'
import type { IEventRemover, TaskRunner } from '@teamflow/lib'
import {
    castRoomSessionToRoom,
    generateShortUuid,
    LogManager,
    randomBetween,
    REALTIME_DISCONNECTED,
    REALTIME_REJOIN_ERROR,
    REALTIME_REJOINED,
    StateMachine,
    waitForMilliseconds,
    waitForSeconds,
} from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'
import {
    ConnectionDroppedCode,
    ConnectionState,
    DeploymentState,
    Feature,
    IFeatureFlagService,
    ILogger,
    IParticipantSchema_Global,
    RemoteConfig,
    Role,
} from '@teamflow/types'

import { RealtimeStateContainer } from './RealtimeStateContainer'
import ParticipantReadyCommand from './commands/ParticipantReadyCommand'
import PingCommand from './commands/PingCommand'
import { CustomColyseusClient } from './customColyseusClient'
import Events from './events'
import { ConnectionError, ConnectionTransition } from './states'
import { Connected } from './states/Connected'
import CreateAndJoinRoom from './states/CreateAndJoinRoom'
import Disconnect from './states/Disconnect'
import Dropped from './states/Dropped'
import FailedToConnect from './states/FailedToConnect'
import FindExistingRoom from './states/FindExistingRoom'
import JoinRoom from './states/JoinRoom'
import Reconnect from './states/Reconnect'
import SendCertifiedClientMessage from './tasks/SendCertifiedClientMessage'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { presentationModule } from './modules/presentations'

const MAX_DELAY_MS = 10000
const INITIAL_DELAY_MS = 100
const DELAY_MULTIPLIER = 1.5
const MAX_RECONNECT_TRIES = 10

const logger = LogManager.createLogger('RealtimeService', { critical: true })

function isStateDisconnected(name?: ConnectionState) {
    switch (name) {
        case ConnectionState.Disconnected:
        case ConnectionState.Dropped:
        case ConnectionState.Disconnect:
            return true

        default:
            return false
    }
}

function isStateConnecting(name?: ConnectionState) {
    switch (name) {
        case ConnectionState.Reconnect:
        case ConnectionState.Connecting:
        case ConnectionState.CreateAndJoinRoom:
        case ConnectionState.FindExistingRooms:
        case ConnectionState.JoinRoom:
            return true

        default:
            return false
    }
}

/**
 * The realtime service is the multiplayer client modeled as a {@link IStateMachine}.
 *
 * It integrates the world state into the rest of the system. To learn more, see the {@link multiplayer} wiki.
 *
 * ## Connection States
 *
 * | State                        | Brief                                                                              |
 * |------------------------------|------------------------------------------------------------------------------------|
 * | Inactive                     | The default state of the {@link RealtimeService}                                   |
 * | Disconnected                 | Rest state of disconnection. See {@link ConnectionWatcherService}.                 |
 * | {@link FindExistingRoom}     | Transient state while connecting which finds the Colyseus room for the workspace.  |
 * | {@link CreateAndJoinRoom}    | Transient state that creates the Colyseus room and joins it.                       |
 * | {@link JoinRoom}             | Transient state that connects into the workspace's Colyseus room when already made.|
 * | {@link Connected}            | Rest state while connected − subscribing, propagating, and publishing state.       |
 * | {@link FailedToConnect}      | Transient state when any step in the connection process fails. Goes to disconnected|
 * | {@link Dropped}              | Transient state for when the socket closes unexpectedly. Goes to disconnected.     |
 * | {@link Reconnect}            | Transient state that {@link reconnect} invokes to recover from a disconnection.    |
 *
 * ```mermaid
 * stateDiagram-v2
 *     [*] --> Inactive
 *     Inactive          --> FindExistingRooms : Connect
 *     Disconnected      --> FindExistingRooms : Connect
 *     Reconnect         --> FindExistingRooms : Connect
 *     FindExistingRooms --> JoinRoom          : Connect
 *     FindExistingRooms --> CreateAndJoinRoom : CreateRoom
 *     CreateAndJoinRoom --> FailedToConnect   : Failed
 *     JoinRoom          --> FailedToConnect   : Failed
 *     JoinRoom          --> Connected         : Connected
 *     CreateAndJoinRoom --> Connected         : Connected
 *     Reconnect         --> Connected         : Connected
 *     Disconnected      --> Reconnect         : Reconnect
 *     Connected         --> Disconnected      : Disconnect
 *     FailedToConnect   --> Disconnected      : Disconnect
 *     Connected         --> Dropped           : Dropped
 *     Dropped           --> Disconnected      : Disconnected
 * ```
 *
 * The implementation of {@link IVerseState} is present in the {@link Connected} state.
 */
export class RealtimeService
    extends RealtimeStateContainer
    implements t.IRealtimeService
{
    protected readonly flags: IFeatureFlagService
    protected readonly tasks: TaskRunner

    protected client: CustomColyseusClient
    protected userOrgId?: string
    protected role?: t.Role
    protected orgId?: string
    protected activeOrgName?: string
    protected lastMessageSent = 0
    protected roomName = 'huddle'
    protected _hasReceivedInitialState = false

    /**
     * This is the retry config to be used with the SendCertifiedClientMessage task.
     * - The first element of the tuple is the number of retries allowed
     * - The second element is an array with all the retry intervals. If the size of the array is less than the number
     *    of retries, the last element of the array is going to be used for all retries after N, where N is the number of retries.
     * - The third and last element is a boolean in case we want to enable jitter
     */
    protected messageRetryConfig: [number, [number, ...number[]], boolean] = [
        5,
        [2000, 3000, 4500, 6000],
        false,
    ]
    protected isMessageRetryConfigUpdated = false

    protected subscriptions: IEventRemover[] = []

    protected windowCloseHandler?: (evt: Event) => void

    protected simulatingDisconnect = false

    protected readonly connectionMachine = new StateMachine<
        ConnectionState,
        ConnectionTransition
    >('realtime connection', logger, (state: ConnectionState) => {
        rootStore.commons.updateConnectionState(state)
    })
    private reconnectAttempt: Promise<boolean> | null = null

    constructor(
        flags: IFeatureFlagService,
        protected readonly analytics: t.IAnalytics,
        tasks: TaskRunner
    ) {
        super()

        this.flags = flags
        this.tasks = tasks

        this.client = new CustomColyseusClient(
            RealtimeServiceGlobals.wsEndpoint ||
                (process.env.NEXT_PUBLIC_RT_WS_ENDPOINT as string),
            flags,
            () => {
                // Disconnect transition with consented=false will result in
                // an attempt to soft reconnect
                return this.connectionMachine.runTransition(
                    ConnectionTransition.Disconnect,
                    {
                        consented: false,
                        code: ConnectionDroppedCode.TFNoHeartbeat,
                        lostHeartbeat: true,
                    }
                )
            }
        )

        this.connectionMachine
            .addState(ConnectionState.Inactive)
            .addState(ConnectionState.Disconnected, {
                onEnter: (
                    _machine,
                    data: {
                        consented?: boolean
                        code: ConnectionDroppedCode
                        deployment?: boolean
                    }
                ) => {
                    this.events.emit(Events.OnDisconnect, data)
                },
            })
            .addState(
                ConnectionState.FindExistingRooms,
                new FindExistingRoom(this.client, logger)
            )
            .addState(ConnectionState.JoinRoom, new JoinRoom(this.client))
            .addState(
                ConnectionState.CreateAndJoinRoom,
                new CreateAndJoinRoom(this.client, logger)
            )
            .addState(ConnectionState.Reconnect, new Reconnect(this.client))
            .addState(
                ConnectionState.Connected,
                new Connected(
                    this,
                    logger,
                    this.sharedState,
                    this.events,
                    this.flags,
                    this.analytics
                )
            )
            .addState(
                ConnectionState.FailedToConnect,
                new FailedToConnect(logger, this.analytics)
            )
            .addState(
                ConnectionState.Disconnect,
                new Disconnect(this.sharedState)
            )
            .addState(
                ConnectionState.Dropped,
                new Dropped(this.sharedState, this.events, logger)
            )
            .addTransition(
                ConnectionTransition.Connect,
                ConnectionState.Inactive,
                ConnectionState.FindExistingRooms
            )
            .addTransition(
                ConnectionTransition.Connect,
                ConnectionState.Disconnected,
                ConnectionState.FindExistingRooms
            )
            .addTransition(
                ConnectionTransition.Connect,
                ConnectionState.Reconnect,
                ConnectionState.FindExistingRooms
            )
            // when tried to create but someone else already did it
            .addTransition(
                ConnectionTransition.Connect,
                ConnectionState.CreateAndJoinRoom,
                ConnectionState.FindExistingRooms
            )
            .addTransition(
                ConnectionTransition.JoinRoom,
                ConnectionState.FindExistingRooms,
                ConnectionState.JoinRoom
            )
            .addTransition(
                ConnectionTransition.CreateRoom,
                ConnectionState.FindExistingRooms,
                ConnectionState.CreateAndJoinRoom
            )
            .addTransition(
                ConnectionTransition.Failed,
                ConnectionState.JoinRoom,
                ConnectionState.FailedToConnect
            )
            .addTransition(
                ConnectionTransition.Failed,
                ConnectionState.CreateAndJoinRoom,
                ConnectionState.FailedToConnect
            )
            .addTransition(
                ConnectionTransition.Failed,
                ConnectionState.FindExistingRooms,
                ConnectionState.FailedToConnect
            )
            .addTransition(
                ConnectionTransition.Reconnect,
                ConnectionState.Disconnected,
                ConnectionState.Reconnect
            )
            .addTransition(
                ConnectionTransition.Connected,
                ConnectionState.JoinRoom,
                ConnectionState.Connected
            )
            .addTransition(
                ConnectionTransition.Connected,
                ConnectionState.CreateAndJoinRoom,
                ConnectionState.Connected
            )
            .addTransition(
                ConnectionTransition.Connected,
                ConnectionState.Reconnect,
                ConnectionState.Connected
            )
            .addTransition(
                ConnectionTransition.Disconnect,
                ConnectionState.Connected,
                ConnectionState.Disconnect
            )
            .addTransition(
                ConnectionTransition.Disconnect,
                ConnectionState.FailedToConnect,
                ConnectionState.Disconnected
            )
            .addTransition(
                ConnectionTransition.Disconnected,
                ConnectionState.Disconnect,
                ConnectionState.Disconnected
            )
            .addTransition(
                ConnectionTransition.Dropped,
                ConnectionState.Connected,
                ConnectionState.Dropped
            )
            .addTransition(
                ConnectionTransition.Disconnected,
                ConnectionState.Dropped,
                ConnectionState.Disconnected
            )
            // NOTE: this is for simulated reconnects
            .addTransition(
                ConnectionTransition.Dropped,
                ConnectionState.Disconnected,
                ConnectionState.Dropped
            )
            .startWith(ConnectionState.Inactive)

        this.reconnect = this.reconnect.bind(this)
        this.reconnectWithRetry = this.reconnectWithRetry.bind(this)

        this.windowCloseHandler = this.handleWindowClose.bind(this)
        window.addEventListener('unload', this.windowCloseHandler, {
            once: true,
        })

        this.sharedState.onRoomChanged((room) => {
            if (!room) {
                this.events.emit(
                    Events.OnConnectionError,
                    new Error('missing room instance')
                )
                return
            }
            room.onLeave(async (code: t.ConnectionDroppedCode) => {
                await this.connectionMachine.runTransition(
                    ConnectionTransition.Dropped,
                    {
                        code,
                        deployment:
                            this.deployment.state === DeploymentState.Starting,
                    }
                )
            })
        })
    }

    get organizationName() {
        return this.activeOrgName
    }

    get userOrganizationId() {
        return this.userOrgId
    }

    get deployment() {
        const deployment = { ...this.sharedState.deployment }

        if (typeof deployment.state !== 'number')
            deployment.state = DeploymentState.None

        return deployment
    }

    get localParticipant() {
        if (!this.userOrgId) {
            return undefined
        }

        return this.getParticipant(this.userOrgId)
    }

    /**
     * The state of the connection to the server.
     */
    get connectionState(): ConnectionState {
        return this.connectionMachine.currentState ?? ConnectionState.Inactive
    }

    get activeEvent(): t.IActiveEvent {
        return (
            this.sharedState.activeRoom?.state.activeEvent ?? {
                active: false,
                attendees: 5,
            }
        )
    }

    get hasReceivedInitialState() {
        return this._hasReceivedInitialState
    }

    /**
     * Disconnect from the server and destroy this client. All event listeners are deregistered and the
     * state is destroyed.
     */
    destroy() {
        this.events.removeAllListeners()
        this.sharedState.destroy()
        this.subscriptions.forEach((sub) => sub.remove())

        if (this.windowCloseHandler) {
            window.removeEventListener('unload', this.windowCloseHandler)
        }

        logger.info('Realtime teardown & disconnected by destroy')
        this.analytics.track(REALTIME_DISCONNECTED, {
            cause: 'destroy',
        })
        void this.disconnect().then(() => {
            this.connectionMachine.destroy()
        })
    }

    async joinVerse(options: t.JoinOptions) {
        const {
            roomName = this.roomName,
            orgId,
            userOrgId,
            orgName,
            role,
            floorInviteCode,
            backgroundImage,
        } = options

        this.orgId = orgId
        this.userOrgId = userOrgId
        this.role = role
        this.activeOrgName = orgName
        this.lastMessageSent = Date.now()
        this.roomName = roomName

        this.sharedState.setFloorInviteCode(floorInviteCode)
        this.sharedState.setBackgroundImage(backgroundImage)

        if (!userOrgId || userOrgId.length < 11) {
            // we are getting some ids that are 9 characters long, which seems to be a dailyId
            // we want to figue out why this is happening
            Sentry.captureException(
                new Error(`No userOrgId passed to joinVerse`)
            )
        }

        try {
            await this.connectionMachine.runTransition(
                ConnectionTransition.Connect,
                options
            )
        } catch (err) {
            // reset the state machine to bring it back to a valid starting state
            await this.connectionMachine.reset()

            this.events.emit(Events.OnConnectionError, err)

            throw err
        }

        // this may happen if the transition above ended up in the FailedToConnect/Disconnect state
        if (this.connectionMachine.currentState !== ConnectionState.Connected) {
            const err = new ConnectionError(
                `Failed to connect to room: ${orgId}`
            )

            this.events.emit(Events.OnConnectionError, err)

            throw err
        } else {
            this._hasReceivedInitialState = false
            this.onceStateChanged(() => {
                this._hasReceivedInitialState = true
            })
        }

        return this
    }

    protected async handleWindowClose(_evt: Event) {
        this.analytics.track(REALTIME_DISCONNECTED, {
            cause: 'window_close',
        })
        await this.disconnect()
    }

    async simulateNetworkDisconnect() {
        this.simulatingDisconnect = true

        await this.connectionMachine.runTransition(
            ConnectionTransition.Dropped,
            {
                code: 2000,
                deployment: this.deployment.state === DeploymentState.Starting,
            }
        )
    }

    disconnect(simulate = false) {
        return this.connectionMachine.runTransition(
            ConnectionTransition.Disconnect,
            {
                consented: true,
                code: ConnectionDroppedCode.CloseNormal,
                simulate,
            }
        )
    }

    /**
     * Tries to reconnect to the socket server with finite retries, if not already in a "connecting" state. It
     * will update the "reconnecting" toast.
     *
     * The retries are done with an exponential backoff.
     */
    async reconnectWithRetry(options: t.ReconnectWithRetryOptions = {}) {
        if (!navigator.onLine) return false

        const slogger = logger.child({
            action: 'Realtime@Reconnect',
            maxRetries: options.maxRetries ?? MAX_RECONNECT_TRIES,
            sequence: generateShortUuid(),
        })

        if (isStateConnecting(this.connectionMachine.currentState)) {
            slogger.info('Paused until another attempt finishes')

            if (this.reconnectAttempt) await this.reconnectAttempt
            else await RealtimeService.untilReconnected(this)

            const didSucceed =
                this.connectionMachine.currentState ===
                ConnectionState.Connected

            if (didSucceed || options.maxRetries === 1) {
                slogger.info('No reconnect because another attempt resolved')
                return didSucceed
            }
        }

        rootStore.layout.updateReconnectToast({ reconnecting: true })
        let result = false

        try {
            this.reconnectAttempt = this.tryReconnectWithRetry(slogger, options)
            result = await this.reconnectAttempt
        } finally {
            rootStore.layout.updateReconnectToast({ reconnecting: false })
            this.reconnectAttempt = null
        }

        return result
    }

    /**
     * Tries to reconnect to the socket server once, if not already in a "connecting" state. It will update
     * the "reconnecting" toast.
     */
    async reconnect(options: t.ReconnectOptions = {}): Promise<boolean> {
        if (!navigator.onLine) return false

        const slogger = logger.child({
            action: 'Realtime@Reconnect',
            maxRetries: -1,
            sequence: generateShortUuid(),
        })

        if (isStateConnecting(this.connectionMachine.currentState)) {
            slogger.info('Paused until another attempt resolves')

            if (this.reconnectAttempt) await this.reconnectAttempt
            else await RealtimeService.untilReconnected(this)

            return (
                this.connectionMachine.currentState ===
                ConnectionState.Connected
            )
        }

        rootStore.layout.updateReconnectToast({ reconnecting: true })
        const result = await this.tryReconnect(slogger, options)
        rootStore.layout.updateReconnectToast({ reconnecting: false })
        return result
    }

    // reconnect without updating reconnect toast
    private async tryReconnectWithRetry(
        slogger: ILogger,
        options: t.ReconnectWithRetryOptions = {}
    ) {
        if (isStateConnecting(this.connectionMachine.currentState)) {
            return false
        }

        const { maxRetries = MAX_RECONNECT_TRIES } = options

        if (!isStateDisconnected(this.connectionMachine.currentState)) {
            try {
                slogger.info('Client is being disconnected')

                const disconnected = await this.disconnect()
                if (!disconnected) {
                    slogger.info('Client is not disconnected')
                    return false
                }
            } catch (e) {
                slogger.error('Error while disconnecting client', e)
                return false
            }
        }

        let delay = INITIAL_DELAY_MS
        let count = 0
        do {
            ++count

            let result: boolean | undefined = undefined
            try {
                slogger.info(`Client reconnection attempt #${count}`)
                result = await this.tryReconnect(slogger)
            } catch (e) {
                result = false
            }

            if (count > maxRetries) {
                slogger.info(
                    `Client failed to reconnect after maximum retries ${maxRetries}`
                )
                break
            }
            if (options.continue && !options.continue()) {
                slogger.info(
                    `Injected continuation function negative. Terminating reconnection attempts.`
                )
                break
            }
            if (!navigator.onLine) {
                slogger.info('Not online, terminating reconnect attempt')
                break
            }
            if (!result) {
                await waitForMilliseconds(delay)

                // slowly back off
                if (delay < MAX_DELAY_MS) {
                    delay *= DELAY_MULTIPLIER
                }
            }
        } while (
            this.connectionMachine.currentState !== ConnectionState.Connected
        )

        return this.connectionMachine.currentState === ConnectionState.Connected
    }

    // reconnect without updating reconnect toast
    private async tryReconnect(
        slogger: ILogger,
        options: t.ReconnectOptions = {}
    ) {
        if (isStateConnecting(this.connectionMachine.currentState)) {
            return false
        }

        if (!isStateDisconnected(this.connectionMachine.currentState)) {
            await this.connectionMachine.runTransition(
                ConnectionTransition.Disconnect,
                { consented: true, code: ConnectionDroppedCode.CloseNormal }
            )

            this.events.emit(Events.OnDisconnect, {
                consented: true,
            })
        }

        // NOTE: maybe this is a separate state
        if (this.simulatingDisconnect) {
            await waitForSeconds(randomBetween(1, 9))

            this.simulatingDisconnect = false
        }

        if (!(this.orgId && this.userOrgId)) return false

        const userParticipant = this.getParticipant(this.userOrgId)

        if (!userParticipant) return false

        const transitionOptions = {
            roomName: this.roomName,
            orgId: this.orgId,
            userOrgId: this.userOrgId,
            orgName: this.organizationName as string,
            roomId: rootStore.commons.avConnectedSpace,
            role: this.role ?? Role.GUEST,
            floorInviteCode: this.sharedState.floorInviteCode,
            backgroundImage: this.sharedState.backgroundImage,
            x: userParticipant?.x,
            y: userParticipant?.y,
            retrySoftReconnect: options.retrySoftReconnect ?? false,
            ready: !rootStore.settings.manuallyDisconnected, // force set ready = false if existing participant still in state!
        }

        await this.connectionMachine.runTransition(
            ConnectionTransition.Reconnect,
            transitionOptions
        )

        if (this.connectionMachine.currentState === ConnectionState.Connected) {
            // wait for initial state before continuing
            await new Promise((resolve) => {
                this.onceStateChanged(resolve)
            })

            this.analytics.track(REALTIME_REJOINED)

            this.events.emit(Events.OnReconnected)

            // fire this to make sure participant will be visible
            // to others
            slogger.info('RealtimeService: reconnect')
            if (!rootStore.commons.disconnected) {
                await this.dispatchCertified(new ParticipantReadyCommand())
            }

            return true
        }

        this.analytics.track(REALTIME_REJOIN_ERROR)

        return false
    }

    enableDeployMode(onParticipantLeft: t.ListenerRegistrationFn<string>) {
        const room = this.sharedState.activeRoom

        if (room) {
            if (this.connectionState !== ConnectionState.Disconnected) {
                throw new Error(
                    'deploy mode can only be enabled after disconnection'
                )
            }
        }

        onParticipantLeft((userId: string) => {
            // this is the internal userOrgId
            const participant =
                rootStore.participants.participantById.get(userId)
            if (!participant) {
                logger.error({
                    action: 'Realtime@onParticipantLeft',
                    message: `unable to find participant with id ${userId}`,
                    userId,
                })
                return
            }

            if (room) {
                const { participantsGlobal, roomSubstates, roomSessions } =
                    room.state
                // remove from local state

                // remove from main list
                let idx = participantsGlobal.findIndex(
                    (p) => p.id === participant.id
                )
                let participantGlobal: IParticipantSchema_Global | undefined
                if (idx >= 0) {
                    participantGlobal = participantsGlobal[idx]
                    participantsGlobal.splice(idx, 1)
                }

                // remove from room substate
                let participantRoomSpecific:
                    | t.IParticipantSchema_RoomSpecific
                    | undefined
                const substate = roomSubstates.get(participant.locationId)
                if (substate) {
                    participantRoomSpecific =
                        substate.participantsMapRoomSpecific.get(participant.id)
                    substate.participantsMapRoomSpecific.delete(participant.id)
                }

                // remove from roomSession if in one
                if (participant.locationId) {
                    const roomSession = roomSessions.find(
                        (session) => session._id === participant.locationId
                    )
                    if (roomSession) {
                        idx = roomSession.participantIds.findIndex(
                            (id) => id === participant.id
                        )
                        if (idx >= 0) {
                            roomSession.participantIds.splice(idx, 1)
                            this.events.emit(
                                Events.RoomChanged,
                                castRoomSessionToRoom(roomSession)
                            )
                        }
                    }
                }

                if (participantGlobal) {
                    room.state.participantsGlobal.onRemove?.(
                        participantGlobal,
                        idx
                    )
                }
                if (participantRoomSpecific && substate) {
                    substate.participantsMapRoomSpecific.onRemove?.(
                        participantRoomSpecific,
                        participantRoomSpecific.id
                    )
                }
            }

            this.events.emit(Events.OnLeft, participant)
        }).addTo(this.subscriptions)
    }

    ping() {
        const now = Date.now()

        if (now - this.lastMessageSent < 1000 * 60) return

        void this.dispatch(new PingCommand(Date.now()))
    }

    protected preDispatch<PayloadType>(
        command: t.ICommand<PayloadType> | t.ICertifiedCommand<PayloadType>
    ) {
        const room = this.sharedState.activeRoom
        if (!room) {
            logger.error(
                'realtime room is missing when trying to dispatch command'
            )
            throw new Error(
                'realtime room is missing when trying to dispatch command'
            )
        }

        if (command.localExecute && this.userOrgId) {
            // for operations that want to have data updated
            // immediately on the client
            const events =
                command.localExecute(room.state, this.userOrgId) ?? []

            // any events that should be emitted from the local execute
            events.forEach((event) => {
                const { name, payload } = event
                const args = Array.isArray(payload) ? payload : [payload]
                this.events.emit(name, ...args)
            })
        }
        const payload = command.execute()

        this.lastMessageSent = Date.now()

        return { payload, room }
    }

    /**
     * Will immediately send an uncertified message
     * @param command
     * @returns
     */
    dispatch<PayloadType>(command: t.ICommand<PayloadType>): void {
        const { payload, room } = this.preDispatch(command)
        room.send(command.messageType, payload)
    }

    /**
     * Will resolve successfully if message is sent and will wait for
     * completion from certified commands before resolving
     * @param command
     * @returns
     */
    async dispatchCertified<PayloadType>(
        command: t.ICertifiedCommand<PayloadType>
    ): Promise<void> {
        const { payload, room } = this.preDispatch(command)

        const retryConfig = await this.retrieveMessageRetryConfigFromFlagsmith()

        if (command.certified) {
            // send a certified message that requires
            // acknowledgement from server
            const task = new SendCertifiedClientMessage(
                this,
                room,
                command.messageType,
                payload,
                retryConfig[0],
                retryConfig[1],
                retryConfig[2]
            )

            this.tasks.run(task)

            return task.asyncResult()
        }

        room.send(command.messageType, payload)
    }

    // This code is similar (almost identical) to the one inside packages/server-realtime/src/rooms/Huddle.ts
    // However, this is the case now, in the future we may want a different behavior here.
    // For this reason it is not using shared function
    protected async retrieveMessageRetryConfigFromFlagsmith() {
        if (this.isMessageRetryConfigUpdated) return this.messageRetryConfig

        // grab retries amount
        let retries =
            (await this.flags.value(
                RemoteConfig.RealtimeMsgConfigClientRetries
            )) ?? this.messageRetryConfig[0]

        if (typeof retries !== 'number') {
            retries = parseInt(retries as string, 10)
            if (isNaN(retries)) {
                retries = this.messageRetryConfig[0] // defaults to the existing value
            }
        }
        this.messageRetryConfig[0] = retries

        // grab retry timeout config
        const configValue = await this.flags.value(
            RemoteConfig.RealtimeMsgConfigServerRetryInterval
        )
        const retryInterval = configValue.split(',')

        const retryTimeoutArray = retryInterval
            .map((n) => parseInt(n, 10))
            .filter((n) => !isNaN(n))

        this.messageRetryConfig[1] =
            retryTimeoutArray.length > 0
                ? (retryTimeoutArray as [number, ...number[]])
                : this.messageRetryConfig[1]

        // retry jitter
        this.messageRetryConfig[2] = await this.flags.isEnabled(
            Feature.RealtimeMsgConfigClientRetryJitter
        )

        this.isMessageRetryConfigUpdated = true

        return this.messageRetryConfig
    }

    private static untilReconnected(
        service: RealtimeService
    ): Promise<boolean> {
        return new Promise((resolve) => {
            service.connectionMachine.on(
                'transition',
                function onTransition(stateMachine) {
                    logger.info('Waiting for reconnection to finish')
                    if (!isStateConnecting(stateMachine.currentState)) {
                        stateMachine.off('transition', onTransition)
                        resolve(!isStateDisconnected(stateMachine.currentState))
                    }
                }
            )
        })
    }
}
