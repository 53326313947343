import Mousetrap from 'mousetrap'
import React from 'react'

import { track } from '@teamflow/bootstrap'
import { LogManager } from '@teamflow/lib'
import { Analytic, KeyboardShortcut } from '@teamflow/types'

import transformedShortcutForPlatform from '../helpers/transformedKeyboardShortcut'

type KeybindingHandler = (e: Partial<KeyboardEvent>, combo: string) => void

function addPreventDefault(handler: KeybindingHandler) {
    return function (...args: Parameters<typeof handler>) {
        args[0].preventDefault?.()
        return handler(...args)
    }
}

const DefaultOptions = {
    addPreventDefault: true,
}

const KEYBOARD_SHORTCUT_TO_LOG_REGEXP = /ctrl|alt|shift|opt|command/

export function useKeyBinding(
    binding?: KeyboardShortcut,
    handler?: KeybindingHandler,
    /**
     * Boolean to control if the keybinding should be enabled/disabled
     */
    bindCondition?: boolean,
    options: Partial<typeof DefaultOptions> = {}
) {
    options = { ...DefaultOptions, ...options }

    React.useEffect(() => {
        if (!binding || !handler || bindCondition === false) {
            return
        }

        const bindingTransformed = transformedShortcutForPlatform(binding)
        const finalHandler = options.addPreventDefault
            ? addPreventDefault(handler)
            : handler

        // we only want to log keyboard combinations
        const shouldLog =
            KEYBOARD_SHORTCUT_TO_LOG_REGEXP.test(bindingTransformed)
        Mousetrap.bind(bindingTransformed, (...args) => {
            if (shouldLog) {
                track(Analytic.KeyboardShortcutUsed, {
                    key: bindingTransformed,
                })

                LogManager.ui.info(
                    `Keyboard shorcut being triggered: ${bindingTransformed}`
                )
            }
            return finalHandler(...args)
        })

        return () => {
            Mousetrap.unbind(bindingTransformed)
        }
    }, [bindCondition, binding, handler, options.addPreventDefault])
}
