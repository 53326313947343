export default {
    orgCreated: 'orgCreated',
    guestInviteUrl: 'guestInviteUrl',
    searchUsers: 'searchUsers',
    foundUsers: 'foundUsers',
    selectedUserData: 'selectedUserData',
    userDeleted: 'userDeleted',
    userNotDeleted: 'userNotDeleted',
    applyUpdates: 'applyUpdates',
}
