export const formatPrice = (val: number) => {
    const options = {
        style: 'currency',
        currency: 'USD',
        // shows decimals if has remainder
        minimumFractionDigits: val % 100 === 0 ? 0 : 2,
        maximumFractionDigits: 2,
    }
    return `${(val / 100).toLocaleString('en-US', options)}`
}

export const formatDiscount = (percentOff?: number) => {
    if (!percentOff) {
        return '0%'
    }
    return `${percentOff * 100}%`
}

const FORMAT_TIME: [[], Intl.DateTimeFormatOptions] = [
    [],
    {
        minute: '2-digit',
        hour: 'numeric', // Remove leading zeros
    },
]
/**
 * - Remove leading 0s
 * - If it's on the clock (:00), remove the minutes
 * - No space between the time and AM / PM
 * - AM / PM in lowercase
 *
 * If it's not on the clock, it looks like 3:15pm (instead of 03:15 PM)
 */
export const formatTime = (date: Date | string) =>
    (typeof date === 'string' ? date : date.toLocaleTimeString(...FORMAT_TIME))

        // Don't include minutes if it's on the dot.
        .replace(/:00/g, '')

        // Lowercase AM, PM and remove leading space, if any.
        .replace(/[ ]*AM/g, 'am')
        .replace(/[ ]*PM/g, 'pm')
