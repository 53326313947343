import debounce from 'lodash/debounce'
import Mousetrap from 'mousetrap'
import { useEffect, useRef, MutableRefObject } from 'react'

import rootStore from '@teamflow/store'
import { KeyboardShortcut } from '@teamflow/types'

import { PUSH_TO_TALK_USED, track } from '../helpers/analytics'

const handleEnable = debounce(
    async (timeStartedRef: MutableRefObject<number | null>) => {
        if (rootStore.settings.pushToTalk && !rootStore.audioVideo.micEnabled) {
            await rootStore.audioVideo.toggleMicEnabled(true)
            timeStartedRef.current = Date.now()
        }
    },
    120
)

async function handleDisable(timeStartedRef: MutableRefObject<number | null>) {
    handleEnable.cancel() //cancels the functions calls that occur in the debounce window after the user releases the key
    if (rootStore.settings.pushToTalk && timeStartedRef.current) {
        await rootStore.audioVideo.toggleMicEnabled(false)
        const timeElapsed = Date.now() - timeStartedRef.current
        timeStartedRef.current = null
        track(PUSH_TO_TALK_USED, {
            localUserId: rootStore.users.localUser?._id ?? '',
            orgId: rootStore.users.localUser?.currentOrgId ?? '',
            timeElapsed: timeElapsed,
            timeStamp: String(Date.now()),
        })
    }
}

export function usePushToTalk() {
    const timeStartedRef = useRef<number | null>(null)
    useEffect(() => {
        Mousetrap.bind(
            KeyboardShortcut.PushToTalk,
            () => {
                void handleEnable(timeStartedRef)
            },
            'keydown'
        )
        Mousetrap.bind(
            KeyboardShortcut.PushToTalk,
            () => {
                void handleDisable(timeStartedRef)
            },
            'keyup'
        )

        return () => {
            Mousetrap.unbind(KeyboardShortcut.PushToTalk, 'keydown')
            Mousetrap.unbind(KeyboardShortcut.PushToTalk, 'keyup')
        }
    }, [])
}
