import { ICommand, IInviteToRoom, ClientMessage } from '@teamflow/types'

export default class InviteToRoomCommand implements ICommand<IInviteToRoom> {
    readonly messageType = ClientMessage.InviteToRoom

    private initiatedUserId: string
    private invitedUserIds: string[]
    private roomId: string

    constructor(
        initiatedUserId: string,
        invitedUserIds: string[],
        roomID: string
    ) {
        this.initiatedUserId = initiatedUserId
        this.invitedUserIds = invitedUserIds
        this.roomId = roomID
    }

    execute() {
        return {
            initiatedUserId: this.initiatedUserId,
            invitedUserIds: this.invitedUserIds,
            roomId: this.roomId,
        }
    }
}
