import * as t from '@teamflow/types'

/** @group Commands */
export default class RoomDeleteCommand
    implements t.ICommand<{ roomId: string; contentPolicy: string }>
{
    readonly messageType = t.ClientMessage.RoomDelete

    private roomId: string
    private contentPolicy: string

    /**
     * @param roomId
     * @param contentPolicy - What to do if there are apps in the room.
     */
    constructor(
        roomId: string,
        contentPolicy?: 'transferToFloor' | 'denyRequest' | 'delete'
    ) {
        this.roomId = roomId
        this.contentPolicy = contentPolicy ?? 'denyRequest'
    }

    execute() {
        return {
            roomId: this.roomId,
            contentPolicy: this.contentPolicy,
        }
    }

    /* localExecute() cannot be added because the request to delete a room
       may be denied. */
}
