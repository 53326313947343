import { api } from '@teamflow/client-api'
import { LogManager } from '@teamflow/lib'
import { User } from '@teamflow/store'

export async function setOrgOwner(user: User) {
    const userId = user.accountId
    try {
        await api.organization.setOwner({
            orgId: user.currentOrgId,
            userId,
        })
    } catch (e: any) {
        LogManager.global.error(
            'setOrgOwner: Error setting org owner',
            e.message
        )
        throw e
    }
}
