import { api } from '@teamflow/client-api'
import { UpdateRoomSessionCommand } from '@teamflow/client-realtime'
import { castRoomSessionToRoom } from '@teamflow/lib'
import { IRoom } from '@teamflow/store'
import * as t from '@teamflow/types'

import { track } from '../helpers/analytics'

// TODO(RYAN:API) update once API is refactored to no longer use room sessions
export async function updateRoomBackgroundImage(
    roomSlugName: string,
    realtime: t.IRealtimeService,
    backgroundImage: string,
    backgroundPattern?: t.BackgroundPattern
): Promise<IRoom>
export async function updateRoomBackgroundImage(
    roomSlugName: string,
    realtime: t.IRealtimeService,
    backgroundImage: string | undefined | null,
    backgroundPattern: t.BackgroundPattern
): Promise<IRoom>

export async function updateRoomBackgroundImage(
    roomSlugName: string,
    realtime: t.IRealtimeService,
    backgroundImage?: string | null,
    backgroundPattern?: t.BackgroundPattern
) {
    track('room_background_image_changed')

    const { data: newRoomSession, error } = await api.room.updateBackground({
        roomSlugName,
        backgroundImage,
        backgroundPattern,
    })

    if (!newRoomSession) {
        throw new Error(error?.message || 'Unknown error')
    }

    realtime.dispatch(new UpdateRoomSessionCommand(newRoomSession))

    return castRoomSessionToRoom(newRoomSession)
}

export default updateRoomBackgroundImage
