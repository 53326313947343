import groupBy from 'lodash/groupBy'
import { makeAutoObservable, observable, ObservableMap } from 'mobx'

import { IAudioZone } from '@teamflow/types'

import { Participant } from './Participant'
import { logger } from './logging'
import { RootStore } from './rootStore'

export class AudioZoneStore {
    rootStore: RootStore

    audioZonesById = new ObservableMap<string, IAudioZone>()

    /**
     audio-zones by rooms; e.g.:
     r1: {az1, az2}
     r2: {}
     */
    audioZoneIdsByRoomId = observable<string, Set<string>>(new Map())

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
    }

    reset() {
        this.audioZonesById.clear()
    }

    findAudioZoneById(id: string) {
        return this.audioZonesById.get(id)
    }

    addAudioZone(audioZone: IAudioZone) {
        const existing = this.findAudioZoneById(audioZone.id)
        if (existing) {
            return
        }

        logger.debug(
            `AudioZoneStore.addAudioZone ${audioZone.label ?? ''} ${
                audioZone.id
            }`
        )

        this.audioZonesById.set(audioZone.id, audioZone)
    }

    updateAudioZone(audioZone: IAudioZone) {
        this.audioZonesById.set(audioZone.id, { ...audioZone })
    }

    removeAudioZone(id: string) {
        this.audioZonesById.delete(id)
    }

    /**
     participants by audio-zones (one-to-many); e.g.:
     az1: {p1, p2}
     az2: {p3}
     az3: {}
     */
    get participantIdsByAzId() {
        const result = new Map<string, Set<string>>()
        this.rootStore.participants.participants
            .filter((p) => p.spatial.audioZoneId)
            .forEach((p) => {
                // always truthy, filtered
                const azId = p.spatial.audioZoneId as string
                if (result.has(azId)) {
                    result.get(azId)?.add(p.id)
                } else {
                    result.set(azId, new Set([p.id]))
                }
            })
        return result
    }

    get allAudioZones(): IAudioZone[] {
        return Array.from(this.audioZonesById.values())
    }

    get allNamedAudioZones(): IAudioZone[] {
        return this.allAudioZones
            .filter((audioZone) => audioZone.label)
            .sort((az1, az2) => az1.label!.localeCompare(az2.label!))
    }

    get participantsByAudioZoneId(): Record<string, Participant[]> {
        const entries = Array.from(this.participantIdsByAzId).map(
            ([azId, pSet]) => {
                const participants = Array.from(pSet)
                    .map((pId) =>
                        this.rootStore.participants.findParticipantById(pId)
                    )
                    .filter((p): p is NonNullable<typeof p> => !!p)
                    .sort((p1, p2) => {
                        const user1 = this.rootStore.users.getUserById(p1.id)
                        const user2 = this.rootStore.users.getUserById(p2.id)
                        if (!user1 || !user2) return 0
                        const fullName1 = user1.fullName.toLowerCase()
                        const fullName2 = user2.fullName.toLowerCase()
                        return fullName1.localeCompare(fullName2)
                    })
                return [azId, participants] as const
            }
        )

        const byAudioZoneId = Object.fromEntries(entries)

        return byAudioZoneId
    }

    get namedAudioZonesByRooms() {
        const byRooms = groupBy(this.allNamedAudioZones, (az) => az.locationId)
        return byRooms
    }

    findAudioZonesInRoom(roomId: string) {
        return this.allAudioZones.filter((e) => e.locationId === roomId)
    }

    findNamedAudioZonesInRoom(roomId: string) {
        return this.namedAudioZonesByRooms[roomId] ?? []
    }

    /**
     * Contains business logic for rendering; feel free to update with new business requirements
     * 1. Filters out unnamed AZs
     * 2. Filters out empty AZs
     */
    findVisibleAZsInRoom(roomId: string) {
        const participantsByAz = this.participantsByAudioZoneId
        return this.findNamedAudioZonesInRoom(roomId).filter(
            (az) => participantsByAz[az.id]?.length
        )
    }
}
