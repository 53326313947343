import debounce from 'lodash/debounce'
import { makeAutoObservable, observable } from 'mobx'
import { clearPersistedStore, makePersistable } from 'mobx-persist-store'

import * as t from '@teamflow/types'
import { RoomToolbarStaticTab, RoomType, SidebarPopout } from '@teamflow/types'

import { migrateZoomScaleLevel } from './migrations.ts/migrateZoomScaleLevel'

import type { RootStore } from '.'

export enum SidebarCustomizeWidth {
    TwoColumns = 292,
    ThreeColumns = 414,
    FourColumns = 536,
}

type ReconnectToastState = {
    show: boolean
    shownSince: number
    reconnecting: boolean
    errorCode: number
}

export class LayoutStore {
    rootStore: RootStore

    sidebarPopout: t.SidebarPopout = t.SidebarPopout.None
    lastSidebarPopout: t.SidebarPopout = t.SidebarPopout.None
    settingsMenu: t.SettingsMenu | null = null
    screenSharePresetModalOpen = false
    statusModalOpen = false
    inviteModalOpen = false
    inviteAsGuest = false
    defaultInviteModalToGuest = false
    fullScreenMode = false
    gridMode = false
    gridModePage = 0
    sidebarExpanded = true
    sidebarIsEphemeral = false
    sidebarExpandedPref = true
    radicalSidebarPref = false
    customizeMode = false
    customizeModeTab = t.CustomizeLayerTab.Furniture
    customizePanelWidth: number = SidebarCustomizeWidth.FourColumns
    isDragPan = false
    isSpacebarPan = false
    showRightSideRibbon = false
    createRoomModal: RoomType.Room | null = null
    deleteUserModal = false
    deleteUserFirstName = ''
    deleteUserUserId = ''
    shareModalOpen = false
    portalModalOpen = false
    portalPreset: t.IRectanglePortal | undefined = undefined
    initialZoom = 1
    zoomScaleLevel: number | null = null
    isUserSelectionMode = false
    roomToolbarStaticTab = RoomToolbarStaticTab.Space
    spatialRibbonZoomedOutThreshold = 0.24
    autoActivateSpatialRibbon = false
    spatialRibbonWhenNearAvatar = false
    spatialRibbonWhenZoomedOutOrPannedAway = true
    animatingSidebar = false
    private previousSidebarExpanded = false
    alwaysKeepSidebarExpanded = false
    appcuesFlowRunning = false
    loadingMeetingRoom = false
    meetingChatOpened = false
    topBanner = t.TopBanner.None
    shareAudioModal = false
    shareAudioFailedModal = false

    reconnectToast: ReconnectToastState = {
        reconnecting: false,
        show: false,
        errorCode: 0,
        shownSince: 0,
    }

    windowInnerWidth = 0
    windowInnerHeight = 0

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            portalPreset: observable.ref,
        })
        void makePersistable(this, {
            name: 'LayoutStore',
            properties: [
                'sidebarPopout',
                'lastSidebarPopout',
                'sidebarExpandedPref',
                'radicalSidebarPref',
                'customizePanelWidth',
                'zoomScaleLevel',
                'spatialRibbonWhenNearAvatar',
            ],
            // Stop it blowing up with SSR
            storage:
                typeof window !== 'undefined' ? window.localStorage : undefined,
        })
        this.rootStore = rootStore

        if (typeof window !== 'undefined') {
            this.updateWindowDimensions()
            window.addEventListener(
                'resize',
                debounce(() => this.updateWindowDimensions(), 100, {
                    leading: false,
                    trailing: true,
                })
            )
        }
    }

    reset(shouldClearPersistentStore = false) {
        if (shouldClearPersistentStore) {
            void clearPersistedStore(this)
        }
    }

    updateWindowDimensions() {
        this.windowInnerWidth = window.innerWidth
        this.windowInnerHeight = window.innerHeight
    }

    get objectEditorOpen(): boolean {
        return (
            this.customizeMode &&
            this.customizeModeTab === t.CustomizeLayerTab.Furniture
        )
    }

    get levelEditorOpen(): boolean {
        return (
            this.customizeMode &&
            this.customizeModeTab === t.CustomizeLayerTab.Layout
        )
    }

    get isOneOnOneChat() {
        return (
            this.roomToolbarStaticTab === t.RoomToolbarStaticTab.Chat &&
            this.rootStore.chat.chat?.type === t.ChatType.ONE_ON_ONE
        )
    }

    get isRoomChat() {
        return (
            this.roomToolbarStaticTab === t.RoomToolbarStaticTab.Chat &&
            this.rootStore.chat.chat?.type === t.ChatType.ROOM
        )
    }

    setLoadingMeetingRoom(value: boolean): void {
        this.loadingMeetingRoom = value
    }

    setRoomToolbarStaticTab(value: t.RoomToolbarStaticTab): void {
        if (
            this.rootStore.organization.isSalesFloor &&
            value === t.RoomToolbarStaticTab.Space
        ) {
            value = t.RoomToolbarStaticTab.Grid
        }
        this.roomToolbarStaticTab = value
    }

    setSidebarPopout(sidebarPopout: t.SidebarPopout): void {
        this.lastSidebarPopout = this.sidebarPopout
        this.sidebarPopout = sidebarPopout
    }

    setSettingsMenu(value: t.SettingsMenu | null): void {
        this.settingsMenu = value
    }

    setScreenSharePresetModalOpen(value: boolean): void {
        this.screenSharePresetModalOpen = value
    }

    setStatusModalOpen(value: boolean): void {
        this.statusModalOpen = value
    }

    setInviteModalOpen(
        value: boolean,
        inviteAsGuest = false,
        defaultToGuest = false
    ): void {
        this.inviteModalOpen = value
        this.inviteAsGuest = inviteAsGuest
        this.defaultInviteModalToGuest = defaultToGuest
    }

    setShareModalOpen(value: boolean): void {
        this.shareModalOpen = value
    }

    setShareAudioModal(value: boolean): void {
        this.shareAudioModal = value
    }

    setShareAudioFailedModal(value: boolean): void {
        this.shareAudioFailedModal = value
    }

    setPresenterMode(value: boolean): void {
        this.fullScreenMode = value
        this.setSidebarPopout(t.SidebarPopout.None)
        this.gridMode = false
        this.sidebarIsEphemeral = false
        this.sidebarExpandedPref = false
        this.radicalSidebarPref = false
        if (!this.alwaysKeepSidebarExpanded) {
            this.sidebarExpanded = false
        }
    }

    setFullScreenMode(value: boolean): void {
        if (this.rootStore.organization.isSalesFloor) {
            value = true
        }
        if (value && this.fullScreenMode) return //already in FSM

        this.fullScreenMode = value

        // Close sidebar when entering full-screen mode and when leaving expanded if it was expanded before entering FSM
        if (!this.alwaysKeepSidebarExpanded) {
            if (value) {
                this.previousSidebarExpanded = this.sidebarExpanded
                this.sidebarExpanded = false
            } else if (this.previousSidebarExpanded) {
                this.sidebarExpanded = true
            }
        }

        this.setSidebarPopout(t.SidebarPopout.None)
        if (value) this.gridMode = !this.rootStore.app.selectedApp
    }

    setFullScreenModeWithoutSidebar(value: boolean): void {
        this.fullScreenMode = value
    }

    toggleFullScreenMode(): void {
        this.setFullScreenMode(!this.fullScreenMode)
    }

    setGridMode(value: boolean): void {
        this.gridMode = value
    }

    setGridModePage(page: number): void {
        this.gridModePage = page
    }

    setSpatialRibbonZoomedOutThreshold(value: number): void {
        this.spatialRibbonZoomedOutThreshold = value
    }

    get userZoomedOut() {
        return (this.zoomScaleLevel ?? 0) < this.spatialRibbonZoomedOutThreshold
    }

    setAutoActivateSpatialRibbon(value: boolean) {
        this.autoActivateSpatialRibbon = value
    }

    exitFullScreenMode(): void {
        this.fullScreenMode = false
    }

    setSidebarExpanded(
        expanded: boolean,
        savePref = false,
        ephemeral = false,
        radical = false
    ): void {
        if (this.alwaysKeepSidebarExpanded) {
            expanded = true
        }

        this.sidebarExpanded = expanded
        this.sidebarIsEphemeral = ephemeral

        if (savePref && !radical) this.sidebarExpandedPref = expanded
        if (savePref && radical) this.radicalSidebarPref = expanded
        if (
            !this.sidebarExpanded &&
            this.sidebarPopout !== t.SidebarPopout.Chat &&
            this.sidebarPopout !== t.SidebarPopout.Schedule
        ) {
            this.setSidebarPopout(SidebarPopout.None)
        }
    }

    setAnimatingSidebar(animating: boolean) {
        this.animatingSidebar = animating
    }

    toggleCustomizeMode(value = !this.customizeMode): void {
        this.customizeMode = value
    }

    setCustomizeModeTab(value: t.CustomizeLayerTab): void {
        this.customizeModeTab = value
    }

    resizeCustomizePanel(width: number): void {
        this.customizePanelWidth = width
    }

    setIsDragPan(value: boolean): void {
        this.isDragPan = value
    }

    setIsSpacebarPan(value: boolean): void {
        this.isSpacebarPan = value
    }

    get forcePan(): boolean {
        return this.isDragPan || this.isSpacebarPan
    }

    setCreateRoomModal(value: RoomType.Room | null): void {
        this.createRoomModal = value
    }

    setDeleteUserModal(
        value: boolean,
        firstName?: string,
        userId?: string
    ): void {
        this.deleteUserModal = value
        this.deleteUserFirstName = firstName ? firstName : ''
        this.deleteUserUserId = userId ? userId : ''
    }

    toggleRightSideRibbon(
        valueDesired: boolean,
        isPhonePlatform: boolean
    ): void {
        this.showRightSideRibbon = isPhonePlatform ? false : valueDesired
    }

    setPortalModalOpen(open: boolean, preset?: t.IRectanglePortal) {
        this.portalModalOpen = open
        this.portalPreset = preset
    }

    setChatIdentifierAndOpen(id: t.ChatIdentifier | null) {
        if (id) {
            this.setSidebarPopout(t.SidebarPopout.Chat)
            this.rootStore.chat.setChatIdentifier(id)
        } else {
            this.setSidebarPopout(SidebarPopout.None)
            this.rootStore.chat.setChatIdentifier(null)
        }
    }

    setTopBanner(value: t.TopBanner): void {
        this.topBanner = value
    }

    toggleChat(id: t.ChatIdentifier | null): void {
        if (this.rootStore.chat.isActiveChat(id)) {
            this.setSidebarPopout(t.SidebarPopout.None)
            this.rootStore.chat.setChatIdentifier(null)
        } else {
            this.setSidebarPopout(SidebarPopout.Chat)

            this.rootStore.chat.setChatIdentifier(id)
        }
    }

    setInitialZoom(initialZoom: number) {
        this.initialZoom = initialZoom
        // TODO: Added Dec 2021, remove after ~1 month in production
        migrateZoomScaleLevel(this)
        // init zoomScaleLevel if has not been saved yet
        if (typeof this.zoomScaleLevel !== 'number') {
            this.zoomScaleLevel = initialZoom
        }
    }

    updateZoomScaleLevel(zoomScaleLevel: number) {
        this.zoomScaleLevel = zoomScaleLevel
    }

    updateSpatialRibbonWhenZoomedOutOrPannedAway(value: boolean) {
        this.spatialRibbonWhenZoomedOutOrPannedAway = value
    }

    get spatialRibbonActive() {
        if (
            this.rootStore.commons.disconnected ||
            !this.rootStore.commons.sameViewingAndAVSpace
        ) {
            return false
        }

        if (
            this.autoActivateSpatialRibbon &&
            this.rootStore.settings.autoSpatialRibbonChange &&
            this.userZoomedOut &&
            this.rootStore.participants.nearByParticipants.length > 0
        ) {
            return this.spatialRibbonWhenZoomedOutOrPannedAway
        }

        return this.spatialRibbonWhenNearAvatar
    }

    updateAppcuesFlowRunning(value: boolean) {
        this.appcuesFlowRunning = value
    }

    toggleSpatialRibbonWhenZoomedOutOrPannedAway() {
        this.spatialRibbonWhenZoomedOutOrPannedAway =
            !this.spatialRibbonWhenZoomedOutOrPannedAway
    }

    toggleSpatialRibbonWhenNearAvatar() {
        this.spatialRibbonWhenNearAvatar = !this.spatialRibbonWhenNearAvatar
    }

    enableSpatialRibbonEverywhere() {
        this.spatialRibbonWhenZoomedOutOrPannedAway = true
        this.spatialRibbonWhenNearAvatar = true
    }

    setIsUserSelectionMode(value: boolean) {
        this.isUserSelectionMode = value
    }

    closeReconnectToast(): void {
        this.reconnectToast = {
            show: false,
            reconnecting: false,
            errorCode: 0,
            shownSince: 0,
        }
    }

    /**
     * Shows the reconnect toast in the reconnecting state.
     */
    showReconnectToast(errorCode: number): void {
        if (this.reconnectToast.show) return

        this.reconnectToast = {
            show: true,
            reconnecting: true,
            errorCode,
            shownSince: Date.now(),
        }
    }

    /**
     * Update the reconnect toast's reconnecting state. `HandleReconnect` is called if `reconnecting` is false and
     * the reconnect button is clicked.
     */
    updateReconnectToast(value: { reconnecting: boolean }): void {
        this.reconnectToast = {
            ...this.reconnectToast,
            reconnecting: value.reconnecting,
        }
    }

    updateMeetingChatOpened(newValue: boolean): void {
        this.meetingChatOpened = newValue
    }
}
