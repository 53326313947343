import type { ThrottledEmitter } from '@teamflow/lib'
import * as t from '@teamflow/types'

import { ISharedState } from '../../sharedState'

import type EventEmitter from 'eventemitter3'

export type ValueInMapRecord<T> = T extends Map<any, infer I> ? I : never

export type Substate<K extends keyof t.IVerseState> = ValueInMapRecord<
    t.IVerseState[K]
>

type SubstateConnectors<K extends keyof t.IVerseState> = {
    [S in keyof Substate<K>]?: {
        new (
            realtime: t.IRealtimeService,
            sharedState: ISharedState,
            events: EventEmitter,
            throttledEmitter: ThrottledEmitter,
            actionQueue: Array<() => void>,
            flags: t.IFeatureFlagService,
            analytics: t.IAnalytics
        ): StateConnectorBase
    }
}

export type ConnectorSubstatesRegistery = {
    [K in keyof t.IVerseState]?: SubstateConnectors<K>
}

abstract class StateConnectorBase {
    constructor(
        protected readonly realtime: t.IRealtimeService,
        protected readonly sharedState: ISharedState,
        protected readonly events: EventEmitter,
        protected readonly throttledEmitter: ThrottledEmitter,
        protected readonly actionQueue: Array<() => void>,
        protected readonly flags: t.IFeatureFlagService,
        protected readonly analytics: t.IAnalytics
    ) {}
}

/** @group Connectors */
export abstract class StateConnector<
    T extends keyof t.IVerseState
> extends StateConnectorBase {
    abstract onAttach(collection: t.IVerseState[T]): void
    abstract onDetach(collection: t.IVerseState[T]): void

    /**
     * Gets called when the active spaces changes (whenever `rootStore.commons.currentSpace` changes).
     *
     * This will clean the spatialHash whenever we switch to a new space.
     */
    onSwitchSpace(_collection: t.IVerseState[T]): void {
        /* noop */
    }
}

export abstract class SubstateConnector<
    T extends keyof t.IVerseState,
    S extends keyof Substate<T>
> extends StateConnectorBase {
    abstract onAttach(collection: Substate<T>[S]): void
    abstract onDetach(collection: Substate<T>[S]): void

    /**
     * Gets called when the active spaces changes (whenever `rootStore.commons.currentSpace` changes).
     */
    onSwitchSpace(_collection: Substate<T>[S]): void {
        /* noop */
    }
}

const CONNECTOR_REGISTRY: {
    [K in keyof t.IVerseState]?: {
        new (
            realtime: t.IRealtimeService,
            sharedState: ISharedState,
            events: EventEmitter,
            throttledEmitter: ThrottledEmitter,
            actionQueue: Array<() => void>,
            flags: t.IFeatureFlagService,
            analytics: t.IAnalytics
        ): StateConnectorBase
    }
} = {}

const CONNECTOR_SUBSTATE_REGISTERY: ConnectorSubstatesRegistery = {}

export const readRegistry = (): Readonly<typeof CONNECTOR_REGISTRY> =>
    CONNECTOR_REGISTRY
export const readSubstateRegistery = (): Readonly<
    typeof CONNECTOR_SUBSTATE_REGISTERY
> => CONNECTOR_SUBSTATE_REGISTERY

export const Connect =
    <K extends keyof t.IVerseState, S extends keyof Substate<K>>({
        key,
        substate,
    }: {
        key: K
        substate?: S
    }) =>
    <T extends StateConnectorBase>(Digester: {
        new (
            service: t.IRealtimeService,
            sharedState: ISharedState,
            events: EventEmitter,
            throttledEmitter: ThrottledEmitter,
            actionQueue: Array<() => void>,
            flags: t.IFeatureFlagService,
            analytics: t.IAnalytics
        ): T
    }) => {
        if (substate) {
            if (CONNECTOR_SUBSTATE_REGISTERY[key]?.[substate]) {
                throw new Error(
                    `Substate consumer for ${key}.${substate.toString()} is already registerd`
                )
            }

            if (!(key in CONNECTOR_SUBSTATE_REGISTERY)) {
                CONNECTOR_SUBSTATE_REGISTERY[key] = {}
            }

            ;(CONNECTOR_SUBSTATE_REGISTERY[key] as SubstateConnectors<K>)[
                substate
            ] = Digester

            return Digester
        }

        if (CONNECTOR_REGISTRY[key]) {
            throw new Error(`State consumer for ${key} is already registered!`)
        }

        CONNECTOR_REGISTRY[key] = Digester

        return Digester
    }
