import { request } from '../request'

const blocked = ({ domain }: { domain: string }) =>
    request<'POST /api/util/blocked'>('POST', '/api/util/blocked', {
        body: {
            domain,
        },
    })

export default {
    blocked,
}
