import { api } from '@teamflow/client-api'
import * as shared from '@teamflow/lib/src/shared-old'

export async function verifyEmail(email: string) {
    // A preliminary filtering of popular webmail to skip API requests.
    if (shared.isWebmail(email)) {
        return { status: 'webmail' } as const
    }

    const { data } = await api.services.verifyEmail(email)

    return data
}
