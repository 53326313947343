import { invokeAction } from './service'
import { IActionDefinition, TriggerResult } from './types'

export async function processActions(actions: IActionDefinition[]) {
    for (const action of actions) {
        const result = await invokeAction(action.name, action.payload)

        // trigger nested based on result before moving on
        switch (result) {
            case TriggerResult.Break:
                // stop this sequence
                return

            default:
            case TriggerResult.Ok:
                // move on to next trigger in sequence
                break
        }
    }
}
