import * as types from '@teamflow/types'

export class ParticipantMeetingRecordingChangedCommand
    implements types.ICommand<types.IParticipantMeetingRecordingChanged>
{
    readonly messageType =
        types.ClientMessage.ParticipantMeetingRecordingChanged

    constructor(private isRecording: boolean) {}

    execute() {
        return {
            isRecording: this.isRecording,
        }
    }
}
