import * as t from '@teamflow/types'

export default class NotifyNearbyScreenShareCommand
    implements t.ICommand<t.INotifyNearbyScreenShare>
{
    readonly messageType = t.ClientMessage.NotifyNearbyScreenShare

    private initiatedUserId: string
    private notifiedUserIds: string[]
    private appId: string

    constructor(
        initiatedUserId: string,
        notifiedUserIds: string[],
        appId: string
    ) {
        this.initiatedUserId = initiatedUserId
        this.notifiedUserIds = notifiedUserIds
        this.appId = appId
    }

    execute() {
        return {
            initiatedUserId: this.initiatedUserId,
            notifiedUserIds: this.notifiedUserIds,
            appId: this.appId,
        }
    }
}
