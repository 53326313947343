import useSWR from 'swr'

import { api } from '@teamflow/client-api'
import { getPublicApiUrl } from '@teamflow/lib'
import { ApiError, ChatFrontendModel } from '@teamflow/types'

export function useUserOrgChats(enabled = true): [
    ChatFrontendModel[] | undefined,
    {
        loading: boolean
        mutate: () => void
        revalidate: () => void
        error?: ApiError
    }
] {
    const url = getPublicApiUrl('/api/userOrg/chats/get')
    const { data, mutate } = useSWR(
        enabled ? url : null,
        () => api.userOrg.chats.list(),
        {
            refreshInterval: 0,
            revalidateOnFocus: false,
        }
    )
    const userOrgChats =
        data?.data && Array.isArray(data.data) ? data.data : undefined
    const loading = !userOrgChats
    return [
        userOrgChats,
        {
            loading,
            mutate,
            revalidate: () => void mutate(),
            error: data?.error,
        },
    ]
}
