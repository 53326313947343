import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import Events from '../../events'

import { Connect, Substate, SubstateConnector } from './StateConnector'
import { putOrRemoveFromHash } from './helpers'

type Portals = Substate<'roomSubstates'>['portals']

const putPortalInHash = (item: t.IPortal) => {
    rootStore.spatialHash.put(
        item.id,
        'portal',
        item.x - item.width / 2,
        item.y - item.height / 2,
        item.x + item.width / 2,
        item.y + item.height / 2
    )
}

/** @group Connectors */
@Connect({ key: 'roomSubstates', substate: 'portals' })
export class PortalConnector extends SubstateConnector<
    'roomSubstates',
    'portals'
> {
    onAttach(portals: Portals) {
        portals.onAdd = (item) => {
            putOrRemoveFromHash(item, putPortalInHash)

            item.onChange = () => {
                putOrRemoveFromHash(item, putPortalInHash)

                /* Note: Portal IDs *never* change. So mapping is intact :+1 */
                this.sharedState.addPortal(item)

                if (item.locationId === rootStore.commons.viewingSpace) {
                    this.throttledEmitter.emit(
                        Events.PortalChanged,
                        item.id,
                        item
                    )
                    this.throttledEmitter.emit(
                        Events.PortalUpdated,
                        item.id,
                        item
                    )
                }
            }

            this.sharedState.addPortal(item)

            if (item.locationId === rootStore.commons.viewingSpace) {
                this.events.emit(Events.PortalAdded, item)
                this.events.emit(Events.PortalUpdated, item)
            }
        }

        portals.onRemove = (portal) => {
            rootStore.spatialHash.delete(portal.id)

            // TODO: Cancel throttled-emitter so it doesn't emit any pending Change ev
            this.sharedState.removePortal(portal)

            if (portal.locationId === rootStore.commons.viewingSpace) {
                this.events.emit(Events.PortalRemoved, portal)
                this.events.emit(Events.PortalUpdated, portal)
            }
        }
    }

    onDetach(portals: Portals) {
        portals.onAdd = undefined
        portals.onRemove = undefined
    }

    onSwitchSpace(portals: Portals) {
        const currentSpace = rootStore.commons.viewingSpace

        // Populate portal
        for (const portal of portals)
            if (portal.locationId === currentSpace) putPortalInHash(portal)
    }
}
