import { makeAutoObservable, observable } from 'mobx'

import type { IAsset, Entity } from '@teamflow/types'

import type { ICatalogFurniture } from '../../requests'

export type PlaceData = {
    entity: Entity
    template: IAsset | ICatalogFurniture
    params?: {
        orientation?: 'h' | 'v'
    }
}

export enum LevelEditorTool {
    None = 'none',
    Select = 'select',
    Hand = 'hand',
    Erase = 'erase',
    Place = 'place',
}

export class LevelEditor {
    entityBeingPlaced: PlaceData | null = null
    tool: LevelEditorTool = LevelEditorTool.None

    constructor() {
        makeAutoObservable(this, {
            entityBeingPlaced: observable.ref,
        })
    }

    reset() {
        this.entityBeingPlaced = null
        this.tool = LevelEditorTool.None
    }

    openPlaceTool(entityBeingPlaced: PlaceData) {
        this.entityBeingPlaced = entityBeingPlaced
        this.tool = LevelEditorTool.Place
    }

    setTool(tool: LevelEditorTool) {
        if (tool === this.tool) return
        this.entityBeingPlaced = null
        this.tool = tool
    }
}
