import { useEffect } from 'react'

import { useRefAutoUpdating } from '@teamflow/lib'

/**
 * Ensures an invariant condition by running a "enforcing callback" that is invoked whenever the
 * invariant is in a broken condition.
 *
 * WARNING: Using conflicting invariants can cause an infinite renders.
 *
 * @param broken - Whether the invariant is broken.
 * @param enforce - The enforcing callback. This should try to make the invariant straight.
 */
export function useInvariant(broken: boolean, enforce: () => unknown) {
    const enforceRef = useRefAutoUpdating(enforce)
    useEffect(() => {
        if (broken) {
            enforceRef.current()
        }
    }, [broken, enforceRef])
}
