import auth from './api/auth'
import billing from './api/billing'
import chat from './api/chat'
import config from './api/config'
import debug from './api/debug'
import email from './api/email'
import file from './api/file'
import instances from './api/instances'
import invitation from './api/invitation'
import inviteCode from './api/inviteCode'
import join from './api/join'
import login from './api/login'
import logout from './api/logout'
import marketing from './api/marketing'
import meetingRecording from './api/meetingRecording'
import notification from './api/notification'
import oauth from './api/oauth'
import organization from './api/organization'
import outAppNotification from './api/out-app-notification'
import preload from './api/preload'
import role from './api/role'
import room from './api/room'
import services from './api/services'
import sheets from './api/sheets'
import slack from './api/slack'
import sso from './api/sso'
import status from './api/status'
import template from './api/template'
import token from './api/token'
import triggers from './api/triggers'
import user from './api/user'
import userOrg from './api/userOrg'
import util from './api/util'
import verse from './api/verse'
import youTube from './api/youTube'

export * as apiConfig from './config'

export const api = {
    auth,
    billing,
    chat,
    config,
    debug,
    email,
    file,
    instances,
    invitation,
    inviteCode,
    join,
    login,
    logout,
    notification,
    marketing,
    oauth,
    organization,
    outAppNotification,
    preload,
    role,
    room,
    services,
    sheets,
    slack,
    sso,
    status,
    template,
    token,
    triggers,
    user,
    userOrg,
    verse,
    meetingRecording,
    youTube,
    util,
}

export {
    getAccessToken as token,
    DONOTUSEORYOUWILLBEFIRED_testTokenLoop,
} from './request'
