import { makeAutoObservable } from 'mobx'

import { LogManager } from '@teamflow/lib'

import { fetchFurnitureModel, ICatalogFurniture } from '../../requests'

export class Furniture {
    catalog: Partial<{
        [id: string]: ICatalogFurniture
    }> = {}

    pendingRequests: Map<string, Promise<ICatalogFurniture | undefined>> =
        new Map()

    constructor() {
        makeAutoObservable(this, {
            pendingRequests: false,
        })
    }

    reset() {
        this.catalog = {}
        this.pendingRequests.clear()
    }

    async load(modelId: string): Promise<ICatalogFurniture | undefined> {
        if (this.pendingRequests.has(modelId)) {
            return this.pendingRequests.get(modelId)
        }

        const stateHit = this.getModel(modelId)

        if (stateHit) {
            return Promise.resolve(stateHit)
        }

        const promise = fetchFurnitureModel(modelId)
        this.pendingRequests.set(modelId, promise)

        try {
            const model = await promise
            if (!model) {
                throw new Error('Model not found')
            }
            this.appendCatalog(model)
            this.pendingRequests.delete(modelId)
            return model
        } catch {
            this.pendingRequests.delete(modelId)
            LogManager.global.error(`Failed to load furniture model ${modelId}`)
        }
    }

    appendCatalog(model: ICatalogFurniture | ReadonlyArray<ICatalogFurniture>) {
        const models = Array.isArray(model) ? model : [model]
        for (const m of models) {
            this.catalog[m._id] = m

            if (m.nameSlug) {
                this.catalog[m.nameSlug] = m
            }
        }
    }

    getModel(id: string) {
        return this.catalog[id]
    }
}
