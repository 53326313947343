import * as t from '@teamflow/types'

import * as Formatter from './format'

const delimiter = '|'

export function splitEmojiAndText(
    currentStatus: string | undefined,
    metadata: t.IStatusMetadata | undefined
): [string, string] {
    const parts = currentStatus?.includes(delimiter)
        ? currentStatus.split(delimiter)
        : ['', currentStatus ?? '']
    const emoji = parts[0]
    let text = parts.slice(1).join()

    if (
        typeof metadata?.triggerCalendarEventTime === 'number' &&
        text.includes('{{triggerCalendarEventTime}}')
    ) {
        text = text.replace(
            '{{triggerCalendarEventTime}}',
            Formatter.formatTime(new Date(metadata.triggerCalendarEventTime))
        )
    }

    return [emoji, text]
}

export function joinEmojiAndText(emoji: string, text: string): string {
    return `${emoji}${delimiter}${text}`
}
