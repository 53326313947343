import {
    devMessages,
    waitForMilliseconds,
    colyseusMaxSoftReconnectTime,
    LogManager,
} from '@teamflow/lib'
import * as t from '@teamflow/types'

import { CustomColyseusClient } from '../customColyseusClient'

import { ConnectionTransition } from './enums'
import { State, StateMachine } from './types'

type ReconnectOptions = t.JoinOptions & {
    retrySoftReconnect?: boolean
    ready?: boolean
}

const logger = LogManager.createLogger('Reconnect', { critical: true })

/** @group Connection States */
export default class Reconnect implements State {
    private readonly client: CustomColyseusClient

    constructor(client: CustomColyseusClient) {
        this.client = client
    }

    private async attemptSoftReconnect(
        machine: StateMachine,
        options: ReconnectOptions
    ) {
        try {
            await this.client.updateEndpoint(options.orgId)

            // try the normal reconnect option
            const room = await this.client.reconnect<t.IVerseState>(
                options.orgId,
                options.userOrgId
            )

            await machine.runTransition(ConnectionTransition.Connected, {
                room,
                isReconnect: true,
            })

            return true
        } catch (error) {
            /* @metric rt_reconnect_errors */
            logger.error({
                action: 'Reconnect@Soft',
                message:
                    'Reconnect.attemptSoftReconnect' + // just for LogRocket, rm later
                    (error instanceof Error ? error.message : 'error'),
            })
            return false
        }
    }

    async onEnter(machine: StateMachine, options: ReconnectOptions) {
        const reconnectStart = Date.now()

        // always try soft reconnect at least once
        if (await this.attemptSoftReconnect(machine, options)) return

        // if retrySoftReconnect=true, try to soft reconnect so long as server is accepting reconnects
        while (
            options.retrySoftReconnect &&
            Date.now() + 500 < reconnectStart + colyseusMaxSoftReconnectTime
        ) {
            await waitForMilliseconds(500)
            if (await this.attemptSoftReconnect(machine, options)) return
        }

        // if we haven't returned yet, then all soft reconnects failed
        // try rejoining by going through the join logic
        options.refreshUrl = true

        /* @metric client-realtime_reconnect_errors */
        logger.error({
            action: 'Reconnect@Hard',
            message: devMessages.hardReconnect,
            options,
        })
        await machine.runTransition(ConnectionTransition.Connect, options)
    }
}
