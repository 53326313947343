import { makeAutoObservable } from 'mobx'
import { clearPersistedStore, makePersistable } from 'mobx-persist-store'

import { SLACK_NOTIFY_JOIN_TEAM_COUNT } from '@teamflow/lib'

export class SlackStore {
    appConnected = false
    userConnected = false
    userSyncEnabled = false
    notifyJoinTeamCount = SLACK_NOTIFY_JOIN_TEAM_COUNT

    constructor() {
        makeAutoObservable(this)

        void makePersistable(this, {
            name: 'SlackStore',
            properties: ['notifyJoinTeamCount'],
            // Stop it blowing up with SSR
            storage:
                typeof window !== 'undefined' ? window.localStorage : undefined,
        })
    }

    reset(shouldClearPersistentStore = false) {
        if (shouldClearPersistentStore) {
            void clearPersistedStore(this)
        }

        this.appConnected = false
        this.userConnected = false
        this.userSyncEnabled = false
        this.notifyJoinTeamCount = SLACK_NOTIFY_JOIN_TEAM_COUNT
    }

    setAppConnected(appConnected: boolean): void {
        this.appConnected = appConnected
    }

    setUserConnected(userConnected: boolean): void {
        this.userConnected = userConnected
    }

    updateSettings({
        userSyncEnabled,
        notifyJoinTeamCount,
    }: Partial<Pick<SlackStore, 'notifyJoinTeamCount' | 'userSyncEnabled'>>) {
        this.userSyncEnabled = userSyncEnabled ?? this.userSyncEnabled
        this.notifyJoinTeamCount =
            notifyJoinTeamCount ?? this.notifyJoinTeamCount
    }
}
