import jwt from 'jsonwebtoken'

import {
    ID_REFRESH_TOKEN_NAME,
    ID_TOKEN_NAME,
    LogManager,
    retryWithTimeout,
    TimeUnit,
} from '@teamflow/lib'
import {
    ApiError,
    ApiErrorCode,
    ApiResponseBody,
    MergeVariants,
    TeamflowRestApiSpecification as ApiSpec,
} from '@teamflow/types'

import { defaultHeaders, TokenEvents, tokenEvents } from './config'

type NoBodyMethods = 'GET' | 'DELETE' | 'OPTIONS' | 'HEAD'

type BodyMethods = 'PUT' | 'POST' | 'PATCH'

const isBodyMethod = (method: BodyMethods | NoBodyMethods) =>
    ['PUT', 'POST', 'PATCH'].includes(method)

const stringifyBody = (value: any) => {
    return value instanceof FormData ? value : JSON.stringify(value)
}

/**
 * Time, in milliseconds, before token expiry that we refresh the tokens.
 *
 * @private
 */
const EXPIRY_SAFE_HARBOR = TimeUnit.MINUTES.toMillis(4) // guarantees refresh b/c tokens must live at least 5 mins

export const toApiResponseSafe = async (
    fetcher: () => Promise<Response>,
    method: BodyMethods | NoBodyMethods,
    path: string
) => {
    let res: Response | undefined
    try {
        res = await fetcher()
        const body: ApiResponseBody<any> | { message: string } | undefined =
            await res.json()

        if (!body || (!('data' in body) && !('error' in body))) {
            throw new Error(
                `Invalid response format ${
                    body && 'message' in body ? body.message : ''
                }`
            )
        }
        return body
    } catch (error) {
        if (res?.ok) {
            return { data: null }
        }
        const message = `${method} ${path} ${
            error instanceof Error ? error.message : ''
        }`
        LogManager.global.error(message)
        return {
            error: {
                code: ApiErrorCode.ErrorThrownInFetcher,
                status: res?.status ?? 0,
                message,
                stack: (error as any).stack,
            } as ApiError,
        }
    }
}

/**
 * authedFetch adds user Authorization header if an auth token is found
 */
export const authedFetch = async (
    input: string,
    init?: RequestInit
): Promise<Response> => {
    const contentHeaders: HeadersInit =
        init?.body && init.body instanceof FormData
            ? {}
            : { 'Content-Type': 'application/json' }

    const idToken = await getAccessToken()
    const authHeaders: HeadersInit =
        idToken && idToken !== 'undefined'
            ? {
                  Authorization: `Bearer ${idToken}`,
              }
            : {}

    return fetch(`${process.env.NEXT_PUBLIC_TEAMFLOW_API_URL}${input}`, {
        ...init,
        headers: {
            ...defaultHeaders,
            ...contentHeaders,
            ...init?.headers,
            ...authHeaders,
        },
    })
}

type Token = {
    exp: number
    [key: string]: unknown
}
/**
 * Returns the accessToken from localStorage, or:
 *  - Negotiates a new one if the refresh token is still valid
 *  - Logs out the user by emitting TokenExpired event
 */
let accessTokenPromise: Promise<string | undefined> | null

export const getAccessToken = async (): Promise<string | null | undefined> => {
    let idToken = getFromLocalStorage(ID_TOKEN_NAME)
    const refreshToken = getFromLocalStorage(ID_REFRESH_TOKEN_NAME)
    if (idToken && idToken !== 'undefined') {
        const decoded = jwt.decode(idToken) as Token
        const decodedRefresher = refreshToken
            ? (jwt.decode(refreshToken) as Token)
            : null
        if (
            willExpire(decoded) ||
            (decodedRefresher && willExpire(decodedRefresher))
        ) {
            LogManager.global.info(
                `AccessToken or RefreshToken about to expire or already did, getting a new one`,
                {
                    auth: true,
                }
            )
            if (!accessTokenPromise)
                accessTokenPromise = getNewAccessToken(idToken)
        } else {
            return idToken
        }
    }
    if (accessTokenPromise) {
        idToken = await accessTokenPromise
        accessTokenPromise = null
    }
    return idToken
}

const isExpired = (token: Token) => {
    return Date.now() > token.exp * 1000
}

const willExpire = (token: Token) => {
    return Date.now() > token.exp * 1000 - EXPIRY_SAFE_HARBOR
}

const getFromLocalStorage = (key: string): string | null | undefined => {
    return typeof localStorage !== 'undefined'
        ? localStorage.getItem(key)
        : null
}

// Ensure we check the token
export const DONOTUSEORYOUWILLBEFIRED_testTokenLoop = () => {
    let handle = setTimeout(function checkAccessToken() {
        getAccessToken().finally(() => {
            handle = setTimeout(checkAccessToken, EXPIRY_SAFE_HARBOR)
        })
    }, EXPIRY_SAFE_HARBOR)

    return {
        get handle() {
            return handle
        },
    }
}

const getNewAccessToken = async (
    oldAccessToken: string
): Promise<string | undefined> => {
    const refreshToken = getFromLocalStorage(ID_REFRESH_TOKEN_NAME)
    if (!refreshToken) {
        LogManager.global.error(`No refresh token found`, { auth: true })
        const decodedAccess = jwt.decode(oldAccessToken) as Token
        if (isExpired(decodedAccess)) {
            LogManager.global.error('Access token also expired, logging out', {
                auth: true,
            })
            tokenEvents.emit(TokenEvents.Expired)
            return undefined
        }
        return oldAccessToken
    }
    const decoded = jwt.decode(refreshToken) as Token
    if (isExpired(decoded)) {
        LogManager.global.error(`Refresh token expired, logging out`, {
            auth: true,
        })
        tokenEvents.emit(TokenEvents.Expired)
        return undefined
    }
    const path = '/api/token/refresh'
    const method = 'POST'
    const response = await toApiResponseSafe(
        () =>
            fetch(`${process.env.NEXT_PUBLIC_TEAMFLOW_API_URL}${path}`, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: stringifyBody({
                    refreshToken,
                    oldAccessToken,
                }),
            }),
        method,
        path
    )

    const data = response.data
    if (!data) {
        LogManager.global.warn('Failed to refresh tokens', {
            auth: true,
            error: response.error,
        })
        return undefined
    }
    if (typeof localStorage !== 'undefined') {
        if (data.accessToken)
            localStorage.setItem(ID_TOKEN_NAME, data.accessToken)
        if (data.refreshToken)
            localStorage.setItem(ID_REFRESH_TOKEN_NAME, data.refreshToken)
    }
    return data.accessToken
}

type TypedFetchParams<P extends keyof ApiSpec> =
    // "No Body Methods" 'GET' | 'DELETE' | 'OPTIONS' | 'HEAD':
    ApiSpec[P]['method'] extends NoBodyMethods
        ? ApiSpec[P]['request']['headers'] extends never
            ? [ApiSpec[P]['method'], string]
            : [
                  ApiSpec[P]['method'],
                  string,
                  {
                      headers: ApiSpec[P]['request']['headers']
                  }
              ]
        : // "Body Methods" 'PUT' | 'POST' | 'PATCH':
        ApiSpec[P]['request']['headers'] extends never
        ? // Body Methods with no headers:
          ApiSpec[P]['request']['body'] extends never
            ? [ApiSpec[P]['method'], string]
            : [
                  ApiSpec[P]['method'],
                  string,
                  {
                      body: ApiSpec[P]['request']['body']
                  }
              ]
        : // Body Methods with headers:
        ApiSpec[P]['request']['body'] extends never
        ? [
              ApiSpec[P]['method'],
              string,
              {
                  headers: ApiSpec[P]['request']['headers']
              }
          ]
        : [
              ApiSpec[P]['method'],
              string,
              {
                  body: ApiSpec[P]['request']['body']
                  headers: ApiSpec[P]['request']['headers']
              }
          ]

/**
 * request is a fetcher for requests with runtime params, queries, or headers.
 */

export const request = async <P extends keyof ApiSpec>(
    ...[method, path, init]: TypedFetchParams<P>
): Promise<ApiResponseBody<ApiSpec[P]['response']>> => {
    return toApiResponseSafe(
        () =>
            authedFetch(path, {
                method,
                ...(init && {
                    headers: 'headers' in init ? init.headers : undefined,
                    body: 'body' in init ? stringifyBody(init.body) : undefined,
                }),
            }),
        method,
        path
    )
}

/**
 * createRequest is a curried fetcher for requests without runtime params, queries, or headers.
 *
 * It returns a fetcher function that can accept a request body object.
 *
 * It can be configured with 'addons' for side effects after the response is processed.
 */

export const createRequest =
    <P extends keyof ApiSpec>(
        method: ApiSpec[P]['method'],
        path: string,
        addons: ReadonlyArray<
            (
                response: ApiResponseBody<ApiSpec[P]['response']>
            ) => ApiResponseBody<ApiSpec[P]['response']>
        > = []
    ) =>
    async (
        ...[body]: ApiSpec[P]['method'] extends NoBodyMethods
            ? []
            : ApiSpec[P]['request']['body'] extends never
            ? []
            : [ApiSpec[P]['request']['body']]
    ): Promise<ApiResponseBody<ApiSpec[P]['response']>> => {
        const resBody = await toApiResponseSafe(
            () =>
                authedFetch(path, {
                    method,
                    ...(isBodyMethod(method) && {
                        body: stringifyBody(body),
                    }),
                }),
            method,
            path
        )

        for (const addon of addons) addon(resBody)

        return resBody
    }

export const saveAccessToken =
    <T extends ApiResponseBody<any>>(field: keyof MergeVariants<T>) =>
    (body: T) => {
        if (body.data) {
            saveToken(ID_TOKEN_NAME, body.data[field])
        }
        return body
    }

export const saveRefreshToken =
    <T extends ApiResponseBody<any>>(field: keyof MergeVariants<T>) =>
    (body: T) => {
        if (body.data) {
            saveToken(ID_REFRESH_TOKEN_NAME, body.data[field])
        }
        return body
    }

export const saveToken = (key: string, value: string) => {
    if (value && typeof localStorage !== 'undefined') {
        localStorage.setItem(key, value)
    }
}

export const removeTokens = () => {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(ID_TOKEN_NAME)
        localStorage.removeItem(ID_REFRESH_TOKEN_NAME)
    }
}

export const retry = <T>(
    action: () => Promise<T>,
    options?: {
        times?: number
        timeout?: number
        cooldown?: number
        onRetry?: (count: number) => void
        handleAndValidateResponse?: (response: T) => boolean
    }
) =>
    retryWithTimeout<T>(
        action,
        options?.times,
        options?.timeout,
        options?.cooldown,
        options?.onRetry,
        options?.handleAndValidateResponse
    )
