import "@teamflow/server/../design/dist/tokens/colors.css.js.vanilla.css!=!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"@teamflow/server/../design/dist/tokens/colors.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA42V204CMRCG732K3phgAmR7bvFhzHIQUFhhwQMa393pblu2nZpwY4L/x8z/T9th+qT3x4vidE1+7giZTPznakbObd2cDnW7as6PQ4nOyLbZrNpt+m82I5vTrh5VY1Ldjwmtur8PCcM9wxhQQjuAYkp01LBQqsu0k1W4hMoQjhGdIsZgxHiEWgEA6EpjyEZIjYnTBcNQnTbjhdTzFGGFVIsUEZhYxgFT0FxqUaizChQ1oDnPtmDoOVIaNFfGFDquIwXxmfjnPDaRgknyrozF1PamWi+BksxbUoXjfQ2UgI5WulqFc9lFCjJaNwkjMbWPHcGXcaOXBV9NpKCWdgMVham+xY7Kj90WOh4iZb0lXaCON2Vs43PSth9Wqp9S3eb6+aqb/qGk+nvUpYsj8+9/RL27i/A8Uv3zequDQbjfU5ZSX30VBjfW6FKMiwdg+KaY4zurgILQ6kp0B4yiUOoJ68OiMDSswaHRQhzar8IRVSbsSq7yWmLAyM6yzhE5RJxnGGGGKNwp36hUIwaeX8bEbegg4VIZVoxm/ZAq5t+BRFOqc8QiS/McMcjRYoCo7m6hA1vmCJc5Erfh0G8pV1iIXE+Vu2b9oHT4Tfj9A/c6sglMBwAA\"}!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
import "@teamflow/server/../design/dist/tokens/tokens.css.js.vanilla.css!=!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"@teamflow/server/../design/dist/tokens/tokens.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW207bQBB971esipBAipHH6ztPUBqpX1E5ziY2OHZiOxeo+u+ddQiws+MUCEToePbMmd05nk3bpunFn29COM7veP0UbJWbCvf2IwCpgPXBgLxUeASSqfAJ5KciJFCQiphAIdK7BIsQowlixChdgjro2gwxqmSWCkn5chRM184Ro/pUKgKad4GV0RzLVEQ0R4Hl0hxlKhLK96jrpYmf6EFUqXDoSawQo1lrxKi6BjGadY0YTbrROajkVoM0S6dBStlrOXT5VoNU0A5B60z2WjldftAg1fms66GcLwhaZwXY0I51MgC6eJoKsLEd63RADvVTCeBbTglsq0BoewUi2ywQ226BxLYLZIw3ABscLMaccQxgi4PFqRgfwYIxEiwZJ0HBWAlKxkvwyJgJnhg3QcXYCVaMnwDbPbYSNYzLYM3ZDDZ6myzWVldvoZ0u30Kx6yEKKLrVm2rF7ljevUYtZdj5vrUxz/p96V6aIHb+ouydvKl7Vffmuxr7P9v2jQli+9dNrUwQu98dPrjxYpe1V/goWm2eQ1ldm5FyNLInkf5o5AuJDEYjISCh4XjojISi1XC3td20lUS7nGVX7kS4l69/N0AWxMcF6OOB/W3B8ffGjUh88h4vmXi4npznC69vTcLsSKiDA37B5K0kbPFhJjBpTdJZaq4ZkfKqdfjSyR2+JJM7Tz+s0l/OWd3e8BWMxVH2+VeUD7TBoAG4QI92kvriaS+Y0wZ/Ijw5ETIZPXAzRJ+5wbp8P3LfqNFaNzG6eSyQ7mExtocj/MPewRDqfy5DecwQn4SF/8lwbAL/XAaPpHj8ZBHxKYWO9GOzF8xYzyc5rDuXVzG335U90r3aHuleY490b22PdG/DjHSvZaa311n6ekbfltG3Y/TtGX0HRt8zp++F0Sdd5iYgcdaUdaHa0hxMEsdNQAaZxMHy/RcOsfb7RHRZ3TkdrluYMb5OYm6DDDQXwXBKhBTDcRBRLNa3CoIlTI6M4Zsxa/ORevFNVqkFAfEFlCtdsAnje6YtlwUJxvfEolIHp+uzljwqeJ7ydYWq5+YDdNMs61RVkkuARAt0fav6vDBxNEK3znLlzFS/V6o2n6IldmVXzirChrYoyvmchjfMnUSiNbScrHWWbTYvsZorkMFcLSfiInQfkul0GNkXgYwf8H99CzLtKzdnGKbTn1HiHhmm02QahRxDe5YhdO9+nBgCGUiOobMZ3OP66CGMTzXcR3f3fA39uV0Yfo4M/lx/Tgx//wH4SwB/yg8AAA==\"}!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
import "@teamflow/server/../design/dist/components/Toolbar/Toolbar.css.js.vanilla.css!=!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"@teamflow/server/../design/dist/components/Toolbar/Toolbar.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81W226jMBB971f4ZaV0N46AXJpS7f7IqqocbGAaYlPbJGlX/fc1hrSYW9PLQx8SCWYy9jlz5kxmd/5x97BZch/9u0CIZJBwDJrtVIj2RE4wvlvn22XB5unljUnYkGibSFFw+hK/2j08ruZBFRaSMok3QmuxC5GfH5ESGVA3l9lcCirPyGOI4owdyxcpgyTVrXP9o03OCaXAkxB5rTAx4eeL2QlG0IURMa6ZHL2817h86/xg2wQmCYWiTU2wtymRyIRsFabvQRq7SN0zLt3npc09pAYjVjmJWIi4OEiSu2yEqdgzOUWze4VjERUK70HBJmPoNWfmBEL7ZFkcpAsG8c6rSCFVGcoFVNQ7VyKRhj2bovYt68D40Wzw6KgSwoeAiiNWKaHiYPRlJHv6zM1HJhsy8YNgiq69KQoW6ynyZoEruvmHRAeO6EZH5Qz1dTQWC65xTHaQPbZHueqSTVDwZKTjL3JHl77n/bD6AqpT87j2yngTcdWymj17ub6exC2eKs7xAXQKfLzRjp/01b5v9+CP4WBviw5P4gvAGvCOyAQ4zljcnsZ6wGoCFm38CHhe6HEIrqtwwdnNSWzwZEc8Mi0wQjF+WbXMtA6bUkaG/ls2IQqdAWevdd/lGzUs2+YGrMWYkHOhQIPgIZIsI+WofitPTUje20Ir80Mfk/Pl5Vss10TVoZol4wOk0OJr3HrcLRd/KdEEK000+y347Wd8ebBsHN+Ojs3YD5s+8PlrOYugsSHaNLxjYaw+vjAWZy0M4IppZ20EZ66N5RfRtmp2D3jKJOiO8hvvNTtqbIc8RKXzWSM0LliL3XOKX9ni3e1SWhUFyaLaEsTh5XUp7BCd5D00l/12chrGiGTRZNIeqp/IfP3qTGpliEYRcVZ2JAVKGe8aagPV2qJybPB8kAP+2AW6dvt0bQ+1RVUqgW8t12/+2en3p6jHn+pQye/zf9cspTrZCwAA\"}!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var overflowToggleGroup = '_1xmqb5n8';
export var toggleItemWidth = 'var(--_1xmqb5n0)';
export var toolbar = '_1xmqb5n1';
export var toolbarButton = '_1xmqb5n2';
export var toolbarInput = '_1xmqb5n3';
export var toolbarSeparator = '_1xmqb5n9';
export var toolbarToggleItem = '_1xmqb5n4';
export var toolbarToggleItemHoverStyles = {background:'var(--_7mqy63i)',color:'var(--_7mqy633)'};
export var toolbarToggleItemSelected = '_1xmqb5n5';
export var toolbarToggleItemText = '_1xmqb5n6';
export var toolbarToogleItemWrap = '_1xmqb5n7';