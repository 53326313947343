import EventEmitter from 'eventemitter3'

import { event, when } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import Events from './events'
import { createSharedState } from './sharedState'

export abstract class RealtimeStateContainer {
    protected events = new EventEmitter()
    protected sharedState = createSharedState()

    addListener(event: string, fn: (...args: any[]) => void, context?: any) {
        this.events.addListener(event, fn, context)
    }

    removeListener(event: string, fn: (...args: any[]) => void, context?: any) {
        this.events.removeListener(event, fn, context)
    }

    get furniture() {
        return this.sharedState.furniture
    }

    get roomLayout() {
        return this.sharedState.roomLayout
    }

    get participants() {
        return rootStore.participants.participants
    }

    get totalParticipants() {
        return rootStore.participants.participants.length
    }

    get deployment() {
        return this.sharedState.deployment
    }

    getState(): t.IVerseState | undefined {
        return this.sharedState.activeRoom?.state
    }

    getAssets(): Map<string, t.IIndexedAsset> | null {
        return this.sharedState.getAssets()
    }

    getIndexedAsset(index: number): t.IIndexedAsset | null {
        return this.sharedState.getIndexedAsset(index)
    }

    getFurnitureOnFloor() {
        return [...this.furniture].filter((furn) => furn.locationId === '')
    }

    getFurnitureInRoom(id: string) {
        return [...this.furniture].filter((furn) => furn.locationId === id)
    }

    getFurniture(id: string) {
        return this.sharedState.getFurniture(id)
    }

    getParticipant(id: string) {
        return rootStore.participants.participantById.get(id)
    }

    getBackgroundImage() {
        return this.sharedState.backgroundImage
    }

    getPortals() {
        return this.sharedState.getPortals()
    }

    getPortal(id: string) {
        return this.sharedState.getPortal(id)
    }

    getPortalsTo(to: string) {
        return this.sharedState.getPortalsTo(to)
    }

    getPortalsInRoom(roomId: string) {
        return [...this.getPortals()].filter(
            (portal) => portal.locationId === roomId
        )
    }

    getAudioZones() {
        return this.sharedState.getAudioZones()
    }

    getAudioZone(id: string) {
        return this.sharedState.getAudioZone(id)
    }

    getAudioZonesOnFloor() {
        return [...this.getAudioZones()].filter(
            (audioZone) => audioZone.locationId === ''
        )
    }

    getAudioZonesInRoom(roomId: string) {
        return [...this.getAudioZones()].filter(
            (audioZone) => audioZone.locationId === roomId
        )
    }

    getPin(id: string) {
        return this.sharedState.getPin(id)
    }

    getPins() {
        return this.sharedState.getPins()
    }

    getPinsOnFloor() {
        return [...this.getPins()].filter((pin) => pin.locationId === '')
    }

    getPinsInRoom(roomId: string) {
        return [...this.getPins()].filter((pin) => pin.locationId === roomId)
    }

    getSpeakerCircle(id: string) {
        return this.sharedState.getSpeakerCircle(id)
    }

    getSpeakerCircles() {
        return this.sharedState.getSpeakerCircles()
    }

    getSpeakerCirclesOnFloor() {
        return [...this.getSpeakerCircles()].filter(
            (circle) => circle.locationId === ''
        )
    }

    getSpace(id: string): t.IRealtimeSpace | undefined {
        return this.sharedState.getSpace(id)
    }

    getWall(id: string, location: string): t.IPopulatedWall | undefined {
        return this.sharedState.getWall(id, location)
    }

    getWallsInRoom(roomId: string): t.IPopulatedWall[] {
        return this.sharedState.getWallsInRoom(roomId)
    }

    getSpeakerCirclesInRoom(roomId: string) {
        return [...this.getSpeakerCircles()].filter(
            (circle) => circle.locationId === roomId
        )
    }

    /* One day, somebody will take a broom, put it on fire, and burn this code with the broom. */

    @event(Events.OnConnectionError)
    declare onConnectionError: t.ListenerRegistrationFn<Error>

    @event(Events.OnJoined)
    declare onJoined: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.RoomInvitedIdsAdded)
    declare onRoomInvitedIdsAdded: t.EnhancedListenerRegistrationFn<
        [t.RoomSessionForRealTime] // participant id, room session
    >

    @event(Events.OnLeft)
    declare onLeft: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.OnDropped)
    declare onDropped: t.ListenerRegistrationFn<number>

    @event(Events.AudioZoneAdded)
    declare onAudioZoneAdded: t.ListenerRegistrationFn<t.IAudioZone>

    @event(Events.AudioZoneChanged)
    declare onAudioZoneChanged: t.ListenerRegistrationFn<t.IAudioZone>

    @event(Events.AudioZoneRemoved)
    declare onAudioZoneRemoved: t.ListenerRegistrationFn<t.IAudioZone>

    /**
     * Firest.WhenFn an app is added, changed, or removed from the room state.
     */
    @event(Events.AppUpdated)
    declare onAppUpdated: t.ListenerRegistrationFn<t.IApp>

    @event(Events.OnAppOpened)
    declare onAppOpened: t.ListenerRegistrationFn<t.IAppOpenedInfo>

    @event(Events.OnAppClosed)
    declare onAppClosed: t.ListenerRegistrationFn<t.IAppClosedInfo>

    @event(Events.StateChanged, { once: true })
    declare onceStateChanged: t.ListenerRegistrationFn<t.IVerseState>

    @event(Events.StateChanged)
    declare onStateChanged: t.ListenerRegistrationFn<t.IVerseState>

    @event(Events.RoomSpatialAudioChanged)
    declare onRoomSpatialAudioChanged: t.EnhancedListenerRegistrationFn<
        [t.BETA_IRoom, boolean, boolean]
    >

    @event(Events.onBackgroundImageChanged)
    declare onBackgroundImageChanged: t.ListenerRegistrationFn<{
        backgroundImage?: string
        backgroundPattern?: t.BackgroundPattern
    }>

    @event(Events.ParticipantsUpdated)
    declare onParticipantsUpdated: t.ListenerRegistrationFn<t.IParticipant[]>

    // Difference between this and onParticipantAdded: this is for every single participant in the Daily call — onParticipantAdded is only for folks in your current floor
    @event(Events.ParticipantAdded)
    declare onParticipantAdded: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.ParticipantChanged)
    declare onParticipantChanged: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.ParticipantReadyChanged)
    declare onParticipantReadyChanged: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.ParticipantWasMoved)
    declare onParticipantWasMoved: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.ParticipantRemoved)
    declare onParticipantRemoved: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.ParticipantLocationChanged)
    declare onParticipantLocationChanged: t.ListenerRegistrationFn<t.IParticipant>

    // participant was deleted by another user
    @event(Events.OnParticipantDelete)
    declare onParticipantDelete: t.ListenerRegistrationFn<string>

    /**
     * Firedt.WhenFn a participant is added, changed, or removed from the room state.
     */
    @event(Events.ParticipantUpdated)
    declare onParticipantUpdated: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.ParticipantPositionChanged)
    declare onParticipantPositionChanged: t.ListenerRegistrationFn<t.IParticipant>

    /**
     * Firedt.WhenFn a participant cursor moves
     */
    @event(Events.ParticipantCursorChanged)
    declare onParticipantCursorChanged: t.ListenerRegistrationFn<t.IParticipantCursorChanged>

    @event(Events.AppChanged)
    declare onAppChanged: t.ListenerRegistrationFn<t.IApp>

    @event(Events.OnRoomLocked)
    declare onRoomLocked: t.ListenerRegistrationFn<t.IRoomSessionLockedMessage>

    @event(Events.OnRoomUnlocked)
    declare onRoomUnlocked: t.ListenerRegistrationFn<t.IRoomSessionUnlockedMessage>

    @event(Events.EphemeralReaction)
    declare onEphemeralReaction: t.ListenerRegistrationFn<t.IParticipantEphemeralReactionMessage>

    @event(Events.OnRoomKnock)
    declare onRoomKnock: t.ListenerRegistrationFn<t.IRoomKnockMessage>

    @event(Events.OnRoomKnockResponse)
    declare onRoomKnockResponse: t.ListenerRegistrationFn<t.IRoomKnockResponseMessage>

    @event(Events.OnFloorKnock)
    declare onFloorKnock: t.ListenerRegistrationFn<t.IFloorKnockAction>

    @event(Events.OnFloorKnockResponse)
    declare onFloorKnockResponse: t.ListenerRegistrationFn<{ id: string }>

    @event(Events.OnDisconnect)
    declare onDisconnect: t.ListenerRegistrationFn<{
        consented: boolean
        code: t.ConnectionDroppedCode
        deployment?: boolean
    }>

    @event(Events.OnReconnected)
    declare onReconnected: t.ListenerRegistrationFn

    @event(Events.OnDeployStarting)
    declare onDeployStarting: t.ListenerRegistrationFn<t.IDeployData>

    @event(Events.OnDeployCancelled)
    declare onDeployCancelled: t.ListenerRegistrationFn<void>

    @event(Events.FurnitureAdded)
    declare onFurnitureAdded: t.ListenerRegistrationFn<t.IFurnitureConfig>

    @event(Events.FurnitureChanged)
    declare onFurnitureChanged: t.ListenerRegistrationFn<t.IFurnitureConfig>

    @event(Events.FurnitureRemoved)
    declare onFurnitureRemoved: t.ListenerRegistrationFn<t.IFurnitureConfig>

    @event(Events.GetUser)
    declare onGetUser: t.ListenerRegistrationFn<t.IGetUser>

    @event(Events.TapUser)
    declare onTapUser: t.ListenerRegistrationFn<t.ITapUser>

    @event(Events.NotifyNearbyScreenShare)
    declare onNotifyNearbyScreenShare: t.ListenerRegistrationFn<t.INotifyNearbyScreenShare>

    @event(Events.PresentationEnded)
    declare onPresentationEnded: t.ListenerRegistrationFn<{
        presenterId: string
    }>

    @event(Events.MeetLater)
    declare onMeetLater: t.ListenerRegistrationFn<t.IMeetLater>

    @event(Events.MuteUser)
    declare onMuteUser: t.ListenerRegistrationFn<t.IMuteUser>

    @event(Events.PinAdded)
    declare onPinAdded: t.ListenerRegistrationFn<t.IPin>

    @event(Events.PinChanged)
    declare onPinChanged: t.ListenerRegistrationFn<t.IPin>

    @event(Events.PinRemoved)
    declare onPinRemoved: t.ListenerRegistrationFn<t.IPin>

    @event(Events.PortalAdded)
    declare onPortalAdded: t.ListenerRegistrationFn<t.IPortal>

    @event(Events.PortalChanged)
    declare onPortalChanged: t.ListenerRegistrationFn<t.IPortal>

    @event(Events.PortalRemoved)
    declare onPortalRemoved: t.ListenerRegistrationFn<t.IPortal>

    @event(Events.PortalUpdated)
    declare onPortalUpdated: t.ListenerRegistrationFn<t.IPortal>

    @event(Events.PromoteGuest)
    declare onPromoteGuest: t.ListenerRegistrationFn<t.IPromoteGuest>

    @event(Events.OnActiveEventChanged)
    declare onActiveEventChanged: t.ListenerRegistrationFn<t.IActiveEvent>

    @event(Events.OnMoveToFloor)
    declare onMoveToFloor: t.ListenerRegistrationFn

    @event(Events.OnParticipantBlocked)
    declare onParticipantBlocked: t.ListenerRegistrationFn<
        Pick<t.IUser, 'firstName' | 'lastName'>
    >

    @event(Events.OnGoToFloorError)
    declare onGoToFloorError: t.ListenerRegistrationFn<
        t.IServerError<t.NavigationError>
    >

    @event(Events.OnGoToRoomError)
    declare onGoToRoomError: t.ListenerRegistrationFn<
        t.IServerError<t.NavigationError>
    >

    @event(Events.OnRoomError)
    declare onRoomError: t.ListenerRegistrationFn<t.IServerError<t.RoomError>>

    @event(Events.OnInviteRemoved)
    declare onInviteRemoved: t.ListenerRegistrationFn<t.IInviteRemoved>

    @event(Events.OnParticipantSelectedAppId)
    declare onParticipantSelectedAppId: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.OnParticipantFocusModeChanged)
    declare onParticipantFocusModeChanged: t.ListenerRegistrationFn<t.IParticipant>

    @event(Events.OnAcknowledge)
    declare onAcknowledged: t.ListenerRegistrationFn<t.ClientMessage>

    @event(Events.SpeakerCircleAdded)
    declare onSpeakerCircleAdded: t.ListenerRegistrationFn<t.ISpeakerCircle>

    @event(Events.SpeakerCircleChanged)
    declare onSpeakerCircleChanged: t.ListenerRegistrationFn<t.ISpeakerCircle>

    @event(Events.SpeakerCircleRemoved)
    declare onSpeakerCircleRemoved: t.ListenerRegistrationFn<t.ISpeakerCircle>

    @event(Events.WallAdded)
    declare onWallAdded: t.ListenerRegistrationFn<t.IPopulatedWall>

    @event(Events.WallChanged)
    declare onWallChanged: t.ListenerRegistrationFn<t.IPopulatedWall>

    @event(Events.WallRemoved)
    declare onWallRemoved: t.ListenerRegistrationFn<t.IPopulatedWall>

    @event(Events.WallUpdated)
    declare onWallUpdated: t.ListenerRegistrationFn<t.IPopulatedWall>

    @event(Events.OnYoutubeVideoChanged)
    declare onYoutubeVideoChanged: t.ListenerRegistrationFn<t.YouTubePayload>

    @event(Events.OnPermissionUpdated)
    declare onPermissionUpdated: t.ListenerRegistrationFn<t.IBroadcastWithUser>

    @event(Events.SpaceInvalidation)
    declare onSpaceInvalidation: t.IRealtimeService['onSpaceInvalidation']

    @event(Events.SpaceInvalidationAll)
    declare onSpaceInvalidationAll: t.IRealtimeService['onSpaceInvalidationAll']

    @event(Events.onInviteToRoom)
    declare onInviteToRoom: t.ListenerRegistrationFn<t.IInviteToRoom>

    @event(Events.EventOnboardingFinished)
    declare onEventOnboardingFinished: t.ListenerRegistrationFn<void>

    @event(Events.InvokeTriggerAction)
    declare onInvokeAction: t.ListenerRegistrationFn<{
        name: string
        payload: unknown
    }>

    /*t.WhenFn */

    @when(Events.onBackgroundImageChanged, { timeout: 4000 })
    declare mainFloorBackgroundChanged: t.WhenFn<{
        backgroundImage?: string
        backgroundPattern?: t.BackgroundPattern
    }>

    @when(Events.RoomAdded, { timeout: 10000 })
    declare roomAdded: t.WhenFn<t.BETA_IRoom>

    @when(Events.OnRoomLocked)
    declare roomLocked: t.WhenFn<t.IRoomSessionLockedMessage>

    @when(Events.OnRoomUnlocked)
    declare roomUnlocked: t.WhenFn<t.IRoomSessionUnlockedMessage>

    @when(Events.AudioZoneAdded)
    declare audioZoneAdded: t.WhenFn<t.IAudioZone>

    @when(Events.FurnitureAdded)
    declare furnitureAdded: t.WhenFn<t.IFurnitureConfig>

    @when(Events.ParticipantChanged, { timeout: 10000 })
    declare participantChanged: t.WhenFn<t.IParticipant>

    @when(Events.ParticipantWasMoved, { timeout: 1000 })
    declare participantWasMoved: t.WhenFn<t.IParticipant>

    @when(Events.PinAdded, { timeout: 10000 })
    declare pinAdded: t.WhenFn<t.IPin>

    @when(Events.PinRemoved, { timeout: 10000 })
    declare pinRemoved: t.WhenFn<t.IPin>

    @when(Events.SpeakerCircleAdded)
    declare speakerCircleAdded: t.WhenFn<t.ISpeakerCircle>

    @when(Events.SpeakerCircleRemoved)
    declare speakerCircleRemoved: t.WhenFn<t.ISpeakerCircle>

    @when(Events.WallAdded)
    declare wallAdded: t.WhenFn<t.IPopulatedWall>
    @when(Events.WallChanged)
    declare wallChanged: t.WhenFn<t.IPopulatedWall>
    @when(Events.WallRemoved)
    declare wallRemoved: t.WhenFn<t.IPopulatedWall>
    @when(Events.WallUpdated)
    declare wallUpdated: t.WhenFn<t.IPopulatedWall>

    @when(Events.AssetAppended)
    declare assetAppended: t.WhenFn<t.IIndexedAsset>

    /**
     * @returns A Promise that resolves once a matching portal is added. It
     *  times out at 10s.
     * @example
     * const portal = await realtimeServer.portalAdded({ roomId })
     */
    @when(Events.PortalAdded, { timeout: 10000 })
    declare portalAdded: t.WhenFn<t.IPortal>

    // Higher timeout since we want to wait on OnPortalDeleteDenied
    @when(Events.PortalRemoved, { timeout: 30000 })
    declare portalRemoved: t.WhenFn<t.IPortal>

    @when(Events.OnPortalDeleteDenied, { timeout: 10000 })
    declare portalDeleteDenied: t.WhenFn<t.IPortal>

    @when(Events.RoomRemoved, { timeout: 30000 })
    declare roomRemoved: t.WhenFn<t.BETA_IRoom>

    @when(Events.OnRoomDeleteDenied, { timeout: 10000 })
    declare roomDeleteDenied: t.WhenFn<t.BETA_IRoom>

    @when(Events.ParticipantUpdated)
    declare participantUpdated: t.WhenFn<t.IParticipant>

    @when(Events.RoomChanged)
    declare roomChanged: t.WhenFn<t.BETA_IRoom>

    @when(Events.ParticipantReadyChanged)
    declare participantReady: t.WhenFn<t.IParticipant>
}
