import { makeAutoObservable, observable } from 'mobx'

import { AppType } from '@teamflow/types'

import { UIStore } from '../uiStore'

export class Dock {
    readonly uiStore: UIStore

    isSelectingHeadphoneOptions = false
    isSelectingAudioOptions = false
    isSelectingVideoOptions = false
    isSelectingApps = false
    isEnteringEmbedLinkIds = observable.set<string>()
    isSelectingReaction = false
    isSelectingVolume = false

    appPulseEnabled: { [key in AppType]: boolean } = {
        [AppType.Browser]: false,
        [AppType.Codenames]: false,
        [AppType.Countdown]: false,
        [AppType.DrawBattle]: false,
        [AppType.Generic]: false,
        [AppType.Image]: false,
        [AppType.None]: false,
        [AppType.Poker]: false,
        [AppType.Scratchpad]: false,
        [AppType.ScreenShare]: false,
        [AppType.Skribbl]: false,
        [AppType.Tetris]: false,
        [AppType.Whiteboard]: false,
        [AppType.YouTube]: false,
        [AppType.GoogleDocs]: false,
        [AppType.GoogleSheets]: false,
        [AppType.GoogleSlides]: false,
        [AppType.Poll]: false,
        [AppType.Giphy]: false,
        [AppType.Spotify]: false,
        [AppType.Gong]: false,
        [AppType.Trello]: false,
        [AppType.Leaderboard]: false,
        [AppType.Dummy]: false,
    }

    constructor(uiStore: UIStore) {
        this.uiStore = uiStore

        makeAutoObservable(this, { uiStore: false })
    }

    setIsSelectingHeadphoneOptions(isSelecting: boolean) {
        this.isSelectingHeadphoneOptions = isSelecting
    }

    setIsSelectingAudioOptions(isSelecting: boolean) {
        this.isSelectingAudioOptions = isSelecting
    }

    setIsSelectingVideoOptions(isSelecting: boolean) {
        this.isSelectingVideoOptions = isSelecting
    }

    setIsSelectingApps(isSelecting: boolean) {
        this.isSelectingApps = isSelecting
    }

    setIsSelectingReaction(isSelecting: boolean) {
        this.isSelectingReaction = isSelecting
    }

    setAppPulse(appType: AppType, enabled: boolean) {
        this.appPulseEnabled[appType] = enabled
    }

    setIsSelectingVolume(isSelecting: boolean) {
        this.isSelectingVolume = isSelecting
    }

    get isEnteringEmbedLink() {
        return this.isEnteringEmbedLinkIds.size
    }

    /**
     * Whether dock should be hidden automatically (only true in FSM).
     */
}
