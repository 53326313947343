import { request } from '../request'

const getTalkToSpecialist = (userOrgId: string) => {
    return request<'GET /api/sheets/get-talk-to-specialist/:userOrgId'>(
        'GET',
        `/api/sheets/get-talk-to-specialist/${userOrgId}`
    )
}

export default {
    getTalkToSpecialist,
}
