import * as t from '@teamflow/types'

export class ValidateFurnitureCommand
    implements t.ICommand<t.FurnitureValidationData>
{
    readonly messageType = t.ClientMessage.ValidateFurniture

    constructor(private checksum: string, private locationId: string) {}

    execute() {
        return {
            checksum: this.checksum,
            locationId: this.locationId,
        }
    }
}
