import { autorun } from 'mobx'
import { useEffect, useState } from 'react'

import { useFeatureFlag } from '@teamflow/bootstrap'
import rootStore from '@teamflow/store'
import { AppType, RemoteConfig } from '@teamflow/types'

export const MAX_INTEGER_SHIFT_BITS = 32

export function useAppFlags() {
    const { enabled, value } = useFeatureFlag({
        flag: RemoteConfig.AppsEnabled,
        type: 'value',
        subscribe: false,
    })
    const [enabledApps, setEnabledApps] = useState<Set<string>>(new Set())
    const [isAppEnabled, setIsAppEnabled] = useState<
        (type: AppType) => boolean
    >(() => () => false)
    useEffect(
        () =>
            autorun(() => {
                if (!enabled) {
                    return
                }
                let appNames: string[] = []
                if (typeof value === 'number') {
                    const apps = Object.entries(AppType)
                        .filter(
                            ([key, appName]) =>
                                isNaN(Number(appName)) && key !== '-1'
                        )
                        .filter(([num]) => Number(num) < MAX_INTEGER_SHIFT_BITS)
                        .map(([num]) => [num, (value & (1 << Number(num))) > 0])
                        .filter(([, isEnabled]) => isEnabled)
                        .map(([num]) => +num)
                    appNames = apps
                        .map(
                            (app) =>
                                rootStore.appConfig.configsByType.get(app)?.name
                        )
                        .filter(
                            (name): name is NonNullable<typeof name> => !!name
                        )
                } else if (typeof value === 'string') {
                    appNames = value
                        .split(',')
                        .map((appName) => appName.trim())
                        .filter(
                            (name) =>
                                !!rootStore.appConfig.getConfigByName(name)
                        )
                }

                const appNamesSet = new Set(appNames)
                setEnabledApps(appNamesSet)
                setIsAppEnabled(() => (type: AppType) => {
                    switch (type) {
                        // Apps available only to SalesFloor
                        case AppType.Leaderboard:
                            return (
                                rootStore.organization.isSalesFloor &&
                                appNamesSet.has(
                                    rootStore.appConfig.configsByType.get(type)
                                        ?.name ?? ''
                                )
                            )
                        default:
                            return appNamesSet.has(
                                rootStore.appConfig.configsByType.get(type)
                                    ?.name ?? ''
                            )
                    }
                })
            }),
        [enabled, value]
    )

    return [enabledApps, isAppEnabled] as const
}
