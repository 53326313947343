import { LogManager } from '@teamflow/lib'

let reloadForced = false

export function reload(reason: string) {
    LogManager.global.warn(`Reloading: ${reason}`)

    reloadForced = true
    window.location.reload()
}

export function setHref(url: string) {
    LogManager.global.warn(`setHref: ${url}`)

    reloadForced = true
    window.location.href = url
}

export function isForcedReload() {
    return reloadForced
}
