import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export default class AppWindowChangedCommand
    implements t.ICommand<Partial<t.IAppWindowChanged>>
{
    readonly messageType = t.ClientMessage.AppWindowChanged

    private id = ''
    private locationId: string
    private x?: number
    private y?: number
    private z?: number
    private width?: number
    private height?: number
    private minimized?: boolean

    constructor(
        id: string,
        locationId: string,
        x?: number,
        y?: number,
        z?: number,
        width?: number,
        height?: number,
        minimized?: boolean
    ) {
        this.id = id
        this.locationId = locationId
        this.x = x
        this.y = y
        this.z = z
        this.width = width
        this.height = height
        this.minimized = minimized
    }

    execute() {
        return {
            id: this.id,
            locationId: this.locationId,
            x: this.x,
            y: this.y,
            z: this.z,
            width: this.width,
            height: this.height,
            minimized: this.minimized,
        }
    }

    localExecute() {
        // This does not (and should not) update the local RT state, for reasoning see comment inside the localExecute of SaveChangeCommand
        const localApp = rootStore.app.getAppById(this.id)
        localApp?.update({
            x: this.x ?? localApp.x,
            y: this.y ?? localApp.y,
            z: this.z ?? localApp.z,
            width: this.width ?? localApp.width,
            height: this.height ?? localApp.height,
            minimized: this.minimized ?? localApp.minimized,
        })
    }
}
