import * as types from '@teamflow/types'

export class ParticipantCursorChangedCommand
    implements types.ICommand<types.IParticipantCursorMovement>
{
    readonly messageType = types.ClientMessage.ParticipantCursorChanged

    constructor(
        private appId: types.IParticipantCursorMovement['appId'],
        private bufferedX: types.IParticipantCursorMovement['bufferedX'],
        private bufferedY: types.IParticipantCursorMovement['bufferedY'],
        private bufferedTimes: types.IParticipantCursorMovement['bufferedTimes'],
        private duration: types.IParticipantCursorMovement['duration']
    ) {}

    execute(): types.IParticipantCursorMovement {
        return {
            appId: this.appId,
            bufferedX: this.bufferedX,
            bufferedY: this.bufferedY,
            bufferedTimes: this.bufferedTimes,
            duration: this.duration,
        }
    }
}
