import { createRequest, request } from '../request'

const verifyEmail = (email: string) =>
    request<'GET /api/services/emailVerifier/:email'>(
        'GET',
        `/api/services/emailVerifier/${email}`
    )

const getCurrentTime = createRequest<'GET /api/services/currentTime'>(
    'GET',
    '/api/services/currentTime'
)

export default {
    verifyEmail,
    getCurrentTime,
}
