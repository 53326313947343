/**
 * Size of buckets for {@link SpatialHashStore}
 */
export const BUCKET_DIMEN = 512

export type SpatialEntity<EntityType extends string | number = string> = {
    id: string
    type: EntityType
    l: number
    t: number
    r: number
    b: number
    buckets: SpatialHashBucket<EntityType>[]
    /**
     * id to check if the entity is part of the current operation.
     */
    lastSeenId?: number
}

const SYMBOL_FILTER_TYPED_CB = Symbol('spatialHashFilterTypedCb')

interface HitTestFilter<EntityType extends string | number = string | number> {
    (
        l: number,
        t: number,
        r: number,
        b: number,
        entity: SpatialEntity<EntityType>
    ): boolean

    [SYMBOL_FILTER_TYPED_CB]?: EntityType
}

type HitTestFrame = Readonly<{
    left: number
    top: number
    right: number
    bottom: number
}>

type QueryArgs =
    | [HitTestFrame]
    | [number, number, number, number]
    | [number, number]

type QueryCallback<E extends string | number = string> = (
    entity: SpatialEntity<E>
) => void

class SpatialHashBucket<
    EntityType extends string | number = string
> extends Set<string> {
    constructor(readonly entityType: EntityType, readonly id: string) {
        super()
    }
}

/**
 * 2D lookup for all entities the whole world.
 *
 * A spatial hash provides O(1) queries for finding entities in within a particular
 * region in the world. It works by dividing the world into a rectangular buckets, and
 * tracking which entities are in each bucket.
 *
 * This spatial hash implementation references entities by an string identifier. There
 * are two mutating operations one can do on these entities - put and delete.
 *
 * Hint: {@code rootStore.spatialHash} is managed exclusively by the internals of the
 * realtime serve (specifically, the "Connected" state).
 */
export class SpatialHashStore<EntityType extends string | number = string> {
    /**
     * Hit-testing filter for finding intersecting entities
     */
    public readonly FILTER_INTERSECTION: HitTestFilter<EntityType> = (
        l: number,
        t: number,
        r: number,
        b: number,
        entity: SpatialEntity<EntityType>
    ) => !(entity.r < l || entity.b < t || entity.l > r || entity.t > b)

    /**
     * Higher-order filter for filtering based on entity type as well.
     *
     * This uses a special Symbol in the returned function to indicate
     * the entity type. This symbol is then used in the {@link queryByCallback}
     * to pick an optimized execution path
     */
    public readonly FILTER_ENTITY_TYPE_SPECIFIC = (
        type: EntityType,
        filter: HitTestFilter<EntityType>
    ): HitTestFilter<EntityType> => {
        // the only reason we create a new function here is to
        // add the symbol to the function, avoiding mutating the
        // original function
        const filterTypedCb: HitTestFilter<EntityType> = (
            l: number,
            t: number,
            r: number,
            b: number,
            entity: SpatialEntity<EntityType>
        ) => filter(l, t, r, b, entity)

        filterTypedCb[SYMBOL_FILTER_TYPED_CB] = type

        return filterTypedCb
    }

    /**
     * Map of spatial hash buckets per entity type.
     *
     * The SpatialHashBucket is a Set of entity ids.
     */
    private bucketsByEntityType = new Map<
        EntityType,
        Map<string, SpatialHashBucket<EntityType>>
    >()

    /**
     * This is a cache with the all the entity types
     * we ever added to the spatial hash.
     */
    private entityTypesAdded = new Set<EntityType>()

    /** Map for storing all buckets an entity overlaps with. */
    private entityData = new Map<string, SpatialEntity<EntityType>>()

    private lastSeenId = 0

    /**
     * Put the entity {@code id} into the spatial hash.
     *
     * @param id - The identifier for the entity.
     * @param type - Tag identifying the kind of entity.
     * @param l - The left world edge of its bounds.
     * @param t - The top world edge of its bounds.
     * @param r - The right world edge of its bounds.
     * @param b - The bottom world edge of its bounds.
     */
    put(
        id: string,
        type: EntityType,
        l: number,
        t: number,
        r: number,
        b: number
    ): void {
        // Delete this entity, in case it was already in the spatial hash
        this.delete(id)

        const modifiedBuckets: SpatialEntity<EntityType>['buckets'] = []

        const bucketForEntity =
            this.bucketsByEntityType.get(type) ||
            this.bucketsByEntityType.set(type, new Map()).get(type)!

        // Put entity into all the buckets
        this.callByEachBucket(l, t, r, b, (bucketId: string) => {
            const bucket =
                bucketForEntity.get(bucketId) ||
                new SpatialHashBucket(type, bucketId)
            bucket.add(id)

            modifiedBuckets.push(bucket)

            bucketForEntity.set(bucketId, bucket)
        })

        this.entityTypesAdded.add(type)

        // Track the buckets this entity is in
        this.entityData.set(id, {
            id,
            type,
            l,
            t,
            r,
            b,
            buckets: modifiedBuckets,
        })
    }

    /**
     * Delete the entity {@code id} from the spatial hash.
     */
    delete(id: string): void {
        // Find all buckets the entity was in
        const modifiedBuckets = this.entityData.get(id)
        if (!modifiedBuckets) return

        // Pluck the entity out of the buckets
        for (const bucket of modifiedBuckets.buckets) {
            bucket.delete(id)

            // we could delete the bucket here if their size === 0, but
            // we decide not to so it stays in-memory for future operations
        }

        // Remove the bucket-records of the entity
        this.entityData.delete(id)
    }

    /**
     * Clear the spatial hash of all entities.
     */
    clear(): void {
        this.bucketsByEntityType.clear()
        this.entityData.clear()
    }

    /**
     * Hit-test & find all entities fulfilling the filter function with the rectangle.
     */
    private queryCallback(
        callback: QueryCallback<EntityType>,
        ...[filter, l, t, r, b]: [HitTestFilter<EntityType>, ...QueryArgs]
    ): void {
        if (typeof l === 'object') {
            t = l.top
            r = l.right
            b = l.bottom
            l = l.left
        } else {
            r = typeof r === 'undefined' ? l : r
            b = typeof b === 'undefined' ? t : b
        }

        this.lastSeenId += 1

        this.callByEachBucket(l, t as number, r, b as number, (bucketId) => {
            // if filter is for a specific entity type, we can short-circuit the search

            for (const entityType of this.entityTypesAdded) {
                if (
                    filter[SYMBOL_FILTER_TYPED_CB] &&
                    filter[SYMBOL_FILTER_TYPED_CB] !== entityType
                ) {
                    continue
                }
                const bucket = this.bucketsByEntityType
                    .get(entityType)
                    ?.get(bucketId)

                if (!bucket) continue

                for (const entityId of bucket) {
                    const entity = this.entityData.get(entityId)
                    const alreadyProcessedEntity =
                        entity?.lastSeenId === this.lastSeenId

                    if (!entity || alreadyProcessedEntity) {
                        continue
                    }

                    if (
                        filter(
                            l as number,
                            t as number,
                            r as number,
                            b as number,
                            entity
                        )
                    ) {
                        entity.lastSeenId = this.lastSeenId
                        callback(entity)
                    }
                }
            }
        })
    }

    /**
     * Hit-test & find all entities fulfilling the filter function with the rectangle.
     */
    private query(
        ...args: [HitTestFilter<EntityType>, ...QueryArgs]
    ): Set<string> {
        const result = new Set<string>()
        this.queryCallback((entity) => {
            result.add(entity.id)
        }, ...args)
        return result
    }

    /**
     * Hit-test & find all entities with bounds intersecting the passed rectangle, and calls
     * a function for each found entity.
     *
     * See {@link queryByTypedIntersectionCallback} for a high performance alternative.
     */
    queryByIntersection(...args: QueryArgs): Set<string> {
        return this.query(this.FILTER_INTERSECTION, ...args)
    }

    queryByIntersectionCallback(
        callback: QueryCallback<EntityType>,
        ...args: QueryArgs
    ): void {
        this.queryCallback(callback, this.FILTER_INTERSECTION, ...args)
    }

    /**
     * Hit-test entities by intersection for a specific type.
     */
    queryByTypedIntersection(
        type: EntityType,
        ...args: QueryArgs
    ): Set<string> {
        return this.query(
            this.FILTER_ENTITY_TYPE_SPECIFIC(type, this.FILTER_INTERSECTION),
            ...args
        )
    }

    /**
     * Hit-test entities by intersection for a specific type, and calls a function
     * for each found entity.
     */
    queryByTypedIntersectionCallback(
        callback: QueryCallback<EntityType>,
        type: EntityType,
        ...args: QueryArgs
    ): void {
        this.queryCallback(
            callback,
            this.FILTER_ENTITY_TYPE_SPECIFIC(type, this.FILTER_INTERSECTION),
            ...args
        )
    }

    /**
     * Pick out an entity from the spatial hash. Use sparingly.
     *
     * The spatial hash retains only an identifier and the entity's bounds. You should retrieve other
     * information from the {@link RealtimeService} instead.
     */
    pick(
        id: string
    ): Readonly<Exclude<SpatialEntity<EntityType>, 'buckets'>> | undefined {
        return this.entityData.get(id)
    }

    /** Size of the spatial hash, in entities stored. */
    size(): number {
        return this.entityData.size
    }

    /**
     * Call {@code cb} for all buckets overlapping with the rectangular region described by l, t, r, b.
     *
     * The callback's first parameter should be a bucket-ID, and the rest are the passed {@code args}.
     *
     * @param l - The left edge's offset of the region.
     * @param t - The top edge's offset of the region.
     * @param r - The right edge's offset of the region.
     * @param b - The bottom edge's offset of the region.
     * @param cb - The callback to invoke per bucket.
     * @param args - Additional arguments to pass to the callback.
     */
    private callByEachBucket<T extends Array<unknown>>(
        l: number,
        t: number,
        r: number,
        b: number,
        cb: (bucket: string, ...args: T) => void,
        ...args: T
    ) {
        l = Math.floor(l / BUCKET_DIMEN)
        t = Math.floor(t / BUCKET_DIMEN)
        r = Math.floor(r / BUCKET_DIMEN)
        b = Math.floor(b / BUCKET_DIMEN)

        for (let x = l; x <= r; x++)
            for (let y = t; y <= b; y++) cb.call(this, `${x}-${y}`, ...args)
    }
}
