import { api } from '@teamflow/client-api'
import { ParticipantStatusCommand } from '@teamflow/client-realtime'
import rootStore from '@teamflow/store'
import { IRealtimeService } from '@teamflow/types'

import { joinEmojiAndText } from './userStatus'

export default async function setCustomStatus(
    realtimeService: IRealtimeService,
    emoji = '',
    text = ''
) {
    const str = emoji || text ? joinEmojiAndText(emoji, text) : ''

    rootStore.participants.localParticipant?.update({
        status: str,
        statusMetadata: {
            kind: 'normal',
            vendor: 'manual',
        },
    })

    realtimeService.dispatch(new ParticipantStatusCommand(str))
    if (rootStore.slack.userConnected) {
        await api.slack.setStatus({ emoji, text })
    }
}
