import { IEventRemover, LogManager, Task } from '@teamflow/lib'
import { ClientMessage } from '@teamflow/types'
import type { IRealtimeService } from '@teamflow/types'

import type { Room } from 'colyseus.js'

const logger = LogManager.createLogger('SendCertifiedClientMessage', {
    critical: true, // i hope you don't disagree on this one
})

/**
 * The {@link SendCertifiedClientMessage} task is used by {@link RealtimeService#dispatchCertified}
 * to ensure critical messages are received on the server side.
 *
 * It will retry a number of times before failing. To ensure large downlink messages to not expire
 * the message retries, it waits until a state update gets
 *
 * @group Tasks
 */
export default class SendCertifiedClientMessage extends Task {
    private readonly realtime: IRealtimeService
    private readonly room: Room
    private readonly messageType: ClientMessage
    private readonly payload?: any
    private readonly maxRetries: number
    private readonly retryInterval: number[]
    private readonly hasRetryJitter: boolean
    private currentRetryInterval: number
    private currentRetryIntervalJitter: number

    private subscriptions: IEventRemover[] = []

    private accumulatedTime = 0
    private retryCount = 0
    private loggedExtendedDelayOnDownlink = false

    get name() {
        return 'send-certified-message'
    }

    constructor(
        realtime: IRealtimeService,
        room: Room,
        type: ClientMessage,
        payload?: any,
        retries = 5,
        retryInterval: number | [number, ...number[]] = 2000,
        hasRetryJitter = false
    ) {
        super()

        this.realtime = realtime
        this.room = room
        this.messageType = type
        this.payload = payload
        this.maxRetries = retries
        this.retryInterval =
            typeof retryInterval === 'number'
                ? [retryInterval]
                : [...retryInterval] // copy the array to not mutate the original array, this should be pretty fast as this array is always going to be small
        this.hasRetryJitter = hasRetryJitter

        // this will never be undefined given the types of retryInterval
        this.currentRetryInterval = this.retryInterval.shift()!
        this.currentRetryIntervalJitter = this.applyJitter(
            this.currentRetryInterval
        )
    }

    private applyJitter(value: number) {
        return Math.floor(
            (this.hasRetryJitter ? Math.random() + 0.5 : 1) * value // >= 0.5 <= 1.5 of the value
        )
    }

    init() {
        super.init()

        this.realtime
            .onAcknowledged((message) => {
                if (this.messageType !== message) {
                    return
                }

                this.setFinished()
                this.clearSubscriptions()
            })
            .addTo(this.subscriptions)

        this.realtime
            .onDisconnect(() => {
                this.abort()
            })
            .addTo(this.subscriptions)

        this.room.send(this.messageType, this.payload)
    }

    update(dt: number) {
        this.accumulatedTime += dt
        if (this.accumulatedTime < this.currentRetryIntervalJitter) {
            return
        }
        if (!this.realtime.hasReceivedInitialState) {
            if (!this.loggedExtendedDelayOnDownlink)
                logger.warn(
                    `SendCertifiedClientMessage: Delaying ${
                        ClientMessage[this.messageType]
                    } message retry due to downlink saturation`
                )

            this.loggedExtendedDelayOnDownlink = true
            return
        }

        this.accumulatedTime = 0

        if (this.retryCount >= this.maxRetries) {
            // TODO: what else should we do here?
            this.setFailed(
                `Could not deliver message ${
                    ClientMessage[this.messageType]
                } (${this.messageType}) to server`
            )
            return
        }

        // retry sending message
        logger.info(
            `SendCertifiedClientMessage: Retrying ${
                ClientMessage[this.messageType]
            } message now`
        )
        this.room.send(this.messageType, this.payload)

        this.currentRetryInterval =
            this.retryInterval.shift() ?? this.currentRetryInterval
        this.currentRetryIntervalJitter = this.applyJitter(
            this.currentRetryInterval
        )
        ++this.retryCount
    }

    onAbort() {
        this.clearSubscriptions()
    }

    private clearSubscriptions() {
        this.subscriptions.forEach((sub) => sub.remove())
        this.subscriptions.length = 0
    }
}
