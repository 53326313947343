import useSWR from 'swr'

import { api } from '@teamflow/client-api'
import { getPublicApiUrl } from '@teamflow/lib'
import { IAPIUserOrganization } from '@teamflow/types'

const URL = getPublicApiUrl('/api/userOrg/all')

export function useUserOrgs(): [IAPIUserOrganization[], () => void] {
    const { data, mutate } = useSWR(URL, () => api.userOrg.all(), {
        refreshInterval: 3 * 60 * 1000,
        revalidateOnFocus: false,
    })

    return [data?.data ?? [], mutate]
}
