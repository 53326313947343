import { request } from '../request'

const redirect = ({
    redirect_uri,
    state,
}: {
    redirect_uri: string
    state: string
}) => {
    const params = new URLSearchParams()

    params.append('redirect_uri', redirect_uri)
    params.append('state', state)

    return request<'GET /api/oauth/redirect'>(
        'GET',
        `/api/oauth/redirect?${params.toString()}`
    )
}

export default {
    redirect,
}
