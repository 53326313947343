import * as t from '@teamflow/types'

export default class AppUrlUpdateCommand
    implements t.ICommand<t.IAppUrlUpdate>
{
    readonly messageType = t.ClientMessage.AppUrlUpdated

    private id = ''
    private url = ''
    private width = 0
    private height = 0
    private locationId = ''

    constructor(
        id: string,
        url: string,
        width: number,
        height: number,
        locationId: string
    ) {
        this.id = id
        this.url = url
        this.width = width
        this.height = height
        this.locationId = locationId
    }

    execute() {
        return {
            id: this.id,
            url: this.url,
            width: this.width,
            height: this.height,
            locationId: this.locationId,
        }
    }
}
