import * as Sentry from '@sentry/react'

import { IFurnitureCache, verseDB } from '@teamflow/bootstrap'
import {
    sortByDistanceFromUser,
    result,
    REALTIME_FURNITURE_LOADED,
    LogManager,
} from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import { RequestFurnitureCommand } from '../../commands/RequestFurnitureCommand'
import Events from '../../events'

import { Connect, StateConnector } from './StateConnector'
import { putOrRemoveFromHash } from './helpers'

const putFurnitureInHash = (item: t.IFurnitureConfig) => {
    rootStore.spatialHash.put(
        item.id,
        'furniture',
        item.transform.tx,
        item.transform.ty,
        item.transform.tx + item.width * item.transform.scale,
        item.transform.ty + item.height * item.transform.scale
    )
}

const BATCH_SIZE = 50
const DEFAULT_FURNITURE_PAGE_SIZE = 200

const logger = LogManager.createLogger('FurnitureConnector')
// not critical (who cares abt furniture)
// offended? but i wrote furniture originally ^_^

/** @group Connectors */
@Connect({ key: 'furniture' })
export class FurnitureConnector extends StateConnector<'furniture'> {
    /** Used to make sure we don't request furniture twice for the same room */
    private lastLocationIdRequestedFurniture: null | string = null

    protected batchAddFurnitureTimeout: number | null = null

    protected addFurnitureInBatch(
        furniture: t.IFurnitureConfig[]
    ): Promise<void> {
        return new Promise((resolve) => {
            const doBatch = (furniture: t.IFurnitureConfig[]) => {
                if (!furniture.length) {
                    return resolve()
                }

                this.batchAddFurnitureTimeout = null

                const furnituresToAdd = furniture.splice(0, BATCH_SIZE)

                this.addFurniture(furnituresToAdd)

                const boundFn = () => {
                    doBatch(furniture)
                }

                this.batchAddFurnitureTimeout =
                    window.requestAnimationFrame(boundFn)
            }

            doBatch(furniture)
        })
    }

    protected clearAddFurnitureTimeout() {
        if (!this.batchAddFurnitureTimeout) return

        window.cancelAnimationFrame(this.batchAddFurnitureTimeout)

        this.batchAddFurnitureTimeout = null
    }

    onAttach() {
        const room = this.sharedState.activeRoom
        if (!room) return

        this.realtime.onceStateChanged(() => {
            void this.requestFurniture()
        })

        const startTime = Date.now()

        room.onMessage(
            t.ServerMessage.FurnitureAdded,
            (item: t.IFurnitureConfig) => {
                putOrRemoveFromHash(item, putFurnitureInHash)
                this.sharedState.addFurniture(item)
                this.events.emit(Events.FurnitureAdded, item)
            }
        )

        room.onMessage(
            t.ServerMessage.FurnitureChanged,
            ({ furniture }: { furniture: t.IFurnitureConfig }) => {
                if (!furniture.id) {
                    console.error('Tried to update furniture without id')
                    return
                }

                this.sharedState.addFurniture(furniture)
                /* Note: Furniture IDs *never* change. So mapping is intact :+1 */
                putOrRemoveFromHash(furniture, putFurnitureInHash)

                if (furniture.locationId === rootStore.commons.viewingSpace)
                    this.throttledEmitter.emit(
                        Events.FurnitureChanged,
                        furniture.id,
                        furniture
                    )
            }
        )

        room.onMessage(
            t.ServerMessage.FurnitureRemoved,
            (item: t.IFurnitureConfig) => {
                rootStore.spatialHash.delete(item.id)
                // TODO: Cancel throttled-emitter so it doesn't emit any pending Change ev
                this.sharedState.removeFurniture(item)
                this.events.emit(Events.FurnitureRemoved, item)
            }
        )

        room.onMessage(
            t.ServerMessage.ReportFurnitureValidation,
            (isValid: boolean) => {
                if (isValid) {
                    logger.info('✅ Furniture is valid')
                } else {
                    logger.error('❌ Furniture is not valid')
                }
            }
        )

        room.onMessage(
            t.ServerMessage.SendFurnitureData,
            async (data: t.IFurnitureData) => {
                const locationId = data.locationId
                const orgId = rootStore.organization.data?._id

                logger.info(`Receiving furniture`, {
                    locationId,
                    isCacheValid: data.isCacheValid,
                    page: data.page,
                    pageSize: data.pageSize,
                    done: data.done,
                })

                // Use cached furniture instead if data is up to date
                if (data.isCacheValid && verseDB && orgId) {
                    logger.debug('Furniture cache valid')

                    const [error, furnitureCache] = await result(
                        verseDB.loadFurniture(orgId, locationId)
                    )

                    if (error) {
                        Sentry.captureException(error, {
                            tags: {
                                furniture_cache_error: true,
                            },
                        })
                        logger.error('Unable to load furniture from cache', {
                            error,
                        })
                    }

                    // we already checked if furniture existed in Connected
                    if (!furnitureCache) return

                    // add it in batches so we do not block the main thread in case there are a lot of furnitures
                    // to do that we are splitting it in batches
                    await this.addFurnitureInBatch(
                        furnitureCache.furniture.sort(
                            sortByDistanceFromUser({ x: data.x, y: data.y })
                        )
                    )

                    this.analytics.track(REALTIME_FURNITURE_LOADED, {
                        ms: Date.now() - startTime,
                        furnitureCount: furnitureCache.furniture.length,
                        locationId,
                        loadedFromCache: true,
                    })

                    return
                }

                logger.debug(
                    'Furniture cache invalid, receiving furniture from realtime server'
                )

                this.addFurniture(data.furniture)

                if (data.done) {
                    const furnitureToSave = this.sharedState.furniture.filter(
                        (f) => f.locationId === locationId
                    )

                    if (verseDB && orgId) {
                        const payload: IFurnitureCache = {
                            furniture: furnitureToSave,
                            updatedAt: data.updatedAt,
                            locationId,
                            orgId,
                        }

                        // Update cache with final furniture
                        const [error] = await result(
                            verseDB.saveFurniture(payload)
                        )

                        if (error) {
                            Sentry.captureException(error, {
                                tags: {
                                    furniture_cache_error: true,
                                },
                            })
                            logger.error('Unable to save furniture to cache', {
                                error,
                            })
                        }

                        this.analytics.track(REALTIME_FURNITURE_LOADED, {
                            ms: Date.now() - startTime,
                            furnitureCount: furnitureToSave.length,
                            locationId,
                            loadedFromCache: false,
                        })
                    }
                    // if we changed space, there is no need to keep requesting furniture
                } else if (rootStore.commons.viewingSpace === locationId) {
                    this.realtime.dispatch(
                        new RequestFurnitureCommand({
                            locationId: data.locationId,
                            page: data.page + 1,
                            x: data.x,
                            y: data.y,
                            pageSize: data.pageSize,
                        })
                    )
                } else {
                    logger.info(
                        'Furniture received but we are not in the same space anymore',
                        {
                            currentSpace: rootStore.commons.viewingSpace,
                        }
                    )
                }
            }
        )
    }

    onDetach() {
        this.clearAddFurnitureTimeout()
    }

    onSwitchSpace() {
        this.sharedState.clearFurniture()
        this.clearAddFurnitureTimeout()
        void this.requestFurniture()
    }

    // Optimize this function, maybe add all in one batch, or emit one event for multiple furniture
    private addFurniture(furniture: t.IFurnitureConfig[]) {
        for (const f of furniture) {
            putOrRemoveFromHash(f, putFurnitureInHash)
            this.sharedState.addFurniture(f)

            if (f.locationId === rootStore.commons.viewingSpace)
                this.events.emit(Events.FurnitureAdded, f)
        }
    }

    private async requestFurniture() {
        const locationId = rootStore.commons.viewingSpace

        if (this.lastLocationIdRequestedFurniture === locationId) {
            return
        }

        this.lastLocationIdRequestedFurniture = locationId
        const orgId = rootStore.organization.data?._id

        const pageSize =
            this.flags.valueSync(t.RemoteConfig.FurniturePageSize) ??
            DEFAULT_FURNITURE_PAGE_SIZE

        const payload: t.FurnitureRequest = {
            locationId: rootStore.commons.viewingSpace,
            x: rootStore.participants.localParticipant?.x ?? 0,
            y: rootStore.participants.localParticipant?.y ?? 0,
            page: 0,
            pageSize,
        }

        if (verseDB && orgId) {
            const [, cachedFurniture] = await result(
                verseDB.loadFurniture(orgId, locationId)
            )

            if (cachedFurniture) {
                payload.updatedAt = cachedFurniture.updatedAt
            }
        }

        this.realtime.dispatch(new RequestFurnitureCommand(payload))
    }
}
