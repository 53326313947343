import * as t from '@teamflow/types'

export default class MeetLaterCommand implements t.ICommand<t.IMeetLater> {
    readonly messageType = t.ClientMessage.MeetLater

    private initiatedUserId: string
    private askedForUserId: string

    constructor(initiatedUserId: string, askedForUserId: string) {
        this.initiatedUserId = initiatedUserId
        this.askedForUserId = askedForUserId
    }

    execute() {
        return {
            initiatedUserId: this.initiatedUserId,
            askedForUserId: this.askedForUserId,
        }
    }
}
