// reset.css uses this to remove focus styles for non-keyboard interactions
import 'focus-visible/dist/focus-visible'
import { observer } from 'mobx-react'
import { appWithTranslation } from 'next-i18next'

import * as monitor from '@teamflow/web/src/helpers/monitor'
// this import must happen as soon as possible - as it will start instrumentation
import '@teamflow/web/src/observability/tracing'

import { DefaultLayout } from '../components/pageLayouts/DefaultLayout'
import type { AppComponentProps } from '../src/types/nextPageComponent'

import '@teamflow/web/src/style/reset.css'
import '@teamflow/web/src/style/style.css'
// @teamflow/design imports
import 'react-datepicker/dist/react-datepicker.css'

void monitor.initialize()

const TeamflowApp = observer((props: AppComponentProps) => {
    const PageLayout = props.Component.PageLayout ?? DefaultLayout
    return (
        <PageLayout {...props}>
            <props.Component {...props.pageProps} />
        </PageLayout>
    )
})

export default appWithTranslation(TeamflowApp)
