import _ from 'lodash'
import { useEffect, useState } from 'react'

import { useStableEventCallback } from '@teamflow/lib'
import {
    Feature,
    flagDefaults,
    FlagDefaultValues,
    FlagType,
    RemoteJson,
    RemoteJsonTypes,
} from '@teamflow/types'

import { featureFlags } from '../flags'

interface FlagDetails<TFlag extends FlagType> {
    flag: TFlag
    type?: 'flag' | 'value'
    subscribe?: boolean
}

const defaults = {
    type: 'flag',
    subscribe: false,
} as const

/**
 * A hook to evaluate feature-flags and use them in UI components.
 *
 * @param flag - The feature flag to evaluate.
 * @param [flag.subscribe=false] - Listen to changes in the feature flag and re-render with
 *  the latest value if it changes. This should be used sparingly and if possible, offload these
 *  feature flags to the server-side state instead.
 * @param [flag.defaultEnabled] - The fallback state for whether the feature is enabled.
 * @param [flag.defaultValue] - The fallback value for the remote configuration.
 * @returns enabled - If the feature should be enabled.
 * @returns value - The value of the remote configuration, if any.
 */
export function useFeatureFlag<TFlag extends FlagType>(
    flag: FlagDetails<TFlag> | TFlag
): Readonly<{
    enabled: boolean | undefined
    value: FlagDefaultValues[TFlag]
}> {
    // A little logic to support both call signatures:
    //
    //      useFeatureFlag('chat-feature')
    //      useFeatureFlag({
    //          flag: 'chat-feature',
    //          subscribe: true,
    //          type: 'value'
    //      })
    //
    const isStringArg = _.isString(flag)
    const config = isStringArg
        ? ({ ...defaults, flag } as FlagDetails<TFlag>)
        : ({
              ...defaults,
              ...(flag as FlagDetails<TFlag>),
          } as FlagDetails<TFlag>)

    const { flag: flagName, type, subscribe } = config

    const defaultsForFlag = flagDefaults[flagName]
    const defaultValue = defaultsForFlag?.value ?? null

    const [state, setState] = useState(() => {
        // checking syncronously so we don't do a first render with false
        // and then another with true in case the flag value is already in the cache.
        const enabled = featureFlags.isEnabledSync(flagName)
        const value =
            type === 'value'
                ? featureFlags.getConfigValueSync(flagName)
                : defaultValue
        return {
            enabled,
            value: value as FlagDefaultValues[TFlag],
        }
    })

    const fetchFlagEnabledAndValue = useStableEventCallback(
        async function (mountedState: { isMounted: boolean }) {
            const [enabled, value] = await Promise.all([
                featureFlags.isEnabled(flagName as Feature),
                featureFlags.value(flagName),
            ])
            if (!mountedState.isMounted) return
            setState((prevState) => {
                if (prevState.enabled != enabled || prevState.value !== value) {
                    return { enabled, value: value as FlagDefaultValues[TFlag] }
                }
                return prevState
            })
        },
        [flagName]
    )

    const fetchFlagEnabled = useStableEventCallback(
        async function (mountedState: { isMounted: boolean }) {
            const enabled = await featureFlags.isEnabled(flagName as Feature)
            if (!mountedState.isMounted) return
            setState((prevState) => {
                if (prevState.enabled != enabled) {
                    return { ...prevState, enabled }
                }
                return prevState
            })
        },
        [flagName]
    )

    // get initial flag value
    useEffect(() => {
        const mountedState = { isMounted: true }
        const fetchflags = async () => {
            // Using two seperate functions with a little overlapping
            // logic to limit rerenders. If we make two seperate calls
            // for the enabled and value and update individual thats
            // two updates and a render for each one. Instead, if we
            // know we need to get the value do the state update atomic.
            // The mounted state obj is to avoid setting state if the hook
            // has been unmounted, as the fetch may take a while.
            if (type === 'flag') await fetchFlagEnabled(mountedState)
            else await fetchFlagEnabledAndValue(mountedState)
        }
        void fetchflags()

        return () => {
            mountedState.isMounted = false
        }
    }, [fetchFlagEnabled, fetchFlagEnabledAndValue, type])

    // listen for changes that happen while app is running
    useEffect(() => {
        if (!subscribe) return
        return featureFlags.onFlagChanged(
            flagName as Feature,
            (enabled, value) => {
                setState((prevState) => {
                    if (
                        prevState.enabled !== enabled ||
                        prevState.value !== value
                    ) {
                        return {
                            enabled,
                            value: value as FlagDefaultValues[TFlag],
                        }
                    }
                    return prevState
                })
            }
        ).remove
    }, [flagName, subscribe])

    return state
}

export function useRemoteJson<T extends RemoteJson>(
    flag: T
): RemoteJsonTypes[T] {
    const { enabled, value } = useFeatureFlag({
        ...defaults,
        flag,
        type: 'value',
    })
    return featureFlags.getRemoteJsonSync(flag, enabled, value)
}
