import { makeAutoObservable } from 'mobx'

import {
    Role,
    OrgUserFrontendModel,
    UserFrontendModel,
    IProfileUpdated,
    SerializedUser,
    Product,
} from '@teamflow/types'

import { isSameArray } from './helpers/mobx'

import rootStore from '.'

export class OrgUser implements OrgUserFrontendModel {
    _id: string
    timezone: string
    role: Role
    profilePictureUrl: string
    email: string
    emailConfirmed: boolean
    marketingEmail: string
    currentOrgId: string
    accountId: string
    isDeleted: boolean
    auth: 'googleSSO' | 'emailAndPassword'
    preferredCalendar: string
    topDownloadBannerDismissed: boolean
    assignedRoomId: string

    _firstName!: string
    get firstName() {
        return this._firstName
    }
    set firstName(val: string) {
        this._firstName = val?.trim()
    }

    _lastName!: string
    get lastName() {
        return this._lastName
    }
    set lastName(val: string) {
        this._lastName = val?.trim()
    }

    constructor(userObj: OrgUserFrontendModel) {
        makeAutoObservable(this)

        this._id = userObj._id
        this.firstName = userObj.firstName
        this.lastName = userObj.lastName
        this.profilePictureUrl = userObj.profilePictureUrl
        this.timezone = userObj.timezone
        this.isDeleted = userObj.isDeleted
        this.role = userObj.role
        this.email = userObj.email
        this.accountId = userObj.accountId
        this.emailConfirmed = userObj.emailConfirmed
        this.marketingEmail = userObj.marketingEmail
        this.currentOrgId = userObj.currentOrgId
        this.auth = userObj.auth
        this.preferredCalendar = userObj.preferredCalendar
        this.topDownloadBannerDismissed = userObj.topDownloadBannerDismissed
        this.assignedRoomId = userObj.assignedRoomId
    }

    updateProfile(data: IProfileUpdated) {
        if (typeof data.firstName === 'string') this.firstName = data.firstName
        if (typeof data.lastName === 'string') this.lastName = data.lastName
        if (typeof data.profilePictureUrl === 'string')
            this.profilePictureUrl = data.profilePictureUrl
    }

    update(partial: Partial<OrgUser>) {
        Object.entries(partial).forEach(([key, value]) => {
            if (!(key in this)) return
            if (value !== (this as any)[key]) {
                const thisAny = this as any
                const currentFieldValue = thisAny[key]

                // check if an identical array, and if yes, skip
                if (isSameArray(currentFieldValue, value)) {
                    return
                }

                thisAny[key] = value
            }
        })
    }

    get fullName() {
        return [this.firstName, this.lastName].map((s) => s?.trim()).join(' ')
    }

    get isGuest() {
        return this.role === Role.GUEST
    }

    get isMember() {
        return this.role === Role.USER
    }

    get isAdmin() {
        return this.role === Role.ADMIN || this.role === Role.SUPER_ADMIN
    }

    get assignedRoom() {
        return this.assignedRoomId !== ''
            ? rootStore.rooms.roomsById.get(this.assignedRoomId)
            : undefined
    }
}

export class LocalUser implements UserFrontendModel {
    _id: string
    accountId: string
    activityStatus: string
    allow: string[]
    auth: 'googleSSO' | 'emailAndPassword'
    availabilityStatus: string
    currentOrgCreatedBy: string
    currentOrgDisplayName: string
    currentOrgId: string
    currentOrgName: string
    currentOrgSlug: string
    currentRoomId: string | null
    deny: string[]
    email: string
    emailConfirmed: boolean
    favoriteRoomsIds: string[]
    joinedRoomsIds: string[]
    isDeleted: boolean
    product: Product
    jobTitle: string
    marketingEmail: string
    profilePictureUrl: string
    role: Role
    roles: string[]
    signupDateTime: string
    timezone: string
    x: number
    y: number
    homeX: number
    homeY: number
    homeRoomId: string
    preferredCalendar: string
    topDownloadBannerDismissed: boolean
    assignedRoomId: string

    _firstName!: string
    get firstName() {
        return this._firstName
    }
    set firstName(val: string) {
        this._firstName = val?.trim()
    }

    _lastName!: string
    get lastName() {
        return this._lastName
    }
    set lastName(val: string) {
        this._lastName = val?.trim()
    }

    constructor(userObj: UserFrontendModel) {
        makeAutoObservable(this)

        this._id = userObj._id
        this.accountId = userObj.accountId
        this.activityStatus = userObj.activityStatus
        this.allow = userObj.allow
        this.auth = userObj.auth
        this.availabilityStatus = userObj.availabilityStatus
        this.currentOrgCreatedBy = userObj.currentOrgCreatedBy
        this.currentOrgDisplayName = userObj.currentOrgDisplayName
        this.currentOrgId = userObj.currentOrgId
        this.currentOrgName = userObj.currentOrgName
        this.currentOrgSlug = userObj.currentOrgSlug
        this.currentRoomId = userObj.currentRoomId
        this.deny = userObj.deny
        this.email = userObj.email
        this.emailConfirmed = userObj.emailConfirmed
        this.favoriteRoomsIds = userObj.favoriteRoomsIds
        this.joinedRoomsIds = userObj.joinedRoomsIds
        this.firstName = userObj.firstName
        this.isDeleted = userObj.isDeleted
        this.product = userObj.product
        this.jobTitle = userObj.jobTitle
        this.lastName = userObj.lastName
        this.marketingEmail = userObj.marketingEmail
        this.profilePictureUrl = userObj.profilePictureUrl
        this.role = userObj.role
        this.roles = userObj.roles
        this.signupDateTime = userObj.signupDateTime
        this.timezone = userObj.timezone
        this.x = userObj.x
        this.y = userObj.y
        this.homeX = userObj.homeX
        this.homeY = userObj.homeY
        this.homeRoomId = userObj.homeRoomId
        this.preferredCalendar = userObj.preferredCalendar
        this.topDownloadBannerDismissed = userObj.topDownloadBannerDismissed
        this.assignedRoomId = userObj.assignedRoomId
    }

    updateProfile(data: IProfileUpdated) {
        if (typeof data.firstName === 'string') this.firstName = data.firstName
        if (typeof data.lastName === 'string') this.lastName = data.lastName
        if (typeof data.profilePictureUrl === 'string')
            this.profilePictureUrl = data.profilePictureUrl
    }

    update(partial: Partial<LocalUser>) {
        Object.entries(partial).forEach(([key, value]) => {
            if (!(key in this)) return
            if (value !== (this as any)[key]) {
                const thisAny = this as any
                const currentFieldValue = thisAny[key]

                // check if an identical array, and if yes, skip
                if (isSameArray(currentFieldValue, value)) {
                    return
                }

                thisAny[key] = value
            }
        })
    }

    get fullName() {
        return [this.firstName, this.lastName].map((s) => s?.trim()).join(' ')
    }

    get isGuest() {
        return this.role === Role.GUEST
    }

    get isMember() {
        return this.role === Role.USER
    }

    get isAdmin() {
        return (
            this.role === Role.ADMIN ||
            this.role === Role.SUPER_ADMIN ||
            this.isOwnerOfCurrentOrg
        )
    }

    get googleSSO() {
        return this.auth === 'googleSSO'
    }

    get isOwnerOfCurrentOrg() {
        return this.currentOrgCreatedBy === this.accountId
    }

    get assignedRoom() {
        return this.assignedRoomId !== ''
            ? rootStore.rooms.roomsById.get(this.assignedRoomId)
            : undefined
    }
}

export class GlobalUser implements SerializedUser {
    _id: string
    email: string
    emailConfirmed: boolean
    currentOrgId: string
    marketingEmail: string
    auth: 'googleSSO' | 'emailAndPassword'
    preferredCalendar: string
    topDownloadBannerDismissed: boolean

    _firstName!: string
    get firstName() {
        return this._firstName
    }
    set firstName(val: string) {
        this._firstName = val?.trim()
    }

    _lastName!: string
    get lastName() {
        return this._lastName
    }
    set lastName(val: string) {
        this._lastName = val?.trim()
    }

    get fullName() {
        return `${this.firstName.trim()} ${this.lastName.trim()}`.trim()
    }

    get emailAddress() {
        return this.email || this.marketingEmail || ''
    }

    constructor(userObj: SerializedUser) {
        makeAutoObservable(this)

        this._id = userObj._id
        this.email = userObj.email
        this.emailConfirmed = userObj.emailConfirmed
        this.firstName = userObj.firstName
        this.lastName = userObj.lastName
        this.currentOrgId = userObj.currentOrgId
        this.marketingEmail = userObj.marketingEmail
        this.auth = userObj.auth
        this.preferredCalendar = userObj.preferredCalendar
        this.topDownloadBannerDismissed = userObj.topDownloadBannerDismissed
    }

    update(partial: Partial<SerializedUser>) {
        Object.entries(partial).forEach(([key, value]) => {
            if (!(key in this)) return
            if (value !== (this as any)[key]) {
                ;(this as any)[key] = value
            }
        })
    }
}

export type User = OrgUser | LocalUser
