import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import Router, { NextRouter } from 'next/router'
import { useEffect } from 'react'

import { api } from '@teamflow/client-api'
import { LogManager } from '@teamflow/lib'
import { waitForMilliseconds } from '@teamflow/lib/src/time/waitForSeconds'
import rootStore from '@teamflow/store'
import { Role, UserAnalyticsTraits } from '@teamflow/types'
import { ElectronService } from '@teamflow/web/src/verse/services/ElectronService'

import { DEFAULT_LOCALE, LOCALES, ID_TOKEN_NAME } from '../../constants'
import { page, identify, track } from '../../helpers/analytics'
import * as monitor from '../../helpers/monitor'
import { setUpDebugFunctions } from '../../helpers/tf_debug'
import { useLoadLocalUser } from '../../hooks/useLoadLocalUser'
import { useOrganizationTierState } from '../../hooks/useOrganizationTierState'

const IS_NEW_THRESHOLD = 1000 * 60 * 60 * 24 * 7 // 1 week in milliseconds

// For non-prod builds. Add helper functions to global window
setUpDebugFunctions()

// Track client-side page views with Segment
Router.events &&
    Router.events.on('routeChangeComplete', (url) => {
        page(url)
    })

interface Props {
    children: JSX.Element
    router: NextRouter
    acceptLanguage: string
}

/**
 * The timestamp of the user is an important key for analytics, with this event (server-timestamp) we will be able
 * to detect when a user has an incorrect timestamp. As well we log 10 times the server-timestamp to discard problems
 * related to the network
 */
async function createAnalyticServerTimestamp(userId: string) {
    for (let index = 0; index < 10; index++) {
        if (window.localStorage.getItem(ID_TOKEN_NAME)) {
            const { data } = await api.services.getCurrentTime()

            if (data) {
                track('server-timestamp', {
                    userId: userId,
                    userTimestamp: Date.now(),
                    serverTimestamp: data.currentTime,
                })
            }
        }
        await waitForMilliseconds(60000)
    }
}

const isNew = (timestamp?: number) => {
    return !!timestamp && Date.now() - timestamp < IS_NEW_THRESHOLD
}

const Page = observer(function Page({
    router,
    children,
    acceptLanguage,
}: Props) {
    const { i18n } = useTranslation()

    useLoadLocalUser(router.route)
    const user = rootStore.users.localUser
    const orgSlug = user?.currentOrgSlug
    useOrganizationTierState(router.route, orgSlug)
    const tierType = rootStore.tier.state?.tierType
    const locale = rootStore.settings.locale
    const orgCreatedTime = rootStore.organization.data?.createdAt

    useEffect(() => {
        async function identifyUser() {
            if (user && tierType && orgCreatedTime instanceof Date) {
                const traits: UserAnalyticsTraits = {
                    userId: user._id,
                    email: user.email ?? '',
                    name: `${user.firstName} ${user.lastName}`,
                    orgId: user.currentOrgId,
                    org: user.currentOrgDisplayName,
                    product: rootStore.organization.data?.product,
                    companyName: user.currentOrgDisplayName,
                    role: user.role === Role.GUEST ? 'guest' : 'non-guest',
                    tier: tierType,
                    lastSeen: new Date().toISOString().split('T')[0],
                    newUser: isNew(new Date(user.signupDateTime).getTime()),
                    newOrg: isNew(orgCreatedTime.getTime()),
                }

                try {
                    const inviteCode = (await api.inviteCode.floor.get())?.data
                        ?.inviteCode
                    if (inviteCode && orgSlug) {
                        traits.joinTeamLink = `${process.env.NEXT_PUBLIC_APP_URL}/join/${orgSlug}/${inviteCode}`
                        traits.linkToInvitePage = `${process.env.NEXT_PUBLIC_APP_URL}/join/${orgSlug}/${inviteCode}`
                    }
                } catch (e) {
                    LogManager.ui.warn(
                        'Unable to set user joinTeamLink and linkToInvitePage for analytics.identify call'
                    )
                }

                identify(user._id, traits)
                void monitor.identify(user._id, traits)
                void createAnalyticServerTimestamp(user._id)

                if (ElectronService.available) {
                    ElectronService.identifyAuthedUser(toJS(user))
                }
            }
        }

        void identifyUser()
    }, [user?._id, tierType, user, orgSlug, orgCreatedTime])

    useEffect(() => {
        if (!locale) {
            // Set locale from browser language if not already set
            const newLocale = LOCALES.includes(acceptLanguage)
                ? acceptLanguage
                : DEFAULT_LOCALE
            void rootStore.settings.setLocale(newLocale)
        } else if (i18n?.language && locale !== i18n?.language) {
            // Update locale in i18n lib
            void i18n?.changeLanguage?.(locale)
        }
    }, [locale, i18n?.language, i18n, acceptLanguage])

    return <div className="page">{children}</div>
})

export default Page
