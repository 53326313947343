import { action, makeAutoObservable, ObservableMap, when } from 'mobx'

import * as t from '@teamflow/types'

import { logger } from './logging'
import { RootStore } from './rootStore'

/**
 * Adapter for sending presentation changes to other participants.
 *
 * @see RealtimeService
 */
export interface PresentationAdapter {
    startPresentation(
        presenterId: string,
        appId: string,
        locationId: string
    ): void

    // TODO for @Nathan? - you shouldn't need the participantId argument as only you can make yourself
    // join a presentation
    joinPresentation(
        presenterId: string,
        participantId: string,
        locationId: string
    ): void

    endPresentation(
        presenterId: string,
        shouldInform: boolean,
        locationId: string
    ): void

    leavePresentation(
        presenterId: string,
        attendeeId: string,
        locationId: string
    ): void

    switchPresentationApp(
        presenterId: string,
        appId: string,
        locationId: string
    ): void
}

/** @ignore */
class NullPresentationAdapter implements PresentationAdapter {
    startPresentation(
        presenterId: string,
        _appId: string,
        _locationId: string
    ): void {
        logger.error(`Cannot start ${presenterId}'s presentation!`)
    }

    joinPresentation(
        presenterId: string,
        _participantId: string,
        _locationId: string
    ): void {
        logger.error(`Can't join ${presenterId}'s presentation, not ready`)
    }

    endPresentation(
        presenterId: string,
        _shouldInform: boolean,
        _locationId: string
    ) {
        logger.error(`Can't end ${presenterId}'s presentation, not ready`)
    }

    leavePresentation(
        presenterId: string,
        _participantId: string,
        _locationId: string
    ): void {
        logger.error(`Can't leave ${presenterId}'s presentation, not ready`)
    }

    switchPresentationApp(
        _presenterId: string,
        _appId: string,
        _locationId: string
    ): void {
        logger.error('Cannot switch presentation, not ready!')
    }
}

/**
 * Store for presentations.
 */
export class PresentationStore {
    rootStore: RootStore

    /**
     * A mirror of the realtime's participant state
     */
    presentations: ObservableMap<string, RTPresentation> = new ObservableMap<
        string,
        RTPresentation
    >()

    _adapter: PresentationAdapter = new NullPresentationAdapter()

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            reset: action,
            _adapter: false,
            setAdapter: false,
        })
        this.rootStore = rootStore
    }

    /**
     * Set the presentation state adapter.
     *
     * @param adapter - The adapter used for starting, joining, leaving, and
     *  switching apps between presentations.
     * @return - A callback to reset back to the null adapter.
     * @see PresentationDigester
     */
    public setAdapter(adapter: PresentationAdapter): () => void {
        this._adapter = adapter

        return () => {
            // Only reset if we haven't switched to another adapter!
            if (this._adapter === adapter)
                this._adapter = new NullPresentationAdapter()
        }
    }

    public addPresentation(presentation: t.IPresentation) {
        this.presentations.set(
            presentation.presenterId,
            new RTPresentation(
                this,
                presentation.presenterId,
                presentation.appId,
                presentation.startedAt
            )
        )
    }

    public updateAppId(appId: string, presenterId: string) {
        const presentation = this.presentations.get(presenterId)
        if (!presentation) return

        presentation.updateAppId(appId)
    }

    public removePresentation(presenterId: string) {
        this.presentations.delete(presenterId)
    }

    public addAttendee(
        newAteendee: t.IPresentationAttendee,
        presenterId: string
    ) {
        const presentation = this.presentations.get(presenterId)
        if (!presentation) return

        presentation.addAttendee(newAteendee)
    }

    public removeAttendee(attendeeId: string, presenterId: string) {
        const presentation = this.presentations.get(presenterId)
        if (!presentation) return

        presentation.removeAttendee(attendeeId)
    }

    startPresentation(presenterId: string, appId: string, locationId: string) {
        this._adapter.startPresentation(presenterId, appId, locationId)

        if (!this.rootStore.layout.fullScreenMode) {
            this.rootStore.layout.setPresenterMode(true)
        }
        this.rootStore.participants.nearByParticipants.forEach((p) => {
            if (p.id !== presenterId) {
                this.joinPresentation(presenterId, p.id, locationId)
            }
        })
    }

    joinPresentation(
        presenterId: string,
        participantId: string,
        locationId: string
    ) {
        if (this.presenting) return
        this._adapter.joinPresentation(presenterId, participantId, locationId)
    }

    /**
     * Leaves a presentation.
     *
     * If the presenter is leaving, we end the presentation
     *
     * @param presenterId
     * @param participantId
     */
    leavePresentation(
        presenterId: string,
        participantId: string,
        locationId: string
    ) {
        this._adapter.leavePresentation(presenterId, participantId, locationId)
    }

    /**
     * Ends Presentation
     * @param presenterId
     */
    endPresentation(
        presenterId: string,
        shouldInform: boolean,
        locationId: string
    ) {
        this._adapter.endPresentation(presenterId, shouldInform, locationId)
    }

    /**
     * Request realtime server for an app change
     *
     * @param appId - The app being presented now.
     */
    async switchApp(appId: string, locationId: string) {
        const presenterId = this.currentPresentation?.presenterId
        if (!presenterId || presenterId !== this.rootStore.users.localUserId) {
            // Don't allow non-presenter to switch the app
            return
        }

        this._adapter.switchPresentationApp(presenterId, appId, locationId)
    }

    /**
     * The presentation that I'm currently active in.  Either as a presetner
     * or attendee
     */
    get currentPresentation(): RTPresentation | null {
        const localUserId = this.rootStore.users.localUserId
        const presentationArray = Array.from(this.presentations.values())
        const presenting = presentationArray.find(
            (p) => p.presenterId === localUserId
        )
        if (presenting) return presenting

        const attending = presentationArray.find((p) =>
            Array.from(p.attendees.values()).find(
                (a) => a.attendeeId === localUserId
            )
        )
        if (attending) return attending

        return null
    }

    get isSomeonePresentingInMySpace(): boolean {
        const localParticipant = this.rootStore.participants.localParticipant
        if (!localParticipant) return false

        return Array.from(this.presentations.values()).some((presentation) => {
            const presenterParticipant =
                this.rootStore.participants.findParticipantById(
                    presentation.presenterId
                )
            return (
                localParticipant.locationId === presenterParticipant?.locationId
            )
        })
    }

    get presentationInMySpace(): RTPresentation | undefined {
        const localParticipant = this.rootStore.participants.localParticipant
        if (!localParticipant) return
        const nearByParticipantIds = new Set(
            this.rootStore.participants.nearByParticipants.map((p) => p.id)
        )
        nearByParticipantIds.add(this.rootStore.users.localUserId ?? '')

        return Array.from(this.presentations.values()).find((presentation) => {
            return nearByParticipantIds.has(presentation.presenterId)
        })
    }

    get followingAPresentation(): boolean {
        const localParticipant = this.rootStore.participants.localParticipant
        if (!localParticipant) return false

        for (const p of Array.from(this.presentations.values())) {
            if (p.attendees.get(localParticipant.id)) return true
        }

        return false
    }

    get presenting(): boolean {
        const localParticipant = this.rootStore.participants.localParticipant
        if (!localParticipant) return false

        for (const p of Array.from(this.presentations.values())) {
            if (p.presenterId === localParticipant.id) return true
        }

        return false
    }

    async stopCurrentPresentation(): Promise<void> {
        if (!this.presentationInMySpace) return
        this.endPresentation(
            this.presentationInMySpace.presenterId,
            false,
            this.rootStore.commons.viewingSpace
        )
        await when(() => !this.presentationInMySpace)
    }

    /**
     * Clears out all state.
     */
    reset() {
        this.presentations = new ObservableMap<string, RTPresentation>()
    }
}

export class RTPresentationAttendee implements t.IPresentationAttendee {
    attendeeId: string
    joinedAt: number

    constructor(attendeeId: string, joinedAt = 0) {
        makeAutoObservable(this)
        this.attendeeId = attendeeId
        this.joinedAt = joinedAt
    }

    updateJoinedAt(joined: number) {
        this.joinedAt = joined
    }
}

export class RTPresentation implements t.IPresentation {
    presenterId: string
    appId: string
    attendees: Map<string, RTPresentationAttendee>
    startedAt?: number
    store: PresentationStore

    constructor(
        presentationStore: PresentationStore,
        presenterId: string,
        appId: string,
        startedAt?: number
    ) {
        makeAutoObservable(this, {
            store: false,
        })
        this.store = presentationStore
        this.presenterId = presenterId
        this.appId = appId
        this.startedAt = startedAt
        this.attendees = new Map<string, RTPresentationAttendee>()
    }

    updateAppId(appId: string) {
        this.appId = appId
    }

    /**
     * Shouldn't be used directly.
     *
     * @param attendee
     */
    addAttendee(attendee: t.IPresentationAttendee) {
        const newAttendee = new RTPresentationAttendee(
            attendee.attendeeId,
            attendee.joinedAt
        )
        this.attendees.set(attendee.attendeeId, newAttendee)
    }

    removeAttendee(attendeeId: string) {
        this.attendees.delete(attendeeId)
    }

    /**
     * Request realtime server for an app change
     *
     * @param appId
     */
    async switchApp(appId: string, locationId: string) {
        // TODO: Don't allow non-presenters to change app
        this.store._adapter.switchPresentationApp(
            this.presenterId,
            appId,
            locationId
        )
    }

    get asJson() {
        return {
            presenterId: this.presenterId,
            appId: this.appId,
            startedAt: this.startedAt,
        }
    }
}
