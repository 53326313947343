/**
 * This class is constructed with a URL search string and default flag values
 * when the frontend first loads. It provides a way to override default flags.
 * If `?json=[...]` is present in the search string, the remaining key/value pairs
 * are used to override values in that JSON config.
 *
 * E.g., `?json=video-parameters&VIDEO_WIDTH=960&VIDEO_HEIGHT=720` will override
 * the `json-video-parameters` remote config with the given values.
 */
export class UrlQueryFlagOverrides<
    FlagKey extends string,
    FlagReference extends Record<FlagKey, { value?: unknown }>
> {
    /**
     * If value is a boolean, only feature enabled/disabled is overridden.
     * If value is a number, feature is overridden to enabled, and its value is that number.
     * @private
     */
    private readonly overriddenFlags = new Map<
        FlagKey,
        string | number | boolean | Record<string, string | number>
    >()

    /**
     * @param urlSearch The URL search string, e.g. `window.location.search`.
     * @param flagReference Used to type check values in `urlSearch` and ignore
     * invalid values. Pass in the default flag values here.
     */
    constructor(
        urlSearch: string,
        private readonly flagReference: FlagReference
    ) {
        this.initOverriddenFlags(urlSearch)
    }

    private initOverriddenFlags(urlSearch: string) {
        const params = new URLSearchParams(urlSearch)

        const jsonKey = params.get('json')

        if (jsonKey != null) {
            // all other url params configure the json flag
            const jsonFlag = `json-${jsonKey}`
            if (!this.keyIsFlag(jsonFlag)) return

            const defaultJsonValue = this.getFlagDefaultJsonValue(jsonFlag)
            if (!defaultJsonValue) return

            const jsonOverride: Record<string, string | number> = {}

            params.forEach((value, key) => {
                if (key === jsonKey) return
                if (!this.keyInJson(defaultJsonValue, key)) return

                const defaultValue = defaultJsonValue[key]

                if (typeof defaultValue === 'string') {
                    jsonOverride[key] = value
                } else if (typeof defaultValue === 'number') {
                    const valNum = Number(value)

                    if (!isNaN(valNum)) {
                        jsonOverride[key] = valNum
                    }
                }
            })

            this.overriddenFlags.set(jsonFlag, jsonOverride)
            return
        }

        // otherwise, all url params configure individual flags
        params.forEach((value, key) => {
            if (!this.keyIsFlag(key)) return

            const refValue = this.flagReference[key].value

            if (value.toLowerCase() === 'true') {
                this.overriddenFlags.set(key, true)
            } else if (value.toLowerCase() === 'false') {
                this.overriddenFlags.set(key, false)
            } else if (typeof refValue === 'string') {
                this.overriddenFlags.set(key, value)
            } else if (typeof refValue === 'number') {
                const valNum = Number(value)

                if (!isNaN(valNum)) {
                    this.overriddenFlags.set(key, valNum)
                }
            }
        })
    }

    private keyIsFlag(key: string): key is FlagKey {
        return Object.prototype.hasOwnProperty.call(this.flagReference, key)
    }

    private keyInJson(
        jsonValue: Record<string, unknown>,
        key: string
    ): boolean {
        return Object.prototype.hasOwnProperty.call(jsonValue, key)
    }

    private getFlagDefaultJsonValue(
        key: FlagKey
    ): Record<string, unknown> | null {
        const value = this.flagReference[key].value
        if (typeof value !== 'string') return null

        try {
            const jsonValue = JSON.parse(value)
            if (
                typeof jsonValue !== 'object' ||
                // null is 'object'
                jsonValue === null ||
                // array is 'object'
                Array.isArray(jsonValue)
            )
                return null
            return jsonValue
        } catch {
            return null
        }
    }

    /**
     * Overrides `oldIsEnabled` if `flag` appears in the URL query string.
     */
    augmentIsEnabled(flag: FlagKey, oldIsEnabled: boolean): boolean {
        const overriddenValue = this.overriddenFlags.get(flag)

        return overriddenValue === undefined
            ? oldIsEnabled
            : overriddenValue !== false
    }

    /**
     * Overrides `oldValue` if `flag` appears in the URL query string and its
     * value has the correct type.
     */
    augmentValue<TFlag extends FlagKey>(
        flag: TFlag,
        oldValue: FlagReference[TFlag]['value']
    ): FlagReference[TFlag]['value'] {
        const overriddenValue = this.overriddenFlags.get(flag)

        if (
            typeof overriddenValue === 'object' &&
            typeof oldValue === 'string'
        ) {
            // add overridden JSON keys
            try {
                const augmentedJson = {
                    ...JSON.parse(oldValue),
                    ...overriddenValue,
                }
                return JSON.stringify(augmentedJson)
            } catch {
                // This would happen if the feature flag doesn't accept JSON-encoded values.
                return oldValue
            }
        }

        if (
            typeof overriddenValue === 'string' ||
            typeof overriddenValue === 'number'
        ) {
            return overriddenValue
        }

        return oldValue
    }
}
