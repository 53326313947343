import * as t from '@teamflow/types'

export default class AppOpenedCommand implements t.ICommand<t.IAppOpened> {
    readonly messageType = t.ClientMessage.AppOpened

    private id: string
    private owner: string
    private type: t.AppType
    private url: string | undefined
    private x: number
    private y: number
    private z: number
    private width: number
    private height: number
    private locationId: string
    private title: string
    private dataRawAsRecord: Record<string, string>
    private embedded: boolean

    constructor({
        id,
        owner,
        type,
        url,
        x,
        y,
        z,
        width,
        height,
        locationId,
        title,
        _dataRaw,
        embedded,
    }: t.IApp) {
        this.id = id
        this.owner = owner
        this.type = type
        this.url = url
        this.x = x
        this.y = y
        this.z = z
        this.width = width
        this.height = height
        this.locationId = locationId
        this.title = title
        this.dataRawAsRecord = Object.fromEntries(_dataRaw)
        this.embedded = !!embedded
    }

    execute() {
        return {
            id: this.id,
            owner: this.owner,
            type: this.type,
            url: this.url,
            x: this.x,
            y: this.y,
            z: this.z,
            width: this.width,
            height: this.height,
            locationId: this.locationId,
            title: this.title,
            dataRawAsRecord: this.dataRawAsRecord,
            embedded: this.embedded,
        }
    }
}
