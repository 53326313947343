import { createRequest, request } from '../request'

const checkAccess = ({ scope }: { scope: string }) =>
    request<'GET /api/slack/check-access/:scope'>(
        'GET',
        `/api/slack/check-access/${scope}`
    )

const revokeAccess = ({ scope }: { scope: string }) =>
    request<'GET /api/slack/revoke/:scope'>('GET', `/api/slack/revoke/${scope}`)

const oauth = ({
    code,
    error,
    state,
}: {
    code: string
    error?: string
    state: string
}) => {
    const query = new URLSearchParams()

    query.append('code', code)
    if (error) query.append('error', error)
    query.append('state', state)

    return request<'GET /api/slack/oauth_redirect'>(
        'GET',
        `/api/slack/oauth_redirect?${query.toString()}`
    )
}

const listTeam = createRequest<'GET /api/slack/team'>('GET', '/api/slack/team')
const createMessage = createRequest<'POST /api/slack/message'>(
    'POST',
    '/api/slack/message'
)
const setStatus = createRequest<'POST /api/slack/status'>(
    'POST',
    '/api/slack/status'
)
const resetStatus = ({
    apiKey,
    userOrgId,
}: {
    apiKey?: string
    userOrgId: string
}) =>
    request<'POST /api/slack/reset-status'>('POST', '/api/slack/reset-status', {
        headers: { 'x-api-key': apiKey },
        body: { userOrgId },
    })
const updateSettings = createRequest<'POST /api/slack/updateSettings'>(
    'POST',
    '/api/slack/updateSettings'
)

const reportAVProblem = ({
    lrLink,
    issueType,
}: {
    lrLink: string
    issueType: string
}) =>
    request<'POST /api/slack/av-issue/:issueType'>(
        'POST',
        `/api/slack/av-issue/${issueType}`,
        { body: { lrLink } }
    )

export default {
    checkAccess,
    revokeAccess,
    oauth,
    listTeam,
    createMessage,
    setStatus,
    resetStatus,
    updateSettings,
    reportAVProblem,
}
