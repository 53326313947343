import { reaction } from 'mobx'

import { track } from '@teamflow/bootstrap'
import rootStore from '@teamflow/store'

// Re-export analytics from bootstrap for convenience
export { BACK_BUTTON_INSIDE_ROOM, USER_REFRESHED } from '@teamflow/bootstrap'

export const REMOVED_USER = 'removed_user'
export const USER_ROLE_CHANGED = 'user_role_changed'
export const ORG_PHOTO_CHANGED = 'org_photo_changed'
export const INVOICE_VIEWED = 'invoice_viewed'
export const PAYMENT_UPDATED = 'payment_updated'
export const CREATE_NEW_WORKSPACE = 'create_new_workspace'
export const OPEN_SIDEBAR_POPOUT = 'open_sidebar_popout'
export const SIGNUP_MEETING_MIGRATION = 'signup_meeting_migration'
export const START_IN_FULL_SCREEN_MODE_TOGGLE =
    'start_in_full_screen_mode_toggle'
export const SPATIAL_AUDIO_TOGGLE = 'spatial_audio_toggle'
export const PORTAL_ENTER_ROOM = 'portal_enter_room'
export const PORTAL_GOTO_FLOOR = 'portal_goto_floor'
export const PREFERENCES_CHANGED = 'preferences_changed'
export const AV_DESYNC_LOCAL = 'av_desync_local'
export const LEFT_ROOM = 'left_room'
export const GUEST_HANG_UP = 'guest_hang_up'
export const DISCONNECT_FROM_CALL = 'disconnect_from_call'
export const SEARCH_OPENED = 'search_opened'
export const SEARCH_QUERY = 'search_query'
export const SEARCH_RESULT_SELECTED = 'search_result_selected'
export const SEND_THIS_LINK = 'send_this_link'
export const SLACK_APP_CONNECT_CLICKED = 'slack_app_connect_clicked'
export const SLACK_APP_REVOKED = 'slack_app_revoked'
export const SLACK_APP_CONNECTED = 'slack_app_connected'
export const SLACK_USER_CONNECT_CLICKED = 'slack_user_connect_clicked'
export const SLACK_USER_REVOKED = 'slack_user_revoked'
export const SLACK_USER_CONNECTED = 'slack_user_connected'
export const AUDIOTRACK_EVENT = 'audiotrack_event'
export const TRAY_ACTION = 'tray_action'
export const AV_AUTO_FIXING = 'av_auto_fixing'
export const CHANGE_AUDIO_SOURCE = 'change_audio_source'
export const CHANGE_OUTPUT_SOURCE = 'change_output_source'
export const CHANGE_VIDEO_SOURCE = 'change_video_source'
export const AV_MODAL_NO_CLICKED = 'av_modal_no_clicked'
export const AV_MODAL_YES_CLICKED = 'av_modal_yes_clicked'
export const AUTO_MUTE_DELAY_SET = 'auto_mute_delay_set'
export const ERROR_MODAL_RELOAD = 'error_modal_reload'
export const ERROR_MODAL_DISMISS = 'error_modal_dismiss'
export const SETTINGS_CHANGED_LANGUAGE = 'settings_changed_language'
export const SETTINGS_MOVEMENT_SPEED = 'settings_movement_speed'
export const SETTINGS_LITE_MODE = 'settings_lite_mode'
export const SETTINGS_SCROLL_TO_ZOOM = 'settings_scroll_to_zoom'
export const SETTINGS_OPEN_PIP_ON_SCREEN_SHARE =
    'settings_open_pip_on_screen_share'
export const SETTINGS_ENABLE_SOUND = 'settings_enable_sound'
export const SETTINGS_NOTIFICATIONS = 'settings_notifications'
export const SETTINGS_EMAIL_NOTIFICATIONS = 'settings_notifications'
export const SETTINGS_CALENDAR_STATUS_UPDATES =
    'settings_calendar_status_updates'
export const SETTINGS_AUTO_FACE_CENTERING = 'settings_auto_face_centering'
export const SETTINGS_MANUAL_FACE_ZOOM = 'settings_manual_face_zoom'
export const SETTINGS_MANUAL_FACE_OFFSET_X = 'settings_manual_face_offset_x'
export const SETTINGS_MANUAL_FACE_OFFSET_Y = 'settings_manual_face_offset_y'
export const SETTINGS_FLIP_CAMERA = 'settings_flip_camera'
export const SETTINGS_REMEMBER_AV_STATE = 'settings_remember_av_state'
export const SETTINGS_PUSH_TO_TALK = 'settings_push_to_talk'
export const SETTINGS_SCREEN_SHARE_PRESET = 'settings_screen_share_preset'
export const SETTINGS_AUTO_SPATIAL_RIBBON_TOGGLE =
    'settings_auto_spatial_ribbon_toggle'
export const SETTINGS_BACKGROUND_BLUR = 'settings_background_blur'
export const SETTINGS_ALWAYS_DISPLAY_AV_SETUP_TOGGLED =
    'settings_always_display_av_setup_toggled'
export const SLACK_NOTIFY_TEAM_COUNT = 'slack_notify_team_count'
export const SIDEBAR_ADD_TO_TEAM_BUTTON_CLICKED =
    'sidebar_add_to_team_button_clicked'
export const SIDEBAR_EXPANDED = 'sidebar_expanded'
export const SIDEBAR_ROOMLIST_CLICK_ON_USER = 'sidebar_roomlist_click_on_user'
export const SIDEBAR_CLICK_ON_USER = 'sidebar_click_on_user'
export const ROOM_ENTERED = 'room_entered'
export const FLOOR_ENTERED = 'floor_entered'
export const MOVED_OWN_BUBBLE = 'moved_own_bubble'
export const USER_PERFORMANCE_NAVIGATION = 'USER_PERFORMANCE_NAVIGATION'
export const APP_RENAMED = 'app_renamed'
export const APP_LOCKED = 'app_locked'
export const APP_UNLOCKED = 'app_unlocked'
export const CUSTOMIZE_MODE_TOGGLE = 'customize_mode_toggle'
export const AUDIO_TOGGLE = 'audio_toggle'
export const VIDEO_TOGGLE = 'video_toggle'
export const BACKEND_GUEST_CREATED = 'backend_guest_created'
export const USER_STARTED_SUBSCRIPTION = 'user_started_subscription'
export const SHORTCUT_CHANGE_AVAILABILITY_STATUS =
    'keyboard_shortcut_changed_availability_status'
export const SIDEBAR_CHANGE_AVAILABILITY_STATUS =
    'sidebar_header_changed_availability_status'
export const PARTICIPANT_CHANGE_AVAILABILITY_STATUS =
    'participant_menu_changed_availability_status'
export const CENTER_MAP = 'center_map'
export const ZOOM_TO = 'zoom_to'
export const WHATS_NEW_CLICK = 'whats-new-click'
export const MEETING_RECORDING_STARTED = 'meeting-recording-started'
export const MEETING_RECORDING_STOPPED = 'meeting-recording-stopped'
export const AV_SETUP_AUDIO_DEVICE_CHANGE = 'av_setup_audio_device_change'
export const AV_SETUP_SPEAKER_DEVICE_CHANGE = 'av_setup_speaker_device_change'
export const AV_SETUP_VIDEO_DEVICE_CHANGE = 'av_setup_video_device_change'
export const AV_SETUP_AUDIO_TOGGLE = 'av_setup_audio_toggle'
export const AV_SETUP_VIDEO_TOGGLE = 'av_setup_video_toggle'
export const AV_SETUP_JOIN = 'av_setup_join'
export const AV_DROPPED_HELP = 'av_dropped_help'
export const AUDIO_ZONE_ENTER_VERSE = 'audiozone_enter_via_verse'
export const AUDIO_ZONE_ENTER_SIDEBAR = 'audiozone_enter_via_sidebar'
export const AUDIO_ZONE_RENAME = 'audiozone_rename'
export const AUDIO_ZONE_DELETE = 'audiozone_delete'
export const ONBOARDING_BOT_STARTED = 'onboarding_bot_started'
export const ONBOARDING_BOT_FINISHED = 'onboarding_bot_finished'
export const ONBOARDING_BOT_SKIPPED = 'onboarding_bot_skipped'
export const REACTION_EPHEMERAL = 'reaction_ephemeral'
export const REACTION_STICKY = 'reaction_sticky'
export const PUSH_TO_TALK_USED = 'push_to_talk'
export const USER_ENTERED_SPEAKER_CIRCLE = 'user-entered-speaker-circle'
export const USER_EXITED_SPEAKER_CIRCLE = 'user-exited-speaker-circle'
export const USER_ENTERED_WHISPER_MODE = 'user-entered-whisper-mode'
export const USER_EXITED_WHISPER_MODE = 'user-exited-whisper-mode'
export const TAB_SELECTED = 'tab-selected'
export const JOIN_LOCATION_CONNECTED = 'join-location-connected'
export const JOIN_LOCATION_DISCONNECTED = 'join-location-disconnected'
export const SETTINGS_OWNER_CHANGED = 'settings-owner-changed'
export const MUTE_ALL_AUDIO = 'mute_all_audio'
export const LEAVE_JOIN_AUDIO = 'leave_join_audio'
export const REDUCED_PERFORMANCE_FPS_DETECTED =
    'reduced-performance-fps-detected'
export const VISIT_PERFORMANCE_FPS_HELP_PAGE = 'visit-performance-fps-help-page'
export const CLICK_LOW_FPS_ENABLE_LITE = 'click-low-fps-enable-lite'
export const PERFORMANCE_FPS_NORMALIZED = 'performance-fps-normalized'
export const HI_CPU_USAGE_DETECTED = 'high-cpu-usage-detected'
export const CPU_USAGE_NORMALIZED = 'cpu-usage-normalized'
export const HI_SYSTEM_MEMORY_USAGE_DETECTED = 'high-system-mem-usage-detected'
export const SYSTEM_MEMORY_USAGE_NORMALIZED = 'system-mem-usage-normalized'
export const REDUCED_PERFORMANCE_BANDWIDTH_DETECTED =
    'reduced-performance-bandwidth-detected'
export const VISIT_PERFORMANCE_BANDWIDTH_HELP_PAGE =
    'visit-performance-bandwidth-help-page'
export const PERFORMANCE_BANDWIDTH_NORMALIZED =
    'performance-bandwidth-normalized'
export const SCREEN_SHARE_AVAILABLE_OUTGOING_KBPS_LOW =
    'screen-share-available-outgoing-kbps-low'
export const SCREEN_SHARE_AVAILABLE_OUTGOING_KBPS_NORMALIZED =
    'screen-share-available-outgoing-kbps-normalized'
export const VISIT_AV_HELP_PAGE = 'visit-av-help-page'
export const AUDIO_INPUT_BLOCKED = 'audio_input_blocked'
export const AUDIO_INPUT_UNBLOCKED = 'audio_input_unblocked'
export const VIDEO_INPUT_BLOCKED = 'video_input_blocked'
export const VIDEO_INPUT_UNBLOCKED = 'video_input_unblocked'
export const NO_AUDIO_INPUTS_AVAILABLE = 'no_audio_inputs_available'
export const NO_VIDEO_INPUTS_AVAILABLE = 'no_video_inputs_available'
export const TAP_ON_SHOULDER = 'tap-on-shoulder'
export const GO_TO_USER = 'go-to-user'
export const FRONTEND_VERSION_OUTDATED = 'frontend-version-outdated'
export const MAX_TIER_LIMIT_EXCEEDED = 'max-tier-limit-exceeded'
export const JOIN_BUTTON_CLICKED = 'join_button_clicked'
export const HOME_PAGE_VIEW = 'homepage_view'
export const HOME_PAGE_SIDEBAR_CLICK = 'home_page_sidebar_click'
export const HOME_NEW_MEETING_CLICK = 'home_page_new_meeting_click'
export const HOME_PAGE_OFFICE_CLICK = 'home_page_office_click'
export const HOME_PAGE_SCHEDULE_MEETING = 'home_page_schedule_meeting'
export const HOME_PAGE_SCHEDULE_GOOGLE = 'home_page_schedule_google'
export const HOME_PAGE_SCHEDULE_OUTLOOK = 'home_page_schedule_outlook'
export const HOME_PAGE_SCHEDULE_APPLE = 'home_page_schedule_apple'
export const HOME_PAGE_MEETING_MIGRATION = 'home_page_meeting_migration'
export const DOCK_CLICKED = 'dock_clicked'
export const TOOLBAR_CLICKED = 'toolbar_clicked'
export const PARTICIPANT_LEFT_MEETING = 'participant_left_meeting'
export const DESKTOP_OPEN_SETTINGS = 'desktop_open_settings'
export const SCREENSHARE_SHOW_ANYWAY = 'screenshare_show_anyway'
export const FOLLOW_CALENDAR_LINK = 'follow-calendar-link'
export const TOP_DOWNLOAD_BANNER_DISMISS = 'top_download_banner_dismiss'
export const TOP_DOWNLOAD_BANNER_CLICK = 'top_download_banner_click'

export enum Salesfloor {
    ClickSetupDialer = 'salesfloor_click_setup_dialer',
    StopListeningToParticipant = 'salesfloor_stop_listening_to_participant',
    StartListeningToParticipant = 'salesfloor_start_listening_to_participant',
    SendListenModeMessage = 'salesfloor_send_listen_mode_message',
}

export enum Chat {
    ChatChanged = 'chat_changed',
    ToggleChat = 'chat_toggle_from_dock',
}

export enum Notification {
    UpcomingMeeting = 'notification_upcoming_meeting',
}

export enum Fullscreen {
    FullscreenToggle = 'fullscreen_toggle',
    SplitModeEnabled = 'fullscreen_splitmode_enabled',
    GridModeEnabled = 'fullscreen_gridmode_enabled',
    ToggleRibbon = 'fullscreen_toggle_ribbon',
}

export enum Minimap {
    MinimapShowToggle = 'minimap_show_toggle',
    MinimapZoomIn = 'minimap_zoom_in',
    MinimapZoomOut = 'minimap_zoom_out',
}

export enum VerseDownloadScreen {
    OpenInBrowserClicked = 'verse_download_screen_go_to_office_in_browser_clicked_2022_07_21',
    OpenTeamflowClicked = 'verse_download_screen_open_teamflow_clicked_2022_07_21',
    DownloadNowClicked = 'verse_download_now_clicked_download_screen_2022_07_21',
}

export enum Flow {
    Signup = 'signup',
    Guest = 'guest',
    NewOrg = 'neworg',
    NewTeam = 'newteam',
    JoinLink = 'join',
    CreateOrg = 'createorg',
    CreateSalesfloor = 'createsalesfloor',
    Admin = 'admin',
    Login = 'login',
    None = '',
}

export enum Apps {
    Open = 'app_opened',
    Close = 'app_closed',
    CopyLink = 'app_copy_link',
    OpenInNewWindow = 'app_open_in_new_window',
    ShowQrCode = 'app_show_qr_code',
    ToggleUseSharedBrowser = 'app_toggle_use_shared_browser',
    Select = 'app_select',
    ToggleMultiplayerCursors = 'toggle_multiplayer_cursors',
    PiPOpened = 'pip_opened',
    PiPClosed = 'pip_closed',
    PiPError = 'pip_error',
    Minimized = 'app_minimized',
    Resize = 'app_resize',
    Move = 'app_move',
    Pinned = 'app_pinned',
    ScreenShareManualPause = 'screen_share_manual_pause',
    ScreenShareManualPlay = 'screen_share_manual_play',
    OpenShareAudio = 'open_share_audio',
}

export enum Upsell {
    ConvertMeetingVenue = 'upsell_convert_meeting_venue',
}

export enum Furniture {
    Add = 'furniture_add',
    Delete = 'furniture_delete',
    Rotate = 'furniture_rotate',
    Move = 'furniture_move',
    BringForward = 'furniture_bring_forward',
    SendToBack = 'furniture_send_to_back',
}

export enum Schedule {
    SyncGoogleCalendar = 'schedule_sync_google_calendar',
    SyncOutlookCalendar = 'schedule_sync_outlook_calendar',
    AddEvent = 'schedule_add_event',
}

export enum LevelEditor {
    Open = 'level_editor_open',
    OpenTool = 'level_editor_open_tool',
    Pick = 'level_editor_pick',
    Place = 'level_editor_place',
    CreateSelection = 'level_editor_create_selection',
    MoveSelection = 'level_editor_move_selection',
    DeleteSelection = 'level_editor_delete_selection',
    Erase = 'level_editor_erase',
}

export enum TemplateEvents {
    /**
     * Called when a users opens the modal to select a template
     */
    OPENED_TEMPLATE_MODAL = 'opened_template_modal',

    /**
     * When a user selects a template and moves to the next stage
     * of building a room
     */
    PICKED_A_TEMPLATE = 'picked_a_template',

    /**
     * When a user closes the template chooser.  In effect,
     * cancelling creating a room
     */
    CANCELED_SPACE_CREATION = 'canceled_space_creation',

    /**
     * When a user creates a space with a selected template.
     */
    CREATED_SPACE = 'created_space',
}

export enum CustomStatus {
    CUSTOM_STATUS_MODAL_OPENED = 'custom_status_modal_opened',
    CUSTOM_STATUS_SET = 'custom_status_set',
    CUSTOM_STATUS_CLEARED = 'custom_status_cleared',
}

export enum CustomStatusModalTriggerLocation {
    SPATIAL = 'spatial',
    FULL_SCREEN_MODE = 'full_screen_mode',
}

export {
    LoadingFunnel,
    Signup,
    eventWithFlow,
    identify,
    page,
    registerTrackEvent,
    track,
} from '@teamflow/bootstrap'

export enum ROOM_ENTRY {
    Portal = 'portal',
    Sidebar = 'sidebar',
    GoHome = 'go-home',
    GoToUser = 'go-to-user',
    Gui = 'gui',
    QuickSearch = 'quick-search',
    ChatHeader = 'chat-header',
    EventPopover = 'event-popover',
    Knocking = 'knocking',
    PostLoad = 'post_load',
    AddRoom = 'add-room',
    InviteNotification = 'invite-notification',
    OnboardingBot = 'onboarding-bot',
    LeaveMeetingRecording = 'leave-meeting-recording',
    RoomBrowser = 'room-browser',
    LeaveRoomDock = 'leave-room-dock',
    JoinBanner = 'join-banner',
    Home = 'home',
    CreateMeeting = 'create-meeting',
    HangUp = 'hang-up',
    DesktopDeepLink = 'desktop-deep-link',
}

export enum Navigation {
    InviteOver = 'invite_over',
}

// Track when customize mode is toggled
// TODO: Not a fan of a rogue mobx store reaction in the analytics helpers file.
reaction(
    () => rootStore.layout.customizeMode,
    () => {
        track(CUSTOMIZE_MODE_TOGGLE, {
            active: rootStore.layout.customizeMode,
        })
    }
)
