import { computed, makeAutoObservable, observable } from 'mobx'
import { clearPersistedStore, makePersistable } from 'mobx-persist-store'

import {
    AvailabilityRanking,
    AvailabilityStatus,
    ICalendarEvent,
    IProfileUpdated,
    SerializedUser,
    UserFrontendModel,
    ChatType,
    OrgUserFrontendModel,
} from '@teamflow/types'

import { GlobalUser, OrgUser, LocalUser } from './User'
import { logger } from './logging'

import type { RootStore } from './rootStore'

export class UserStore {
    rootStore: RootStore

    localUserId: string | null = null

    // The reaction of the local participant
    // Reason: snappier reaction
    localReaction = ''

    floorPermission: { access: boolean } | null = null

    hasRequestedLocalUser = false

    localUserError: string | null = null

    hasUserOrgsLoaded = false

    userById = observable<string, OrgUser>(new Map())

    schedule: ReadonlyArray<ICalendarEvent> = []

    // The global user object that can be
    // attached to different 'user orgs'
    globalUser: GlobalUser | null = null

    localUser: LocalUser | null = null

    lastTalkToSomeoneNotification: number | null = null

    // Becomes false once the user has clicked No Thanks twice.
    showTalkToSomeoneNotification = true

    calendarConnected = false

    meetingJoinedAt: number | undefined

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            sortedUserList: computed.struct,
            membersCount: computed.struct,
            usersInSameLocation: computed.struct,
            usersBySelectedApp: computed.struct,
            usersWithUnreadMessages: computed.struct,
            schedule: observable.ref,
        })
        this.rootStore = rootStore

        void makePersistable(this, {
            name: 'UserStore',
            properties: [
                'lastTalkToSomeoneNotification',
                'showTalkToSomeoneNotification',
            ],
            // Stop it blowing up with SSR
            storage:
                typeof window !== 'undefined' ? window.localStorage : undefined,
            // Set to true for verbose logs
            debugMode: false,
        })
    }

    reset(shouldClearPersistentStore = false) {
        if (shouldClearPersistentStore) {
            void clearPersistedStore(this)
        }

        this.globalUser = null
        this.localUser = null
        this.updateLocalUserId('')
        this.userById.clear()
        this.schedule = []
        this.setLocalUserError(null)
        this.hasUserOrgsLoaded = false
        this.hasRequestedLocalUser = false
        this.localReaction = ''
        this.floorPermission = null
        this.meetingJoinedAt = undefined
    }

    updateLocalUserId(id: string) {
        this.setLocalUserError(null)
        this.localUserId = id
    }

    updateLastTalkToSomeoneNotification(time: number) {
        this.lastTalkToSomeoneNotification = time
    }

    updateShowTalkToSomeoneNotification(show: boolean) {
        this.showTalkToSomeoneNotification = show
    }

    updateLocalUser(user: UserFrontendModel) {
        if (!user) {
            logger.warn('UserStore.updateLocalUser called with undefined user')
            return
        }
        if (!user?._id && !this.localUser) {
            logger.warn(
                'UserStore.updateLocalUser trying to update undefined local user'
            )
            return
        }
        logger.info('UserStore.updateLocalUser updating local user', {
            newLocalUserId: user?._id,
            previousLocalUserId: this.localUser?._id,
        })
        if (user?._id) {
            this.updateLocalUserId(user._id)
        }
        if (this.localUser) {
            this.localUser.update(user)
        } else {
            this.localUser = new LocalUser(user)
        }
    }

    updateGlobalUser(user: SerializedUser) {
        if (this.globalUser) {
            this.globalUser.update(user)
        } else {
            this.globalUser = new GlobalUser(user)
        }
    }

    setHasRequestedLocalUser() {
        this.hasRequestedLocalUser = true
    }

    setLocalUserError(message: string | null) {
        this.localUserError = message
    }

    updateLocalReaction(reaction: string) {
        this.localReaction = reaction
    }

    updateLocalFloorAccess(access: boolean) {
        this.floorPermission = { access }
    }

    addUsers(userModels: OrgUserFrontendModel[]) {
        const userModelsIds: string[] = []
        const userModelsById = userModels.reduce<
            Record<string, OrgUserFrontendModel>
        >((acc, user) => {
            acc[user._id] = user
            userModelsIds.push(user._id)
            return acc
        }, {})

        const visitedIds: string[] = []

        for (const existingUser of this.userById.values()) {
            const userModel = userModelsById[existingUser._id]
            visitedIds.push(existingUser._id)

            if (!userModel) {
                this.userById.delete(existingUser._id)
            } else {
                existingUser.update(userModel)
            }
        }

        const visitedIdsMatchRetrievedIds =
            visitedIds.length === userModelsIds.length &&
            visitedIds.every((id) => !!userModelsById[id])

        if (!visitedIdsMatchRetrievedIds) {
            // we need to add new users
            const newUsersIds = userModelsIds.filter(
                (id) => !visitedIds.includes(id)
            )

            for (const id of newUsersIds) {
                const userModel = userModelsById[id]
                this.addUser(userModel)
            }
        }

        this.hasUserOrgsLoaded = true
    }

    addUser(item: OrgUserFrontendModel) {
        const existing = this.userById.get(item._id)
        if (existing) {
            existing.update(item)
            return existing
        }
        const user = new OrgUser(item)
        this.userById.set(user._id, user)
        return user // for testing mostly
    }

    removeUser(id: string) {
        if (this.userById.has(id)) this.userById.delete(id)
    }

    getUserById(id: string) {
        return this.userById.get(id)
    }

    updateUserProfile(data: IProfileUpdated) {
        if (data.userId === this.localUserId) {
            this.localUser?.updateProfile(data)
        }
        const existing = this.userById.get(data.userId)
        existing?.updateProfile(data)
    }

    updateSchedule(schedule: ReadonlyArray<ICalendarEvent>) {
        this.schedule = schedule
    }

    get localFloorAccess() {
        return !!this.floorPermission?.access
    }

    get localUserLoading() {
        return !this.hasRequestedLocalUser
    }

    get users(): OrgUser[] {
        return Array.from(this.userById.values())
    }

    get userOrgsByEmail(): Record<string, OrgUser> {
        return this.users.reduce<Record<string, OrgUser>>((record, user) => {
            if (user.email) record[user.email] = user
            return record
        }, {})
    }

    get sortedUserList(): OrgUser[] {
        const localUserId = this.localUserId
        if (!localUserId) return []

        return this.users
            .filter((userOrg) => {
                // Filter out local user
                if (userOrg._id === localUserId) return false
                if (userOrg.isDeleted) return false

                const participant =
                    this.rootStore.participants.findParticipantById(userOrg._id)

                const isOfflineGuest =
                    userOrg.isGuest &&
                    (!participant ||
                        !participant.availability ||
                        participant.availability === AvailabilityStatus.OFFLINE)

                // If you are a team member, show all team members and all online guests
                if (!this.localUser?.isGuest && !isOfflineGuest) {
                    return true
                }

                const online = !!participant
                const available =
                    participant?.availability !== AvailabilityStatus.OFFLINE
                const isOnline = !!(online && available)

                const localParticipant =
                    this.rootStore.participants.localParticipant
                if (!localParticipant) {
                    return false
                }

                // If you are a guest and only online guests/team members
                if (this.localUser?.isGuest) {
                    const inRoom =
                        participant?.locationId === localParticipant.locationId
                    return inRoom && online
                }

                return isOnline
            })
            .sort((uOrgA, uOrgB) => {
                const aAvailability =
                    this.rootStore.participants.findParticipantById(uOrgA._id)
                        ?.availability || AvailabilityStatus.OFFLINE
                const bAvailability =
                    this.rootStore.participants.findParticipantById(uOrgB._id)
                        ?.availability || AvailabilityStatus.OFFLINE

                const aStatusRank = AvailabilityRanking[aAvailability] || 0
                const bStatusRank = AvailabilityRanking[bAvailability] || 0

                if (aStatusRank === bStatusRank) {
                    const aName = uOrgA.fullName.toLowerCase()
                    const bName = uOrgB.fullName.toLowerCase()
                    return aName > bName ? 1 : -1
                } else {
                    return aStatusRank > bStatusRank ? -1 : 1
                }
            })
    }

    get usersMembersOnly(): OrgUser[] {
        return this.users.filter((user) => !user.isGuest)
    }

    get membersCount(): number {
        return this.usersMembersOnly.length
    }

    get guestsCount(): number {
        return this.users.filter((user) => user.isGuest).length
    }

    get usersInSameLocation(): OrgUser[] {
        return this.rootStore.participants.participantsInSameAVConnectedSpace.reduce<
            OrgUser[]
        >((acc, participant) => {
            if (participant.ready) {
                const user = this.getUserById(participant.id)
                if (user) acc.push(user)
            }
            return acc
        }, [])
    }

    get usersBySelectedApp(): Record<string, OrgUser[]> {
        return this.rootStore.participants.participants.reduce(
            (acc: Record<string, OrgUser[]>, p) => {
                if (p.selectedAppId) {
                    if (!acc[p.selectedAppId]) {
                        acc[p.selectedAppId] = []
                    }
                    const user = this.getUserById(p.id)
                    if (user) {
                        acc[p.selectedAppId].push(user)
                    }
                }
                return acc
            },
            {}
        )
    }

    get usersWithUnreadMessages() {
        const users = this.sortedUserList
        const localUserId = this.localUserId
        const chats = this.rootStore.chat.chatsById?.[ChatType.ONE_ON_ONE] || {}

        return users.filter((user) => {
            const chat = chats[user._id]
            return chat?.badgeCount > 0 && user._id !== localUserId
        })
    }

    get owner() {
        const currentOrgCreatedBy = this.localUser?.currentOrgCreatedBy
        return currentOrgCreatedBy
            ? this.users.find((u) => u.accountId === currentOrgCreatedBy)
            : undefined
    }

    setCalendarConnected(calendarConnected: boolean) {
        this.calendarConnected = calendarConnected
    }

    setMeetingJoinedAt(meetingJoinedAt: number | undefined) {
        this.meetingJoinedAt = meetingJoinedAt
    }
}
