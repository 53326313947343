import { api } from '@teamflow/client-api'

export const fetchFloorPermission = async (orgName: string) => {
    const { data } = await api.organization.floorPermissions({ orgName })

    if (!data) {
        return {
            access: false,
        }
    }

    return data
}
