import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export default class ParticipantSelectedAppIdCommand
    implements t.ICommand<t.IParticipantSelectedAppId>
{
    readonly messageType = t.ClientMessage.ParticipantSelectedAppId

    private selectedAppId: string

    constructor(selectedAppId: string) {
        this.selectedAppId = selectedAppId
    }

    execute() {
        return {
            selectedAppId: this.selectedAppId,
        }
    }

    localExecute() {
        rootStore.app.setSelectedApp(this.selectedAppId)
    }
}
