import * as t from '@teamflow/types'

export default class ChangeRoomSpatialAudioCommand
    implements t.ICommand<t.IChangeRoomSpatialAudioAction>
{
    readonly messageType = t.ClientMessage.ChangeRoomSpatialAudio

    private roomSlugName: string
    private spatialAudio: boolean

    constructor(roomSlugName: string, spatialAudio: boolean) {
        this.roomSlugName = roomSlugName
        this.spatialAudio = spatialAudio
    }

    execute() {
        return {
            roomSlugName: this.roomSlugName,
            spatialAudio: this.spatialAudio,
        }
    }
}
