import type { UserFrontendModel } from '@teamflow/types'

import { createRequest, saveAccessToken, request } from '../request'

const create = createRequest<'POST /api/user'>('POST', '/api/user', [
    saveAccessToken('idToken'),
])
const get = createRequest<'GET /api/user'>('GET', '/api/user')
const update = createRequest<'PUT /api/user'>('PUT', '/api/user')

const getById = ({ id }: { id: string }) =>
    request<'GET /api/user/:id'>('GET', `/api/user/${id}`)
const updateById = ({
    id,
    changes,
}: {
    id: string
    changes: Partial<UserFrontendModel>
}) =>
    request<'PUT /api/user/:id'>('PUT', `/api/user/${id}`, {
        body: changes,
    })

const changePassword = createRequest<'POST /api/user/changePassword'>(
    'POST',
    '/api/user/changePassword'
)

const resetPassword = (code: string) =>
    request<'POST /api/user/passwordReset/:code'>(
        'POST',
        `/api/user/passwordReset/${code}`
    )

const switchOrganization = async ({ org }: { org: string }) => {
    const { data, error } = await request<'POST /api/user/switchOrganization'>(
        'POST',
        '/api/user/switchOrganization',
        { body: { org } }
    )

    if (data && data.idToken) {
        saveAccessToken('idToken')({ data })
    }

    return { data, error }
}
const exitOrganization = async () => {
    const { data, error } = await request<'POST /api/user/switchOrganization'>(
        'POST',
        '/api/user/switchOrganization',
        { body: { org: undefined } }
    )

    if (data && data.idToken) {
        saveAccessToken('idToken')({ data })
    }

    return { data, error }
}

const onboarding = {
    get: createRequest<'GET /api/user/onboarding'>(
        'GET',
        '/api/user/onboarding'
    ),
    update: createRequest<'POST /api/user/onboarding'>(
        'POST',
        '/api/user/onboarding'
    ),
}

const settings = {
    get: createRequest<'GET /api/user/settings'>('GET', '/api/user/settings'),
    update: createRequest<'POST /api/user/settings'>(
        'POST',
        '/api/user/settings'
    ),
}

const logs = {
    save: createRequest<'POST /api/user/logs'>('POST', '/api/user/logs'),
}

const calendar = {
    googleCalendarToday: ({
        beginUTCTime,
        endUTCTime,
    }: {
        beginUTCTime: number
        endUTCTime: number
    }) =>
        request<'GET /api/user/calendar/events'>(
            'GET',
            `/api/user/calendar/events?beginUTCTime=${beginUTCTime}&endUTCTime=${endUTCTime}`
        ),
    outlookCalendarToday: ({
        beginUTCTime,
        endUTCTime,
    }: {
        beginUTCTime: number
        endUTCTime: number
    }) =>
        request<'GET /api/user/calendar/outlookEvents'>(
            'GET',
            `/api/user/calendar/outlookEvents?beginUTCTime=${beginUTCTime}&endUTCTime=${endUTCTime}`
        ),
    getOnboardingEvents:
        createRequest<'GET /api/user/calendar/onboardingEvents'>(
            'GET',
            '/api/user/calendar/onboardingEvents'
        ),
    conversion: {
        get: createRequest<'GET /api/user/calendar/convert'>(
            'GET',
            '/api/user/calendar/convert'
        ),
        execute: createRequest<'PATCH /api/user/calendar/convert'>(
            'PATCH',
            '/api/user/calendar/convert'
        ),
    },
    outlookCalendarTokenStatus: () =>
        request<'GET /api/user/calendar/outlookTokenStatus'>(
            'GET',
            `/api/user/calendar/outlookTokenStatus`
        ),
    googleCalendarTokenStatus: () =>
        request<'GET /api/user/calendar/googleTokenStatus'>(
            'GET',
            `/api/user/calendar/googleTokenStatus`
        ),
    outlookRecurringEvents:
        createRequest<'GET /api/user/calendar/outlookRecurringEvents'>(
            'GET',
            '/api/user/calendar/outlookRecurringEvents'
        ),
    outlookRecurringEventsConvert:
        createRequest<'PATCH /api/user/calendar/outlookRecurringEvents/convert'>(
            'PATCH',
            '/api/user/calendar/outlookRecurringEvents/convert'
        ),
}

const signupByEmailDomain = ({ emailDomain }: { emailDomain: string }) =>
    request<'POST /api/user/signupToOrgs/:emailDomain'>(
        'POST',
        `/api/user/signupToOrgs/${emailDomain}`
    )

const getTimeSinceSignup = createRequest<'GET /api/user/signupDiff'>(
    'GET',
    '/api/user/signupDiff'
)

const asAdmin = {
    update: createRequest<'PATCH /api/user/admin'>('PATCH', '/api/user/admin'),
    get: ({ id }: { id: string }) =>
        request<'GET /api/user/admin/:id'>('GET', `/api/user/admin/${id}`),
}

const search = ({ email }: { email: string }) =>
    request<'GET /api/user/search'>('GET', `/api/user/search?email=${email}`)

const confirmEmail = ({ token }: { token: string }) =>
    request<'PATCH /api/user/confirmEmail'>(
        'PATCH',
        `/api/user/confirmEmail?token=${token}`
    )

export default {
    create,
    get,
    update,
    getById,
    updateById,
    changePassword,
    resetPassword,
    switchOrganization,
    exitOrganization,
    onboarding,
    settings,
    logs,
    calendar,
    signupByEmailDomain,
    getTimeSinceSignup,
    asAdmin,
    search,
    confirmEmail,
}
