import * as t from '@teamflow/types'

export default class PromoteGuestCommand
    implements t.ICommand<t.IPromoteGuest>
{
    readonly messageType = t.ClientMessage.PromoteGuest

    private promoterUserId: string
    private guestUserId: string

    constructor(promoterUserId: string, guestUserId: string) {
        this.promoterUserId = promoterUserId
        this.guestUserId = guestUserId
    }

    execute() {
        return {
            promoterUserId: this.promoterUserId,
            guestUserId: this.guestUserId,
        }
    }
}
