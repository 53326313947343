import type { Entity } from '@teamflow/types'

import { AppConfigStore } from './AppConfigStore'
import { AppStore } from './AppStore'
import { AudioVideoStore } from './AudioVideoStore'
import { AudioZoneStore } from './AudioZoneStore'
import { ChatStore } from './ChatStore'
import { CommonsStore } from './CommonsStore'
import { ExperimentsStore } from './ExperimentsStore'
import { LayoutStore } from './LayoutStore'
import { MarketingStore } from './MarketingStore'
import { OnboardingStore } from './OnboardingStore'
import { OrganizationStore } from './OrganizationStore'
import { PerformanceStore } from './PerformanceStore'
import { PresentationStore } from './PresentationStore'
import { RoleStore } from './RoleStore'
import { RoomStore } from './RoomStore'
import { SettingsStore } from './SettingsStore'
import { SlackStore } from './SlackStore'
import { SpatialHashStore } from './SpatialHashStore'
import { SpeakerModeStore } from './SpeakerModeStore'
import { TierStore } from './TierStore'
import { ToastStore } from './ToastStore'
import { ParticipantStore } from './participantStore'
import { UserStore } from './userStore'

export class RootStore {
    readonly app: AppStore
    readonly appConfig: AppConfigStore
    /** Common data for the world and UI. */
    readonly commons: CommonsStore

    /** State for marketing. */
    readonly marketing: MarketingStore

    readonly users: UserStore
    readonly participants: ParticipantStore
    readonly presentations: PresentationStore
    readonly performance: PerformanceStore
    readonly audioVideo: AudioVideoStore
    readonly settings: SettingsStore
    readonly chat: ChatStore
    readonly speakerMode: SpeakerModeStore

    /** State for general UI layout. */
    readonly layout: LayoutStore

    readonly onboarding: OnboardingStore

    /** Organization data */
    readonly organization: OrganizationStore

    /** Role and permissions data */
    readonly roles: RoleStore

    /** State for the rooms / spaces */
    readonly rooms: RoomStore

    /**
     * A 2D lookup for entities that live in the multiplayer state.
     *
     * This is owned by {@link RealtimeService} and no other subsystem should be modifying it. Otherwise,
     * that data may be lost when the realtime service decides to clean its plate.
     */
    readonly spatialHash: SpatialHashStore<Entity>

    /** Ephemeral mirror of slack settings */
    readonly slack: SlackStore

    readonly audioZones: AudioZoneStore

    /** State needed by any experiments */
    readonly experiments: ExperimentsStore

    readonly tier: TierStore

    readonly toast: ToastStore

    constructor() {
        this.app = new AppStore(this)
        this.appConfig = new AppConfigStore(this)
        this.marketing = new MarketingStore()
        this.users = new UserStore(this)
        this.participants = new ParticipantStore(this)
        this.settings = new SettingsStore()
        this.rooms = new RoomStore(this)
        this.commons = new CommonsStore(
            this.participants,
            this.settings,
            this.rooms
        )
        this.presentations = new PresentationStore(this)
        this.performance = new PerformanceStore(this)
        this.audioVideo = new AudioVideoStore(
            this.settings,
            this.users,
            this.participants,
            this.commons,
            this.performance
        )
        this.audioZones = new AudioZoneStore(this)
        this.roles = new RoleStore()
        this.spatialHash = new SpatialHashStore()
        this.slack = new SlackStore()
        this.chat = new ChatStore(this)
        this.speakerMode = new SpeakerModeStore()
        this.onboarding = new OnboardingStore()
        this.organization = new OrganizationStore()
        this.layout = new LayoutStore(this)
        this.experiments = new ExperimentsStore()
        this.tier = new TierStore(this)
        this.toast = new ToastStore()
    }

    /** Called on logout to reset user data */
    reset(options?: {
        isUserLogout?: boolean
        shouldClearPersistentStore?: boolean
    }) {
        const { isUserLogout = false, shouldClearPersistentStore = false } =
            options ?? {}

        this.app.reset()
        this.marketing.reset()
        this.users.reset(shouldClearPersistentStore)
        this.participants.reset()
        this.settings.reset(shouldClearPersistentStore)
        this.commons.reset(shouldClearPersistentStore)
        this.presentations.reset()
        this.performance.reset()
        this.audioVideo.reset({ isUserLogout, shouldClearPersistentStore })
        this.audioZones.reset()
        this.roles.reset()
        this.rooms.reset()
        this.spatialHash.clear()
        this.slack.reset(shouldClearPersistentStore)
        this.chat.reset()
        this.speakerMode.reset()
        this.onboarding.reset()
        this.organization.reset()
        this.layout.reset(shouldClearPersistentStore)
        this.experiments.reset()
        this.tier.reset()
        this.toast.reset()
    }
}

const rootStore = new RootStore()

if (
    process.env.NEXT_PUBLIC_APP_ENV !== 'production' ||
    (typeof window !== 'undefined' && (window as any).tfBotAutomation)
) {
    // This is used by e2e tests - if you change this make sure to change the e2e tests
    ;(globalThis as any).tf_root_store = rootStore
}

export default rootStore
