import debounce from 'lodash/debounce'
import { when } from 'mobx'

import { api } from '@teamflow/client-api'
import {
    RoomSessionLockedCommand,
    RoomSessionUnlockedCommand,
} from '@teamflow/client-realtime'
import { LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { RoomStatus } from '@teamflow/types/src/roomStatus'
import type { Verse } from '@teamflow/web/src/verse'

/* An ongoing room-lock/unlock operation */
let lockOp: null | Promise<void> = null

// NOTE: there is almost the same logic in toggleLockRoomButton.tsx
async function toggleRoomLockImpl(roomId: string, verse: Verse) {
    const room = rootStore.rooms.roomsById.get(roomId)

    if (!room) {
        lockOp = null
        return // YOLO
    }

    const locked = room.locked
    const action = locked ? api.room.unlock : api.room.lock
    const { data } = await action({ roomSessionId: room.sessionId })

    if (data) {
        const newRoomSession = data.roomSession
        const oldRoomSession = data.previousRoomSession || data.roomSession

        switch (newRoomSession.status) {
            case RoomStatus.PRIVATE:
                verse.realtime.dispatch(
                    new RoomSessionLockedCommand(
                        oldRoomSession._id,
                        newRoomSession
                    )
                )

                await when(() => room.locked, { timeout: 5000 }).catch(
                    LogManager.global.error
                )
                break

            case RoomStatus.PUBLIC:
                verse.realtime.dispatch(
                    new RoomSessionUnlockedCommand(
                        oldRoomSession._id,
                        newRoomSession
                    )
                )

                await when(() => !room.locked, { timeout: 5000 }).catch(
                    LogManager.global.error
                )
                break
        }
    }

    lockOp = null
}

const toggleRoomLock = debounce(
    (roomId: string, verse: Verse) => {
        if (lockOp) {
            return
        }

        return (lockOp = toggleRoomLockImpl(roomId, verse))
    },
    200,
    { trailing: true }
)

export { toggleRoomLock }
