import { getUnixTime } from 'date-fns'

import { saveParsed, loadParsed, LogManager } from '@teamflow/lib'

import { processActions } from './processActions'
import {
    TriggerAction,
    ActionHandler,
    ITriggerEvent,
    TriggerResult,
    ActionContext,
} from './types'

const DEBOUNCE_WAIT = 300
const ONE_HOUR_IN_SECS = 60 * 60
const actionHandlers = new Map<TriggerAction, ActionHandler>()
const triggerDefinitions = new Map<string, ITriggerEvent>()

type Props = { [key: string]: { lastSeen?: number } }

const LocalStateKey = 'trigger-actions'
const triggerState = {
    ...(loadParsed<Props>(LocalStateKey) ?? {}),
}
const context: ActionContext = {}

export function setContext(_context: ActionContext) {
    Object.assign(context, _context)
}

export function registerAction(name: TriggerAction, handler: ActionHandler) {
    actionHandlers.set(name, handler)
}

export async function invokeAction(name: TriggerAction, payload?: unknown) {
    const handler = actionHandlers.get(name)
    if (!handler) {
        return TriggerResult.Ok
    }

    return handler(context, payload)
}

export function addTriggerEvent(trigger: ITriggerEvent) {
    if (triggerDefinitions.has(trigger.event)) {
        // TODO: maybe later handle multiple triggers for the same event
        LogManager.global.warn(`overwriting existing sequence with most recent`)
    }

    triggerDefinitions.set(trigger.event, trigger)
}

const debounceTimeouts = new Map<string, number>()
export async function invokeEvent(
    event: string,
    payload: Record<string, any> = {}
) {
    let id = debounceTimeouts.get(event)
    if (id) {
        window.clearTimeout(id)
    }

    id = window.setTimeout(() => {
        void internalInvokeEvent(event, payload)
        debounceTimeouts.delete(event)
    }, DEBOUNCE_WAIT)

    debounceTimeouts.set(event, id)
}

async function internalInvokeEvent(
    event: string,
    payload: Record<string, any> = {}
) {
    const definition = triggerDefinitions.get(event)
    if (!definition) {
        return
    }

    let state = triggerState[event] ?? {}

    // this is at the user level and not the userOrg level
    // may want to reconsider this but not sure we want to show
    // the same user in a different org, the same messages
    if (state.lastSeen) {
        const now = getUnixTime(new Date())
        const cooldown = Math.max(
            ONE_HOUR_IN_SECS,
            definition.cooldown ?? ONE_HOUR_IN_SECS
        )
        const diff = now - state.lastSeen
        if (diff < cooldown) {
            // don't fire again while in cooldown
            return
        }

        // reset state after cooldown
        state = {}
    }

    const payloadMatch = definition.payloadMatch
    if (payloadMatch) {
        // check the payload matches if this is set
        // otherwise it is assumed to apply to any firing of event
        for (const key in payloadMatch) {
            if (!(key in payload)) {
                // match fails if key is missing
                return
            }

            if (payloadMatch[key] !== payload[key]) {
                // match fails if values are different
                return
            }
        }
    }

    const actions = definition.actions ?? []
    await processActions(actions)

    // update with new lastSeen
    triggerState[event] = {
        ...state,
        lastSeen: getUnixTime(new Date()),
    }

    saveParsed(LocalStateKey, triggerState)
}
