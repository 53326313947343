import * as t from '@teamflow/types'

export default class ParticipantSpeakingCommand
    implements t.ICommand<t.IParticipantSpeaking>
{
    readonly messageType = t.ClientMessage.Speaking

    constructor(private speaking: boolean) {}

    execute() {
        return { speaking: this.speaking }
    }
}
