import { Wall } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import Events from '../../events'

import { Connect, Substate, SubstateConnector } from './StateConnector'
import { putOrRemoveFromHash } from './helpers'

type Space = Substate<'roomSubstates'>['space']

const putWallInSpatialHash = (item: t.IPopulatedWall) => {
    const width = Wall.getWallWidth(item)
    const height = Wall.getWallHeight(item)
    rootStore.spatialHash.put(
        item.id,
        'wall',
        item.x,
        item.y,
        item.x + width,
        item.y + height
    )
}

/** @group Connectors */
@Connect({ key: 'roomSubstates', substate: 'space' })
export class SpaceConnector extends SubstateConnector<
    'roomSubstates',
    'space'
> {
    onAttach(space: Space): void {
        const state = this.sharedState.activeRoom!.state
        if (!space) return

        this.sharedState.addSpace(space)

        space.walls.onAdd = (item: t.IWallSchema, id: string) => {
            const populatedWall = Wall.populate(id, space.id, item, state)
            putOrRemoveFromHash(populatedWall, putWallInSpatialHash)

            if (space.id === rootStore.commons.viewingSpace) {
                this.events.emit(Events.WallAdded, populatedWall)
                this.events.emit(Events.WallUpdated, populatedWall)
            }

            item.onChange = () => {
                if (space.id !== rootStore.commons.viewingSpace) return

                // creating populated Wall again inside of onChange as `item` is mutable
                const populatedChangedWall = Wall.populate(
                    id,
                    space.id,
                    item,
                    state
                )
                putOrRemoveFromHash(populatedChangedWall, putWallInSpatialHash)
                this.throttledEmitter.emit(
                    Events.WallChanged,
                    id,
                    populatedChangedWall
                )
                this.throttledEmitter.emit(
                    Events.WallUpdated,
                    id,
                    populatedChangedWall
                )
            }
        }

        space.walls.onRemove = (item: t.IWallSchema, id: string) => {
            rootStore.spatialHash.delete(id)

            if (space.id === rootStore.commons.viewingSpace) {
                this.throttledEmitter.cancel(Events.WallChanged, id)
                this.events.emit(
                    Events.WallRemoved,
                    Wall.populate(id, space.id, item, state)
                )
            }
        }

        space.onRemove = () => {
            this.sharedState.removeSpace(space)
        }
    }

    onDetach(space: Space) {
        if (space) {
            space.walls.onAdd = undefined
            space.walls.onRemove = undefined
        }
    }

    onSwitchSpace(space: Space): void {
        const state = this.sharedState.activeRoom!.state

        if (space) {
            for (const [id, wall] of space.walls) {
                const populatedWall = Wall.populate(id, space.id, wall, state)
                putWallInSpatialHash(populatedWall)
            }
        }
    }
}
