import { easeBackIn } from 'd3-ease'

import { Platform } from '@teamflow/bootstrap'

export const LOCALES = ['en', 'fr', 'ko', 'ja']
export const DEFAULT_LOCALE = 'en'

export const SIDEBAR_WIDTH = 292
export const SIDEBAR_SLIM_WIDTH = 240
export const TINY_SCREEN_SIDEBAR_POPOUT_BOUND = 1456
export const TINY_SCREEN_SIDEBAR_NO_POPOUT_BOUND = 1160
export const SIDEBAR_WIDTH_COLLAPSED_PHONE = 44
export const SIDEBAR_WIDTH_COLLAPSED = 72
export const CHAT_WIDTH = 292

export const TOP_BAR_HEIGHT = 44
export const TOP_BAR_AND_ROOM_TOOLBAR_HEIGHT = 88
export const TOP_BANNER_HEIGHT = 32

export const DOCK_HEIGHT = 92
export const DOCK_MARGIN_BOTTOM = 24

export const DOCK_BUTTON_SIZE = 56
//export const DOCK_BUTTON_SIZE_PHONE = 30

export const OVERSHOOT_SPRING_CONFIG = {
    type: 'tween',
    duration: 0.22,
    easing: easeBackIn.overshoot(1.3),
}

export const OVERSHOOT_SPRING_SLOW_CONFIG = {
    type: 'tween',
    duration: 0.42,
    ease: easeBackIn.overshoot(1.1),
}

export const OVERSHOOT_SPRING_PULSE_CONFIG = {
    repeat: Infinity,
    duration: 0.7,
    repeatType: 'reverse',
} as const

export const NO_TRANSITION_CONFIG = {
    type: 'linear',
    duration: 0,
}

export const MIN_PASS_LEN = 6
export const MAX_FIRST_NAME_LEN = 30
export const MAX_LAST_NAME_LEN = 30
export const MAX_USER_NAME_LEN = 60
export const MAX_ORG_NAME_LEN = 30

export {
    PARTICIPANT_AUDIBLE_RADIUS,
    SPEAKER_AUDIBLE_RADIUS,
    WHISPER_AUDIBLE_RADIUS,
    PERSONAL_RADIUS,
    SPEAKER_AVATAR_SCALE,
    PARTICIPANTS_SEARCH_AREA_SIZE,
} from '@teamflow/lib'

export function calculateLeftWidth(
    sidebarExpanded: boolean,
    customizeMode: boolean,
    customizePanelWidth: number
) {
    if (Platform.isPhone()) {
        return 0
    }

    const sidebarWidth = customizeMode
        ? customizePanelWidth
        : sidebarExpanded
        ? SIDEBAR_SLIM_WIDTH
        : getSidebarCollapsedWidth()
    const chatWidth = 0
    return sidebarWidth + chatWidth
}

export function calculateTopHeight(isOneOnOneChat: boolean) {
    if (Platform.isMobileApp()) return 0
    return isOneOnOneChat ? TOP_BAR_HEIGHT : TOP_BAR_AND_ROOM_TOOLBAR_HEIGHT
}

export function getSidebarCollapsedWidth() {
    return Platform.isPhone() ? SIDEBAR_WIDTH_COLLAPSED_PHONE : 0
}

export const DEFAULT_AVATAR_OPACITY = 1
export const DEFAULT_AVATAR_OVERLAP_OPACITY = 0.5
export const MINIMUM_MOVEMENT_SPEED_PER_FRAME = 10
export const MAXIMUM_MOVEMENT_SPEED_PER_FRAME = 50
export const STEP_MOVEMENT_SPEED_PER_FRAME = 5

export * from '@teamflow/lib/src/shared-old/const'

export const SLACK_WORKSPACE_SCOPES = 'commands,incoming-webhook'
export const SLACK_WORKSPACE_SCOPES_INVITE =
    'commands,incoming-webhook,users:read,users:read.email'
export const SLACK_USER_SCOPES =
    'users:read,users.profile:read,users.profile:write'
export const SLACK_REDIRECT_URI = '/slack-installed'

export const APPZI_ID =
    process.env.NEXT_PUBLIC_APPZI_WIDGET_ID ??
    '5ae564f6-39e7-48f7-85be-855704d01589'

export const SUPPORT_EMAIL = 'support@teamflowhq.com'

export const MAX_PARTICIPANTS_ALLOWED_IN_SPACE = 100

export const CUSTOMIZE_MODE_OPACITY = 0.25

export const IMAGES_UPLOAD_MAX_AMOUNT = 5
export const IMAGES_UPLOAD_OFFSET = 100
export const SUPPORTED_IMAGE_TYPES = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/webp',
    'image/apng',
]

export const HIDE_HUD_IN_FSM_TIMEOUT = 500

export { INITIALS_REGEX } from '@teamflow/lib'
export const MEETING_REGEX =
    /\b(?:zoom.us|webex.com|meet.google.com|plus.google.com|bluejeans.com|teams.microsoft.com)\b/i

export {
    ID_TOKEN_NAME,
    DEFAULT_ZOOM,
    DEFAULT_MIN_ZOOM,
    DEFAULT_MAX_ZOOM,
    ZOOM_ROUND,
    ZOOM_STEP,
} from '@teamflow/lib'

export const SIDEBAR_CUSTOMIZE_COLUMN_WIDTH = 118
export const SIDEBAR_PADDING = 24
export const PERMISSION_REFRESH_RATE = 60000 // once a minute

export const MAX_TIME_APP_OFF_SCREEN = 1000 * 20
export const MINIMIZED_APP_SIZE = 100

export enum SidebarCustomizeWidth {
    TwoColumns = 292,
    ThreeColumns = 414,
    FourColumns = 536,
}

export const EPHEMERAL_REACTION_LIFESPAN_MILLIS = 4000
export const EPHEMERAL_REACTION_COOLDOWN = 250
export const EPHEMERAL_REACTION_RADIUS_FACTOR = 0.7
export const EPHEMERAL_REACTION_SIZE_RANGE = [0.45, 0.65] as const

export const CHROME_EXTENSION_LINK =
    'https://chrome.google.com/webstore/detail/teamflow-virtual-office/akbfbofhedhefhmcmgkmianllcpplblj'

export const HAS_BEEN_EVENTS_ONBOARDED_KEY = 'has-been-events-onboarded'

/**
 * The amount of space at the bottom of the page to account
 * for the dock in fullscreen mode for grid view mode
 */
export const FSM_GRID_BOTTOM_PADDING = 183

/**
 * The amount of space at the bottom of the page to account
 * for the dock in fullscreen mode for split view mode
 */
export const FSM_SPLIT_BOTTOM_PADDING = 170

/**
 * Extra padding to add to the bottom of the fullscreen page
 * if the user has the text labels active on their dock
 */
export const FSM_DOCK_LABEL_PADDING = 11

/**
 * Threshold in pixels where the viewport pans
 * automatically when dragging avatars or apps
 */
export const AUTO_WALK_TOLERANCE = 190

/**
 * Threshold in proportion of screen dimensions where the
 * viewport pans automatically when using the keyboard to move
 */
export const AUTO_PAN_TOLERANCE = 0.4

export const MEETING_MIGRATION_LOCAL_STORAGE_KEY = 'meeting-migration-success'

/**
 * Help documentation URLs used in the app
 */
export const HELP_AV_COMMON_PROBLEMS_URL =
    'https://help.teamflowhq.com/article/199-configure-audio-video#common-problems'
export const HELP_HOW_TO_ENABLE_SCREENSHARE =
    'https://help.teamflowhq.com/article/153-how-to-enable-screenshare-in-teamflow'
export const HELP_DESKTOP_SCREENSHARE_TROUBLESHOOTING_URL =
    'https://help.teamflowhq.com/article/168-screenshare-troubleshooting'
export const HELP_HOW_TO_RECORD_MEETING_URL =
    'https://help.teamflowhq.com/article/198-how-to-record-meetings-on-teamflow-30'
export const HELP_MEETING_RECORDING_SUPPORT_URL =
    'https://help.teamflowhq.com/article/179-meeting-recording-support '
export const HELP_HOW_TO_REMOVE_USERS_URL =
    'https://help.teamflowhq.com/article/173-how-to-remove-users-from-your-workspace'
export const HELP_CONFIGURE_AUDIO_VIDEO_URL =
    'https://help.teamflowhq.com/article/199-configure-audio-video'
export const HELP_AV_QUALITY_LOW_BANDWIDTH_URL =
    'https://help.teamflowhq.com/article/196-a-v-degradation-due-to-low-bandwidth'
export const HELP_SLACK_INTEGRATIONS =
    'https://help.teamflowhq.com/article/120-slack-integration'
export const HELP_LITE_MODE_URL =
    'https://help.teamflowhq.com/article/178-how-to-enable-teamflow-lite'
export const HELP_MAX_APPS_URL =
    'https://help.teamflowhq.com/article/295-limiting-apps'

/**
 * Control the number of users in the space
 */
export const MAX_GUESTS_FOR_FREE_ORG = 10
