import type { LayoutStore } from '../LayoutStore'

import { overwriteOldPrefs, retrieveOldPrefs } from './prefs'

export function migrateZoomScaleLevel(store: LayoutStore) {
    const oldPrefs = retrieveOldPrefs()
    const key = 'teamflow.viewport.scale'
    if (!oldPrefs || !oldPrefs.has(key)) {
        return
    }
    const pref = oldPrefs.get(key)
    if (typeof pref?.value === 'number') {
        store.updateZoomScaleLevel(pref.value)
    }
    oldPrefs.delete(key)
    overwriteOldPrefs(oldPrefs)
}
