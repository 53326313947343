import { when } from 'mobx'
import { v4 as uuidv4 } from 'uuid'

import { api } from '@teamflow/client-api'
import {
    SimpleCommand,
    PortalCommand,
    UpdateRoomSessionCommand,
} from '@teamflow/client-realtime'
import { LogManager } from '@teamflow/lib'
import rootStore, { Room } from '@teamflow/store'
import * as t from '@teamflow/types'
import { RoomType } from '@teamflow/types'

export const createRoom = async (
    userId: string,
    orgId: string,
    realtime: t.IRealtimeService,
    spaceName: string,
    whoCanJoin: t.RoomAccessOptions,
    templateId: string | null,
    personalOffice = false,
    roomType = RoomType.Room
): Promise<Room | undefined> => {
    const data = await makeAddRoomRequest(
        orgId,
        spaceName,
        whoCanJoin,
        templateId,
        personalOffice,
        roomType
    )
    const { room, roomSession } = data

    // NOTE: A set templateId only happens if the feature flag RoomTemplatesUse
    // is turned on.
    let updateProps = templateId ? { templateId } : { furnish: true }
    if (roomType === RoomType.Meeting) {
        updateProps = { furnish: false }
    }
    realtime.dispatch(new UpdateRoomSessionCommand(roomSession, updateProps))

    await when(() => !!rootStore.rooms.roomsById.get(room._id), {
        timeout: 5000,
    }).catch(LogManager.global.warn)

    const permissionResponse = await api.userOrg.permissions({
        userOrgId: userId,
    })

    if (!permissionResponse.error) {
        const { allow, deny } = permissionResponse.data
        rootStore.users.localUser?.update({ allow, deny })
    }

    realtime.dispatch(
        new SimpleCommand<t.IBroadcast>(t.ClientMessage.Broadcast, {
            kind: t.BroadcastType.PermissionUpdated,
        })
    )

    return rootStore.rooms.roomsById.get(room._id)
}

export async function makeAddRoomRequest(
    orgId: string,
    spaceName: string,
    whoCanJoin: t.RoomAccessOptions,
    templateId: string | null,
    personalOffice = false,
    roomType = RoomType.Room,
    scheduleMeeting = false
) {
    const { data, error } = await api.organization.addRoom({
        orgId,
        body: {
            roomName: spaceName,
            whoCanJoin,
            templateId: templateId ?? '',
            personalOffice,
            type: roomType,
            scheduleMeeting: scheduleMeeting,
        },
    })

    if (!data) {
        throw new Error(error?.message || error?.code)
    }

    return data
}

// This will be removed later
export const addRoom = async (
    orgId: string,
    realtime: t.IRealtimeService,
    portal: Omit<Omit<t.IPortal, 'to'>, 'id'>
): Promise<{
    room: Room | undefined
    portal: t.IPortal
}> => {
    const { data, error } = await api.organization.addRoom({ orgId })
    if (!data) {
        throw new Error(error?.message || error?.code)
    }

    const { room, roomSession } = data

    realtime.dispatch(
        new UpdateRoomSessionCommand(roomSession, {
            furnish: true,
        })
    )

    // Just wait otherwise portal maybe added before the room session lol
    await when(() => !!rootStore.rooms.roomsById.get(room._id), {
        timeout: 5000,
    }).catch(LogManager.global.error)

    const portalComplete = portal as t.IPortal

    portalComplete.id = uuidv4()
    portalComplete.to = room._id

    realtime.dispatch(new PortalCommand(portalComplete))

    // Just wait until the portal is added for success!
    await realtime.portalAdded({
        to: room._id,
    })

    return {
        room: rootStore.rooms.roomsById.get(room._id),
        portal: realtime.getPortal(portalComplete.id) as t.IPortal,
    }
}
