import { useEffect } from 'react'

import { api } from '@teamflow/client-api'
import rootStore from '@teamflow/store'

export function useUserOnboarding() {
    useEffect(() => {
        if (!rootStore.onboarding.loaded) {
            void api.user.onboarding.get().then(({ data }) => {
                if (data) rootStore.onboarding.update(data)
            })
        }

        const detachAdapter = rootStore.onboarding.setAdapter({
            save: (updates) =>
                api.user.onboarding
                    .update(updates)
                    .then(() => Promise.resolve()),
        })

        return detachAdapter
    }, [])
}
