import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import { autorun } from 'mobx'

import { featureFlags } from '@teamflow/bootstrap'
import { apiConfig } from '@teamflow/client-api'
import { waitForMilliseconds, LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import Feature from '@teamflow/types/src/verse/flags'

import { shouldUseSentry } from './sentry'

const initAppzi = () => {
    ;(window as any).appziSettings = { data: {} }
}

const allowedLogLevelsInSentryBreadcrumbs: Sentry.SeverityLevel[] = [
    'fatal',
    'error',
    'warning',
]

const originalIdleCallback =
    typeof window !== 'undefined' && 'requestIdleCallback' in window
        ? window.requestIdleCallback
        : undefined

const LOGROCKET_BUSY_FRAMES_REMOVAL_MAX_TRIES = 50
const LOGROCKET_SESSION_URL_RETRIEVAL_INTERVAL_MS = 5 * 60 * 1000

const environment = process.env.NEXT_PUBLIC_APP_ENV || process.env.DEPLOY_ENV

let logRocketSessionURL = ''

export const getLogRocketSessionURL = () => {
    return logRocketSessionURL
}

const setLogRocketSessionURL = (url: string) => {
    // integrate with Sentry
    Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', url)
    })

    // so our API requests also contain the session URL of the client
    apiConfig.addDefaultHeader('X-LogRocket-URL', url)

    logRocketSessionURL = url
}

let currentLogRocketSessionURLRetrievalTimeout: number | undefined = undefined

export const fetchAndSetLogRocketSessionURL = () => {
    clearTimeout(currentLogRocketSessionURLRetrievalTimeout)
    return new Promise<string>((resolve) => {
        LogRocket.getSessionURL((sessionURL) => {
            setLogRocketSessionURL(sessionURL)

            resolve(sessionURL)

            currentLogRocketSessionURLRetrievalTimeout = setTimeout(
                fetchAndSetLogRocketSessionURL,
                LOGROCKET_SESSION_URL_RETRIEVAL_INTERVAL_MS
            ) as unknown as number
        })
    })
}

const initSentry = () => {
    if (!shouldUseSentry) return

    LogManager.global.debug(
        'sentry release',
        process.env.NEXT_PUBLIC_COMMIT_SHA
    )

    const developmentOnlyErrorsToDisable =
        environment === 'development'
            ? [
                  'call_error Meeting is full', // daily error for when there are too many participants - ignored due to p1k on dev
              ]
            : []

    const sentryOpts: Sentry.BrowserOptions = {
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        environment,
        release: `teamflow-frontend-${environment}@${process.env.NEXT_PUBLIC_COMMIT_SHA}`,
        autoSessionTracking: false, // TODO(jonathan): enable session tracking later: https://github.com/getsentry/sentry-javascript/blob/master/CHANGELOG.md#600
        // we already have logrocket doing performance tracing and we also plan to add newrelic later
        tracesSampleRate: 0,
        normalizeDepth: 10,
        ignoreErrors: [
            ...developmentOnlyErrorsToDisable,
            'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching', // Microsoft Crawler causing some frontend errors - https://getteamflow.slack.com/archives/C01C304DTS7/p1629313618087300
            'ResizeObserver loop limit exceeded', // we can safely ignore this error: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
        ],
        // we do not want to instrument rAF
        integrations: [
            new Sentry.Integrations.TryCatch({
                requestAnimationFrame: false,
            }),
        ],
        beforeSend(event, hint) {
            // we are logging the event cuz we want to see the error on LogRocket
            // and we have disabled LogRocket's own error reporting.
            LogManager.global.error(
                `Captured an error with Sentry: ${
                    typeof hint?.originalException === 'string'
                        ? hint.originalException
                        : typeof hint?.originalException === 'object' &&
                          hint?.originalException instanceof Error
                        ? hint.originalException.message
                        : 'Unknown error'
                }`,
                hint?.originalException
            )
            return event
        },
        beforeBreadcrumb(breadcrumb, _hint) {
            if (breadcrumb.type === 'http') {
                const url = breadcrumb.data?.url as string | undefined
                if (!url) return breadcrumb

                // ignore breadcrumbs related to URLs like these:
                // https://r.lr-ingest.io/i?a=mgzpyr%2Fhuddle-production&r=4-3c8e34fd-f033-405d-b0e7-a85f8ff1d965&t=244764d2-fd8c-4477-855a-d1d8e67fe5cb&s=0&rs=0%2Ct&u=605137b4987e90000a9cf8a8
                // https://api.segment.io/v1/t
                // https://{COLLECTOR_URL}/v1/metrics
                // indexOf seems to be faster than substring / RegExp for partial search - .includes is not available in all browsers
                if (
                    url.indexOf('https://r.lr-ingest.io') > -1 ||
                    url.indexOf('https://api.segment.io/v1/') > -1 ||
                    (process.env.NEXT_PUBLIC_OPENTELEMETRY_COLLECTOR_URL &&
                        url.indexOf(
                            `${process.env.NEXT_PUBLIC_OPENTELEMETRY_COLLECTOR_URL}`
                        ) > -1)
                ) {
                    return null
                }
            }

            // ignore daily's redux breadcrumbs
            if (breadcrumb.category === 'redux-action') {
                return null
            }

            // ignore all logs that are not errors - we use LogRocket instead of Sentry to trace all logs for a single session
            if (
                breadcrumb.category === 'console' &&
                breadcrumb.level &&
                allowedLogLevelsInSentryBreadcrumbs.indexOf(
                    breadcrumb.level
                ) === -1
            ) {
                return null
            }

            return breadcrumb
        },
    }
    Sentry.init(sentryOpts)
}

let initializedLogRocket = false

const initLogRocket = (domEnabled: boolean) => {
    if (!initializedLogRocket) {
        LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID, {
            dom: {
                isEnabled: domEnabled,
            },
            console: {
                isEnabled: true,
                shouldAggregateConsoleErrors: true,
            },
            network: {
                requestSanitizer: (request) => {
                    // ignore metrics req
                    if (
                        process.env.NEXT_PUBLIC_OPENTELEMETRY_COLLECTOR_URL &&
                        request.url.indexOf(
                            `${process.env.NEXT_PUBLIC_OPENTELEMETRY_COLLECTOR_URL}`
                        ) > -1
                    ) {
                        return null
                    }
                    // ignore sensitive endpoints
                    if (
                        // just being extra safe, as the type says this will always be a string
                        typeof request.body === 'string' &&
                        // login
                        (request.url.toLowerCase().indexOf('/login') !== -1 ||
                            // update password
                            request.body.indexOf('password') !== -1 ||
                            request.body.indexOf('currentPassword') !== -1)
                    ) {
                        request.body = '**sensitive**'
                        return request
                    }

                    // otherwise log the request normally
                    return request
                },
            },
            release: process.env.NEXT_PUBLIC_COMMIT_SHA,
        })

        // Makes it easier to find LogRocket sessions by AV id.
        // In LogRocket dashboard, use the following session filter:
        // Custom Event > is > 'av_joined'
        autorun(() => {
            const avId = rootStore.participants.localParticipant?.currentAvId
            if (avId) {
                LogRocket.track('av_joined', { avId })
            }
        })

        void fetchAndSetLogRocketSessionURL()

        initializedLogRocket = true

        // We don't want the BusyFrames feature from LogRocket as
        // we have seen it using a lot of scripting time randomly.
        // As LogRocket does not offer a way to disable/remove that,
        // we are using this hack approach to do it.
        if (originalIdleCallback) {
            let retries = 0
            try {
                window.requestIdleCallback = (fn, opts) => {
                    if (retries++ >= LOGROCKET_BUSY_FRAMES_REMOVAL_MAX_TRIES) {
                        LogManager.global.warn(
                            `Could not disable LogRocket BusyFrames after ${LOGROCKET_BUSY_FRAMES_REMOVAL_MAX_TRIES} tries.`
                        )
                        window.requestIdleCallback = originalIdleCallback
                    }

                    if (typeof fn !== 'function')
                        return originalIdleCallback(fn, opts)

                    const fnSource = fn.toString()
                    // this may break if they change the internals
                    // of the function, but if that happens we would
                    // bail from trying to do that after the limit of tries
                    // is reached
                    if (
                        fnSource.indexOf('busyFrames') !== -1 &&
                        fnSource.indexOf('nextIdle') !== -1
                    ) {
                        window.requestIdleCallback = originalIdleCallback
                        return 0
                    }

                    return originalIdleCallback(fn, opts)
                }
            } catch (error) {
                LogManager.global.error(
                    'Could not override requestIdleCallback to remove LogRocket BusyFrames',
                    error
                )
            }
        }
    }
}

const LOGROCKET_BLACKLIST = [
    '/tf-internal/desktop-windows/screenshare-banner',
    '/tf-internal/desktop-windows/pointers-overlay-window',
]

export const initialize = async () => {
    // we only want to initialize this in the client
    if (typeof window === 'undefined') return

    initSentry()
    initAppzi()

    // this will initialize flagsmith
    // need to do this before calling setTraits
    await featureFlags.initialize()

    if (
        environment !== 'production' &&
        environment !== 'staging' &&
        environment !== 'qa' &&
        environment !== 'development'
    ) {
        return
    }

    // check whether to wait for flagsmith identify
    // before initializing logrocket; defaults to waiting
    const waitForIdentify = await featureFlags.isEnabled(
        Feature.LogRocketWaitIdentify
    )

    if (waitForIdentify) {
        // wait for flagsmith to be identified
        // we race against the timeout to avoid not enabling LogRocket in pages that do not have a logged user
        await Promise.race([
            waitForMilliseconds(5000),
            featureFlags.whenIdentified(),
        ])
    }

    // Don't use logrocket on desktop windows like screenshare overlay
    if (LOGROCKET_BLACKLIST.includes(window.location.pathname)) {
        return
    }

    // we do the whenIdentified() wait above so that we can have segment
    // information before setting domEnabled below
    const logRocketDisabled = await featureFlags.isEnabled(
        Feature.DisableLogRocket
    )
    if (!logRocketDisabled) {
        const domEnabled = await featureFlags.isEnabled(
            Feature.EnableLogRocketDom
        )
        initLogRocket(domEnabled)
    }
}

export async function identify(
    userId: string,
    traits: { [key: string]: any } = {}
) {
    LogRocket.identify(userId, traits)

    // minimum identification for Sentry
    // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
    Sentry.setUser({
        id: userId,
        username: traits.name,
        email: traits.email,
        org: traits.org,
        product: traits.product,
        role: traits.role,
        tier: traits.tier,
    })

    let traitOverrides = {}

    if (process.env.NEXT_PUBLIC_FLAGSMITH_USER_TRAIT_OVERRIDES) {
        try {
            traitOverrides = JSON.parse(
                process.env.NEXT_PUBLIC_FLAGSMITH_USER_TRAIT_OVERRIDES
            )

            if (
                typeof traitOverrides !== 'object' ||
                Array.isArray(traitOverrides)
            ) {
                console.warn(
                    'Invalid trait overrides provided - must be an object'
                )
                traitOverrides = {}
            }
        } catch {
            /* noop */
        }
    }

    try {
        await featureFlags.identify(userId, {
            email: traits.email,
            name: traits.name,
            org: traits.org,
            product: traits.product,
            role: traits.role,
            tier: traits.tier,
            newOrg: traits.newOrg,
            newUser: traits.newUser,
            tfbot: window.navigator.userAgent.indexOf('via TFBot') > -1, // true if this is a bot
            ...traitOverrides,
        })
    } catch (err) {
        console.warn(err)
    }
}
