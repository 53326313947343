import { useEffect, useState } from 'react'

import { documentProxy } from '@teamflow/bootstrap'

/**
 * Gives the number of pixels from the right edge of the screen taken up
 * by the title bar window control buttons.
 */
export function useTitleBarOffsetWidth() {
    const [val, setVal] = useState(0)

    useEffect(() => {
        if ('windowControlsOverlay' in navigator) {
            const { windowControlsOverlay } = navigator as any

            const updateOffsetWidth = () => {
                // Chrome/Electron doesn't support Fullscreen API's
                // `document.fullscreenElement`
                const isFullscreen =
                    window.innerWidth === screen.width &&
                    window.innerHeight === screen.height

                // windowControlsOverlay reports that title bar controls
                // are visible in fullscreen even through they are not
                if (isFullscreen || !windowControlsOverlay.visible) {
                    setVal(0)
                    return
                }

                const titleBarArea = windowControlsOverlay.getTitlebarAreaRect()

                let offsetWidth = window.innerWidth - titleBarArea.right
                if (offsetWidth < 0) offsetWidth = 0
                setVal(offsetWidth)
            }

            // defer offset width calculation in event handler because
            // `window.innerWidth` and `windowControlsOverlay.getTitlebarAreaRect`
            // don't simultaneously have the latest values when the event is fired
            const updateOffsetWidthDeferred = () => {
                setTimeout(updateOffsetWidth, 0)
            }

            updateOffsetWidth()

            windowControlsOverlay.addEventListener(
                'geometrychange',
                updateOffsetWidthDeferred
            )
            documentProxy.addEventListener(
                'fullscreenchange',
                updateOffsetWidthDeferred
            )

            return () => {
                windowControlsOverlay.removeEventListener(
                    'geometrychange',
                    updateOffsetWidthDeferred
                )
                documentProxy.removeEventListener(
                    'fullscreenchange',
                    updateOffsetWidthDeferred
                )
            }
        }
    }, [])

    return val
}
