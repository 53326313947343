import { Platform } from '@teamflow/bootstrap'

export const APP_DOWNLOAD_PAGE = 'https://www.teamflowhq.com/download'
export const MOBILE_APP_DOWNLOAD_PAGE =
    'https://www.teamflowhq.com/download#mobile-beta'

export function getMobileAppDownloadUrl() {
    const platform = Platform.current()

    switch (platform) {
        case 'Android':
            return 'https://play.google.com/store/apps/details?id=com.teamflowhq.mobileApp'
        case 'iOS':
            return 'https://testflight.apple.com/join/GupvMvhC'
        default:
            return MOBILE_APP_DOWNLOAD_PAGE
    }
}

export function getAppDownloadUrl() {
    const platform = Platform.current()
    const downloadPath =
        platform === 'MacOS'
            ? 'Teamflow-latest-universal.dmg'
            : platform === 'Windows'
            ? 'Teamflow%20Setup-latest.exe'
            : null

    if (!downloadPath) return null
    // TODO: do we care about putting staging here?
    const BASE_DOWNLOAD_URL = `https://huddle-production.${process.env.NEXT_PUBLIC_DIGITAL_OCEAN_SPACES_URL}/desktop-apps/`

    return BASE_DOWNLOAD_URL + downloadPath
}

export function openAppDownloadURL() {
    window.open(getAppDownloadUrl() ?? APP_DOWNLOAD_PAGE)
}

export function openMobileAppDownloadURL() {
    window.open(MOBILE_APP_DOWNLOAD_PAGE)
}

export default getAppDownloadUrl
