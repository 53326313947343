import { createRequest, request } from '../request'

const customers = {
    get: (args: { orgId: string } | { stripeCustomerId: string }) => {
        const params = new URLSearchParams()
        if ('orgId' in args && args.orgId) params.append('orgId', args.orgId)
        if ('stripeCustomerId' in args && args.stripeCustomerId)
            params.append('stripeCustomerId', args.stripeCustomerId)

        return request<'GET /api/billing/customers'>(
            'GET',
            `/api/billing/customers?${params.toString()}`
        )
    },
    create: createRequest<'POST /api/billing/customers'>(
        'POST',
        '/api/billing/customers'
    ),
    createFromStripe: createRequest<'POST /api/billing/customers/fromStripe'>(
        'POST',
        '/api/billing/customers/fromStripe'
    ),
    updateFromStripe: createRequest<'PATCH /api/billing/customers/fromStripe'>(
        'PATCH',
        '/api/billing/customers/fromStripe'
    ),
    update: createRequest<'PATCH /api/billing/customers'>(
        'PATCH',
        '/api/billing/customers'
    ),
}

const subscriptions = {
    get: ({ orgId }: { orgId: string }) =>
        request<'GET /api/billing/subscriptions/:orgId'>(
            'GET',
            `/api/billing/subscriptions/${orgId}`
        ),
    create: createRequest<'POST /api/billing/subscriptions'>(
        'POST',
        '/api/billing/subscriptions'
    ),
    complete: createRequest<'POST /api/billing/subscriptions/complete'>(
        'POST',
        '/api/billing/subscriptions/complete'
    ),
    retrieve: ({ subscriptionId }: { subscriptionId: string }) =>
        request<'GET /api/billing/subscriptions/fromStripe/:subscriptionId'>(
            'GET',
            `/api/billing/subscriptions/fromStripe/${subscriptionId}`
        ),
}

const coupons = {
    get: ({ couponId, productId }: { couponId: string; productId: string }) =>
        request<'GET /api/billing/coupons/:id'>(
            'GET',
            `/api/billing/coupons/${couponId}?productId=${productId}`
        ),
}

const products = {
    get: ({ priceId }: { priceId: string }) =>
        request<'GET /api/billing/products'>(
            'GET',
            `/api/billing/products?priceId=${priceId}`
        ),
}

const prices = {
    list: createRequest<'GET /api/billing/prices'>(
        'GET',
        '/api/billing/prices'
    ),
}

const invoices = {
    list: (params: { orgId: string; startingAfterId?: string }) =>
        request<'GET /api/billing/invoices?orgId&startingAfterId'>(
            'GET',
            `/api/billing/invoices?${new URLSearchParams(
                Object.entries(params).filter((p) => !!p[1])
            ).toString()}`
        ),
}

const paymentMethod = {
    get: ({ orgId }: { orgId: string }) =>
        request<'GET /api/billing/paymentMethod/:orgId'>(
            'GET',
            `/api/billing/paymentMethod/${orgId}`
        ),
    delete: ({ orgId }: { orgId: string }) =>
        request<'DELETE /api/billing/paymentMethod/:orgId'>(
            'DELETE',
            `/api/billing/paymentMethod/${orgId}`
        ),
}

export default {
    customers,
    subscriptions,
    coupons,
    products,
    prices,
    invoices,
    paymentMethod,
}
