import { DataChange } from '@colyseus/schema'
import _ from 'lodash'
import { runInAction } from 'mobx'

import { AVATAR_RADIUS, throttleReduce } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'
import { IParticipant_RoomSpecific } from '@teamflow/types'

import Events from '../../events'

import { Connect, Substate, SubstateConnector } from './StateConnector'
import {
    changesAccumulator,
    changesTransformer,
    putOrRemoveFromHashBasedOnSessionId,
    throttleOptions,
    throttleWait,
} from './helpers'

const putParticipantInHash = (item: t.IParticipant) => {
    rootStore.spatialHash.put(
        item.id,
        'participant',
        item.x - AVATAR_RADIUS,
        item.y - AVATAR_RADIUS,
        item.x + AVATAR_RADIUS,
        item.y + AVATAR_RADIUS
    )
}

type ParticipantsRoomSpecific =
    Substate<'roomSubstates'>['participantsMapRoomSpecific']

@Connect({ key: 'roomSubstates', substate: 'participantsMapRoomSpecific' })
export class ParticipantRoomSpecificConnector extends SubstateConnector<
    'roomSubstates',
    'participantsMapRoomSpecific'
> {
    onAttach(participantsMapRoomSpecific: ParticipantsRoomSpecific) {
        participantsMapRoomSpecific.onAdd = (item) => {
            item.onChange = throttleReduce(
                (changes: DataChange<any>[]) => {
                    const participant =
                        rootStore.participants.findParticipantById(item.id)
                    if (!participant) {
                        return
                    }

                    changes.forEach((change) => {
                        const field =
                            change.field as keyof IParticipant_RoomSpecific
                        const value = change.value
                        if (participant) {
                            participant.update({ [field]: value })
                        }

                        switch (field) {
                            case 'x':
                            case 'y':
                                this.throttledEmitter.emit(
                                    Events.ParticipantPositionChanged,
                                    participant.id,
                                    participant
                                )
                                if (participant) {
                                    rootStore.participants.updateSpatialHash(
                                        participant
                                    )
                                }
                                break
                        }
                    })

                    this.events.emit(Events.ParticipantChanged, participant)
                    this.events.emit(Events.ParticipantUpdated, participant)

                    putOrRemoveFromHashBasedOnSessionId(
                        participant,
                        putParticipantInHash
                    )

                    this.throttledEmitter.emit(
                        Events.ParticipantsUpdated,
                        Events.ParticipantsUpdated,
                        this.realtime.participants
                    )
                },
                changesAccumulator,
                changesTransformer,
                throttleWait,
                throttleOptions
            )

            item.networkQuality.onChange = (changes: DataChange<any>[]) => {
                const participant = rootStore.participants.findParticipantById(
                    item.id
                )
                if (!participant) return

                const update = _.mapValues(
                    _.keyBy(changes, 'field'),
                    'value'
                ) as t.INetworkQuality

                participant.updateNetworkQuality(update)
            }

            item.cursor.onChange = (changes: DataChange<any>[]) => {
                const participant = rootStore.participants.findParticipantById(
                    item.id
                )
                if (!participant) return

                const update = _.mapValues(
                    _.keyBy(changes, 'field'),
                    'value'
                ) as t.ICursor

                runInAction(() => {
                    participant.cursor = {
                        ...participant.cursor,
                        ...update,
                    }
                })

                const cursorChangedEvent: t.IParticipantCursorChanged = {
                    userId: participant.id,
                    ...participant.cursor,
                }
                this.events.emit(
                    Events.ParticipantCursorChanged,
                    cursorChangedEvent
                )
            }

            item.faceCentering.onChange = (changes: DataChange<any>[]) => {
                const participant = rootStore.participants.findParticipantById(
                    item.id
                )
                if (!participant) {
                    return
                }
                const update = _.mapValues(
                    _.keyBy(changes, 'field'),
                    'value'
                ) as t.IFaceCentering
                runInAction(() => {
                    participant.faceCentering = {
                        ...participant.faceCentering,
                        ...update,
                    }
                })
            }

            const p = rootStore.participants.put(item)
            this.events.emit(Events.ParticipantAdded, p)
            this.events.emit(Events.ParticipantUpdated, p)
            putOrRemoveFromHashBasedOnSessionId(p, putParticipantInHash)

            this.throttledEmitter.emit(
                Events.ParticipantsUpdated,
                Events.ParticipantsUpdated,
                this.realtime.participants
            )
        }

        participantsMapRoomSpecific.onRemove = (_item) => {
            // Do nothing. Removing the room-specific object means the
            // participant moved to another room, so we can simply let
            // the existing data sit in the store. It will eventually be
            // overridden by a new room-specific participant.
        }
    }

    onDetach(participantsMapRoomSpecific: ParticipantsRoomSpecific) {
        participantsMapRoomSpecific.onAdd = undefined
        participantsMapRoomSpecific.onRemove = undefined
    }
}
