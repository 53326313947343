import { api } from '@teamflow/client-api'

export async function signupToOrgs(email?: string) {
    if (!(email && email.includes('@'))) {
        return { organizations: [] }
    }

    const { data } = await api.user.signupByEmailDomain({
        emailDomain: encodeURI(email.split('@')[1]),
    })

    return { organizations: data?.organizations ?? [] }
}
