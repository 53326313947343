import { ParsedUrlQuery } from 'querystring'

import _ from 'lodash'

import type { IVerseParams } from '@teamflow/types'

export type VerseParams = IVerseParams

const uuidRegex = new RegExp(/[0-9a-f]{12}$/)

/**
 * The query comes in as an array of the path parts after root /
 * so it is basically: ["asdasfadfasd", "12345"]
 * if the route was: /asdasfadfasd/kitchen-12345-ss
 *
 * @param query
 */
export function parseQuery(query: ParsedUrlQuery): VerseParams {
    if (!query || !query.org) {
        return {
            org: '',
            slugName: '',
            code: '',
        }
    }

    let list = query.org as string[] | string

    if (_.isString(list)) {
        list = [list] as string[]
    }

    const params = {} as VerseParams

    const extractInviteCode = (
        stringValue: string,
        requireHyphenBefore: boolean
    ) => {
        const code = stringValue.match(uuidRegex)

        let uuidIndex = null
        let uuid = null

        if (code) {
            const hasHyphenBefore =
                code.index != null &&
                code.index > 0 &&
                stringValue.charAt(code.index - 1) === '-'

            if (!requireHyphenBefore || hasHyphenBefore) {
                uuid = code[0]
                uuidIndex = code.index
            }
        }

        return { uuid, uuidIndex }
    }

    list.forEach((val, idx) => {
        switch (idx) {
            case 0: {
                const { uuid, uuidIndex } = extractInviteCode(val, true)
                if (uuid) {
                    params.code = uuid
                }

                const orgName = uuidIndex
                    ? val.substring(0, uuidIndex - 1)
                    : val
                params.org = orgName

                break
            }

            case 1: {
                if (params.code && uuidRegex.test(val)) {
                    // This value is an invitation code
                    params.invitation = val
                    break
                }

                const index = val.indexOf('-')
                if (index < 0) {
                    params.slugName = val
                    break
                }

                const { uuid, uuidIndex } = extractInviteCode(val, true)
                if (uuid) {
                    params.code = uuid
                }

                const slugName = uuidIndex
                    ? val.substring(0, uuidIndex - 1)
                    : val

                params.slugName = slugName

                break
            }

            case 2: {
                const { uuid } = extractInviteCode(val, false)
                if (uuid) {
                    params.invitation = uuid
                }
                break
            }
            default:
                break
        }
    })

    if (query.locationX && query.locationY) {
        params.locationX = Number(query.locationX)
        params.locationY = Number(query.locationY)
    }

    if (query.pushType) {
        params.pushType = query.pushType as VerseParams['pushType']
        params.pushSenderId = query.pushSenderId as VerseParams['pushSenderId']
    }

    if (query.mobileWebView) {
        params.mobileWebView = Boolean(query.mobileWebView)
    }
    if (query.hideMobileNavigation) {
        params.hideMobileNavigation = Boolean(query.hideMobileNavigation)
    }

    if (query.scene) {
        params.scene = query.scene as string
    }

    return params
}

/**
 * Validates that the passed {@code url} is an in-app verse URL. If so, returns the parsed result.
 *
 * It uses {@code process.env.NEXT_PUBLIC_APP_URL} to match the domain.
 *
 * This will return an erroneous result if one of the "pages" URL is used,
 * i.e. `https://app.teamflowhq.com/upgrade`.
 *
 * @param url - A valid URL.
 */
export function parseVerseURL(url: string): VerseParams | null {
    const appURL = new URL(process.env.NEXT_PUBLIC_APP_URL as string)
    let validateURL: URL

    try {
        validateURL = new URL(url)
    } catch {
        return null
    }

    if (appURL.host === validateURL.host) {
        const query = validateURL.pathname.slice(1) // remove initial /
        const params = parseQuery({
            org: query.split('/'),
            ...Object.fromEntries(validateURL.searchParams.entries()),
        })

        return params
    }

    // "url" is from a different host
    return null
}

export default parseQuery
