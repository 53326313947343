import debounce from 'lodash/debounce'
import { makeAutoObservable, observable, reaction, runInAction } from 'mobx'
import { clearPersistedStore, makePersistable } from 'mobx-persist-store'

import {
    AUDIO_INPUT_AUTO_UPDATED,
    AUDIO_OUTPUT_AUTO_UPDATED,
    generateShortUuid,
    getNewDevices,
    LogManager,
    MIC_SELF_HEALING_COUNT,
    MIC_SELF_HEALING_INTERVAL,
    retry,
    selectDeviceList,
    VIDEO_INPUT_AUTO_UPDATED,
} from '@teamflow/lib'
import {
    AVBandwidthUsage,
    AVDebugData,
    AVDeviceError,
    AVInputDevices,
    AVPeerConnections,
    AVProblem,
    AVProvider,
    AVState,
    AVWebRTCStats,
    getRemoteJsonDefault,
    IAnalytics,
    ILogger,
    INetworkQuality,
    ParticipantSimulcastLayer,
    RoomNavigationState,
    ScreenSharePreset,
} from '@teamflow/types'

import { ParticipantStore } from './participantStore'

import type { CommonsStore } from './CommonsStore'
import type { Participant } from './Participant'
import type { PerformanceStore } from './PerformanceStore'
import type { SettingsStore } from './SettingsStore'
import type { UserStore } from './userStore'

export type VideoProcessorMode =
    | { type: 'none'; config?: {} }
    | {
          type: 'background-blur'
          config: { readonly strength: number }
      }
    | {
          type: 'background-image'
          config: { readonly source: string }
      }

export interface AudioVideoStoreAdapter {
    analytics?: IAnalytics
    readonly supportedFeatures: Readonly<{
        'video-processor.background-blur'?: boolean
        'video-processor.background-image'?: boolean
    }>

    join: () => Promise<void>
    leave: () => Promise<void>
    updateSubscriptions: (participants: Participant[]) => void
    setMicDevice: (deviceId: string) => Promise<void>
    setCameraDevice: (deviceId: string) => Promise<void>
    setMicEnabled: (isEnabled: boolean) => Promise<void>
    setCameraEnabled: (isEnabled: boolean) => Promise<void>
    setParticipantVideoSimulcastLayer: (
        participantId: string,
        layer: ParticipantSimulcastLayer
    ) => Promise<void>
    setParticipantScreenShareSimulcastLayer: (
        participantId: string,
        layer: ParticipantSimulcastLayer
    ) => Promise<void>
    getScreenShareStreams: (
        preset: ScreenSharePreset,
        audioOnlyCapture?: boolean
    ) => Promise<MediaStream[]>
    startScreenShare: (
        streams: MediaStream[],
        preset: ScreenSharePreset
    ) => Promise<void>
    stopScreenShare: () => Promise<void>
    getNetworkQuality: () => Promise<INetworkQuality | undefined>
    checkDevicePermissions: () => Promise<void>
    getDebugData: () => Promise<AVDebugData | null>
    getBandwidthUsage: () => Promise<AVBandwidthUsage | undefined>
    getPeerConnections: () => AVPeerConnections | undefined
    processVideo: (
        mode: VideoProcessorMode
    ) => Promise<VideoProcessorMode & { error?: boolean }>
    requestUpdatedInputState: () => Promise<AVInputDevices | undefined>
}

const NULL_AVM_ERR = `AudioVideoServiceAdapter not registered.`

function logError(message: string) {
    if (process.env.NODE_ENV !== 'test') {
        LogManager.global.error(message, {
            call: true,
            critical: true,
        })
    }
}

export class NullAudioVideoStoreAdapter implements AudioVideoStoreAdapter {
    supportedFeatures = {
        'video-processor.background-blur': true,
        'video-processor.background-image': true, // fake it until you make (bind actual adapter) it
    }

    async join() {
        logError(`Cannot join, ${NULL_AVM_ERR}`)
    }

    async leave() {
        logError(`Cannot leave, ${NULL_AVM_ERR}`)
    }

    updateSubscriptions() {
        logError(`Cannot update subscriptions, ${NULL_AVM_ERR}`)
    }

    async setMicDevice(deviceId: string) {
        logError(`Cannot set mic to ${deviceId}, ${NULL_AVM_ERR}`)
    }

    async setCameraDevice(deviceId: string) {
        logError(`Cannot set camera to ${deviceId}, ${NULL_AVM_ERR}`)
    }

    async setMicEnabled(isEnabled: boolean) {
        const prefix = isEnabled ? 'en' : 'dis'
        logError(`Cannot ${prefix}able mic, ${NULL_AVM_ERR}`)
    }

    async setCameraEnabled(isEnabled: boolean) {
        const prefix = isEnabled ? 'en' : 'dis'
        logError(`Cannot ${prefix}able camera, ${NULL_AVM_ERR}`)
    }

    async setParticipantVideoSimulcastLayer(
        participantId: string,
        layer: ParticipantSimulcastLayer
    ) {
        logError(
            `Cannot set participant video ${participantId} layer to ${layer}, ${NULL_AVM_ERR}`
        )
    }

    async setParticipantScreenShareSimulcastLayer(
        participantId: string,
        layer: ParticipantSimulcastLayer
    ) {
        logError(
            `Cannot set participant screen ${participantId} layer to ${layer}, ${NULL_AVM_ERR}`
        )
    }

    async getScreenShareStreams() {
        logError(`Cannot get screen share stream, ${NULL_AVM_ERR}`)
        return []
    }

    async startScreenShare() {
        logError(`Cannot start screen share, ${NULL_AVM_ERR}`)
        return
    }

    async stopScreenShare() {
        logError(`Cannot stop screen share, ${NULL_AVM_ERR}`)
    }

    async getNetworkQuality() {
        logError(`Cannot get network quality, ${NULL_AVM_ERR}`)
        return undefined
    }

    async checkDevicePermissions() {
        logError(`Cannot get check device permissions, ${NULL_AVM_ERR}`)
        return undefined
    }

    async getDebugData() {
        logError(`Cannot get debug data, ${NULL_AVM_ERR}`)
        return null
    }

    getPeerConnections() {
        logError(`Cannot get peer connections, ${NULL_AVM_ERR}`)
        return undefined
    }

    async getBandwidthUsage() {
        logError(`Cannot get bandwidth usage, ${NULL_AVM_ERR}`)
        return undefined
    }

    processVideo() {
        return Promise.resolve({ type: 'none' as const })
    }

    async requestUpdatedInputState() {
        return { micId: null, cameraId: null }
    }
}

/**
 * Managing state for custom video processing settings inside {@link AudioVideoStore}.
 */
class VideoProcessor {
    supported: Array<VideoProcessorMode['type']> = []
    mode: VideoProcessorMode = { type: 'none' }
    updating = false
    error = false

    _adapter: AudioVideoStoreAdapter
    _timeout: number | null = null
    readonly _logger: ILogger

    constructor(_adapter: AudioVideoStoreAdapter, _logger: LogManager) {
        makeAutoObservable(this, {
            _adapter: false,
            _timeout: false,
            _logger: false,
        })
        void makePersistable(this, {
            name: 'AudioVideoStore.VideoProcessor',
            properties: ['mode'],
            // Stop it blowing up with SSR
            storage:
                typeof window !== 'undefined' ? window.localStorage : undefined,
            // Set to true for verbose logs
            debugMode: false,
        })

        this._adapter = _adapter
        this._logger = _logger.child({
            class: 'AudioVideoStore.VideoProcessor',
        })
        this.publish = this.publish.bind(this)
        this.resolveWithError = this.resolveWithError.bind(this)
        this.bindAdapter(_adapter)
    }

    reset(shouldClearPersistentStore = false) {
        if (shouldClearPersistentStore) {
            void clearPersistedStore(this)
        }
    }

    /** Regular background blur processing. */
    blur() {
        this._logger.info({
            action: 'Call@BlurLocalVideo',
            strength: 0.5,
        })
        this.markUpdate()
        this._adapter
            .processVideo({
                type: 'background-blur',
                config: { strength: 0.5 },
            })
            .then(this.publish)
            .catch(this.resolveWithError)
    }

    /** No video processing. */
    none() {
        this._logger.info('AudioVideoStore: VideoProcessor switching to none')
        this.markUpdate()
        this._adapter
            .processVideo({ type: 'none', config: {} })
            .then(this.publish)
            .catch(this.resolveWithError)
    }

    /** Publish new video processsing mode in effect. To be used by implementation. */
    publish(mode: VideoProcessorMode & { error?: boolean }) {
        this._logger.info({
            action: 'Call@PublishVideoProcessorConfiguration',
            mode,
        })
        this.mode = mode
        this.updating = false
        this.error = mode.error ?? false

        if (this._timeout !== null) {
            clearTimeout(this._timeout)
            this._timeout = null
        }
    }

    /**
     * Bind adapter. Resets settings to "none" if new adapter does not support current
     * settings.
     */
    bindAdapter(adapter: AudioVideoStoreAdapter) {
        this._adapter = adapter
        this.supported = adapter.supportedFeatures[
            'video-processor.background-blur'
        ]
            ? ['none', 'background-blur']
            : ['none']

        if (!this.supported.includes(this.mode.type)) {
            this._logger.warn(
                'Falling back to no processing due to lack of support in adapter!'
            )
            this.publish({ type: 'none' })
        }

        if (this.mode.type !== 'none') {
            this._logger.warn(
                'Starting with virtual background features. ' +
                    'This may cause issues related to related to reliability and / or performance.'
            )
        }
    }

    isOn() {
        return this.mode.type !== 'none'
    }

    get supportsBlur(): boolean {
        return this.supported.includes('background-blur')
    }

    private resolveWithError(e: any) {
        this._logger.error('AudioVideoStore: VideoProcessor Unknown error', e)
        this.error = true
        this.mode = { type: 'none' }
    }

    private markUpdate() {
        this.updating = true

        if (this._timeout !== null) clearTimeout(this._timeout)
        this._timeout = window.setTimeout(() => {
            runInAction(() => {
                this.error = true
            })
            this._logger.error('Video processing change did not reflect')
        }, 4000)
    }
}

/**
 * Logic for selecting a new device:
 * If we're tracking system, then auto-pick the first device, which is the default system device
 * If we're tracking a preferred device, then auto-pick that one if it exists
 * Otherwise auto-pick the default system device
 *
 * When a new device is plugged in which is not auto-picked, a prompt will pop up asking if
 * the user wants to select it as their preferred device. This does not happen on initial
 * load, only afterwards.
 */
const getUpdatedDevice = ({
    list,
    currentId,
    preferredId,
    trackSystem,
}: {
    list: ReadonlyArray<MediaDeviceInfo>
    currentId: string | null
    preferredId: string | null
    trackSystem: boolean
}) => {
    const defaultDevice = list.length && list[0]
    if (!defaultDevice) return null

    const defaultDeviceJSON =
        'toJSON' in defaultDevice ? defaultDevice.toJSON() : defaultDevice

    if (trackSystem && currentId !== defaultDevice.deviceId) {
        LogManager.global.debug(
            `AudioVideoStore: change ${defaultDevice.kind} to new system default`,
            {
                currentDeviceId: currentId,
                defaultDevice: defaultDeviceJSON,
                preferredId,
                trackSystem,
            }
        )
        return defaultDevice
    }

    const preferredDevice = list.find((d) => d.deviceId === preferredId)
    if (preferredDevice && currentId !== preferredDevice.deviceId) {
        LogManager.global.debug(
            `AudioVideoStore: preferred ${preferredDevice.kind} was connected`,
            {
                currentDeviceId: currentId,
                defaultDevice: defaultDeviceJSON,
                preferredDevice:
                    'toJSON' in preferredDevice
                        ? preferredDevice.toJSON()
                        : preferredDevice,
                trackSystem,
            }
        )
        return preferredDevice
    }

    if (!list.find((d) => d.deviceId === currentId)) {
        LogManager.global.debug(
            `AudioVideoStore: current ${defaultDevice.kind} was disconnected (${currentId}), revert to system default`,
            {
                currentDeviceId: currentId,
                defaultDevice: defaultDeviceJSON,
                preferredId,
                trackSystem,
            }
        )
        return defaultDevice
    }

    return null
}

/**
 * This store controls state related to the (audio & video) call. The data pertains to the local user only.
 *
 * An {@link AudioVideoStoreAdapter} must be plugged in for call functionality to work. The store
 * provides an unified interface against call providers − like {@link DailyService} and {@link LiveKitService}.
 */
export class AudioVideoStore {
    /**
     * The default state of the microphone. When the "rememberAVState" setting is off, this should be
     * the state of microphone when opening Teamflow.
     *
     * Note that the setup screen overrides this.
     */
    readonly MICROPHONE_DEFAULT_STATE = false

    /**
     * The default state of the camera. When the "rememberAVState" setting is off, this should be the
     * state of the camera when opening Teamflow.
     *
     * Note that the setup screen overrides this.
     */
    readonly CAMERA_DEFAULT_STATE = false

    /**
     * The initial state of the microphone when "rememberAVState" is on. Since "rememberAVState" is on by default,
     * this will be the microphone state for new users when they enter Teamflow.
     */
    readonly MICROPHONE_INITIAL_STATE = true

    /**
     * The initial state of the camera when "rememberAVState" is on. Since "rememberAVState" is on by default,
     * this will be the camera state for new users when they enter Teamflow.
     */
    readonly CAMERA_INITIAL_STATE = true

    shardId: string | null = null
    /**
     * The name of the call provider implementation.
     *
     * The set {@link AudioVideoStoreAdapter} should be the corresponding implementation. This consistency
     * is required so all participants join the same call.
     */
    provider: AVProvider | null = null

    /** The state of the call connection. */
    state: AVState = AVState.None

    /** The list of available microphone devices on the system. */
    mics: ReadonlyArray<MediaDeviceInfo> = []

    /** The list of available camera devices on the system. */
    cameras: ReadonlyArray<MediaDeviceInfo> = []

    /** The list of available speaker devices on the system. */
    speakers: ReadonlyArray<MediaDeviceInfo> = []

    newlyConnectedDevices: ReadonlyArray<MediaDeviceInfo> = []

    /**
     * The [deviceId]{@link https://developer.mozilla.org/en-us/docs/Web/API/MediaDeviceInfo/deviceId} for the microphone.
     *
     * This preference is saved in local storage across sessions.
     *
     * @see trackSystemMic
     */
    micId: string | null = null
    preferredMicId: string | null = null

    /**
     * The [deviceId]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo/deviceId} for the camera.
     *
     * This preference is saved in local storage across sessions.
     *
     * @see trackSystemCamera
     */
    cameraId: string | null = null
    preferredCameraId: string | null = null

    /**
     * The [deviceId]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo/deviceId} for the speaker.
     *
     * Because of poor [setSinkId]{@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId}
     * support, this feature is not available on Safari and Android.
     *
     * This preference is saved in local storage across sessions.
     *
     * @see trackSystemSpeaker
     */
    speakerId: string | null = null
    preferredSpeakerId: string | null = null

    /** Sets whether to use the system-set microphone over {@link micId}. */
    trackSystemMic = true

    /** Sets whether to use the system-set camera over {@link cameraId}. */
    trackSystemCamera = true

    /** Sets whether to use the system-set speaker over {@link speakerId}. */
    trackSystemSpeaker = true

    /**
     * Tracks whether currently calling provider to toggle local audio.
     */
    micUpdating = false

    /** Tracks whether currently calling provider to toggle local video. Analogous to {@link micUpdating}. */
    cameraUpdating = false

    /** Sets whether to allow **any** audio input and output at all. */
    allAudioEnabled = true

    /** Sets whether to allow **any** video capture or streaming at all. */
    allVideoEnabled = true

    /** Synchronized with PiPService's enabled */
    pipEnabled = false

    /**
     * Set to true when the local user gets disconnected from the RT and the
     * soft-reconnect window for the RT passes. Setting this to true will set
     * all tracks to staged, which prevents the local user from listening in
     * on the call, but allows them to rejoin AV quickly later.
     */
    softDisconnect = false

    micError: AVDeviceError | null = null
    cameraError: AVDeviceError | null = null
    problems: AVProblem[] = []
    problemHistory: Map<string, number> = new Map()

    /** The simulcast layer to be subscribed to when the video is small. */
    defaultVideoLayer: ParticipantSimulcastLayer = getRemoteJsonDefault(
        'json-video-parameters'
    ).VIDEO_DEFAULT_LAYER
    /** The simulcast layer to be subscribed to when the screen share is small. */
    defaultScreenLayer: ParticipantSimulcastLayer = getRemoteJsonDefault(
        'json-video-parameters'
    ).SCREEN_DEFAULT_LAYER

    _logger: LogManager

    _adapter: AudioVideoStoreAdapter = new NullAudioVideoStoreAdapter()

    /**
     * The microphone state to be set when joining the call. This is enabled by default so that
     * new users are not confused due to lack of audio input when loading Teamflow.
     *
     * If "rememberAVState" is explicitly set to false, however, this will be set to
     * {@link MICROPHONE_DEFAULT_STATE} in the preloading phase.
     *
     * @protected
     */
    _enableMicWhenJoined = this.MICROPHONE_INITIAL_STATE

    /**
     * The camera state to be set when joining the call. The is enabled by default so that new users
     * are not confused due to lack to video input when loading Teamflow.
     *
     * If "rememberAVState" is explicitly set to false, however, this will be set to
     * {@link CAMERA_DEFAULT_STATE} in the preloading phase.
     *
     * @protected
     */
    _enableCameraWhenJoined = this.CAMERA_INITIAL_STATE

    _enableMicWhenAvailable = false
    _enableCameraWhenAvailable = false

    /**
     * Set on the A/V setup screen,
     * used in the google login flow to redirect users back to the A/V setup screen
     */
    isSetup = false

    private previousEnableMic

    screenStreams: MediaStream[] = []

    /**
     * When using Daily, this stores the preset the call was initialized with,
     * because Daily doesn't let us update call parameters without restarting.
     */
    screenShareStuckAtPreset: ScreenSharePreset | null = null

    readonly processor: {
        video: VideoProcessor
    }

    readonly settings: SettingsStore
    users: UserStore
    participants: ParticipantStore
    commons: CommonsStore
    performance: PerformanceStore

    selfHealMicTimeoutCancel = false

    /**
     * @param settings - Used for lite mode considerations.
     * @param users - Used for callId.
     * @param participants - Used for local participant's media
     */
    constructor(
        settings: SettingsStore,
        users: UserStore,
        participants: ParticipantStore,
        commons: CommonsStore,
        performance: PerformanceStore
    ) {
        this.settings = settings
        this.users = users
        this.participants = participants
        this.commons = commons
        this.performance = performance

        this._logger = LogManager.createLogger('AudioVideoStore', {
            critical: true,
            call: true,
            provider: {
                get: () => {
                    return this.provider
                },
                enumerable: true,
            },
            state: {
                get: () => {
                    return AVState[this.state]
                },
                enumerable: true,
            },
        })

        makeAutoObservable(this, {
            // Adapter shouldn't be observed.
            setAVAdapter: false,
            _adapter: false,
            _logger: false,
            // Stores shouldn't be observed.
            settings: false,
            users: false,
            participants: false,
            commons: false,
            performance: false,
            // Adapter methods that don't mutate state:
            setParticipantVideoSimulcastLayer: false,
            setParticipantScreenShareSimulcastLayer: false,
            startScreenShare: false,
            stopScreenShare: false,
            updateSubscriptions: false,
            join: false,
            leave: false,
            reconnect: false,
            getNetworkQuality: false,
            getDebugData: false,
            problemHistory: false,
            // ref only
            problems: observable.ref,
            mics: observable.ref,
            cameras: observable.ref,
            speakers: observable.ref,
            selfHealMicTimeoutCancel: false,
        })

        void makePersistable(this, {
            name: 'AudioVideoStore',
            properties: [
                'micId',
                'cameraId',
                'speakerId',
                'preferredMicId',
                'preferredCameraId',
                'preferredSpeakerId',
                'trackSystemMic',
                'trackSystemCamera',
                'trackSystemSpeaker',
                '_enableMicWhenJoined',
                '_enableCameraWhenJoined',
            ],
            // Stop it blowing up with SSR
            storage:
                typeof window !== 'undefined' ? window.localStorage : undefined,
            // Set to true for verbose logs
            debugMode: false,
        })

        reaction(
            () => settings.lite,
            (lite) => {
                if (lite) this.processor.video.none()
            }
        )

        this.previousEnableMic = false

        this.processor = {
            video: new VideoProcessor(this._adapter, this._logger),
        }

        if (typeof navigator !== 'undefined' && navigator.mediaDevices) {
            navigator.mediaDevices.addEventListener(
                'devicechange',
                debounce(() => this.handleDeviceChange(), 500, {
                    leading: false,
                    trailing: true,
                })
            )
        }
    }

    /** Bind {@link AudioVideoStoreAdapter} to handle device switching. */
    setAVAdapter(adapter: AudioVideoStoreAdapter) {
        this._adapter = adapter
        this.processor.video.bindAdapter(adapter)
    }

    setIsSetup(newValue: boolean) {
        this.isSetup = newValue
    }

    updateDefaultLayers(
        videoLayer: ParticipantSimulcastLayer,
        screenLayer: ParticipantSimulcastLayer
    ) {
        this.defaultVideoLayer = videoLayer
        this.defaultScreenLayer = screenLayer
    }

    updateState(state: AVState) {
        this._logger.info({
            action: 'Call@ConnectionState',
            stateValue: state,
            state: AVState[state],
        })
        this.state = state
    }

    updateShardId(shardId: string) {
        this.shardId = shardId
    }

    /**
     * The ID of the call the current participant is supposed to be in.
     *
     * Due to the async process of switching calls, the current participant may **actually be in a different
     * call or disconnected altogether** at the exact present.
     */
    get callId() {
        const { localUser } = this.users
        return localUser && this.shardId
            ? `${localUser.currentOrgId}-${this.shardId}`
            : null
    }

    get isConnecting() {
        return (
            this.state === AVState.Reconnecting ||
            this.state === AVState.Joining
        )
    }

    get isConnected() {
        return this.state === AVState.Connected
    }

    get isInBrokenState() {
        return (
            this.commons.isConnected &&
            this.commons.ready &&
            !this.settings.manuallyDisconnected &&
            (this.state === AVState.Broken ||
                this.state === AVState.Disconnected)
        )
    }

    async handleDeviceChange() {
        await this.requestDevices()
    }

    async requestDevices(updateSelectedDevices = true) {
        const devices = await navigator.mediaDevices.enumerateDevices()
        await this.updateDeviceList(devices, updateSelectedDevices)
    }

    async updateDeviceList(
        devices: MediaDeviceInfo[],
        updateSelectedDevices = true
    ) {
        this._logger.info({
            action: 'Call@ListDevices',
            devices: devices.map((d) => d.toJSON?.()),
            updateSelectedDevices,
        })

        const currentMics = [...this.mics]
        const currentCameras = [...this.cameras]
        const currentSpeakers = [...this.speakers]

        this.mics = selectDeviceList(devices, 'audioinput')
        this.cameras = selectDeviceList(devices, 'videoinput')
        this.speakers = selectDeviceList(devices, 'audiooutput')

        if (updateSelectedDevices) {
            await this.trackSystemDeviceChanges()
            await this.checkAdapterInputState()
        }

        let newDevices: MediaDeviceInfo[] = []

        // don't run on initial device loading on page load
        if (currentMics.length) {
            newDevices = newDevices.concat(
                getNewDevices(currentMics, this.mics, this.micId)
            )
        }

        // don't run on initial device loading on page load
        if (currentCameras.length) {
            newDevices = newDevices.concat(
                getNewDevices(currentCameras, this.cameras, this.cameraId)
            )
        }

        // don't run on initial device loading on page load
        if (currentSpeakers.length) {
            newDevices = newDevices.concat(
                getNewDevices(currentSpeakers, this.speakers, this.speakerId)
            )
        }

        runInAction(() => {
            this.newlyConnectedDevices = newDevices
        })
    }

    async trackSystemDeviceChanges() {
        const updatedMic = getUpdatedDevice({
            list: this.mics,
            currentId: this.micId,
            preferredId: this.preferredMicId,
            trackSystem: this.trackSystemMic,
        })
        if (updatedMic) {
            this._adapter.analytics?.track(AUDIO_INPUT_AUTO_UPDATED, {
                isPreferredDevice:
                    !this.trackSystemMic &&
                    this.preferredMicId === updatedMic.deviceId,
                isSystemDevice: this.trackSystemMic,
            })
            await this.setMic(updatedMic.deviceId, false)
        }

        const updatedCamera = getUpdatedDevice({
            list: this.cameras,
            currentId: this.cameraId,
            preferredId: this.preferredCameraId,
            trackSystem: this.trackSystemCamera,
        })
        if (updatedCamera) {
            this._adapter.analytics?.track(VIDEO_INPUT_AUTO_UPDATED, {
                isPreferredDevice:
                    !this.trackSystemCamera &&
                    this.preferredCameraId === updatedCamera.deviceId,
                isSystemDevice: this.trackSystemCamera,
            })
            await this.setCamera(updatedCamera.deviceId, false)
        }

        const updatedSpeaker = getUpdatedDevice({
            list: this.speakers,
            currentId: this.speakerId,
            preferredId: this.preferredSpeakerId,
            trackSystem: this.trackSystemSpeaker,
        })
        if (updatedSpeaker) {
            this._adapter.analytics?.track(AUDIO_OUTPUT_AUTO_UPDATED, {
                isPreferredDevice:
                    !this.trackSystemSpeaker &&
                    this.preferredSpeakerId === updatedSpeaker.deviceId,
                isSystemDevice: this.trackSystemSpeaker,
            })
            await this.setSpeaker(updatedSpeaker.deviceId, false)
        }
    }

    async checkAdapterInputState() {
        const inputs = await this._adapter.requestUpdatedInputState()

        if (inputs?.micId && inputs.micId !== this.micId) {
            this._logger.warn({
                action: 'Call@Audit.AdapterInputState',
                message: `micId out of sync store=${this.micId} adapter=${inputs.micId}`,
            })
            if (
                this.micId &&
                this.mics.find((d) => d.deviceId === this.micId)
            ) {
                void this.setMic(this.micId, false)
            } else {
                runInAction(() => {
                    this.micId = inputs.micId
                })
            }
        }
        if (inputs?.cameraId && inputs.cameraId !== this.cameraId) {
            this._logger.warn({
                action: 'Call@Audit.AdapterInputState',
                message: `cameraId out of sync store=${this.cameraId} adapter=${inputs.cameraId}`,
            })
            if (
                this.cameraId &&
                this.cameras.find((d) => d.deviceId === this.cameraId)
            ) {
                void this.setCamera(this.cameraId, false)
            } else {
                runInAction(() => {
                    this.cameraId = inputs.cameraId
                })
            }
        }
    }

    /**
     * Sets the active microphone device used in the call. {@link AudioVideoStore.micId} does not
     * reflect the change until the new microphone is switched to.
     */
    async setMic(deviceId: string, updatePreferred = true) {
        try {
            const previousPreferred = this.preferredMicId
            this.stopMicSelfHealing()
            await this._adapter.setMicDevice(deviceId)
            runInAction(() => {
                this.micId = deviceId
                if (updatePreferred) this.preferredMicId = deviceId
            })
            this._logger.info({
                action: 'Call@setMicrophone',
                request: deviceId,
                updatePreferred,
                trackSystemDefault: this.trackSystemMic,
                previousPreferred,
                device: this.mics.find((d) => d.deviceId === deviceId),
            })
        } catch (error) {
            this._logger.error({ action: 'Call@setMicrophone', error })
        }
        if (this.provider === AVProvider.Daily) {
            this.startMicSelfHealing()
        }
    }

    startMicSelfHealing() {
        let count = 0

        // Make sure to check the cancel flag after every await
        const run = async () => {
            if (this.selfHealMicTimeoutCancel) {
                return
            }

            count++

            const inputs = await this._adapter.requestUpdatedInputState()
            if (this.selfHealMicTimeoutCancel) {
                return
            }

            if (inputs?.micId && this.micId && inputs.micId !== this.micId) {
                this._logger.error({
                    action: 'Call@startMicSelfHealing',
                    message: `micId out of sync - current ${inputs.micId} should be ${this.micId}`,
                })
                await this._adapter.setMicDevice(this.micId)
            }
            if (count < MIC_SELF_HEALING_COUNT) {
                window.setTimeout(run, MIC_SELF_HEALING_INTERVAL)
            } else {
                this.stopMicSelfHealing()
            }
        }
        window.setTimeout(run, MIC_SELF_HEALING_INTERVAL)
    }

    stopMicSelfHealing() {
        this.selfHealMicTimeoutCancel = true
    }

    setTrackSystemMic(value: boolean) {
        this._logger.info({
            action: 'Call@setTrackSystemMic',
            value,
        })
        this.trackSystemMic = value
    }

    setTrackSystemSpeaker(value: boolean) {
        this._logger.info({
            action: 'Call@setTrackSystemSpeaker',
            value,
        })
        this.trackSystemSpeaker = value
    }

    setTrackSystemCamera(value: boolean) {
        this._logger.info({
            action: 'Call@setTrackSystemCamera',
            value,
        })
        this.trackSystemCamera = value
    }

    /**
     * Sets the active camera device used in the call. {@link AudioVideoStore.cameraId} does
     * not reflect the change until the new camera is switched to.
     */
    async setCamera(deviceId: string, updatePreferred = true) {
        try {
            const previousPreferred = this.preferredCameraId
            await this._adapter.setCameraDevice(deviceId)
            runInAction(() => {
                this.cameraId = deviceId
                if (updatePreferred) this.preferredCameraId = deviceId
            })
            this._logger.info({
                action: 'Call@setCamera',
                request: deviceId,
                updatePreferred,
                trackSystemDefault: this.trackSystemCamera,
                previousPreferred,
                device: this.cameras.find((d) => d.deviceId === deviceId),
            })
        } catch (error) {
            this._logger.error({ action: 'Call@setCamera', error })
        }
    }

    /**
     * Sets the active speaker all sound output is send to. {@link AudioVideoStore.speakerId} only
     * applies to browsers that support HTMLAudioElement.setSinkId
     */
    async setSpeaker(deviceId: string, updatePreferred = true) {
        try {
            this.speakerId = deviceId
            const previousPreferred = this.preferredSpeakerId
            if (updatePreferred) this.preferredSpeakerId = deviceId

            this._logger.info({
                action: 'Call@setSpeaker',
                request: deviceId,
                updatePreferred,
                trackSystemDefault: this.trackSystemCamera,
                previousPreferred,
                device: this.speakers.find((d) => d.deviceId === deviceId),
            })
        } catch (error) {
            this._logger.error({ action: 'Call@setSpeaker', error })
        }
    }

    /**
     * Toggle the microphone on or off. Works regardless if the call is joined.
     *
     * If the microphone is {@link AudioVideoStore.micUpdating already switching states}, toggling
     * it again will have no effect. The change will not reflect in {@link AudioVideoStore.micEnabled micEnabled} until the
     * microphone device is fully toggled.
     */
    async toggleMicEnabled(enable = !this.micEnabled) {
        runInAction(() => {
            this._enableMicWhenAvailable = false
        })

        if (this.micUpdating) return

        runInAction(() => {
            // optimistic -- assume setMicEnabled succeeds when setting values below
            this._enableMicWhenJoined = enable
            if (enable) this.allAudioEnabled = true
        })

        if (!this.isConnected) {
            this._logger.info({
                action: 'Call@toggleMicrophone',
                value: enable, // logger context will know we're not connected
            })
            return
        }
        const sequence = generateShortUuid()
        const slogger = this._logger.child({
            sequence: sequence,
        })

        slogger.info({
            action: 'Call@toggleMicrophone',
            value: enable,
        })

        runInAction(() => {
            this.micUpdating = true
        })

        try {
            await this._adapter.setMicEnabled(enable)

            if (this.micEnabled !== enable) {
                slogger.error({
                    action: 'Call@toggleMicrophone',
                    success: false,
                })
            } else {
                slogger.info({
                    action: 'Call@toggleMicrophone',
                    success: true,
                })
            }
        } catch (error) {
            slogger.error({
                action: 'Call@toggleMicrophone',
                error,
                success: false,
            })
        }

        runInAction(() => {
            this.micUpdating = false
        })
    }

    /**
     * Toggle the camera on or off. Works regardless if the call is joined.
     *
     * If the camera is {@link AudioVideoStore.cameraEnabled already switching states}, toggling it
     * again will have no effect. The change will not reflect in {@link AudioVideoStore.cameraEnabled cameraEnabled}
     * until the camera device is fully toggled.
     */
    async toggleCameraEnabled(
        enable = !this.cameraEnabled,
        { waitForOnline = false } = {}
    ) {
        runInAction(() => {
            this._enableCameraWhenAvailable = false
        })

        if (this.cameraUpdating) return

        runInAction(() => {
            // optimistic -- assume setCameraEnabled succeeds when setting values below
            this._enableCameraWhenJoined = enable
            if (enable) this.allVideoEnabled = true
        })

        if (!this.isConnected) {
            this._logger.info({
                action: 'Call@toggleCamera',
                value: enable,
            })
            return
        }
        const sequence = generateShortUuid()
        const slogger = this._logger.child({
            sequence: sequence,
        })

        slogger.info({
            action: 'Call@toggleCamera',
            value: enable,
        })

        runInAction(() => {
            this.cameraUpdating = true
        })

        if (enable && waitForOnline) {
            try {
                await this.waitForCameraOnline()
            } catch (error) {
                this.updateCameraError(AVDeviceError.NotFound)

                slogger.error({
                    action: 'Call@toggleCamera',
                    errorType: AVDeviceError.NotFound,
                    error,
                })

                runInAction(() => {
                    this.cameraUpdating = false
                })
                return
            }
        }

        try {
            await this._adapter.setCameraEnabled(enable)

            if (this.cameraEnabled !== enable) {
                slogger.error({
                    action: 'Call@toggleCamera',
                    success: false,
                })
            } else {
                slogger.info({
                    action: 'Call@toggleCamera',
                    success: true,
                })
            }
        } catch (error) {
            slogger.error({
                action: 'Call@toggleCamera',
                success: false,
                error,
            })
        }

        runInAction(() => {
            this.cameraUpdating = false
        })
    }

    /**
     * Toggles your mic off and makes all other participant audio off.
     */
    async toggleAllAudioEnabled(enable = !this.allAudioEnabled, setMic = true) {
        try {
            if (!enable) this.previousEnableMic = this.micEnabled
            if (setMic) {
                await this.toggleMicEnabled(
                    enable ? this.previousEnableMic : enable
                )
            }
            runInAction(() => {
                this.allAudioEnabled = enable
            })
            this._logger.info({
                action: 'Call@toggleAudio',
                value: this.allAudioEnabled,
            })
        } catch (error) {
            this._logger.error({
                action: 'Call@toggleAudio',
                error,
                success: false,
            })
        }
    }

    /**
     * Toggles your camera off and makes all other participant video feeds off.
     */
    async toggleAllVideoEnabled(enable = !this.allVideoEnabled, setCam = true) {
        try {
            if (setCam) await this.toggleCameraEnabled(enable)
            runInAction(() => {
                this.allVideoEnabled = enable
            })
            this._logger.info({
                action: 'Call@toggleVideo',
                value: this.allVideoEnabled,
            })
        } catch (error) {
            this._logger.error({
                action: 'Call@toggleVideo',
                error,
                success: false,
            })
        }
    }

    async waitForCameraOnline() {
        // wait for camera to show up in enumerateDevices
        await retry({
            action: async () => {
                if (!navigator.mediaDevices) {
                    throw new Error(
                        'AudioVideoStore (waitForCameraOnline): navigator.mediaDevices inaccessible in enableCameraWhenOnline'
                    )
                }
                const d = await navigator.mediaDevices.enumerateDevices()
                const online =
                    d.filter((d) => d.kind === 'videoinput').length > 0
                if (!online)
                    throw new Error(
                        'AudioVideoStore (waitForCameraOnline): no camera found'
                    )
            },
            times: 6,
            cooldown: 100,
            cooldownMultiplier: 2,
            onRetry: (count: number) => {
                this._logger.info({
                    action: 'Call@waitForCameraOnline',
                    retries: count,
                })
            },
        })
    }

    setPipEnabled(pipEnabled: boolean) {
        this.pipEnabled = pipEnabled
    }

    setSoftDisconnect(value: boolean) {
        this.softDisconnect = value
    }

    async handleAvailabilityBusy(busy: boolean) {
        this._logger.info({ action: '@handleAvailabilityBusy', busy })
        if (busy) {
            this._enableMicWhenAvailable = false
            this._enableCameraWhenAvailable = false

            if (this.micEnabled) {
                await this.toggleMicEnabled(false)
                this._enableMicWhenAvailable = true
            }
            if (this.cameraEnabled) {
                await this.toggleCameraEnabled(false)
                this._enableCameraWhenAvailable = true
            }
        } else {
            if (this._enableMicWhenAvailable) {
                await this.toggleMicEnabled(true)
            }
            if (this._enableCameraWhenAvailable) {
                await this.toggleCameraEnabled(true)
            }
        }
    }

    async setParticipantVideoSimulcastLayer(
        participantId: string,
        layer: ParticipantSimulcastLayer
    ) {
        await this._adapter.setParticipantVideoSimulcastLayer(
            participantId,
            layer
        )
    }

    async setParticipantScreenShareSimulcastLayer(
        participantId: string,
        layer: ParticipantSimulcastLayer
    ) {
        await this._adapter.setParticipantScreenShareSimulcastLayer(
            participantId,
            layer
        )
    }

    setScreenStreams(value: MediaStream[]) {
        this.screenStreams = value
    }

    setScreenShareStuckAtPreset(value: ScreenSharePreset | null) {
        this.screenShareStuckAtPreset = value
    }

    async startScreenShare({
        audioOnlyCapture = false,
    }: { audioOnlyCapture?: boolean } = {}) {
        let streams = this.screenStreams

        if (!streams.length) {
            this._logger.info(
                'AudioVideoStore.startScreenShare: Getting new screenshare stream'
            )

            streams = await this._adapter.getScreenShareStreams(
                this.settings.screenSharePreset,
                audioOnlyCapture
            )

            if (audioOnlyCapture) {
                let hasAudio = false
                for (const stream of streams) {
                    if (stream.getAudioTracks().length) {
                        hasAudio = true
                        break
                    }
                }

                if (!hasAudio) {
                    throw new Error('No audio detected')
                }
            }

            this.setScreenStreams(streams)
            this._logger.info({
                action: 'Call@StartScreenShare',
                message: streams.length
                    ? `AudioVideoStore.startScreenShare: Got screenshare streams with ids: ${streams.map(
                          (s) => s.id
                      )}`
                    : `AudioVideoStore.startScreenShare: User cancelled screenshare`,
            })
        } else {
            this._logger.info(
                `AudioVideoStore.startScreenShare: Reusing existing screenshare streams with ids: ${streams.map(
                    (s) => s.id
                )}`
            )
        }

        if (!streams.length) {
            this._logger.warn(
                'AudioVideoStore.startScreenShare: No screenshare stream'
            )
            throw new Error('No screenshare stream')
        }

        await this._adapter.startScreenShare(
            streams,
            this.settings.screenSharePreset
        )

        try {
            let totalWidth = 0
            let totalHeight = 0

            if (!audioOnlyCapture) {
                streams.forEach((stream) => {
                    const [video] = stream.getVideoTracks()
                    const { width, height } = video.getSettings()
                    if (
                        typeof width === 'number' &&
                        typeof height === 'number'
                    ) {
                        this._logger.info({
                            action: 'Call@startScreenshare',
                            message: `AudioVideoStore.startScreenShare: initial dimensions ${width}x${height}`,
                            width,
                            height,
                        })

                        totalWidth += width
                        totalHeight = Math.max(height, totalHeight)
                    }
                })
            } else {
                totalWidth = 190
                totalHeight = 160
            }

            return { width: totalWidth, height: totalHeight }
        } catch (error) {
            this._logger.warn(
                `AudioVideoStore.startScreenShare: could not get initial dimensions`,
                error
            )
        }
        return
    }

    /**
     * You should prefer using `packages/web/src/helpers/screenShare#stopScreenShare()`
     * over this method directly as it does some electron specific cleanup
     */
    async stopScreenShare() {
        await this._adapter.stopScreenShare()
        this.setScreenStreams([])
    }

    /** Updates which audio / video streams to subscribe to. */
    updateSubscriptions(participants: Participant[]) {
        if (!this.isConnected) return
        this._adapter.updateSubscriptions(participants)
    }

    async join() {
        this._logger.info('AudioVideoStore.join')

        // don't show AV setup modal once join has been attempted
        this.setIsSetup(true)

        this.updateState(AVState.Joining)
        try {
            await retry<void>({
                action: () => this._adapter.join(),
                times: 3,
                cooldown: 1500,
                cooldownMultiplier: 2,
                onRetry: (count: number) => {
                    this._logger.info(
                        `AudioVideoStore.join: retry number ${count}`
                    )
                },
            })
            this._logger.info(`AudioVideoStore.join: success`)
            this.updateState(AVState.Connected)

            await this.setMicCameraEnabledAfterJoined()
        } catch (error) {
            this._logger.error(
                `AudioVideoStore.join: error ${
                    error instanceof Error ? error.message : ''
                }`
            )
            this.updateState(AVState.Broken)
        }
    }

    async leave() {
        this.updateState(AVState.Leaving)
        await this._adapter.leave()
        this.updateState(AVState.Disconnected)
    }

    async reconnect(force = false) {
        this._logger.info(
            `AudioVideoStore.reconnect: force=${force} current state=${
                AVState[this.state]
            }`
        )
        const shouldNotReconnectStates = [
            AVState.Reconnecting,
            AVState.Connected,
        ]
        const shouldReconnect =
            force || !shouldNotReconnectStates.includes(this.state)

        if (!shouldReconnect) {
            return
        }

        this.state = AVState.Reconnecting
        await this.join()
    }

    async setMicCameraEnabledAfterJoined() {
        const enableMic = this._enableMicWhenJoined
        const enableCamera = this._enableCameraWhenJoined

        this._logger.info({
            action: 'Call@setMicCameraEnabledAfterJoined',
            to: {
                microphone: enableMic,
                camera: enableCamera,
            },
            settings: {
                rememberAVState: this.settings.rememberAVState,
            },
        })

        await Promise.all([
            this.toggleMicEnabled(enableMic),
            this.toggleCameraEnabled(enableCamera, { waitForOnline: true }),
        ])

        await this._adapter.checkDevicePermissions()
    }

    async getNetworkQuality() {
        if (!this.isConnected) return
        return this._adapter.getNetworkQuality()
    }

    async getBandwidthUsage() {
        if (!this.isConnected) return
        return this._adapter.getBandwidthUsage()
    }

    async getDebugData() {
        return this._adapter.getDebugData()
    }

    async getWebRTCStats(): Promise<AVWebRTCStats | undefined> {
        const peerConnections = this._adapter.getPeerConnections()
        if (!peerConnections) return

        const sendStats = await peerConnections.sendConn?.getStats()
        const recvStats = await peerConnections.recvConn?.getStats()

        let availableOutgoingBitrate: number | undefined
        let availableIncomingBitrate: number | undefined

        sendStats?.forEach((value) => {
            if (
                value.type === 'candidate-pair' &&
                // only active candidate pair has this property
                value.availableOutgoingBitrate != null
            ) {
                availableOutgoingBitrate = value.availableOutgoingBitrate
            }
        })

        recvStats?.forEach((value) => {
            if (
                value.type === 'candidate-pair' &&
                value.availableIncomingBitrate != null
            ) {
                // not supported (undefined) for Daily or LiveKit
                availableIncomingBitrate = value.availableIncomingBitrate
            }
        })

        return {
            availableOutgoingBitrate,
            availableIncomingBitrate,
        }
    }

    addProblem(problem: AVProblem) {
        const lastTimeAdded = this.problemHistory.get(problem.message) ?? 0
        const skip = Date.now() - lastTimeAdded < (problem.throttleMs ?? 0)
        if (!skip) {
            this.problemHistory.set(problem.message, Date.now())
            this.problems = [...this.problems, problem]
        }
    }

    clearProblems() {
        this.problems.length = 0
    }

    updateMicError(value: AVDeviceError | null) {
        this.micError = value
    }

    updateCameraError(value: AVDeviceError | null) {
        this.cameraError = value
    }

    updateProvider(provider: AVProvider) {
        this.provider = provider
    }

    /** Tracks whether local user's audio is enabled, regardless if the call is joined. */
    get micEnabled() {
        if (!this.isConnected) {
            return this._enableMicWhenJoined
        }
        return !!this.participants.localParticipant?.media?.audioIsOn
    }

    /** Tracks whether local user's audio is enabled, regardless if the call is joined. */
    get cameraEnabled() {
        if (!this.isConnected) {
            return this._enableCameraWhenJoined
        }
        return !!this.participants.localParticipant?.media?.videoIsOn
    }

    get willJoinVerseWithAVSetup() {
        return !this.isSetup && this.settings.alwaysDisplayAvSetup
    }

    get displayAVSetup() {
        return (
            this.willJoinVerseWithAVSetup &&
            (this.state === AVState.None ||
                this.state === AVState.Disconnected) &&
            this.commons.roomNavigationState === RoomNavigationState.Idle
        )
    }

    get reduceUploadBandwidth() {
        const isScreenSharing = !!this.screenStreams.length

        return (
            isScreenSharing ||
            this.settings.lite ||
            this.performance.lowNetworkQuality
        )
    }

    reset(options?: {
        isUserLogout?: boolean
        shouldClearPersistentStore?: boolean
    }) {
        const { isUserLogout = false, shouldClearPersistentStore = false } =
            options ?? {}

        if (shouldClearPersistentStore) {
            void clearPersistedStore(this)
        }

        this._adapter = new NullAudioVideoStoreAdapter()

        this.state = AVState.None

        this.mics = []
        this.cameras = []
        this.speakers = []

        this.micUpdating = false
        this.cameraUpdating = false

        this.allAudioEnabled = true
        this.allVideoEnabled = true

        this.pipEnabled = false

        this.softDisconnect = false

        this._enableMicWhenAvailable = false
        this._enableCameraWhenAvailable = false

        this.problems = []
        this.micError = null
        this.cameraError = null

        this.isSetup = false
        this.previousEnableMic = false

        this.screenStreams = []

        this.processor.video.reset(shouldClearPersistentStore)

        if (!isUserLogout) {
            this.micId = null
            this.cameraId = null
            this.speakerId = null

            this.preferredMicId = null
            this.preferredCameraId = null
            this.preferredSpeakerId = null

            this.trackSystemMic = true
            this.trackSystemCamera = true
            this.trackSystemSpeaker = true

            this._enableMicWhenJoined = true
            this._enableCameraWhenJoined = true
        }
    }
}
