import { AvailabilityStatus } from '@teamflow/types'

const audioVideo = {
    networkQualityIsLow: 'audioVideo.networkQualityIsLow',
    newDeviceConnected: 'audioVideo.newDeviceConnected',
    errorConnectingNewDevice: 'audioVideo.errorConnectingNewDevice',
    connect: 'audioVideo.connect',
    dismiss: 'audioVideo.dismiss',
    audioInput: 'audioVideo.audioInput',
    audioOutput: 'audioVideo.audioOutput',
    videoInput: 'audioVideo.videoInput',
    connectionNone: 'audioVideo.connectionNone',
    connectionLow: 'audioVideo.connectionLow',
    connectionHigh: 'audioVideo.connectionHigh',
    audioSharingOnlyOnChromeTab: 'audioVideo.audioSharingOnlyOnChromeTab',
    doNotShowThisAgain: 'audioVideo.doNotShowThisAgain',
    howToShareAudio: 'audioVideo.howToShareAudio',
}

const availabilityStatus: Record<AvailabilityStatus, string> = {
    [AvailabilityStatus.AVAILABLE]: 'availabilityStatus.available',
    [AvailabilityStatus.IDLE]: 'availabilityStatus.idle',
    [AvailabilityStatus.BUSY]: 'availabilityStatus.busy',
    [AvailabilityStatus.OFFLINE]: 'availabilityStatus.offline',
}

// src/frontend/components/common

const billingForm = {
    address: 'billingForm.address',
    city: 'billingForm.city',
    state: 'billingForm.state',
    zip: 'billingForm.zip',
}

const grantAccessModal = {
    giveAccess: 'grantAccessModal.giveAccess',
    giveUserAccess: 'grantAccessModal.giveUserAccess',
    goToThem: 'grantAccessModal.goToThem',
    userDoesNotHaveAccess: 'grantAccessModal.userDoesNotHaveAccess',
}

const huddleDivider = {
    or: 'huddleDivider.or',
}

const huddleDropdownMenu = {
    pleaseSelect: 'huddleDropdownMenu.pleaseSelect',
}

const huddleFormLabel = {
    optional: 'huddleFormLabel.optional',
}

const huddleHeader = {
    linkToHome: 'huddleHeader.linkToHome',
    dontHaveAnAccount: 'huddleHeader.dontHaveAnAccount',
    signUp: 'huddleHeader.signUp',
    alreadyOnATeam: 'huddleHeader.alreadyOnATeam',
    logIn: 'huddleHeader.logIn',
}

const inviteChecklist = {
    name: 'inviteChecklist.name',
}

const inviteModal = {
    guest: 'inviteModal.guest',
    member: 'inviteModal.member',
    mustKnockToEnter: 'inviteModal.mustKnockToEnter',
    teamMember: 'inviteModal.teamMember',
    invitesSent: 'inviteModal.invitesSent',
    inviteSent: 'inviteModal.inviteSent',
    giveUserAccess: 'inviteModal.giveUserAccess',
    areYouSureThisWillInvalidateAllOtherLinksThatHaveBeenSentOut:
        'inviteModal.areYouSureThisWillInvalidateAllOtherLinksThatHaveBeenSentOut',
    invitePeopleToTeamflow: 'inviteModal.invitePeopleToTeamflow',
    whoAreYouInviting: 'inviteModal.whoAreYouInviting',
    thisPersonJoinsYourOrganizationWithFullAccess:
        'inviteModal.thisPersonJoinsYourOrganizationWithFullAccess',
    guestsCanSeeOnlyWhatTheyAreInvitedToAndAreNotAPartOfYourOrganization:
        'inviteModal.guestsCanSeeOnlyWhatTheyAreInvitedToAndAreNotAPartOfYourOrganization',
    grantAccessToTheMainFloorAndAllUnlockedRooms:
        'inviteModal.grantAccessToTheMainFloorAndAllUnlockedRooms',
    grantAccessToASpecificRoomTheyCantAccessYourFloor:
        'inviteModal.grantAccessToASpecificRoomTheyCantAccessYourFloor',
    invitePeopleViaEmail: 'inviteModal.invitePeopleViaEmail',
    mainFloor: 'inviteModal.mainFloor',
    members: 'inviteModal.members',
    teamMembers: 'inviteModal.teamMembers',
    guests: 'inviteModal.guests',
    inviteToSpace: 'inviteModal.inviteToSpace',
    inviteToTeam: 'inviteModal.inviteToTeam',
    inviteGuests: 'inviteModal.inviteGuests',
    invite: 'inviteModal.invite',
    remove: 'inviteModal.remove',
    addAnotherEmail: 'inviteModal.addAnotherEmail',
    anyoneWithTheLinkCanAccess: 'inviteModal.anyoneWithTheLinkCanAccess',
    inviteViaLink: 'inviteModal.inviteViaLink',
    copied: 'inviteModal.copied',
    copy: 'inviteModal.copy',
    copyLink: 'inviteModal.copyLink',
    linkChanged: 'inviteModal.linkChanged',
    regenerateLink: 'inviteModal.regenerateLink',
    enterNameOrEmail: 'inviteModal.enterNameOrEmail',
    userDoesNotHaveAccess: 'inviteModal.userDoesNotHaveAccess',
    sharedWith: 'inviteModal.sharedWith',
    pendingInvites: 'inviteModal.pendingInvites',
    userUninvitedFromRoom: 'inviteModal.userUninvitedFromRoom',
    errorInvitingToRoom: 'inviteModal.errorInvitingToRoom',
    errorWhileRemovingUser: 'inviteModal.errorWhileRemovingUser',
    cannotCompleteRequest: 'inviteModal.cannotCompleteRequest',
    roomOwnerChanged: 'inviteModal.roomOwnerChanged',
    removeFromRoom: 'inviteModal.removeFromRoom',
    resetLink: 'inviteModal.resetLink',
    errorResettingLink: 'inviteModal.errorResettingLink',
    inviteUpdated: 'inviteModal.inviteUpdated',
    inviteOnly: 'inviteModal.inviteOnly',
    inviteFullAccess: 'inviteModal.inviteFullAccess',
    inviteFullAccessDescription: 'inviteModal.inviteFullAccessDescription',
    inviteLimitedAccess: 'inviteModal.inviteLimitedAccess',
    inviteLimitedAccessDescription:
        'inviteModal.inviteLimitedAccessDescription',
}

const screenLoader = {
    loading1: 'screenLoader.loading1',
    loading2: 'screenLoader.loading2',
    loading3: 'screenLoader.loading3',
    loading4: 'screenLoader.loading4',
    loading5: 'screenLoader.loading5',
    loading6: 'screenLoader.loading6',
    loading7: 'screenLoader.loading7',
    loading8: 'screenLoader.loading8',
    loading9: 'screenLoader.loading9',
}

const teachingMomentDescriptions = {
    busyMode: 'teachingMomentDescriptions.busyMode',
    fullscreenMode: 'teachingMomentDescriptions.fullscreenMode',
    quickSearch: 'teachingMomentDescriptions.quickSearch',
    goHome: 'teachingMomentDescriptions.goHome',
    tapOnShoulder: 'teachingMomentDescriptions.tapOnShoulder',
    slackIntegration: 'teachingMomentDescriptions.slackIntegration',
    listenMode: 'teachingMomentDescriptions.listenMode',
}

const teachingMomentTitles = {
    proTip: 'teachingMomentTitles.proTip',
    busyMode: 'teachingMomentTitles.busyMode',
    fullscreenMode: 'teachingMomentTitles.fullscreenMode',
    quickSearch: 'teachingMomentTitles.quickSearch',
    goHome: 'teachingMomentTitles.goHome',
    tapOnShoulder: 'teachingMomentTitles.tapOnShoulder',
    slackIntegration: 'teachingMomentTitles.slackIntegration',
    listenMode: 'teachingMomentTitles.listenMode',
}

const slackInviteModal = {
    failedToLoadTeamFromSlackPleaseTryAgain:
        'slackInviteModal.failedToLoadTeamFromSlackPleaseTryAgain',
    invitesSent: 'slackInviteModal.invitesSent',
    failedToSendInvitesPleaseTryAgain:
        'slackInviteModal.failedToSendInvitesPleaseTryAgain',
    inviteTeamViaSlack: 'slackInviteModal.inviteTeamViaSlack',
    cancel: 'slackInviteModal.cancel',
    inviteAmountToYourTeam: 'slackInviteModal.inviteAmountToYourTeam',
    inviteTeam: 'slackInviteModal.inviteTeam',
}

const teleportModal = {
    firstNameIsOffline: 'teleportModal.firstNameIsOffline',
    inRoomName: 'teleportModal.inRoomName',
    onMainFloor: 'teleportModal.onMainFloor',
    goToUserName: 'teleportModal.goToUserName',
    userNameIsInAPrivateRoom: 'teleportModal.userNameIsInAPrivateRoom',
    userNameIsRoomMessageWhichIsCurrentlyLockedWouldYouLikeToJoinThem:
        'teleportModal.userNameIsRoomMessageWhichIsCurrentlyLockedWouldYouLikeToJoinThem',
    userNameIsRoomMessageWouldYouLikeToGoThere:
        'teleportModal.userNameIsRoomMessageWouldYouLikeToGoThere',
    cancel: 'teleportModal.cancel',
    knock: 'teleportModal.knock',
    yes: 'teleportModal.yes',
    chat: 'teleportModal.chat',
    inviteOver: 'teleportModal.inviteOver',
}

// src/frontend/components/topHeader

const topHeader = {
    daysLeftBeforeFreePlan: 'topHeader.daysLeftBeforeFreePlan',
    afterYourTrialYoullBeMovedToOurFreePlanWith1MinCooldownsEvery45Min:
        'topHeader.afterYourTrialYoullBeMovedToOurFreePlanWith1MinCooldownsEvery45Min',
    upgrade: 'topHeader.upgrade',
    goToFirstName: 'topHeader.goToFirstName',
    gridMode: 'topHeader.gridMode',
    meet: 'topHeader.meet',
    googleCalendar: 'topHeader.googleCalendar',
    outlookCalendar: 'topHeader.outlookCalendar',
    office365Calendar: 'topHeader.office365Calendar',
    iCal: 'topHeader.iCal',
    sorryAnotherRoomWithTheNameNewNameAlreadyExists:
        'topHeader.sorryAnotherRoomWithTheNameNewNameAlreadyExists',
    spatialAudioIsNowOn: 'topHeader.spatialAudioIsNowOn',
    spatialAudioWasTurnedOnBy: 'topHeader.spatialAudioWasTurnedOnBy',
    onlyHearThoseAroundYou: 'topHeader.onlyHearThoseAroundYou',
    spatialAudioIsNowOff: 'topHeader.spatialAudioIsNowOff',
    spatialAudioWasTurnedOffBy: 'topHeader.spatialAudioWasTurnedOffBy',
    hearEveryoneInTheSpaceEqually: 'topHeader.hearEveryoneInTheSpaceEqually',
    failedToToggleStartInFullScreenModePleaseTryAgain:
        'topHeader.failedToToggleStartInFullScreenModePleaseTryAgain',
    failedToToggleSpatialAudioPleaseTryAgain:
        'topHeader.failedToToggleSpatialAudioPleaseTryAgain',
    customizeSpace: 'topHeader.customizeSpace',
    rename: 'topHeader.rename',
    startInFullScreenMode: 'topHeader.startInFullScreenMode',
    spatialAudio: 'topHeader.spatialAudio',
    shareThisLinkSoPeopleCanJoinYou:
        'topHeader.shareThisLinkSoPeopleCanJoinYou',
    linkCopied: 'topHeader.linkCopied',
    copyInviteLink: 'topHeader.copyInviteLink',
    unlockRoom: 'topHeader.unlockRoom',
    lockRoom: 'topHeader.lockRoom',
    mainFloor: 'topHeader.mainFloor',
    customize: 'topHeader.customize',
    invite: 'topHeader.invite',
    share: 'topHeader.share',
    clickRename: 'topHeader.clickRename',
    recording: 'topHeader.recording',
    recordingNotificationOnePerson: 'topHeader.recordingNotificationOnePerson',
    recordingNotificationMoreThanOnePerson:
        'topHeader.recordingNotificationMoreThanOnePerson',
    recordingMeetingNotificationOnePerson:
        'topHeader.recordingMeetingNotificationOnePerson',
    recordingMeetingNotificationMoreThanOnePerson:
        'topHeader.recordingMeetingNotificationMoreThanOnePerson',
    recordingLeave: 'topHeader.recordingLeave',
    invitePeopleToEvent: 'topHeader.invitePeopleToEvent',
    connectToSpace: 'topHeader.connectToSpace',
    downloadAppBanner: 'topHeader.downloadAppBanner',
    download: 'topHeader.download',
}

// src/frontent/components/apps

const apps = {
    viewScreen: 'apps.viewScreen',
    tuneInScreen: 'apps.tuneInScreen',
    theLinkedWebSite: 'apps.theLinkedWebSite',
    yourBrowser: 'apps.yourBrowser',
    noDice: 'apps.noDice',
    sourceIsNotLettingUsLoadThemInBrowserNameYouCanUseTheTeamflowDesktopAppToViewThisApp:
        'apps.sourceIsNotLettingUsLoadThemInBrowserNameYouCanUseTheTeamflowDesktopAppToViewThisApp',
    downloadTheApp: 'apps.downloadTheApp',
    openThisAppInANewTabInstead: 'apps.openThisAppInANewTabInstead',
    authenticationRequired: 'apps.authenticationRequired',
    serviceRequiresAuthentication: 'apps.serviceRequiresAuthentication',
    loginWithService: 'apps.loginWithService',
    reloadApp: 'apps.reloadApp',
    youArePresentingToEveryone: 'apps.youArePresentingToEveryone',
    stopPresenting: 'apps.stopPresenting',
    youPausedScreen: 'apps.youPausedScreen',
    youPausedSharedAudio: 'apps.youPausedSharedAudio',
    audioPausedToSaveBandwidth: 'apps.audioPausedToSaveBandwidth',
    screenPausedToSaveBandwidth: 'apps.screenPausedToSaveBandwidth',
    screenShareLowBandwidth: 'apps.screenShareLowBandwidth',
    sorryCouldNotCompleteTheSearchPleaseTryAgainLaterOrCopyAUrlDirectlyFromYouTube:
        'apps.sorryCouldNotCompleteTheSearchPleaseTryAgainLaterOrCopyAUrlDirectlyFromYouTube',
    youAreSearchingForQuery: 'apps.youAreSearchingForQuery',
    hideQrCode: 'apps.hideQrCode',
    openOnAnotherDevice: 'apps.openOnAnotherDevice',
    copyLink: 'apps.copyLink',
    openInNewWindow: 'apps.openInNewWindow',
    rename: 'apps.rename',
    unlock: 'apps.unlock',
    lock: 'apps.lock',
    useSharedBrowser: 'apps.useSharedBrowser',
    useEmbed: 'apps.useEmbed',
    changeQuality: 'apps.changeQuality',
    notOpeningEmbedsWorkBetterInOurApp:
        'apps.notOpeningEmbedsWorkBetterInOurApp',
    downloadDesktopApp: 'apps.downloadDesktopApp',
    closeAppName: 'apps.closeAppName',
    reload: 'apps.reload',
    areYouSureThisWillCloseThisAppForEveryone:
        'apps.areYouSureThisWillCloseThisAppForEveryone',
    scanTheQrCodeToOpenTheAppNameOnAnotherDevice:
        'apps.scanTheQrCodeToOpenTheAppNameOnAnotherDevice',
    closeFullScreenMode: 'apps.closeFullScreenMode',
    openFullScreenMode: 'apps.openFullScreenMode',
    enterFullScreenModeToMakeYourAppBiggerSeeAnyoneNearYouInPictureInPictureMode:
        'apps.enterFullScreenModeToMakeYourAppBiggerSeeAnyoneNearYouInPictureInPictureMode',
    okGotIt: 'apps.okGotIt',
    titleNameSLabel: 'apps.titleNameSLabel',
    yourLabel: 'apps.yourLabel',
    theirLabel: 'apps.theirLabel',
    copiedLinkToAppTitle: 'apps.copiedLinkToAppTitle',
    presentAppJoin: 'apps.presentAppJoin',
    presentationJoin: 'apps.presentationJoin',
    presentationEndPresentation: 'apps.presentationEndPresentation',
    presentationYouArePresenting: 'apps.presentationYouArePresenting',
    presentThisApp: 'apps.presentThisApp',
    pinApp: 'apps.pinApp',
    unpinApp: 'apps.unpinApp',
    showMultiplayerCursors: 'apps.showMultiplayerCursors',
    hideMultiplayerCursors: 'apps.hideMultiplayerCursors',
    tapToOpen: 'apps.tapToOpen',
    screenshareLoadingError: 'apps.screenshareLoadingError',
    youTubeVideoThumbnail: 'apps.youTubeVideoThumbnail',
    untitledYouTubeVideo: 'app.untitledYouTubeVideo',
    noPermission: 'apps.noPermission',
    learnMore: 'apps.learnMore',
    sleepingAppExplanation: 'apps.sleepingAppExplanation',
}

const tutorial = {
    screensharePointers: 'tutorial.screensharePointers',
    privateRooms: 'tutorial.privateRooms',
    layoutEditor: 'tutorial.layoutEditor',
    faceCentering: 'tutorial.faceCentering',
    sharedBrowser: 'tutorial.sharedBrowser',
}

// src/frontend/components/dock

const dock = {
    embedLink: 'dock.embedLink',
    sharedBrowser: 'dock.sharedBrowser',
    createAnAppFromALinkOrAnIframeOLD: 'dock.createAnAppFromALinkOrAnIframeOLD',
    pasteYourLinkOrIframeOLD: 'dock.pasteYourLinkOrIframeOLD',
    createOLD: 'dock.createOLD',
    createAnAppFromALinkOrAnIframe: 'dock.createAnAppFromALinkOrAnIframe',
    pasteYourLinkOrIframe: 'dock.pasteYourLinkOrIframe',
    create: 'dock.create',
    theEmbedAppIsInBetaCertainWebsitesMayNotFunctionAsIntended:
        'dock.theEmbedAppIsInBetaCertainWebsitesMayNotFunctionAsIntended',
    downloadDesktopAppForBestExperience:
        'dock.downloadDesktopAppForBestExperience',
    domainIsNotLettingUsIframeThemYetButWereWorkingOnIt:
        'dock.domainIsNotLettingUsIframeThemYetButWereWorkingOnIt',
    pleaseDoubleCheckYourUrl: 'dock.pleaseDoubleCheckYourUrl',
    turnOffMic: 'dock.turnOffMic',
    turnOnMic: 'dock.turnOnMic',
    disableAllAudio: 'dock.disableAllAudio',
    enableAllAudio: 'dock.enableAllAudio',
    audioBlocked: 'dock.audioBlocked',
    audio: 'dock.audio',
    mic: 'dock.mic',
    speakers: 'dock.speakers',
    leaveFocusMode: 'dock.leaveFocusMode',
    enterFocusMode: 'dock.enterFocusMode',
    lowerSoundTo10AndMuteYourself: 'dock.lowerSoundTo10AndMuteYourself',
    muteTeamflow: 'dock.muteTeamflow',
    joinAudio: 'dock.joinAudio',
    youWontBeAbleToHearAnyoneOnTeamflow:
        'dock.youWontBeAbleToHearAnyoneOnTeamflow',
    sameAsSystem: 'dock.sameAsSystem',
    pictureInPictureMode: 'dock.pictureInPictureMode',
    turnOffMyVideo: 'dock.turnOffMyVideo',
    turnOnMyVideo: 'dock.turnOnMyVideo',
    turnOffAllVideo: 'dock.turnOffAllVideo',
    turnOnAllVideo: 'dock.turnOnAllVideo',
    turnOffCamera: 'dock.turnOffCamera',
    turnOnCamera: 'dock.turnOnCamera',
    camera: 'dock.camera',
    whiteboard: 'dock.whiteboard',
    document: 'dock.document',
    countdown: 'dock.countdown',
    youHaveMaxAppsOutOfMaxAppsAppsOpenCloseAppsOrUpgradeYourPlan:
        'dock.youHaveMaxAppsOutOfMaxAppsAppsOpenCloseAppsOrUpgradeYourPlan',
    areYouSureYouWantToLeave: 'dock.areYouSureYouWantToLeave',
    hangUp: 'dock.hangUp',
    chat: 'dock.chat',
    reactShortcut: 'dock.reactShortcut',
    stickyReaction: 'dock.stickyReaction',
    fullScreenMode: 'dock.fullScreenMode',
    leaveRoom: 'dock.leaveRoom',
    goHome: 'dock.goHome',
    youtube: 'dock.youtube',
    spotify: 'dock.spotify',
    poker: 'dock.poker',
    codenames: 'dock.codenames',
    tetris: 'dock.tetris',
    drawBattle: 'dock.drawBattle',
    giphy: 'dock.giphy',
    dummy: 'dock.dummy',
    moreApps: 'dock.moreApps',
    default: 'dock.default',
    watch: 'dock.watch',
    productivity: 'dock.productivity',
    poll: 'dock.poll',
    googledocs: 'dock.googledocs',
    googlesheets: 'dock.googlesheets',
    googleslides: 'dock.googleslides',
    gong: 'dock.gong',
    trello: 'dock.trello',
    leaderboard: 'dock.leaderboard',
    games: 'dock.games',
    shareScreen: 'dock.shareScreen',
    stopScreenShare: 'dock.stopScreenShare',
    toShareYourScreen: 'dock.toShareYourScreen',
    youCanAlsoDragAndDropImagesDirectlyOntoTheFloorToShareThem:
        'dock.youCanAlsoDragAndDropImagesDirectlyOntoTheFloorToShareThem',
    image: 'dock.image',
    errorUploadingImage: 'dock.errorUploadImage',
    deactivateSpatialRibbon: 'dock.deactivateSpatialRibbon',
    activateSpatialRibbon: 'dock.activateSpatialRibbon',
    disableAutomaticFaceCentering: 'dock.disableAutomaticFaceCentering',
    enableAutomaticFaceCentering: 'dock.enableAutomaticFaceCentering',
    blurMyBackground: 'dock.blurMyBackground',
    recordMeeting: 'dock.recordMeeting',
    stopRecording: 'dock.stopRecording',
    notificationMeetingRecordingDismiss:
        'dock.notificationMeetingRecordingDismiss',
    notificationMeetingRecordingGoTo: 'dock.notificationMeetingRecordingGoTo',
    notificationMeetingRecordingDescription:
        'dock.notificationMeetingRecordingDescription',
    notificationMeetingRecordingTitle: 'dock.notificationMeetingRecordingTitle',
    notificationMeetingRecordingErrorTitle:
        'dock.notificationMeetingRecordingErrorTitle',
    notificationMeetingRecordingErrorDescription:
        'dock.notificationMeetingRecordingErrorDescription',
    cancel: 'dock.cancel',
    installExtension: 'dock.installExtension',
    recordAMeeting: 'dock.recordAMeeting',
    modalDescription: 'dock.modalDescription',
    learnMore: 'dock.learnMore',
    notificationExtensionOutOfDateTitle:
        'dock.notificationExtensionOutOfDateTitle',
    notificationExtensionOutOfDateDescription:
        'dock.notificationExtensionOutOfDateDescription',
    notificationExtensionOutOfDateOption:
        'dock.notificationExtensionOutOfDateOption',
    notificationMissingAudioTitle: 'dock.notificationMissingAudioTitle',
    notificationMissingAudioDescription:
        'dock.notificationMissingAudioDescription',
    micMutedTooltip: 'dock.micMutedTooltip',
    browserNotSupported: 'dock.browserNotSupported',
    browserNotSupportedMac: 'dock.browserNotSupportedMac',
    browserNotSupportedDescription: 'dock.browserNotSupportedDescription',
    notSupportedHelp: 'dock.notSupportedHelp',
    giveAccessToSeeDevices: 'dock.giveAccessToSeeDevices',
    turnOnToAllowAccess: 'dock.turnOnToAllowAccess',
    disconnect: 'dock.disconnect',
    cantShareScreenInEmbed: 'dock.cantShareScreenInEmbed',
    downloadApp: 'dock.downloadApp',
    shareScreenOrWindow: 'dock.shareScreenOrWindow',
    shareAudioOnly: 'dock.shareAudioOnly',
}

// src/components/settings/ScreenSharePresetSection
// src/components/settings/ScreenSharePresetModal

const screenShare = {
    screenShareQuality: 'screenShare.screenShareQuality',
    currently: 'screenShare.currently',
    restartToUpdate: 'screenShare.restartToUpdate',
    cancel: 'screenShare.cancel',
    restartNow: 'screenShare.restartNow',
    restartScreenShare: 'screenShare.restartScreenShare',
    startScreenShare: 'screenShare.startScreenShare',
    textOptimized: 'screenShare.textOptimized',
    motionOptimized: 'screenShare.motionOptimized',
    presetText: 'screenShare.presetText',
    preset480p15fps: 'screenShare.preset480p15fps',
    preset720p15fps: 'screenShare.preset720p15fps',
    preset720p30fps: 'screenShare.preset720p30fps',
    preset1080p30fps: 'screenShare.preset1080p30fps',
    preset1080p60fps: 'screenShare.preset1080p60fps',
}

// src/frontend/components/electron

const electron = {
    entireScreen: 'electron.entireScreen',
    applicationWindows: 'electron.applicationWindows',
    close: 'electron.close',
    select: 'electron.select',
    downloadFileError: 'electron.downloadFileError',
    screenShareError: 'electron.screenShareError',
    multipleScreenShareSelection: 'electron.multipleScreenShareSelection',
}

// src/frontend/components/fullscreen

const fullscreen = {
    closeLabel: 'fullscreen.closeLabel',
    youAreCurrentlyViewingThisApp: 'fullscreen.youAreCurrentlyViewingThisApp',
    copiedLinkToAppTitle: 'fullscreen.copiedLinkToAppTitle',
    thereIsNoAppSelected: 'fullscreen.thereIsNoAppSelected',
    exitFullScreen: 'fullscreen.exitFullScreen',
    openPresenterView: 'fullscreen.openPresenterView',
    openGridMode: 'fullscreen.openGridMode',
    people: 'fullscreen.people',
    apps: 'fullscreen.apps',
    recording: 'fullscreen.recording',
    sendMessageHeader: 'fullscreen.sendMessageHeader',
    sendMessagePlaceholder: 'fullscreen.sendMessagePlaceholder',
    sendMessageError: 'fullscreen.sendMessageError',
    cancel: 'fullscreen.cancel',
    sendMessage: 'fullscreen.sendMessage',
    listeningTo: 'fullscreen.listeningTo',
    sentMessageTo: 'fullscreen.sentMessageTo',
    listeningToParticipant: 'fullscreen.listeningToParticipant',
    listeningToYou: 'fullscreen.listeningToYou',
    clickToListenToParticipant: 'fullscreen.clickToListenToParticipant',
}

// src/frontend/components/participants

const participants = {
    nextTimeUseShortcutToChangeYourAvailability:
        'participants.nextTimeUseShortcutToChangeYourAvailability',
    toggleAvailability: 'participants.toggleAvailability',
    chatWithFirstName: 'participants.chatWithFirstName',
    clearCustomStatus: 'participants.clearCustomStatus',
    setACustomStatus: 'participants.setACustomStatus',
    changeCustomStatus: 'participants.changeCustomStatus',
    areYouSureThisWillRemoveFirstNameFromYourOrganization:
        'participants.areYouSureThisWillRemoveFirstNameFromYourOrganization',
    areYouSureThisWillDeleteTheChat:
        'participants.areYouSureThisWillDeleteTheChat',
    chatHasBeenDeleted: 'participants.chatHasBeenDeleted',
    firstNameHasBeenRemoved: 'participants.firstNameHasBeenRemoved',
    firstNameCouldNotBeRemovedErrorMessage:
        'participants.firstNameCouldNotBeRemovedErrorMessage',
    removeFromSpace: 'participants.removeFromSpace',
    deleteChat: 'participants.deleteChat',
    getFirstName: 'participants.getFirstName',
    goToFirstName: 'participants.goToFirstName',
    tapOnFirstNameShoulder: 'participants.tapOnFirstNameShoulder',
    settings: 'participants.settings',
    muteNotifications: 'participants.muteNotifications',
    enableNotifications: 'participants.enableNotifications',
    muteFirstName: 'participants.muteFirstName',
    guest: 'participants.guest',
    firstNameWillBeAddedToYourTeamWhenTheyAccept:
        'participants.firstNameWillBeAddedToYourTeamWhenTheyAccept',
    promoteToTeamMember: 'participants.promoteToTeamMember',
    fullAccess: 'participants.fullAccess',
    fullAccessDescription: 'participants.fullAccessDescription',
    fullAccessGranted: 'participants.fullAccessGranted',
    viewOnly: 'participants.viewOnly',
    viewOnlyDescription: 'participants.viewOnlyDescription',
    viewOnlyAccessGranted: 'participants.viewOnlyAccessGranted',
    invite: 'participants.invite',
    inviteOnly: 'participants.inviteOnly',
    quality: 'participants.quality',
    receivePacketLoss: 'participants.receivePacketLoss',
    sendPacketLoss: 'participants.sendPacketLoss',
    whisperModeTooltip: 'participants.whisperModeTooltip',
    user: 'participants.user',
}

// src/frontend/components/search

const search = {
    noResultsFound: 'search.noResultsFound',
    tryADifferentSearchTerm: 'search.tryADifferentSearchTerm',
    clearSearch: 'search.clearSearch',
    mainFloor: 'search.mainFloor',
    searchPlaceholder: 'search.searchPlaceholder',
    groupTitles: {
        audioZone: 'search.groupTitles.audioZones',
        quickActions: 'search.groupTitles.quickActions',
        games: 'search.groupTitles.games',
        team: 'search.groupTitles.team',
        rooms: 'search.groupTitles.rooms',
        helpSupport: 'search.groupTitles.helpSupport',
        office: 'search.groupTitles.office',
        customize: 'search.groupTitles.customize',
        settings: 'search.groupTitles.settings',
        account: 'search.groupTitles.account',
        watch: 'search.groupTitles.watch',
        productivity: 'search.groupTitles.productivity',
    },
    titles: {
        showAllAudioZones: 'search.titles.showAllAudioZones',
        showAllTeam: 'search.titles.showAllTeam',
        mainFloor: 'search.titles.mainFloor',
        showAllRooms: 'search.titles.showAllRooms',
        appScreenshare: 'search.titles.appScreenshare',
        appWhiteboard: 'search.titles.appWhiteboard',
        appScratchpad: 'search.titles.appScratchpad',
        appImage: 'search.titles.appImage',
        appCountdown: 'search.titles.appCountdown',
        appEmbed: 'search.titles.appEmbed',
        meeting: 'search.titles.meeting',
        moreApps: 'search.titles.moreApps',
        setCustomStatus: 'search.titles.setCustomStatus',
        toggleCamera: 'search.titles.toggleCamera',
        toggleMicrophone: 'search.titles.toggleMicrophone',
        toggleAudio: 'search.titles.toggleAudio',
        appCodenames: 'search.titles.appCodenames',
        appPoker: 'search.titles.appPoker',
        appTetris: 'search.titles.appTetris',
        appDrawBattle: 'search.titles.appDrawBattle',
        inviteTeammate: 'search.titles.inviteTeammate',
        inviteGuest: 'search.titles.inviteGuest',
        manageIntegrations: 'search.titles.manageIntegrations',
        lockRoom: 'search.titles.lockRoom',
        unlockRoom: 'search.titles.unlockRoom',
        placeFurniture: 'search.titles.placeFurniture',
        newAudioZone: 'search.titles.newAudioZone',
        newRoom: 'search.titles.newRoom',
        uploadCustomFurniture: 'search.titles.uploadCustomFurniture',
        changeBackground: 'search.titles.changeBackground',
        editProfile: 'search.titles.editProfile',
        changePassword: 'search.titles.changePassword',
        configureNotifications: 'search.titles.configureNotifications',
        contactSupport: 'search.titles.contactSupport',
        sendFeedback: 'search.titles.sendFeedback',
        downloadApp: 'search.titles.downloadApp',
        signOut: 'search.titles.signOut',
        goToRoom: 'search.titles.goToRoom',
        copyInviteLink: 'search.titles.copyInviteLink',
        chatRoom: 'search.titles.chatRoom',
        goToUser: 'search.titles.goToUser',
        getUser: 'search.titles.getUser',
        tapOnUser: 'search.titles.tapOnUser',
        chatUser: 'search.titles.chatUser',
        appYoutube: 'search.titles.appYoutube',
        appGoogleDocs: 'search.titles.appGoogleDocs',
        appGoogleSlides: 'search.titles.appGoogleSlides',
        appGoogleSheets: 'search.titles.appGoogleSheets',
        appPoll: 'search.titles.appPoll',
        appLeaderboard: 'search.titles.appLeaderboard',
        appGiphy: 'search.titles.appGiphy',
    },
    terms: {
        audioZone: 'search.terms.audioZone',
        room: 'search.terms.room',
        mainFloor: 'search.terms.mainFloor',
        appScreenshare: 'search.terms.appScreenshare',
        appWhiteboard: 'search.terms.appWhiteboard',
        appScratchpad: 'search.terms.appScratchpad',
        appImage: 'search.terms.appImage',
        appCountdown: 'search.terms.appCountdown',
        appEmbed: 'search.terms.appEmbed',
        meeting: 'search.terms.meeting',
        setCustomStatus: 'search.terms.setCustomStatus',
        toggleCamera: 'search.terms.toggleCamera',
        toggleMicrophone: 'search.terms.toggleMicrophone',
        toggleAudio: 'search.terms.toggleAudio',
        appCodenames: 'search.terms.appCodenames',
        appPoker: 'search.terms.appPoker',
        appTetris: 'search.terms.appTetris',
        appDrawBattle: 'search.terms.appDrawBattle',
        inviteTeammate: 'search.terms.inviteTeammate',
        inviteGuest: 'search.terms.inviteGuest',
        manageIntegrations: 'search.terms.manageIntegrations',
        lockRoom: 'search.terms.lockRoom',
        unlockRoom: 'search.terms.unlockRoom',
        placeFurniture: 'search.terms.placeFurniture',
        newAudioZone: 'search.terms.newAudioZone',
        newRoom: 'search.terms.newRoom',
        uploadCustomFurniture: 'search.terms.uploadCustomFurniture',
        changeBackground: 'search.terms.changeBackground',
        editProfile: 'search.terms.editProfile',
        changePassword: 'search.terms.changePassword',
        configureNotifications: 'search.terms.configureNotifications',
        contactSupport: 'search.terms.contactSupport',
        sendFeedback: 'search.terms.sendFeedback',
        downloadApp: 'search.terms.downloadApp',
        signOut: 'search.terms.signOut',
        appYoutube: 'search.terms.appYoutube',
        appGoogleDocs: 'search.terms.appGoogleDocs',
        appGoogleSlides: 'search.terms.appGoogleSlides',
        appGoogleSheets: 'search.terms.appGoogleSheets',
        appPoll: 'search.terms.appPoll',
        appLeaderboard: 'search.terms.appLeaderboard',
        appGiphy: 'search.terms.appGiphy',
    },
}

// src/frontend/components/settings

const settings = {
    cancel: 'settings.cancel',
    save: 'settings.save',
    editYourProfile: 'settings.editYourProfile',
    changeYourPassword: 'settings.changeYourPassword',
    notifications: 'settings.notifications',
    userInterface: 'settings.userInterface',
    language: 'settings.language',
    connectedApps: 'settings.connectedApps',
    audioAndVideoSettings: 'settings.audioAndVideoSettings',
    account: 'settings.account',
    profile: 'settings.profile',
    password: 'settings.password',
    audioVideo: 'settings.audioVideo',
    workspace: 'settings.workspace',
    auto: 'settings.auto',
    english: 'settings.english',
    french: 'settings.french',
    korean: 'settings.korean',
    japanese: 'settings.japanese',
    neverTurnItOff: 'settings.neverTurnItOff',
    after15Minutes: 'settings.after15Minutes',
    after30Minutes: 'settings.after30Minutes',
    after1Hour: 'settings.after1Hour',
    microphone: 'settings.microphone',
    speaker: 'settings.speaker',
    testYourMicInput: 'settings.testYourMicInput',
    camera: 'settings.camera',
    blurBackground: 'settings.blurBackground',
    blursTheBackgroundOfYourVideo: 'settings.blursTheBackgroundOfYourVideo',
    turnOffMyCameraAndMicWhenImIdle: 'settings.turnOffMyCameraAndMicWhenImIdle',
    inputMode: 'settings.inputMode',
    voiceActivity: 'settings.voiceActivity',
    pushToTalk: 'settings.pushToTalk',
    faceCentering: 'settings.faceCentering',
    autoCenterFaceInVideo: 'settings.autoCenterFaceInVideo',
    faceZoom: 'settings.faceZoom',
    faceOffsetX: 'settings.faceOffsetX',
    faceOffsetY: 'settings.faceOffsetY',
    faceReset: 'settings.faceReset',
    flipYourCamera: 'settings.flipYourCamera',
    flipYourCameraDescription: 'settings.flipYourCameraDescription',
    rememberAVState: 'settings.rememberAVState',
    rememberAVStateDescription: 'settings.rememberAVStateDescription',
    needMoreHelpWithYourSetup: 'settings.needMoreHelpWithYourSetup',
    readTheGuide: 'settings.readTheGuide',
    movementSpeed: 'settings.movementSpeed',
    liteMode: 'settings.liteMode',
    useBasicFeaturesOnly: 'settings.useBasicFeaturesOnly',
    limitMaxApps: 'settings.limitMaxApps',
    limitMaxAppsDescription: 'settings.limitMaxAppsDescription',
    disabledInLiteMode: 'settings.disabledInLiteMode',
    zoomWithScrollWheel: 'settings.zoomWithScrollWheel',
    makeTheScrollWheelZoomInsteadOfPan:
        'settings.makeTheScrollWheelZoomInsteadOfPan',
    pictureInPicture: 'settings.pictureInPicture',
    openPictureInPictureUponScreenShare:
        'settings.openPictureInPictureUponScreenShare',
    autoActivateSpatialRibbonTitle: 'settings.autoActivateSpatialRibbonTitle',
    autoActivateSpatialRibbonDescription:
        'settings.autoActivateSpatialRibbonDescription',
    enableDesktopNotifications: 'settings.enableDesktopNotifications',
    enableEmailNotifications: 'settings.enableEmailNotifications',
    sendMeNotificationsForNewChatMessages:
        'settings.sendMeNotificationsForNewChatMessages',
    sendMeEmailNotifications: 'settings.sendMeEmailNotifications',
    sounds: 'settings.sounds',
    enableCalendarStatusUpdates: 'settings.enableCalendarStatusUpdates',
    calendarStatusUpdates: 'settings.calendarStatusUpdates',
    footstepsWhenSomeoneEntersYourSpace:
        'settings.footstepsWhenSomeoneEntersYourSpace',
    soundsOfTeamflow: 'settings.soundsOfTeamflow',
    doorbellWhenEnteringTeamflow: 'settings.doorbellWhenEnteringTeamflow',
    messageReceived: 'settings.messageReceived',
    knockingWhenSomeoneIsTryingToEnter:
        'settings.knockingWhenSomeoneIsTryingToEnter',
    pingWhenSomeoneIsInvitingYou: 'settings.pingWhenSomeoneIsInvitingYou',
    someoneTapsYouOnTheShoulder: 'settings.someoneTapsYouOnTheShoulder',
    yourCurrentPassword: 'settings.yourCurrentPassword',
    yourNewPassword: 'settings.yourNewPassword',
    onlyPngAndJpegImagesAreSupportedForProfilePictures:
        'settings.onlyPngAndJpegImagesAreSupportedForProfilePictures',
    uploadPhoto: 'settings.uploadPhoto',
    min200X200PxPngOrJpeg: 'settings.min200X200PxPngOrJpeg',
    errorPictureMin200X200Px: 'settings.errorPictureMin200X200Px',
    firstName: 'settings.firstName',
    lastName: 'settings.lastName',
    slack: 'settings.slack',
    linkSlackAndTeamflowAccountsTogetherToSyncYourStatusToLearnMoreAboutOurSlackAppVisitOurGuide:
        'settings.linkSlackAndTeamflowAccountsTogetherToSyncYourStatusToLearnMoreAboutOurSlackAppVisitOurGuide',
    revoke: 'settings.revoke',
    connect: 'settings.connect',
    firstNameMissing: 'settings.firstNameMissing',
    lastNameMissing: 'settings.lastNameMissing',
    passwordIsIncorrect: 'settings.passwordIsIncorrect',
    passwordIsLessThanCharacters: 'settings.passwordIsLessThanCharacters',
    pleaseSupplyACurrentPassword: 'settings.pleaseSupplyACurrentPassword',
    pleaseSupplyANewPassword: 'settings.pleaseSupplyANewPassword',
    newPasswordMustBeDifferentFromTheCurrentPassword:
        'settings.newPasswordMustBeDifferentFromTheCurrentPassword',
    profileUpdated: 'settings.profileUpdated',
    errorUpdatingProfile: 'settings.errorUpdatingProfile',
    receiveASlackNotificationInAChannelWhenPeopleAreHangingOutInTeamflowToLearnMoreAboutOurSlackAppVisitOurGuide:
        'settings.receiveASlackNotificationInAChannelWhenPeopleAreHangingOutInTeamflowToLearnMoreAboutOurSlackAppVisitOurGuide',
    sendALinkToMySlackChannelWhenPeopleAreOnline:
        'settings.sendALinkToMySlackChannelWhenPeopleAreOnline',
    setYourselfAsAvailable: 'settings.setYourselfAsAvailable',
    sinceYouWereAwayWeAutomaticallySetYourCameraAndMicOff:
        'settings.sinceYouWereAwayWeAutomaticallySetYourCameraAndMicOff',
    manageSettings: 'settings.manageSettings',
    notYet: 'settings.notYet',
    yesEnableText: 'settings.yesEnableText',
    setCustomStatus: 'settings.setCustomStatus',
    whatsYourStatus: 'settings.whatsYourStatus',
    coffeeBreakRoundTwo: 'settings.coffeeBreakRoundTwo',
    clear: 'settings.clear',
    setStatus: 'settings.setStatus',
    billing: 'settings.billing',
    team: 'settings.team',
    searchByNameOrEmail: 'settings.searchByNameOrEmail',
    member: 'settings.member',
    memberDescription: 'settings.memberDescription',
    memberDescriptionAdminOnlyInvite:
        'settings.memberDescriptionAdminOnlyInvite',
    admin: 'settings.admin',
    adminDescription: 'settings.adminDescription',
    owner: 'settings.owner',
    ownerDescription: 'settings.ownerDescription',
    transferOwner: 'settings.transferOwner',
    transferOwnerDescription: 'settings.transferOwnerDescription',
    transferOwnerConfirm: 'settings.transferOwnerConfirm',
    transferOwnerExplanation: 'settings.transferOwnerExplanation',
    removeMember: 'settings.removeMember',
    noMembersInFilter: 'settings.noMembersInFilter',
    cannotPerformAction: 'settings.cannotPerformAction',
    loading: 'settings.loading',
    adminInviteDescription: 'settings.adminInviteDescription',
    resendInvite: 'settings.resendInvite',
    cancelInvite: 'settings.cancelInvite',
    inviteSent: 'settings.inviteSent',
    inviteResentToEmail: 'settings.inviteResentToEmail',
    inviteCanceledForEmail: 'settings.inviteCanceledForEmail',
    invoiceLink: 'settings.invoiceLink',
    invoiceHistory: 'settings.invoiceHistory',
    noInvoicesFound: 'settings.noInvoicesFound',
    viewMoreInvoices: 'settings.viewMoreInvoices',
    noPaymentMethodFound: 'settings.noPaymentMethodFound',
    paymentMethod: 'settings.paymentMethod',
    editPayment: 'settings.editPayment',
    editPaymentMethod: 'settings.editPaymentMethod',
    removePaymentMethod: 'settings.removePaymentMethod',
    confirmRemovePaymentMethod: 'settings.confirmRemovePaymentMethod',
    addPayment: 'settings.addPayment',
    aCompleteAddressIsRequired: 'settings.aCompleteAddressIsRequired',
    noPaymentMethodWasCreated: 'settings.noPaymentMethodWasCreated',
    somethingWentWrongPleaseTryAgainLater:
        'settings.somethingWentWrongPleaseTryAgainLater',
    close: 'settings.close',
    update: 'settings.update',
    bespoke: 'settings.bespoke',
    yourPlan: 'settings.yourPlan',
    planType: 'settings.planType',
    seats: 'settings.seats',
    cost: 'settings.cost',
    nextPayment: 'settings.nextPayment',
    changePlan: 'settings.changePlan',
    seat: 'settings.seat',
    trial: 'settings.trial',
    contactUs: 'settings.contactUs',
    upgrade: 'settings.upgrade',
    uploadLogo: 'settings.uploadLogo',
    paymentMethodUpdated: 'settings.paymentMethodUpdated',
    alwaysDisplayAvSetup: 'settings.alwaysDisplayAvSetup',
    avSetupDescription: 'settings.avSetupDescription',
    mainFloor: 'settings.mainFloor',
    teamflowVolumeWhileDialingCall: 'settings.teamflowVolumeWhileDialingCall',
    teamflowVolumeWhileDialingCallDescription:
        'settings.teamflowVolumeWhileDialingCallDescription',
}

// src/frontend/components/sidebar/customize*

const customize = {
    custom: 'customize.custom',
    default: 'customize.default',
    walls: 'customize.walls',
    floors: 'customize.floors',
    backgroundImages: {
        blank: 'customize.backgroundImages.blank',
        os: 'customize.backgroundImages.os',
        woodLight: 'customize.backgroundImages.woodLight',
        woodMedium: 'customize.backgroundImages.woodMedium',
        woodDark: 'customize.backgroundImages.woodDark',
        concreteLight: 'customize.backgroundImages.concreteLight',
        concreteDark: 'customize.backgroundImages.concreteDark',
        marble: 'customize.backgroundImages.marble',
        marbleLight: 'customize.backgroundImages.marbleLight',
        noise: 'customize.backgroundImages.noise',
        grass: 'customize.backgroundImages.grass',
    },
    furnitureCategories: {
        CHAIRS: 'customize.furnitureCategories.CHAIRS',
        'DESK SETS': 'customize.furnitureCategories.DESK SETS',
        PLANTS: 'customize.furnitureCategories.PLANTS',
        SOFAS: 'customize.furnitureCategories.SOFAS',
        TABLES: 'customize.furnitureCategories.TABLES',
        'TABLE SETS': 'customize.furnitureCategories.TABLE SETS',
        COMMUNAL: 'customize.furnitureCategories.COMMUNAL',
        RUGS: 'customize.furnitureCategories.RUGS',
        'FOODS AND DRINKS': 'customize.furnitureCategories.FOODS AND DRINKS',
        DECORATION: 'customize.furnitureCategories.DECORATION',
        PARTY: 'customize.furnitureCategories.PARTY',
    } as Record<string, string>,
    furnitures: {
        'White Arm Chair': 'customize.furnitures.White Arm Chair',
        'Black Office Chair': 'customize.furnitures.Black Office Chair',
        'Midcentry Armchair': 'customize.furnitures.Midcentry Armchair',
        'Modern White': 'customize.furnitures.Modern White',
        'Tonon Black': 'customize.furnitures.Tonon Black',
        'Wooden Desk': 'customize.furnitures.Wooden Desk',
        'Modern Desk': 'customize.furnitures.Modern Desk',
        Bub: 'customize.furnitures.Bub',
        Fern: 'customize.furnitures.Fern',
        'Purple Succulent': 'customize.furnitures.Purple Succulent',
        'Green Succulent': 'customize.furnitures.Green Succulent',
        'Potted Plant 1': 'customize.furnitures.Potted Plant 1',
        'Potted Plant 2': 'customize.furnitures.Potted Plant 2',
        'Potted Plant 3': 'customize.furnitures.Potted Plant 3',
        'Black Sofa': 'customize.furnitures.Black Sofa',
        'White Sofa': 'customize.furnitures.White Sofa',
        'Green Leather Sofa': 'customize.furnitures.Green Leather Sofa',
        'Mcm-1': 'customize.furnitures.Mcm-1',
        'Modern Sectional': 'customize.furnitures.Modern Sectional',
        'Red Leather': 'customize.furnitures.Red Leather',
        'Scandinavian 1': 'customize.furnitures.Scandinavian 1',
        'Scandinavian 2': 'customize.furnitures.Scandinavian 2',
        'Traditional Blue Velvet':
            'customize.furnitures.Traditional Blue Velvet',
        'White Leather': 'customize.furnitures.White Leather',
        'Yellow Leather': 'customize.furnitures.Yellow Leather',
        'Side Table': 'customize.furnitures.Side Table',
        'Wood Table': 'customize.furnitures.Wood Table',
        'Set 1': 'customize.furnitures.Set 1',
        'Set 2': 'customize.furnitures.Set 2',
        'Set 3': 'customize.furnitures.Set 3',
        'Office Cooler': 'customize.furnitures.Office Cooler',
        'Animal Print': 'customize.furnitures.Animal Print',
        'Black Area': 'customize.furnitures.Black Area',
        Classic: 'customize.furnitures.Classic',
        'Classic 2': 'customize.furnitures.Classic 2',
        'Geometric-1': 'customize.furnitures.Geometric-1',
        'Geometric-2': 'customize.furnitures.Geometric-2',
        'Geometric-3': 'customize.furnitures.Geometric-3',
        'Geometric-4': 'customize.furnitures.Geometric-4',
        Honeycomb: 'customize.furnitures.Honeycomb',
        Linen: 'customize.furnitures.Linen',
        'Theater Conference Chair':
            'customize.furnitures.Theater Conference Chair',
        Printer: 'customize.furnitures.Printer',
        'Magnolia Tree': 'customize.furnitures.Magnolia Tree',
        'Metal Bench': 'customize.furnitures.Metal Bench',
        'Brown Swivel Chair': 'customize.furnitures.Brown Swivel Chair',
        'Champagne Flute': 'customize.furnitures.Champagne Flute',
        'Champagne on Ice': 'customize.furnitures.Champagne on Ice',
        'Persimmon Tree': 'customize.furnitures.Persimmon Tree',
        'Beer Mug': 'customize.furnitures.Beer Mug',
        'Stuffed Dinosaur': 'customize.furnitures.Stuffed Dinosaur',
        'Conference Room Set': 'customize.furnitures.Conference Room Set',
        Tiki: 'customize.furnitures.Tiki',
        'Champagne Bottle': 'customize.furnitures.Champagne Bottle',
        'Lemon Tree': 'customize.furnitures.Lemon Tree',
        'Whiskey Cocktail': 'customize.furnitures.Whiskey Cocktail',
        'Director Chair': 'customize.furnitures.Director Chair',
        'Leather Chair': 'customize.furnitures.Leather Chair',
        'Cartoon Dinosaur': 'customize.furnitures.Cartoon Dinosaur',
        Stroller: 'customize.furnitures.Stroller',
        'Willow Tree': 'customize.furnitures.Willow Tree',
        'Popcorn Bucket': 'customize.furnitures.Popcorn Bucket',
        'Executive Office': 'customize.furnitures.Executive Office',
        Mojito: 'customize.furnitures.Mojito',
        'Blue Cocktail': 'customize.furnitures.Blue Cocktail',
        'Big Fall Tree': 'customize.furnitures.Big Fall Tree',
    } as Record<string, string>,
    layoutCategories: {
        walls: {
            PLYWOOD: 'customize.layoutCategories.walls.PLYWOOD',
            BRICK: 'customize.layoutCategories.walls.BRICK',
            STANDARD: 'customize.layoutCategories.walls.STANDARD',
            'CONCRETE SMOOTH':
                'customize.layoutCategories.walls.CONCRETE SMOOTH',
            'CONCRETE LINES': 'customize.layoutCategories.walls.CONCRETE LINES',
            'CONCRETE BLOCKS':
                'customize.layoutCategories.walls.CONCRETE BLOCKS',
            'AEGEAN TEAL': 'customize.layoutCategories.walls.AEGEAN TEAL',
            'DYRE HAVEN': 'customize.layoutCategories.walls.DYRE HAVEN',
            'GRANITE DUST': 'customize.layoutCategories.walls.GRANITE DUST',
            'HAGUE BLUE': 'customize.layoutCategories.walls.HAGUE BLUE',
            'KALAHARI SUNSET':
                'customize.layoutCategories.walls.KALAHARI SUNSET',
            'PALE APRICOT': 'customize.layoutCategories.walls.PALE APRICOT',
            'PALE OAK': 'customize.layoutCategories.walls.PALE OAK',
            ROCKS: 'customize.layoutCategories.walls.ROCKS',
            SANDSTONE: 'customize.layoutCategories.walls.SANDSTONE',
            QUARTZ: 'customize.layoutCategories.walls.QUARTZ',
        } as Record<string, string>,
        tiles: {
            ALDER: 'customize.layoutCategories.tiles.ALDER',
            BIRCH: 'customize.layoutCategories.tiles.BIRCH',
            MAPLE: 'customize.layoutCategories.tiles.MAPLE',
            WALNUT: 'customize.layoutCategories.tiles.WALNUT',
            BERMUDA: 'customize.layoutCategories.tiles.BERMUDA',
            'BIG PEBBLES': 'customize.layoutCategories.tiles.BIG PEBBLES',
            'BLUE ROCKS': 'customize.layoutCategories.tiles.BLUE ROCKS',
            'DARK CONCRETE': 'customize.layoutCategories.tiles.DARK CONCRETE',
            'LIGHT CONCRETE': 'customize.layoutCategories.tiles.LIGHT CONCRETE',
            'NAVY CARPET': 'customize.layoutCategories.tiles.NAVY CARPET',
            'TAN CARPET': 'customize.layoutCategories.tiles.TAN CARPET',
            'TEAL CARPET': 'customize.layoutCategories.tiles.TEAL CARPET',
            BEACH: 'customize.layoutCategories.tiles.BEACH',
            DESERT: 'customize.layoutCategories.tiles.DESERT',
            LAVA: 'customize.layoutCategories.tiles.LAVA',
            POOL: 'customize.layoutCategories.tiles.POOL',
            SNOW: 'customize.layoutCategories.tiles.SNOW',
            WOOD: 'customize.layoutCategories.tiles.WOOD',
            OUTDOOR: 'customize.layoutCategories.tiles.OUTDOOR',
            CONCRETE: 'customize.layoutCategories.tiles.CONCRETE',
            CARPET: 'customize.layoutCategories.tiles.CARPET',
        } as Record<string, string>,
    },
    imageIsTooLargePleaseUploadAnImageUnder5Mb:
        'customize.imageIsTooLargePleaseUploadAnImageUnder5Mb',
    fileMustBePngOrJpeg: 'customize.fileMustBePngOrJpeg',
    failedToLoadImage: 'customize.failedToLoadImage',
    customBackgrounds: 'customize.customBackgrounds',
    teamflowBackgrounds: 'customize.teamflowBackgrounds',
    internalError: 'customize.internalError',
    onlyPngAndJpeGsAreSupported: 'customize.onlyPngAndJpeGsAreSupported',
    imageTooLarge5MbLimit: 'customize.imageTooLarge5MbLimit',
    imageWidthHeightMustBeAtLeast32Px:
        'customize.imageWidthHeightMustBeAtLeast32Px',
    imageWidthHeightMustBeLessThan2048Px:
        'customize.imageWidthHeightMustBeLessThan2048Px',
    failedToUploadImage: 'customize.failedToUploadImage',
    failedToAddFurnitureIntoOrganization:
        'customize.failedToAddFurnitureIntoOrganization',
    invalidFormat: 'customize.invalidFormat',
    uploadPngs: 'customize.uploadPngs',
    interactiveItems: 'customize.interactiveItems',
    speakerCircle: 'customize.speakerCircle',
    broadcastToTheEntireSpaceOrOnlyInsideAnAudioZone:
        'customize.broadcastToTheEntireSpaceOrOnlyInsideAnAudioZone',
    wayfinding: 'customize.wayfinding',
    sign: 'customize.sign',
    audioZones: 'customize.audioZones',
    audioZonesLounge: 'customize.audioZonesLounge',
    setupAnAudioZone: 'customize.setupAnAudioZone',
    audioZoneDescription: 'customize.audioZoneDescription',
    everyoneHearsEquallyInThisDefinedZone:
        'customize.everyoneHearsEquallyInThisDefinedZone',
    audioZone: 'customize.audioZone',
    fetchingYourFurniture: 'customize.fetchingYourFurniture',
    somethingWentWrong: 'customize.somethingWentWrong',
    customizeSpace: 'customize.customizeSpace',
    closeCustomizeMode: 'customize.closeCustomizeMode',
    furniture: 'customize.furniture',
    background: 'customize.background',
    layout: 'customize.layout',
    saveExit: 'customize.saveExit',
    editingTheLocation: 'customize.editingTheLocation',
    room: 'customize.room',
    mainFloor: 'customize.mainFloor',
    rooms: 'customize.rooms',
    audioZoneLabelPlaceholder: 'customize.audioZoneLabelPlaceholder',
}

// src/frontend/components/sidebar/chatPopout

const chatPopout = {
    mainFloor: 'chatPopout.mainFloor',
    goTo: 'chatPopout.goTo',
    get: 'chatPopout.get',
    more: 'chatPopout.more',
    inviteToRoom: 'chatPopout.inviteToRoom',
    goToRoom: 'chatPopout.goToRoom',
    customize: 'chatPopout.customize',
}

// src/frontend/components/sidebar/schedulePopout

const schedulePopout = {
    dismiss: 'schedulePopout.dismiss',
    weFoundOtherMeetingLinks: 'schedulePopout.weFoundOtherMeetingLinks',
    wouldYouLikeToUpdateYourMeetingLinksToTeamflow:
        'schedulePopout.wouldYouLikeToUpdateYourMeetingLinksToTeamflow',
    reviewMeetingLinks: 'schedulePopout.reviewMeetingLinks',
    seeYourScheduleOnTeamflow: 'schedulePopout.seeYourScheduleOnTeamflow',
    looksLikeYouHaveNoMeetingsToday:
        'schedulePopout.looksLikeYouHaveNoMeetingsToday',
    createNewEvent: 'schedulePopout.createNewEvent',
    edit: 'schedulePopout.edit',
    close: 'schedulePopout.close',
    agenda: 'schedulePopout.agenda',
    attendees: 'schedulePopout.attendees',
    addEvent: 'schedulePopout.addEvent',
    noEventsYet: 'schedulePopout.noEventsYet',
    syncWithGoogleCalendarToSeeYourDaysScheduleAndAutomaticallyMuteTeamflowWhenInAMeeting:
        'schedulePopout.syncWithGoogleCalendarToSeeYourDaysScheduleAndAutomaticallyMuteTeamflowWhenInAMeeting',
    syncGoogleCalendar: 'schedulePopout.syncGoogleCalendar',
    syncOutlookCalendar: 'schedulePopout.syncOutlookCalendar',
    syncWithAnotherCalendar: 'schedulePopout.syncWithAnotherCalendar',
    googleCalendarOnlyAvailableWithSSO:
        'schedulePopout.googleCalendarOnlyAvailableWithSSO',
    updateYourTeamflowApp: 'schedulePopout.updateYourTeamflowApp',
    syncingWithGoogleCalendarRequiresANewerVersionOfTheTeamflowApp:
        'schedulePopout.syncingWithGoogleCalendarRequiresANewerVersionOfTheTeamflowApp',
    downloadUpdate: 'schedulePopout.downloadUpdate',
    meetingsMigratedSuccessfully: 'schedulePopout.meetingsMigratedSuccessfully',
}

// src/frontend/components/sidebar/eventAdminToolsPopout

const eventAdminToolsPopout = {
    adminTools: 'eventAdminToolsPopout.adminTools',
    editEvent: 'eventAdminToolsPopout.editEvent',
    editEventTooltip: 'eventAdminToolsPopout.editEventTooltip',
    startBroadcasting: 'eventAdminToolsPopout.startBroadcasting',
    stopBroadcasting: 'eventAdminToolsPopout.stopBroadcasting',
    broadcastTooltip: 'eventAdminToolsPopout.broadcastTooltip',
    startBroadcastingToast: 'eventAdminToolsPopout.startBroadcastingToast',
    stopBroadcastingToast: 'eventAdminToolsPopout.stopBroadcastingToast',
    muteAll: 'eventAdminToolsPopout.muteAll',
    muteAllTooltip: 'eventAdminToolsPopout.muteAllTooltip',
    selectAll: 'eventAdminToolsPopout.selectAll',
    movePeopleNote: 'eventAdminToolsPopout.movePeopleNote',
    allowGuestsToScreenshare: 'eventAdminToolsPopout.allowGuestsToScreenshare',
    allowGuestsToOpenApps: 'eventAdminToolsPopout.allowGuestsToOpenApps',
    startSelecting: 'eventAdminToolsPopout.startSelecting',
    stopSelecting: 'eventAdminToolsPopout.stopSelecting',
    selectAllTooltip: 'eventAdminToolsPopout.selectAllTooltip',
    selectionModeStarted: 'eventAdminToolsPopout.selectionModeStarted',
    participantsSelected: 'eventAdminToolsPopout.participantsSelected',
}

// src/frontend/components/sidebar

const sidebar = {
    createAccount: 'sidebar.createAccount',
    addMembers: 'sidebar.addMembers',
    addTeammates: 'sidebar.addTeammates',
    syncCalendar: 'sidebar.syncCalendar',
    connectSlack: 'sidebar.connectSlack',
    getTheApp: 'sidebar.getTheApp',
    getTheAppMobile: 'sidebar.getTheAppMobile',
    getCalendarExtension: 'sidebar.getCalendarExtension',
    gettingStarted: 'sidebar.gettingStarted',
    markAllComplete: 'sidebar.markAllComplete',
    createNewWorkspace: 'sidebar.createNewWorkspace',
    createNewSalesFloor: 'sidebar.createNewSalesFloor',
    closeSidebar: 'sidebar.closeSidebar',
    settings: 'sidebar.settings',
    profileApps: 'sidebar.profileApps',
    workspaceApps: 'sidebar.workspaceApps',
    downloadDesktopApp: 'sidebar.downloadDesktopApp',
    downloadMobileApp: 'sidebar.downloadMobileApp',
    signOutOfTeamflow: 'sidebar.signOutOfTeamflow',
    signOutOfOrg: 'sidebar.signOutOfOrg',
    invitePeopleToOrg: 'sidebar.invitePeopleToOrg',
    quickSearch: 'sidebar.quickSearch',
    searchAndQuicklyPerformActions: 'sidebar.searchAndQuicklyPerformActions',
    schedule: 'sidebar.schedule',
    calendar: 'sidebar.calendar',
    whatsNew: 'sidebar.whatsNew',
    seeWhatsNew: 'sidebar.seeWhatsNew',
    mainFloor: 'sidebar.mainFloor',
    lobby: 'sidebar.lobby',
    newPod: 'sidebar.newPod',
    createPodHeader: 'sidebar.createPodHeader',
    createPodPlaceholder: 'sidebar.createPodPlaceholder',
    cancel: 'sidebar.cancel',
    createPod: 'sidebar.createPod',
    visibilityRoomsWithNoUsersEmptyRooms:
        'sidebar.visibilityRoomsWithNoUsersEmptyRooms',
    hide: 'sidebar.hide',
    show: 'sidebar.show',
    roomInviteLinkCopied: 'sidebar.roomInviteLinkCopied',
    createPortalToRoom: 'sidebar.createPortalToRoom',
    inviteToRoom: 'sidebar.inviteToRoom',
    goToRoom: 'sidebar.goToRoom',
    previewRoom: 'sidebar.previewRoom',
    unsubscribe: 'sidebar.unsubscribe',
    rename: 'sidebar.rename',
    unpin: 'sidebar.unpin',
    pin: 'sidebar.pin',
    copyInviteLink: 'sidebar.copyInviteLink',
    chat: 'sidebar.chat',
    delete: 'sidebar.delete',
    expandSidebar: 'sidebar.expandSidebar',
    editProfile: 'sidebar.editProfile',
    connectApps: 'sidebar.connectApps',
    rooms: 'sidebar.rooms',
    favorites: 'sidebar.favorites',
    new: 'sidebar.new',
    directMessagesAmount: 'sidebar.directMessagesAmount',
    directMessages: 'sidebar.directMessages',
    invite: 'sidebar.invite',
    closePopout: 'sidebar.closePopout',
    close: 'sidebar.close',
    invitationForEmailRemoved: 'sidebar.invitationForEmailRemoved',
    emailHasBeenInvitedButHasNotSignedUpYet:
        'sidebar.emailHasBeenInvitedButHasNotSignedUpYet',
    inviteResentToEmail: 'sidebar.inviteResentToEmail',
    sendReminderEmail: 'sidebar.sendReminderEmail',
    removeInvitation: 'sidebar.removeInvitation',
    itsLonelyInHere: 'sidebar.itsLonelyInHere',
    teamflowWorksBestWhenYouInviteYourTeam:
        'sidebar.teamflowWorksBestWhenYouInviteYourTeam',
    addYourTeam: 'sidebar.addYourTeam',
    inviteViaSlack: 'sidebar.inviteViaSlack',
    thisChatIsPrivateToThePeopleInsideTheRoom:
        'sidebar.thisChatIsPrivateToThePeopleInsideTheRoom',
    thisChatIsPrivateToThePeopleWithAccessToTheRoom:
        'sidebar.thisChatIsPrivateToThePeopleWithAccessToTheRoom',
    goTo: 'sidebar.goTo',
    inviteFirstName: 'sidebar.inviteFirstName',
    more: 'sidebar.more',
    deleteRoom: 'sidebar.deleteRoom',
    clearChat: 'sidebar.clearChat',
    chatHasBeenDeleted: 'sidebar.chatHasBeenDeleted',
    roomDeleted: 'sidebar.roomDeleted',
    dashboard: 'sidebar.dashboard',
    viewHomeScreen: 'sidebar.viewHomeScreen',
    unreadMessages: 'sidebar.unreadMessages',
    showUnreadMessages: 'sidebar.showUnreadMessages',
    hideUnreadMessages: 'sidebar.hideUnreadMessages',
    unreadMessagesIcon: 'sidebar.unreadMessagesIcon',
    adminTools: 'sidebar.adminTools',
    adminToolsTooltip: 'sidebar.adminToolsTooltip',
    viewAll: 'sidebar.viewAll',
    viewLess: 'sidebar.viewLess',
    connected: 'sidebar.connected',
    connect: 'sidebar.connect',
    addRooms: 'sidebar.addRooms',
    browseRooms: 'sidebar.browseRooms',
    createRoom: 'sidebar.createRoom',
    addFloors: 'sidebar.addFloors',
    browseFloors: 'sidebar.browseFloors',
    createFloor: 'sidebar.createFloor',
}

// src/frontend/components/tierLimits

const tierLimits = {
    yourFreeMeetingIsOnCooldown: 'tierLimits.yourFreeMeetingIsOnCooldown',
    itWillResumeAfterThisShortBreak:
        'tierLimits.itWillResumeAfterThisShortBreak',
    activeParticipantsMaxUsersUsers:
        'tierLimits.activeParticipantsMaxUsersUsers',
    activeParticipantsMaxGuests: 'tierLimits.activeParticipantsMaxGuests',
    upgrade: 'tierLimits.upgrade',
    welcomeBack: 'tierLimits.welcomeBack',
    trialExtended: 'tierLimits.trialExtended',
    awesome: 'tierLimits.awesome',
}

// src/frontend/components/verse

const verse = {
    furniture: {
        rotateCounterclockwise: 'verse.furniture.rotateCounterclockwise',
        rotateClockwise: 'verse.furniture.rotateClockwise',
        bringForward: 'verse.furniture.bringForward',
        sendBack: 'verse.furniture.sendBack',
        place: 'verse.furniture.place',
        trash: 'verse.furniture.trash',
    },
    hooks: {
        howToFix: 'verse.hooks.howToFix',
        settings: 'verse.hooks.settings',
        itLooksLikeYouveTurnedHardwareAccelerationOffOrWebGlIsntSupportedSomeFeaturesAreDisabled:
            'verse.hooks.itLooksLikeYouveTurnedHardwareAccelerationOffOrWebGlIsntSupportedSomeFeaturesAreDisabled',
        theMeetingHostHasDeniedEntry:
            'verse.hooks.theMeetingHostHasDeniedEntry',
        firstNameMutedYou: 'verse.hooks.firstNameMutedYou',
        ok: 'verse.hooks.ok',
        clickToEnableAudio: 'verse.hooks.clickToEnableAudio',
    },
    minimap: {
        switchToRoom: 'verse.minimap.switchToRoom',
        switchToFloor: 'verse.minimap.switchToFloor',
        openMap: 'verse.minimap.openMap',
        closeMap: 'verse.minimap.closeMap',
        center: 'verse.minimap.center',
        zoomIn: 'verse.minimap.zoomIn',
        zoomOut: 'verse.minimap.zoomOut',
    },
    notifications: {
        audioOrVideoProblemDetected:
            'verse.notifications.audioOrVideoProblemDetected',
        someoneIsUnableToHearOrSeeYou:
            'verse.notifications.someoneIsUnableToHearOrSeeYou',
        namedListAndDiffOthersAreHavingTroubleSeeingOrHearingYou:
            'verse.notifications.namedListAndDiffOthersAreHavingTroubleSeeingOrHearingYou',
        nameIsHavingTroubleSeeingOrHearingYou:
            'verse.notifications.nameIsHavingTroubleSeeingOrHearingYou',
        namedListAreHavingTroubleSeeingOrHearingYou:
            'verse.notifications.namedListAreHavingTroubleSeeingOrHearingYou',
        upgradeToLetUserIn: 'verse.notifications.upgradeToLetUserIn',
        plansStartAt15Month: 'verse.notifications.plansStartAt15Month',
        user: 'verse.notifications.user',
        upgradeToLetFirstNameIn: 'verse.notifications.upgradeToLetFirstNameIn',
        newChangesComing: 'verse.notifications.newChangesComing',
        youMayGetDisconnectedInAFewMinutes:
            'verse.notifications.youMayGetDisconnectedInAFewMinutes',
        updateInProgress: 'verse.notifications.updateInProgress',
        interactionsWillBeLimitedForAFewMinutes:
            'verse.notifications.interactionsWillBeLimitedForAFewMinutes',
        updateComplete: 'verse.notifications.updateComplete',
        reloadToGetTheNewChanges:
            'verse.notifications.reloadToGetTheNewChanges',
        upToMaxUsersUsersCanBeInYourFreePlanMoreThanThatJoinedSoWeGaveYouFreeAccessToOurPaidPlanFor30Days:
            'verse.notifications.upToMaxUsersUsersCanBeInYourFreePlanMoreThanThatJoinedSoWeGaveYouFreeAccessToOurPaidPlanFor30Days',
        firstNameInvitedCurrentUserFirstNameToJoin:
            'verse.notifications.firstNameInvitedCurrentUserFirstNameToJoin',
        firstNameWantsYouToJoinThem:
            'verse.notifications.firstNameWantsYouToJoinThem',
        newInvitation: 'verse.notifications.newInvitation',
        justAMinute: 'verse.notifications.justAMinute',
        firstNameWillMeetUpLater:
            'verse.notifications.firstNameWillMeetUpLater',
        firstNameWantsToChat: 'verse.notifications.firstNameWantsToChat',
        areYouFree: 'verse.notifications.areYouFree',
        joinAsTeamMember: 'verse.notifications.joinAsTeamMember',
        youveBeenInvited: 'verse.notifications.youveBeenInvited',
        joinDisplayNameSTeam: 'verse.notifications.joinDisplayNameSTeam',
        youveBeenInvitedToJoin: 'verse.notifications.youveBeenInvitedToJoin',
        spatialAudioEnabledInRooms:
            'verse.notifications.spatialAudioEnabledInRooms',
        peopleCloseCanHearEachOther:
            'verse.notifications.peopleCloseCanHearEachOther',
        spatialAudioDisabledInRooms:
            'verse.notifications.spatialAudioDisabledInRooms',
        everyoneCanHearEveryone: 'verse.notifications.everyoneCanHearEveryone',
        nearbyScreenShare: 'verse.notifications.nearbyScreenShare',
        nearbyUserJoinedRoom: 'verse.notifications.nearbyUserJoinedRoom',
        mainFloor: 'verse.notifications.mainFloor',
        reload: 'verse.notifications.reload',
        dismiss: 'verse.notifications.dismiss',
        upgrade: 'verse.notifications.upgrade',
        great: 'verse.notifications.great',
        go: 'verse.notifications.go',
        decline: 'verse.notifications.decline',
        okay: 'verse.notifications.okay',
        accept: 'verse.notifications.accept',
        okGotIt: 'verse.notifications.okGotIt',
        joinFirstName: 'verse.notifications.joinFirstName',
        meetLater: 'verse.notifications.meetLater',
        firstNameHasInvitedYouToRoomName:
            'verse.notifications.firstNameHasInvitedYouToRoomName',
        later: 'verse.notifications.later',
        viewChat: 'verse.notifications.viewChat',
        view: 'verse.notifications.view',
        talkToSpecialist: 'verse.notifications.talkToSpecialist',
        findTime: 'verse.notifications.findTime',
        noThanks: 'verse.notifications.noThanks',
        weUpdatedHowYourAudioVideoAreRouted:
            'verse.notifications.weUpdatedHowYourAudioVideoAreRouted',
        reloadIfYouBeginExperiencingAnyIssues:
            'verse.notifications.reloadIfYouBeginExperiencingAnyIssues',
    },
    portals: {
        rename: 'verse.portals.rename',
        rotateCounterclockwise: 'verse.portals.rotateCounterclockwise',
        rotateClockwise: 'verse.portals.rotateClockwise',
        place: 'verse.portals.place',
        cannotDeleteWhileOccupied: 'verse.portals.cannotDeleteWhileOccupied',
        delete: 'verse.portals.delete',
        deleteRoom: 'verse.portals.deleteRoom',
        close: 'verse.portals.close',
        areYouSureYouWantToDeleteThisRoom:
            'verse.portals.areYouSureYouWantToDeleteThisRoom',
        appWillBeRemovedOnDeletion: 'verse.portals.appWillBeRemovedOnDeletion',
        moveAppsToFloor: 'verse.portals.moveAppsToFloor',
        sorryAnotherRoomWithTheNameRoomNameAlreadyExists:
            'verse.portals.sorryAnotherRoomWithTheNameRoomNameAlreadyExists',
        mainFloor: 'verse.portals.mainFloor',
    },
    screens: {
        goingToOrgName: 'verse.screens.goingToOrgName',
        clickOpenTeamflowToLaunchTheDesktopApp:
            'verse.screens.clickOpenTeamflowToLaunchTheDesktopApp',
        notWorkingYouCanAlsoGoToYourOfficeInTheBrowser:
            'verse.screens.notWorkingYouCanAlsoGoToYourOfficeInTheBrowser',
        teamflowIsOpened: 'verse.screens.teamflowIsOpened',
        openTeamflow: 'verse.screens.openTeamflow',
        dontHaveTeamflowInstalled: 'verse.screens.dontHaveTeamflowInstalled',
        downloadNow: 'verse.screens.downloadNow',
        openInBrowser: 'verse.screens.openInBrowser',
        recentlySkipped: 'verse.screens.recentlySkipped',
    },
    placeAudioZone: 'verse.placeAudioZone',
    delete: 'verse.delete',
    connectionLost: 'verse.connectionLost',
    looksLikeYourConnectionWasLostStatusCode:
        'verse.looksLikeYourConnectionWasLostStatusCode',
    reconnecting: 'verse.reconnecting',
    reconnect: 'verse.reconnect',
    unableToConnectToServer: 'verse.unableToConnectToServer',
    unableToConnectToCall: 'verse.unableToConnectToCall',
    weAreWorkingToResolveTheIssuePleaseTryAgainLater:
        'verse.weAreWorkingToResolveTheIssuePleaseTryAgainLater',
    youMayHaveToModifyYourFirewallOrVPNSettings:
        'verse.youMayHaveToModifyYourFirewallOrVPNSettings',
    unableToCommunicateWithServer: 'verse.unableToCommunicateWithServer',
    somethingWentWrong: 'verse.somethingWentWrong',
    weWereUnableToConnectToTeamflowPleaseTryAgainLater:
        'verse.weWereUnableToConnectToTeamflowPleaseTryAgainLater',
    dismiss: 'verse.dismiss',
    reload: 'verse.reload',
    edit: 'verse.edit',
    place: 'verse.place',
    sign: 'verse.sign',
    fontSize: 'verse.fontSize',
    pickColor: 'verse.pickColor',
    roomNameIsFull: 'verse.roomNameIsFull',
    mainFloorIsFull: 'verse.mainFloorIsFull',
    theRoomYouAreTryingToJoinIsAtTheLimit:
        'verse.theRoomYouAreTryingToJoinIsAtTheLimit',
    pleaseWaitAFewMomentsThenTryJoiningAgainOrChooseAnotherRoom:
        'verse.pleaseWaitAFewMomentsThenTryJoiningAgainOrChooseAnotherRoom',
    mainFloor: 'verse.mainFloor',
    theMainFloorIsFull: 'verse.theMainFloorIsFull',
    thereAreCountPeopleHangingOutOnTheMainFloor:
        'verse.thereAreCountPeopleHangingOutOnTheMainFloor',
    joinThem: 'verse.joinThem',
    thisRoomIsFull: 'verse.thisRoomIsFull',
    thereAreCountPeopleHangingOutInThisRoom:
        'verse.thereAreCountPeopleHangingOutInThisRoom',
    enteringRoom: 'verse.enteringRoom',
    goingTo: 'verse.goingTo',
    returningTo: 'verse.returningTo',
    toMainFloor: 'verse.toMainFloor',
    clickAnywhereToContinue: 'verse.clickAnywhereToContinue',
    placeSpeakerCircle: 'verse.placeSpeakerCircle',
    loading: 'verse.loading',
    teamflowHeading: 'verse.teamflowHeading',
    noPermissionForRoom: 'verse.noPermissionForRoom',
    lowFps: 'verse.lowFps',
    enableLiteMode: 'verse.enableLiteMode',
    enabledLiteModeInSettings: 'verse.enabledLiteModeInSettings',
    lowFpsHelp: 'verse.lowFpsHelp',
    lowBandwidth: 'verse.lowBandwidth',
    lowBandwidthHelp: 'verse.lowBandwidthHelp',
}

const avPermissions = {
    allowTeamflowToUseYourCameraAndMicrophone:
        'avPermissions.allowTeamflowToUseYourCameraAndMicrophone',
    teamflowNeedsAccessToYourCameraAndMicrophone:
        'avPermissions.teamflowNeedsAccessToYourCameraAndMicrophone',
    teamflowRequiresAccessToYourCameraAndMicrophone:
        'avPermissions.teamflowRequiresAccessToYourCameraAndMicrophone',
    clickOnTheBlockedIconInYourBrowsersAddressBar:
        'avPermissions.clickOnTheBlockedIconInYourBrowsersAddressBar',
    reload: 'avPermissions.reload',
    clickOnSafariInTheMenuBarAndNavigateToPreferences:
        'avPermissions.clickOnSafariInTheMenuBarAndNavigateToPreferences',
    setAppTeamflowhqComToAllowAndThenRefreshYourPage:
        'avPermissions.setAppTeamflowhqComToAllowAndThenRefreshYourPage',
    navigateToSystemPreferencesSecurityPrivacyMicrophone:
        'avPermissions.navigateToSystemPreferencesSecurityPrivacyMicrophone',
    allowTeamflowAccessToYourMicrophoneByCheckingTheBoxEnableYourCameraTheSameWay:
        'avPermissions.allowTeamflowAccessToYourMicrophoneByCheckingTheBoxEnableYourCameraTheSameWay',
    yourCameraIsBlocked: 'avPermissions.yourCameraIsBlocked',
    yourMicIsBlocked: 'avPermissions.yourMicIsBlocked',
    yourCameraAndMicAreBlocked: 'avPermissions.yourCameraAndMicAreBlocked',
    dismiss: 'avPermissions.dismiss',
    continueWithMicrophone: 'avPermissions.continueWithMicrophone',
    microphone: 'avPermissions.microphone',
    camera: 'avPermissions.camera',
    yourCameraIsInUseByAnotherApplication:
        'avPermissions.yourCameraIsInUseByAnotherApplication',
    pleaseCloseTheOtherApplication:
        'avPermissions.pleaseCloseTheOtherApplication',
    externalCameraDisconnectReconnect:
        'avPermissions.externalCameraDisconnectReconnect',
    ifThisDoesNotFixTheIssueTryToReloadTeamflow:
        'avPermissions.ifThisDoesNotFixTheIssueTryToReloadTeamflow',
    pleaseCheckYourDeviceConnections:
        'avPermissions.pleaseCheckYourDeviceConnections',
    help: 'avPermissions.help',
}

// src/frontend/helpers

const helpers = {
    knockKnock: 'helpers.knockKnock',
    roomIsUnoccupiedWithOwner: 'helpers.roomIsUnoccupiedWithOwner',
    roomIsUnoccupiedWithoutOwner: 'helpers.roomIsUnoccupiedWithoutOwner',
    failedToGoToFloorPleaseTryAgain: 'helpers.failedToGoToFloorPleaseTryAgain',
    failedToLeaveRoomPleaseTryAgain: 'helpers.failedToLeaveRoomPleaseTryAgain',
    failedToEnterRoomPleaseTryAgain: 'helpers.failedToEnterRoomPleaseTryAgain',
    weAreSorryGettingYouIntoTheRoomIsTakingTooLongPleaseWait:
        'helpers.weAreSorryGettingYouIntoTheRoomIsTakingTooLongPleaseWait',
    alreadyInTransit: 'helpers.alreadyInTransit',
    alreadyInRoom: 'helpers.alreadyInRoom',
    alreadyOnFloor: 'helpers.alreadyOnFloor',
    screen: 'helpers.screen',
    image: 'helpers.image',
    whiteboard: 'helpers.whiteboard',
    scratchpad: 'helpers.scratchpad',
    countdown: 'helpers.countdown',
    browser: 'helpers.browser',
    embed: 'helpers.embed',
    pokerGame: 'helpers.pokerGame',
    skribblGame: 'helpers.skribblGame',
    codenamesGame: 'helpers.codenamesGame',
    tetrisGame: 'helpers.tetrisGame',
    drawBattle: 'helpers.drawBattle',
    youTubePlayer: 'helpers.youTubePlayer',
    spotifyPlayer: 'helpers.spotifyPlayer',
    invitingFirstNameOver: 'helpers.invitingFirstNameOver',
    firstNameIsAlreadyNearYou: 'helpers.firstNameIsAlreadyNearYou',
    missingSlugOrInviteCode: 'helpers.missingSlugOrInviteCode',
    youDoNotHavePermissionToAccessThisArea:
        'helpers.youDoNotHavePermissionToAccessThisArea',
    userIsOffline: 'helpers.userIsOffline',
    areYouSureYouWantToSignOut: 'helpers.areYouSureYouWantToSignOut',
    askedFirstNameIfTheyAreFreeToChat:
        'helpers.askedFirstNameIfTheyAreFreeToChat',
    cantNavigateToAudioZone: 'helpers.cantNavigateToAudioZone',
    pleaseCloseTab: 'helpers.pleaseCloseTab',
    hardwareAccelerationIsDisabled: 'helpers.hardwareAccelerationIsDisabled',
    yourDeviceIsNotSupported: 'helpers.yourDeviceIsNotSupported',
    pleaseContactSupportForHelp: 'helpers.pleaseContactSupportForHelp',
    somethingWentWrong: 'helpers.somethingWentWrong',
    notSupportedOnSafari: 'helpers.notSupportedOnSafari',
    notSupportedOnYourDevice: 'helpers.notSupportedOnYourDevice',
}

// src/frontend/hooks

const hooks = {
    firstNameLastNameIsKnockingOnTheFloor:
        'hooks.firstNameLastNameIsKnockingOnTheFloor',
    letIn: 'hooks.letIn',
    dismiss: 'hooks.dismiss',
    decline: 'hooks.decline',
    someone: 'hooks.someone',
    nameIsKnockingOnYourRoom: 'hooks.nameIsKnockingOnYourRoom',
    someoneIsKnockingOnYourRoom: 'hooks.someoneIsKnockingOnYourRoom',
    firstNameLastNameIsKnocking: 'hooks.firstNameLastNameIsKnocking',
    mutedFirstName: 'hooks.mutedFirstName',
    disabledFaceCentering: 'hooks.disabledFaceCentering',
    reEnableFaceCentering: 'hooks.reEnableFaceCentering',
    pinCopied: 'hooks.pinCopied',
}

// src/frontend/verse

const verse2 = {
    render: {
        sign: 'verse2.render.sign',
        broadcastToTheEntireRoom: 'verse2.render.broadcastToTheEntireRoom',
    },
    services: {
        peopleWillAppearHereAsTheyMoveCloser:
            'verse2.services.peopleWillAppearHereAsTheyMoveCloser',
        inMeetingUntilTriggerCalendarEventTime:
            'verse2.services.inMeetingUntilTriggerCalendarEventTime',
        notificationStatusBusyTitle:
            'verse2.services.notificationStatusBusyTitle',
        notificationStatusBusyDescription:
            'verse2.services.notificationStatusBusyDescription',
        notificationStatusBusyGoAvailable:
            'verse2.services.notificationStatusBusyGoAvailable',
        notificationStatusBusyDismiss:
            'verse2.services.notificationStatusBusyDismiss',
    },
    spaces: {
        somebody: 'verse2.spaces.somebody',
        you: 'verse2.spaces.you',
        reOpenedTheAppName: 'verse2.spaces.reOpenedTheAppName',
        teamflow: 'verse2.spaces.teamflow',
        becauseClosedReason: 'verse2.spaces.becauseClosedReason',
        youJustClosedArticleAppTypeReasonClause:
            'verse2.spaces.youJustClosedArticleAppTypeReasonClause',
        closerNameJustClosedArticleAppTypeReasonClause:
            'verse2.spaces.closerNameJustClosedArticleAppTypeReasonClause',
        nameSScreenshareEnded: 'verse2.spaces.nameSScreenshareEnded',
        undo: 'verse2.spaces.undo',
        nameClosedAScreenshare: 'verse2.spaces.nameClosedAScreenshare',
        failedToOpenYourImage: 'verse2.spaces.failedToOpenYourImage',
        areYouSureYouWantToCloseThisScreenShareThisWillCloseItForEveryone:
            'verse2.spaces.areYouSureYouWantToCloseThisScreenShareThisWillCloseItForEveryone',
        youAreNoLongerASpeaker: 'verse2.spaces.youAreNoLongerASpeaker',
        youreNowBroadcastingToTheEntireRoomAudienceSpatialAudioIsReduced:
            'verse2.spaces.youreNowBroadcastingToTheEntireRoomAudienceSpatialAudioIsReduced',
        firstNameIsSpeakingYourSpatialAudioHasBeenReduced:
            'verse2.spaces.firstNameIsSpeakingYourSpatialAudioHasBeenReduced',
        aSpeakerHasLeftThePodium: 'verse2.spaces.aSpeakerHasLeftThePodium',
    },
    statelets: {
        upcomingEvent: 'verse2.statelets.upcomingEvent',
        inCountMinutes: 'verse2.statelets.inCountMinutes',
        join: 'verse2.statelets.join',
        dismiss: 'verse2.statelets.dismiss',
        now: 'verse2.statelets.now',
    },
}

// web/src/components/rooms
const deleteUserModal = {
    personIsNoLongerWithTheCompany:
        'deleteUserModal.personIsNoLongerWithTheCompany',
    personIsStillWithTheCompanyButDoesNotUserTeamflow:
        'deleteUserModal.personIsStillWithTheCompanyButDoesNotUserTeamflow',
    weWillBeCancelingEventually: 'deleteUserModal.weWillBeCancelingEventually',
    other: 'deleteUserModal.other',
    otherPlaceHolder: 'deleteUserModal.otherPlaceHolder',
    reason: 'deleteUserModal.reason',
    description: 'deleteUserModal.description',
    removeUser: 'deleteUserModal.removeUser',
    cancel: 'deleteUserModal.cancel',
    deleteUser: 'deleteUserModal.deleteUser',
}

// web/src/components/rooms
const createRoomModal = {
    cancel: 'createRoomModal.cancel',
    back: 'createRoomModal.back',
    createRoom: 'createRoomModal.createRoom',
    createFloor: 'createRoomModal.createFloor',
    title: 'createRoomModal.title',
    roomName: 'createRoomModal.roomName',
    floorName: 'createRoomModal.floorName',
    noTemplatesInThisCategory: 'createRoomModal.noTemplatesInThisCategory',
    anyoneInCompanyCanJoinRoom: 'createRoomModal.anyoneInCompanyCanJoinRoom',
    anyoneInCompanyCanJoinFloor: 'createRoomModal.anyoneInCompanyCanJoinFloor',
    anyoneWithLinkCanJoin: 'createRoomModal.anyoneWithLinkCanJoin',
    pleaseEnterRoomName: 'createRoomModal.pleaseEnterRoomName',
    onlyInvitedCanJoinRoom: 'createRoomModal.onlyInvitedCanJoinRoom',
    onlyInvitedCanJoinFloor: 'createRoomModal.onlyInvitedCanJoinFloor',
    saving: 'createRoomModal.saving',
    floorTitle: 'createRoomModal.floorTitle',
}

// web/src/hooks/ useRoomFanOut
const roomFanOut = {
    roomIsFull: 'roomFanOut.roomIsFull',
    floorIsFull: 'roomFanOut.floorIsFull',
}

// web/src/components/rooms/portalModal
const portalModal = {
    connectPortalToSpace: 'portalModal.connectPortalToSpace',
    connectToExistingRoom: 'portalModal.connectToExistingRoom',
    connect: 'portalModal.connect',
    createNewSpace: 'portalModal.createNewSpace',
    creatingSpace: 'portalModal.creatingSpace',
    searchExistingSpaces: 'portalModal.searchExistingSpaces',
    noSpaceFoundWithThatName: 'portalModal.noSpaceFoundWithThatName',
    mainFloor: 'portalModal.mainFloor',
}

const mobileApp = {
    yourTeam: 'mobileApp.yourTeam',
    yourSpaces: 'mobileApp.yourSpaces',
    youAreLiveInSpace: 'mobileApp.youAreLiveInSpace',
    download: 'mobileApp.download',
    downloadTheMobileApp: 'mobileApp.downloadTheMobileApp',
    takeTeamflowOnTheGoWithOurNewMobileAppAndChatWithCoworkersJumpOnCallsFromYourMobileDeviceAndMore:
        'mobileApp.takeTeamflowOnTheGoWithOurNewMobileAppAndChatWithCoworkersJumpOnCallsFromYourMobileDeviceAndMore',
}

const desktopApp = {
    weVeGotSomeNewUpdatesDownloadTheLatestVersionOfTheDesktopAppToGetThem:
        'desktopApp.weVeGotSomeNewUpdatesDownloadTheLatestVersionOfTheDesktopAppToGetThem',
    ifYouAreHavingIssuesWhileScreensharingTakeTheseSteps:
        'desktopApp.ifYouAreHavingIssuesWhileScreensharingTakeTheseSteps',
    forMacOsThereAreAFewExtraStepsForThisUpdate:
        'desktopApp.forMacOsThereAreAFewExtraStepsForThisUpdate',
    deleteTheTeamflowApp: 'desktopApp.deleteTheTeamflowApp',
    downloadAndInstallTheNewUpdateHere:
        'desktopApp.downloadAndInstallTheNewUpdateHere',
    teamflowRemoveScreensharePermissionsInstructions:
        'desktopApp.teamflowRemoveScreensharePermissionsInstructions',
    restartTheComputer: 'desktopApp.restartTheComputer',
    teamflowScreenshareInstructions:
        'desktopApp.teamflowScreenshareInstructions',
}

const helpButton = {
    technicalHelp: 'helpButton.technicalHelp',
    submitFeedback: 'helpButton.submitFeedback',
    helpCenter: 'helpButton.helpCenter',
    helpFeedback: 'helpButton.helpFeedback',
}
const experiments = {
    cancel: 'experiments.cancel',
    saving: 'experiments.saving',
    savePreference: 'experiments.savePreference',
    googleCalendar: 'experiments.googleCalendar',
    outlookCalendar: 'experiments.outlookCalendar',
    office365Calendar: 'experiments.office365Calendar',
    iCal: 'experiments.iCal',
    preferredCalendar: 'experiments.preferredCalendar',
    youOnlyNeedToSetThisUpOnce: 'experiments.youOnlyNeedToSetThisUpOnce',
    viewHomeScreen: 'experiments.viewHomeScreen',
}

const loading = {
    downloadingStateTakingAWhile: 'loading.downloadingStateTakingAWhile',
}

// web/src/hooks/useRibbonSuggestion
// web /src/components/dock/RibbonSuggestionTooltip
const ribbonSuggestion = {
    activate: 'ribbonSuggestion.activate',
    ignore: 'ribbonSuggestion.ignore',
    thereAreALotOfPeopleAroundYou:
        'ribbonSuggestion.thereAreALotOfPeopleAroundYou',
    activateSpatialRibbonForABetterExperience:
        'ribbonSuggestion.activateSpatialRibbonForABetterExperience',
}

// web/src/components/rooms/RoomBrowserModal
const roomBrowserModal = {
    browseRooms: 'roomBrowserModal.browseRooms',
    searchByRoomName: 'roomBrowserModal.searchByRoomName',
    noRoomsFound: 'roomBrowserModal.noRoomsFound',
    results: 'roomBrowserModal.results',
    rooms: 'roomBrowserModal.rooms',
    browseFloors: 'roomBrowserModal.browseFloors',
    searchByFloorName: 'roomBrowserModal.searchByFloorName',
    noFloorsFound: 'roomBrowserModal.noFloorsFound',
    floors: 'roomBrowserModal.floors',
}

// Export

export default {
    audioVideo,
    availabilityStatus,
    billingForm,
    grantAccessModal,
    huddleDivider,
    huddleDropdownMenu,
    huddleFormLabel,
    huddleHeader,
    inviteChecklist,
    inviteModal,
    screenLoader,
    slackInviteModal,
    teachingMomentTitles,
    teachingMomentDescriptions,
    teleportModal,
    topHeader,
    apps,
    tutorial,
    dock,
    screenShare,
    electron,
    fullscreen,
    participants,
    search,
    settings,
    customize,
    chatPopout,
    schedulePopout,
    eventAdminToolsPopout,
    sidebar,
    tierLimits,
    verse,
    avPermissions,
    helpers,
    hooks,
    verse2,
    createRoomModal,
    deleteUserModal,
    roomFanOut,
    portalModal,
    mobileApp,
    desktopApp,
    helpButton,
    experiments,
    loading,
    ribbonSuggestion,
    roomBrowserModal,
}
