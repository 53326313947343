import * as t from '@teamflow/types'

/** @group Commands */
export default class SimpleCommand<Type = unknown>
    implements t.ICommand<Type | undefined>
{
    readonly messageType: t.ClientMessage

    private readonly payload?: Type

    constructor(messageType: t.ClientMessage, payload?: Type) {
        this.messageType = messageType
        this.payload = payload
    }

    getPayload() {
        return this.payload
    }

    execute() {
        return this.payload
    }
}

/** @group Commands */
export class SimpleCertifiedCommand<Type = unknown>
    extends SimpleCommand<Type>
    implements t.ICertifiedCommand<Type | undefined>
{
    readonly certified = true
}
