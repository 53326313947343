import { computed, makeAutoObservable, observable, runInAction } from 'mobx'
import { HtmlPortalNode } from 'react-reverse-portal'

import { Arrays, AVATAR_SIZE, clamp, LogManager } from '@teamflow/lib'
import * as t from '@teamflow/types'
import { IParticipant, SalesFloorDialerCallStatus } from '@teamflow/types'

import rootStore, { RootStore } from './rootStore'

export const TRACK_INTERRUPT_DETECTION_DELAY = 5000

const logger = LogManager.createLogger('Participant', { critical: true })

export interface IParticipantEntity {
    addComponent(component: t.IComponent): void

    removeComponent(component: t.Constructor<t.IComponent>): void

    getComponent<T>(ctor: t.Constructor<T>): T | undefined

    lateUpdate(dt: number): void

    update(dt: number): void
}

type SpaceComponents = {
    entity?: IParticipantEntity

    // Use entity for getting typed component, these fields for reacting only (useEffect dep for example)
    htmlElementComponent?: unknown
    audibleRadiusComponent?: unknown
    personalRadiusComponent?: unknown
    invertZoomComponent?: unknown
    Component?: unknown
    panScreenHintComponent?: unknown
    arrowKeysHintComponent?: unknown
    transformComponent?: unknown
}

// make sure that if you add new fields here, you also add them in the
// isSameAsChange method in the SpatialState class below
export interface SpatialStateChange {
    audioZoneId: string | null | undefined
    distSq: number
    proximal: boolean
    inSameAudioZone: boolean
    video: boolean
}

class SpatialState {
    audioZoneId: string | null | undefined = null
    distSq = 0
    proximal = false
    inSameAudioZone = false

    video = {
        required: false,
        lastModified: 0,
    }

    constructor() {
        makeAutoObservable(this)
    }

    get audio() {
        return {
            required: this.proximal,
        }
    }

    isSameAsChange(change: SpatialStateChange) {
        return (
            this.audioZoneId === change.audioZoneId &&
            this.distSq === change.distSq &&
            this.proximal === change.proximal &&
            this.inSameAudioZone === change.inSameAudioZone &&
            this.video.required === change.video
        )
    }
}

export interface MediaRegistration {
    avId: string
    local: boolean
    media: ParticipantMedia
}

export class Participant implements IParticipant {
    rootStore?: RootStore

    id: string
    selectedAppId: string
    availability: t.AvailabilityStatus
    manualAvailability: t.AvailabilityStatus
    callShard: string
    x: number
    y: number
    mouseX: number
    mouseY: number
    name?: string
    locationType: t.Location

    /**
     * The room session id that the participant is in.
     */
    locationId: string

    status: string
    statusMetadata: t.IStatusMetadata
    ready: boolean
    navigationState: t.NavigationState
    role: t.Role
    isInViewport: boolean
    isVisibleInRibbon: boolean
    isSpeaker: boolean
    speaking: boolean
    /** Time at which this participant's `speaking` field was last modified */
    lastSpeakingTime?: Date
    allAudioMuted?: boolean
    focusModeActive: boolean
    opacity: number
    avNode?: HtmlPortalNode
    faceCentering: t.IFaceCentering
    screenManualPauseState: 'pause' | 'play' | 'unset'
    cursor: t.ICursor
    isSelected: boolean

    /**
     * The first item in `mediaRegistrations` should be the most up-to-date media
     * instance.
     *
     * Any time an avId is seen for the first time, its media is added to the front.
     * The old AV participant (aka the ghost) may continue to get updates for a
     * short while, but the media for the new avId should be kept at the front.
     *
     * In this way, we purposely support the same person having multiple AV
     * participants. This is necessary because although we try our best to prevent
     * the same person from joining the AV call multiple times, it may still happen.
     * The person could be spamming the join/leave call button, or open teamflow
     * in two tabs at the same time.
     *
     * If this happens, we assume that the AV participant that joined most recently
     * is the true one. However, if this AV participant leaves, we must be able
     * to fall back to the media for the person's other AV participant immediately,
     * rather than wait for the next track update on that other AV participant.
     *
     * Consider the following scenario: the same person opens Teamflow in two tabs,
     * and as a result, joins the AV call first as session A, then as session B.
     * Others' clients assume that session B is the true one, but session B turns
     * out to be short-lived (the person closes/gets kicked off that tab). The other
     * clients must then fall back to session A's media.
     *
     * @see media
     */
    mediaRegistrations: MediaRegistration[] = []

    /**
     * This should only be used in SpatialService. Normally, use `volume`.
     */
    spatialVolume = 0

    @observable roles: string[]
    networkQuality: t.INetworkQuality
    reaction: string
    // Components rendered by the ParticipantSpace, but are still
    // accessed via React.  Store in Mobx so we can react to the
    // entities changing (replacement) and update the DOM correctly.
    // NOTE: I think the space components should update the Mobx
    // state needed to render the participants differently over
    // the back-n-forth of who is responible for rendering/udpating
    spaceComponents: SpaceComponents = {}

    weather: string

    readonly spatial: SpatialState = new SpatialState()
    isRecording: boolean

    isOnShittyInternet: boolean

    dialerCallStatus: SalesFloorDialerCallStatus =
        SalesFloorDialerCallStatus.Hangup

    listeningToParticipantId = ''

    constructor(partObj: t.IParticipant, rootStore?: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            avNode: observable.ref,
            audioIsMuted: computed,
            videoIsOn: computed,
            shouldHearAudio: computed,
            audioVolume: computed,
            screenVolume: computed,
            bounds: computed,
            spaceComponents: observable.ref,
            freezeVideoTrack: computed,
            freezeScreenTrack: computed,
            screenShareApp: computed,
        })

        this.rootStore = rootStore

        this.id = partObj.id
        this.selectedAppId = partObj.selectedAppId
        this.availability = partObj.availability
        this.manualAvailability = partObj.manualAvailability
        this.callShard = partObj.callShard
        this.x = partObj.x
        this.y = partObj.y
        this.mouseX = 0
        this.mouseY = 0
        this.cursor = partObj.cursor
        this.name = partObj.name
        this.locationType = partObj.locationType
        this.locationId = partObj.locationId
        this.status = partObj.status
        this.statusMetadata = partObj.statusMetadata
        this.ready = partObj.ready
        this.navigationState = partObj.navigationState
        this.role = partObj.role
        this.isSpeaker = partObj.isSpeaker
        this.isInViewport = false
        this.isVisibleInRibbon = false
        this.speaking = partObj.speaking
        this.allAudioMuted = partObj.allAudioMuted
        this.focusModeActive = partObj.focusModeActive
        this.opacity = 1
        this.faceCentering = {
            scale: 1,
            offsetX: 0,
            offsetY: 0,
        }
        this.screenManualPauseState = 'unset'
        this.isSelected = false
        this.roles = []
        this.networkQuality = partObj.networkQuality
        this.reaction = partObj.reaction
        this.isRecording = partObj.isRecording
        this.weather = partObj.weather
        this.isOnShittyInternet = partObj.isOnShittyInternet
        this.dialerCallStatus = partObj.dialerCallStatus
        this.listeningToParticipantId = partObj.listeningToParticipantId
    }

    private get currentMediaRegistration() {
        const { mediaRegistrations } = this
        const localRegistrations = mediaRegistrations.filter((r) => r.local)
        const remoteRegistrations = mediaRegistrations.filter((r) => !r.local)

        // if both a local and remote media instance exists, the local one
        // is certainly the most up-to-date
        if (localRegistrations.length > 0) {
            return localRegistrations[0]
        }
        if (remoteRegistrations.length > 0) {
            return remoteRegistrations[0]
        }
        return undefined
    }

    get media() {
        return this.currentMediaRegistration?.media
    }

    get audioIsMuted() {
        return this.avIsConnected && !this.media?.audioIsOn
    }

    get videoIsOn() {
        return !!this.media?.videoIsOn
    }

    get freezeVideoTrack() {
        if (!this.media?.videoTrack) return false
        return (
            !this.spatial.video.required || this.media.videoState !== 'playable'
        )
    }

    get freezeScreenTrack() {
        const app = this.screenShareApp
        if (!app) return false
        return !!app.hiddenAt
    }

    updateSpatialVolume(value: number) {
        this.spatialVolume = clamp(value, 0, 1)
    }

    private get salesFloorAudioVolumeMultiplier() {
        if (
            !this.rootStore ||
            !this.rootStore.organization.isSalesFloor ||
            !this.rootStore.participants.localParticipant
        ) {
            // skip sales floor logic
            return 1
        }

        const { localParticipant } = this.rootStore.participants

        const localIsListeningToSomeone =
            !!localParticipant.listeningToParticipantId
        const localIsListeningToThis =
            localParticipant.listeningToParticipantId === this.id
        const thisIsListeningToLocal =
            this.listeningToParticipantId === localParticipant.id
        const localIsOnCall =
            localParticipant.dialerCallStatus ===
            SalesFloorDialerCallStatus.Answered
        const localIsDialing =
            localParticipant.dialerCallStatus ===
            SalesFloorDialerCallStatus.Dialing

        const thisIsListeningToSomeone = !!this.listeningToParticipantId
        const thisIsOnCall =
            this.dialerCallStatus === SalesFloorDialerCallStatus.Answered

        // two parties at play: local participant and this participant

        // always mute all audio for local if on a call
        if (localIsOnCall) {
            logger.info({
                action: 'Participant@SalesFloorAudioVolumeMultiplier',
                message: 'Participant salesfloor volume multiplier updated',
                value: 0,
                reason: { localIsOnCall },
            })

            return 0
        }

        // if local or participant are listening to each
        // other they should both be able to hear each other
        if (localIsListeningToThis || thisIsListeningToLocal) {
            logger.info({
                action: 'Participant@SalesFloorAudioVolumeMultiplier',
                message: 'Participant salesfloor volume multiplier updated',
                value: 1,
                reason: { localIsListeningToThis, thisIsListeningToLocal },
            })

            return 1
        }

        if (
            // mute if either party is listening to someone else
            localIsListeningToSomeone ||
            thisIsListeningToSomeone ||
            // mute this participant if they're on call
            thisIsOnCall
        ) {
            logger.info({
                action: 'Participant@SalesFloorAudioVolumeMultiplier',
                message: 'Participant salesfloor volume multiplier updated',
                value: 0,
                reason: {
                    localIsListeningToSomeone,
                    thisIsListeningToSomeone,
                    thisIsOnCall,
                },
            })

            return 0
        }

        if (localIsDialing) {
            const value = rootStore.settings.salesfloorCallDialingTFVolume

            logger.info({
                action: 'Participant@SalesFloorAudioVolumeMultiplier',
                message: 'Participant salesfloor volume multiplier updated',
                value,
                reason: { localIsDialing },
            })

            return value
        }

        logger.info({
            action: 'Participant@SalesFloorAudioVolumeMultiplier',
            message: 'Participant salesfloor volume multiplier updated',
            value: 1,
            reason: { default: true },
        })

        return 1
    }

    get audioVolume() {
        return this.spatialVolume * this.salesFloorAudioVolumeMultiplier
    }

    get shouldHearAudio() {
        return this.audioVolume > 0
    }

    get screenVolume() {
        if (
            !this.rootStore ||
            !this.rootStore.organization.isSalesFloor ||
            !this.rootStore.participants.localParticipant
        ) {
            // skip sales floor logic
            return 1
        }

        const { localParticipant } = this.rootStore.participants

        const localIsListeningToSomeone =
            !!localParticipant.listeningToParticipantId
        const localIsListeningToThis =
            localParticipant.listeningToParticipantId === this.id
        const thisIsListeningToLocal =
            this.listeningToParticipantId === localParticipant.id
        const localIsOnCall =
            localParticipant.dialerCallStatus ===
            SalesFloorDialerCallStatus.Answered

        const thisIsDialing =
            this.dialerCallStatus === SalesFloorDialerCallStatus.Dialing
        const thisIsOnCall =
            this.dialerCallStatus === SalesFloorDialerCallStatus.Answered

        // two parties at play: local participant and this participant

        // always mute all audio for local if on a call
        if (localIsOnCall) {
            logger.info({
                action: 'Participant@ScreenVolume',
                message: 'Participant screen volume updated',
                value: 0,
                reason: { localIsOnCall },
            })
            return 0
        }

        // if local or participant are listening to each
        // other they should both be able to hear each other
        if (localIsListeningToThis || thisIsListeningToLocal) {
            logger.info({
                action: 'Participant@ScreenVolume',
                message: 'Participant screen volume updated',
                value: 1,
                reason: { localIsListeningToThis, thisIsListeningToLocal },
            })
            return 1
        }

        if (
            // mute if local is listening to someone else
            // (note that if this participant is listening to someone else, local
            // should still hear their screen)
            localIsListeningToSomeone ||
            // mute this participant if they're on call
            thisIsOnCall
        ) {
            logger.info({
                action: 'Participant@ScreenVolume',
                message: 'Participant screen volume updated',
                value: 0,
                reason: { localIsListeningToSomeone, thisIsOnCall },
            })
            return 0
        }

        if (thisIsDialing) {
            // lower screen volume when dialing
            const value = this.rootStore.settings.salesfloorCallDialingTFVolume

            logger.info({
                action: 'Participant@ScreenVolume',
                message: 'Participant screen volume updated',
                value: value,
                reason: { thisIsDialing },
            })

            return value
        }

        logger.info({
            action: 'Participant@ScreenVolume',
            message: 'Participant screen volume updated',
            value: 1,
            reason: { default: true },
        })

        return 1
    }

    get screenShareApp() {
        if (!this.rootStore) return null

        const apps = this.rootStore.app.appById.values()
        for (const app of apps) {
            if (app.type === t.AppType.ScreenShare && app.owner === this.id) {
                return app
            }
        }

        return null
    }

    get avIsConnected() {
        return !!this.media
    }

    get bounds(): Readonly<{
        x: number
        y: number
        width: number
        height: number
        left: number
        top: number
        right: number
        bottom: number
    }> {
        const x = this.x - AVATAR_SIZE / 2
        const y = this.y - AVATAR_SIZE / 2

        return {
            x,
            y,
            width: AVATAR_SIZE,
            height: AVATAR_SIZE,
            top: y,
            right: x + AVATAR_SIZE,
            bottom: y + AVATAR_SIZE,
            left: x,
        }
    }

    get directionAngleRad() {
        const diffX = this.x - this.mouseX
        const diffY = this.y - this.mouseY
        return Math.atan2(diffY, diffX) + Math.PI
    }

    get screenAutoPaused() {
        if (this.id === this.rootStore?.users.localUserId) return false
        // auto pause if participant is not audible
        // (not proximal and not in same AZ)
        return !this.spatial.proximal && !this.spatial.inSameAudioZone
    }

    /**
     * Whether participant's screen share should be paused, either from manually
     * pressing play/pause button or automatic play/pause.
     */
    get shouldPauseScreen() {
        if (this.rootStore?.layout.fullScreenMode) return false

        if (this.screenManualPauseState !== 'unset') {
            return this.screenManualPauseState === 'pause'
        }

        // automatic pausing
        return this.screenAutoPaused
    }

    update(partial: Partial<Participant>) {
        Object.entries(partial).forEach(([key, value]) => {
            if (key !== 'id' && value !== (this as any)[key]) {
                ;(this as any)[key] = value

                if (key === 'speaking') {
                    this.lastSpeakingTime = new Date()
                }

                if (key === 'ready') {
                    logger.debug(
                        `Participant: update ${this.id} ready = ${value}`,
                        {
                            peer: this.id,
                            ready: value,
                        }
                    )
                }
            }
        })
    }

    /**
     * This should really be used only for logging. `media` should contain
     * everything needed for AV.
     *
     * @see media
     */
    get allAvIds() {
        return this.mediaRegistrations.map((registration) => registration.avId)
    }

    /**
     * This should really be used only for logging. `media` should contain
     * everything needed for AV.
     *
     * @see media
     */
    get currentAvId() {
        return this.currentMediaRegistration?.avId
    }

    /**
     * `persistTracks` is used in LiveKit, since LiveKit has its own logic
     * for stopping tracks.
     */
    updateMedia({
        avId,
        local,
        media,
    }: {
        avId: string
        local: boolean
        media: t.AVMedia
    }) {
        const logChange = (oldMedia?: ParticipantMedia) => {
            // deprecated metric
            if (
                oldMedia?.audioState !== media.audioState ||
                oldMedia?.videoState !== media.videoState ||
                oldMedia?.screenAudioState !== media.screenAudioState ||
                !Arrays.equal(
                    oldMedia?.screenVideoStates,
                    media.screenVideoStates
                )
            ) {
                /* @metric client-call_audio_track-interrupted,
                           client-call_video_track-interrupted,
                           client-call_audio_local-track-interrupted,
                           client-call_video_local-track-interrupted */
                logger.info(
                    `Participant: updateMedia ${this.id} (local: ${local}, avId: ${avId}) ` +
                        `audioState from ${oldMedia?.audioState} to ${media.audioState} / ` +
                        `videoState from ${oldMedia?.videoState} to ${media.videoState}`,
                    {
                        action: 'Participant@updateMedia',
                        peer: this.id,
                        local,
                        audio: {
                            previousValue: oldMedia?.audioState,
                            value: media.audioState,
                        },
                        video: {
                            previousValue: oldMedia?.videoState,
                            value: media.videoState,
                        },
                        screenAudio: {
                            previousValue: oldMedia?.screenAudioState,
                            value: media.screenAudioState,
                        },
                        screenVideo: {
                            previousValue: [
                                ...(oldMedia?.screenVideoStates ?? []),
                            ],
                            value: [...media.screenVideoStates],
                        },
                    }
                )
            }

            if (
                this.mediaRegistrations.length > 1 ||
                (this.mediaRegistrations.length === 1 &&
                    this.mediaRegistrations[0].avId !== avId)
            ) {
                /* @metric client_call_multiple_media_registrations */
                logger.warn(
                    `Participant: updateMedia ${this.id} media avId=${avId} ` +
                        `results in the participant having multiple media registrations`,
                    {
                        action: 'Call@MultipleMediaRegistrations',
                        peer: this.id,
                        avId,
                    }
                )
            }
        }

        const mediaIndex = this.mediaRegistrations.findIndex(
            (registration) => registration.avId === avId
        )

        if (mediaIndex === -1) {
            logChange()

            // haven't seen this avId before, so it must be the most recent;
            // add it to the front
            this.mediaRegistrations.unshift({
                avId,
                local,
                media: new ParticipantMedia(media),
            })
            return
        }

        // else: registration with avId exists, so update it
        const registration = this.mediaRegistrations[mediaIndex]
        logChange(registration.media)
        registration.local = local // just to be safe (shouldn't be needed)
        registration.media.update(media)
    }

    /**
     * `persistTracks` is used in LiveKit, since LiveKit has its own logic
     * for stopping tracks.
     */
    resetMedia({
        avId,
        persistTracks,
    }: {
        avId: string
        persistTracks?: boolean
    }) {
        const mediaIndex = this.mediaRegistrations.findIndex(
            (registration) => registration.avId === avId
        )

        if (mediaIndex === -1) return

        // else: remove matching registration
        const [registration] = this.mediaRegistrations.splice(mediaIndex, 1)
        if (!persistTracks) {
            const { media } = registration
            media.videoTrack?.stop()
            media.audioTrack?.stop()
            media.screenVideoTracks?.forEach((t) => t.stop())
            media.screenAudioTrack?.stop()
        }

        const remaining = this.mediaRegistrations.length
        logger.debug(
            `Participant: resetMedia ${this.id} (avId: ${avId}) ` +
                `leaves the participant with ${remaining} remaining media registrations`,
            {
                peer: this.id,
                avId,
                remaining,
            }
        )
    }

    resetAllMedia({ persistTracks }: { persistTracks?: boolean } = {}) {
        this.allAvIds.forEach((avId) => {
            this.resetMedia({ avId, persistTracks })
        })
    }

    updateAVNode(node: HtmlPortalNode | undefined) {
        this.avNode = node
    }

    updateComponents(components: SpaceComponents) {
        this.spaceComponents = components
    }

    updateRoles(roles: string[]) {
        this.roles = [...roles]
    }

    updateSpatial(change: SpatialStateChange) {
        if (this.spatial.isSameAsChange(change)) {
            return
        }
        if (change.proximal !== this.spatial.proximal) {
            logger.debug(
                `Participant: updateSpatial shouldBeAudible ${this.id} from ${this.spatial.proximal} ` +
                    `to ${change.proximal} audioZoneId = ${change.audioZoneId}`,
                {
                    peer: this.id,
                    shouldBeAudible: change.proximal,
                    audioZoneId: change.audioZoneId,
                }
            )
        }

        this.spatial.audioZoneId = change.audioZoneId
        this.spatial.distSq = change.distSq
        this.spatial.proximal = change.proximal
        this.spatial.inSameAudioZone = change.inSameAudioZone

        if (change.video != this.spatial.video.required) {
            this.spatial.video.required = change.video
            this.spatial.video.lastModified = Date.now()
        }
    }

    updateIsRecording(isRecording: boolean) {
        this.isRecording = isRecording
    }

    updateNetworkQuality(networkQualityUpdate: Partial<t.INetworkQuality>) {
        this.networkQuality = {
            ...this.networkQuality,
            ...networkQualityUpdate,
        }
    }

    resetScreenManualPaused() {
        this.screenManualPauseState = 'unset'
    }

    pauseScreenByContext() {
        if (this.screenAutoPaused) {
            // automatic behavior is to pause, so turn off manual override
            this.screenManualPauseState = 'unset'
        } else {
            // automatic behavior is to play, so must manually pause
            this.screenManualPauseState = 'pause'
        }
    }

    playScreenByContext() {
        if (this.screenAutoPaused) {
            // automatic behavior is to pause, so must manually play
            this.screenManualPauseState = 'play'
        } else {
            // automatic behavior is to play, so turn off manual override
            this.screenManualPauseState = 'unset'
        }
    }

    get showAsSpeaking() {
        return (
            this.speaking &&
            this.shouldHearAudio &&
            !this.rootStore?.audioVideo.softDisconnect
        )
    }

    get isNetworkUnstable() {
        return (
            this.networkQuality.packetLoss ||
            this.networkQuality.qualityThreshold === t.QualityThreshold.LOW
        )
    }

    get hasHighNetworkQuality() {
        return this.networkQuality.qualityThreshold === t.QualityThreshold.HIGH
    }
}

/**
 * Media state for a participant
 */
class ParticipantMedia implements t.AVMedia {
    videoTrack?: MediaStreamTrack
    audioTrack?: MediaStreamTrack
    screenVideoTracks: MediaStreamTrack[] = []
    screenAudioTrack?: MediaStreamTrack
    videoIsOn = false
    audioIsOn = false
    videoState: t.TrackState = 'off'
    audioState: t.TrackState = 'off'
    screenAudioState: t.TrackState = 'off'
    screenVideoStates: t.TrackState[] = []

    /**
     * This is a derived value from {@link videoState} and flags when the video state is
     * "interrupted" for more than a few seconds.
     */
    videoInterrupted = false

    private _videoInterruptedTimeout: NodeJS.Timeout | null = null

    constructor(media: t.AVMedia) {
        makeAutoObservable(this, {
            videoTrack: observable.ref,
            audioTrack: observable.ref,
        })

        Object.assign(this, media)
    }

    updateVideoState(state: t.TrackState): void {
        if (this.videoState === state) return
        this.videoState = state

        if (this._videoInterruptedTimeout) {
            clearTimeout(this._videoInterruptedTimeout)
        }

        if (state === 'interrupted') {
            this._videoInterruptedTimeout = setTimeout(() => {
                runInAction(() => {
                    this.videoInterrupted = true
                })
            }, TRACK_INTERRUPT_DETECTION_DELAY)
        } else {
            this.videoInterrupted = false
        }
    }

    update(partial: Partial<ParticipantMedia>) {
        Object.entries(partial).forEach(([k, v]) => {
            const key = k as keyof ParticipantMedia
            const value = v as ParticipantMedia[typeof key]

            if (key === 'videoState') {
                this.updateVideoState(value as t.TrackState)
                return
            }

            const current = this[key]
            if (current !== value) {
                ;(this as any)[key] = value
            }
        })
    }
}
