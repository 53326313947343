import { retry } from '@teamflow/lib'
import * as t from '@teamflow/types'
import { Role } from '@teamflow/types'

import { CustomColyseusClient } from '../customColyseusClient'

import { ConnectionTransition } from './enums'
import { VerseFullError } from './errors'

import type { State, StateMachine } from './types'
import type { RoomAvailable } from 'colyseus.js'

type Data = {
    existingRoom: RoomAvailable
    options: t.JoinOptions
}

/**
 * If you are not a guest AND there are more members than maximum users allowed
 * AND those members are all logged in then refuse to join.
 *
 * Or if you are a guest and there are more than max guests allowed.
 * @param userRole The role of the user attempting to join the verse
 * @param membersCount The number of members in the organization
 * @param maxUsers The maximum number of users allowed in the organization
 * @param connectedClientCount The number of users currently connected to the verse
 * @param guestsCount The number of guests currently online
 * @param maxGuests The maximum number of guests allowed in the organization
 */
export function isFull(
    userRole: Role,
    membersCount: number,
    maxUsers: number,
    connectedClientCount: number,
    guestsCount: number,
    maxGuests: number
) {
    return (
        (userRole > Role.GUEST &&
            membersCount > maxUsers &&
            connectedClientCount - guestsCount >= maxUsers) ||
        (userRole === Role.GUEST && guestsCount > maxGuests)
    )
}

/** @group Connection States */
export default class JoinRoom implements State {
    private readonly client: CustomColyseusClient

    constructor(client: CustomColyseusClient) {
        this.client = client
    }

    async onEnter(machine: StateMachine, { existingRoom, options }: Data) {
        const {
            orgId,
            maxUsers = Infinity,
            membersCount = 0,
            guestsCount = 0,
            role,
            maxGuests = Infinity,
        } = options

        const shouldTestRTOnJoinError =
            process.env.NEXT_PUBLIC_APP_ENV !== 'production' &&
            window.tfBotAutomation &&
            !!window.tfBotAutomation.shouldTestRTOnJoinError

        if (
            isFull(
                role,
                membersCount,
                maxUsers,
                existingRoom.clients,
                guestsCount,
                maxGuests
            )
        ) {
            throw new VerseFullError()
        }

        // we found an existing room so try to join it
        const room = await retry(async () => {
            return this.client.joinById<t.IVerseState>(orgId, {
                ...options,
                shouldTestRTOnJoinError,
                /**
                 * Hotfix for an error when joining and checking join options schema in the RT server.
                 * @see https://www.notion.so/teamflowhq/Realtime-errors-while-joining-915558e0b904402da7356b69143ec23d
                 */
                roomId: options.roomId === '' ? undefined : options.roomId,
            })
        }, 5)

        if (room) {
            await machine.runTransition(ConnectionTransition.Connected, {
                room,
            })
        } else {
            await machine.runTransition(ConnectionTransition.Failed, options)
        }
    }
}
