import admin from './admin'
import authorize from './authorize'
import common from './common'
import confirmEmail from './confirm-email'
import contextMenu from './contextMenu'
import downgrade from './downgrade'
import eventCapacity from './eventCapacity'
import full from './full'
import join from './join'
import login from './login'
import maintenance from './maintenance'
import passwordreset from './passwordreset'
import ready from './ready'
import reconnect from './reconnect'
import redirect from './redirect'
import rename from './rename'
import resetPassword from './reset-password'
import rooms from './rooms'
import salesfloor from './salesfloor'
import signup from './signup'
import slackError from './slack-error'
import switchOrganization from './switchOrganization'
import trials from './trials'
import unsubscribeEmailNotifications from './unsubscribe-email-notifications'
import updatePayment from './updatePayment'
import upgrade from './upgrade'

const allTKeys = {
    common,
    confirmEmail,
    contextMenu,
    signup,
    login,
    passwordreset,
    resetPassword,
    maintenance,
    ready,
    full,
    reconnect,
    redirect,
    slackError,
    switchOrganization,
    updatePayment,
    upgrade,
    eventCapacity,
    authorize,
    join,
    admin,
    trials,
    rename,
    downgrade,
    unsubscribeEmailNotifications,
    rooms,
    salesfloor,
}

export default allTKeys
