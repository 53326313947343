import { request } from '../request'

const google = {
    users: ({
        accessToken,
        domain,
    }: {
        accessToken?: string
        domain?: string
    }) => {
        const params = new URLSearchParams()
        if (accessToken) params.append('accessToken', accessToken)
        if (domain) params.append('domain', domain)

        return request<'GET /api/auth/google/users'>(
            'GET',
            `/api/google/users?${params.toString()}`
        )
    },
}

export default {
    google,
}
