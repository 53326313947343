import rootStore from '@teamflow/store'

import { calculateLeftWidth } from '../constants'

export const getLeftWidth = () =>
    calculateLeftWidth(
        rootStore.layout.sidebarExpanded,
        rootStore.layout.customizeMode,
        rootStore.layout.customizePanelWidth
    )
