import { keyframes } from '@stitches/react'

import { Box, Color } from '@teamflow/design'

const spin = keyframes({
    '0%': {
        transform: 'rotate(0deg)',
    },
    '100%': {
        transform: 'rotate(360deg)',
    },
})

interface Props {
    thickness?: string
    speed?: string
    color?: Color
    emptyColor?: string
    diameter?: number
}

export const Spinner = ({
    thickness = '2px',
    speed = '0.45s',
    color = 'neutral80',
    emptyColor = 'transparent',
    diameter = 100,
}: Props) => {
    return (
        <Box
            borderColor={color}
            __cssOverrides={{
                width: diameter,
                height: diameter,
                display: 'inline-block',
                borderStyle: 'solid',
                borderRadius: '99999px',
                borderWidth: thickness,
                borderBottomColor: emptyColor,
                borderLeftColor: emptyColor,
                animation: `${spin} ${speed} linear infinite`,
            }}
        />
    )
}
