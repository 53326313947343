import Router from 'next/router'

import { generateShortUuid, LogManager, result } from '@teamflow/lib'

const MAX_BODY_SIZE = 1024 // 1 kilobyte. For larger payloads, refer to server-side logs instead

export const Intercept = {
    fetch: () => {
        if (typeof window === 'undefined') return
        const _logger = LogManager.createLogger('intercept/network')
        const fetch = window.fetch
        const pathsIgnored = [
            process.env.NEXT_PUBLIC_OPENTELEMETRY_COLLECTOR_URL,
            'log',
            'ingest.teamflowhq',
            '/services/currentTime',
        ].filter((s): s is string => !!s)
        const pathsSensitive = ['/login', 'password']
        const headersIgnored = ['x-api-key']
        const requestFilter = (url: string) => {
            return pathsIgnored.every((ignored) => !url.includes(ignored))
        }
        const requestSensitive = (url: string) => {
            return pathsSensitive.every((sensitive) => !url.includes(sensitive))
        }
        const extractOrigin = (url: string) => {
            try {
                return new URL(url).origin
            } catch {
                return null
            }
        }

        window.fetch = async (...args: Parameters<typeof fetch>) => {
            const logger = _logger.child({
                sequence: 'fetch_' + generateShortUuid(),
            })
            const [resource, config] = args
            const url =
                typeof resource === 'string'
                    ? resource
                    : resource instanceof URL
                    ? resource.href
                    : resource.url
            const logged = requestFilter(url)

            if (logged) {
                logger.info({
                    action: 'Fetch@Request',
                    url,
                    headers: Object.fromEntries(
                        Object.entries(config?.headers ?? {}).filter(
                            ([key]) =>
                                !headersIgnored.includes(key.toLowerCase())
                        )
                    ),
                    method: config?.method ?? 'GET',
                    body: requestSensitive(url)
                        ? config?.body ?? null
                        : '**sensitive**',
                })
            }

            try {
                const start = performance.now()
                const response = await fetch(...args)
                const end = performance.now()

                if (logged) {
                    const [, text] = await result(response.clone().text())
                    const [, json] = await result(
                        text
                            ? async () => JSON.parse(text)
                            : Promise.resolve(null)
                    )
                    logger.info({
                        action: 'Fetch@Response',
                        body: json
                            ? json
                            : text && text.length < MAX_BODY_SIZE
                            ? text
                            : text
                            ? '**too_long**'
                            : null,
                        status: response.status,
                        statusText: response.statusText,
                        //for log-based metric
                        origin: extractOrigin(url),
                        responseTime: end - start,
                    })
                }

                return response
            } catch (e) {
                if (logged) {
                    logger.error({
                        action: 'Fetch@Response',
                        error: e instanceof Error ? e.message : e,
                    })
                }
                throw e
            }
        }
    },
    navigation: () => {
        if (!Router.events) return // Unit tests

        const logger = LogManager.createLogger('intercept/navigation')

        Router.events.on('routeChangeStart', (url, { shallow }) => {
            logger.info({
                action: 'Router@routeChangeStart',
                message: 'Navigating to ' + url,
                url,
                shallow,
            })
        })

        Router.events.on('routeChangeComplete', (url, { shallow }) => {
            logger.info({
                action: 'Router@routeChangeComplete',
                message: 'Navigated to' + url,
                url,
                shallow,
            })
        })
    },
    events: () => {
        if (typeof window !== 'object') return

        const logger = LogManager.createLogger('intercept/events')
        const windowEvents: (
            | [string]
            | [string, { detailed?: boolean; debug?: true }]
        )[] = [
            ['error', { detailed: true }],
            ['online'],
            ['offline'],
            ['orientationchange'],
            ['messageerror', { detailed: true }],
            ['onunhandledrejection', { detailed: true }],
            ['popstate'],
            ['unload'],
        ]

        for (const [event, configuration] of windowEvents) {
            window.addEventListener(event, (e) => {
                logger[configuration?.debug ? 'debug' : 'info']({
                    action: `window.on${event}`,
                    event: configuration?.detailed
                        ? e instanceof Event
                            ? {
                                  // @ts-expect-error type isn't enumerable sometimes
                                  type: e.type,
                                  ...e,
                              }
                            : e
                        : '**skipped**',
                })
            })
        }
    },
}
