import * as t from '@teamflow/types'

/**
 * DO NOT USE THIS DIRECTLY. USE {@link UserDispatchService.dispatchPositionChange}
 *
 * @group Commands
 */
export default class PositionChangedCommand
    implements t.ICommand<t.IPositionChanged>
{
    readonly messageType = t.ClientMessage.PositionChanged
    private x = 0
    private y = 0
    private motionVelocity = 0
    private motionBegin = 0

    constructor(x: number, y: number, velocity = 0) {
        this.x = x
        this.y = y
        this.motionVelocity = velocity
        this.motionBegin = new Date().getUTCMilliseconds()
    }

    execute() {
        return {
            id: '', // server fills this for us :P
            x: this.x,
            y: this.y,
            motionVelocity: this.motionVelocity,
            motionBegin: this.motionBegin,
            motionFrom: { x: 0, y: 0 }, // server fills this for us :P
        }
    }
}
