import { Role } from '@teamflow/types'

import { createRequest, request } from '../request'

const connect = ({
    apiKey,
    userOrgId,
}: {
    apiKey: string
    userOrgId: string
}) =>
    request<'POST /api/userOrg/:userOrgId/connect'>(
        'POST',
        `/api/userOrg/${userOrgId}/connect`,
        { headers: { 'x-api-key': apiKey } }
    )

const disconnectGuest = ({
    apiKey,
    userOrgId,
}: {
    apiKey: string
    userOrgId: string
}) =>
    request<'DELETE /api/userOrg/disconnectGuest/:userOrgId'>(
        'DELETE',
        `/api/userOrg/disconnect/${userOrgId}`,
        { headers: { 'x-api-key': apiKey } }
    )

const chats = {
    list: () =>
        request<'POST /api/userOrg/chats/get'>(
            'POST',
            '/api/userOrg/chats/get'
        ),
    create: createRequest<'POST /api/userOrg/chats'>(
        'POST',
        '/api/userOrg/chats'
    ),
    update: ({
        chatId,
        ...body
    }: {
        chatId: string
        badgeCount?: number
        hasUnreads?: boolean
        timestamp: number
        showNotifications?: boolean
        badgeUpdateSenderId?: string
        badgeUpdateProviderConversationId?: string
    }) =>
        request<'PUT /api/userOrg/chats/:chatId'>(
            'PUT',
            `/api/userOrg/chats/${chatId}`,
            { body }
        ),
    chat: {
        get: ({ chatProviderId }: { chatProviderId: string }) =>
            request<'GET /api/userOrg/chats/:chatId(*)'>(
                'GET',
                `/api/userOrg/chats/${chatProviderId}`
            ),
        updateRecipients: ({ chatId }: { chatId: string }) =>
            request<'POST /api/userOrg/chats/:chatId(*)/updateRecipients'>(
                'POST',
                `/api/userOrg/chats/${chatId}/updateRecipients`
            ),
    },
}

const all = createRequest<'GET /api/userOrg/all'>('GET', '/api/userOrg/all')

const search = ({ email }: { email: string }) =>
    request<'GET /api/userOrg/search'>(
        'GET',
        `/api/userOrg/search?email=${email}`
    )

const getDailyMeetingToken = createRequest<'POST /api/userOrg/meetingToken'>(
    'POST',
    '/api/userOrg/meetingToken'
)

const getLiveKitMeetingToken = createRequest<'POST /api/userOrg/livekitToken'>(
    'POST',
    '/api/userOrg/livekitToken'
)

const updateUserHomeLocation = createRequest<'PUT /api/userOrg/home'>(
    'PUT',
    '/api/userOrg/home'
)

const updateAdmin = createRequest<'PUT /api/userOrg/admin'>(
    'PUT',
    '/api/userOrg/admin'
)

const update = createRequest<'PUT /api/userOrg'>('PUT', '/api/userOrg')

const updateStatus = ({
    apiKey,
    userOrgId,
    availabilityStatus,
}: {
    apiKey: string
    userOrgId: string
    availabilityStatus: string
}) =>
    request<'PUT /api/userOrg/:id/status'>(
        'PUT',
        `/api/userOrg/${userOrgId}/status`,
        { body: { availabilityStatus }, headers: { 'x-api-key': apiKey } }
    )

const updateAssignedRoom = ({
    userOrgId,
    assignedRoomId,
}: {
    userOrgId: string
    assignedRoomId: string
}) =>
    request<'PUT /api/userOrg/:id/assignedRoom'>(
        'PUT',
        `/api/userOrg/${userOrgId}/assignedRoom`,
        { body: { assignedRoomId } }
    )

const setFloorPermission = ({ userOrgId }: { userOrgId: string }) =>
    request<'POST /api/userOrg/:id/floorPermission'>(
        'POST',
        `/api/userOrg/${userOrgId}/floorPermission`
    )

const role = {
    create: ({ userOrgId, roleId }: { userOrgId: string; roleId: string }) =>
        request<'PUT /api/userOrg/:id/role/:roleId'>(
            'PUT',
            `/api/userOrg/${userOrgId}/role/${roleId}`
        ),
    delete: ({ userOrgId, roleId }: { userOrgId: string; roleId: string }) =>
        request<'DELETE /api/userOrg/:id/role/:roleId'>(
            'DELETE',
            `/api/userOrg/${userOrgId}/role/${roleId}`
        ),
    setRoleEnum: ({
        userOrgId,
        roleEnum,
    }: {
        userOrgId: string
        roleEnum: Role
    }) =>
        request<'PUT /api/userOrg/:id/roleEnum/:roleEnum'>(
            'PUT',
            `/api/userOrg/${userOrgId}/roleEnum/${roleEnum}`
        ),
}

const permissions = async ({ userOrgId }: { userOrgId: string }) => {
    const res = await request<'GET /api/userOrg/:id/permissions'>(
        'GET',
        `/api/userOrg/${userOrgId}/permissions`
    )
    if (res.data) {
        const [allow, deny, roles, role] = res.data
        return { data: { allow, deny, roles, role }, error: undefined }
    } else {
        return res
    }
}

const invitation = {
    create: ({
        userOrgId,
        inviteCode,
    }: {
        userOrgId: string
        inviteCode: string
    }) =>
        request<'PUT /api/userOrg/:id/invitation/:inviteCode'>(
            'PUT',
            `/api/userOrg/${userOrgId}/invitation/${inviteCode}`
        ),
}

const deleteUserOrg = ({ userOrgId }: { userOrgId: string }) =>
    request<'DELETE /api/userOrg/:userOrgId'>(
        'DELETE',
        `/api/userOrg/${userOrgId}`
    )

const adminDelete = ({ userOrgId }: { userOrgId: string }) =>
    request<'DELETE /api/userOrg/:userOrgId/adminDelete'>(
        'DELETE',
        `/api/userOrg/${userOrgId}/adminDelete`
    )

const undelete = ({ userOrgId }: { userOrgId: string }) =>
    request<'POST /api/userOrg/:userOrgId/undelete'>(
        'POST',
        `/api/userOrg/${userOrgId}/undelete`
    )

const tags = {
    list: ({ userOrgId }: { userOrgId: string }) =>
        request<'GET /api/userOrg/:id/tags'>(
            'GET',
            `/api/userOrg/${userOrgId}/tags`
        ),
    get: ({ userOrgId, tagName }: { userOrgId: string; tagName: string }) =>
        request<'GET /api/userOrg/:id/tags/:tagName'>(
            'GET',
            `/api/userOrg/${userOrgId}/tags/${tagName}`
        ),
    create: ({
        userOrgId,
        name,
        userId,
        apiKey,
    }: {
        userOrgId: string
        name: string
        userId: string
        apiKey: string
    }) =>
        request<'POST /api/userOrg/:id/tags'>(
            'POST',
            `/api/userOrg/${userOrgId}/tags`,
            { body: { name, userId }, headers: { 'x-api-key': apiKey } }
        ),
}

const createUserOrg = createRequest<'POST /api/userOrg'>('POST', '/api/userOrg')

export default {
    connect,
    disconnectGuest,
    chats,
    all,
    search,
    updateUserHomeLocation,
    getDailyMeetingToken,
    getLiveKitMeetingToken,
    updateAdmin,
    update,
    updateStatus,
    updateAssignedRoom,
    setFloorPermission,
    role,
    permissions,
    invitation,
    delete: deleteUserOrg,
    adminDelete,
    undelete,
    tags,
    createUserOrg,
}
