// reset.css uses this to remove focus styles for non-keyboard interactions
import 'focus-visible/dist/focus-visible'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react'
import { appWithTranslation } from 'next-i18next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

import {
    apiConfig,
    DONOTUSEORYOUWILLBEFIRED_testTokenLoop,
} from '@teamflow/client-api'
import { ThemeProvider } from '@teamflow/design'
import {
    LEAVE_TIME,
    LEAVE_PATH,
    load,
    save,
    remove,
    isElectron,
    LogManager,
    ID_REFRESH_TOKEN_NAME,
} from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { TitleBarMenuWrapper } from '@teamflow/web/src/components/common'
import Page from '@teamflow/web/src/components/common/_page'
import Appzi from '@teamflow/web/src/components/verse/Appzi'
import VerseErrorPage from '@teamflow/web/src/components/verse/VerseErrorPage'
import { ID_TOKEN_NAME } from '@teamflow/web/src/constants'
import {
    track,
    USER_REFRESHED,
    USER_PERFORMANCE_NAVIGATION,
} from '@teamflow/web/src/helpers/analytics'
import { trackSecondsInMeeting } from '@teamflow/web/src/helpers/meeting'
import { isForcedReload } from '@teamflow/web/src/helpers/reload'
// this import must happen as soon as possible - as it will start instrumentation
import '@teamflow/web/src/observability/tracing'
import { ElectronService } from '@teamflow/web/src/verse/services/ElectronService'

import type { AppComponentProps } from '../../src/types/nextPageComponent'

const useRefreshTracking = () => {
    useEffect(() => {
        const handleBeforeUnload = () => {
            if (rootStore.commons.userInMeetingRoom) {
                trackSecondsInMeeting()
            }
            // detecting a refresh here by storing time and then
            // checking the diff upon loading the app again
            if (!isForcedReload()) {
                save(LEAVE_TIME, Date.now().toString())
                save(LEAVE_PATH, window.location.pathname)
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        // justification of any: this code is not critical
        const navigationEntry: any =
            window?.performance?.getEntriesByType?.('navigation')?.[0]
        if (navigationEntry && navigationEntry.type) {
            const reloaded = navigationEntry.type === 'reload'
            track(USER_PERFORMANCE_NAVIGATION, {
                reloaded,
            })
        }

        const leaveTime = load(LEAVE_TIME)
        const leaveURL = load(LEAVE_PATH)
        if (leaveTime) {
            const leftAt = parseInt(leaveTime)
            const now = Date.now()
            const diff = now - leftAt

            if (diff <= 15000 && leaveURL === window.location.pathname) {
                // rejoined within 15s; consider it a refresh
                track(USER_REFRESHED, {
                    diffInMS: diff,
                })
            }

            remove(LEAVE_TIME)
        }
    }, [])
}

const useBeforeUnload = () => {
    useEffect(() => {
        const handleBeforeUnload = () => {
            rootStore.commons.setReloading()
            ElectronService.resetTitleBarColorOverride()
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])
}

const saveAccessToken = (urlSearchParams: URLSearchParams) => {
    const idTokenRaw = urlSearchParams.get('idToken')
    const idToken = typeof idTokenRaw === 'string' ? idTokenRaw : null
    if (idToken) window.localStorage.setItem(ID_TOKEN_NAME, idToken)
}

const saveRefreshToken = (urlSearchParams: URLSearchParams) => {
    const refreshTokenRaw = urlSearchParams.get('refreshToken')
    const refreshToken =
        typeof refreshTokenRaw === 'string' ? refreshTokenRaw : null
    if (refreshToken)
        window.localStorage.setItem(ID_REFRESH_TOKEN_NAME, refreshToken)
}

const useAuthToken = () => {
    // Note: Don't use useEffect since we need to guarantee this logic executes
    // before everything else!
    //
    // useEffect runs after the components render. In addition, a parent component's
    // useEffect runs after its child components' effects.
    //
    // For example, the useLoadLocalUser effect in <Page /> needs this due to
    // authentication requirements.

    const didExecute = useRef<boolean>(false)
    if (didExecute.current) return
    if (typeof window === 'undefined') return // server-side

    // Don't use useQueryParams as it isn't synchronous (uses setState which
    // means the first render will occur without idToken in local storage!)
    const urlSearchParams = new URLSearchParams(window.location.search)
    saveAccessToken(urlSearchParams)
    saveRefreshToken(urlSearchParams)
    didExecute.current = true
}

const useOnTokenExpired = () => {
    const router = useRouter()
    const [tokenExpired, setTokenExpired] = useState(false)

    apiConfig.onTokenExpired(() => {
        if (window.localStorage.getItem(ID_TOKEN_NAME)) setTokenExpired(true)
    })

    useEffect(() => {
        if (tokenExpired && window.localStorage.getItem(ID_TOKEN_NAME)) {
            LogManager.ui.info('[JWT] Token expired, logging out...')
            window.localStorage.removeItem(ID_TOKEN_NAME)
            setTokenExpired(false)
            void router.replace('/logout')
        }
    }, [router, tokenExpired])

    useEffect(() => {
        const ptr = DONOTUSEORYOUWILLBEFIRED_testTokenLoop()

        return () => {
            clearTimeout(ptr.handle)
        }
    }, [])
}

/**
 * This is a temporary measure to prevent some
 * edge cases using the back/forward mouse buttons
 * on the electron app.
 * Later when we have better history stack integration
 * we can rethink this.
 */
const useDisableBackForward = () => {
    const disableButtons = isElectron()
    if (disableButtons) {
        window.addEventListener('mouseup', (e) => {
            if (e.button === 3 || e.button === 4) {
                e.preventDefault()
            }
        })
    }
}

const beforeTopLevelCapture = (scope: Sentry.Scope) => {
    scope.setTag('is_top_level_error', true)
}

/**
 * Using in most of our pages, this check for the auth token,
 * adds a global error boundary, and adds a few providers.
 */
export const DefaultLayout = observer(
    ({ children, Component, pageProps, router }: AppComponentProps) => {
        useRefreshTracking()
        useBeforeUnload()
        useAuthToken()
        useOnTokenExpired()
        useDisableBackForward()

        const lite = rootStore.settings.lite

        return (
            <ThemeProvider lite={lite}>
                <Page router={router} acceptLanguage={pageProps.acceptLanguage}>
                    <>
                        <Head>
                            <title>Teamflow - Feel like a team again</title>
                            <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                            />
                        </Head>
                        {Component.skipAppzi ? null : <Appzi />}
                        <Sentry.ErrorBoundary
                            fallback={<VerseErrorPage />}
                            showDialog={
                                process.env.NEXT_PUBLIC_APP_ENV === 'production'
                            }
                            beforeCapture={beforeTopLevelCapture}
                        >
                            <TitleBarMenuWrapper>
                                {children}
                            </TitleBarMenuWrapper>
                        </Sentry.ErrorBoundary>
                    </>
                </Page>
            </ThemeProvider>
        )
    }
)

export default appWithTranslation(DefaultLayout)
