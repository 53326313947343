export default {
    notFound: 'notFound',
    orgName: 'orgName',
    searchName: 'searchName',
    searchId: 'searchId',
    newOrgName: 'newOrgName',
    important: 'important',
    afterRenamingAnOrgGoToColyseusViaTheLinkBelowAndDisposeTheRoom:
        'afterRenamingAnOrgGoToColyseusViaTheLinkBelowAndDisposeTheRoom',
    renameOrg: 'renameOrg',
}
