import _ from 'lodash'

import { api } from '@teamflow/client-api'
import * as t from '@teamflow/types'

// Assets nested by
// -> category
// -> subcategory
// -> group
export type AssetTree = Record<
    string,
    Record<string, Record<string, Array<t.IAsset>>>
>

function buildAssetTree(catalog: ReadonlyArray<t.IAsset>): AssetTree {
    return _.chain(catalog)
        .groupBy((asset: t.IAsset) => asset.category || 'Others')
        .mapValues((assets) =>
            _.chain(assets)
                .groupBy((asset: t.IAsset) => asset.subcategory || 'Others')
                .mapValues((assets) =>
                    _.groupBy(assets, (asset) => asset.group || 'Others')
                )
                .value()
        )
        .value()
}

// Ensure asset is complete
function filterAsset(asset: Partial<t.IAsset>): boolean {
    return (
        typeof asset._id === 'string' &&
        typeof asset.name === 'string' &&
        typeof asset.url === 'string'
    )
}

export async function fetchAssetShowcase(orgId: string): Promise<AssetTree> {
    const { data } = await api.organization.catalog.getAsset({
        orgId,
    })
    if (!data || !Array.isArray(data)) return {}
    return buildAssetTree(data.filter(filterAsset))
}
