import rootStore from '../'

export function retrieveOldPrefs() {
    try {
        const userId = rootStore.users.localUserId
        if (userId) {
            const prefsItem = localStorage.getItem(userId)
            if (prefsItem) {
                const prefsOb = JSON.parse(prefsItem)
                return new Map<string, { value: number | boolean | string }>(
                    prefsOb
                )
            }
        }
    } catch {
        /* noop */
    }
    return null
}

export function overwriteOldPrefs(
    prefs: Map<string, { value: number | boolean | string }>
) {
    try {
        const userId = rootStore.users.localUserId
        if (userId) {
            localStorage.setItem(userId, JSON.stringify([...prefs]))
        }
    } catch {
        /* noop */
    }
}
