import { styled } from '@stitches/react'
import { PropsWithChildren, useState, CSSProperties } from 'react'

import { usePreventWheel } from '@teamflow/design'

import { SIDEBAR_SLIM_WIDTH } from '../../constants'

const StyledMenuForSidebar = styled('div', {
    width: SIDEBAR_SLIM_WIDTH,
    backgroundColor: 'white',
    border: '0.5px solid rgba(0, 0, 0, 0.08)',
    boxShadow:
        '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 0.5px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
})

export default function MenuForSidebar({
    children,
    style,
    testId,
}: PropsWithChildren<{ style?: CSSProperties; testId?: string }>) {
    const [ref, setRef] = useState<HTMLDivElement | null>(null)

    usePreventWheel(ref)

    return (
        <StyledMenuForSidebar ref={setRef} style={style} data-testid={testId}>
            {children}
        </StyledMenuForSidebar>
    )
}
