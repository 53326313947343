import * as t from '@teamflow/types'

type Data = t.PayloadWithLocationId<Partial<t.IPortal>>

/** @group Commands */
export default class PortalCommand implements t.ICommand<Data> {
    readonly messageType = t.ClientMessage.Portal

    private data: Data

    constructor(data: Data) {
        this.data = data
    }

    execute() {
        return this.data
    }

    // This does not (and should not) have a localExecute to update the local RT state, for reasoning see comment inside the localExecute of SaveChangeCommand
}
