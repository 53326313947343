import { makeAutoObservable } from 'mobx'

import { UIStore } from '../uiStore'

export class Minimap {
    readonly uiStore: UIStore

    mapPulse = false
    centerPulse = false
    zoomOutPulse = false

    constructor(uiStore: UIStore) {
        this.uiStore = uiStore

        makeAutoObservable(this, { uiStore: false })
    }

    setMapPulse(enabled: boolean) {
        this.mapPulse = enabled
    }

    setCenterPulse(enabled: boolean) {
        this.centerPulse = enabled
    }

    setZoomOutPulse(enabled: boolean) {
        this.zoomOutPulse = enabled
    }
}
