import type { ILogger } from '@teamflow/types'
import { ConnectionDroppedCode } from '@teamflow/types'

import Events from '../events'
import type { ISharedState } from '../sharedState'

import { ConnectionTransition } from './enums'
import { State, StateMachine } from './types'

import type EventEmitter from 'eventemitter3'

/** @group Connection States */
export default class Dropped implements State {
    private readonly sharedState: ISharedState
    private readonly events: EventEmitter
    private readonly logger?: ILogger

    constructor(
        sharedState: ISharedState,
        events: EventEmitter,
        logger?: ILogger
    ) {
        this.sharedState = sharedState
        this.events = events
        this.logger = logger
    }

    async onEnter(
        machine: StateMachine,
        data: { code: ConnectionDroppedCode; deployment: boolean }
    ) {
        const {
            code = ConnectionDroppedCode.PolicyViolation,
            deployment = false,
        } = data

        if (code >= ConnectionDroppedCode.CloseNormal) {
            const room = this.sharedState.activeRoom
            const unsentBytes = (
                room?.connection.transport as undefined | { ws: WebSocket }
            )?.ws?.bufferedAmount

            if (unsentBytes) {
                this.logger?.error(
                    `Colyseus connection dropped without consent! ${unsentBytes} bytes lost indefinitely!`
                )
            }
        }

        await machine.runTransition(ConnectionTransition.Disconnected, {
            consented: code <= ConnectionDroppedCode.CloseNormal,
            code,
            deployment,
        })

        this.events.emit(Events.OnDropped, code)
    }
}
