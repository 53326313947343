import React from 'react'

import { currentTimeForTimezone } from '@teamflow/lib/src/date/timezoneFormat'

export function useLocalTime(timezone: string): string
export function useLocalTime(timezone: string | null): string | null

export function useLocalTime(timezone: string | null): string | null {
    const [time, setTime] = React.useState<string | null>(
        timezone ? currentTimeForTimezone(timezone) : null
    )

    React.useEffect(() => {
        // Set time immediately in case the timezone itself changed!
        setTime(timezone ? currentTimeForTimezone(timezone) : null)

        if (typeof timezone === 'string') {
            const updateTimeTextInterval = setInterval(() => {
                setTime(currentTimeForTimezone(timezone))
            }, 5000)

            return () => {
                clearInterval(updateTimeTextInterval)
            }
        }
    }, [timezone])

    return time
}
