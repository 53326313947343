import { waitForSeconds } from '@teamflow/lib'
import {
    IVerseSpaceOp,
    PersistedVerseProperties,
    TeamflowRestApiSpecification as ApiSpec,
} from '@teamflow/types'

import { request } from '../request'

const lock = ({ orgId }: { orgId: string }) =>
    request<'POST /api/verse/locks/:orgId'>('POST', `/api/verse/locks/${orgId}`)

const unlock = ({ orgId, apiKey }: { orgId: string; apiKey: string }) =>
    request<'DELETE /api/verse/locks/:orgId'>(
        'DELETE',
        `/api/verse/locks/${orgId}`,
        {
            headers: {
                'x-api-key': apiKey,
            },
        }
    )

const space = {
    deleteAllTiles: ({
        orgId,
        spaceId,
        apiKey,
    }: {
        orgId: string
        spaceId: string
        apiKey: string
    }) =>
        request<'DELETE /api/verse/:orgId/space/:spaceId/floorTiles'>(
            'DELETE',
            `/api/verse/${orgId}/space/${spaceId}/floorTiles`,
            {
                headers: {
                    'x-api-key': apiKey,
                },
            }
        ),
    post: ({
        orgId,
        spaceId,
        floorTiles,
        apiKey,
    }: {
        orgId: string
        spaceId: string
        floorTiles: ApiSpec['POST /api/verse/:orgId/space/:spaceId']['request']['body']['floorTiles']
        apiKey?: string
    }) =>
        request<'POST /api/verse/:orgId/space/:spaceId'>(
            'POST',
            `/api/verse/${orgId}/space/${spaceId}`,
            {
                headers: {
                    'x-api-key': apiKey,
                },
                body: { floorTiles },
            }
        ),
    put: ({
        orgId,
        spaceId,
        op,
        apiKey,
    }: {
        orgId: string
        spaceId: string
        op: IVerseSpaceOp
        apiKey: string
    }) =>
        request<'PUT /api/verse/:orgId/space/:spaceId'>(
            'PUT',
            `/api/verse/${orgId}/space/${spaceId}`,
            {
                headers: {
                    'x-api-key': apiKey,
                },
                body: op,
            }
        ),
}

const copyLayout = ({
    srcId,
    destId,
    sourceLocationId,
    destinationLocationId,
}: {
    srcId: string
    destId: string
    sourceLocationId: string
    destinationLocationId: string
}) =>
    request<'PATCH /api/verse/:srcId/to/:destId'>(
        'PATCH',
        `/api/verse/${srcId}/to/${destId}`,
        {
            body: {
                sourceLocationId,
                destinationLocationId,
            },
        }
    )

const get = ({ orgId, apiKey }: { orgId: string; apiKey: string }) =>
    request<'GET /api/verse/:id'>('GET', `/api/verse/${orgId}`, {
        headers: {
            'x-api-key': apiKey,
        },
    })

const patch = ({
    orgId,
    apiKey,
    verseState,
}: {
    orgId: string
    apiKey: string
    verseState: PersistedVerseProperties
}) =>
    Promise.race([
        request<'PATCH /api/verse/:id'>('PATCH', `/api/verse/${orgId}`, {
            headers: {
                'x-api-key': apiKey,
            },
            body: verseState,
        }),
        waitForSeconds(30),
    ])

const createProjectRoom = ({
    orgId,
    roomName,
}: {
    orgId: string
    roomName: string
}) =>
    request<'POST /api/verse/:id/project-room/:roomName'>(
        'POST',
        `/api/verse/${orgId}/project-room/${roomName}`
    )

export default {
    lock,
    unlock,
    space,
    copyLayout,
    get,
    patch,
    createProjectRoom,
}
