import { api } from '@teamflow/client-api'
import { UpdateRoomSessionCommand } from '@teamflow/client-realtime'
import { castRoomSessionToRoom } from '@teamflow/lib'
import rootStore, { Room } from '@teamflow/store'
import type { IRealtimeService } from '@teamflow/types'

import { track } from '../helpers/analytics'

// TODO(RYAN:API) update once API is refactored to no longer use room sessions
export const updateRoomName = async (
    newName: string,
    room: Room,
    realtime: IRealtimeService,
    eventName = 'changed_room_name'
) => {
    const roomExists = rootStore.rooms.roomNameExists(newName, room.id)

    if (!newName || roomExists) {
        return
    }

    track(eventName)

    const res = await api.room.update({
        slug: room.slug,
        update: { roomName: newName },
    })

    if (res.error) {
        throw new Error(`${res.error.code}: ${res.error.message ?? ''}`)
    }

    const newRoomSession = res.data

    realtime.dispatch(new UpdateRoomSessionCommand(newRoomSession))

    return castRoomSessionToRoom(newRoomSession)
}

export default updateRoomName
