import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export default class AppMinimizeCommand implements t.ICommand<t.IAppMinimize> {
    readonly messageType = t.ClientMessage.AppMinimize

    private id = ''
    private minimized = false
    private locationId = ''

    constructor(id: string, minimized: boolean, locationId: string) {
        this.id = id
        this.minimized = minimized
        this.locationId = locationId
    }

    execute() {
        return {
            id: this.id,
            minimized: this.minimized,
            locationId: this.locationId,
        }
    }

    localExecute() {
        rootStore.app.getAppById(this.id)?.setMinimized(this.minimized)
    }
}
