import { makeAutoObservable } from 'mobx'

export class ExperimentsStore {
    privateOfficeSlug = ''
    isRecentlyCreatedUser = false

    constructor() {
        makeAutoObservable(this)
    }

    reset() {
        this.privateOfficeSlug = ''
        this.isRecentlyCreatedUser = false
    }

    setPrivateOfficeSlug(value: string) {
        this.privateOfficeSlug = value
    }

    setIsRecentlyCreatedUser(value: boolean) {
        this.isRecentlyCreatedUser = value
    }
}
