import * as t from '@teamflow/types'

export class RequestFurnitureCommand implements t.ICommand<t.FurnitureRequest> {
    readonly messageType = t.ClientMessage.RequestFurniture

    constructor(private options: t.FurnitureRequest) {}

    execute() {
        return this.options
    }
}
