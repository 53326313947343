import { getPublicApiUrl } from '@teamflow/lib'
import { FlowType } from '@teamflow/types'

import { sharedInstance as electron } from '../verse/services/ElectronService'

import { finishInNewWindow } from './finishInNewWindow'

export async function requestOutlookCalendarPermissions(accountId: string) {
    if (electron.available && (await electron.atLeastVersion('10.0.0'))) {
        // Similar to what we do in helpers/google.ts, resolves when FinishMicrosoftSSO message is recieved
        return electron.waitForMicrosoftSSO(
            '/close',
            accountId,
            FlowType.Permission
        )
    }
    return finishInNewWindow(
        getPublicApiUrl(
            `/api/auth/microsoft/permission?&redirect=/close&accountId=${accountId}`
        )
    )
}
