import { REALTIME_CONNECTION_ERROR } from '@teamflow/lib'
import * as t from '@teamflow/types'

import { ConnectionTransition } from './enums'
import { State, StateMachine } from './types'

/** @group Connection States */
export default class FailedToConnect implements State {
    constructor(
        private readonly logger: t.ILogger,
        private readonly analytics: t.IAnalytics
    ) {}

    async onEnter(machine: StateMachine, options: t.JoinOptions) {
        const { orgId } = options

        this.logger.error(`Unable to connect to room: ${orgId}`)
        this.analytics.track(REALTIME_CONNECTION_ERROR, { orgId })

        await machine.runTransition(ConnectionTransition.Disconnect, {
            consented: false,
            code: t.ConnectionDroppedCode.PolicyViolation,
        })
    }
}
