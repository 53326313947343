import * as Sentry from '@sentry/react'
import EventEmitter from 'eventemitter3'
import flagsmith from 'flagsmith'

import {
    createEventRemover,
    LogManager,
    retryUntilTimeout,
} from '@teamflow/lib'
import {
    Feature,
    flagDefaults,
    FlagDefaultsWithUndefinedValue,
    FlagDefaultValues,
    FlagType,
    FlagValue,
    getRemoteJsonDefault,
    IFeatureFlagService,
    RemoteJson,
    RemoteJsonTypes,
    Source,
} from '@teamflow/types'

import { featureFlagInitRetryTimers } from '../const'

import { UrlQueryFlagOverrides } from './UrlQueryFlagOverrides'

import type { IFlags, IRetrieveInfo } from 'flagsmith/types'

enum Events {
    FlagChanged = 'flag-changed',
    Initialized = 'initialized',
}

enum State {
    Uninitialized,
    Ready,
    BROKEN,
}

enum IdentifyState {
    Anonymous,
    Identifying,
    WaitingForUpdate,
    Updated,
    Identified,
    Failed,
}

const featureFlagsToLog: Array<FlagType> = []
const logger = LogManager.createLogger('FeatureFlagService') // not critical

/**
 * A feature-flag evaluator wrapping Flagsmith's JS SDK.
 *
 * ```ts
 * import { featureFlags } from '@teamflow/bootstrap';
 * import { Feature } from '@teamflow/types';
 *
 * console.log(`Test if feature FlagsmithReference is enabled ` +
 *             `${featureFlag.isEnabledSync(Feature.FlagsmithReference)}`);
 * ```
 *
 * @see useFeatureFlag
 */
export class FeatureFlagService implements IFeatureFlagService {
    private events = new EventEmitter()
    private state = State.Uninitialized
    private identifyState = IdentifyState.Anonymous
    private cachedFlagValues = new Map<
        FlagType,
        { enabled: boolean; value: FlagValue }
    >()
    private loadPromise?: Promise<void>
    private identifiedResolves: ((value: string) => void)[] = []
    private isIdentified = false
    private userId?: string

    private flagOverrides: UrlQueryFlagOverrides<
        string,
        FlagDefaultsWithUndefinedValue
    >

    constructor() {
        const urlSearch =
            // don't let prod users mess with flags
            process.env.DEPLOY_ENV === 'production' ||
            // can't access window when run during SSR
            typeof window === 'undefined'
                ? ''
                : window.location.search

        this.flagOverrides = new UrlQueryFlagOverrides(urlSearch, flagDefaults)
    }

    get isReady() {
        return this.state === State.Ready
    }

    get isBroken() {
        return this.state === State.BROKEN
    }

    get hasIdentity() {
        return this.isIdentified
    }

    async getLoadPromise() {
        return this.loadPromise ?? this.initialize()
    }

    async whenIdentified() {
        if (this.isIdentified && this.userId) {
            return Promise.resolve(this.userId)
        }

        return new Promise<string>((resolve) => {
            this.identifiedResolves.push(resolve)
        })
    }

    async initialize() {
        if (this.isReady) {
            return Promise.resolve()
        }

        if (this.loadPromise) {
            return this.loadPromise
        }

        let attempt = 1

        this.loadPromise = retryUntilTimeout<void>({
            action: async () => {
                try {
                    logger.info({ action: 'featureFlags@Init' })
                    await flagsmith.init({
                        environmentID:
                            process.env.NEXT_PUBLIC_FLAGSMITH_ENV_ID!,
                        onChange: this.handleFlagsChanged,
                        enableAnalytics: true,
                    })
                    // polling is how this library checks for flag changes
                    // we may be able to get away with a much longer poll?
                    // each poll counts against the plan so any flags that need
                    // to sync immediately should use the FlagChangedCommand to
                    // propagate the change via the realtime server
                    flagsmith.startListening(60 * 60 * 1000)
                } catch (err) {
                    /* @metric service_feature-flags_init-errors */
                    logger.error({
                        action: 'featureFlag@Init',
                        error: err,
                        attempt: attempt++,
                    })
                    throw err
                }
            },
            timeout: featureFlagInitRetryTimers.timeout,
            cooldown: featureFlagInitRetryTimers.cooldown,
            cooldownMultiplier: featureFlagInitRetryTimers.cooldownMultiplier,
        }).catch((err) => {
            /* @metric service_feature-flags_init-errors */
            logger.error({
                action: 'featureFlags@Init',
                error: err,
                final: true,
            })
            this.state = State.BROKEN
            Sentry.captureException(err, {
                contexts: {
                    flagServiceState: {
                        state: this.state,
                        identifyState: this.identifyState,
                        userId: this.userId,
                    },
                },
                tags: {
                    is_flagsmith_error: true,
                },
            })
        })

        return this.loadPromise
    }

    async identify(
        userId: string,
        traits?: Record<string, string | number | boolean>
    ) {
        this.userId = userId

        await this.loadPromise
        this.setIdentifyState(IdentifyState.Identifying)

        try {
            await flagsmith.identify(userId, traits)
            this.setIdentifyState(IdentifyState.WaitingForUpdate)
        } catch (err) {
            this.setIdentifyState(IdentifyState.Failed)
            /* @metric service_feature-flags_identify-errors */
            logger.error({
                action: 'featureFlags@Identify',
                error: err,
            })
        }
    }

    private setIdentifyState(newState: IdentifyState) {
        if (!this.userId) {
            return
        }

        if (this.identifyState === IdentifyState.Identified) {
            // we are finished so do nothing
            return
        }

        if (newState === this.identifyState) {
            // same state so do nothing
            return
        }

        switch (newState) {
            case IdentifyState.Identifying:
                break

            case IdentifyState.WaitingForUpdate:
                // force flag fetch
                void flagsmith.getFlags()
                break

            case IdentifyState.Updated:
                if (this.identifyState === IdentifyState.Anonymous) {
                    // flags updated before identify was called
                    return
                } else if (this.identifyState === IdentifyState.Identifying) {
                    // means it is the first update before identify has finished
                    // flags are not guaranteed to be updated so keep waiting
                    // for either WaitForUpdate or Failed that gets set in identify()
                    return
                } else if (
                    this.identifyState === IdentifyState.WaitingForUpdate
                ) {
                    // we should have the latest flags so set to Identified
                    this.setIdentifyState(IdentifyState.Identified)
                    return
                }
                break

            // resolve all waiting promises if failed
            case IdentifyState.Failed:
            case IdentifyState.Identified: {
                const userId = this.userId
                this.isIdentified = true
                this.identifiedResolves.forEach((resolve) => resolve(userId))
                this.identifiedResolves.length = 0
                logger.info({
                    action: 'featureFlags@Identify',
                    state: this.identifyState,
                })
                break
            }
        }

        this.identifyState = newState
    }

    /**
     * Evaluate the feature-flag {@code flag} without waiting for Flagsmith to download them.
     *
     * This should be used in code that is run after the Verse has been created. This include critical code
     * that cannot be async.
     *
     * @param flag
     */
    isEnabledSync(flag: FlagType): boolean {
        return this.flagOverrides.augmentIsEnabled(flag, this.hasFeature(flag))
    }

    /**
     * Evaluate the data-value for the feature flag {@code flag} without waiting for Flagsmith to download them.
     *
     * @see FeatureFlagService#isEnabledSync
     * @param flag
     */
    valueSync<TFlag extends FlagType>(flag: TFlag): FlagDefaultValues[TFlag] {
        return this.flagOverrides.augmentValue(
            flag,
            this.valueSyncInternal(flag)
        ) as FlagDefaultValues[TFlag]
    }

    private valueSyncInternal<TFlag extends FlagType>(
        flag: TFlag
    ): FlagDefaultValues[TFlag] {
        const defaultValue = flagDefaults[flag]
            .value as FlagDefaultValues[TFlag]

        if (this.state === State.BROKEN) {
            logger.warn({
                action: 'featureFlags@fallback',
                flag,
                defaultValue,
            })
            return defaultValue
        }

        const enabled = this.hasFeature(flag)
        if (!enabled) {
            return defaultValue
        }

        return (this.cachedFlagValues.get(flag)?.value ??
            flagsmith.getValue(flag) ??
            defaultValue) as FlagDefaultValues[TFlag]
    }

    /**
     * Evaluate if the feature flag {@code flag} is enabled.
     *
     * This will wait for Flagsmith to finish downloading the flags. If you need a feature flag in critical
     * code, try using {@link FeatureFlagService#isEnabledSync} instead.
     *
     * @param flag
     */
    async isEnabled(flag: FlagType): Promise<boolean> {
        const defaultsForFlag = flagDefaults[flag]
        const defaultEnabled = defaultsForFlag?.enabled ?? false

        if (this.state === State.BROKEN) {
            // we cannot use the Logger here due to a cyclical dependency
            logger.warn({
                action: 'featureFlags@fallback',
                flag,
                defaultEnabled,
            })
            return this.flagOverrides.augmentIsEnabled(flag, defaultEnabled)
        }

        if (this.state === State.Ready) {
            return this.flagOverrides.augmentIsEnabled(
                flag,
                this.hasFeature(flag)
            )
        }

        return new Promise<boolean>((resolve) => {
            this.events.once(Events.Initialized, () => {
                const val = this.flagOverrides.augmentIsEnabled(
                    flag,
                    this.hasFeature(flag)
                )
                resolve(val)
            })
        })
    }

    /**
     * @see FeatureFlagService#isEnabled
     * @param flag
     */
    async value<TFlag extends FlagType>(
        flag: TFlag
    ): Promise<FlagDefaultValues[TFlag]> {
        return this.flagOverrides.augmentValue(
            flag,
            await this.valueInternal<TFlag>(flag)
        ) as FlagDefaultValues[TFlag]
    }

    private async valueInternal<TFlag extends FlagType>(
        flag: FlagType
    ): Promise<FlagDefaultValues[TFlag]> {
        const defaultValue = flagDefaults[flag]
            .value as FlagDefaultValues[TFlag]

        if (this.state === State.BROKEN) {
            logger.warn({
                action: 'featureFlags@fallback',
                flag,
                defaultValue,
            })
            return defaultValue
        }

        if (this.state === State.Ready) {
            const enabled = this.hasFeature(flag)
            if (!enabled) {
                return defaultValue
            }
            return (this.cachedFlagValues.get(flag)?.value ??
                flagsmith.getValue(flag) ??
                defaultValue) as FlagDefaultValues[TFlag]
        }

        return new Promise<FlagDefaultValues[TFlag]>((resolve) => {
            this.events.once(Events.Initialized, () => {
                const enabled = this.hasFeature(flag)
                if (!enabled) {
                    resolve(defaultValue)
                    return
                }
                const value = (this.cachedFlagValues.get(flag)?.value ??
                    flagsmith.getValue(flag) ??
                    defaultValue) as FlagDefaultValues[TFlag]
                resolve(value)
            })
        })
    }

    async getConfigValue<TFlag extends FlagType>(
        name: TFlag
    ): Promise<FlagDefaultValues[TFlag]> {
        const defaultValue = this.flagOverrides.augmentValue(
            name,
            flagDefaults[name].value
        ) as FlagDefaultValues[TFlag]
        try {
            const [enabled, value] = await Promise.all([
                this.isEnabled(name as unknown as Feature),
                this.value(name),
            ])
            return enabled ? value : defaultValue
        } catch {
            return defaultValue
        }
    }

    getConfigValueSync<TFlag extends FlagType>(
        name: TFlag
    ): FlagDefaultValues[TFlag] {
        const defaultValue = this.flagOverrides.augmentValue(
            name,
            flagDefaults[name].value
        ) as FlagDefaultValues[TFlag]
        try {
            const enabled = this.isEnabledSync(name as unknown as Feature)
            const value = this.valueSync(name)
            return enabled ? value : defaultValue
        } catch {
            return defaultValue
        }
    }

    getRemoteJsonSync<T extends RemoteJson>(
        flag: T,
        enabled: boolean | undefined,
        value: unknown
    ): RemoteJsonTypes[T] {
        const remoteJson = getRemoteJsonDefault(flag)
        try {
            // Apply remote json over default values if enabled & valid
            if (enabled && typeof value === 'string') {
                Object.assign(remoteJson, JSON.parse(value))
            }
            // Init dat.gui tweaks with actual reference to remote json obj
            // so it can be tweaked
            const datguiTweaks = window.datguiRemoteJsonTweaks?.[flag]
            if (datguiTweaks !== remoteJson) {
                window.datguiRemoteJsonTweaks =
                    window.datguiRemoteJsonTweaks ?? {}
                window.datguiRemoteJsonTweaks[flag] = remoteJson
            }
        } catch (e: unknown) {
            // Invalid json, don't apply anything over defaults
        }
        return remoteJson
    }

    remoteJsonSync<T extends RemoteJson>(flag: T): RemoteJsonTypes[T] {
        return this.getRemoteJsonSync(
            flag,
            this.isEnabledSync(flag),
            this.valueSync(flag)
        )
    }

    /**
     * This will call the given callback when the value changes. The callback is *never* called for the initial value.
     * If the initial value is needed please call the function {@link isEnabledSync}, and then subscribe to future
     * changes with this method
     * @param flag
     * @param cb
     * @param context
     * @returns
     */
    onFlagChanged(
        flag: FlagType,
        cb: (enabled: boolean, value: FlagValue, source: Source) => void,
        context?: any
    ) {
        const wrappedCallback = (
            changedFlag: FlagType,
            enabled: boolean,
            value: FlagValue,
            source: Source
        ) => {
            if (changedFlag !== flag) {
                return
            }

            cb.call(context, enabled, value, source)
        }

        this.events.on(Events.FlagChanged, wrappedCallback)

        return createEventRemover(() => {
            this.events.off(Events.FlagChanged, wrappedCallback)
        })
    }

    /**
     * Update the flag value internally before officially received from
     * Flagsmith; used in the case where we know a flag will change
     * but the poll time has not been hit yet
     * @param flag
     * @param newEnabled
     * @param newValue
     */
    updateFlagValue(
        flag: FlagType,
        newEnabled: boolean,
        newValue: FlagValue
    ): void {
        if (this.state === State.BROKEN || this.state !== State.Ready) {
            logger.warn({
                action: 'featureFlags@flagsUpdated',
                state: this.state,
                message: 'Failed to update flag value',
            })
        }

        const enabled =
            this.cachedFlagValues.get(flag)?.enabled ??
            flagsmith.hasFeature(flag)
        const value =
            this.cachedFlagValues.get(flag)?.value ?? flagsmith.getValue(flag)
        if (newEnabled === enabled && newValue === value) {
            return
        }

        if (featureFlagsToLog.indexOf(flag) !== -1) {
            // we cannot use the Logger here due to a cyclical dependency
            logger.info({
                action: 'featureFlags@flagsUpdated',
                flag,
                newEnabled,
                newValue,
            })
        }

        // update cache
        this.cachedFlagValues.set(flag, {
            enabled: newEnabled,
            value: newValue,
        })

        this.events.emit(
            Events.FlagChanged,
            flag,
            newEnabled,
            newValue,
            Source.Realtime
        )
    }

    private handleFlagsChanged = (oldFlags: IFlags, _params: IRetrieveInfo) => {
        // TODO: should this emit after values have been set below?
        if (this.state === State.Uninitialized) {
            this.state = State.Ready
            this.events.emit(Events.Initialized)
        }

        for (const _f in oldFlags) {
            const flag = _f as FlagType
            const enabled = flagsmith.hasFeature(flag)
            const wasEnabled =
                this.cachedFlagValues.get(flag)?.enabled ??
                oldFlags[flag].enabled

            const value = flagsmith.getValue(flag)
            const oldValue =
                this.cachedFlagValues.get(flag)?.value ?? oldFlags[flag].value

            if (featureFlagsToLog.indexOf(flag as Feature) !== -1) {
                logger.info({
                    action: 'featureFlags@flagsChanged',
                    flag,
                    enabled,
                    wasEnabled,
                    value,
                    oldValue,
                })
            }

            this.cachedFlagValues.set(flag, { enabled, value })

            const enabledChanged = enabled !== wasEnabled
            const valueChanged = value !== oldValue

            if (enabledChanged || valueChanged) {
                this.events.emit(
                    Events.FlagChanged,
                    flag,
                    enabled,
                    value,
                    Source.Flagsmith
                )
            }
        }

        this.setIdentifyState(IdentifyState.Updated)
    }

    private hasFeature(flag: FlagType): boolean {
        const cachedValue = this.cachedFlagValues.get(flag)?.enabled
        if (typeof cachedValue === 'boolean') {
            return cachedValue
        }

        // if flag has been added to flagsmith return the
        // value otherwise return the default value

        const defaultsForFlag = flagDefaults[flag]
        let enabled = defaultsForFlag?.enabled ?? false
        let value = defaultsForFlag?.value ?? null
        const flags = flagsmith.getAllFlags()

        if (flags && typeof flags[flag] !== 'undefined') {
            enabled = flagsmith.hasFeature(flag)
            value = flagsmith.getValue(flag)
            // only add to cache if value comes from flagsmith
            this.cachedFlagValues.set(flag, { enabled, value })
        }

        if (featureFlagsToLog.indexOf(flag) !== -1) {
            logger.info({
                action: 'featureFlags@hasFeature',
                flag: flag,
                enabled: enabled,
                value: value,
            })
        }

        return enabled
    }
}

export const featureFlags = new FeatureFlagService()
