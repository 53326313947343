/**
 * An {@link EventTargetProxy} wraps an {@link EventTarget} to allow multiplex event listeners on the DOM.
 *
 * This class is not exported from @teamflow/bootstrap. The {@link documentProxy}, {@link windowProxy} globals
 * are exported instead.
 *
 * By using a proxy for event listeners, we are able to avoid bugs introduced by DOM modifications from 3rd party
 * libraries like Sentry and LogRocket. For example, sometimes LogRocket fails to de-register a listener in its
 * own {@code removeEventListener} implementation − which causes spurious errors in our code after the Verse is
 * destroyed.
 *
 * @internal
 */
export class EventTargetProxy<T extends EventTarget> {
    private readonly _eventProxy: {
        [K in string]: {
            handler: Parameters<T['addEventListener']>[1]
            listeners: Set<Parameters<T['addEventListener']>[1]>
        }
    }

    constructor(private target: T) {
        this._eventProxy = {}
    }

    /**
     * Proxy to {@code addEventListener} on {@code EventTarget}. This will not support passive, signal, abort options.
     */
    addEventListener(...args: Parameters<T['addEventListener']>) {
        const [event, listener, options] = args
        const key = EventTargetProxy.key(event, options)

        if (!this._eventProxy[key]) {
            this._eventProxy[key] = {
                handler: this.proxyCallback(key),
                listeners: new Set(),
            }
            this.target.addEventListener(args[0], this._eventProxy[key].handler)
        }

        this._eventProxy[key].listeners.add(listener)
    }

    /**
     * Proxy to {@code removeEventListener} on {@code EventTarget}.
     *
     * This will never remove the internal handler from {@link EventTargetProxy} that multiplexes application-level
     * handlers.
     */
    removeEventListener(
        e: string,
        handler: EventListener | EventListenerObject,
        options?: { capture: boolean } | boolean
    ) {
        const listeners =
            this._eventProxy[EventTargetProxy.key(e, options)]?.listeners
        if (!listeners) return

        listeners.delete(handler)
    }

    private proxyCallback =
        (key: string): Parameters<T['addEventListener']>[1] =>
        (...args) => {
            const listeners = this._eventProxy[key]?.listeners
            if (!listeners) return

            for (const listener of listeners)
                if (typeof listener === 'object') listener?.handleEvent(...args)
                else listener(...args)
        }

    private static key(
        event: string,
        options?: { capture?: boolean } | boolean | null
    ): string {
        const capture =
            options && typeof options === 'object'
                ? options.capture === true
                : typeof options === 'boolean'
                ? options
                : false
        return `on${capture ? 'capture' : ''}${event}`
    }
}
