import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export default class AppLockCommand implements t.ICommand<t.IAppLock> {
    readonly messageType = t.ClientMessage.AppLock

    private id = ''
    private locked = false
    private locationId = ''

    constructor(id: string, locked: boolean, locationId: string) {
        this.id = id
        this.locked = locked
        this.locationId = locationId
    }

    execute() {
        return {
            id: this.id,
            locked: this.locked,
            locationId: this.locationId,
        }
    }

    localExecute(state: t.IVerseState) {
        rootStore.app.getAppById(this.id)?.setLocked(this.locked)
        const app = state.roomSubstates
            .get(this.locationId)
            ?.apps.find((app) => app.id === this.id)
        if (app) {
            app.locked = this.locked
        }
    }
}
