import { createRequest, request } from '../request'

const trigger = createRequest<'POST /api/out-app-notification/trigger'>(
    'POST',
    '/api/out-app-notification/trigger'
)

const unsubscribeFromEmail =
    createRequest<'POST /api/out-app-notification/unsubscribe-email'>(
        'POST',
        '/api/out-app-notification/unsubscribe-email'
    )

const del = ({ id }: { id: string }) =>
    request<'DELETE /api/out-app-notification/notified-of-event/:id'>(
        'DELETE',
        `/api/out-app-notification/notified-of-event/${id}`
    )

const addToken = createRequest<'POST /api/out-app-notification/token'>(
    'POST',
    '/api/out-app-notification/token'
)

const send = {
    getting: createRequest<'POST /api/out-app-notification/getting'>(
        'POST',
        '/api/out-app-notification/getting'
    ),
}

const deleteStale =
    createRequest<'POST /api/out-app-notification/delete-stale'>(
        'POST',
        '/api/out-app-notification/delete-stale'
    )

export default {
    trigger,
    unsubscribeFromEmail,
    delete: del,
    addToken,
    send,
    deleteStale,
}
