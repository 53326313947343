export function finishInNewWindow(url: string) {
    return new Promise<void>(function (resolve, reject) {
        const loginWindow = window.open(url)
        if (loginWindow) {
            window.addEventListener('message', function onMessage(e) {
                if (e.data === 'close') {
                    resolve()
                }
            })
        } else {
            reject(new Error('Failed to initiate login process in new window!'))
        }
    })
}
