import * as t from '@teamflow/types'

export default class ProfileUpdatedCommand
    implements t.ICommand<t.IProfileUpdated>
{
    readonly messageType = t.ClientMessage.ProfileUpdated

    private _id: string
    private profilePictureUrl: string
    private firstName?: string
    private lastName?: string

    constructor(
        userOrgId: string,
        profilePictureUrl: string,
        firstName?: string,
        lastName?: string
    ) {
        this._id = userOrgId
        this.profilePictureUrl = profilePictureUrl
        this.firstName = firstName
        this.lastName = lastName
    }

    execute() {
        return {
            userId: this._id,
            profilePictureUrl: this.profilePictureUrl,
            firstName: this.firstName,
            lastName: this.lastName,
        }
    }
}
