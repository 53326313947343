import { useRef } from 'react'

import { distanceSquaredBetween } from '@teamflow/lib'

export function useOnClickWithThreshold(
    onClick?: (e: React.MouseEvent) => void,
    clickThreshold?: 'none' | 'tiny' | 'small'
): Pick<
    React.DOMAttributes<HTMLElement>,
    'onMouseDown' | 'onMouseMove' | 'onMouseUp'
> {
    // Requirements for click to fire:
    // 1. Down was on the button
    // 2. Up was on the button
    // 3. Mouse never left the threshold range

    const thresholdPixels =
        clickThreshold === 'none' || !clickThreshold
            ? Infinity
            : clickThreshold === 'tiny'
            ? 1
            : 3
    const thresholdPixelsSq = thresholdPixels * thresholdPixels
    const downMousePos = useRef<[number, number]>([-1000, -1000])
    const useClick = useRef<boolean>(false)

    return {
        onMouseDown: (e) => {
            downMousePos.current = [e.clientX, e.clientY]
            useClick.current = true
        },
        onMouseMove: (e) => {
            const distSq = distanceSquaredBetween(
                ...downMousePos.current,
                e.clientX,
                e.clientY
            )
            if (distSq > thresholdPixelsSq) {
                useClick.current = false
            }
        },
        onMouseUp: (e) => {
            const distSq = distanceSquaredBetween(
                ...downMousePos.current,
                e.clientX,
                e.clientY
            )
            if (!useClick.current || distSq > thresholdPixelsSq) {
                return
            }
            onClick?.(e)
        },
    }
}
