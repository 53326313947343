import Head from 'next/head'
import { useEffect } from 'react'

import { useFeatureFlag, Platform } from '@teamflow/bootstrap'
import { Feature } from '@teamflow/types'
import { useScript } from '@teamflow/web/src/hooks'

const appziScriptUrl = `https://w.appzi.io/w.js?token=${
    process.env.NEXT_PUBLIC_APPZI_TOKEN ?? 'Dtvrz'
}`

export const hideAppziStyle = (
    <Head>
        <style
            data-appzi-hide="true"
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            dangerouslySetInnerHTML={{
                __html: `
                    div[data-appzi-dom="1"] {
                        display: none !important;
                    }
                    div[data-appzi-dom="1"] + div[data-appzi-dom="1"] {
                        display: block !important;
                    }

                    body.appzi-is-ios{
                        left: 0;
                        right: 0;
                    }
                `,
            }}
        />
    </Head>
)

export const undoPointerEventsAppziStyle = (
    <Head>
        <style
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            dangerouslySetInnerHTML={{
                __html: `
                    div[data-appzi-dom="1"] {
                        pointer-events: auto;
                    }
                `,
            }}
        />
    </Head>
)

export default function Appzi() {
    const { enabled: newFeedbackButtonEnabled } = useFeatureFlag(
        Feature.NewFeedbackButton
    )
    const appzi = useScript(appziScriptUrl, 'appzi')

    useEffect(() => {
        if (
            !appzi.loaded &&
            !window.location.pathname.includes('/quick-search-overlay')
        ) {
            appzi.inject()
            return
        }
    }, [appzi, appzi.loaded])

    if (newFeedbackButtonEnabled) {
        return hideAppziStyle
    }

    return Platform.isPhone() ? hideAppziStyle : undoPointerEventsAppziStyle
}
