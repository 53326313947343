import { api } from '@teamflow/client-api'
import { IRole } from '@teamflow/types'

export async function removeRolesFromUser(userId: string, roles: IRole[]) {
    return await Promise.all(
        roles.map(async (role) => {
            api.userOrg.role.delete({
                userOrgId: userId,
                roleId: role._id,
            })
        })
    )
}
