import { rootStore } from '@teamflow/store'
import { ElectronService } from '@teamflow/web/src/verse/services/ElectronService'

import { setHref } from './reload'
const APP_URL = process.env.NEXT_PUBLIC_APP_URL

export { getRoomInvitePath } from '@teamflow/lib'

export const prependAppUrl = (path: string) => `${APP_URL}${path}`

export function openDesktopApp(deepLink?: string): Promise<boolean> {
    if (typeof deepLink === 'undefined') {
        deepLink = `${ElectronService.protocol}://${window.location.href}`
    }
    if (rootStore.commons.desktopOpenAttempted) {
        return Promise.resolve(false)
    }
    rootStore.commons.setDesktopOpenAttempted()

    return new Promise(function openDeepLink(resolve) {
        // Success
        function onWindowBlur() {
            onComplete(true)
        }

        const timeoutHandle = setTimeout(() => onComplete(false), 500)

        function onComplete(result: boolean) {
            clearTimeout(timeoutHandle)
            window.removeEventListener('blur', onWindowBlur)
            resolve(result)
        }

        window.addEventListener('blur', onWindowBlur)
        setHref(deepLink as string)
    })
}
