import { autorun, makeAutoObservable } from 'mobx'
import { clearPersistedStore, makePersistable } from 'mobx-persist-store'

import { LogManager } from '@teamflow/lib'
import {
    ConnectionState,
    DeploymentState,
    PendingUpdateState,
    RoomNavigationState,
    RoomSessionFrontendModel,
    RoomType,
} from '@teamflow/types'

import { RoomStore } from './RoomStore'

import type { SettingsStore } from './SettingsStore'
import type { ParticipantStore } from './participantStore'

interface PendingRoomTeleport {
    room: {
        _id: string
        orgId: string
        positionX: number
        positionY: number
        displayName: string
        slugName: string
    }
    roomSession: RoomSessionFrontendModel
    templateId?: string
}

/**
 * This store holds unclassified state that is globally relevant to the whole frontend. It should not
 * be used as a holder for relatively unimportant variables that aren't easily grouped elsewhere.
 */
export class CommonsStore {
    /**
     * The space being rendered in the world. It is not necessarily the same space the audio and video are connected to
     */
    viewingSpace = ''

    /**
     * The space to which the audio and video is connected to, this should be tail synchronized
     * to the roomId of the room the localUser is in.
     */
    avConnectedSpace = ''

    /**
     * This flags whether the computer is in sleep! Before retrying over-the-network logic, you should
     * check this.
     */
    suspended = false

    /**
     * Synchronized with LoopService's inBackground
     */
    inBackground = false

    /**
     * Whether a page reload is pending. If set to true, it won't be set to
     * false again until the page reloads.
     */
    reloading = false

    /**
     * TODO: This is temporary, we need to consolidate the state after reconnecting
     * Set to true whenever we try to reconnect
     */
    reconnecting = false

    /**
     * Connection State
     */
    connectionState = ConnectionState.Inactive

    /**
     * Deployment State
     */
    deploymentState = DeploymentState.None

    /**
     * Pending Update State
     */
    pendingUpdateState = PendingUpdateState.None

    /**
     * Room Navigation State
     */
    roomNavigationState = RoomNavigationState.Idle

    /**
     * Used to queue up a teleport into a specific room
     * (Currently used in signup flow when creating a room from a specific templateId)
     */
    pendingRoomTeleport?: PendingRoomTeleport

    /**
     *  Tracks of whether we have already attempted to open the desktop app during the session (i.e. in the signup flow)
     */
    desktopOpenAttempted? = false

    /**
     Whether or not appcues is identified (i.e. whether we can send events to Appcues yet)
     */
    appcuesIdentified = false

    constructor(
        private readonly participants: ParticipantStore,
        private readonly settings: SettingsStore,
        private readonly rooms: RoomStore
    ) {
        makeAutoObservable(this, {
            viewingSpace: true,
            avConnectedSpace: true,
        })
        void makePersistable(this, {
            name: 'CommonsStore',
            properties: ['pendingRoomTeleport'],
            // Stop it blowing up with SSR
            storage:
                typeof window !== 'undefined' ? window.localStorage : undefined,
            // Set to true for verbose logs
            debugMode: false,
        })

        autorun(() => {
            LogManager.tag({ viewingSpace: this.viewingSpace })
        })
        autorun(() => {
            LogManager.tag({ avConnectedSpace: this.avConnectedSpace })
        })
    }

    reset(shouldClearPersistentStore = false) {
        if (shouldClearPersistentStore) {
            void clearPersistedStore(this)
        }

        this.viewingSpace = ''
        this.avConnectedSpace = ''
        this.suspended = false
        this.inBackground = false
        this.reloading = false
        this.connectionState = ConnectionState.Inactive
        this.deploymentState = DeploymentState.None
        this.appcuesIdentified = false
    }

    switchSpace(newSpace: string) {
        this.viewingSpace = newSpace
        this.avConnectedSpace = newSpace
    }

    switchViewingSpace(newSpace: string) {
        this.viewingSpace = newSpace
    }

    switchAvConnectedSpace(newSpace: string) {
        this.avConnectedSpace = newSpace
    }

    sleep() {
        this.suspended = true
    }

    resume() {
        this.suspended = false
    }

    setInBackground(inBackground: boolean) {
        this.inBackground = inBackground
    }

    setAppcuesIdentified(appcuesIdentified: boolean) {
        this.appcuesIdentified = appcuesIdentified
    }

    setReloading() {
        this.reloading = true
    }

    setDesktopOpenAttempted() {
        this.desktopOpenAttempted = true
    }

    updateConnectionState(value: ConnectionState) {
        this.connectionState = value
    }

    updateDeploymentState(value: DeploymentState) {
        this.deploymentState = value
    }

    updatePendingUpdateState(value: PendingUpdateState) {
        this.pendingUpdateState = value
    }

    updateNavigationState(value: RoomNavigationState) {
        this.roomNavigationState = value
    }

    updatePendingTeleport(room: PendingRoomTeleport) {
        this.pendingRoomTeleport = room
    }

    clearPendingTeleport() {
        this.pendingRoomTeleport = undefined
    }

    /**
     * Flags whether the user is visible, available and with the A/V connected in the virtual world to other participants. This is a preferential flag
     * and may not reflect temporary disconnections.
     */
    get ready(): boolean {
        return this.participants.localParticipant?.ready ?? false
    }

    /**
     * Flags whether the user manually selected to be hidden and unavailable
     */
    get disconnected(): boolean {
        return !this.ready && this.settings.manuallyDisconnected
    }

    get isConnected() {
        return this.connectionState === ConnectionState.Connected
    }

    get isDeploying() {
        return (
            this.connectionState !== ConnectionState.Connected &&
            this.deploymentState !== DeploymentState.None
        )
    }

    /**
     * Flags whether the user is viewing the same space that is rendered
     */
    get sameViewingAndAVSpace(): boolean {
        return this.viewingSpace === this.avConnectedSpace
    }

    /**
     * Indicates if the room where the user has the av connected to is the type Meeting (only FSM, no space)
     */
    get userInMeetingRoom(): boolean {
        if (!this.participants.localParticipant) return false
        return (
            this.rooms.roomsBySessionId.get(
                this.participants.localParticipant.locationId
            )?.type === RoomType.Meeting
        )
    }
}
