import { useEffect } from 'react'

import { api } from '@teamflow/client-api'
import { LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'

export function useOrganizationTierState(route: string, orgSlug?: string) {
    async function loadTierState(orgSlug: string) {
        try {
            const { data: tier, error } = await api.organization.tier.get({
                orgName: orgSlug,
            })
            if (tier) rootStore.tier.update(tier)
            if (error) LogManager.global.error(`loadTierState ${error.code}`)
        } catch (error) {
            LogManager.global.error('useOrganizationTierState error', error)
        }
    }

    useEffect(() => {
        if (route === '/[...org]') return
        if (orgSlug) void loadTierState(orgSlug)
    }, [route, orgSlug])
}
