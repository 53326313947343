import * as t from '@teamflow/types'
export default class InviteCodeChangedCommand
    implements t.ICommand<t.InviteCodeChanged>
{
    readonly messageType = t.ClientMessage.InviteCodeChanged

    private floorInviteCode?: string
    private inviteCode?: string

    constructor(opts: t.InviteCodeChanged) {
        this.floorInviteCode = opts.floorInviteCode
        this.inviteCode = opts.inviteCode
    }

    execute() {
        return {
            floorInviteCode: this.floorInviteCode,
            inviteCode: this.inviteCode,
        }
    }
}
