import { request } from '../request'

const del = (chatProviderId: string) =>
    request<'DELETE /api/conversations/:chatProviderId'>(
        'DELETE',
        `/api/conversations/${chatProviderId}`
    )

const sendOneOnOneMessage = (
    userIds: string[],
    senderId: string,
    text: string
) =>
    request<'POST /api/conversations/one-on-one-message'>(
        'POST',
        `/api/conversations/one-on-one-message`,
        {
            body: {
                userIds,
                senderId,
                text,
            },
        }
    )

export default {
    delete: del,
    sendOneOnOneMessage,
}
