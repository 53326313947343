import * as t from '@teamflow/types'

export default class RoomSessionUnlockedCommand
    implements t.ICommand<t.IRoomSessionUnlocked>
{
    readonly messageType = t.ClientMessage.RoomSessionUnlocked

    private privateRoomSessionId: string
    private publicRoomSession: t.RoomSessionForRealTime

    constructor(
        privateRoomSessionId: string,
        publicRoomSession: t.RoomSessionForRealTime
    ) {
        this.privateRoomSessionId = privateRoomSessionId
        this.publicRoomSession = {
            _id: publicRoomSession._id,
            roomId: publicRoomSession.roomId,
            roomName: publicRoomSession.roomName,
            roomSlugName: publicRoomSession.roomSlugName,
            roomInviteCode: publicRoomSession.roomInviteCode,
            orgId: publicRoomSession.orgId.toString(),
            participantIds: publicRoomSession.participantIds,
            waitlistIds: publicRoomSession.waitlistIds,
            isActive: publicRoomSession.isActive,
            isKnockingEnabled: publicRoomSession.isKnockingEnabled,
            status: publicRoomSession.status,
            backgroundImage: publicRoomSession.backgroundImage,
            backgroundPattern: publicRoomSession.backgroundPattern,
            spatialAudio: publicRoomSession.spatialAudio,
            isPrivate: publicRoomSession.isPrivate,
            startInFullScreenMode: publicRoomSession.startInFullScreenMode,
            invitedIds: publicRoomSession.invitedIds,
            whoCanJoin: publicRoomSession.whoCanJoin,
            owner: publicRoomSession.owner,
            knockingAllowedUserIds: publicRoomSession.knockingAllowedUserIds,
            templateId: publicRoomSession.templateId,
            personalOffice: publicRoomSession.personalOffice,
            furnished: publicRoomSession.furnished,
            type: publicRoomSession.type,
        }
    }

    execute() {
        return {
            privateRoomSessionId: this.privateRoomSessionId,
            publicRoomSession: this.publicRoomSession,
        }
    }
}
