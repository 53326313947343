import * as t from '@teamflow/types'

export default class RoomKnockCommand
    implements t.ICommand<t.IRoomKnockAction>
{
    readonly messageType = t.ClientMessage.RoomKnock

    private roomSessionId: string
    private guestId: string

    constructor(roomSessionId: string, guestId: string) {
        this.roomSessionId = roomSessionId
        this.guestId = guestId
    }

    execute() {
        return {
            roomSessionId: this.roomSessionId,
            guestId: this.guestId,
        }
    }
}
