import _ from 'lodash'
import { TFunction } from 'next-i18next'

import { api } from '@teamflow/client-api'
import allTKeys from '@teamflow/translations'
import { IFurnitureManifest } from '@teamflow/types'

const tKeys = allTKeys.common.customize

export const STRUCTURAL_FURNITURE_CATEGORY = 'STRUCTURES'

export type FurnitureRaster = {
    angle: number
    imageUrl: string
}

export type FurnitureModel = {
    id: string
    name: string
    thumbnailUrl?: string
    rasters: FurnitureRaster[]
}

export type FurnitureCategory = {
    title: string
    models: Array<FurnitureModel>
}

export interface IStateDeclarationResource {
    type: 'state'
    name: string
    values: Array<string>
}

export interface IStateMappingResource {
    type: 'textures'
    values: Array<{
        state: Record<string, string>
        url: string
    }>
}

export interface ICatalogFurniture {
    _id: string
    category?: string
    subcategory?: string
    name: string
    nameSlug?: string
    thumbnail: string
    rasters: Array<{ angle: number; image: string; name?: string }>
    manifest?: IFurnitureManifest
    vendor?: string
}

export interface ICatalogFurnitureCategory {
    title: string
    models: ICatalogFurniture[]
}

export type FurnitureTree = Record<string, Record<string, ICatalogFurniture[]>>

/**
 * Categorizes the furniture catalog and returns a list of furniture categories, containing the
 * models grouped. The categorized is based on the case-insensitive comparision of the category field.
 *
 * @param catalog
 * @private
 */
function categorizeCatalog(
    t: TFunction,
    catalog: ICatalogFurniture[],
    makeCustomCategory?: boolean
): FurnitureTree {
    const custom = t(tKeys.custom)
    let tree = _.chain(catalog)
        .groupBy(
            (catalog: ICatalogFurniture) =>
                catalog.category?.toLowerCase() || custom
        )
        .mapValues((catalog) =>
            _.chain(catalog)
                .groupBy(
                    (model: ICatalogFurniture) =>
                        model.subcategory?.toLowerCase() || 'Default'
                )
                .value()
        )
        .value()

    if (!tree[custom] && makeCustomCategory) {
        tree = {
            custom: {},
            ...tree,
        }
    }

    return tree
}

/**
 * Fetches the latest furniture showcase.
 */
export async function fetchFurnitureShowcase(
    t: TFunction,
    orgId: string
): Promise<FurnitureTree> {
    const { data } = await api.organization.catalog.getFurniture({
        orgId,
    })
    if (!data) return {}
    return categorizeCatalog(t, data, true)
}

export async function fetchFurnitureByVendor(
    vendorId: string
): Promise<ICatalogFurniture[]> {
    const { data } = await api.organization.catalog.getByVendor({
        vendorId,
    })
    return data ?? []
}

export async function fetchFurnitureByCategory(
    t: TFunction,
    category: string
): Promise<FurnitureTree> {
    const { data } = await api.organization.catalog.getFurniture({
        category,
    })
    if (!data) return {}
    return categorizeCatalog(t, data)
}

/**
 * Fetches the model manifest
 *
 * @param modelId
 */
export async function fetchFurnitureModel(
    modelId: string
): Promise<ICatalogFurniture | undefined> {
    const { data } = await api.organization.catalog.getModel({
        modelId,
    })
    return data ?? undefined
}
