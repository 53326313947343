import { makeAutoObservable } from 'mobx'

import { User, RootStore } from '@teamflow/store'

export class Users {
    readonly rootStore: RootStore

    fetchingUser: User | null

    teleportingToUser: User | null

    hoveredUserId: string | null = null

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this, { rootStore: false })
        this.fetchingUser = null
        this.teleportingToUser = null
    }

    teleportToUser(user: User) {
        this.teleportingToUser = user
    }

    teleportToUserById(userId: string) {
        const user = this.rootStore.users.getUserById(userId)
        if (user) {
            this.teleportingToUser = user
        }
    }

    clearTeleportToUser() {
        this.teleportingToUser = null
    }

    fetchUser(user: User) {
        this.fetchingUser = user
    }

    fetchUserById(userId: string) {
        const user = this.rootStore.users.getUserById(userId)
        if (user) {
            this.fetchingUser = user
        }
    }

    cancelFetchingUser() {
        this.fetchingUser = null
    }

    updateHoveredUserId(id: string | null) {
        this.hoveredUserId = id
    }
}
