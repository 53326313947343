import * as t from '@teamflow/types'

export default class RoomKnockResponseCommand
    implements t.ICommand<t.IRoomKnockResponseAction>
{
    readonly messageType = t.ClientMessage.RoomKnockResponse

    private roomSessionId: string
    private roomName: string
    private guestId: string
    private allowEntry: boolean

    constructor(
        roomSessionId: string,
        roomName: string,
        guestId: string,
        allowEntry: boolean
    ) {
        this.roomSessionId = roomSessionId
        this.roomName = roomName
        this.guestId = guestId
        this.allowEntry = allowEntry
    }

    execute() {
        return {
            roomSessionId: this.roomSessionId,
            roomName: this.roomName,
            guestId: this.guestId,
            allowEntry: this.allowEntry,
        }
    }
}
