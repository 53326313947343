export default {
    missingRedirectUriParameter: 'missingRedirectUriParameter',
    missingStateParameter: 'missingStateParameter',
    loading: 'loading',
    requesterWantsToAccessYourTeamflowAccount:
        'requesterWantsToAccessYourTeamflowAccount',
    errorErrorMsg: 'errorErrorMsg',
    thisWillAllowRequesterTo: 'thisWillAllowRequesterTo',
    seeYourTeamflowRoomNamesAndInviteLinks:
        'seeYourTeamflowRoomNamesAndInviteLinks',
    allowAccess: 'allowAccess',
    cancel: 'cancel',
}
