import { makeAutoObservable } from 'mobx'

export class QuickSearch {
    isOpen = false

    constructor() {
        makeAutoObservable(this)
    }

    toggle(value?: boolean) {
        this.isOpen = value ?? !this.isOpen
    }
}
