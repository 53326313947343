import { EventEmitter } from 'eventemitter3'

import { createEventRemover } from '@teamflow/lib'

const defaultHeaders: Record<string, string> = {}

export function addDefaultHeader(key: string, value: string) {
    defaultHeaders[key] = value
}

export const tokenEvents = new EventEmitter()

export enum TokenEvents {
    Expired = 'expired',
}

export const onTokenExpired = (cb: () => void) => {
    tokenEvents.on(TokenEvents.Expired, cb)

    return createEventRemover(() => {
        tokenEvents.off(TokenEvents.Expired, cb)
    })
}

export { defaultHeaders }
