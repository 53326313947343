import * as t from '@teamflow/types'

export default class ForcedFloorNavErrorCommand
    implements t.ICommand<t.IServerError<t.NavigationError>>
{
    readonly messageType = t.ClientMessage.QueueGoToFloorError

    private code: t.NavigationError

    constructor(code: t.NavigationError) {
        this.code = code
    }

    execute() {
        return {
            code: this.code,
        }
    }
}
