import { IVerseSpaceTemplateMeta } from '@teamflow/types'

import { createRequest, request } from '../request'

/**
 * Fetch templates from the API
 *
 * @param filter Filter params to get only a subset of templates
 * @returns
 */
const list = ({
    type,
    category,
    tag,
    full,
    apiKey,
}: {
    type?: string
    category?: string
    tag?: string
    full?: string
    apiKey?: string
}) => {
    const params = new URLSearchParams()
    if (type) params.append('type', type)
    if (category) params.append('category', category)
    if (tag) params.append('tag', tag)
    if (full) params.append('full', full)

    return request<'GET /api/template'>(
        'GET',
        `/api/template?${params.toString()}`,
        {
            headers: { 'x-api-key': apiKey },
        }
    )
}

const random = ({ apiKey }: { apiKey?: string }) =>
    request<'GET /api/template/random'>('GET', '/api/template/random', {
        headers: { 'x-api-key': apiKey },
    })

/**
 * Create a template with the given meta information and roomID.
 *
 * This will copy the rooms tiles, walls, furniture and other
 * properties that makes up the "map" and stores it into a
 * template.
 */
const create = createRequest<'PUT /api/template'>('PUT', '/api/template')
const get = ({ templateId, apiKey }: { templateId: string; apiKey?: string }) =>
    request<'GET /api/template/:templateId'>(
        'GET',
        `/api/template/${templateId}`,
        { headers: { 'x-api-key': apiKey } }
    )

/**
 * Request to update the meta information for a template
 *
 *
 * @param templateId The id of the template we want to update
 * @param meta The meta information we are updating
 */
const update = ({
    templateId,
    meta,
}: {
    templateId: string
    meta: IVerseSpaceTemplateMeta
}) =>
    request<'PATCH /api/template/:templateId'>(
        'PATCH',
        `/api/template/${templateId}`,
        {
            body: {
                meta,
            },
        }
    )

/**
 * Request to delete a template from the database
 */
const del = ({ templateId }: { templateId: string }) =>
    request<'DELETE /api/template/:templateId'>(
        'DELETE',
        `/api/template/${templateId}`
    )

/**
 * Request to apply a template to a room via the API
 *
 * This is just a proxy to send the request to the Realtime endpoint
 * of: POST /:spaceId/applyTemplate
 *
 * ~~@deprecated While not truly deprecated, it should not be used under
 * normal circumstances.  Use the RT UpdateRoomSessionCommand to apply
 * via the client.~~
 *
 * NOT DEPRECATED: We need to use this when creating a new private office
 * for new users. At that point the verse is not yet loaded.
 */
const apply = ({
    templateId,
    spaceId,
    orgId,
}: {
    templateId: string
    spaceId: string
    orgId: string
}) =>
    request<'POST /api/template/:templateId/apply'>(
        'POST',
        `/api/template/${templateId}/apply`,
        {
            body: { spaceId, orgId },
        }
    )

export default {
    list,
    random,
    create,
    get,
    update,
    delete: del,
    apply,
}
