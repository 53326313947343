import { useState } from 'react'

interface ScriptState<T> {
    lib: T | null
    loaded: boolean
    scriptEl: HTMLElement | null
}

export function useScript<T>(url: string, name: string) {
    const [state, setState] = useState<ScriptState<T>>({
        lib: null,
        loaded: false,
        scriptEl: null,
    })

    const onLoad = () => {
        setState({
            ...state,
            lib: (window as any)[name],
            loaded: true,
        })
    }

    const inject = () => {
        const existingScript = document.querySelector(`[data-lib="${name}"]`)
        if (existingScript) {
            return
        }
        const scriptEl = document.createElement('script')
        scriptEl.src = url
        scriptEl.async = true
        scriptEl.setAttribute('data-lib', name)
        scriptEl.onload = onLoad
        setState({ ...state, scriptEl })
        document.body.appendChild(scriptEl)
    }

    const cleanup = () => {
        if (!state.scriptEl) return
        document.body.removeChild(state.scriptEl)
    }

    return {
        ref: state.lib as T | null,
        [name]: state.lib as T | null,
        loaded: state.loaded as boolean,
        inject,
        cleanup,
    }
}
