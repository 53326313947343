import { toStringifiedRecord } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'
import { Json } from '@teamflow/types'

export default class AppUpdateDataCommand
    implements t.ICommand<t.IAppUpdateData>
{
    readonly messageType = t.ClientMessage.AppUpdateData

    private updateRaw: Record<string, string>
    private locationId = ''

    constructor(
        private id: string,
        private update: Record<string, Json>,
        locationId: string
    ) {
        this.updateRaw = toStringifiedRecord(this.update)
        this.locationId = locationId
    }

    execute() {
        return {
            id: this.id,
            dataRaw: this.updateRaw,
            locationId: this.locationId,
        }
    }

    localExecute() {
        rootStore.app.appById.get(this.id)?.updateAppData(this.update)
    }
}
