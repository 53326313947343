import { ClientMessage } from '@teamflow/types'
import type { ICommand, IAppClosed } from '@teamflow/types'

export default class AppClosedCommand implements ICommand<IAppClosed> {
    readonly messageType = ClientMessage.AppClosed

    private id: string
    private kind: 'manual' | 'request-rt'
    private reason: string
    private locationId: string

    constructor(
        id: string,
        locationId: string,
        kind?: 'manual' | 'request-rt',
        options: {
            reason?: string
        } = {}
    ) {
        this.id = id
        this.kind = kind || 'manual'
        this.reason = options.reason || ''
        this.locationId = locationId
    }

    execute() {
        return {
            id: this.id,
            kind: this.kind,
            reason: this.reason,
            locationId: this.locationId,
        }
    }

    /* localExecute should not do anything if kind is request-rt. */
}
