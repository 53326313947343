import { Client, Room } from 'colyseus.js'

import { RealtimeServiceGlobals } from '@teamflow/bootstrap'
import { api } from '@teamflow/client-api'
import { COLYSEUS_MAX_MISS_HEARBEAT_INTERVAL, LogManager } from '@teamflow/lib'
import {
    ApiError,
    ConnectionDroppedCode,
    Feature,
    IFeatureFlagService,
    JoinOptions,
} from '@teamflow/types'

interface IWebSocketTransport {
    ws?: WebSocket
}

const logger = LogManager.createLogger('CustomColyseusClient', {
    critical: true,
})

/**
 * Custom colyseus implementation
 *
 * At any given point in time, we may have multiple RT instances running,
 * we need to be able to decide to which one we should connect
 * (the API exposes an endpoint for that) and we need to be able to
 * tell the colyseus client to connect to a dfferent RT.
 *
 * This extension of the Colyseus client allow us to change the endpoint
 * of the RT without the need to re create the entire client.
 *
 */
export class CustomColyseusClient extends Client {
    private readonly dropConnectionOnMissHeartbeat: boolean
    private readonly onMissHeartbeat: () => void

    constructor(
        endpoint: string,
        flags: IFeatureFlagService,
        /**
         * May or may not be called depending on feature flag
         */
        onMissHeartbeat: () => void
    ) {
        super(endpoint)

        this.dropConnectionOnMissHeartbeat = flags.isEnabledSync(
            Feature.DropConnectionOnMissHeartbeat
        )

        this.onMissHeartbeat = onMissHeartbeat
    }

    private addRoomHeartbeat(room: Room) {
        // don't worry, `ws` most definitely exists on Colyseus transport
        const webSocket = (room.connection.transport as IWebSocketTransport).ws
        if (!webSocket) return

        let pingTimeout: NodeJS.Timeout

        const heartbeat = () => {
            clearHeartbeat()

            pingTimeout = setTimeout(() => {
                if (this.dropConnectionOnMissHeartbeat) {
                    /* @metric client-realtime_heartbeat-not-detected */
                    logger.warn({
                        action: 'Colyseus@Heartbeat',
                        message: 'Heartbeat not detected - closing web socket',
                    })
                    webSocket?.close(
                        ConnectionDroppedCode.TFNoHeartbeat,
                        'no heartbeat'
                    )
                    this.onMissHeartbeat()
                } else {
                    /* @metric client-realtime_heartbeat-not-detected */
                    logger.warn({
                        action: 'Colyseus@Heartbeat',
                        message: 'Heartbeat not detected - doing nothing',
                    })
                }
            }, COLYSEUS_MAX_MISS_HEARBEAT_INTERVAL)
        }

        const clearHeartbeat = () => {
            clearTimeout(pingTimeout)
        }

        const logError = (error: Event) => {
            logger.error({
                action: 'Colyseus@SocketError',
                error,
            })
        }

        const clearEventListeners = () => {
            clearHeartbeat()

            webSocket.removeEventListener('open', heartbeat)
            webSocket.removeEventListener('message', heartbeat)
            webSocket.removeEventListener('close', clearEventListeners)
            webSocket.removeEventListener('error', logError)
        }

        webSocket.addEventListener('open', heartbeat)
        webSocket.addEventListener('message', heartbeat)
        webSocket.addEventListener('close', clearEventListeners)
        webSocket.addEventListener('error', logError)
    }

    /**
     * Retrieve and update the RT url we should use for the given org
     *
     * In case of error, the previously retrieved url will be used.
     * If we was never able to retrieve an url from the API, the
     * env var NEXT_PUBLIC_RT_WS_ENDPOINT (default value) will be used.
     *
     * @param orgId
     * @return The error while fetching the endpoint if any.
     */
    async updateEndpoint(orgId: string): Promise<{
        error: ApiError | null
    }> {
        const { data: instance, error } = await api.instances.match({
            orgId,
        })
        if (instance) {
            const newEndpoint = instance.wsUrl.toString()
            if (newEndpoint) {
                this.endpoint = newEndpoint
                RealtimeServiceGlobals.httpEndpoint = instance.httpUrl
                RealtimeServiceGlobals.wsEndpoint = instance.wsUrl
            }
        }
        return {
            error: error ?? null,
        }
    }

    async reconnect<T>(roomId: string, sessionId: string): Promise<Room<T>> {
        logger.info({
            action: 'Colyseus@reconnect',
            message: 'Reconnecting to ' + this.endpoint,
        })
        // Uncomment to test deployment disconnected window:
        // await waitForSeconds(10)
        const room = await super.reconnect<T>(roomId, sessionId)
        this.addRoomHeartbeat(room)
        return room
    }

    async joinById<T>(roomId: string, options?: JoinOptions): Promise<Room<T>> {
        logger.info({
            action: 'Colyseus@joinById',
            message: `Joining ${roomId} at ${this.endpoint}`,
        })
        const room = await super.joinById<T>(roomId, options)
        this.addRoomHeartbeat(room)
        return room
    }
}
