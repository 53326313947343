import { makeAutoObservable } from 'mobx'

import { editable } from '@teamflow/lib'
import { AppConfig, AppType } from '@teamflow/types'

import { RootStore } from './rootStore'

export class AppConfigStore {
    rootStore: RootStore

    readonly configsByType: ReadonlyMap<AppType, AppConfig> = new Map()

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
        })
        this.rootStore = rootStore
    }

    get configsByName(): ReadonlyMap<string, AppConfig> {
        return new Map(
            Array.from(this.configsByType.values()).map((c) => [c.name, c])
        )
    }

    getConfig(type: AppType) {
        const config = this.configsByType.get(type)
        if (config === undefined) {
            throw new RangeError(`Missing config for type: ${AppType[type]}`)
        }
        return config
    }

    getConfigByName(name: string) {
        const config = this.configsByName.get(name)
        return config
    }

    addConfig(config: AppConfig) {
        editable(this.configsByType).set(config.type, config)
    }

    deleteConfig(type: AppType) {
        editable(this.configsByType).delete(type)
    }
}
