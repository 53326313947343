import * as t from '@teamflow/types'

type P = Partial<{
    backgroundImage: string | undefined
    backgroundPattern: t.BackgroundPattern | undefined
}>

/**
 * Only send this command when the background changes ON THE MAIN FLOOR.
 *
 * @group Commands
 */
export default class UpdateBackgroundImageCommand implements t.ICommand<P> {
    readonly messageType = t.ClientMessage.BackgroundImageChanged

    private backgroundImage?: string
    private backgroundPattern?: t.BackgroundPattern

    constructor(payload: P) {
        Object.assign(this, payload)
    }

    execute() {
        return {
            backgroundImage: this.backgroundImage,
            backgroundPattern: this.backgroundPattern,
        }
    }
}
