import { useEffect } from 'react'

import { loadLocalUser } from '@teamflow/bootstrap'
import rootStore from '@teamflow/store'

export function useLoadLocalUser(currentRoute: string) {
    useEffect(() => {
        if (
            currentRoute !== '/logout' &&
            currentRoute !== '/[...org]' &&
            !rootStore.users.hasRequestedLocalUser
        ) {
            void loadLocalUser()
        }
    }, [currentRoute])
}
