import * as t from '@teamflow/types'

type Payload = {
    presenterId: string
    locationId: string
    attendeeId: string
}

export default class PresentationLeaveCommand
    implements t.ICommand<Partial<t.IPresentation>>
{
    readonly messageType = t.ClientMessage.PresentationLeave

    private payload: Payload

    constructor(payload: Payload) {
        this.payload = payload
    }

    execute() {
        return this.payload
    }
}
