import * as t from '@teamflow/types'

/** @group Commands */
export default class GetUserCommand implements t.ICommand<t.IGetUser> {
    readonly messageType = t.ClientMessage.GetUser

    private initiatedUserId: string
    private askedForUserId: string
    private inviteCode?: string

    constructor(
        initiatedUserId: string,
        askedForUserId: string,
        inviteCode?: string
    ) {
        this.initiatedUserId = initiatedUserId
        this.askedForUserId = askedForUserId
        this.inviteCode = inviteCode
    }

    execute() {
        return {
            initiatedUserId: this.initiatedUserId,
            askedForUserId: this.askedForUserId,
            inviteCode: this.inviteCode,
        }
    }
}
