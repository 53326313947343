import rootStore from '@teamflow/store'

import { Dock } from './ui/Dock'
import { EphemeralReactions } from './ui/EphemeralReactions'
import { Furniture } from './ui/Furniture'
import { LevelEditor } from './ui/LevelEditor'
import { Minimap } from './ui/Minimap'
import { Notifications } from './ui/Notifications'
import { QuickSearch } from './ui/QuickSearch'
import { TopHeader } from './ui/TopHeader'
import { Users } from './ui/Users'

export class UIStore {
    readonly dock: Dock
    readonly users: Users
    readonly minimap: Minimap
    readonly topHeader: TopHeader
    readonly levelEditor: LevelEditor
    readonly notifications: Notifications
    readonly furniture: Furniture
    readonly ephemeralReactions: EphemeralReactions
    readonly quickSearch: QuickSearch

    constructor() {
        this.dock = new Dock(this)
        this.users = new Users(rootStore)
        this.minimap = new Minimap(this)
        this.topHeader = new TopHeader(this)
        this.furniture = new Furniture()
        this.levelEditor = new LevelEditor()
        this.notifications = new Notifications()
        this.ephemeralReactions = new EphemeralReactions(this)
        this.quickSearch = new QuickSearch()
    }
}

const uiStore = new UIStore()

if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
    ;(globalThis as any).tf_ui_store = uiStore
}

export default uiStore
