import {
    ParticipantChangeAvailability as ParticipantChangeAvailabilityCommand,
    ParticipantFocusModeCommand,
} from '@teamflow/client-realtime'
import rootStore from '@teamflow/store'
import { AvailabilityStatus, IRealtimeService } from '@teamflow/types'

import { ElectronService } from '../verse/services/ElectronService'

import setCustomStatus from './setCustomStatus'

export default function updateAvailability(
    { userId, realtime }: { userId: string; realtime: IRealtimeService },
    status: AvailabilityStatus,
    manual = true
) {
    const { audioVideo, participants } = rootStore
    const { localParticipant } = participants
    if (localParticipant) {
        localParticipant.update({ availability: status })
        // if the current status is a no-bubble one, we overwrite it, otherwise the Busy availability bubble would not appear
        if (
            manual &&
            localParticipant.statusMetadata &&
            localParticipant.statusMetadata.kind === 'no-bubble'
        ) {
            void setCustomStatus(realtime)
        }
    }
    realtime.dispatch(new ParticipantChangeAvailabilityCommand(status, manual))
    void audioVideo.handleAvailabilityBusy(status === AvailabilityStatus.BUSY)

    const focusModeActive = status == AvailabilityStatus.BUSY
    realtime.dispatch(new ParticipantFocusModeCommand(userId, focusModeActive))

    if (ElectronService?.available) {
        ElectronService.setUserAvailability(status)
    }
}
