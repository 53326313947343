import * as t from '@teamflow/types'

export default class MuteUserCommand implements t.ICommand<t.IMuteUser> {
    readonly messageType = t.ClientMessage.MuteUser

    private initiatedUserId: string
    private targetUserId: string

    constructor(initiatedUserId: string, targetUserId: string) {
        this.initiatedUserId = initiatedUserId
        this.targetUserId = targetUserId
    }

    execute() {
        return {
            initiatedUserId: this.initiatedUserId,
            targetUserId: this.targetUserId,
        }
    }
}
