import { makeAutoObservable } from 'mobx'

import { processActions } from '../processActions'
import { ActionContext, IActionDefinition, TriggerResult } from '../types'

type Payload = {
    title?: string
    body?: JSX.Element | null
    url?: string
    width?: number
    height?: number
    confirmText?: string
    confirmActions?: IActionDefinition[]
    dismissText?: string
    dismissActions?: IActionDefinition[]
}

type GenericModalStore = {
    isOpen: boolean
    open: () => void
    close: () => void
    confirm: () => void
    dismiss: () => void
} & Pick<
    Payload,
    | 'title'
    | 'body'
    | 'url'
    | 'width'
    | 'height'
    | 'confirmText'
    | 'dismissText'
>

function createStore() {
    const store: GenericModalStore = {
        isOpen: false,
        open() {
            store.isOpen = true
        },
        close() {
            store.isOpen = false
        },
        confirm() {
            store.close()
        },
        dismiss() {
            store.close()
        },
    }
    return store
}

export const store = makeAutoObservable(createStore())

export async function openGenericModal(
    _: ActionContext,
    payload: Payload = {}
) {
    const {
        title,
        body,
        url,
        width,
        height,
        confirmText,
        confirmActions = [],
        dismissText,
        dismissActions = [],
    } = payload

    store.title = title
    store.body = body
    store.url = url
    store.width = width
    store.height = height
    store.confirmText = confirmText
    store.dismissText = dismissText

    if (confirmActions.length > 0) {
        store.confirm = async () => {
            await processActions(confirmActions)
            store.close()
        }
    }

    if (dismissActions.length > 0) {
        store.dismiss = async () => {
            await processActions(dismissActions)
            store.close()
        }
    }

    store.open()

    return TriggerResult.Break
}
