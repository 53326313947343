import debounce from 'lodash/debounce'
import { autorun, makeAutoObservable, runInAction } from 'mobx'
import { clearPersistedStore, makePersistable } from 'mobx-persist-store'

import {
    DEFAULT_LOCALE,
    DEFAULT_MOVEMENT_SPEED_PER_FRAME,
    waitForMilliseconds,
} from '@teamflow/lib'
import { Calendar, ScreenSharePreset, SettingsState } from '@teamflow/types'

import { logger } from './logging'

const HYDRATION_TIMEOUT = 500

export interface SettingsAdapter {
    save(data: Partial<SettingsState>): Promise<void>
    getUnsavedData?: () => Partial<SettingsState>
}

class NullSettingsAdapter implements SettingsAdapter {
    _unsavedData: Partial<SettingsState> = {}

    async save(data: Partial<SettingsState>): Promise<void> {
        logger.error('Failed to save settings (not ready)', data)
        this._unsavedData = { ...this._unsavedData, ...data }
    }

    getUnsavedData(): Partial<SettingsState> {
        return this._unsavedData
    }
}
export interface ReactionEmojiEntry {
    readonly text: string
    readonly isSticky: boolean
}
const DEFAULT_EMOJIS = [
    { text: '✋', isSticky: true },
    { text: '👉', isSticky: true },
    { text: '👍', isSticky: false },
    { text: '👎', isSticky: false },
    { text: '🎉', isSticky: false },
    { text: '💯', isSticky: false },
    { text: '👋', isSticky: false },
    { text: '👏', isSticky: false },
]

export class SettingsStore implements SettingsState {
    /**
     * Whether settings have been retrieved from api. See useInitSettings.tsx.
     */
    hydrated = false

    locale = ''
    autoMuteDelayMins = 0
    pushToTalk = false
    browserNotifications = false
    emailNotifications = false
    calendarStatusUpdates = false
    avatarMovementSpeed = DEFAULT_MOVEMENT_SPEED_PER_FRAME
    scrollToZoom = false
    openPipOnScreenShare = true
    autoFaceCentering = false
    manualFaceZoom = 0
    manualFaceOffsetX = 0
    manualFaceOffsetY = 0
    /**
     * Only flips local user's own video feed. Other users do not see the
     * user flipped.
     */
    flipCamera = true
    rememberAVState = true
    screenSharePreset: ScreenSharePreset = ScreenSharePreset.TEXT
    chatSound = true
    knockSound = true
    joinSound = true
    cameSound = true
    joinedSound = true
    leftSound = true
    tapSound = true
    showMinimap = true
    radicalShowMinimap = false
    preferredCalendar = Calendar.GoogleCalendar
    lite = false
    showJoinLink = true
    autoSpatialRibbonChange = true
    masterVolume = 1
    // used for tracking state of volume when muting or unmuting
    previousMasterVolume: number | null = null
    // used to display the AV setup in a modal when user joins the app
    alwaysDisplayAvSetup = true
    // used to display ShareAudioModal
    showShareAudioTutorial = true
    // how many apps will load their embed content on the screen at a time
    maxLoadedApps = 10
    // how quiet to make Teamflow when a call is being dialed for Salesfloor
    salesfloorCallDialingTFVolume = 0.3

    /**
     * Indicates if the user explicitly disconnected e.g. user clicked on the button disconnect in the sidebar
     */
    manuallyDisconnected = false
    /* time when user clicks hang up button so we can track how long they are offline for analytics purposes */
    timeWentIntoOfflineMode: number | null = null

    /**
     * Customizable set of emojis to use for reactions
     */
    reactionEmojis: ReactionEmojiEntry[] = DEFAULT_EMOJIS

    _adapter: SettingsAdapter = new NullSettingsAdapter()
    _debouncedSave?: ReturnType<typeof debounce> &
        ((data: Partial<SettingsState>) => void)
    _debouncedEdits: Partial<SettingsState> = {}

    constructor() {
        makeAutoObservable(this, {
            _adapter: false,
            adapterUnsavedData: false,
            waitForHydration: false,
            save: false,
            debouncedSave: false,
            _debouncedSave: false,
            _debouncedEdits: false,
        })

        /**
         * Remember to also update:
         * - packages/api `models/Settings.ts`
         * - packages/types `state/settings.ts`
         * - the `reset` method here
         */
        void makePersistable(this, {
            name: 'SettingsStore',
            properties: [
                'locale',
                'autoMuteDelayMins',
                'pushToTalk',
                'browserNotifications',
                'emailNotifications',
                'calendarStatusUpdates',
                'avatarMovementSpeed',
                'scrollToZoom',
                'openPipOnScreenShare',
                'autoFaceCentering',
                'manualFaceZoom',
                'manualFaceOffsetX',
                'manualFaceOffsetY',
                'flipCamera',
                'rememberAVState',
                'screenSharePreset',
                'chatSound',
                'knockSound',
                'joinSound',
                'cameSound',
                'joinedSound',
                'leftSound',
                'tapSound',
                'showMinimap',
                'radicalShowMinimap',
                'preferredCalendar',
                'lite',
                'autoSpatialRibbonChange',
                'alwaysDisplayAvSetup',
                'maxLoadedApps',
                'reactionEmojis',
                'showShareAudioTutorial',
            ],
            // Stop it blowing up with SSR
            storage:
                typeof window !== 'undefined' ? window.localStorage : undefined,
            // Set to true for verbose logs
            debugMode: false,
        })
    }

    get avatarMovementPerSecond(): number {
        return this.avatarMovementSpeed * 30
    }

    get adapterUnsavedData() {
        return this._adapter.getUnsavedData?.() ?? {}
    }

    setAdapter(adapter: SettingsAdapter): () => void {
        this._adapter = adapter

        return () => {
            if (this._adapter === adapter)
                this._adapter = new NullSettingsAdapter()
        }
    }

    update(
        partial: Partial<SettingsState>,
        skipSave = false,
        debounceSave = true
    ) {
        let settingsChanged = false
        Object.entries(partial).forEach(([key, value]) => {
            ;(this as any)[key] = value
            settingsChanged = true
        })
        if (skipSave || !settingsChanged) return
        if (debounceSave) {
            Object.assign(this._debouncedEdits, partial)
            this.debouncedSave()
        } else {
            void this.save(partial)
        }
    }

    /**
     * setLocale
     *
     * Special method for locale to
     * save on server immediately
     *
     * @param locale
     */
    async setLocale(locale: string = DEFAULT_LOCALE) {
        await this.save({ locale })
        runInAction(() => {
            this.locale = locale
        })
    }

    /**
     * clearLocale
     *
     * Used on logout
     */
    clearLocale() {
        this.locale = ''
    }

    reset(shouldClearPersistentStore = false) {
        if (shouldClearPersistentStore) {
            void clearPersistedStore(this)
        }

        this.hydrated = false
        this.locale = ''
        this.autoMuteDelayMins = 0
        this.browserNotifications = false
        this.emailNotifications = false
        this.calendarStatusUpdates = false
        this.avatarMovementSpeed = DEFAULT_MOVEMENT_SPEED_PER_FRAME
        this.scrollToZoom = false
        this.openPipOnScreenShare = true
        this.autoFaceCentering = false
        this.manualFaceZoom = 0
        this.manualFaceOffsetX = 0
        this.manualFaceOffsetY = 0
        this.flipCamera = true
        this.rememberAVState = true
        this.screenSharePreset = ScreenSharePreset.TEXT
        this.chatSound = true
        this.knockSound = true
        this.joinSound = true
        this.cameSound = true
        this.joinedSound = true
        this.leftSound = true
        this.tapSound = true
        this.showMinimap = true
        this.radicalShowMinimap = false
        this.preferredCalendar = Calendar.GoogleCalendar
        this.lite = false
        this.manuallyDisconnected = false
        this.reactionEmojis = DEFAULT_EMOJIS
    }

    setHydrated(value: boolean) {
        this.hydrated = value
    }

    async waitForHydration(timeout = HYDRATION_TIMEOUT) {
        const hydrationPromise = new Promise<void>((resolve) => {
            autorun(() => {
                if (this.hydrated) resolve()
            })
        })
        await Promise.race([hydrationPromise, waitForMilliseconds(timeout)])
    }

    save = async (data: Partial<SettingsState>) => {
        await this._adapter.save(data)
    }

    get debouncedSave(): () => unknown {
        // Lazy initializer because lodash's debounce can cause infinite recursion in
        // setTimeout event loop.
        if (!this._debouncedSave) {
            this._debouncedSave = debounce(
                () => {
                    const promise = this.save(this._debouncedEdits)
                    this._debouncedEdits = {}
                    return promise
                },
                1000,
                {
                    leading: false,
                    trailing: true,
                }
            )
        }

        return this._debouncedSave
    }

    setManuallyDisconnected(disconnected: boolean) {
        this.manuallyDisconnected = disconnected
    }

    setEmoji(index: number, emoji: ReactionEmojiEntry) {
        if (index < 0 || index >= this.reactionEmojis.length) {
            return
        }
        this.reactionEmojis[index] = emoji
    }
}
