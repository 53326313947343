import { computed, makeAutoObservable, observable, ObservableSet } from 'mobx'

import { AVATAR_SIZE } from '@teamflow/lib'
import * as t from '@teamflow/types'

import { App } from './App'
import { logger } from './logging'
import { RootStore } from './rootStore'

const EMPTY_SET = new Set<string>()

export class AppStore {
    rootStore: RootStore

    selectedAppId = ''
    lastSelectedAppId = ''

    appById = observable<string, App>(new Map())
    appsByLocationId = observable<string, ObservableSet<App>>(new Map())

    activeAppModal: App | null = null

    indexCurrentAppTab: number | undefined

    maxAppsFeatureEnabled = false

    // Need to get this from RT state since state splitting may make it so not all apps are in local store
    globalAppCount = 0

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            getUserScreenShare: false,
            getAppsInRoom: false,
            appsInSameLocation: computed.struct,
            appsInSameLocationAndNeedingVisibilityCheck: computed.struct,
            appsInSameLocationUnpinned: computed.struct,
            appsInSameLocationPinned: computed.struct,
            localUserHasScreenShare: computed,
            appsOnMainFloor: computed.struct,
            appsRendered: computed.struct,
            appIdsOverlappingLocalUser: computed.struct,
        })
        this.rootStore = rootStore
    }

    reset() {
        this.selectedAppId = ''
        this.lastSelectedAppId = ''

        this.appById.clear()
        this.appsByLocationId.clear()
    }

    getAppById(appId: string) {
        return (
            this.appById.get(appId) ??
            (this.activeAppModal?.id === appId
                ? this.activeAppModal
                : undefined)
        )
    }

    addApp(appData: t.IApp, config: t.AppConfig) {
        const existing = this.appById.get(appData.id)
        if (existing) {
            existing.update(appData)
            return existing
        }
        const app = new App(this.rootStore, appData, config)

        this.appById.set(app.id, app)

        let locationIdSet = this.appsByLocationId.get(app.locationId)
        if (!locationIdSet) {
            locationIdSet = observable(new Set<App>())
            this.appsByLocationId.set(app.locationId, locationIdSet)
        }
        locationIdSet.add(app)

        return app
    }

    removeApp(appId: string) {
        const app = this.appById.get(appId)
        if (!app) return

        this.appById.delete(appId)

        this.appsByLocationId.get(app.locationId)?.delete(app)
        // if true, app.locationId must exist as key in appsByLocationId and
        // corresponding set must be empty
        if (this.appsByLocationId.get(app.locationId)?.size === 0) {
            this.appsByLocationId.delete(app.locationId)
        }
    }

    setSelectedApp(appId: string) {
        this.lastSelectedAppId = this.selectedAppId
        this.appById
            .get(this.lastSelectedAppId)
            ?.update({ needsVisibilityCheck: true })
        this.selectedAppId = appId
        this.appById
            .get(this.selectedAppId)
            ?.update({ needsVisibilityCheck: true })
        this.appById.get(appId)?.load()
        if (this.rootStore.presentations.presenting) {
            void this.rootStore.presentations.switchApp(
                appId,
                this.rootStore.commons.viewingSpace
            )
        }
    }

    setIndexCurrentAppTab(newIndex: number | undefined) {
        this.indexCurrentAppTab = newIndex
    }

    setAppModal(appData: t.IApp | null) {
        if (
            this.activeAppModal &&
            appData &&
            this.activeAppModal.id === appData.id
        ) {
            this.activeAppModal.update(appData)
        } else if (appData) {
            try {
                const app = new App(
                    this.rootStore,
                    appData,
                    this.rootStore.appConfig.getConfig(appData.type)
                )
                this.activeAppModal = app
            } catch (e) {
                logger.warn('AppStore.setAppModal error', e)
            }
        } else {
            this.activeAppModal = null
        }
    }

    getUserScreenShare(userId: string): App | null {
        const apps = this.appById.values()
        for (const app of apps) {
            if (app.type === t.AppType.ScreenShare && app.owner === userId) {
                return app
            }
        }
        return null
    }

    getAppsInRoom(roomId: string) {
        return Array.from(this.appsByLocationId.get(roomId) ?? [])
    }

    get appsInSameLocation(): ReadonlyArray<App> {
        const currentSpace = this.rootStore.commons.viewingSpace
        return Array.from(this.appsByLocationId.get(currentSpace) ?? []).filter(
            (app) => !app.embedded
        )
    }

    get appsInSameLocationAndNeedingVisibilityCheck(): ReadonlyArray<App> {
        return this.appsInSameLocation.filter((app) => app.needsVisibilityCheck)
    }

    get appsInSameLocationUnpinned(): ReadonlyArray<App> {
        return this.appsInSameLocation.filter((app) => !app.pinned)
    }

    get appsInSameLocationPinned(): ReadonlyArray<App> {
        return this.appsInSameLocation.filter((app) => app.pinned)
    }

    get appsOnMainFloor(): ReadonlyArray<App> {
        return Array.from(this.appsByLocationId.get('') ?? [])
    }

    get appsRendered(): ReadonlyArray<App> {
        return this.appsInSameLocation.filter((app) => app.shouldRender)
    }

    get appsRenderedHeadless(): ReadonlyArray<App> {
        return this.appsInSameLocation.filter(
            (app) => app.shouldRender && app.headless
        )
    }

    get appIdsOverlappingLocalUser(): Set<string> {
        const appIds =
            this.rootStore.participants.localParticipant &&
            this.rootStore.spatialHash.queryByTypedIntersection('app', {
                left:
                    this.rootStore.participants.localParticipant?.x -
                    AVATAR_SIZE / 2,
                top:
                    this.rootStore.participants.localParticipant?.y -
                    AVATAR_SIZE / 2,
                right:
                    this.rootStore.participants.localParticipant?.x +
                    AVATAR_SIZE / 2,
                bottom:
                    this.rootStore.participants.localParticipant?.y +
                    AVATAR_SIZE / 2,
            })
        return appIds ?? EMPTY_SET
    }

    get localUserHasScreenShare(): boolean {
        const localUserId = this.rootStore.users.localUserId
        return localUserId ? !!this.getUserScreenShare(localUserId) : false
    }

    get selectedApp() {
        return this.appById.get(this.selectedAppId)
    }

    get highestZApp() {
        if (!this.appById.size) {
            return undefined
        }

        const apps = Array.from(this.appById.values())

        let highestApp = apps[0]
        for (const app of apps) {
            if (!highestApp || app.z > highestApp.z) {
                highestApp = app
            }
        }
        return highestApp
    }

    get highestZIndex() {
        return this.highestZApp?.z ?? 0
    }

    get count() {
        return this.appById.size
    }

    get mostSelectedAppInSameLocation(): App | undefined {
        if (this.appsInSameLocation.length === 0) return
        const participantsByApp = new Map<number, string>()
        this.appsInSameLocation.forEach((app) => {
            const numberOfParticipants =
                this.rootStore.participants.findParticipantsBySelectedAppId(
                    app.id
                ).length
            if (numberOfParticipants > 0) {
                participantsByApp.set(numberOfParticipants, app.id)
            }
        })
        if (participantsByApp.size === 0) return
        const rankApps = [...participantsByApp.entries()].sort().reverse()
        return this.getAppById([...rankApps][0][1])
    }
}
