import { forwardRef, PropsWithChildren } from 'react'

import { Box } from '@teamflow/design'

export const NonDraggableElectron = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{}>
>(function NonDraggableElectron({ children, ...props }, ref) {
    return (
        <Box
            className="electron-non-draggable"
            display="flex"
            justifyContent="center"
            alignItems="center"
            ref={ref}
            {...props}
        >
            {children}
        </Box>
    )
})
