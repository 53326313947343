import { createRequest, request, saveAccessToken } from '../request'

const user = {
    get: ({ inviteCode }: { inviteCode: string }) =>
        request<'GET /api/inviteCode/:id'>(
            'GET',
            `/api/inviteCode/${inviteCode}`
        ).then(saveAccessToken('idToken')),

    create: ({ inviteCode }: { inviteCode: string }) =>
        request<'POST /api/inviteCode/:id'>(
            'POST',
            `/api/inviteCode/${inviteCode}`
        ).then(saveAccessToken('idToken')),
}

const floor = {
    get: createRequest<'GET /api/inviteCode/floor'>(
        'GET',
        '/api/inviteCode/floor'
    ),
    regenerate: createRequest<'POST /api/inviteCode/floor'>(
        'POST',
        '/api/inviteCode/floor'
    ),
}

const room = {
    get: ({ roomSessionId }: { roomSessionId: string }) =>
        request<'GET /api/inviteCode/:id/roomSession'>(
            'GET',
            `/api/inviteCode/${roomSessionId}/roomSession`
        ),
    regenerate: ({
        roomSessionId,
        toggle,
    }: {
        roomSessionId: string
        toggle: boolean
    }) =>
        request<'POST /api/inviteCode/:id/roomSession'>(
            'POST',
            `/api/inviteCode/${roomSessionId}/roomSession`,
            {
                body: { toggle },
            }
        ),
}

export default {
    user,
    floor,
    room,
}
