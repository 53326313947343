import { useState } from 'react'

import { SpaceEvents } from '@teamflow/types'
import type { SpaceService } from '@teamflow/verse'
import type { Verse } from '@teamflow/web/src/verse'

import { useEvent } from './useEvent'

type ExtractSpaces<T> = T extends SpaceService<infer S> ? S : never
type VerseSpaces = ExtractSpaces<Verse['spaces']>
type VerseEntities = keyof VerseSpaces
type SpaceForEntity<T extends VerseEntities> = VerseSpaces[T]

/**
 * Use a space, listening to space started & stopped events.
 * @param verse
 * @param entity
 */
export function useParallelSpace<TEntity extends VerseEntities>(
    verse: Verse,
    entity: TEntity
): SpaceForEntity<TEntity> | undefined {
    const [space, setSpace] = useState(verse.spaces.findByEntity(entity))

    useEvent(
        (startedSpace: SpaceForEntity<TEntity>) => {
            if (startedSpace.ENTITY === entity) {
                setSpace(startedSpace)
            }
        },
        SpaceEvents.SpaceStarted,
        verse.spaces
    )

    useEvent(
        (stoppedSpace: SpaceForEntity<TEntity>) => {
            if (stoppedSpace.ENTITY === entity) {
                setSpace(undefined)
            }
        },
        SpaceEvents.SpaceStopped,
        verse.spaces
    )

    return space
}
