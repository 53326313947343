import EventEmitter from 'eventemitter3'

import * as u from '@teamflow/lib'
import type { ISerializable } from '@teamflow/types'
import IService from '@teamflow/types/src/verse/IService'
import type { VerseBase } from '@teamflow/verse'

/**
 * A service is a "functional" pillar of the Teamflow application. The {@link ServiceLike} class provides
 * a framework for creating services.
 *
 * A service class should extend from {@link Service} or {@link BindableService} instead of this class.
 *
 * * {@link Service}: A service that is tightly coupled with its {@link Verse}, and is created and destroyed
 *      with the Verse.
 * * {@link BindableService}: A service that is loosely coupled with its {@link Verse}. It can be created
 *      and destroyed independent of a Verse. It could even be reused when the Verse is recreated.
 *
 * @group Services
 */
export default class ServiceLike<TVerse extends VerseBase = VerseBase>
    implements IService, ISerializable
{
    protected events: EventEmitter
    managedSubscriptions: Array<u.IEventRemover> = []

    private _verse?: TVerse

    constructor() {
        this.events = new EventEmitter()
    }

    get verse(): TVerse | undefined {
        return this._verse
    }
    set verse(value: TVerse | undefined) {
        this._verse = value
    }

    public destroy() {
        this.onDestroy()
        this.events.removeAllListeners()
    }

    public addListener(event: string, callback: Function, context?: any): this {
        this.events.addListener(
            event,
            callback as (...args: any[]) => void,
            context
        )

        return this
    }

    public removeListener(
        event: string,
        callback: Function,
        context?: any
    ): this {
        this.events.removeListener(
            event,
            callback as (...args: any[]) => void,
            context
        )

        return this
    }

    public removeAllListeners() {
        this.events.removeAllListeners()
    }

    public serialize() {
        return {
            type: this.constructor.name,
        }
    }

    protected emit(event: string | symbol, ...args: any[]): this {
        this.events.emit(event, ...args)

        return this
    }

    protected onDestroy(): void {
        this.managedSubscriptions.forEach((sub) => sub.remove())
        this.managedSubscriptions.length = 0

        /* Call Super */
    }
}
