import {
    makeAutoObservable,
    $mobx,
    AnnotationsMap,
    CreateObservableOptions,
    isObservableArray,
} from 'mobx'

export type AutoObserved<T extends object> = T & { $mobxAutoObserved: never }

/**
 * Same input/output as `makeAutoObservable`, but:
 *   - Only does it if the object is not already auto-observed
 *   - Uses a special type so you can ensure an object is auto-observed
 */
export function makeAutoObservableOnceWithType<T extends object>(
    target: T,
    overrides?: AnnotationsMap<T, never> | undefined,
    options?: CreateObservableOptions | undefined
): AutoObserved<T> {
    const ret = (target as any)[$mobx]
        ? target
        : makeAutoObservable(target, overrides, options)
    return ret as AutoObserved<T>
}

/**
 * This will check if:
 * - `observableArray` is actually an observable array
 * - `array` is actually an array
 * - both have the same elements
 * @param observableArray
 * @param array
 */
export function isSameArray(observableArray: any, array: any): boolean {
    return (
        isObservableArray(observableArray) &&
        Array.isArray(array) &&
        observableArray.length === array.length &&
        array.every(
            (indexValue, index) => indexValue === observableArray[index]
        )
    )
}
