import { useMemo } from 'react'

import rootStore from '@teamflow/store'
import { IToastFunc } from '@teamflow/types'

export default function useHuddleToast() {
    const toast = useMemo(() => createStandloneHuddleToast(), [])
    return toast
}

export { useHuddleToast }

export function createStandloneHuddleToast(): IToastFunc {
    return (content) => {
        const topBanner = document.querySelector('.top-banner')
        let offSet = topBanner ? 50 : 0
        offSet += 96

        rootStore.toast.updateOffset(offSet)

        return rootStore.toast.add(content)
    }
}
