import { fromUnixTime, addYears } from 'date-fns'
import { makeAutoObservable } from 'mobx'

import {
    IOrganizationFreeTrial,
    IOrganizationTierState,
    ITier,
    OrganizationTierType,
} from '@teamflow/types'

import { RootStore } from './rootStore'

export class TierStore {
    rootStore: RootStore

    state: IOrganizationTierState | undefined

    startTime: Date = new Date()
    endTime: Date = addYears(new Date(), 1)

    isTrial = false
    trialDaysLeft = 0
    trialPeriod = 1000

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
        })
        this.rootStore = rootStore
    }

    reset() {
        this.state = undefined
        this.startTime = new Date()
        this.endTime = addYears(new Date(), 1)
        this.isTrial = false
        this.trialDaysLeft = 0
        this.trialPeriod = 1000
    }

    update(data: ITier) {
        this.updateFreeTrial(data)
        this.updateTierState(data.tierState)
    }

    updateFreeTrial(data: IOrganizationFreeTrial) {
        this.isTrial = data.trial
        this.trialDaysLeft = data.trialDaysLeft
        this.trialPeriod = data.trialPeriod
    }

    updateTierState(state: IOrganizationTierState) {
        this.state = { ...state }
        if (!this.isFree) {
            // no meeting time limits on non-free tiers
            this.startTime = new Date()
            this.endTime = addYears(new Date(), 1)
        } else {
            this.startTime = fromUnixTime(state.meetingStartTime)
            this.endTime = fromUnixTime(state.meetingEndTime)
        }
    }

    get isFree() {
        return this.state?.tierType === OrganizationTierType.Free
    }

    get isSeed() {
        return this.state?.tierType === OrganizationTierType.Seed
    }

    get isLimited() {
        switch (this.state?.tierType) {
            case OrganizationTierType.Free:
            case OrganizationTierType.Seed:
                return true

            case OrganizationTierType.Pro:
            case OrganizationTierType.Business:
            case OrganizationTierType.BusinessTrial:
                return false
            default:
                return false
        }
    }

    get isPaid() {
        switch (this.state?.tierType) {
            case OrganizationTierType.Business:
            case OrganizationTierType.Seed:
                return true

            default:
                return false
        }
    }

    get meetingEndTime() {
        return this.endTime
    }

    get maxUsers() {
        const maxUsers = this.state?.limits?.maxUsers ?? -1
        if (maxUsers < 0 || !this.isLimited) {
            return Infinity
        }

        return maxUsers
    }

    get maxApps() {
        const maxApps = this.state?.limits?.maxApps ?? -1
        if (maxApps < 0) {
            return Infinity
        }

        return maxApps
    }

    get maxAppsReached() {
        return this.rootStore.app.globalAppCount >= this.maxApps
    }
}
