/* eslint-disable no-console */
import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'

import { featureFlags } from '@teamflow/bootstrap'
import { apiConfig } from '@teamflow/client-api'
import { BufferedLogger, LogManager, getSession } from '@teamflow/lib'
import { ILoggerTransport, LogLevel, LogTuple } from '@teamflow/types'

import { Intercept } from './observability/Intercept'

class LogRocketLoggerTransport implements ILoggerTransport {
    print(data: {
        level: LogLevel
        log: LogTuple
        metadata: Record<string, string>
    }) {
        switch (data.level) {
            case 'debug':
                LogRocket.debug(...data.log)
                break
            default:
            case 'log':
                LogRocket.log(...data.log)
                break
            case 'info':
                LogRocket.info(...data.log)
                break
            case 'warn':
                LogRocket.warn(...data.log)
                break
            case 'error':
                // LogRocket has strange behavior with console.error where it only logs the
                // first param and ignores the other params. The other functions don't have
                // this issue. Their support team blames Sentry, which intercepts the original
                // error function, but they don't have a clear explanation.
                //
                // Our workaround is to log an extra warn, which LogRocket does fully capture.
                LogRocket.error(...data.log)
                if (data.log.length > 1) LogRocket.warn(...data.log)
                break
        }
    }
}

if (
    process.env.NEXT_PUBLIC_APP_ENV !== 'local' &&
    process.env.NEXT_PUBLIC_LOGGING_ENDPOINT
)
    LogManager.cloudLogging(process.env.NEXT_PUBLIC_LOGGING_ENDPOINT, Sentry, {
        sha: process.env.NEXT_PUBLIC_COMMIT_SHA_SHORT,
    })
else console.warn('Cloud logging is disabled on this application')

LogManager.console({ featureFlags }).transport(new LogRocketLoggerTransport())

// Intercept fetch to log network requests and responses.
Intercept.fetch()

// Intercept Next.js router events to log navigation events.
Intercept.navigation()

// Intercept important global events to log them.
Intercept.events()

// x-session-id will be logged on the server for distributed logging.
apiConfig.addDefaultHeader('x-session', getSession())

/* @metric logger_init */
LogManager.global.info({
    action: 'logger@init',
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    session: BufferedLogger.sessionId,
})
