import type { ICommand } from '@teamflow/types'
import { ClientMessage } from '@teamflow/types'

/** @group Commands */
export default class GoToRoomCommand implements ICommand<{ roomId: string }> {
    readonly messageType = ClientMessage.GoToRoom

    private readonly roomId: string

    constructor(roomId: string) {
        this.roomId = roomId
    }

    execute() {
        return { roomId: this.roomId }
    }
}
