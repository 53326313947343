import { LoadingFunnel, featureFlags } from '@teamflow/bootstrap'
import { LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { RemoteConfig } from '@teamflow/types'

import { track } from './analytics'

export enum AppcuesEvents {
    CreatedSpace = 'created-new-room',
    AddRoomClicked_TF2 = 'tf2-add-room-clicked',
    AddRoomClicked_TF3 = 'tf3-add-room-clicked',
    CreateRoomClicked_TF3 = 'tf3-create-room-clicked',
    MeetButtonClicked_TF2 = 'tf2-header-meet-button-clicked',
    MeetButtonClicked_TF3 = 'tf3-header-meet-button-clicked',
    InviteButtonClicked_TF2 = 'tf2-header-invite-button-clicked',
    InviteButtonClicked_TF3 = 'tf3-header-invite-button-clicked',
    CopySendThisLinkClicked = 'copy-send-this-link-clicked',
    OpenedApp = 'opened-app',
    LoadingFinished = 'loading-finished',
    RoomEntered = 'room-entered',
    EmbedButtonClicked = 'embed-button-clicked',
    GridModeClicked = 'grid-mode-clicked',
    TalkToASpecialist = 'talk-to-a-specialist',
    SpatialRibbonActivated = 'spatial-ribbon-activated',
    RadicalLoadingFinished = 'radical-loading-finished',
    SidebarButtonClicked = 'sidebar-button-clicked',
    SecondTeammateJoined = 'second-teammate-joined',
    HomeScreenLoaded = 'home-screen-loaded',
}

const trackOnceMap: { [key: string]: boolean } = {}

trackOnceMap[AppcuesEvents.LoadingFinished] = false
trackOnceMap[AppcuesEvents.RadicalLoadingFinished] = false
trackOnceMap[AppcuesEvents.SecondTeammateJoined] = false
trackOnceMap[AppcuesEvents.HomeScreenLoaded] = false

export const appcuesLogger = LogManager.createLogger('Appcues')

export const appcuesEventHandler = (_eventName: string, data: any) => {
    switch (data.id) {
        case 'flow_started':
            rootStore.layout.updateAppcuesFlowRunning(true)
            appcuesLogger.info({
                action: 'Appcues@FlowStarted',
                data,
            })
            track(LoadingFunnel.AppcuesFlowStarted, data)
            break
        case 'flow_completed':
            rootStore.layout.updateAppcuesFlowRunning(false)
            appcuesLogger.info({ action: 'Appcues@FlowCompleted', data })
            track(LoadingFunnel.AppcuesFlowCompleted, data)
            break
        case 'flow_skipped':
            rootStore.layout.updateAppcuesFlowRunning(false)
            appcuesLogger.info({
                action: 'Appcues@FlowSkipped',
                data,
            })
            track(LoadingFunnel.AppcuesFlowSkipped, data)
            break
        case 'step_aborted':
        case 'flow_aborted':
            rootStore.layout.updateAppcuesFlowRunning(false)
            appcuesLogger.info({ action: 'Appcues@FlowAborted', data })
            break
        case 'step_completed':
            // Other if statements can be added here to affect changes in the Teamflow app based on the Appcues step completed
            appcuesLogger.info({ action: 'Appcues@StepCompleted', data })
            break
        case 'step_started':
        case 'step_skipped':
        case 'step_interacted':
        case 'form_submitted':
        case 'form_field_submitted':
            appcuesLogger.info({ action: 'Appcues@MiscEvent', data })
    }
}

const fireTalkToSpecialistEvent = () => {
    if (window.Appcues) {
        window.Appcues.track(AppcuesEvents.TalkToASpecialist)
        appcuesLogger.info({ action: `Appcues@TalkToSpecialistEvent` })
    }
}

export const initializeTalkToASpecialistTimeout = async () => {
    const appcuesTalkToSpecialistEnabled = await featureFlags.value(
        RemoteConfig.AppcuesTalkToASpecialist
    )
    const appcuesTalkToSpecialistTimeout = await featureFlags.getConfigValue(
        RemoteConfig.AppcuesTalkToASpecialist
    )
    if (appcuesTalkToSpecialistEnabled) {
        setTimeout(() => {
            fireTalkToSpecialistEvent()
        }, appcuesTalkToSpecialistTimeout)
    }
}

export const appcuesTrackOnce = (eventName: string, data?: any) => {
    if (trackOnceMap[eventName]) {
        return
    }
    if (window.Appcues) {
        trackOnceMap[eventName] = true
        window.Appcues.track(eventName, data)
        appcuesLogger.info({
            action: `Appcues@TrackedOnceEvent`,
            data: { eventName, ...data },
        })
    }
}
