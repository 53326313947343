import { getPublicFEUrl } from '@teamflow/lib'
import * as t from '@teamflow/types'

import { sharedInstance as electron } from '../verse/services/ElectronService'

import { finishInNewWindow } from './finishInNewWindow'

/** Request Google account permissions for a specific feature described by a keyword */
export async function requestPermissions(keyword: 'calendar' | 'invite') {
    if (electron.available && (await electron.atLeastVersion('10.0.0'))) {
        // On the desktop app, we open the OAuth prompt an external browser. A short-lived server
        // is created on an arbitrary port as the redirect destination after the SSO callback. Once
        // that server receives a request, it will resolve the waitForGoogleSSO promise here by
        // sending a FinishGoogleSSO message.
        await electron.waitForGoogleSSO('/close', t.FlowType.Permission, {
            keyword,
        })
    } else {
        // Open the OAuth prompt in a new window/tab and wait until it closes.
        return finishInNewWindow(
            getPublicFEUrl(
                `/api/auth/google/permission?redirect=/close&keyword=${keyword}`
            )
        )
    }
}

export async function login(redirect = '') {
    // TODO: find a better way to represent what we want to do here
    // namely that we want to get extra scopes for calendar access
    const flow =
        redirect === '/close' ? t.FlowType.FullScopes : t.FlowType.Normal

    if (electron.available && (await electron.atLeastVersion('10.0.0'))) {
        // updated flow that opens native browser to auth
        // https://developers.googleblog.com/2020/08/guidance-for-our-effort-to-block-less-secure-browser-and-apps.html
        const { idToken, accessToken, domain } =
            await electron.waitForGoogleSSO(redirect, flow)

        const data = {
            // this is the form the api needs
            id_token: idToken,
            accessToken,
            domain,
        } as any

        const query = Object.keys(data)
            .filter((key) => !!data[key])
            .map((key) => `${key}=${data[key]}`)
            .join('&')

        // gets an idToken, acessToken, & domain after sso that is
        // passed to the app from the api via a local server
        // @see main.ts
        const url = getPublicFEUrl(
            `/api/auth/google/token?${query}&flow=${flow}`
        )

        if (redirect === '/close') {
            return finishInNewWindow(`${url}&redirect=${redirect}`)
        } else {
            window.location.replace(url)
        }
    } else {
        return finishInNewWindow(
            getPublicFEUrl(`/api/auth/google?redirect=${redirect}&flow=${flow}`)
        )
    }
}

/**
 * Converts string query to a google search url for that query
 * @param query Query to search for. Can be any string.
 * @returns Full url for google query search.
 */
export function toGoogleQueryUrl(query: string) {
    return (
        'https://www.google.com/search?q=' +
        encodeURIComponent(query).replace(/%20/g, '+')
    )
}
