import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import { RealtimeEvents } from '../events'

/**
 * This command should be used to do generic state updates
 *
 * @group Commands
 * @see ClientMessageHandler.changeState
 */
export class StateChangeCommand
    implements t.ICommand<{ deltas: Array<t.IVerseStateDelta> }>
{
    readonly messageType = t.ClientMessage.StateChange

    protected deltas: Array<t.IVerseStateDelta>

    constructor(deltas: Array<t.IVerseStateDelta> | t.IVerseStateDelta) {
        if (!Array.isArray(deltas)) {
            deltas = [deltas]
        }

        this.deltas = deltas
    }

    execute() {
        return { deltas: this.deltas }
    }

    localExecute(state: t.IVerseState, userId: string) {
        const triggers: Array<{ name: RealtimeEvents; payload: any }> = []

        for (const delta of this.deltas) {
            // Only local execute for $change operations!
            // ! We do not want to trigger this for entities that use the spatial hash, as right now they may get out of sync if we do.
            // ! The spatial hash update is in the entity connector (such as the FurnitureConnector), and that only is called
            // ! when the realtime server emits the onChange event. If we change the field locally, we will not get the onChange event.
            // TODO: added ? as I got a delta which was undefined, investigate
            if (delta?.op === '$change') {
                switch (delta.entity) {
                    case 'pin': {
                        const roomSubstate = state.roomSubstates.get(
                            delta.data.locationId
                        )

                        if (!roomSubstate) {
                            break
                        }

                        const pin = roomSubstate.pins.find(
                            (pin) => pin.id === delta.data.id
                        )

                        if (pin) {
                            Object.assign(pin, delta.data)
                        }
                        break
                    }
                    case 'participant': {
                        const participant =
                            rootStore.participants.participantById.get(userId)

                        if (!participant) return

                        const globalParticipantState =
                            state.participantsGlobal.find(
                                (participant) => participant.id === userId
                            )

                        if (globalParticipantState) {
                            if (delta.data.status) {
                                globalParticipantState.status =
                                    delta.data.status
                                Object.assign(
                                    globalParticipantState.statusMetadata,
                                    delta.data.statusMetadata
                                )

                                // update mobx store
                                const user =
                                    rootStore.participants.findParticipantById(
                                        userId
                                    )
                                user?.update({
                                    status: delta.data.status,
                                    statusMetadata: {
                                        ...user.statusMetadata,
                                        ...delta.data.statusMetadata,
                                    },
                                })
                            }

                            triggers.push({
                                name: RealtimeEvents.ParticipantChanged,
                                payload: participant,
                            })
                        }
                        break
                    }
                }
            }
        }

        return triggers
    }
}
