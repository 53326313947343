/**
 * These events are emitted by {@link RealtimeService} to reflect changes in state.
 */
enum Events {
    OnConnectionError = 'on-connection-error',
    StateChanged = 'state-changed',
    RoomAdded = 'room-added',
    RoomChanged = 'room-changed',
    RoomsUpdated = 'rooms-updated',
    RoomSpatialAudioChanged = 'room-spatial-audio-changed',
    RoomInvitedIdsAdded = 'room-invited-ids-added',
    /**
     * Emitted when an application changes any field
     */
    AppChanged = 'app-changed',
    /**
     * Emitted when an application is added, changes any field, or is removed
     */
    AppUpdated = 'app-updated',
    /**
     * Emitted when any application changes any field
     */
    AudioZoneAdded = 'audio-zone-added',
    AudioZoneChanged = 'audio-zone-changed',
    AudioZoneRemoved = 'audio-zone-removed',
    FurnitureAdded = 'furniture-added',
    FurnitureChanged = 'furniture-changed',
    FurnitureRemoved = 'furniture-removed',
    GetUser = 'get-guest',
    TapUser = 'tap-user',
    NotifyNearbyScreenShare = 'notify-nearby-screen-share',
    MeetLater = 'meet-later',
    MuteUser = 'mute-user',
    /**
     * Emitted when any Participant changes any field
     */
    ParticipantsUpdated = 'participants-updated',
    /**
     * Emitted when a specific Participant is added, and when changes any field
     */
    ParticipantUpdated = 'participant-updated',
    /**
     * Emitted when a Participant gets added.
     * Could be a different floor or room, they just connected to Teamflow.
     */
    ParticipantAdded = 'participant-added',
    /**
     * Emitted when a Participant changes any field (not when it's added).
     */
    ParticipantChanged = 'participant-changed',
    ParticipantWasMoved = 'participant-was-moved',
    /**
     * Emitted when a Participant gets removed.
     */
    ParticipantRemoved = 'participant-removed',
    ParticipantLocationChanged = 'participant-location-changed',
    ParticipantPositionChanged = 'participant-position-changed',
    ParticipantCursorChanged = 'participant-cursor-changed',
    PinAdded = 'pin-added',
    PinChanged = 'pin-changed',
    PinRemoved = 'pin-removed',
    PortalAdded = 'portal-added',
    PortalChanged = 'portal-changed',
    PortalRemoved = 'portal-removed',
    PortalUpdated = 'portal-updated',
    RoomRemoved = 'room-removed',
    PromoteGuest = 'promote-guest',
    OnJoined = 'on-joined', // Someone joined the colyseus server the first time, but not to the participants
    OnLeft = 'on-left',
    OnAppOpened = 'on-app-opened',
    OnAppClosed = 'on-app-closed',
    OnRoomLocked = 'on-room-locked',
    OnRoomUnlocked = 'on-room-unlocked',
    OnRoomKnock = 'on-room-knock',
    OnRoomKnockResponse = 'on-room-knock-response',
    OnDropped = 'on-dropped',
    OnDisconnect = 'on-disconnect',
    OnReconnected = 'on-reconnected',
    ParticipantReadyChanged = 'on-participant-ready-changed',
    OnDeployStarting = 'on-deploy-starting',
    OnDeployCancelled = 'on-deploy-cancelled',
    onBackgroundImageChanged = 'on-background-image-changed',
    OnActiveEventChanged = 'on-active-event-changed',
    OnMoveToFloor = 'on-move-to-floor',
    OnParticipantBlocked = 'on-participant-blocked',
    OnPortalDeleteDenied = 'on-portal-delete-denied',
    OnFlagChanged = 'on-flag-changed',
    OnParticipantDelete = 'on-participant-delete',
    OnGoToFloorError = 'on-go-to-floor-error',
    OnGoToRoomError = 'on-go-to-room-error',
    OnRoomError = 'on-room-error',
    OnFloorKnock = 'on-floor-knock',
    OnFloorKnockResponse = 'on-floor-knock-response',
    OnInviteRemoved = 'on-invite-removed',
    OnAcknowledge = 'on-acknowledge',
    SpeakerCircleAdded = 'speaker-circle-added',
    SpeakerCircleChanged = 'speaker-circle-changed',
    SpeakerCircleUpdated = 'speaker-circle-updated',
    SpeakerCircleRemoved = 'speaker-circle-removed',
    OnYoutubeVideoChanged = 'youtube-video-changed',
    OnParticipantSelectedAppId = 'on-participant-selected-app-id',
    OnParticipantFocusModeChanged = 'on-participant-focus-mode-changed',
    OnPermissionUpdated = 'on-permission-updated',

    PresentationStarted = 'presentation-started',
    PresentationEnded = 'presentation-ended',

    OnRoomDeleteDenied = 'on-room-delete-denied',
    SpaceInvalidation = 'space-invalidation',
    SpaceInvalidationAll = 'space-invalidation-all',

    WallAdded = 'wall-added',
    WallChanged = 'wall-changed',
    WallRemoved = 'wall-removed',
    WallUpdated = 'wall-updated',

    AssetAppended = 'asset-added',
    onInviteToRoom = 'invite-to-room',
    EventOnboardingFinished = 'event-onboarding-finished',
    /** User emits an ephemeral reaction */
    EphemeralReaction = 'ephemeral-reaction',

    InvokeTriggerAction = 'invoke-trigger-action',
}

export default Events
export { Events as RealtimeEvents }
