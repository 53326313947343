import { api } from '@teamflow/client-api'
import { LogManager } from '@teamflow/lib'
import { User } from '@teamflow/store'

// TODO: Make this a single server endpoint to grant access to rooms.
export async function grantViewAccessToRoom(
    user: User,
    orgId: string,
    roomSlug: string
) {
    const { data, error } = await api.role.roomRoles.create(orgId, roomSlug)
    if (error || !data) {
        LogManager.global.error(
            'grantViewAccessToRoom: Could not give user view access',
            error?.message
        )
        throw new Error('Could not get rooms roles for user')
    }

    const [fullRole, viewRole] = data
    await api.userOrg.role.create({
        userOrgId: user._id,
        roleId: viewRole._id,
    })

    await api.userOrg.role.delete({
        userOrgId: user._id,
        roleId: fullRole._id,
    })

    return [fullRole, viewRole]
}

// TODO: Make this a single server endpoint to grant access to rooms.
export async function grantFullAccessToRoom(
    user: User,
    orgId: string,
    roomSlug: string
) {
    const { data, error } = await api.role.roomRoles.create(orgId, roomSlug)

    if (error || !data) {
        LogManager.global.error(
            'grantFullAccessToRoom: Error fetching roles',
            error?.message
        )
        throw new Error('Could not get rooms roles for user')
    }
    const [fullRole, viewRole] = data

    await api.userOrg.role.create({
        userOrgId: user._id,
        roleId: fullRole._id,
    })

    await api.userOrg.role.delete({
        userOrgId: user._id,
        roleId: viewRole._id,
    })

    return [fullRole, viewRole]
}
