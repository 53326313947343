import { createRequest, request } from '../request'

const upload = createRequest<'POST /api/file/upload'>(
    'POST',
    '/api/file/upload'
)

const resize = createRequest<'POST /api/file/resize'>(
    'POST',
    '/api/file/resize'
)

const uploadCustomBackground =
    createRequest<'POST /api/file/uploadCustomBackground'>(
        'POST',
        '/api/file/uploadCustomBackground'
    )

const uploadAdvanced = (
    formData: FormData,
    {
        thumbnailWidth,
        thumbnailHeight,
    }: {
        thumbnailWidth: number
        thumbnailHeight: number
    }
) =>
    request<'POST /api/file/uploadAdvanced'>(
        'POST',
        `/api/file/uploadAdvanced?thumbnailWidth=${thumbnailWidth}&thumbnailHeight=${thumbnailHeight}`,
        {
            body: formData,
        }
    )

export default {
    upload,
    resize,
    uploadCustomBackground,
    uploadAdvanced,
}
