import { Platform } from '@teamflow/bootstrap'
import { KeyboardShortcut } from '@teamflow/types'

export default function transformedShortcutForPlatform(
    shortcut?: KeyboardShortcut,
    forTooltip = false
): string {
    if (!shortcut) {
        return ''
    }

    let modKey = 'meta'
    let windowsKey = 'meta'
    let altKey = 'alt'
    const currentOS = Platform.current()

    if (currentOS === 'MacOS') {
        modKey = forTooltip ? 'cmd' : 'command'
        altKey = 'opt'
        windowsKey = 'ctrl'
    } else if (
        currentOS === 'Windows' ||
        currentOS === 'Linux' ||
        currentOS === 'UNIX'
    ) {
        modKey = 'ctrl'
    }

    const newShortcut = shortcut
        .replace(/ctrl|cmd|mod/gi, modKey)
        .replace(/meta/gi, windowsKey)

    if (forTooltip) {
        return newShortcut.replace(/cmd/g, '⌘').replace(/alt/gi, altKey)
    } else {
        return newShortcut.replace(/\|/g, '+')
    }
}
