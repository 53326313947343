import * as t from '@teamflow/types'

/** @group Commands */
export default class ZIndexChangedCommand
    implements t.ICommand<t.IZIndexChanged>
{
    readonly messageType = t.ClientMessage.ZIndexChanged

    private id = ''
    private z = 0
    private locationId = ''

    constructor(id = '', z: number, locationId: string) {
        this.id = id
        this.z = z
        this.locationId = locationId
    }

    execute() {
        return { id: this.id, z: this.z, locationId: this.locationId }
    }

    localExecute(state: t.IVerseState) {
        const app = state.roomSubstates
            .get(this.locationId)
            ?.apps.find((app) => app.id === this.id)

        if (app) {
            app.z = this.z ?? app.z
        }
    }
}
