import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export class ParticipantListeningToCommand
    implements t.ICommand<t.IParticipantListeningTo>
{
    readonly messageType = t.ClientMessage.ParticipantListeningTo

    private listeningToParticipantId: string

    constructor(listeningToParticipantId: string) {
        this.listeningToParticipantId = listeningToParticipantId
    }

    execute() {
        return {
            listeningToParticipantId: this.listeningToParticipantId,
        }
    }

    localExecute() {
        rootStore.participants.localParticipant?.update({
            listeningToParticipantId: this.listeningToParticipantId,
        })
    }
}

export default ParticipantListeningToCommand
