import { Instance } from '@teamflow/types'

import { createRequest, request, retry } from '../request'

const match = createRequest<'POST /api/realtime/instances/match'>(
    'POST',
    '/api/realtime/instances/match'
)

const getAll = createRequest<'GET /api/realtime/instances'>(
    'GET',
    '/api/realtime/instances/'
)

const create = ({ apikey, payload }: { apikey: string; payload: Instance }) =>
    request<'POST /api/realtime/instances'>('POST', `/api/realtime/instances`, {
        body: payload,
        headers: {
            'x-api-key': apikey,
        },
    })

const patch = ({ apikey, payload }: { apikey: string; payload: Instance }) =>
    retry(
        () =>
            request<'PATCH /api/realtime/instances/:instanceId'>(
                'PATCH',
                `/api/realtime/instances/${payload.instanceId}`,
                {
                    body: payload,
                    headers: {
                        'x-api-key': apikey,
                    },
                }
            ),
        {
            times: 0,
        }
    )

export default {
    match,
    create,
    getAll,
    patch,
}
