import { forwardRef } from 'react'

import { Box, Divider } from '@teamflow/design'

import type { ReactNode } from 'react'

function MenuDivider() {
    return (
        <Divider
            orientation="vertical"
            height="size24"
            color="neutral40"
            marginX="space8"
        />
    )
}

export default forwardRef(function HuddleContextualMenu(
    {
        anchor,
        children,
        width = 193,
    }: {
        anchor: { x: number; y: number }
        children?: ReactNode[] | ReactNode
        width?: number
    },
    ref
) {
    if (children && Array.isArray(children) && children.length > 1) {
        // Create copy b/c React is stubborn and won't allow length mutation
        const childrenCopy = children.slice() as Array<ReactNode>

        childrenCopy.splice(
            children.length - 1,
            0,
            <MenuDivider key="$divider" />
        )
        children = childrenCopy
    }

    return (
        <div
            className="no-outline"
            ref={ref as any}
            tabIndex={0}
            style={{
                position: 'absolute',
                left: `${anchor.x}px`,
                top: `${anchor.y}px`,
                zIndex: 2,
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="between"
                background="neutral10"
                borderRadius="borderRadius12"
                boxShadow="shadow4"
                paddingY="space8"
                paddingX="space12"
                height="size48"
                minHeight="size48"
                __cssOverrides={{
                    width,
                    minWidth: width,
                }}
            >
                {children}
            </Box>
        </div>
    )
})
