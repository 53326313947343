import { request } from '../request'

const get = ({ inviteCode }: { inviteCode: string }) =>
    request<'GET /api/invitation/:inviteCode'>(
        'GET',
        `/api/invitation/${inviteCode}`
    )

export default {
    get,
}
