import { makeAutoObservable } from 'mobx'

import type { IUserOnboarding } from '@teamflow/types'

import { logger } from './logging'

interface OnboardingAdapter {
    save(data: Partial<IUserOnboarding>): Promise<void>
}

class NullOnboardingAdapter implements OnboardingAdapter {
    async save(data: Partial<IUserOnboarding>) {
        logger.error('Failed to save onboarding data (not ready)', data)
    }
}

export class OnboardingStore implements IUserOnboarding {
    user = ''
    lastDownloadPromptUTC = 0
    appFullscreenMode = false
    addTeammates = false
    convertMeetingVenue = false
    syncCalendar = false
    connectSlack = false
    getTheApp = false
    getTheAppMobile = false
    gettingStarted = false
    updatedAppSizeOrPosition = false
    getCalendarExtension = false
    appClosing = false
    newMeeting = false
    getChromeExtension = false
    onboardingBot = false
    removePrivateOffice = false
    goHomeToast = false
    shareAudio = false

    _adapter: OnboardingAdapter = new NullOnboardingAdapter()

    constructor() {
        makeAutoObservable(this, {
            _adapter: false,
        })
    }

    reset() {
        this.user = ''
        this.lastDownloadPromptUTC = 0
        this.appFullscreenMode = false
        this.addTeammates = false
        this.convertMeetingVenue = false
        this.syncCalendar = false
        this.connectSlack = false
        this.getTheApp = false
        this.getTheAppMobile = false
        this.gettingStarted = false
        this.updatedAppSizeOrPosition = false
        this.getCalendarExtension = false
        this.appClosing = false
        this.newMeeting = false
        this.getChromeExtension = false
        this.onboardingBot = false
        this.removePrivateOffice = false
        this.goHomeToast = false
    }

    setAdapter(adapter: OnboardingAdapter): () => void {
        this._adapter = adapter

        return () => {
            if (this._adapter === adapter)
                this._adapter = new NullOnboardingAdapter()
        }
    }

    update(data: Partial<IUserOnboarding>) {
        Object.entries(data).forEach(([key, value]) => {
            if (typeof (this as any)[key] === 'undefined') {
                return
            }
            if (value !== (this as any)[key]) {
                ;(this as any)[key] = value
            }
        })
    }

    async save(data: Partial<IUserOnboarding>) {
        this.update(data)
        await this._adapter.save(data)
    }

    get loaded() {
        return !!this.user
    }
}
