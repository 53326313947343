import { computed, makeAutoObservable } from 'mobx'

import {
    AVBandwidthUsage,
    AVWebRTCStats,
    INetworkQuality,
    QualityThreshold,
} from '@teamflow/types'

import type { RootStore } from './'

export const FPS_THRESHOLD = 15
export const FPS_WAIT_TIME = 30000
export const NETWORK_WAIT_TIME = 15000
export const PACKET_LOSS_THRESHOLD = 0.1
export const UPLOAD_KBPS_THRESHOLD = 1500
export const DOWNLOAD_KBPS_THRESHOLD = 3000

export const MAX_RECOMMENDED_CAMERA_TRACKS = 16
export const MAX_RECOMMENDED_CAMERA_TRACKS_WITH_TWO_SCREEN_SHARES = 8

export const MAX_HIGH_RES_CAMERA_TRACKS = 6
export const MAX_HIGH_RES_CAMERA_TRACKS_WITH_ONE_SCREEN_SHARE = 3
export const MAX_HIGH_RES_CAMERA_TRACKS_WITH_TWO_SCREEN_SHARES = 0

const toApproxEMA = (currentAverage: number, newValue: number, alpha = 0.3) =>
    newValue * alpha + currentAverage * (1 - alpha)

export class PerformanceStore {
    FPS = 1
    timeSinceLowFPS = 0

    /** Electron only, if average CPU usage is over our threshold */
    isCPUHigh = false
    cpuAverage = 0 // mean average for last 60s

    /** Electron only, if average System memory usage is over our threshold */
    isSystemMemoryUsageHigh = false
    systemMemoryUsageAverage = 0

    /** AV usage */
    timeSinceLowNetworkQuality = 0
    networkQualityThreshold = QualityThreshold.UNKNOWN
    networkQuality = 0

    /** Bandwidth */
    uploadKbps = 0
    uploadKbpsAverage = 0
    downloadKbps = 0
    downloadKbpsAverage = 0

    /** WebRTC stats */
    availableOutgoingKbps: number | null = null
    availableIncomingKbps: number | null = null

    /** Subscriptions & Tracks */
    micSubs = 0
    cameraSubs = 0
    screenSubs = 0
    micStaged = 0
    cameraStaged = 0
    screenStaged = 0
    micTracks = 0
    cameraTracks = 0
    screenTracks = 0
    highResCameraSubs = 0
    highResScreenSubs = 0

    /** Daily only, moving average */
    sendPacketLossAverage = 0
    receivePacketLossAverage = 0

    constructor(protected readonly rootStore: RootStore) {
        makeAutoObservable(this, {
            networkQualityStats: computed.struct,
        })
    }

    updateFps(value: number, elapsed: number) {
        this.FPS = value
        // we reduce FPS while in FSM
        if (this.FPS < FPS_THRESHOLD && !this.rootStore.layout.fullScreenMode) {
            this.timeSinceLowFPS += elapsed
        } else {
            this.timeSinceLowFPS = 0
        }
    }

    updateNetworkQuality(qualityInfo: INetworkQuality, elapsed: number) {
        this.networkQualityThreshold = qualityInfo.qualityThreshold
        if (this.networkQualityThreshold === QualityThreshold.LOW) {
            this.timeSinceLowNetworkQuality += elapsed
        } else {
            this.timeSinceLowNetworkQuality = 0
        }
        this.networkQuality = qualityInfo.quality
        this.sendPacketLossAverage = toApproxEMA(
            this.sendPacketLossAverage,
            qualityInfo.sendPacketLoss
        )
        this.receivePacketLossAverage = toApproxEMA(
            this.receivePacketLossAverage,
            qualityInfo.receivePacketLoss
        )
    }

    updateBandwidthUsage(info: AVBandwidthUsage) {
        this.uploadKbps = info.upload.total
        this.uploadKbpsAverage = toApproxEMA(
            this.uploadKbpsAverage,
            this.uploadKbps
        )
        this.downloadKbps = info.download.total
        this.downloadKbpsAverage = toApproxEMA(
            this.downloadKbpsAverage,
            this.downloadKbps
        )
        this.micSubs = info.subscriptions.microphone
        this.cameraSubs = info.subscriptions.camera
        this.screenSubs = info.subscriptions.screen
        this.micStaged = info.staged.microphone
        this.cameraStaged = info.staged.camera
        this.screenStaged = info.staged.screen
        this.micTracks = info.tracks.microphone
        this.cameraTracks = info.tracks.camera
        this.screenTracks = info.tracks.screen
        this.highResCameraSubs = info.highResSubscriptions.camera
        this.highResScreenSubs = info.highResSubscriptions.screen
    }

    updateWebRTCStats(stats: AVWebRTCStats) {
        this.availableOutgoingKbps = stats.availableOutgoingBitrate
            ? stats.availableOutgoingBitrate / 1000
            : null

        this.availableIncomingKbps = stats.availableIncomingBitrate
            ? stats.availableIncomingBitrate / 1000
            : null
    }

    getDebugData() {
        return {
            FPS: this.FPS,
            networkQualityThreshold: this.networkQualityThreshold,
            networkQuality: this.networkQuality,
            cpuAverage: this.cpuAverage,
            uploadKbps: this.uploadKbps,
            uploadKbpsAverage: this.uploadKbpsAverage,
            downloadKbps: this.downloadKbps,
            downloadKbpsAverage: this.downloadKbpsAverage,
            micSubs: this.micSubs,
            cameraSubs: this.cameraSubs,
            screenSubs: this.screenSubs,
            micTracks: this.micTracks,
            cameraTracks: this.cameraTracks,
            screenTracks: this.screenTracks,
            highResCameraSubs: this.highResCameraSubs,
            highResScreenSubs: this.highResScreenSubs,
            sendPacketLoss: this.sendPacketLossAverage,
            receivePacketLoss: this.receivePacketLossAverage,
        }
    }

    reset() {
        this.FPS = 1
        this.timeSinceLowFPS = 0
        this.timeSinceLowNetworkQuality = 0
        this.networkQualityThreshold = QualityThreshold.UNKNOWN
        this.networkQuality = 0
        this.isCPUHigh = false
        this.cpuAverage = 0
        this.isSystemMemoryUsageHigh = false
        this.systemMemoryUsageAverage = 0
        this.uploadKbps = 0
        this.uploadKbpsAverage = 0
        this.downloadKbps = 0
        this.downloadKbpsAverage = 0
        this.micSubs = 0
        this.cameraSubs = 0
        this.screenSubs = 0
        this.micTracks = 0
        this.cameraTracks = 0
        this.screenTracks = 0
        this.highResCameraSubs = 0
        this.highResScreenSubs = 0
        this.sendPacketLossAverage = 0
        this.receivePacketLossAverage = 0
    }

    get lowFPS() {
        return this.timeSinceLowFPS >= FPS_WAIT_TIME
    }

    get lowNetworkQuality() {
        return this.timeSinceLowNetworkQuality >= NETWORK_WAIT_TIME
    }

    get highSendPacketLoss() {
        return this.sendPacketLossAverage >= PACKET_LOSS_THRESHOLD
    }

    get highReceivePacketLoss() {
        return this.receivePacketLossAverage >= PACKET_LOSS_THRESHOLD
    }

    get highUploadBandwidth() {
        return this.uploadKbpsAverage >= UPLOAD_KBPS_THRESHOLD
    }

    get highDownloadBandwidth() {
        return this.downloadKbpsAverage >= DOWNLOAD_KBPS_THRESHOLD
    }

    get exceedMaxRecommendedCameraTracks() {
        const maxCameraTracks =
            this.screenSubs >= 2
                ? MAX_RECOMMENDED_CAMERA_TRACKS_WITH_TWO_SCREEN_SHARES
                : MAX_RECOMMENDED_CAMERA_TRACKS

        return this.cameraTracks > maxCameraTracks
    }

    get exceedMaxHighResCameraTracks() {
        const maxCameraTracks =
            this.screenSubs >= 2
                ? MAX_HIGH_RES_CAMERA_TRACKS_WITH_TWO_SCREEN_SHARES
                : this.screenSubs === 1
                ? MAX_HIGH_RES_CAMERA_TRACKS_WITH_ONE_SCREEN_SHARE
                : MAX_HIGH_RES_CAMERA_TRACKS

        // return whether it would be good to set ALL subscribed camera tracks
        // to high resolution
        return this.cameraTracks > maxCameraTracks
    }

    get networkQualityStats(): INetworkQuality {
        return {
            quality: this.networkQuality,
            qualityThreshold: this.networkQualityThreshold,
            packetLoss: this.highSendPacketLoss || this.highReceivePacketLoss,
            sendPacketLoss: Math.round(this.sendPacketLossAverage * 100),
            receivePacketLoss: Math.round(this.receivePacketLossAverage * 100),
        }
    }
}
