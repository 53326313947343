export enum ConnectionTransition {
    Connect = 'connect',
    CreateRoom = 'create-room',
    JoinRoom = 'join-room',
    Connected = 'connected',
    Reconnect = 'reconnect',
    Disconnect = 'disconnect',
    Disconnected = 'disconnected',
    Dropped = 'dropped',
    Failed = 'failed',
}
