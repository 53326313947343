import { runInAction } from 'mobx'

import { castRoomSessionToRoom, LogManager } from '@teamflow/lib'
import rootStore, { IRoom } from '@teamflow/store'
import * as t from '@teamflow/types'
import { DeploymentState } from '@teamflow/types'

import { Connect, StateConnector } from './StateConnector'

type QueuedRoomSessionForUpdate = {
    room: IRoom
    // operation to perform
    op: 'add' | 'delete' | 'change'
}

const logger = LogManager.createLogger('RoomConnector')

/** @group Connectors */
@Connect({ key: 'roomSessions' })
export class RoomConnector extends StateConnector<'roomSessions'> {
    /**
     * The key is the roomId
     * The value is either `null` for a removal, or the IRoom for an add
     */
    private changeSetQueueForRoomSessionsReplace = new Map<
        string,
        QueuedRoomSessionForUpdate
    >()
    private changeSetQueueReset = false

    private changeSetQueueForRoomSessionsReplaceScheduleRef: null | ReturnType<
        typeof setTimeout
    > = null

    private applyChangeSetQueueOnRoomSessionsReplace() {
        this.changeSetQueueForRoomSessionsReplaceScheduleRef = null

        runInAction(() => {
            if (this.changeSetQueueReset) {
                this.changeSetQueueReset = false
                rootStore.rooms.reset()
            }
            this.changeSetQueueForRoomSessionsReplace.forEach(
                ({ room, op }, roomId) => {
                    if (op === 'delete') {
                        rootStore.rooms.delete(roomId)
                    } else {
                        rootStore.rooms.put(room)
                    }
                    // remove item from queue
                    this.changeSetQueueForRoomSessionsReplace.delete(roomId)
                }
            )
        })
    }

    /**
     * The reasoning for this is that we want to avoid replacing the roomSessions
     * when replaceRoomSessions is called in the realtime-server. This is a patch
     * until we refactor room sessions in the rt server to remove the replace calls.
     *
     * Colyseus updates the state in a synchroneous manner, so by simply queueing the mobx
     * update to the next event loop iteration (by using setTimeout(..., 1) we avoid the remove -> add calls.
     */
    private scheduleApplyOfChangeSetQueueForRoomSessionsReplace() {
        if (this.changeSetQueueForRoomSessionsReplaceScheduleRef)
            clearTimeout(this.changeSetQueueForRoomSessionsReplaceScheduleRef)
        // this should run in the next iteration of the event loop
        this.changeSetQueueForRoomSessionsReplaceScheduleRef = setTimeout(
            this.applyChangeSetQueueOnRoomSessionsReplace.bind(this),
            1
        )
    }

    onAttach(roomSessions: t.IVerseState['roomSessions']) {
        // Rooms can be left over from the previous session!
        if (rootStore.commons.deploymentState === DeploymentState.None) {
            this.changeSetQueueReset = true
        }

        roomSessions.onAdd = (roomSession: t.IRoomSessionSchema) => {
            roomSession.invitedIds.onAdd = () => {
                runInAction(() => {
                    const room = rootStore.rooms.roomsById.get(
                        roomSession.roomId
                    )
                    if (!room) return
                    room.invitedIds = roomSession.invitedIds
                })
            }

            const queueChange = () => {
                const queuedOp = this.changeSetQueueForRoomSessionsReplace.get(
                    roomSession.roomId
                )

                if (!queuedOp) {
                    this.changeSetQueueForRoomSessionsReplace.set(
                        roomSession.roomId,
                        {
                            room: castRoomSessionToRoom(roomSession),
                            op: 'change',
                        }
                    )
                    this.scheduleApplyOfChangeSetQueueForRoomSessionsReplace()
                }
            }

            roomSession.invitedIds.onChange = queueChange
            roomSession.invitedIds.onRemove = queueChange
            roomSession.waitlistIds.onAdd = queueChange
            roomSession.waitlistIds.onChange = queueChange
            roomSession.waitlistIds.onRemove = queueChange

            roomSession.onChange = (changes) => {
                changes.forEach(({ field, value }) => {
                    switch (field) {
                        case 'spatialAudio':
                            runInAction(() => {
                                const room = rootStore.rooms.roomsById.get(
                                    roomSession.roomId
                                )
                                if (!room) return

                                // try to find changedBy and set before spatialAudio so reactions
                                // on spatialAudio get the correct spatialAudioChangedBy value
                                const changedBy = changes.find(
                                    (c) => c.field === 'spatialAudioChangedBy'
                                )
                                if (changedBy) {
                                    room.spatialAudioChangedBy = changedBy.value
                                }
                                room.spatialAudio = value
                            })
                            break
                    }
                })

                queueChange()
            }

            roomSession.onRemove = () => {
                const room = rootStore.rooms.roomsById.get(roomSession.roomId)
                if (!room) return

                this.changeSetQueueForRoomSessionsReplace.set(room.id, {
                    room,
                    op: 'delete',
                })
                this.scheduleApplyOfChangeSetQueueForRoomSessionsReplace()
            }

            const room = castRoomSessionToRoom(roomSession)

            this.changeSetQueueForRoomSessionsReplace.set(roomSession.roomId, {
                room,
                op: 'add',
            })
            this.scheduleApplyOfChangeSetQueueForRoomSessionsReplace()
        }

        roomSessions.onChange = () => {
            logger.error(
                `avoid replacing roomSessions! it doesn't play super nicely with schema changes`
            )
        }
    }

    onDetach(roomSessions: t.IVerseState['roomSessions']) {
        roomSessions.onAdd = undefined
    }
}
