import { api } from '@teamflow/client-api'
import { SimpleCommand } from '@teamflow/client-realtime'
import { LogManager } from '@teamflow/lib'
import { User } from '@teamflow/store'
import {
    BroadcastType,
    ClientMessage,
    IBroadcastWithUser,
    IRealtimeService,
    Role,
} from '@teamflow/types'

import { analytics } from '../../helpers'
import { USER_ROLE_CHANGED } from '../../helpers/analytics'

export async function changeRoles(
    realtime: IRealtimeService,
    actor: User | null,
    user: User,
    roleIdChanges: { add: string[]; remove: string[]; role?: Role }
) {
    analytics.track(USER_ROLE_CHANGED, {
        actorId: actor?._id,
        userId: user._id,
        prevRole: user.role,
        newRole: roleIdChanges.role ?? user.role,
    })
    try {
        if (roleIdChanges.role !== undefined) {
            await api.userOrg.role.setRoleEnum({
                userOrgId: user._id,
                roleEnum: roleIdChanges.role,
            })
        }
        for (const roleId of roleIdChanges.add) {
            await api.userOrg.role.create({
                userOrgId: user._id,
                roleId,
            })
        }
        for (const roleId of roleIdChanges.remove) {
            await api.userOrg.role.delete({
                userOrgId: user._id,
                roleId,
            })
        }
        realtime.dispatch(
            new SimpleCommand<IBroadcastWithUser>(ClientMessage.Broadcast, {
                kind: BroadcastType.PermissionUpdated,
                forUserId: user._id,
            })
        )
    } catch (e: any) {
        LogManager.global.error(
            'changeRoles: Error changing roles for user',
            e.message
        )
        throw e
    }
}
