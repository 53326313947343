export default {
    thereWasAnErrorWithYourLinkItMayHaveExpired:
        'thereWasAnErrorWithYourLinkItMayHaveExpired',
    mustProvideBothPasswords: 'mustProvideBothPasswords',
    passwordsMustMatch: 'passwordsMustMatch',
    passwordIsNotStrongEnough: 'passwordIsNotStrongEnough',
    createNewPassword: 'createNewPassword',
    yourPasswordWasReset: 'yourPasswordWasReset',
    logIn: 'logIn',
    password: 'password',
    minAmountCharacters: 'minAmountCharacters',
    passwordConfirm: 'passwordConfirm',
    enterAgainToConfirm: 'enterAgainToConfirm',
    resetMyPassword: 'resetMyPassword',
    passwordIsLessThanCharacters: 'passwordIsLessThanCharacters',
}
