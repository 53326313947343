import * as t from '@teamflow/types'

export default class ForcedRoomErrorCommand
    implements t.ICommand<t.IServerError<t.RoomError>>
{
    readonly messageType = t.ClientMessage.QueueRoomError

    private code: t.RoomError

    constructor(code: t.RoomError) {
        this.code = code
    }

    execute() {
        return {
            code: this.code,
        }
    }
}
