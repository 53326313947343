import * as t from '@teamflow/types'

import Events from '../../events'

import { Connect, StateConnector } from './StateConnector'

/** @group Connectors */
@Connect({ key: 'assets' })
export class AssetConnector extends StateConnector<'assets'> {
    onAttach(assets: t.IVerseState['assets']) {
        assets.onAdd = (asset) => {
            this.events.emit(Events.AssetAppended, asset)
        }
    }

    onDetach(assets: t.IVerseState['assets']) {
        assets.onAdd = undefined
    }
}
