import { NotificationType } from '@teamflow/types'

import { createRequest, request } from '../request'

const create = createRequest<'POST /api/notification'>(
    'POST',
    '/api/notification'
)

const get = ({ type }: { type: NotificationType }) =>
    request<'GET /api/notification/:type'>('GET', `/api/notification/${type}`)

export default {
    create,
    get,
}
