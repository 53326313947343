import EventEmitter from 'eventemitter3'
import { IReactionDisposer, reaction } from 'mobx'

import {
    documentProxy,
    featureFlags,
    RealtimeServiceGlobals,
} from '@teamflow/bootstrap'
import { api } from '@teamflow/client-api-realtime'
import {
    AV_DEPLOYMENT_DISCONNECT_DELAY,
    avDisconnectDelay,
    CONNECTION_DROPPED,
    LogManager,
    REALTIME_RECONNECTION_TIME,
    generateShortUuid,
    waitForMilliseconds,
    ReadonlyMetric,
    Metric,
} from '@teamflow/lib'
import rootStore from '@teamflow/store'
import {
    ConnectionDroppedCode,
    ConnectionState,
    DeploymentState,
    Feature,
    IAnalytics,
    IEventRemover,
    ILogger,
    IPlatformInfo,
    IRealtimeService,
} from '@teamflow/types'

const logger = LogManager.createLogger('ConnectionWatcherService', {
    critical: true,
})

/**
 * This hooks into {@link RealtimeService} and initiates a reconnection sequence from the "disconnected" state.
 *
 * ```mermaid
 * flowchart TD
 * dropped(((Dropped)))
 * disconnected(((Disconnected)))
 * connectivity>Internet connectivity]
 * already_connecting>Was recovery sequence already activated before?]
 * user_agent_sleep>didSleep check, on browsers]
 * invoke_reconnect(((Reconnect)))
 * state_connected(((Connected)))
 * resume_av(Resume call)
 * pause_av(Pause call)
 * wait_on_connectivity(Wait for Internet connectivity)
 * over((Terminate))
 *
 * dropped-->disconnected
 * disconnected -- recovery sequence starts -->connectivity
 * connectivity-- online -->already_connecting
 * already_connecting-- not already in a recovery sequence -->user_agent_sleep
 * user_agent_sleep-- not sleeping -->invoke_reconnect
 * invoke_reconnect-. RealtimeService .->state_connected & disconnected
 * state_connected-- recovery sequence stops -->resume_av
 *
 * invoke_reconnect-- timeout -->pause_av
 *
 * connectivity-- offline -->wait_on_connectivity
 * wait_on_connectivity x-.-x invoke_reconnect
 * already_connecting-- already in recovery sequence --o over
 * user_agent_sleep-- sleeping --o over
 * ```
 *
 * ## Suspended connections
 *
 * The socket connection to the multiplayer server and call are left disconnected when the
 * system has suspended the application. This occurs when the user-agent is in a "sleeping" state,
 * e.g. when a laptop's lid is shut off.
 * - {@link CommonsStore} exposes a {@link CommonsStore.suspended suspended} state, relayed from the Electron main process, that is used
 *      to prevent reconnection until the user-agent wakes up again.
 *
 * - {@link navigator.onLine} indicates whether network connectivity exists. This is used to prevent reconnection
 *      when the user-agent is offline and avoid unnecessary failed fetches.
 *
 * These two states form the basis for {@link ConnectionWatcherService.connectivity connectivity}'s return value. However,
 * MacBooks do not go offline in sleep so a more sophisticated technique is employed to predict with the user-agent is
 * suspended on the browser. This occurs in the "didSleep check, on browsers" step in the recovery flow chart above.
 *
 * ### Sleep Heuristic
 *
 * **Note that this heuristic only works on Chromium-based browsers**
 *
 * The following 3 indicators are used to infer whether user-agent has suspended, in priority. Note that this assumes
 * network connectivity still exists because a false {@link navigator.onLine} value would prevent a recovery
 * sequence earlier in the flow:
 *
 * 1. All WebSockets must abruptly close. The user-agent closes all TCP and TLS sockets after the system suspends. If the user
 *      was connected to a call, then the call sockets must also close within milliseconds of the multiplayer-server socket.
 *
 * The above indicator only work if the user did not hang up before putting their system to sleep. That's why the next 2
 * indicators are needed.
 *
 * 2. The multiplayer socket must have closed at the same time on the server-side. This is because the browser cleanly closes
 *      the underlying TCP or TLS socket. A fetch is done to the "/troubleshoot/socket/close" endpoint to the realtime server
 *      to get this information.
 *
 * 3. The page should either be in a background state (see {@link document.visibilityState}), or no mouse activity occurs for
 *      a few seconds. The injected {@code showSleepDetectedNotification} callback is used to take user feedback to prevent
 *      a false positive. On macOS devices, the page's visibility state becomes "hidden" _only_ if the user's lockscreen
 *      has activated. If the user allows their device to stay unlocked while in sleep, then the page will remain "visible".
 *
 * @see RealtimeService
 * @see CommonsStore
 */
export class ConnectionWatcherService {
    // For tests only
    static logger = logger

    /**
     * Whether there is connectivity to establish a connection to the Internet.
     *
     * If this returns {@code false}, then {@link ConnectionWatcherService} will wait for it become {@code true}
     * before establishing a connection to servers again.
     */
    public static connectivity(): boolean {
        return !rootStore.commons.suspended && navigator.onLine
    }

    /**
     * Flags whether this connection watcher is in the process of recovering from a disconnection. This
     * is used to prevent recurring reconnection attempts.
     */
    private isInDisconnectRecovery = false

    /** contains the date when a connection gets dropped */
    private droppedDate = 0

    private readonly subscriptions: IEventRemover[] = []
    private readonly sleepSubscription: IReactionDisposer

    /**
     * ID of the timeout to leave A/V - to make disconnect cancellable
     */
    private avDisconnectTimeout: undefined | number

    private lastSequence: null | string = null
    private readonly realtime: IRealtimeService
    private readonly analytics: IAnalytics
    private readonly platformInfo: IPlatformInfo
    private readonly avSoftDisconnect: boolean
    private readonly navigateToReconnectPage: () => void
    private readonly logoutUser: () => void
    private readonly showSleepDetectedNotification: () => Promise<boolean>
    private readonly closeSleepDetectedNotification: () => void
    private readonly chromiumSleepDetection: boolean
    private readonly metrics: {
        socketClosed: ReadonlyMetric
    }

    /**
     * @param options
     * @param options.realtime
     * @param options.analytics - TODO Delete analytics as not product-related
     * @param options.platformInfm
     * @param options.avSoftDisconnect - Feature flag to soft-disconnect the call when there is an extended disconnection
     *  to the multiplayer server.
     * @param options.navigateToReconnectPage - Navigation function that redirects to the /reconnect page
     * @param options.logoutUser - Log out user and clear credentials. For when server asks for it
     * @param options.showSleepDetectedNotification - Since UI store is locked in /web for now, an injected
     *      callback to show a notification that we'll disconnect from the call in a few seconds because the
     *      user-agent has suspended. It should resolve with "true" if the user cancels in this in case of
     *      a false positive. It should always resolve in a few seconds.
     * @param options.closeSleepDetectedNotification - Close the notification shown by {@code showSleepDetectedNotification}.
     * @param options.metrics - The call metrics used in the heuristic to predict a suspended state
     * @param options.metrics.socketClosed - Non-realtime socket closures in the application
     * @param options.metrics.trackStopped - {@link MediaStreamTrack} "stop" events
     */
    constructor(options: {
        realtime: IRealtimeService
        analytics: IAnalytics
        platformInfo: IPlatformInfo
        avSoftDisconnect: boolean
        navigateToReconnectPage: () => void
        logoutUser: () => void
        showSleepDetectedNotification: () => Promise<boolean>
        closeSleepDetectedNotification: () => void
        chromiumSleepDetection: boolean
        metrics: {
            socketClosed: ReadonlyMetric
        }
    }) {
        this.realtime = options.realtime
        this.analytics = options.analytics
        this.platformInfo = options.platformInfo
        this.avSoftDisconnect = options.avSoftDisconnect
        this.navigateToReconnectPage = options.navigateToReconnectPage
        this.logoutUser = options.logoutUser
        this.showSleepDetectedNotification =
            options.showSleepDetectedNotification
        this.closeSleepDetectedNotification =
            options.closeSleepDetectedNotification
        this.chromiumSleepDetection = options.chromiumSleepDetection
        this.metrics = options.metrics

        this.realtime.onDropped(this.onDropped).addTo(this.subscriptions)
        this.realtime.onDisconnect(this.onDisconnect).addTo(this.subscriptions)
        this.realtime
            .onReconnected(this.onReconnected)
            .addTo(this.subscriptions)

        this.sleepSubscription = reaction(
            () => rootStore.commons.suspended,
            () => {
                /* @metric client-realtime_connection-watcher_sleep,
                           client-realtime_connection-watcher_wake */
                logger.info({
                    action: rootStore.commons.suspended
                        ? 'Connection@Suspend'
                        : 'Connection@Unsuspend',
                })
                this.onConnectivityChanged()
            }
        )

        window.addEventListener('online', this.onConnectivityChanged)
        window.addEventListener('offline', this.onConnectivityChanged)
        window.addEventListener('focus', this.onWindowFocus)
    }

    /**
     * Destroy this {@link ConnectionWatcherService} and end all subscriptions. This will not prematurely end
     * a realtime recovery attempt, however.
     */
    destroy() {
        this.subscriptions.forEach((sub) => sub.remove())
        this.subscriptions.length = 0
        this.sleepSubscription()

        window.removeEventListener('online', this.onConnectivityChanged)
        window.removeEventListener('offline', this.onConnectivityChanged)
        window.removeEventListener('focus', this.onWindowFocus)
    }

    private readonly onDropped = () => {
        this.droppedDate = Date.now()
    }

    private readonly onDisconnect = async (
        data: {
            consented: boolean
            code: ConnectionDroppedCode
            deployment?: boolean
            lostHeartbeat?: boolean
        } = {
            consented: false,
            code: ConnectionDroppedCode.PolicyViolation,
            deployment: false,
        }
    ) => {
        const { deployment, code: errorCode } = data
        const slogger = logger.child({
            // onDisconnect is async so we want to identify different invocations in logs
            action: 'Connection@Disconnect',
            sequence: (this.lastSequence = generateShortUuid()),
        })

        if (errorCode === ConnectionDroppedCode.TFUserRemovedFromSpace) {
            slogger.warn({
                message:
                    'Logging user out because they were removed from the space',
                code: errorCode,
            })
            return this.logoutUser()
        } else if (
            errorCode === ConnectionDroppedCode.TFUserJoinedOnAnotherDevice
        ) {
            slogger.warn({
                message:
                    'Navigating to reconnect page because user joined on another device',
                code: errorCode,
            })
            return this.navigateToReconnectPage()
        }

        if (!ConnectionWatcherService.connectivity()) {
            return
        }
        // We already tried to reconnect and have come back to disconnect state after failing.
        if (this.isInDisconnectRecovery) {
            slogger.debug({
                message: 'Not entering disconnect recovery twice',
            })
            return
        }

        /* @metric client-realtime_connection-watcher_disconnects */
        slogger.info({
            message: 'Entering disconnect recovery phase',
            metric: 'unexpected', // easier to filter
            data,
        })
        this.isInDisconnectRecovery = true

        const connectionData = {
            ...globalThis.navigator.connection,
            onchange: undefined,
        }
        this.analytics.track(CONNECTION_DROPPED, {
            org: this.realtime.organizationName,
            useOrgId: this.realtime.userOrganizationId,
            errorCode,
            ...connectionData,
        })

        if (!deployment) {
            rootStore.layout.showReconnectToast(errorCode)
        }

        if (
            this.chromiumSleepDetection &&
            !this.platformInfo.electronService.available &&
            !rootStore.settings.manuallyDisconnected
        ) {
            const sleep = await this.didSleep(slogger)

            if (sleep) {
                rootStore.layout.updateReconnectToast({ reconnecting: false })
                await rootStore.audioVideo.leave()
                rootStore.settings.setManuallyDisconnected(true) // but also from RT
                rootStore.participants.localParticipant?.update({
                    ready: false,
                })
                rootStore.layout.closeReconnectToast()
                return
            }
        }

        // if avSoftDisconnect is true, then we don't want to hard disconnect
        if (!this.avSoftDisconnect) {
            const delay = deployment
                ? AV_DEPLOYMENT_DISCONNECT_DELAY
                : avDisconnectDelay
            this.avDisconnectTimeout = window.setTimeout(() => {
                // Disconnect from call if disconnected from realtime
                logger.warn({
                    message: 'Leaving call due to prolonged disconnection',
                    delay: `${delay}ms`,
                    metric: 'call-leave',
                    deployment,
                })
                void rootStore.audioVideo.leave()
            }, delay)
        }

        logger.info('Re-establishing connection to server')
        rootStore.commons.reconnecting = true

        const success = data.lostHeartbeat
            ? await this.realtime.reconnect({
                  retrySoftReconnect: true,
              })
            : await this.realtime.reconnectWithRetry({
                  // on the mobile app it's likely that the app got minimized,
                  // the user can retry to reconnect when they open the app again.
                  maxRetries: this.platformInfo.isMobileApp ? 5 : undefined,
              })

        // Don't do anything if succeeded as that'll be handled in onReconnected.
        if (!success) {
            rootStore.commons.reconnecting = false
            window.flutter_inappwebview?.callHandler(
                'on_error',
                'Could not reconnect'
            )

            if (this.avSoftDisconnect) {
                /* @metric client-realtime_connection-watcher_call-disruption */
                slogger.error({
                    message: 'audioVideo.setSoftDisconnect(true)',
                    metric: 'call-pause',
                })
                rootStore.audioVideo.setSoftDisconnect(true)
            }
        }
    }

    private readonly onReconnected = async () => {
        const slogger = logger.child({
            action: 'Connection@Reconnect',
            sequence: this.lastSequence ?? '<<null>>',
        })
        this.lastSequence = null
        slogger.info('Exiting disconnect recovery phase')

        this.closeSleepDetectedNotification()
        window.clearTimeout(this.avDisconnectTimeout)

        this.isInDisconnectRecovery = false

        rootStore.layout.closeReconnectToast()
        rootStore.commons.reconnecting = false

        slogger.debug('audioVideo.setSoftDisconnect(false)')
        rootStore.audioVideo.setSoftDisconnect(false)

        // reconnect to daily call once reconnected to realtime unless manually disconnected
        // if has ended audio tracks we need to force a full reconnect to renew the tracks
        const fixAudioTracksEndedAfterSleep = featureFlags.isEnabledSync(
            Feature.FixAudioTracksEndedAfterSleep
        )
        const hasEndedAudioTracks =
            fixAudioTracksEndedAfterSleep &&
            rootStore.participants.participants.some(
                (p) => p.media?.audioTrack?.readyState === 'ended'
            )
        if (
            (hasEndedAudioTracks || !this.avSoftDisconnect) &&
            !rootStore.settings.manuallyDisconnected
        ) {
            if (hasEndedAudioTracks) {
                slogger.warn(
                    'Force reconnect due to audio tracks in ended state'
                )
            }
            slogger.debug('audioVideo.reconnect()')
            await rootStore.audioVideo.reconnect(hasEndedAudioTracks)
        }

        // If we reconnected after a seamless deploy, send a metric
        // with the total disconnection time
        if (this.droppedDate !== 0) {
            const payload = {
                org: this.realtime.organizationName,
                useOrgId: this.realtime.userOrganizationId,
                timeToReconnectInSeconds:
                    (Date.now() - this.droppedDate) / 1000,
                seamlessDeploy:
                    rootStore.commons.deploymentState ===
                    DeploymentState.Starting,
            }

            this.analytics.track(REALTIME_RECONNECTION_TIME, payload)

            /* @metric client-realtime_connection-watcher_reconnection-time */
            slogger.info({ action: 'Connection@Reconnect' }, payload)

            this.droppedDate = 0
        }
    }

    private readonly onConnectivityChanged = () => {
        if (rootStore.commons.suspended || !navigator.onLine) {
            void this.realtime.disconnect()
            void rootStore.audioVideo.leave() // disconnect handler won't do this in sleep
        } else {
            rootStore.layout.showReconnectToast(1000)
            void this.realtime.reconnectWithRetry().then(() => {
                if (!rootStore.commons.disconnected)
                    void rootStore.audioVideo.join()
            })
        }
    }

    private readonly onWindowFocus = async () => {
        if (this.realtime.connectionState === ConnectionState.Disconnected) {
            logger.info('Reconnecting after window re-focused')

            await this.realtime.reconnect()
        }
    }

    /**
     * A heuristic that predicts if the user-agent is going into a suspended state where it is desirable
     * for the application to not reconnect the call.
     *
     * It is not needed on the desktop application because Electron provides an "suspended" event that is
     * relayed to the renderer process.
     */
    private async didSleep(slogger: ILogger = logger): Promise<boolean> {
        const localUser = rootStore.users.localUser
        const org = rootStore.organization.data
        if (!localUser || !org) return false

        if (rootStore.audioVideo.isConnected) {
            const socketClosedDelta = Metric.delta(this.metrics.socketClosed)
            await waitForMilliseconds(100)

            const didCallSocketFail = socketClosedDelta.value() >= 1
            if (!didCallSocketFail) {
                slogger.info({
                    action: 'Connection@DidSleep',
                    didCallSocketFail,
                    value: false,
                })
                return false
            }
        }

        const closeTimeElapsedClient = Date.now() - this.droppedDate
        const requestTimestamp = Date.now()
        const { data } = await api.troubleshoot.socketClose(
            RealtimeServiceGlobals.httpEndpoint!
        )({
            userOrgId: localUser._id,
            orgId: org._id,
        })
        const responseTimestamp = Date.now()
        if (data) {
            const closeTimeElapsedServer = data.closeElapsedTime
            const troubleshootLatency = responseTimestamp - requestTimestamp
            const closeLatency = data.closeLatency

            // closeTimeElapsedClient is calculated before sending out the request. The server then processes
            // the troubleshooting b/w closeTimeElapsedClient and closeTimeElapsedClient + troubleshootLatency.
            // If the sockets closed at the time, the max difference b/w client and server elapsed time must be
            // the request latency : IF the socket closure propagates immediately. Otherwise, the time it takes for
            // a socket closure to propagate is an additional timing error.
            const closeSimultaneous =
                Math.abs(closeTimeElapsedServer - closeTimeElapsedClient) <=
                troubleshootLatency + Math.min(500, data.closeLatency) * 2 // *2 for buffer

            if (!closeSimultaneous || data.closeCode !== 1006) {
                slogger.info({
                    action: 'Connection@DidSleep',
                    closeCodeOnServer: data.closeCode,
                    closeSimultaneous: data.closeSimultaneous,
                    closeTimeElapsedServer: closeTimeElapsedServer,
                    closeTimeElapsedClient: closeTimeElapsedClient,
                    troubleshootLatency,
                    closeLatency,
                    value: false,
                })
                return false
            } else {
                slogger.debug({
                    action: 'Connection@DidSleep',
                    closeSimultaneous,
                    closeCodeServer: data.closeCode,
                    closeLatency,
                    closeTimeElapsedServer: closeTimeElapsedServer,
                    closeTimeElapsedClient: closeTimeElapsedClient,
                    troubleshootLatency,
                })
            }
        }

        if (document.visibilityState === 'visible') {
            const mousemove = new EventEmitter()
            const cb = () => mousemove.emit('resolve')
            documentProxy.addEventListener('mousemove', cb)

            const cancel = await Promise.race([
                this.showSleepDetectedNotification(),
                new Promise((resolve) => {
                    mousemove.once('resolve', () => {
                        slogger.warn({
                            action: 'Connection@DidSleep',
                            value: false,
                            reason: 'mousemove',
                            message:
                                'Connection suspension canceled due to mouse movement',
                        })
                        resolve(true)
                    })
                    mousemove.once('reject', () => resolve(false))
                }),
            ])
            mousemove.emit('reject') // don't leave promise hanging
            documentProxy.removeEventListener('mousemove', cb)

            if (cancel) return false
        }

        slogger.info({
            action: 'Connection@DidSleep',
            value: true,
        })

        return true
    }
}
