import { convertDateObjects } from '@teamflow/lib'

import { request } from '../request'

const space = async (args: {
    orgSlug: string
    roomSlug?: string
    inviteCode?: string
    invitation?: string
}) => {
    const res = await request<'POST /api/preload/:orgSlug/:roomSlug'>(
        'POST',
        args.roomSlug
            ? `/api/preload/${args.orgSlug}/${args.roomSlug}`
            : `/api/preload/${args.orgSlug}`,
        {
            body: {
                inviteCode: args.inviteCode,
                invitation: args.invitation,
            },
        }
    )
    if (res.data?.organization) {
        res.data.organization = convertDateObjects(res.data.organization)
    }
    return res
}

export default {
    space,
}
