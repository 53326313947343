import { findIndex, uniqBy } from 'lodash'
import { makeAutoObservable, observable } from 'mobx'
import Talk from 'talkjs'

import {
    Chat,
    ChatIdentifier,
    ChatsById,
    ChatType,
    ChatNotificationMessage,
    RoomToolbarStaticTab,
    SidebarPopout,
} from '@teamflow/types'

import { logger } from './logging'
import { RootStore } from './rootStore'

export class ChatStore {
    rootStore: RootStore

    chatIdentifier: ChatIdentifier | null = null
    lastChatIdentifier: ChatIdentifier | null = null
    chat: Chat | null = null
    chats: Chat[] = []
    notifications = observable<string, ChatNotificationMessage>(new Map())
    session: Talk.Session | null = null
    hasUnreads = false

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            // Only track ref changes
            chatIdentifier: observable.ref,
            lastChatIdentifier: observable.ref,
            chat: observable.ref,
            chats: observable.ref,
            session: observable.ref,
        })
        this.rootStore = rootStore
    }

    reset() {
        this.chatIdentifier = null
        this.lastChatIdentifier = null
        this.chat = null
        this.chats = []
        this.notifications.clear()
        this.session = null
        this.hasUnreads = false
    }

    openV3Chat() {
        this.rootStore.app.setSelectedApp('')
        this.rootStore.layout.setFullScreenModeWithoutSidebar(true)
        this.rootStore.layout.setGridMode(false)
        this.rootStore.layout.setRoomToolbarStaticTab(RoomToolbarStaticTab.Chat)
        this.rootStore.layout.updateMeetingChatOpened(true)
    }

    chatForIdentifier(chatIdentifier: ChatIdentifier | null) {
        if (!chatIdentifier) return null

        const chatFound = this.chats.find((chat) => {
            if (chatIdentifier.type === ChatType.FLOOR) {
                return chat.type === 'floor'
            } else if (chatIdentifier.type === ChatType.ROOM) {
                return (
                    chat.type === ChatType.ROOM &&
                    (chat.roomSession as unknown as string) ===
                        chatIdentifier.id
                )
            } else if (chatIdentifier.type === ChatType.ONE_ON_ONE) {
                return (
                    chat.participants.includes(chatIdentifier.id) &&
                    chat.type === ChatType.ONE_ON_ONE
                )
            }

            return null
        })

        return chatFound ?? null
    }

    setChatIdentifier(identifier: ChatIdentifier | null) {
        this.lastChatIdentifier = this.chatIdentifier
        this.chatIdentifier = identifier
        this.setChat(this.chatForIdentifier(identifier))
    }

    updateChats(chats: Chat[]) {
        if (chats && Array.isArray(chats)) {
            this.chats = chats
        }
    }

    setChat(chat: Chat | null) {
        this.chat = chat
    }

    setTalkSession(session: Talk.Session | null) {
        this.session = session
    }

    setNotificationMessage(message: ChatNotificationMessage) {
        const existing = this.notifications.get(message.title)
        if (existing) {
            this.notifications.set(
                message.title,
                Object.assign({}, existing, {
                    count: message.count,
                    message: `${message.count} unread ${
                        message.count > 1 ? `messages` : `message`
                    }`,
                    time: Date.now(),
                })
            )
        } else {
            message.time = Date.now()
            this.notifications.set(message.title, message)
        }

        logger.debug(
            `ChatStore.setNotificationMessage ${existing ? 'updated' : 'added'}`
        )
    }

    removeNotificationMessage(title: string) {
        this.notifications.delete(title)
    }

    setUnreadStatus(newStatus: boolean) {
        this.hasUnreads = newStatus
    }

    get talkSession() {
        return this.session
    }

    get notificationsObjects() {
        return Array.from(this.notifications.values())
    }

    isActiveChat(id: ChatIdentifier | null) {
        return (
            this.rootStore.layout.sidebarPopout === SidebarPopout.Chat &&
            this.chatIdentifier?.id === id?.id
        )
    }

    updateChat(chat?: Chat) {
        if (!chat) return

        const newChats = [...this.chats]
        const index = findIndex(newChats, {
            chatProviderId: chat.chatProviderId,
        })
        if (index >= 0) {
            newChats.splice(index, 1, chat)
        } else {
            newChats.push(chat)
        }

        this.chats = uniqBy(newChats, 'chatProviderId')
    }

    get otherUserChat() {
        const pid = this.chat?.participants.find(
            (uid) => uid !== this.rootStore.users.localUserId
        )
        if (pid) {
            return this.rootStore.users.getUserById(pid)
        }
        return undefined
    }

    get otherUserChatName() {
        const otherUser = this.otherUserChat
        let name = ''
        if (otherUser) {
            name = `${otherUser.firstName} ${otherUser.lastName}`
        }
        return name
    }

    get chatsById() {
        const localUserId = this.rootStore.users.localUserId
        return this.chats.reduce(
            (acc: ChatsById, current: Chat) => {
                if (current.type === ChatType.ONE_ON_ONE) {
                    for (const pid of current.participants) {
                        if (pid !== localUserId)
                            acc[ChatType.ONE_ON_ONE][pid] = current
                    }
                } else if (current.type === ChatType.ROOM) {
                    acc[ChatType.ROOM][current.roomSession] = current
                } else if (current.type === ChatType.FLOOR) {
                    acc[ChatType.ROOM]['floor'] = current
                }
                return acc
            },
            {
                [ChatType.ROOM]: {},
                [ChatType.ONE_ON_ONE]: {},
            }
        )
    }
}
