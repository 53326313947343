import { EventTargetProxy } from './EventTargetProxy'

/**
 * Use these const objects as global singleton containers for values that
 * need to shared between the bootstrap code and other parts of the code
 * in an uncoupled way.
 */

export const RealtimeServiceGlobals = {
    /**
     * Initial endpoint used by the realtime retrieved during the /preload
     */
    wsEndpoint: undefined as string | undefined,
    httpEndpoint: undefined as string | undefined,
}

/**
 * {@link EventTargetProxy} around {@link document}.
 */
// @ts-expect-error Global Proxy
export const documentProxy: EventTargetProxy<Document> & {
    addEventListener: Document['addEventListener']
    removeEventListener: Document['removeEventListener']
} = typeof document !== 'undefined' ? new EventTargetProxy(document) : undefined

/**
 * {@link EventTargetProxy} around {@link window}.
 */
// @ts-expect-error Global Proxy
export const windowProxy: EventTargetProxy<Window> & {
    addEventListener: Window['addEventListener']
    removeEventListener: Window['removeEventListener']
} = typeof window !== 'undefined' ? new EventTargetProxy(window) : undefined
