import assert from 'assert'

import { MapSchema } from '@colyseus/schema'
import { makeAutoObservable, observable } from 'mobx'

import { AppType, Feature, IApp, IRect, Json } from '@teamflow/types'
import * as t from '@teamflow/types'

import type { RootStore } from './rootStore'

export const APP_DATA_INTERNAL_PREFIX = '_tf_'

export const enum AppEditingLocation {
    None,
    Spatial,
    Tab,
}

export const BLANK_APP: IApp = {
    id: '',
    owner: '',
    name: 'image',
    type: AppType.Image,
    x: 0,
    y: 0,
    z: 0,
    width: 100,
    height: 100,
    url: '',
    locationId: '',
    title: '',
    locked: false,
    embedded: false,
    _dataRaw: new MapSchema<string>(),
    minimized: false,
    pinned: false,
}

export interface IDisplayApp extends IApp {
    focused: boolean
    selected: boolean
    /** Zero means app is not hidden */
    hiddenAt: number
    localUpdate: boolean
    isLoadable: boolean
    shouldRender: boolean
    audioMuted: boolean
    isBackgrounded: boolean
}

export class App implements IRect {
    rootStore: RootStore
    id: string
    owner: string
    name: string
    type: AppType
    config: t.AppConfig
    x: number
    y: number
    z: number

    /** The width of the app's content, **excluding the chrome** */
    width: number

    /** The height of the app's content, **excluding the chrome** */
    height: number
    url?: string
    /**
     * AppStore's appsByLocationId field depends on this not changing,
     * so make sure to update the logic there if we need to mutate locationId
     */
    locationId: string
    title: string
    locked: boolean
    embedded = false

    focused = false
    selected = false
    /** Zero means app is not hidden */
    hiddenAt = 0
    localUpdate = false
    isLoadable = false
    shouldRender = false
    minimized = false
    pinned = false
    audioMuted = false
    reloading = false
    showQrCode = false
    hideContentsFromOwner = false
    editingLocation = AppEditingLocation.None

    needsVisibilityCheck = true

    /**
     * Don't use this directly, even inside this class! Only use `data`.
     *
     * Custom data, where the values are serialized JSON values.
     * Values MUST be serialized/parsed before setting/getting, even primitives.
     */
    _dataRaw: MapSchema<string> = new MapSchema<string>()

    // Mobx complains when using the MapSchema directly as an observable
    // proxy, so we have to use a plain Map as the data source. The Map
    // must be updated separately when MapSchema changes come in (see:
    // Connected.ts).
    /**
     * App data parsed from the original raw string data.
     *
     * Use `updateAppData` or `deleteAppData` to update this.
     */
    readonly data = new Map() as ReadonlyMap<string, Json>

    /**
     * The total number of characters stored inside the data map.
     *
     * It sums the string lengths of the keys and the values
     */
    dataSize = 0

    /**
     * Contains the most recent partial app data update.
     */
    latestDataUpdate?: Record<string, Json>

    isBackgrounded = false

    constructor(rootStore: RootStore, app: IApp, config: t.AppConfig) {
        this.rootStore = rootStore
        this.id = app.id
        this.owner = app.owner
        this.name = app.name
        this.type = app.type
        this.config = config
        this.x = app.x
        this.y = app.y
        this.z = app.z
        this.width = app.width
        this.height = app.height
        this.url = app.url
        this.locationId = app.locationId
        this.title = app.title
        this.locked = app.locked
        this.minimized = app.minimized
        this.pinned = app.pinned
        this.embedded = app.embedded
        this.hideContentsFromOwner = app.type === AppType.ScreenShare
        this.setDataRaw(app._dataRaw)

        makeAutoObservable(this, {
            rootStore: false,
            _dataRaw: false,
            data: observable.shallow,
            config: observable.deep,
            latestDataUpdate: observable.ref,
        })
    }

    update(partial: Partial<IDisplayApp> & { needsVisibilityCheck?: boolean }) {
        this.needsVisibilityCheck = true

        Object.entries(partial).forEach(([key, value]) => {
            if (key === '_dataRaw') {
                const newDataRaw = value as MapSchema<string>
                assert(
                    !!newDataRaw.clone && !!newDataRaw.get,
                    '_dataRaw value is not a MapSchema'
                )
                this.setDataRaw(newDataRaw)
            } else if (key !== 'id' && value !== (this as any)[key]) {
                ;(this as any)[key] = value
            }
        })
    }

    static readonly PROPS_TO_IGNORE_WHEN_RESPECTING_LOCAL_UPDATE: Array<
        keyof IDisplayApp
    > = ['x', 'y', 'z', 'width', 'height']

    updateRespectingLocalUpdate(partial: Partial<IDisplayApp>) {
        const isLocallyUpdating = this.localUpdate
        if (isLocallyUpdating) {
            for (const prop of App.PROPS_TO_IGNORE_WHEN_RESPECTING_LOCAL_UPDATE) {
                delete partial[prop]
            }
        }
        return this.update(partial)
    }

    private setDataRaw(dataRaw: MapSchema<string>) {
        this._dataRaw = dataRaw

        // Replace existing data
        const dataEditable = this.data as Map<string, Json>
        dataEditable.clear()
        this.updateAppData(
            Object.fromEntries(
                Array.from(dataRaw.entries()).map(([k, v]) => [
                    k,
                    JSON.parse(v),
                ])
            )
        )
    }

    updateAppData(update: Record<string, Json>) {
        // Update data
        const dataEditable = this.data as Map<string, Json>
        for (const key in update) {
            dataEditable.set(key, update[key])
        }
        this.latestDataUpdate = { ...update }

        // Update size
        let newSize = 0
        for (const [k, v] of this.data.entries()) {
            if (k.startsWith(APP_DATA_INTERNAL_PREFIX)) {
                continue
            }
            newSize += k.length + JSON.stringify(v).length
        }
        this.dataSize = newSize
    }

    deleteAppData(...keys: string[]) {
        // Update size
        let dataSizeDeleted = 0
        for (const key of keys) {
            const existing = this.data.get(key)
            if (
                existing !== undefined &&
                !key.startsWith(APP_DATA_INTERNAL_PREFIX)
            ) {
                dataSizeDeleted += key.length
                dataSizeDeleted += JSON.stringify(existing).length
            }
        }
        this.dataSize -= dataSizeDeleted

        // Update data
        const dataEditable = this.data as Map<string, Json>
        for (const key of keys) {
            dataEditable.delete(key)
        }
    }

    getDataAsString(key: string) {
        const val = this.data.get(key)
        return typeof val === 'string' ? val : null
    }

    getDataAsNumber(key: string) {
        const val = this.data.get(key)
        return typeof val === 'number' ? val : null
    }

    getDataAsBoolean(key: string) {
        const val = this.data.get(key)
        return typeof val === 'boolean' ? val : null
    }

    getDataAsObject(key: string) {
        const val = this.data.get(key)
        return typeof val === 'object' && !Array.isArray(val) ? val : null
    }

    getDataAsArray(key: string) {
        const val = this.data.get(key)
        return Array.isArray(val) ? val : null
    }

    /**
     * Calculates what the new data size would be if the update were applied.
     *
     * @param update - The new data to apply.
     */
    calculateDataSizeWithUpdate(update: Record<string, Json>) {
        let dataSize = this.dataSize
        for (const key in update) {
            if (key.startsWith(APP_DATA_INTERNAL_PREFIX)) {
                continue
            }
            const existing = this.data.get(key)
            if (existing !== undefined) {
                dataSize -= key.length
                dataSize -= JSON.stringify(existing).length
            }
            dataSize += key.length
            dataSize += JSON.stringify(update[key]).length
        }
        return dataSize
    }

    setLocked(locked: boolean) {
        this.locked = locked
    }

    setTitle(title: string) {
        this.title = title
    }

    setMinimized(minimized: boolean) {
        this.minimized = minimized
    }

    setPinned(pinned: boolean) {
        this.pinned = pinned
    }

    load() {
        this.isLoadable = true
    }

    sleep() {
        this.isBackgrounded = true
    }

    setConstrainResize(value: boolean) {
        this.config.constrainResize = value
    }

    shouldUseSharedBrowser(sharedFlags: t.IFeatureFlagService) {
        if (!sharedFlags.isEnabledSync(Feature.SharedBrowserEmbed)) {
            return false
        }
        if (!this.data) return false

        return this.data?.get('useSharedBrowser') === true
    }

    reload() {
        this.reloading = true
        setTimeout(() => {
            this.reloading = false
        }, 10)
    }

    toggleQrCode(value?: boolean) {
        this.showQrCode = value ?? !this.showQrCode
    }

    toggleHideContentsFromOwner(value?: boolean) {
        this.hideContentsFromOwner = value ?? !this.hideContentsFromOwner
    }

    editOnSpace(value?: boolean) {
        this.editingLocation = value
            ? AppEditingLocation.Spatial
            : AppEditingLocation.None
    }

    editOnTab(value?: boolean) {
        this.editingLocation = value
            ? AppEditingLocation.Tab
            : AppEditingLocation.None
    }

    stopEditing() {
        this.editingLocation = AppEditingLocation.None
    }

    get isBeingEdittedInSpace() {
        return this.editingLocation === AppEditingLocation.Spatial
    }

    get isBeingEdittedInTab() {
        return this.editingLocation === AppEditingLocation.Tab
    }

    get headless() {
        return typeof this.config.headless === 'boolean'
            ? this.config.headless
            : this.config.headless(this.data)
    }

    get shouldLoad() {
        if (!this.shouldRender) return false

        // Headless apps are the best! (Audio share is headless btw.)
        if (this.headless) return true

        // Image apps are exempt from being put to sleep since they are not embeds
        if (!this.rootStore.settings.lite && this.type === AppType.Image) {
            return true
        }

        if (this.rootStore.settings.lite) {
            return (
                this.rootStore.app.selectedAppId === this.id ||
                (this.rootStore.app.selectedAppId === '' &&
                    this.rootStore.app.lastSelectedAppId === this.id)
            )
        }

        if (
            this.rootStore.app.selectedAppId === this.id ||
            (!this.rootStore.app.selectedAppId &&
                this.rootStore.app.lastSelectedAppId === this.id)
        ) {
            return true
        }

        if (this.isBackgrounded && this.rootStore.app.maxAppsFeatureEnabled)
            return false

        return this.isLoadable
    }

    get vecToLocalUser() {
        let appX = this.x
        let appY = this.y

        const userX = this.rootStore.participants.localParticipant?.x
        const userY = this.rootStore.participants.localParticipant?.y

        if (userX === undefined || userY === undefined) {
            return { x: Infinity, y: Infinity }
        }

        if (this.minimized) {
            // Do nothing, use the app origin for the distance
        } else {
            // Use the point on the nearest edge of the app for the distance
            if (appX < userX) {
                if (appX + this.width < userX) {
                    appX = appX + this.width
                } else {
                    appX = userX
                }
            }

            if (appY < userY) {
                if (appY + this.height < userY) {
                    appY = appY + this.height
                } else {
                    appY = userY
                }
            }
        }

        return { x: userX - appX, y: userY - appY }
    }

    get distSqToLocalUser() {
        return (
            this.vecToLocalUser.x * this.vecToLocalUser.x +
            this.vecToLocalUser.y * this.vecToLocalUser.y
        )
    }
}
