import { makeAutoObservable, observable, runInAction } from 'mobx'

import { ToastConfiguration, ToastItem } from '@teamflow/types'

class Toast implements ToastItem {
    id: string
    open: boolean
    text?: string
    element?: JSX.Element
    action?: string
    onAction?: () => void
    duration?: number
    onClose?: (id: string) => void

    constructor(config: ToastItem) {
        makeAutoObservable(this, {
            element: observable.ref,
            onAction: observable.ref,
        })

        this.id = config.id
        this.open = config.open
        this.text = config.text
        this.element = config.element
        this.action = config.action
        this.onAction = config.onAction
        this.duration = config.duration
        this.onClose = config.onClose
    }
}

export class ToastStore {
    id = 0
    toasts: Array<Toast> = []
    offset = 0

    constructor() {
        makeAutoObservable(this)
    }

    reset() {
        this.toasts = []
    }

    add(content: string | ToastConfiguration): string {
        const config = typeof content === 'string' ? { text: content } : content
        if (config.id) {
            // if id has been set don't create a new toast if already exists
            const existing = this.toasts.find((t) => t.id === config.id)
            if (existing) {
                existing.open = true
                return config.id
            }
        }
        const id = config.id || String(this.id)
        const toast = new Toast({ ...config, id, open: true })
        this.toasts.push(toast)
        this.id++
        // with radix the toast won't dismiss if the user is working on an iframe (whiteboard, notes),
        // reason why we close the toast with a timeout
        if (config.duration !== Infinity) {
            setTimeout(() => {
                runInAction(() => {
                    toast.open = false
                })
            }, config.duration ?? 5000)
        }
        return id
    }

    close(id: string) {
        const toast = this.toasts.find((t) => t.id === id)

        if (!toast) {
            return
        }

        runInAction(() => {
            toast.open = false
        })
    }

    updateOffset(offset: number) {
        this.offset = offset
    }
}
