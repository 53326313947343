import { DataChange, MapSchema } from '@colyseus/schema'
import noop from 'lodash/noop'

import { ParticipantSelectedAppIdCommand } from '@teamflow/client-realtime'
import rootStore, { PresentationAdapter } from '@teamflow/store'
import * as t from '@teamflow/types'
import { RoomToolbarStaticTab } from '@teamflow/types'

import {
    PresentationStartCommand,
    PresentationEndCommand,
    PresentationJoinCommand,
    PresentationSwitchAppCommand,
    PresentationLeaveCommand,
} from '../../commands/'

import { Connect, Substate, SubstateConnector } from './StateConnector'

type Presentations = Substate<'roomSubstates'>['presentations']

/**
 * Connector for presentation state & adapter for presentation store.
 *
 * @group Connectors
 */
@Connect({ key: 'roomSubstates', substate: 'presentations' })
export class PresentationConnector
    extends SubstateConnector<'roomSubstates', 'presentations'>
    implements PresentationAdapter
{
    private resetAdapter: () => void = noop

    // @override
    startPresentation(presenterId: string, appId: string, locationId: string) {
        this.realtime.dispatch(
            new PresentationStartCommand({
                presenterId,
                appId,
                locationId,
            })
        )
    }

    // @override
    joinPresentation(
        presenterId: string,
        participantId: string,
        locationId: string
    ) {
        this.realtime.dispatch(
            new PresentationJoinCommand({
                presenterId,
                participantId,
                locationId,
            })
        )
    }

    // @override
    endPresentation(
        presenterId: string,
        shouldInform: boolean,
        locationId: string
    ) {
        this.realtime.dispatch(
            new PresentationEndCommand({
                presenterId,
                shouldInform,
                locationId,
            })
        )
    }

    // @override
    leavePresentation(
        presenterId: string,
        attendeeId: string,
        locationId: string
    ) {
        this.realtime.dispatch(
            new PresentationLeaveCommand({
                presenterId,
                attendeeId,
                locationId,
            })
        )
    }

    // @override
    switchPresentationApp(
        presenterId: string,
        appId: string,
        locationId: string
    ) {
        this.realtime.dispatch(
            new PresentationSwitchAppCommand({
                presenterId,
                appId,
                locationId,
            })
        )
    }

    displayAppTab() {
        rootStore.layout.setRoomToolbarStaticTab(
            rootStore.commons.userInMeetingRoom
                ? RoomToolbarStaticTab.Meeting
                : RoomToolbarStaticTab.Space
        )
        if (!rootStore.layout.fullScreenMode) {
            rootStore.layout.setFullScreenMode(true)
        }
        rootStore.layout.setGridMode(false)
    }

    displayGridMode() {
        rootStore.layout.setRoomToolbarStaticTab(
            rootStore.commons.userInMeetingRoom
                ? RoomToolbarStaticTab.Meeting
                : RoomToolbarStaticTab.Space
        )
        rootStore.layout.setGridMode(true)
        if (!rootStore.layout.fullScreenMode) {
            rootStore.layout.setFullScreenMode(true)
        }
    }

    async updateAttendeeAppId(
        appId: string,
        presenterId: string
    ): Promise<void> {
        rootStore.presentations.updateAppId(appId, presenterId)
        if (rootStore.presentations.followingAPresentation) {
            const currentPresentation =
                rootStore.presentations.currentPresentation
            if (!currentPresentation) return

            if (
                currentPresentation.presenterId === presenterId &&
                appId &&
                appId !== rootStore.app.selectedAppId
            ) {
                // if the presenter switches to grid / chat / files, don't follow them
                // which is why we require && appId here
                this.realtime.dispatch(
                    new ParticipantSelectedAppIdCommand(appId)
                )

                this.displayAppTab()
            }
        }
    }

    /**
     * Attaches listeners on {@link IVerseState.presentations} ands binds itself
     * as the adapter for {@link PresentationStore}.
     */
    onAttach(presentations: Presentations) {
        presentations.onAdd = (presentation: t.IPresentationSchema) => {
            rootStore.presentations.addPresentation(presentation)

            // Assumed to be constant :eyes:
            const presenterId = presentation.presenterId
            const attendees =
                presentation.attendees as MapSchema<t.IPresentationAttendeeSchema>

            attendees.onAdd = (attendee: t.IPresentationAttendeeSchema) => {
                const attendeeId = attendee.attendeeId
                rootStore.presentations.addAttendee(attendee, presenterId)

                attendee.onRemove = () => {
                    rootStore.presentations.removeAttendee(
                        attendeeId,
                        presenterId
                    )
                }
            }

            presentation.onChange = async (changes: DataChange<any>[]) => {
                changes.forEach((change) => {
                    const { field, value } = change
                    switch (field) {
                        case 'appId':
                            void this.updateAttendeeAppId(value, presenterId)
                            break
                    }
                })
            }

            presentation.onRemove = () => {
                rootStore.presentations.removePresentation(presenterId)
            }
        }

        this.resetAdapter = rootStore.presentations.setAdapter(this)
    }

    /** Detach state listeners and itself as an adapter. */
    onDetach(presentations: Presentations) {
        presentations.onAdd = undefined
        this.resetAdapter()
    }
}
