/** The padding around the app's content that is applied by the chrome */
export const CHROME_BORDER = 12

/**
 * The height of the app chrome-bar when the verse is at 1x zoom.
 *
 * When the verse is at a different zoom, this can vary based on the scale-independent nature
 * of the chrome-bar.
 */
export const CHROME_HEADER_H = 40

/** The outline applied on the app */
export const CHROME_OUTLINE = 1

/** The total width of the app's chrome */
export const CHROME_TOTAL_W = 2 * CHROME_BORDER + 2 * CHROME_OUTLINE

/** The total height of the app's chrome, which excludes the app header. */
export const CHROME_TOTAL_H = 2 * CHROME_BORDER + 2 * CHROME_OUTLINE
