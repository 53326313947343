import * as t from '@teamflow/types'

export class SubscribeToRoomSubstateCommand
    implements t.ICommand<{ roomId: string }>
{
    messageType = t.ClientMessage.SubscribeToRoomSubstate

    constructor(private roomId: string) {}

    execute(): { roomId: string } {
        return { roomId: this.roomId }
    }
}
