import * as t from '@teamflow/types'

type Payload = t.RoomSessionForRealTime &
    Partial<{
        _furnish: boolean
        _templateId: string
    }>

/** @group Commands */
export default class UpdateRoomSessionCommand
    implements t.ICommand<t.RoomSessionForRealTime>
{
    readonly messageType = t.ClientMessage.UpdateRoomSession

    private session: Payload

    /**
     * @param session
     * @param options
     * @param options.furnish - When adding a new room session, this will furnish
     *      the room using a randomly selected preset.
     */
    constructor(
        session: t.RoomSessionForRealTime,
        options: {
            templateId?: string
            furnish?: boolean
        } = {}
    ) {
        this.session = {
            _id: session._id,
            roomInviteCode: session.roomInviteCode,
            roomId: session.roomId,
            roomName: session.roomName,
            roomSlugName: session.roomSlugName,
            orgId: session.orgId,
            participantIds: session.participantIds,
            waitlistIds: session.waitlistIds,
            isActive: session.isActive,
            isKnockingEnabled: session.isKnockingEnabled,
            status: session.status,
            backgroundImage: session.backgroundImage,
            backgroundPattern: session.backgroundPattern,
            spatialAudio: session.spatialAudio,
            isPrivate: session.isPrivate,
            startInFullScreenMode: session.startInFullScreenMode,
            invitedIds: session.invitedIds,
            whoCanJoin: session.whoCanJoin,
            owner: session.owner,
            knockingAllowedUserIds: session.knockingAllowedUserIds,
            templateId: session.templateId,
            personalOffice: session.personalOffice,
            type: session.type,
            furnished: session.furnished,
        }

        if (options.furnish) {
            this.session._furnish = options.furnish
        }

        if (options.templateId) {
            this.session._templateId = options.templateId
        }
    }

    execute() {
        return this.session
    }
}
