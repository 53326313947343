import React from 'react'

import { Platform } from '@teamflow/bootstrap'
import { Box, Button, Tooltip } from '@teamflow/design'
import { KeyboardShortcut } from '@teamflow/types'

interface Props {
    label: string
    icon: React.ReactElement
    shortcut?: KeyboardShortcut
    disabled?: boolean
    loading?: boolean
    hideLabel?: boolean
    onClick: () => void
}

export default function HuddleActionButton({
    label,
    icon,
    shortcut,
    disabled = false,
    loading = false,
    hideLabel = false,
    onClick,
}: Props) {
    const button = (
        <Button
            aria-label={label}
            leadingIcon={icon}
            kind="text"
            isLoading={loading}
            disabled={disabled || loading}
            style={{
                backgroundColor:
                    disabled || loading ? 'transparent' : undefined,
                boxShadow: 'none',
                padding: 0,
                paddingLeft: 4,
                paddingRight: 4,
                width: 32,
                height: 32,
                transform: loading ? 'translate(-8px, 0)' : undefined,
            }}
            onClick={onClick}
        />
    )

    if (hideLabel) {
        return button
    }

    return (
        <Box position="relative" width="size32" height="size32">
            <Tooltip
                side="bottom"
                sideOffset={16}
                content={label}
                shortcut={shortcut ? Platform.tooltip(shortcut) : undefined}
                disabled={disabled}
            >
                {button}
            </Tooltip>
        </Box>
    )
}
