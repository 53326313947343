import { waitForSeconds } from '@teamflow/lib'
import { ConnectionDroppedCode } from '@teamflow/types'

import type { ISharedState } from '../sharedState'

import { ConnectionTransition } from './enums'
import { State, StateMachine } from './types'

/** @group Connection States */
export default class Disconnect implements State {
    private readonly sharedState: ISharedState

    constructor(sharedState: ISharedState) {
        this.sharedState = sharedState
    }

    async onEnter(
        machine: StateMachine,
        data: {
            consented: boolean
            code: ConnectionDroppedCode
            simulate: boolean
            lostHeartbeat?: boolean
        } = {
            consented: true,
            code: ConnectionDroppedCode.CloseNormal,
            simulate: false,
        }
    ) {
        const room = this.sharedState.activeRoom
        if (!room) {
            return
        }

        // Colyseus's room.leave can sometimes hang on a faulty network -- don't await it
        void room.leave(data.consented)

        if (data.simulate) {
            await waitForSeconds(2)
        }

        await machine.runTransition(ConnectionTransition.Disconnected, data)
    }
}
