import { Root, Indicator } from '@radix-ui/react-progress'
import { styled } from '@stitches/react'

const StyledRoot = styled(Root, {
    position: 'relative',
    overflow: 'hidden',
    background: 'rgba(222, 197, 245, 0.6)',
    borderRadius: '100px',
    width: '100%',
    height: 16,
})

const StyledIndicator = styled(Indicator, {
    backgroundColor: '#9B51E0',
    height: '100%',
    transition: 'width 660ms cubic-bezier(0.65, 0, 0.35, 1)',
})

type Props = {
    value: number
    width?: number | string
    height?: number | string
    margin?: string
}

export function Progress({ value, width, height, margin }: Props) {
    return (
        <StyledRoot
            css={{
                width,
                height,
                margin,
            }}
        >
            <StyledIndicator
                style={{ width: `${Math.min(100, Math.max(value, 0))}%` }}
            />
        </StyledRoot>
    )
}
