import * as types from '@teamflow/types'
import { INetworkQuality } from '@teamflow/types'

export class ParticipantNetworkQualityChangedCommand
    implements types.ICommand<types.IParticipantNetworkQualityChanged>
{
    readonly messageType = types.ClientMessage.ParticipantNetworkQualityChanged

    constructor(private networkQuality: INetworkQuality) {}

    execute() {
        return {
            quality: this.networkQuality.quality,
            qualityThreshold: this.networkQuality.qualityThreshold,
            packetLoss: this.networkQuality.packetLoss,
            receivePacketLoss: this.networkQuality.receivePacketLoss,
            sendPacketLoss: this.networkQuality.sendPacketLoss,
        }
    }
}
