import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { styled } from '@stitches/react'
import { CSSProperties, useMemo } from 'react'

import { COLOR, getColor, getInitials } from '../../helpers/avatar'

const StyledAvatar = styled(AvatarPrimitive.Root, {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    userSelect: 'none',
    width: 24,
    height: 24,
    borderRadius: '100%',
    fontSize: 12,
    fontWeight: 500,
    color: 'white',
    variants: {
        size: {
            xs: {
                width: 18,
                height: 18,
                fontSize: 10,
                fontWeight: 600,
            },
            sm: {
                width: 24,
                height: 24,
                fontSize: 12,
                fontWeight: 600,
            },
            md: {
                width: 32,
                height: 32,
                fontSize: 14,
            },
            mdlg: {
                width: 40,
                height: 40,
                fontSize: 16,
            },
            lg: {
                width: 64,
                height: 64,
                fontSize: 18,
            },
            xlg: {
                width: 88,
                height: 88,
                fontSize: 32,
            },
            xxl: {
                width: 190,
                height: 190,
                fontSize: 64,
            },
            fill: {
                width: '100%',
                height: '100%',
                objectFit: 'fill',
                fontSize: 64,
            },
        },
        variant: {
            participant: {
                userSelect: 'none',
                pointerEvents: 'none',
                transition: 'opacity 2s ease',
            },
            grid: {
                userSelect: 'none',
                pointerEvents: 'none',
                borderRadius: 0,
            },
        },
        hidden: {
            true: {
                display: 'none',
            },
        },
        square: {
            true: {
                borderRadius: '16.6%',
            },
        },
        capitalize: {
            true: {
                textTransform: 'capitalize',
            },
        },
        shadow: {
            true: {
                border: '0.5px solid rgba(0, 0, 0, 0.1)',
                boxShadow:
                    '0px 1px 1px rgba(0, 0, 0, 0.04), 0px 8px 12px rgba(0, 0, 0, 0.1)',
            },
        },
    },
})

const StyledImage = styled(AvatarPrimitive.Image, {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 'inherit',
    backgroundColor: '#2F3339',
})

const StyledFallback = styled(AvatarPrimitive.Fallback, {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1,
    variants: {
        color: {
            primary1: {
                backgroundColor: COLOR.primary1,
            },
            danger1: {
                backgroundColor: COLOR.danger1,
            },
            warning1: {
                backgroundColor: COLOR.warning1,
            },
            success1: {
                backgroundColor: COLOR.success1,
            },
            info1: {
                backgroundColor: COLOR.info1,
            },
            purpleOne: {
                backgroundColor: '#CC95FF',
            },
        },
    },
})

interface Props {
    name?: string
    src?: string
    size?: 'xs' | 'sm' | 'md' | 'mdlg' | 'lg' | 'xlg' | 'xxl' | 'fill'
    variant?: 'participant' | 'grid'
    style?: CSSProperties
    testId?: string
    bg?: 'purpleOne'
    hidden?: boolean
    square?: boolean
    capitalize?: boolean
    shadow?: boolean
}

export default function Avatar({
    name,
    src,
    size = 'sm',
    variant,
    style,
    testId,
    bg,
    hidden,
    square,
    capitalize,
    shadow,
}: Props) {
    const [initials, color] = useMemo(() => {
        return [getInitials(name), bg || getColor(name)]
    }, [name, bg])
    return (
        <StyledAvatar
            data-testid={testId}
            size={size}
            variant={variant}
            style={style}
            hidden={hidden}
            square={square}
            capitalize={capitalize}
            shadow={shadow}
        >
            <StyledImage src={src} alt={name} draggable={false} />
            <StyledFallback color={color}>{initials}</StyledFallback>
        </StyledAvatar>
    )
}
