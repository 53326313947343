import { useTranslation } from 'react-i18next'

import { Box, Link, Text } from '@teamflow/design'
import allTKeys from '@teamflow/translations'

import { analytics } from '../../helpers'

import Logo from './HuddleLogo'

const translationPage = 'common'
const tKeys = allTKeys.common.huddleHeader

export default function HuddleHeader({
    showSignUp = false,
    showLogin = false,
}) {
    const { t, ready } = useTranslation(translationPage)

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="electron-draggable"
        >
            <Box
                display="flex"
                width="fill"
                alignItems="center"
                justifyContent="between"
                paddingX={{
                    mobile: 'space32',
                    tablet: 'space32',
                    desktop: 'space128',
                }}
                paddingY="space32"
                __cssOverrides={{
                    maxWidth: 1440,
                }}
            >
                <a
                    id="link_to_home"
                    className="electron-non-draggable"
                    href="https://www.teamflowhq.com"
                    aria-label={ready ? t(tKeys.linkToHome) : undefined}
                >
                    <Logo />
                </a>
                {showSignUp && (
                    <Text
                        size="textSize14"
                        weight="600"
                        color="neutral60"
                        className="electron-non-draggable"
                    >
                        {t(tKeys.dontHaveAnAccount)}{' '}
                        <Link level="neutral" href="/signup">
                            {t(tKeys.signUp)}
                        </Link>
                    </Text>
                )}
                {showLogin && (
                    <Text
                        size="textSize14"
                        weight="600"
                        color="neutral60"
                        className="electron-non-draggable"
                    >
                        {t(tKeys.alreadyOnATeam)}{' '}
                        <span
                            onClick={() => {
                                analytics.track(
                                    analytics.Signup.LEGACY_LoginAlreadyOnTeam
                                )
                            }}
                        >
                            <Link
                                level="primary"
                                // logout route clears a session and takes user to login
                                href="/logout"
                            >
                                {t(tKeys.logIn)}
                            </Link>
                        </span>
                    </Text>
                )}
            </Box>
        </Box>
    )
}
