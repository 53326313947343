import { TriggerAction } from '@teamflow/types'

import { Verse } from '../verse'

export enum TriggerResult {
    Ok,
    Break,
}

export type ActionContext = {
    verse?: Verse
}

export type ActionHandler = (
    context: ActionContext,
    payload?: any
) => Promise<TriggerResult>

export interface IActionDefinition {
    name: TriggerAction
    payload?: unknown
}

export interface ITriggerEvent {
    event: string
    payloadMatch?: Record<string, unknown>
    actions?: IActionDefinition[]
    cooldown?: number
}

export { TriggerAction }
