import { api } from '@teamflow/client-api'
import { InviteToRoomCommand } from '@teamflow/client-realtime'
import rootStore, { IRoom, User } from '@teamflow/store'
import {
    IRole,
    AccessOptions,
    InviteType,
    IRealtimeService,
} from '@teamflow/types'
import Verse from '@teamflow/web/src/verse/Verse'

type EmailUser = Pick<User, '_id' | 'email'>

/**
 * Adds a member to the invited list of a room and ensures that the member
 * has the role to enter the room.
 * @param room The private room
 * @param userId The user that needs access
 * @param verse
 */
export async function giveSingleUserAccessToPrivateRoom(
    room: IRoom,
    userId: string,
    realtime: IRealtimeService
) {
    const orgId = rootStore.organization.data?._id
    if (!orgId) return

    const { data: roomRoles } = await api.role.roomRoles.create(
        orgId,
        room.slug
    )
    if (!roomRoles) {
        throw new Error('Error adding user to room.  Please try again later.')
    }

    const [fullRole]: IRole[] = roomRoles

    await api.userOrg.role.create({
        userOrgId: userId,
        roleId: fullRole._id,
    })

    await api.room.permissions.insertInvited({
        roomId: room.id,
        invitedIds: [userId],
    })

    const userIdInVerse = realtime.userOrganizationId
    if (userIdInVerse) {
        realtime.dispatch(
            new InviteToRoomCommand(userIdInVerse, [userId], room.id)
        )
    }
}

// TODO: Move to org.ts
export async function inviteToRoom(
    room: IRoom,
    selectedUsers: Set<EmailUser>,
    accessLevel: AccessOptions,
    verse: Verse
) {
    // how likely is it that there won't be an org? should i handle this better?
    const orgId = rootStore.organization.data?._id
    if (!orgId) return

    const { data: roomRoles } = await api.role.roomRoles.create(
        orgId,
        room.slug
    )
    if (!roomRoles) {
        // TODO: translate?
        throw new Error('Error inviting to room.  Please try again later.')
    }

    const [fullRole, viewRole]: IRole[] = roomRoles

    // you are inviting team members
    // get the list of existing team member ids
    const idsToInvite = [...selectedUsers]
        .map((user) => user._id || '')
        .filter((userId) => userId !== '')

    // these can be directly invited
    // now add the role to each userOrg
    const roleId =
        accessLevel === AccessOptions.FullAccess ? fullRole._id : viewRole._id

    if (idsToInvite.length) {
        await Promise.all(
            idsToInvite.map((id) =>
                api.userOrg.role.create({
                    userOrgId: id,
                    roleId,
                })
            )
        )

        // and insert the user ids in the invite list for the room
        await api.room.permissions.insertInvited({
            roomId: room.id,
            invitedIds: idsToInvite,
        })

        const userId = verse.realtime.userOrganizationId
        if (userId) {
            verse.realtime.dispatch(
                new InviteToRoomCommand(userId, idsToInvite, room.id)
            )
        }

        // now send emails to each of these members
        const emailsToInvite = [...selectedUsers]
            .filter((user) => !!user._id && !!user.email)
            .map((user) => user.email)
        // TODO(RYAN:API) update once API no longer uses room sessions
        await api.email.inviteToPrivateRoom({
            emails: emailsToInvite,
            roomSessionId: room.sessionId,
        })
    }

    // now find the emails of those that are not yet members
    const emailsToInvite: string[] = [...selectedUsers]
        .filter((user) => !user._id && user.email)
        .map((user) => user.email || '')

    if (emailsToInvite.length) {
        if (accessLevel === AccessOptions.FullAccess) {
            // invite members to join the org
            await api.email.inviteToOrg({
                emails: emailsToInvite.map((e) => ({
                    email: e,
                    accessLevel: InviteType.Member,
                    roles: [roleId],
                })),
                roomId: room.id,
            })
        } else {
            if (!room) {
                throw new Error('Room does not exist!')
            }
            // invite guests
            await api.email.inviteToRoom({
                emails: emailsToInvite.map((e) => ({
                    email: e,
                    accessLevel: InviteType.Guest,
                    roles: [roleId],
                })),
                roomSessionId: room.sessionId, // TODO(RYAN:API) update once API no longer uses room sessions
            })
        }
    }

    return {
        updatedUsers: idsToInvite,
        emailedUsers: emailsToInvite,
    }
}
