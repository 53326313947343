import EventEmitter from 'eventemitter3'

import { createEventRemover, IEventRemover, Index, Wall } from '@teamflow/lib'
import * as t from '@teamflow/types'

import type { Room } from 'colyseus.js'

export interface ISharedState extends t.IRealtimeStateLookup {
    readonly floorInviteCode: string
    readonly backgroundImage: string
    readonly furniture: t.IFurnitureConfig[]
    readonly roomLayout: t.IPortal[]
    readonly deployment: t.IDeployment

    readonly activeRoom: Room<t.IVerseState> | undefined

    setActiveRoom(newRoom: Room<t.IVerseState>): void
    setFloorInviteCode(code: string): void
    setBackgroundImage(url: string): void

    getPortals(): t.IPortal[]
    getAudioZones(): t.IAudioZone[]
    getPins(): t.IPinSchema[]
    getSpeakerCircles(): t.ISpeakerCircleSchema[]

    removeFurniture(value: t.IFurnitureConfig): void
    addFurniture(value: t.IFurnitureConfig): void
    clearFurniture(): void

    removePortal(value: t.IPortal): void
    addPortal(value: t.IPortal): void

    removeAudioZone(value: t.IAudioZone): void
    addAudioZone(value: t.IAudioZone): void

    removeSpeakerCircle(value: t.ISpeakerCircle): void
    addSpeakerCircle(value: t.ISpeakerCircle): void

    removePin(value: t.IPinSchema): void
    addPin(value: t.IPinSchema): void

    addSpace(value: t.ISpaceSchema): void
    removeSpace(value: t.ISpaceSchema): void

    onRoomChanged(
        cb: (room: Room<t.IVerseState>) => void,
        context?: any
    ): IEventRemover

    destroy(): void
}

enum Events {
    RoomChanged = 'room-changed',
}

export function createSharedState(): ISharedState {
    let floorInviteCode = ''
    let backgroundImage = ''
    let room: Room<t.IVerseState>

    const furnitureById = new Map<string, t.IFurnitureConfig>()
    const audioZoneById = new Map<string, t.IAudioZone>()
    const portalById = new Map<string, t.IPortal>()
    const portalsByTo = new Map<string, Map<string, t.IPortal>>()
    const pinById = new Map<string, t.IPinSchema>()
    const speakerCircleById = new Map<string, t.ISpeakerCircleSchema>()
    const spaceById = new Map<string, t.ISpaceSchema>()

    const events = new EventEmitter()

    return {
        get floorInviteCode() {
            return floorInviteCode
        },
        get backgroundImage() {
            return backgroundImage
        },
        get furniture() {
            return Array.from(furnitureById.values())
        },
        get roomLayout() {
            return [...portalById.values()]
        },
        get deployment() {
            return { ...room?.state.deployment }
        },

        get activeRoom() {
            return room
        },

        getAssets() {
            return room?.state.assets ?? null
        },

        getIndexedAsset(index: number): t.IIndexedAsset | null {
            const indexStr = Index.encodeIndex(index)

            return room?.state.assets.get(indexStr) ?? null
        },

        getFurniture(id: string) {
            return furnitureById.get(id)
        },

        getPortal(id: string) {
            return portalById.get(id)
        },

        getAudioZone(id: string) {
            return audioZoneById.get(id)
        },
        getPin(id: string) {
            return pinById.get(id) as t.IPin | undefined // TODO: IPin.action.meta type is messed up
        },
        getSpeakerCircle(id: string) {
            return speakerCircleById.get(id)
        },

        getPortals() {
            return [...portalById.values()]
        },

        getPortalsTo(to: string) {
            return portalsByTo.get(to) ?? new Map<string, t.IPortal>()
        },

        getAudioZones() {
            return [...audioZoneById.values()]
        },

        getPins() {
            return [...pinById.values()]
        },

        getSpeakerCircles() {
            return [...speakerCircleById.values()]
        },

        getSpace(id: string) {
            return spaceById.get(id)
        },

        getWall(id: string, location: string): t.IPopulatedWall | undefined {
            const wall = this.getSpace(location)?.walls.get(id)

            if (wall) {
                return Wall.populate(
                    id,
                    location,
                    wall,
                    room?.state as t.IVerseState
                )
            }

            return undefined
        },

        getWallsInRoom(roomId: string): t.IPopulatedWall[] {
            const state = room?.state

            return [
                ...(state?.roomSubstates.get(roomId)?.space?.walls.entries() ||
                    []),
            ].map(([id, wall]) =>
                Wall.populate(id, roomId, wall, state as t.IVerseState)
            )
        },

        setFloorInviteCode(code: string) {
            floorInviteCode = code
        },
        setBackgroundImage(url: string) {
            backgroundImage = url
        },
        setActiveRoom(newRoom: Room<t.IVerseState>) {
            room = newRoom
            events.emit(Events.RoomChanged, newRoom)
        },

        removeFurniture(value: t.IFurnitureConfig) {
            furnitureById.delete(value.id)
        },
        addFurniture(value: t.IFurnitureConfig) {
            furnitureById.set(value.id, value)
        },
        clearFurniture() {
            furnitureById.clear()
        },

        removePortal(value: t.IPortal) {
            portalById.delete(value.id)
            portalsByTo.get(value.to ?? '')?.delete(value.id)
        },
        addPortal(value: t.IPortal) {
            portalById.set(value.id, value)

            const toMap = portalsByTo.get(value.to) ?? new Map()
            toMap.set(value.id, value)
            portalsByTo.set(value.to, toMap)
        },

        removeAudioZone(value: t.IAudioZone) {
            audioZoneById.delete(value.id)
        },
        addAudioZone(value: t.IAudioZone) {
            audioZoneById.set(value.id, value)
        },

        removeSpeakerCircle(value: t.ISpeakerCircle) {
            speakerCircleById.delete(value.id)
        },
        addSpeakerCircle(value: t.ISpeakerCircleSchema) {
            speakerCircleById.set(value.id, value)
        },

        removePin(value: t.IPinSchema) {
            pinById.delete(value.id)
        },
        addPin(value: t.IPinSchema) {
            pinById.set(value.id, value)
        },

        removeSpace(value: t.ISpaceSchema) {
            spaceById.delete(value.id)
        },
        addSpace(value: t.ISpaceSchema) {
            spaceById.set(value.id, value)
        },

        onRoomChanged(cb: (room: Room<t.IVerseState>) => void, context?: any) {
            events.on(Events.RoomChanged, cb, context)

            return createEventRemover(() => {
                events.off(Events.RoomChanged, cb, context)
            })
        },

        destroy() {
            events.removeAllListeners()
        },
    }
}
