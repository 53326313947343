import { track } from '@teamflow/bootstrap'
import rootStore from '@teamflow/store'

import { PARTICIPANT_LEFT_MEETING } from './analytics'

export const trackSecondsInMeeting = () => {
    if (rootStore.users.meetingJoinedAt) {
        const secondsInMeeting =
            (Date.now() - rootStore.users.meetingJoinedAt) / 1000
        track(PARTICIPANT_LEFT_MEETING, {
            userId: rootStore.users.localUser?._id ?? '',
            properties: {
                secondsInMeeting,
                roomId: rootStore.commons.avConnectedSpace,
                participantsInMeeting:
                    rootStore.participants.participantsInSameAVConnectedSpace
                        .length,
            },
        })
        rootStore.users.setMeetingJoinedAt(undefined)
    }
}
