import rootStore, { Participant } from '@teamflow/store'
import * as t from '@teamflow/types'

export default class ParticipantChangeAvailabilityCommand
    implements t.ICommand<t.IParticipantChangeAvailability>
{
    readonly messageType = t.ClientMessage.ParticipantAvailabilityChange

    private status: t.AvailabilityStatus
    private manual: boolean

    constructor(status: t.AvailabilityStatus, manual: boolean) {
        this.status = status
        this.manual = manual
    }

    execute() {
        return {
            availability: this.status,
            ...(this.manual ? { manualAvailability: this.status } : {}),
        }
    }

    localExecute(state: t.IVerseState, userId: string) {
        // update colyseus client
        const person = state.participantsGlobal.find((p) => p.id === userId)
        if (person) {
            person.availability = this.status
            if (this.manual) person.manualAvailability = this.status
        }
        // update mobx store
        const participant = rootStore.participants.findParticipantById(userId)
        if (participant) {
            const updates: Partial<Participant> = {
                availability: this.status,
            }
            if (this.manual) updates.manualAvailability = this.status
            participant.update(updates)
        }
    }
}
