import { styled } from '@stitches/react'

export const MenuDivider = styled('hr', {
    borderBottomColor: 'rgb(224, 224, 224)',
    borderWidth: '0px 0px 1px',
    margin: '2px 0',
    opacity: 0.6,
    width: '100%',
    height: 0,
})

export default MenuDivider
