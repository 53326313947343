import { request } from '../request'

const orgRoles = {
    list: (orgId: string) =>
        request<'GET /api/role/:id'>('GET', `/api/role/${orgId}`),
}

const roomRoles = {
    create: (orgId: string, roomSlug: string) =>
        request<'POST /api/role/:id/room'>('POST', `/api/role/${orgId}/room`, {
            body: {
                roomSlug,
            },
        }),
}

export default {
    orgRoles,
    roomRoles,
}
