import { Box, Button, Link, Stack, Text } from '@teamflow/design'

import { CenterContent } from '../common'
import HuddleHeader from '../common/HuddleHeader'
import HuddleHeading from '../common/HuddleHeading'

export default function VerseErrorPage() {
    function reload() {
        window.location.reload()
    }

    return (
        <>
            <HuddleHeader />
            <CenterContent centerVertical>
                <Stack space="space32" alignX="center">
                    <Box marginBottom="space20">
                        <Text size="textSize128" selectable={false}>
                            😟
                        </Text>
                    </Box>
                    <HuddleHeading align="center" marginBottom="none">
                        {/* TODO: translation needed */}
                        {'Well, that’s embarrassing...'}
                    </HuddleHeading>

                    <Text color="neutral70" align="center">
                        {/* TODO: translation needed */}
                        Teamflow encountered an unexpected problem. If this
                        issue persists, please{' '}
                        <Link href="mailto:support@teamflowhq.com">
                            {/* TODO: translation needed */}
                            contact support
                        </Link>
                        .
                    </Text>
                    <Button size="lg" isFullWidth onClick={reload}>
                        Reload
                    </Button>
                </Stack>
            </CenterContent>
        </>
    )
}
