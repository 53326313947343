const user = {
    signUpToYourOffice: 'user.signUpToYourOffice',
    signUpToYourSpace: 'user.signUpToYourSpace',
    join: 'user.join',
    create: 'user.create',
    continueWithGoogle: 'user.continueWithGoogle',
    noThanksIdRatherCreateAnAccountWithMyEmailAndPassword:
        'user.noThanksIdRatherCreateAnAccountWithMyEmailAndPassword',
    pleaseFillOutTheEntireForm: 'user.pleaseFillOutTheEntireForm',
    firstName: 'user.firstName',
    pleaseEnterYourFirstName: 'user.pleaseEnterYourFirstName',
    lastName: 'user.lastName',
    pleaseEnterYourLastName: 'user.pleaseEnterYourLastName',
    workEmail: 'user.workEmail',
    notWorkEmail: 'user.notWorkEmail',
    anAccountAlreadyExistsWithThisEmailDidYouMeanToLogIn:
        'user.anAccountAlreadyExistsWithThisEmailDidYouMeanToLogIn',
    pleaseEnterAValidEmail: 'user.pleaseEnterAValidEmail',
    password: 'user.password',
    minCharacters: 'user.minCharacters',
    passwordsMustBeAtLeastCharacters: 'user.passwordsMustBeAtLeastCharacters',
    passwordConfirm: 'user.passwordConfirm',
    enterAgainToConfirm: 'user.enterAgainToConfirm',
    passwordsMustMatch: 'user.passwordsMustMatch',
    continue: 'user.continue',
    byClickingSignUpWithGoogleEmailAboveYouAcknowledgeYouHaveReadAndAgreeToTeamflowsTermsAndConditionsAndPrivacyPolicy:
        'user.byClickingSignUpWithGoogleEmailAboveYouAcknowledgeYouHaveReadAndAgreeToTeamflowsTermsAndConditionsAndPrivacyPolicy',
    phone: 'user.phone',
    pleaseEnterAValidPhoneNumber: 'user.pleaseEnterAValidPhoneNumber',
    youAreInvitedToJoinOrgNamesOffice: 'user.youAreInvitedToJoinOrgNamesOffice',
    pleaseUseWorkEmail: 'user.pleaseUseWorkEmail',
}

const chooseInviteFromEmail = {
    verifyYourEmail: 'chooseInviteFromEmail.verifyYourEmail',
    looksLikeYourCompanyHasATeamflowAlreadyAtOrg:
        'chooseInviteFromEmail.looksLikeYourCompanyHasATeamflowAlreadyAtOrg',
    looksLikeYourCompanyHasATeamflowAlready:
        'chooseInviteFromEmail.looksLikeYourCompanyHasATeamflowAlready',
    joinTheRestOfYourTeamAtOrg:
        'chooseInviteFromEmail.joinTheRestOfYourTeamAtOrg',
    joinTheRestOfYourTeam: 'chooseInviteFromEmail.joinTheRestOfYourTeam',
    at: 'at',
    weVeSentAnEmailToEmailPleaseClickOnTheLinkInsideItToContinue:
        'chooseInviteFromEmail.weVeSentAnEmailToEmailPleaseClickOnTheLinkInsideItToContinue',
    verifyYourEmailToCompleteYourRegistrationAnEmailHasBeenSentToEmail:
        'chooseInviteFromEmail.verifyYourEmailToCompleteYourRegistrationAnEmailHasBeenSentToEmail',
    resendEmail: 'chooseInviteFromEmail.resendEmail',
    continueAsGuest: 'chooseInviteFromEmail.continueAsGuest',
    contactSupport: 'chooseInviteFromEmail.contactSupport',
}

const company = {
    aCompanyNameIsRequired: 'company.aCompanyNameIsRequired',
    setupYourSpace: 'company.setupYourSpace',
    setupYourCompany: 'company.setupYourCompany',
    setupYourSalesfloor: 'company.setupYourSalesfloor',
    nameOfTheSpace: 'company.nameOfTheSpace',
    yourCompany: 'company.yourCompany',
    companyNameAlreadyExists: 'company.companyNameAlreadyExists',
    companyNameRequired: 'company.companyNameRequired',
    companySize: 'company.companySize',
    chooseOne: 'company.chooseOne',
    yourRole: 'company.yourRole',
    productManager: 'company.productManager',
    engineer: 'company.engineer',
    designer: 'company.designer',
    manager: 'company.manager',
    ceo: 'company.ceo',
    marketing: 'company.marketing',
    other: 'company.other',
    continue: 'company.continue',
    genericCompanyError: 'company.genericCompanyError',
    phone: 'company.phone',
    pleaseEnterAValidPhoneNumber: 'company.pleaseEnterAValidPhoneNumber',
    pleaseSelectCompanySize: 'company.pleaseSelectCompanySize',
}

const room = {
    setupYourRoom: 'room.setupYourRoom',
}

const invite = {
    pleaseEnterAValidEmail: 'invite.pleaseEnterAValidEmail',
    failedToSendInvitesPleaseTryAgain:
        'invite.failedToSendInvitesPleaseTryAgain',
    failedToLoadTeamFromSlackPleaseTryAgain:
        'invite.failedToLoadTeamFromSlackPleaseTryAgain',
    loading: 'invite.loading',
    inviteMembers: 'invite.inviteMembers',
    inviteYourTeam: 'invite.inviteYourTeam',
    inviteYourTeamViaSlack: 'invite.inviteYourTeamViaSlack',
    checkEmailAddresses: 'invite.checkEmailAddresses',
    inviteViaGoogle: 'invite.inviteViaGoogle',
    inviteViaSlack: 'invite.inviteViaSlack',
    inviteViaEmail: 'invite.inviteViaEmail',
    inviteOneMoreMember: 'invite.inviteOneMoreMember',
    inviteOneMoreTeammate: 'invite.inviteOneMoreTeammate',
    inviteViaLink: 'invite.inviteViaLink',
    copied: 'invite.copied',
    copyLink: 'invite.copyLink',
    iLlDoThisLater: 'invite.iLlDoThisLater',
    anyoneWithEmailCanJoin: 'invite.anyoneWithEmailCanJoin',
    inviteNumCheckedMembers: 'invite.inviteNumCheckedMembers',
    inviteNumCheckedToYourTeam: 'invite.inviteNumCheckedToYourTeam',
    noThanksIllInviteMembersManually: 'invite.noThanksIllInviteMembersManually',
    noThanksIllInviteMyTeamManually: 'invite.noThanksIllInviteMyTeamManually',
}

const meetingMigration = {
    findingYourRecurringMeetings:
        'meetingMigration.findingYourRecurringMeetings',
    weFoundCountRecurringMeetings:
        'meetingMigration.weFoundCountRecurringMeetings',
    moveThemToTeamflowToHaveBetterMoreFocusedMeetings:
        'meetingMigration.moveThemToTeamflowToHaveBetterMoreFocusedMeetings',
    formACircleWithYourTeamBringYourFavoritePlanningToolNextToYouAndGo:
        'meetingMigration.formACircleWithYourTeamBringYourFavoritePlanningToolNextToYouAndGo',
    setUpATimerAndReviewWhatWorkedAndDidntWorkWithYourSprintTeam:
        'meetingMigration.setUpATimerAndReviewWhatWorkedAndDidntWorkWithYourSprintTeam',
    haveMorePersonalAndNaturalOfficeHoursAnd11MeetingsWithYourTeam:
        'meetingMigration.haveMorePersonalAndNaturalOfficeHoursAnd11MeetingsWithYourTeam',
    useSpatialAudioAndPlayGamesTogetherLikeSkribblPokerOrTetris:
        'meetingMigration.useSpatialAudioAndPlayGamesTogetherLikeSkribblPokerOrTetris',
    liveTitleAnimation: 'meetingMigration.liveTitleAnimation',
    noKeywordToAdd: 'meetingMigration.noKeywordToAdd',
    transferCheckedCountMeetingsToTeamflow:
        'meetingMigration.transferCheckedCountMeetingsToTeamflow',
    dontTransferMeetingsToTeamflow:
        'meetingMigration.dontTransferMeetingsToTeamflow',
    noThanksIllDoThisLater: 'meetingMigration.noThanksIllDoThisLater',
}

const download = {
    doneDownloading: 'download.doneDownloading',
    openTheFileByDoubleClickingItInYourDownloadsFolder:
        'download.openTheFileByDoubleClickingItInYourDownloadsFolder',
    followTheInstructionsToInstallTeamflowToYourComputer:
        'download.followTheInstructionsToInstallTeamflowToYourComputer',
    afterTheAppIsInstalledLaunchTeamflowAndSignIn:
        'download.afterTheAppIsInstalledLaunchTeamflowAndSignIn',
    downloadTheApp: 'download.downloadTheApp',
    useOurDesktopAppToEnjoyBetterPerformanceOrJoinRightInTheBrowser:
        'download.useOurDesktopAppToEnjoyBetterPerformanceOrJoinRightInTheBrowser',
    hangOutWithYourTeamOnOurDesktopAppAndEnjoyBetterPerformanceOrJoinRightFromTheBrowser:
        'download.hangOutWithYourTeamOnOurDesktopAppAndEnjoyBetterPerformanceOrJoinRightFromTheBrowser',
    download: 'download.download',
    openHereInstead: 'download.openHereInstead',
    noThanksIllGoToMyOfficeInTheBrowser:
        'download.noThanksIllGoToMyOfficeInTheBrowser',
    openTheDesktopApp: 'download.openTheDesktopApp',
    clickOpenTeamflowToLaunchTheApp: 'download.clickOpenTeamflowToLaunchTheApp',
    canAlsoOpenInTheBrowser: 'download.canAlsoOpenInTheBrowser',
    inTheBrowser: 'download.inTheBrowser',
    downloadNow: 'download.downloadNow',
    openTeamflowDesktop: 'download.openTeamflowDesktop',
    dontHaveTeamflowInstalled: 'download.dontHaveTeamflowInstalled',
}

const guest = {
    pleaseFillOutTheEntireForm: 'guest.pleaseFillOutTheEntireForm',
    loading: 'guest.loading',
    unknownError: 'guest.unknownError',
    visitOrgDisplayNameSOffice: 'guest.visitOrgDisplayNameSOffice',
    whatsYourName: 'guest.whatsYourName',
    pleaseEnterYourName: 'guest.pleaseEnterYourName',
    whatWouldYouLikeToBeCalled: 'guest.whatWouldYouLikeToBeCalled',
    whatsYourWorkEmail: 'guest.whatsYourWorkEmail',
    optional: 'guest.optional',
    pleaseEnterAValidEmail: 'guest.pleaseEnterAValidEmail',
    enterTheOffice: 'guest.enterTheOffice',
    whoopsYouMayHaveClickedOnAnInvalidLockedOrExpiredLink:
        'guest.whoopsYouMayHaveClickedOnAnInvalidLockedOrExpiredLink',
    notAGuest: 'guest.notAGuest',
    logIn: 'guest.logIn',
    youCantJoinTheSpace: 'guest.youCantJoinTheSpace',
    tryKnockingAgainInAFewMinutes: 'guest.tryKnockingAgainInAFewMinutes',
    clickHereToKnockAgain: 'guest.clickHereToKnockAgain',
    yourRequestToJoinWasDeclinedByTheHost:
        'guest.yourRequestToJoinWasDeclinedByTheHost',
    ifYouWouldLikeToKnockAgainPleaseRefreshThePage:
        'guest.ifYouWouldLikeToKnockAgainPleaseRefreshThePage',
    waitingForSomeoneToLetYouIn: 'guest.waitingForSomeoneToLetYouIn',
    yourMeetingWillStartShortly: 'guest.yourMeetingWillStartShortly',
    join: 'guest.join',
    askToJoin: 'guest.askToJoin',
    yourEmail: 'guest.yourEmail',
    yourName: 'guest.yourName',
}

const denied = {
    accessMissing: 'denied.accessMissing',
    looksLikeYouDontHaveAccessToThisOrganizationOrHadAnInvalidLink:
        'denied.looksLikeYouDontHaveAccessToThisOrganizationOrHadAnInvalidLink',
    oops: 'denied.oops',
    logIn: 'denied.logIn',
    workspaceNotFound: 'denied.workspaceNotFound',
    itSeemsLikeYouDoNotHaveTheRightLink:
        'denied.itSeemsLikeYouDoNotHaveTheRightLink',
}

const creditCard = {
    weHadAProblemChargingThisCardPleaseTryAnotherCard:
        'creditCard.weHadAProblemChargingThisCardPleaseTryAnotherCard',
    failedToCreateASubscription: 'creditCard.failedToCreateASubscription',
    noPaymentMethodWasCreated: 'creditCard.noPaymentMethodWasCreated',
    failedToCreateACustomer: 'creditCard.failedToCreateACustomer',
    somethingWentWrongPleaseTryAgainLater:
        'creditCard.somethingWentWrongPleaseTryAgainLater',
    subscriptionFailedPleaseContactSupportTeamflowhqCom:
        'creditCard.subscriptionFailedPleaseContactSupportTeamflowhqCom',
    completeSubscription: 'creditCard.completeSubscription',
    payYearlySave25: 'creditCard.payYearlySave25',
    priceSelectedTermInTotal: 'creditCard.priceSelectedTermInTotal',
    youSavedAmountWithCodeCoupon: 'creditCard.youSavedAmountWithCodeCoupon',
    seats: 'creditCard.seats',
}

const avSetup = {
    noneAvailable: 'avSetup.noneAvailable',
    sameAsSystem: 'avSetup.sameAsSystem',
    testYourInput: 'avSetup.testYourInput',
    microphone: 'avSetup.microphone',
    video: 'avSetup.video',
    speakers: 'avSetup.speakers',
    dontShowThisAgain: 'avSetup.dontShowThisAgain',
    setUpYourAudioVideo: 'avSetup.setUpYourAudioVideo',
    signupCreateSalesFloor: 'avSetup.signupCreateSalesFloor',
    clickToEnableAudio: 'avSetup.clickToEnableAudio',
    cantHearYou: 'avSetup.cantHearYou',
    switchYourInput: 'avSetup.switchYourInput',
    havingTroubleWithAV: 'avSetup.havingTroubleWithAV',
    test: 'avSetup.test',
    signupToJoinYourSalesFloor: 'avSetup.signupToJoinYourSalesFloor',
}

const password = {
    veryWeak: 'password.veryWeak',
    weak: 'password.weak',
    moderate: 'password.moderate',
    strong: 'password.strong',
    veryStrong: 'password.veryStrong',
}

export default {
    user,
    chooseInviteFromEmail,
    company,
    room,
    invite,
    meetingMigration,
    download,
    guest,
    denied,
    creditCard,
    avSetup,
    password,
}
