import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export default class AppPinCommand implements t.ICommand<t.IAppPin> {
    readonly messageType = t.ClientMessage.AppPin

    private id = ''
    private pinned = false
    private locationId = ''

    constructor(id: string, pinned: boolean, locationId: string) {
        this.id = id
        this.pinned = pinned
        this.locationId = locationId
    }

    execute() {
        return {
            id: this.id,
            pinned: this.pinned,
            locationId: this.locationId,
        }
    }

    localExecute() {
        rootStore.app.getAppById(this.id)?.setPinned(this.pinned)
    }
}
