import { useTranslation } from 'react-i18next'

import { Platform } from '@teamflow/bootstrap'
import { Box, Stack, Text } from '@teamflow/design'
import { rootStore } from '@teamflow/store'
import allTKeys from '@teamflow/translations'
import * as types from '@teamflow/types'

import { getMobileAppDownloadUrl } from '../helpers/appDownload'
import { openGenericModal } from '../triggers/actions/openGenericModal'
import { TriggerAction } from '../triggers/types'
import { Verse } from '../verse'

import { useFailsafeEffect } from './useFailsafeEffect'

const translationPage = 'common'
const tKeys = allTKeys.common

const WAIT_TIME = 5000

export function useMobileAppPromotion(verse?: Verse) {
    const { t } = useTranslation(translationPage)

    useFailsafeEffect(
        ({ wrap }) => {
            const localUser = rootStore.users.localUser
            const isGuest = localUser?.role === types.Role.GUEST

            if (
                isGuest ||
                !Platform.isPhoneWebBrowser() ||
                Platform.isMobileApp() ||
                !verse
            ) {
                return
            }

            const showJoinMobileBetaModal = () =>
                openGenericModal(
                    { verse },
                    {
                        body: (
                            <Box paddingTop="space16">
                                <Stack space="space24">
                                    <Text weight="600">
                                        {t(
                                            tKeys.mobileApp.downloadTheMobileApp
                                        )}
                                    </Text>
                                    <Text>
                                        {t(
                                            tKeys.mobileApp
                                                .takeTeamflowOnTheGoWithOurNewMobileAppAndChatWithCoworkersJumpOnCallsFromYourMobileDeviceAndMore
                                        )}
                                    </Text>
                                </Stack>
                            </Box>
                        ),
                        dismissText: t(tKeys.settings.cancel),
                        confirmActions: [
                            {
                                name: TriggerAction.OpenURL,
                                payload: {
                                    url: getMobileAppDownloadUrl(),
                                },
                            },
                        ],
                        confirmText: t(tKeys.mobileApp.download),
                    }
                )

            // Open teamflows mobile app if it's installed or falls back to inviting
            // the user to join the mobile app beta.
            window.setTimeout(wrap(showJoinMobileBetaModal), WAIT_TIME)
        },
        [t, verse]
    )
}
