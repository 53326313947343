import * as t from '@teamflow/types'

/** @group Commands */
export default class PingCommand implements t.ICommand<t.IPing> {
    readonly messageType = t.ClientMessage.Ping

    private timestamp = 0

    constructor(timestamp: number) {
        this.timestamp = timestamp
    }

    execute() {
        return { timestamp: this.timestamp }
    }
}
