import { api } from '@teamflow/client-api'
import { waitForMilliseconds, result } from '@teamflow/lib'
import * as t from '@teamflow/types'

import { CustomColyseusClient } from '../customColyseusClient'

import { ConnectionTransition } from './enums'

import type { State, StateMachine } from './types'
import type { Room } from 'colyseus.js'

/** @group Connection States */
export default class CreateAndJoinRoom implements State {
    constructor(
        private readonly client: CustomColyseusClient,
        private readonly logger: t.ILogger
    ) {}

    async onEnter(machine: StateMachine, options: t.JoinOptions) {
        const { orgId, roomName = 'huddle' } = options

        // use locking if joining office org room
        // event-onboarding room does not require creating a lock
        if (roomName === 'huddle') {
            // no existing room when we checked but could have been created
            // after we did available rooms check
            // try to create a lock to denote that a room will be created
            const { data } = await api.verse.lock({ orgId })
            if (!data?.ok) {
                // NOTE: the lock is autoreleased by mongodb after 1~ minute or so
                // rooms are also long-lived and releasing the lock too early
                // has the potential to cause problems so we let the mongo TTL handle it

                // throttle this to try after 10s; the TTL is 60s; theoretical max
                // would be 6 tries before the lock is removed
                // throttling to reduce potential load if one org is having problems
                await waitForMilliseconds(10000)

                // this means someone else is creating the room so try to join instead
                await machine.runTransition(
                    ConnectionTransition.Connect,
                    options
                )
                return
            }
        }

        // continue to create a new realtime room
        const [error, room] = await result<Room<t.IVerseState>, ProgressEvent>(
            this.client.create<t.IVerseState>(roomName, {
                ...options,
                /**
                 * Hotfix for an error when joining and checking join options schema in the RT server.
                 * @see https://www.notion.so/teamflowhq/Realtime-errors-while-joining-915558e0b904402da7356b69143ec23d
                 */
                roomId: options.roomId === '' ? undefined : options.roomId,
            })
        )

        if (error) {
            this.logger.error('Failed to create room', { error, options })
        }

        if (room) {
            await machine.runTransition(ConnectionTransition.Connected, {
                room,
            })
        } else {
            await machine.runTransition(ConnectionTransition.Failed, options)
        }
    }
}
