import { computed, makeAutoObservable, observable } from 'mobx'

import { distanceSquaredBetween, LogManager } from '@teamflow/lib'
import {
    AvailabilityStatus,
    AVMedia,
    IParticipant,
    Location,
    NavigationState,
    QualityThreshold,
    Role,
    SalesFloorDialerCallStatus,
} from '@teamflow/types'

import { Participant } from './Participant'
import { ParticipantSpatialHash } from './ParticipantSpatialHash'
import { RootStore } from './rootStore'

const logger = LogManager.createLogger('ParticipantStore', { critical: true })

export const BLANK_PARTICIPANT: IParticipant = {
    callShard: '',
    id: '',
    x: 0,
    y: 0,
    mouseX: 0,
    mouseY: 0,
    cursor: {
        appId: '',
        bufferedX: [],
        bufferedY: [],
        bufferedTimes: [],
        duration: 0,
    },
    weather: '',
    name: '',
    locationType: Location.Floor,
    locationId: '',
    availability: AvailabilityStatus.AVAILABLE,
    manualAvailability: AvailabilityStatus.AVAILABLE,
    status: '',
    statusMetadata: {
        kind: 'normal',
        vendor: 'manual',
    },
    ready: true,
    navigationState: NavigationState.Idle,
    role: Role.USER,
    isSpeaker: false,
    selectedAppId: '',
    speaking: false,
    focusModeActive: false,
    faceCentering: {
        scale: 1,
        offsetX: 0,
        offsetY: 0,
    },
    roles: [],
    networkQuality: {
        quality: 0,
        qualityThreshold: QualityThreshold.UNKNOWN,
        packetLoss: false,
        receivePacketLoss: 0,
        sendPacketLoss: 0,
    },
    reaction: '',
    isRecording: false,
    isOnShittyInternet: false,
    dialerCallStatus: SalesFloorDialerCallStatus.Hangup,
    listeningToParticipantId: '',
}

export class ParticipantStore {
    rootStore: RootStore

    participantById = observable<string, Participant>(new Map())

    /**
     * Maps participant id -> previous index
     */
    prevRibbonParticipantIdOrder = new Map<string, number>()

    /** For participants who were recently speaking */
    ribbonRecentParticipantIds: string[] = []

    spatialHash = new ParticipantSpatialHash()

    /**
     * Participant that is being highlighted in sidebar, minimap, etc.
     */
    highlightedParticipantId?: string

    /**
     * The roomId that the participant is in.
     *
     * NOT the room session id
     */
    localParticipantRoomId = ''

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            spatialHash: false,
            participantsInSameAVConnectedSpace: computed.struct,
            participantsInSameViewingSpace: computed.struct,
            localParticipant: computed.struct,
            participantsInViewport: computed.struct,
            participantsByRoom: computed.struct,
            nearByParticipants: computed.struct,
            nearByParticipantsUsingSameApp: computed.struct,
            participantsSelected: computed.struct,
            ribbonParticipants: computed.struct,
            ribbonRecentParticipantIds: observable.struct,
            prevRibbonParticipantIdOrder: false,
        })
        this.rootStore = rootStore
    }

    reset() {
        this.participantById.clear()
        this.spatialHash.clear()
        this.localParticipantRoomId = ''
        this.prevRibbonParticipantIdOrder.clear()
    }

    clearParticipants() {
        logger.debug('ParticipantStore.clearParticipants')
        this.participantById.clear()
        this.spatialHash.clear()
    }

    findParticipantById(userId: string) {
        return this.participantById.get(userId)
    }

    /**
     * Adds OR UPDATES a Participant using the partial data. If the participant does
     * not exist, the partial is merged with a blank participant and added as a new
     * entry to the store. If the participant does exist, the partial is merged with
     * the existing participant.
     * @param participant Partial Participant data to merge into the store. Requires an id.
     * @returns New or existing Participant after merge.
     */
    put(participant: Partial<IParticipant> & { id: string }): Participant {
        const existing = this.participantById.get(participant.id)
        if (existing) {
            // update using new data
            existing.update(participant)

            // reset screen position to latest from RT if reconnected
            if (existing.id === this.rootStore.users.localUserId) {
                const tx = existing.spaceComponents.transformComponent as any
                tx?.setPosition(existing.x, existing.y)
            }

            return existing
        }

        const fullUser = this.rootStore.users.getUserById(participant.id)
        logger.debug(
            `ParticipantStore.addParticipant ${fullUser?.fullName ?? 'none'} ${
                participant.id
            } (account ID: ${fullUser?.accountId ?? 'none'})`,
            {
                ready: participant.ready,
                locationId: participant.locationId,
            }
        )

        const item = createParticipant(participant, undefined, this.rootStore)
        this.participantById.set(item.id, item)
        this.spatialHash.add(item)

        return item
    }

    addParticipantsSelected(participants: Participant[]) {
        participants.forEach((p) => {
            p.isSelected = true
        })
    }

    toggleParticipantSelected(participant: Participant) {
        participant.isSelected = !participant.isSelected
    }

    clearParticipantsSelected() {
        this.participantsSelected.forEach((p) => {
            p.isSelected = false
        })
    }

    addExistingParticipant(participant: Participant) {
        const existing = this.participantById.get(participant.id)
        if (existing) {
            return
        }

        this.participantById.set(participant.id, participant)
        this.spatialHash.add(participant)
    }

    removeParticipant(participantId: string) {
        const existing = this.participantById.get(participantId)
        if (!existing) {
            return
        }
        logger.debug(
            `ParticipantStore.removeParticipant ${
                this.rootStore.users.getUserById(participantId)?.fullName ?? ''
            } ${participantId}`,
            {
                locationId: existing.locationId,
            }
        )

        this.participantById.delete(existing.id)
        this.spatialHash.remove(existing)
    }

    updateSpatialHash(participant: Participant) {
        const existing = this.participantById.get(participant.id)
        if (!existing) {
            return
        }
        this.spatialHash.update(participant)
    }

    updateParticipantRoles(participantId: string, roles: string[]) {
        const existing = this.participantById.get(participantId)
        if (!existing) {
            return
        }
        existing.updateRoles(roles)
    }

    resetRibbonOrder(): void {
        this.prevRibbonParticipantIdOrder.clear()
        this.ribbonRecentParticipantIds = []
    }

    addRibbonRecentSpeakingParticipantIds(
        idSet: Set<string>,
        totalVisibleParticipants: number
    ): void {
        // Only reorder unreserved participants
        // (those who aren't presenters and aren't inside a speaker circle)
        const unreservedParticipantIds = this.ribbonUnreservedParticipantIds
        const unreservedIdSet = new Set(unreservedParticipantIds)
        const maxUnreserved = this.getRibbonVisibleUnreservedParticipants(
            totalVisibleParticipants
        )

        // reorder at most maxUnreserved participants, and only those from idSet
        // that are in unreservedIdSet
        const toReorderIdSet = new Set(
            Array.from(idSet)
                .filter((id) => unreservedIdSet.has(id))
                .slice(0, maxUnreserved)
        )

        if (toReorderIdSet.size === 0) return

        // make changes to recentIds, then copy it to _ribbonRecentParticipantIds.
        // initialize with current order of unreserved participants.
        let recentIds = unreservedParticipantIds.slice(0, maxUnreserved)

        // subset of toReorderIdSet only containing ids not already in recentIds.
        // only these ids need to be added.
        const toReorderNewIdSet = new Set(toReorderIdSet)
        recentIds.forEach((id) => toReorderNewIdSet.delete(id))

        // make sure that after adding all ids from toReorderNewIdSet to recentIds,
        // recentIds.length <= maxUnreserved.
        // note: recentIdsKeepCount is never negative because
        // toReorderNewIdSet.size <= maxUnreserved.
        const recentIdsKeepCount = maxUnreserved - toReorderNewIdSet.size
        const recentIdsEvictCount = Math.max(
            recentIds.length - recentIdsKeepCount,
            0
        )

        // potential ids to evict from recentIds to make room for new ids
        const recentIdsSortedBySpeakingTime = recentIds
            // only consider ids that aren't supposed to be reordered in this batch
            .filter((id) => !toReorderIdSet.has(id))
            // least recent speaker first
            .sort((id1, id2) => {
                const { participantById } = this
                const time1 =
                    participantById.get(id1)?.lastSpeakingTime?.getTime() ?? 0
                const time2 =
                    participantById.get(id2)?.lastSpeakingTime?.getTime() ?? 0
                return time1 - time2
            })

        // evict the least recent speakers from recentIds
        const toEvictIdSet = new Set(
            recentIdsSortedBySpeakingTime.slice(0, recentIdsEvictCount)
        )
        recentIds = recentIds.filter((id) => !toEvictIdSet.has(id))

        // add all new ids to front of recentIds
        recentIds = [...toReorderNewIdSet, ...recentIds]

        this.ribbonRecentParticipantIds = recentIds
    }

    /**
     * Participants who are neither presenter nor speakers.
     */
    private get ribbonReservedParticipantsCount() {
        if (!this.localParticipant?.ready) return 0

        const presenterId =
            this.rootStore.presentations.currentPresentation?.presenterId

        const fullList = [
            this.localParticipant,
            ...this.nearByParticipants.filter((p) => p.ready),
        ]

        let reservedCount = 0
        fullList.forEach((p) => {
            if (p.id === presenterId || p.isSpeaker) reservedCount++
        })

        return reservedCount
    }

    /**
     * Participants who are neither presenter nor speakers.
     */
    private get ribbonUnreservedParticipantIds() {
        const presenterId =
            this.rootStore.presentations.currentPresentation?.presenterId

        return this.ribbonParticipants
            .filter((p) => p.id !== presenterId && !p.isSpeaker)
            .map((p) => p.id)
    }

    private getRibbonVisibleUnreservedParticipants(
        totalVisibleParticipants: number
    ) {
        const reservedCount = this.ribbonReservedParticipantsCount
        // always assume at least one participant is visible, so a participant
        // gets reordered to the front even if they would only partially fit
        return Math.max(totalVisibleParticipants - reservedCount, 1)
    }

    get ribbonParticipants() {
        // updates when localParticipant.ready or nearByParticipants changes
        if (!this.localParticipant?.ready) return []

        const fullList = [
            this.localParticipant,
            ...this.nearByParticipants.filter((p) => p.ready),
        ]

        const presenterId =
            this.rootStore.presentations.currentPresentation?.presenterId

        // reserve spot for presenter, then speakers
        const reservedList = [
            ...fullList.filter((p) => p.id === presenterId),
            ...fullList.filter((p) => p.id !== presenterId && p.isSpeaker),
        ]

        // everyone else is subject to reordering
        const unreservedList = [
            ...fullList.filter((p) => p.id !== presenterId && !p.isSpeaker),
        ]

        // if there is previous list keep the order
        const prevOrder = this.prevRibbonParticipantIdOrder

        if (prevOrder.size > 0) {
            unreservedList.sort((a, b) => {
                const aIndex = prevOrder.get(a.id)
                const bIndex = prevOrder.get(b.id)
                // if both new keep their nearBy order
                if (aIndex == null && bIndex == null) return 0
                // if a is new put b first
                if (aIndex == null) return 1
                // if b is new put a first
                if (bIndex == null) return -1
                // sort by lowest prev position first
                return aIndex - bIndex
            })
        }

        prevOrder.clear()
        unreservedList.forEach((p, index) => prevOrder.set(p.id, index))

        const sortByRecentSpeaking = (participants: Participant[]) => {
            const participantMap = new Map(participants.map((p) => [p.id, p]))
            const recentIds = this.ribbonRecentParticipantIds
            const recentIdSet = new Set(recentIds)

            // put speakers who have spoken recently first, in the same order
            // as they appear in the recentIds
            const recentParticipants = recentIds
                .map((id) => participantMap.get(id))
                .filter((p): p is Participant => p != null)

            // preserve the order of the remaining speakers that don't appear
            // in list above
            const otherParticipants = participants.filter(
                (p) => !recentIdSet.has(p.id)
            )

            return [...recentParticipants, ...otherParticipants]
        }

        return [...reservedList, ...sortByRecentSpeaking(unreservedList)]
    }

    findParticipantsInRect(
        x: number,
        y: number,
        width: number,
        height: number,
        excludeLocalUser = false,
        locationId = this.localParticipant?.locationId ?? ''
    ): Participant[] {
        const excludeId = excludeLocalUser
            ? this.rootStore.users.localUserId
            : null

        return this.spatialHash.findParticipants(
            x,
            y,
            width,
            height,
            locationId,
            excludeId
        )
    }

    findParticipantsBySelectedAppId(appId: string): Participant[] {
        return Array.from(this.participantById.values()).filter(
            (p) => p.selectedAppId === appId
        )
    }

    findCollidingParticipants(x: number, y: number, r: number): Participant[] {
        const rSq = r * 2 * (r * 2)
        return this.findParticipantsInRect(
            x - 150,
            y - 150,
            300,
            300,
            true
        ).filter((p) => distanceSquaredBetween(x, y, p.x, p.y) < rSq)
    }

    getParticipantsByLocationId(locationId: string) {
        return this.participantsByRoom[locationId] ?? []
    }

    setLocalParticipantRoomId(roomId: string) {
        this.localParticipantRoomId = roomId
    }

    setLocalParticipantToSpeaker(isSpeaker: boolean) {
        if (this.localParticipant) {
            this.localParticipant.isSpeaker = isSpeaker
        }
    }

    get localParticipant(): Participant | null {
        const localUserId = this.rootStore.users.localUserId
        return localUserId
            ? this.participantById.get(localUserId) ?? null
            : null
    }

    get participantsInSameAVConnectedSpace(): Participant[] {
        const localUserId = this.rootStore.users.localUserId
        if (!localUserId) return []

        const localParticipant = this.participantById.get(localUserId)
        if (localParticipant) {
            return Array.from(this.participantById.values()).filter(
                (p) => p.locationId === localParticipant?.locationId
            )
        }
        return []
    }

    get participantsInSameViewingSpace(): Participant[] {
        const viewingSpace = this.rootStore.commons.viewingSpace
        let spaceSessionId: string | undefined = ''
        if (viewingSpace !== '') {
            spaceSessionId =
                this.rootStore.rooms.roomsById.get(viewingSpace)?.sessionId
            if (spaceSessionId === undefined) {
                return []
            }
        }
        return Array.from(this.participantById.values()).filter(
            (p) => p.locationId === spaceSessionId
        )
    }

    get participantsInViewport(): Participant[] {
        const localUserId = this.rootStore.users.localUserId
        return this.participantsInSameViewingSpace.filter(
            (p) => p.ready && (p.isInViewport || p.id === localUserId)
        )
    }

    get participantsByRoom(): Record<string, Participant[]> {
        const byRoom = Array.from(this.participantById.values()).reduce(
            (acc: Record<string, Participant[]>, participant: Participant) => {
                if (!acc[participant.locationId]) {
                    acc[participant.locationId] = []
                }
                acc[participant.locationId].push(participant)
                return acc
            },
            {}
        )
        Object.values(byRoom).forEach((roomParticipants) =>
            roomParticipants.sort((p1, p2) => {
                const user1 = this.rootStore.users.getUserById(p1.id)
                const user2 = this.rootStore.users.getUserById(p2.id)
                if (!user1 || !user2) return 0
                const fullName1 = user1.fullName.toLowerCase()
                const fullName2 = user2.fullName.toLowerCase()
                return fullName1.localeCompare(fullName2)
            })
        )

        return byRoom
    }

    get participantsOnFloor() {
        return this.participantsByRoom[''] ?? []
    }

    get participants(): Participant[] {
        return Array.from(this.participantById.values())
    }

    get nearByParticipants(): Participant[] {
        const localUserId = this.rootStore.users.localUserId
        return this.participantsInSameAVConnectedSpace
            .filter((p) => p.id !== localUserId && p.spatial.proximal)
            .sort((a, b) => a.spatial.distSq - b.spatial.distSq)
    }

    get nearByParticipantsUsingSameApp() {
        const localParticipantSelectedAppId =
            this.localParticipant?.selectedAppId
        return this.nearByParticipants.filter((p) => {
            return p.selectedAppId === localParticipantSelectedAppId
        })
    }

    get participantLocationIds(): string[] {
        return this.participants.map((p) => p.locationId)
    }

    get participantsSelected() {
        return this.participants.filter((p) => p.isSelected)
    }

    get allProximalParticipantsHaveStableConnection(): boolean {
        if (!this.nearByParticipants.length) return false
        for (const p of this.nearByParticipants) {
            if (!p.hasHighNetworkQuality) return false
        }
        return true
    }

    get highlightedParticipant(): Participant | undefined {
        if (!this.highlightedParticipantId) {
            return
        }
        return this.participantById.get(this.highlightedParticipantId)
    }

    setHighlightedParticipant(id: string, value: boolean) {
        if (value) {
            this.highlightedParticipantId = id
        } else if (this.highlightedParticipantId == id) {
            this.highlightedParticipantId = undefined
        }
    }
}

/**
 * Create a new participant with a subset of properties
 *
 * Mostly helpful for writing tests
 */
export function createParticipant(
    partial: Partial<Participant>,
    partialMedia?: Partial<AVMedia>,
    rootStore?: RootStore
) {
    const participant = new Participant(
        {
            ...BLANK_PARTICIPANT,
            ...partial,
        },
        rootStore
    )

    if (partialMedia) {
        participant.updateMedia({
            avId: '',
            local: false,
            media: {
                videoIsOn: false,
                audioIsOn: false,
                videoState: 'loading',
                audioState: 'loading',
                screenVideoStates: [],
                screenVideoTracks: [],
                screenAudioState: 'off',
                ...partialMedia,
            },
        })
    }
    if (partial.spaceComponents) {
        participant.updateComponents(partial.spaceComponents)
    }
    if (partial.isInViewport) {
        participant.update({ isInViewport: partial.isInViewport })
    }

    return participant
}
