import compareVersions from 'compare-versions'
import { reaction, runInAction, when } from 'mobx'

import { Platform } from '@teamflow/bootstrap'
import {
    createEventRemover,
    ELECTRON_DEFAULT_TITLE_BAR_COLOR,
    ELECTRON_DEFAULT_TITLE_BAR_SYMBOL_COLOR,
    generateShortUuid,
    getSession,
    LogManager,
} from '@teamflow/lib'
import { IEventRemover } from '@teamflow/lib/src/events/eventRemover'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'
import {
    AVState,
    ElectronMainMessage,
    ElectronRendererMessage,
    FlowType,
    ICursorMovedAction,
    IDeepLinkAction,
    IElectronService,
    IOpenOverlayAction,
    IScreenConstraints,
    IScreenShareControlResult,
    ITrayMediaAction,
    SettingsMenu,
    UserFrontendModel,
} from '@teamflow/types'
import { IElectronBridge } from '@teamflow/types/src/electron/bridge'

import * as analytics from '../../helpers/analytics'
import { DESKTOP_OPEN_SETTINGS } from '../../helpers/analytics'
import updateAvailability from '../../helpers/updateAvailability'

import '../../logging' // Make sure logging is init.
import type Verse from '../Verse'

import ServiceLike from './ServiceLike'

export enum ControlType {
    ScreenShare = 'screen-share',
}

export interface TitleBarColorOverride {
    color: string
    symbolColor: string
}

export interface IElectronControl {
    awake(): void

    start(...args: any[]): Promise<any>
}

enum Events {
    TitleBarColorChange = 'title-bar-color-change',
}

const logger = LogManager.createLogger('ElectronService')

// This will identify which Teamflow client is being viewed in the DevTools console.
logger.info('NEXT_PUBLIC_APP_URL', process.env.NEXT_PUBLIC_APP_URL)

/* eslint-enable no-console */

/** @group Services */
class ElectronServiceImpl
    extends ServiceLike<Verse>
    implements IElectronService
{
    private bridge?: IElectronBridge
    private controls = new Map<string, IElectronControl>()
    private mediaReady = false
    private _protocol =
        process.env.NEXT_PUBLIC_APP_ENV === 'production'
            ? 'teamflow'
            : `teamflow-${process.env.NEXT_PUBLIC_APP_ENV}`

    public onDeepLink?: (action: IDeepLinkAction) => void

    /**
     * Custom color overrides for title bar buttons.
     * Currently only in Windows app.
     */
    private titleBarColorOverrides = new Map<
        string,
        Partial<TitleBarColorOverride>
    >()

    get available() {
        return this.bridge !== undefined
    }

    /**
     * Protocol used to redirect requests from browser to the desktop app
     * Usage examples:
     * - Produccion: teamflow://http://app.teamflowhq.com/${orgSlug}?${idToken}
     * - Local: teamflow-local://http://localhost:3000/${orgSlug}?${idToken}
     */
    get protocol() {
        return this._protocol
    }

    /* Traffic buttons on top left */
    get hasEmbeddedTopBarMac() {
        return this.available && Platform.isMacOS()
    }

    /* Title bar overlay buttons on top right */
    get hasEmbeddedTopBarWindows() {
        // The check below makes sure we don't do anything special if
        // the Electron app is still on a version without the embedded top bar.
        // The `windowControlsOverlay` api is only available when
        // the BrowserWindow constructor is given the `titleBarOverlay`
        // option.
        const hasTitleBarOverlay = 'windowControlsOverlay' in navigator

        return this.available && Platform.isWindows() && hasTitleBarOverlay
    }

    get hasEmbeddedTopBar() {
        return this.hasEmbeddedTopBarMac || this.hasEmbeddedTopBarWindows
    }

    constructor() {
        super()

        if (typeof window !== 'undefined') {
            // this is only available when run in electron
            this.bridge = window.electron
        }

        // Track actions taken in the tray
        this.onMediaAction((action: ITrayMediaAction) => {
            logger.info({
                action: 'Electron@MediaAction',
                value: action,
            })
            switch (action.media) {
                case 'mic':
                    void rootStore.audioVideo.toggleMicEnabled(
                        action.value ?? !rootStore.audioVideo.micEnabled
                    )
                    break
                case 'camera':
                    void rootStore.audioVideo.toggleCameraEnabled(
                        action.value ?? !rootStore.audioVideo.cameraEnabled
                    )
                    break
            }
            analytics.track(analytics.TRAY_ACTION, {
                kind: 'media',
                media: action.media,
            })
        })

        this.onOpenSettings((_action: ITrayMediaAction) => {
            rootStore.layout.setSettingsMenu(SettingsMenu.Profile)
        })

        this.bridge
            ?.on(ElectronMainMessage.Track, (type, data) => {
                analytics.track(type, { ...data })
            })
            .addTo(this.managedSubscriptions)

        this.bridge
            ?.on(
                ElectronMainMessage.LogMessage,
                (
                    level: 'warn' | 'error' | 'info',
                    msg: string,
                    metadata?: Record<string, unknown>
                ) => {
                    if (level in LogManager.global) {
                        LogManager.global[level](msg, metadata)
                    } else {
                        LogManager.global.warn(
                            'ElectronService: Unknown log level when processing LogMessage',
                            level
                        )
                        LogManager.global.info(msg, metadata)
                    }
                }
            )
            .addTo(this.managedSubscriptions)

        this.bridge?.on(ElectronMainMessage.Suspend, () => {
            LogManager.global.warn('ElectronService: System has suspended!')
            rootStore.commons.sleep()
        })
        this.bridge?.on(ElectronMainMessage.Resume, () => {
            LogManager.global.warn('ElectronService: System has resumed!')
            rootStore.commons.resume()
        })

        this.bridge?.on(ElectronMainMessage.CPUHigh, (cpuAverage) => {
            runInAction(() => {
                rootStore.performance.isCPUHigh = true
                rootStore.performance.cpuAverage = cpuAverage
            })
        })

        this.bridge?.on(ElectronMainMessage.CPUNormal, (cpuAverage) => {
            runInAction(() => {
                rootStore.performance.isCPUHigh = false
                rootStore.performance.cpuAverage = cpuAverage
            })
        })

        this.bridge?.on(
            ElectronMainMessage.SystemMemoryUsageHigh,
            (systemMemoryUsageAverage) => {
                runInAction(() => {
                    rootStore.performance.isSystemMemoryUsageHigh = true
                    rootStore.performance.systemMemoryUsageAverage =
                        systemMemoryUsageAverage
                })
            }
        )

        this.bridge?.on(
            ElectronMainMessage.SystemMemoryUsageNormal,
            (systemMemoryUsageAverage) => {
                runInAction(() => {
                    rootStore.performance.isSystemMemoryUsageHigh = false
                    rootStore.performance.systemMemoryUsageAverage =
                        systemMemoryUsageAverage
                })
            }
        )

        this.bridge?.on(
            ElectronMainMessage.SetSystemInformation,
            (systemInfo) => {
                // for now we are only logging it
                LogManager.global.info(
                    'ElectronService: Received System Information',
                    systemInfo
                )
            }
        )

        this.bridge?.emit(ElectronRendererMessage.LinkSession, getSession())
        this.bridge?.on(ElectronMainMessage.LinkSession, (session: string) => {
            logger.info({
                action: 'Electron@Bind',
                electronSessionId: session,
            })
        })

        this.bridge?.on(
            ElectronMainMessage.HandleDeepLinking,
            (action: IDeepLinkAction) => {
                this.onDeepLink?.(action)
            }
        )

        reaction(
            () => rootStore.audioVideo.state,
            (state) => {
                switch (state) {
                    case AVState.Joining:
                    case AVState.Reconnecting:
                    case AVState.Connected:
                        this.bridge?.emit(ElectronRendererMessage.GoingOnCall)
                        break
                    default:
                        this.bridge?.emit(ElectronRendererMessage.GoingOffCall)
                }
            }
        )
    }

    on(message: ElectronMainMessage, handler: (...args: any[]) => void) {
        if (!this.bridge) {
            throw new Error('missing bridge in ElectronService.on')
        }
        return this.bridge.on(message, handler)
    }

    /**
     * Setups tray media over the electron bridge. Waits until realtime & video-call are
     * ready and then calls {@link onMediaReady} with the initial state.
     */
    tray(verse: Verse) {
        let realtimeReady = false
        let audioVideoReady = false

        // Attempt to initialize tray media once both realtime and video-call
        // services have fully loaded.
        const initiate = () => {
            if (realtimeReady && audioVideoReady) {
                this.onMediaReady({
                    audioIsOn: rootStore.audioVideo.micEnabled,
                    videoIsOn: rootStore.audioVideo.cameraEnabled,
                })

                createEventRemover(
                    reaction(
                        () => rootStore.audioVideo.micEnabled,
                        (audioIsOn) => this.onMediaStateChanged({ audioIsOn })
                    )
                ).addTo(this.managedSubscriptions)

                createEventRemover(
                    reaction(
                        () => rootStore.audioVideo.cameraEnabled,
                        (videoIsOn) => this.onMediaStateChanged({ videoIsOn })
                    )
                ).addTo(this.managedSubscriptions)

                createEventRemover(
                    reaction(
                        () => rootStore.audioVideo.state,
                        (state) => {
                            if (state === t.AVState.Disconnected) {
                                this.onMediaClosed()
                            }

                            if (state === t.AVState.Connected) {
                                this.onMediaReady({
                                    audioIsOn: rootStore.audioVideo.micEnabled,
                                    videoIsOn:
                                        rootStore.audioVideo.cameraEnabled,
                                })
                            }
                        }
                    )
                ).addTo(this.managedSubscriptions)
            }
        }

        // Flag realtimeReady once received initial state
        if (!verse.hasReceivedInitialState) {
            verse.once(t.RTEvents.InitialState, () => {
                realtimeReady = true
                initiate()
            })
        } else {
            realtimeReady = true
        }

        // Flag audioVideoReady once joined
        if (rootStore.audioVideo.state === t.AVState.Connected) {
            audioVideoReady = true
        } else {
            const disposeConnectedReaction = when(
                () => rootStore.audioVideo.state === t.AVState.Connected,
                () => {
                    disposeConnectedReaction()
                    audioVideoReady = true
                    initiate()
                }
            )
        }

        initiate()

        this.bridge
            ?.on(ElectronMainMessage.ToggleUserAvailability, () => {
                const isAvailable =
                    rootStore.participants?.localParticipant?.availability ===
                    t.AvailabilityStatus.AVAILABLE

                const newAvailability = isAvailable
                    ? t.AvailabilityStatus.BUSY
                    : t.AvailabilityStatus.AVAILABLE

                updateAvailability(verse, newAvailability)
            })
            .addTo(this.managedSubscriptions)

        this.bridge
            ?.on(ElectronMainMessage.SetUserToOffCall, async () => {
                await verse.leave({ withAV: true })
                rootStore.settings.setManuallyDisconnected(true)
            })
            .addTo(this.managedSubscriptions)
    }

    addControl(type: ControlType, control: IElectronControl) {
        this.controls.set(type, control)
    }

    getControl(type: ControlType) {
        return this.controls.get(type)
    }

    removeControl(type: ControlType) {
        this.controls.delete(type)
    }

    async atLeastVersion(version: string) {
        if (
            process.env.NEXT_PUBLIC_APP_ENV === 'local' ||
            process.env.NEXT_PUBLIC_APP_ENV === 'staging'
        )
            return true

        const currentVersion = await this.getAppVersion()
        if (version === '0.0.0') {
            return false
        }

        return compareVersions(currentVersion, version) >= 0
    }

    /**
     * This will return the electron version in local dev
     * and the version from package.json from a built binary
     */
    async getAppVersion() {
        if (!this.bridge) {
            return '0.0.0'
        }

        const res = await this.asyncMessage<string>(
            ElectronRendererMessage.AppVersion,
            ElectronMainMessage.AppVersionResponse
        )
        return res
    }

    async waitForGoogleSSO(
        redirect = '',
        flow = FlowType.Normal,
        options?: any
    ) {
        if (!this.bridge) {
            throw new Error('missing bridge in waitForGoogleSSO')
        }

        this.bridge.emit(ElectronRendererMessage.WaitGoogleSSO, {
            redirect,
            flow,
            ...options,
        })

        return new Promise<{
            idToken: string
            accessToken?: string
            domain?: string
        }>((resolve) => {
            this.bridge?.once(ElectronMainMessage.FinishGoogleSSO, (data) => {
                resolve(data)
            })
        })
    }

    async waitForMicrosoftSSO(
        redirect = '',
        accountId = '',
        flow = FlowType.Permission
    ) {
        if (!this.bridge) {
            throw new Error('missing bridge in waitForMicrosoftSSO')
        }

        this.bridge.emit(ElectronRendererMessage.WaitMicrosoftSSO, {
            redirect,
            accountId,
            flow,
        })

        return new Promise<void>((resolve) => {
            this.bridge?.once(ElectronMainMessage.FinishMicrosoftSSO, () => {
                resolve()
            })
        })
    }

    cancelGoogleSSO() {
        if (!this.bridge) {
            throw new Error('missing bridge in cancelGoogleSSO')
        }

        this.bridge.emit(ElectronRendererMessage.CancelGoogleSSO)
    }

    onMediaReady(initialState: t.ITrayMediaState) {
        this.bridge?.emit(ElectronRendererMessage.MediaReady, initialState)
        this.mediaReady = true
    }

    onMediaStateChanged(state: Partial<t.ITrayMediaState>) {
        if (this.mediaReady)
            this.bridge?.emit(ElectronRendererMessage.MediaState, state)
    }

    onMediaClosed() {
        this.bridge?.emit(ElectronRendererMessage.MediaClosed)
        this.mediaReady = false
    }

    onMediaAction(cb: t.Consumer<t.ITrayMediaAction>) {
        return this.bridge?.on(ElectronMainMessage.MediaAction, cb)
    }

    onOpenSettings(cb: t.Consumer<t.ITrayMediaAction>) {
        analytics.track(DESKTOP_OPEN_SETTINGS)
        return this.bridge?.on(ElectronMainMessage.OpenSettings, cb)
    }

    newChatMessage(badgeCount: string) {
        if (!this.bridge) {
            throw new Error('missing bridge in newChatMessage')
        }

        this.bridge.emit(ElectronRendererMessage.ChatMessage, badgeCount)
    }

    async requestScreenShareStream(constraints: IScreenConstraints) {
        if (!this.bridge) {
            throw new Error('missing bridge in requestScreenShareStream')
        }

        await this.requestScreenCapturePermissions()

        const control = this.controls.get(ControlType.ScreenShare)
        if (!control) {
            throw new Error('missing control in requestScreenShareStream')
        }

        control.awake()

        const sources = await this.bridge.getScreenSources()

        logger.info({
            action: 'ElectronService@RequestScreenShareStream',
            message: 'Requesting screen share streams for sources',
            sources,
        })

        const result: IScreenShareControlResult[] =
            (await control.start(sources, constraints)) ?? []

        logger.info({
            action: 'ElectronService@RequestScreenShareStream',
            message: 'Got the following screen shares controls for sources',
            screenShareControlResult: result,
        })

        // user cancelled
        if (!result.length) {
            return null
        }

        this.openOverlayWindow(
            result
                .map((r) => {
                    if (!r?.source?.source) return null

                    return {
                        id: r.source.source.id,
                        name: r.source.source.name,
                        displayId: r.source.source.display_id,
                    }
                })
                .filter((r): r is t.IOpenOverlayAction[number] => !!r)
        )

        return result.map((result) => result.stream)
    }

    async requestCameraPermissions() {
        const res = await this.asyncMessage(
            ElectronRendererMessage.HasCameraPermission,
            ElectronMainMessage.CameraPermission
        )

        switch (res) {
            case 'granted':
                return true

            case 'unknown':
            case 'restricted':
            case 'denied':
                return false
        }

        if (res !== 'not-determined') {
            return false
        }

        // assumes not-determined state
        return this.asyncMessage(
            ElectronRendererMessage.RequestCameraPermissions,
            ElectronMainMessage.CameraPermissionsResponse
        )
    }

    async requestMicrophonePermissions() {
        const res = await this.asyncMessage<string>(
            ElectronRendererMessage.HasMicrophonePermission,
            ElectronMainMessage.MicrophonePermission
        )

        switch (res) {
            case 'granted':
                return true

            case 'unknown':
            case 'restricted':
            case 'denied':
                return false
        }

        if (res !== 'not-determined') {
            return false
        }

        return this.asyncMessage(
            ElectronRendererMessage.RequestMicrophonePermissions,
            ElectronMainMessage.MicrophonePermissionsResponse
        )
    }

    async requestScreenCapturePermissions() {
        return await this.asyncMessage<string>(
            ElectronRendererMessage.RequestScreenCapturePermission,
            ElectronMainMessage.ScreenCapturePermissionsResponse
        )
    }

    // listen for external window closed to handle logins to apps that auth via a popup
    onExternalWindowClosed(cb: (url: string) => void): IEventRemover | null {
        if (this.bridge) {
            return this.bridge.on(ElectronMainMessage.ExternalWindowClosed, cb)
        }
        return null
    }

    cursorMoved(cursorMovedAction: ICursorMovedAction) {
        if (!this.bridge) {
            throw new Error('missing bridge in ElectronService.cursorMoved')
        }
        this.bridge.emit(ElectronRendererMessage.CursorMoved, cursorMovedAction)
    }

    updateReaction(reactions: t.IEphemeralReaction[]) {
        if (!this.bridge) {
            throw new Error('missing bridge in ElectronService.addReactions')
        }
        this.bridge.emit(
            ElectronRendererMessage.UpdateEphemeralReaction,
            reactions
        )
    }

    openOverlayWindow(openOverlayAction: IOpenOverlayAction) {
        if (!this.bridge) {
            throw new Error(
                'missing bridge in ElectronService.openOverlayWindow'
            )
        }

        // for backwards compatibility with older versions of desktop app before multi screen share. Just take the first screen share
        this.bridge.emit(
            ElectronRendererMessage.DEPRECATED_OpenOverlayWindow,
            openOverlayAction[0]
        )

        this.bridge.emit(
            ElectronRendererMessage.OpenOverlayWindow,
            openOverlayAction
        )
    }

    closeOverlayWindow() {
        if (!this.bridge) {
            throw new Error(
                'missing bridge in ElectronService.closeOverlayWindow'
            )
        }
        this.bridge.emit(ElectronRendererMessage.CloseOverlayWindow)
    }

    toggleScreenshareCursorsOnBanner(cursorsEnabled: boolean) {
        if (!this.bridge) {
            throw new Error(
                'missing bridge in ElectronService.toggleScreenshareCursors'
            )
        }
        this.bridge.emit(
            ElectronRendererMessage.ToggleScreenshareCursorsFromApp,
            cursorsEnabled
        )
    }

    identifyAuthedUser(user: UserFrontendModel) {
        if (!this.bridge) {
            throw new Error(
                'missing bridge in ElectronService.identifyAuthedUser'
            )
        }
        this.bridge.emit(ElectronRendererMessage.IdentifyAuthedUser, user)
    }

    setUserAvailability(availability: t.AvailabilityStatus) {
        if (!this.bridge) {
            throw new Error(
                'missing bridge in ElectronService.setUserAvailability'
            )
        }

        this.bridge.emit(
            ElectronRendererMessage.SetUserAvailability,
            availability
        )
    }

    logout() {
        if (!this.bridge) {
            throw new Error('missing bridge in ElectronService.logout')
        }
        this.bridge.emit(ElectronRendererMessage.Logout)
    }

    downloadFile(url: string) {
        if (!this.bridge) {
            throw new Error('missing bridge in ElectronService.downloadFile')
        }
        this.bridge.emit(ElectronRendererMessage.Download, url)
    }

    // helper to emit a ElectronRendererMessage to electron and then wait for an ElectronMainMessage
    // response from electron main process
    private asyncMessage<ResultType>(
        reqMessage: ElectronRendererMessage,
        resMessage: ElectronMainMessage
    ) {
        const slogger = logger.child({
            sequence: generateShortUuid(),
        })

        slogger.info({
            action: 'Electrone@SendMessage',
            rendererMessage: ElectronRendererMessage[reqMessage],
            requestMessage: ElectronMainMessage[resMessage],
        })
        this.bridge?.emit(reqMessage)

        return new Promise<ResultType>((resolve) => {
            this.bridge?.once(resMessage, (res) => {
                slogger.info({
                    action: 'Electron@RecvMessage',
                    data: res,
                })
                resolve(res)
            })
        })
    }

    getPreloadAppScriptPath() {
        return this.bridge?.getPreloadAppScriptPath?.()
    }

    addTitleBarColorOverride(
        id: string,
        colorOverride: Partial<TitleBarColorOverride>
    ) {
        this.titleBarColorOverrides.set(id, colorOverride)
        this.updateTitleBarColor()
    }

    removeTitleBarColorOverride(id: string) {
        this.titleBarColorOverrides.delete(id)
        this.updateTitleBarColor()
    }

    resetTitleBarColorOverride() {
        this.titleBarColorOverrides.clear()
        this.updateTitleBarColor()
    }

    private updateTitleBarColor() {
        // only Windows needs custom title bar color
        if (!this.hasEmbeddedTopBarWindows) return

        const colorOverrides = Array.from(this.titleBarColorOverrides.values())

        // use most recent color override (or default color if none)
        const colors = colorOverrides
            .map((c) => c.color)
            .filter((c): c is string => !!c)
        const symbolColors = colorOverrides
            .map((c) => c.symbolColor)
            .filter((c): c is string => !!c)

        const color =
            colors.length > 0
                ? colors[colors.length - 1]
                : ELECTRON_DEFAULT_TITLE_BAR_COLOR
        const symbolColor =
            symbolColors.length > 0
                ? symbolColors[symbolColors.length - 1]
                : ELECTRON_DEFAULT_TITLE_BAR_SYMBOL_COLOR

        this.bridge?.emit(ElectronRendererMessage.SetTitleBarColor, {
            color,
            symbolColor,
        })

        this.events.emit(Events.TitleBarColorChange, {
            color,
            symbolColor,
        })
    }

    onTitleBarColorChange(cb: (color: TitleBarColorOverride) => void) {
        this.events.on(Events.TitleBarColorChange, cb)
    }

    offTitleBarColorChange(cb: (color: TitleBarColorOverride) => void) {
        this.events.off(Events.TitleBarColorChange, cb)
    }

    openMainWindowMenu(x: number, y: number) {
        this.bridge?.emit(ElectronRendererMessage.OpenMainWindowMenu, { x, y })
    }
}

export const sharedInstance = new ElectronServiceImpl()

export { sharedInstance as ElectronService }
