import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export default class ParticipantUpdateMouseCommand
    implements t.ICommand<t.IParticipantUpdateMouse>
{
    readonly messageType = t.ClientMessage.ParticipantUpdateMouse
    constructor(
        private participantId: string,
        private x: number,
        private y: number
    ) {}

    execute() {
        return {
            participantId: this.participantId,
            x: this.x,
            y: this.y,
        }
    }

    localExecute(_state: t.IVerseState) {
        rootStore.participants.findParticipantById(this.participantId)?.update({
            mouseX: this.x,
            mouseY: this.y,
        })
    }
}
