export default {
    weHadAProblemChargingThisCardPleaseTryAnotherCard:
        'weHadAProblemChargingThisCardPleaseTryAnotherCard',
    failedToCreateASubscription: 'failedToCreateASubscription',
    aCompleteAddressIsRequired: 'aCompleteAddressIsRequired',
    noPaymentMethodWasCreated: 'noPaymentMethodWasCreated',
    failedToCreateACustomer: 'failedToCreateACustomer',
    somethingWentWrongPleaseTryAgainLater:
        'somethingWentWrongPleaseTryAgainLater',
    upgradeFailedPleaseContactSupportTeamflowhqCom:
        'upgradeFailedPleaseContactSupportTeamflowhqCom',
    upgradeCompleteHoldTight: 'upgradeCompleteHoldTight',
    letsFinishYourUpgrade: 'letsFinishYourUpgrade',
    enterYourCreditCardDetails: 'enterYourCreditCardDetails',
    payYearlySave25: 'payYearlySave25',
    priceMonthlyPerSeat: 'priceMonthlyPerSeat',
    seats: 'seats',
    priceSelectedTermInTotal: 'priceSelectedTermInTotal',
    youSavedAmountWithCodeCoupon: 'youSavedAmountWithCodeCoupon',
    upgradeToSeedInstead: 'upgradeToSeedInstead',
    upgradeToBusinessInstead: 'upgradeToBusinessInstead',
    moreDetails: 'moreDetails',
    upgradeOrgNameToPlan: 'upgradeOrgNameToPlan',
    upgradeToBusiness: 'upgradeToBusiness',
    awesomeJustEmailSupportEmailAndWellUpgradeYourPlan:
        'awesomeJustEmailSupportEmailAndWellUpgradeYourPlan',
    requestUpgrade: 'requestUpgrade',
    illDoThisLater: 'illDoThisLater',
    removeMembers: 'removeMembers',
    howToRemoveMembers: 'howToRemoveMembers',
    billingDetails: 'billingDetails',
}
