import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

export default class ParticipantFocusModeCommand
    implements t.ICommand<t.IParticipantFocusMode>
{
    readonly messageType = t.ClientMessage.ParticipantFocusModeChanged

    private participantId: string
    private focusModeActive: boolean

    constructor(participantId: string, focusModeActive: boolean) {
        this.participantId = participantId
        this.focusModeActive = focusModeActive
    }

    execute() {
        return {
            participantId: this.participantId,
            focusModeActive: this.focusModeActive,
        }
    }

    localExecute(state: t.IVerseState, userId: string) {
        // update colyseus client
        const locationId = state.participantsGlobal.find(
            (p) => p.id === userId
        )?.locationId
        if (locationId) {
            const person = state.roomSubstates
                .get(locationId)
                ?.participantsMapRoomSpecific.get(userId)
            if (person) {
                person.focusModeActive = this.focusModeActive
            }
        }
        // update mobx store
        const participant = rootStore.participants.findParticipantById(userId)
        if (participant) {
            participant.update({
                focusModeActive: this.focusModeActive,
            })
        }
        if (this.focusModeActive) {
            void rootStore.audioVideo.toggleMicEnabled(false)
            void rootStore.audioVideo.toggleCameraEnabled(false)
        }
    }
}
