import { makeAutoObservable } from 'mobx'

import { IRole } from '@teamflow/types'

export class RoleStore {
    adminRole?: IRole
    memberRole?: IRole
    guestRole?: IRole

    constructor() {
        makeAutoObservable(this, {})
    }

    reset() {
        this.adminRole = undefined
        this.memberRole = undefined
        this.guestRole = undefined
    }

    setOrgRoles(
        newOrgRoles: Pick<RoleStore, 'adminRole' | 'memberRole' | 'guestRole'>
    ) {
        Object.assign(this, newOrgRoles)
    }

    get adminRoleNonNullAsserted() {
        return this.adminRole!
    }

    get memberRoleNonNullAsserted() {
        return this.memberRole!
    }

    get guestRoleNonNullAsserted() {
        return this.guestRole!
    }
}
