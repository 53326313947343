import { AVATAR_SIZE } from '@teamflow/lib'

import { Participant } from './Participant'

const unit = 512

export class ParticipantSpatialHash {
    buckets: Map<string, Set<Participant>> = new Map()

    /**
     * Adds the participant to the spatial hash
     *
     * @param participant
     */
    add(participant: Participant) {
        const x = Math.floor(participant.x / unit)
        const y = Math.floor(participant.y / unit)
        this.addToBucket(`${x}-${y}`, participant)
    }

    /**
     * Removes the participant from the spatial hash
     *
     * @param participant
     */
    remove(participant: Participant) {
        // Brute force remove from all buckets to ensure
        // there are no lingering entities
        for (const id of this.buckets.keys()) {
            this.removeFromBucket(id, participant)
        }
    }

    /**
     * Refreshes the hashing for the participant
     *
     * @param participant
     */
    update(participant: Participant) {
        this.remove(participant)
        this.add(participant)
    }

    /**
     * Finds participants within the rect specified
     *
     * @param x
     * @param y
     * @param width
     * @param height
     */

    findParticipants(
        x: number,
        y: number,
        width: number,
        height: number,
        locationId: string,
        excludeId: string | null = null
    ): Participant[] {
        const padding = AVATAR_SIZE / 2
        const left = x - padding
        const top = y - padding
        const right = x + width + padding
        const bottom = y + height + padding

        const lowX = Math.floor(left / unit)
        const lowY = Math.floor(top / unit)
        const highX = Math.floor(right / unit)
        const highY = Math.floor(bottom / unit)

        const participants = []
        for (let x = lowX; x <= highX; x++) {
            for (let y = lowY; y <= highY; y++) {
                const id = `${x}-${y}`
                const bucket = this.buckets.get(id)
                if (bucket) {
                    for (const participant of bucket) {
                        if (
                            participant.id !== excludeId &&
                            participant.locationId === locationId &&
                            participant.x < right &&
                            participant.x > left &&
                            participant.y < bottom &&
                            participant.y > top &&
                            participant.ready
                        ) {
                            participants.push(participant)
                        }
                    }
                }
            }
        }
        return participants
    }

    /**
     * Clears all buckets. Used to reset in unit tests
     */
    clear() {
        this.buckets.clear()
    }

    /**
     * Adds the participant to the given bucket in the hash
     *
     * @param id
     * @param participant
     */
    private addToBucket(id: string, participant: Participant) {
        let bucket = this.buckets.get(id)

        if (!bucket) {
            bucket = new Set()
            this.buckets.set(id, bucket)
        }

        bucket.add(participant)
    }

    /**
     * Removes the participant from the given bucket in the hash
     *
     * @param id
     * @param participant
     */
    private removeFromBucket(id: string, participant: Participant) {
        const bucket = this.buckets.get(id)

        if (bucket) {
            bucket.delete(participant)

            if (bucket.size === 0) {
                this.buckets.delete(id)
            }
        }
    }
}
