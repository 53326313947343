import * as Sentry from '@sentry/node'
import { useEffect } from 'react'

import { LogManager } from '@teamflow/lib'

type DependencyList = ReadonlyArray<unknown>

type EffectDestructor = () => void

type RejectFn = (error: unknown) => void

type WrapFn = <T extends any[]>(
    callback: (...args: T) => any
) => (...args: T) => void

type EffectCallback = ({
    reject,
    wrap,
}: {
    reject: RejectFn
    wrap: WrapFn
}) => void | EffectDestructor

/**
 * Like React's useEffect hook, but provides error handling for errors thrown
 * both synchronously during the effect and in async calls.
 *
 * Call the `reject` parameter on error, or do `.catch(reject)` on promises.
 * The `reject` provided in this hook is similar to the `reject` provided in a
 * promise callback.
 *
 * Alternatively, wrap deferred/async functions in `wrap` to catch errors in
 * those functions. Note that the resulting function takes the same arguments
 * as the original, but always returns void. Returning void is required to
 * make the return value of the resulting function predictable (If the wrapped
 * function errors, nothing can be returned).
 */
export function useFailsafeEffect(
    effect: EffectCallback,
    deps?: DependencyList
) {
    useEffect(
        () => {
            function logError(error: unknown) {
                LogManager.global.error('useFailsafeEffect error', error)
                Sentry.captureException(error, {
                    tags: {
                        failsafe_effect_error: true,
                    },
                })
            }

            try {
                return effect({
                    reject: logError,
                    wrap: (callback) => {
                        return (...args) => {
                            async function runWithCatch() {
                                try {
                                    await callback(...args)
                                } catch (error) {
                                    logError(error)
                                }
                            }

                            void runWithCatch()
                        }
                    },
                })
            } catch (error) {
                logError(error)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    )
}
