import {
    ApiErrorCode,
    BackgroundPattern,
    ILogger,
    InviteType,
    RoomAccessOptions,
    RoomPatchBody,
} from '@teamflow/types'

import { request, retry } from '../request'

const get = ({ slug }: { slug: string }) =>
    request<'GET /api/room/:id'>('GET', `/api/room/${slug}`)

const getByCode = ({ code }: { code: string }) =>
    request<'GET /api/room/code/:code'>('GET', `/api/room/code/${code}`)

const update = ({
    slug,
    update,
    apiKey,
    retryLimit = 0,
}: {
    slug: string
    update: RoomPatchBody
    apiKey?: string
    retryLimit?: number
}) => {
    const req = request<'PATCH /api/room/:id'>('PATCH', `/api/room/${slug}`, {
        headers: {
            'x-api-key': apiKey,
        },
        body: update,
    })
    if (retryLimit > 0) {
        return retry(() => req, {
            times: retryLimit,
            handleAndValidateResponse: (res) => !!res.data,
        })
    } else {
        return req
    }
}

const remove = ({ roomId, apiKey }: { roomId: string; apiKey: string }) =>
    request<'DELETE api/room/:id/remove'>(
        'DELETE',
        `/api/room/${roomId}/remove`,
        {
            headers: {
                'x-api-key': apiKey,
            },
        }
    )

const lock = ({ roomSessionId }: { roomSessionId: string }) =>
    request<'POST /api/room/:id/lock'>(
        'POST',
        `/api/room/${roomSessionId}/lock`
    )

const unlock = ({
    roomSessionId,
    apiKey,
}: {
    roomSessionId: string
    apiKey?: string
}) =>
    request<'POST /api/room/:id/unlock'>(
        'POST',
        `/api/room/${roomSessionId}/unlock`,
        {
            headers: {
                'x-api-key': apiKey,
            },
        }
    )

const addUser = ({
    roomSessionId,
    orgId,
    userOrgId,
    apiKey,
    logger,
}: {
    roomSessionId: string
    orgId: string
    userOrgId: string
    apiKey: string
    logger?: ILogger
}) =>
    retry(
        () =>
            request<'POST /api/room/:id/addUser'>(
                'POST',
                `/api/room/${roomSessionId}/addUser`,
                {
                    body: {
                        orgId,
                        userOrgId,
                    },
                    headers: {
                        'x-api-key': apiKey,
                    },
                }
            ),
        {
            onRetry: (retryCount) => {
                logger?.info(
                    `RETRY: ${retryCount}: room/${roomSessionId}/addUser`
                )
            },
            handleAndValidateResponse: (res) => {
                if (res.data) return true
                switch (res.error.code) {
                    // expected error codes
                    case ApiErrorCode.InvalidData:
                    case ApiErrorCode.UnknownOrganization:
                    case ApiErrorCode.Unauthorized:
                    case ApiErrorCode.KnockingRequired:
                        return true
                    default:
                        return false
                }
            },
        }
    )

const removeUser = ({
    roomSessionId,
    userOrgId,
    apiKey,
    logger,
}: {
    roomSessionId: string
    userOrgId: string
    apiKey: string
    logger?: ILogger
}) =>
    retry(
        () =>
            request<'PATCH /api/room/:id/removeUser'>(
                'PATCH',
                `/api/room/${roomSessionId}/removeUser`,
                {
                    body: {
                        userOrgId,
                    },
                    headers: {
                        'x-api-key': apiKey,
                    },
                }
            ),
        {
            onRetry: (retryCount) => {
                logger?.info(
                    `RETRY: ${retryCount}: room/${roomSessionId}/removeUser`
                )
            },
            handleAndValidateResponse: (res) => {
                if (res.data) return true
                switch (res.error.code) {
                    // expected error codes
                    case ApiErrorCode.UnknownRoom:
                    case ApiErrorCode.UnknownUser:
                        return true
                    default:
                        return false
                }
            },
        }
    )

const updateBackground = ({
    roomSlugName,
    backgroundImage,
    backgroundPattern,
}: {
    roomSlugName: string
    backgroundImage?: string | null
    backgroundPattern?: BackgroundPattern
}) =>
    request<'PATCH /api/room/updateRoomBackground/:id'>(
        'PATCH',
        `/api/room/updateRoomBackground/${roomSlugName}`,
        {
            body: {
                backgroundImage,
                backgroundPattern,
            },
        }
    )

const waitlist = {
    add: ({
        roomSessionId,
        orgId,
        userOrgId,
    }: {
        roomSessionId: string
        orgId?: string
        userOrgId?: string
    }) =>
        request<'POST /api/room/:id/waitlist'>(
            'POST',
            `/api/room/${roomSessionId}/waitlist`,
            {
                body: {
                    orgId,
                    userOrgId,
                },
            }
        ),
    accept: ({
        roomSessionId,
        userOrgId,
    }: {
        roomSessionId: string
        userOrgId: string
    }) =>
        request<'PUT /api/room/:id/acceptWaitlistUser/:userOrgId'>(
            'PUT',
            `/api/room/${roomSessionId}/acceptWaitlistUser/${userOrgId}`
        ),
    remove: ({
        roomSessionId,
        userOrgId,
    }: {
        roomSessionId: string
        userOrgId: string
    }) =>
        request<'PUT /api/room/:id/removeWaitlistUser/:userOrgId'>(
            'PUT',
            `/api/room/${roomSessionId}/removeWaitlistUser/${userOrgId}`
        ),
}

const permissions = {
    get: ({ slug }: { slug: string }) =>
        request<'GET api/room/:id/permissions'>(
            'GET',
            `/api/room/${slug}/permissions`
        ),
    delete: ({ roomId }: { roomId: string }) =>
        retry(
            () =>
                request<'DELETE api/room/:id/removePermission'>(
                    'DELETE',
                    `/api/room/${roomId}/removePermission`
                ),
            {
                handleAndValidateResponse: (res) => {
                    if (res.data) return true
                    switch (res.error.code) {
                        // expected error codes
                        case ApiErrorCode.UnknownUser:
                        case ApiErrorCode.UnknownRoom:
                            return true
                        default:
                            return false
                    }
                },
            }
        ),
    insertInvited: ({
        roomId,
        invitedIds,
    }: {
        roomId: string
        invitedIds: string[]
    }) =>
        request<'POST /api/room/:id/insertInvited'>(
            'POST',
            `/api/room/${roomId}/insertInvited`,
            {
                body: {
                    invitedIds,
                },
            }
        ),
    removeInvited: ({
        roomId,
        invitedIds,
    }: {
        roomId: string
        invitedIds: string[]
    }) =>
        request<'PATCH /api/room/:id/removeInvited'>(
            'PATCH',
            `/api/room/${roomId}/removeInvited`,
            {
                body: {
                    invitedIds,
                },
            }
        ),
    transferOwner: ({
        roomId,
        newOwner,
    }: {
        roomId: string
        newOwner: string
    }) =>
        request<'POST /api/room/:id/transferOwner'>(
            'POST',
            `/api/room/${roomId}/transferOwner`,
            {
                body: {
                    newOwner,
                },
            }
        ),
    changeWhoCanJoin: ({
        roomId,
        whoCanJoin,
    }: {
        roomId: string
        whoCanJoin: RoomAccessOptions
    }) =>
        request<'PATCH /api/room/:id/changeWhoCanJoin'>(
            'PATCH',
            `/api/room/${roomId}/changeWhoCanJoin`,
            {
                body: {
                    whoCanJoin,
                },
            }
        ),
}

const invitations = {
    get: ({ roomId }: { roomId: string }) =>
        request<'GET /api/room/:id/invitations'>(
            'GET',
            `/api/room/${roomId}/invitations`
        ),
    update: ({
        roomId,
        inviteCode,
        inviteType,
    }: {
        roomId: string
        inviteCode: string
        inviteType: InviteType
    }) =>
        request<'PUT /api/room/:id/invitation/:inviteCode'>(
            'PUT',
            `/api/room/${roomId}/invitation/${inviteCode}`,
            {
                body: {
                    inviteType,
                },
            }
        ),
    delete: ({ roomId, inviteCode }: { roomId: string; inviteCode: string }) =>
        request<'DELETE /api/room/:id/invitation/:inviteCode'>(
            'DELETE',
            `/api/room/${roomId}/invitation/${inviteCode}`
        ),
}

const unlockBatch = ({
    operations,
    apiKey,
}: {
    operations: { id: string }[]
    apiKey?: string
}) =>
    request<'POST /api/room/unlockBatch'>('POST', '/api/room/unlockBatch', {
        headers: {
            'x-api-key': apiKey,
        },
        body: {
            operations,
        },
    })

const clearBatch = ({
    operations,
    apiKey,
}: {
    operations: { id: string }[]
    apiKey?: string
}) =>
    request<'POST /api/room/clearBatch'>('POST', '/api/room/clearBatch', {
        headers: {
            'x-api-key': apiKey,
        },
        body: {
            operations,
        },
    })

export default {
    get,
    getByCode,
    update,
    remove,
    lock,
    unlock,
    addUser,
    removeUser,
    updateBackground,
    waitlist,
    permissions,
    invitations,
    unlockBatch,
    clearBatch,
}
