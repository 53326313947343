import * as t from '@teamflow/types'

export default class RoomSessionAddInvitedIdsCommand
    implements t.ICommand<t.IRoomSessionAddInvitedIdsAction>
{
    readonly messageType = t.ClientMessage.RoomSessionAddInvitedIds

    constructor(
        private readonly roomSessionId: string,
        private readonly invitedIds: string[]
    ) {}

    execute() {
        return {
            roomSessionId: this.roomSessionId,
            invitedIds: this.invitedIds,
        }
    }
}
