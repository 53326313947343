import { createRequest } from '../request'

const execute = createRequest<'POST /api/sso'>('POST', '/api/sso')
const listDomains = createRequest<'GET /api/sso/domains'>(
    'GET',
    '/api/sso/domains'
)
const listUrls = createRequest<'GET /api/sso/urls'>('GET', '/api/sso/urls')

export default {
    execute,
    listDomains,
    listUrls,
}
