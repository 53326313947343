import * as t from '@teamflow/types'

export default class AudioZoneDeleteCommand
    implements
        t.ICommand<{ id: string; locationId: string; relocate?: boolean }>
{
    readonly messageType = t.ClientMessage.AudioZoneDelete

    constructor(
        private id: string,
        private locationId: string,
        private relocate?: boolean
    ) {}

    execute() {
        return {
            id: this.id,
            locationId: this.locationId,
            relocate: this.relocate,
        }
    }
}
