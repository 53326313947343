export default {
    pleaseFillOutTheEntireForm: 'pleaseFillOutTheEntireForm',
    incorrectEmailOrPassword: 'incorrectEmailOrPassword',
    loading: 'loading',
    welcomeBack: 'welcomeBack',
    logInWithGoogle: 'logInWithGoogle',
    workEmail: 'workEmail',
    pleaseEnterAValidEmail: 'pleaseEnterAValidEmail',
    password: 'password',
    forgotPassword: 'forgotPassword',
    minAmountCharacters: 'minAmountCharacters',
    passwordsMustBeAtLeastAmountCharacters:
        'passwordsMustBeAtLeastAmountCharacters',
    logInWithEmailAndPassword: 'logInWithEmailAndPassword',
    logInWithSso: 'logInWithSso',
    googleSsoUnavailableWhileEmbeddedInAnotherApp:
        'googleSsoUnavailableWhileEmbeddedInAnotherApp',
}
