import * as t from '@teamflow/types'

export default class FloorKnockResponseCommand
    implements t.ICommand<{ id: string }>
{
    readonly messageType = t.ClientMessage.FloorKnockResponse

    private id: string

    constructor(id: string) {
        this.id = id
    }

    execute() {
        return {
            id: this.id,
        }
    }
}
