import "@teamflow/server/../design/dist/tokens/colors.css.js.vanilla.css!=!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"@teamflow/server/../design/dist/tokens/colors.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA42V204CMRCG732K3phgAmR7bvFhzHIQUFhhwQMa393pblu2nZpwY4L/x8z/T9th+qT3x4vidE1+7giZTPznakbObd2cDnW7as6PQ4nOyLbZrNpt+m82I5vTrh5VY1Ldjwmtur8PCcM9wxhQQjuAYkp01LBQqsu0k1W4hMoQjhGdIsZgxHiEWgEA6EpjyEZIjYnTBcNQnTbjhdTzFGGFVIsUEZhYxgFT0FxqUaizChQ1oDnPtmDoOVIaNFfGFDquIwXxmfjnPDaRgknyrozF1PamWi+BksxbUoXjfQ2UgI5WulqFc9lFCjJaNwkjMbWPHcGXcaOXBV9NpKCWdgMVham+xY7Kj90WOh4iZb0lXaCON2Vs43PSth9Wqp9S3eb6+aqb/qGk+nvUpYsj8+9/RL27i/A8Uv3zequDQbjfU5ZSX30VBjfW6FKMiwdg+KaY4zurgILQ6kp0B4yiUOoJ68OiMDSswaHRQhzar8IRVSbsSq7yWmLAyM6yzhE5RJxnGGGGKNwp36hUIwaeX8bEbegg4VIZVoxm/ZAq5t+BRFOqc8QiS/McMcjRYoCo7m6hA1vmCJc5Erfh0G8pV1iIXE+Vu2b9oHT4Tfj9A/c6sglMBwAA\"}!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
import "@teamflow/server/../design/dist/tokens/tokens.css.js.vanilla.css!=!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"@teamflow/server/../design/dist/tokens/tokens.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW207bQBB971esipBAipHH6ztPUBqpX1E5ziY2OHZiOxeo+u+ddQiws+MUCEToePbMmd05nk3bpunFn29COM7veP0UbJWbCvf2IwCpgPXBgLxUeASSqfAJ5KciJFCQiphAIdK7BIsQowlixChdgjro2gwxqmSWCkn5chRM184Ro/pUKgKad4GV0RzLVEQ0R4Hl0hxlKhLK96jrpYmf6EFUqXDoSawQo1lrxKi6BjGadY0YTbrROajkVoM0S6dBStlrOXT5VoNU0A5B60z2WjldftAg1fms66GcLwhaZwXY0I51MgC6eJoKsLEd63RADvVTCeBbTglsq0BoewUi2ywQ226BxLYLZIw3ABscLMaccQxgi4PFqRgfwYIxEiwZJ0HBWAlKxkvwyJgJnhg3QcXYCVaMnwDbPbYSNYzLYM3ZDDZ6myzWVldvoZ0u30Kx6yEKKLrVm2rF7ljevUYtZdj5vrUxz/p96V6aIHb+ouydvKl7Vffmuxr7P9v2jQli+9dNrUwQu98dPrjxYpe1V/goWm2eQ1ldm5FyNLInkf5o5AuJDEYjISCh4XjojISi1XC3td20lUS7nGVX7kS4l69/N0AWxMcF6OOB/W3B8ffGjUh88h4vmXi4npznC69vTcLsSKiDA37B5K0kbPFhJjBpTdJZaq4ZkfKqdfjSyR2+JJM7Tz+s0l/OWd3e8BWMxVH2+VeUD7TBoAG4QI92kvriaS+Y0wZ/Ijw5ETIZPXAzRJ+5wbp8P3LfqNFaNzG6eSyQ7mExtocj/MPewRDqfy5DecwQn4SF/8lwbAL/XAaPpHj8ZBHxKYWO9GOzF8xYzyc5rDuXVzG335U90r3aHuleY490b22PdG/DjHSvZaa311n6ekbfltG3Y/TtGX0HRt8zp++F0Sdd5iYgcdaUdaHa0hxMEsdNQAaZxMHy/RcOsfb7RHRZ3TkdrluYMb5OYm6DDDQXwXBKhBTDcRBRLNa3CoIlTI6M4Zsxa/ORevFNVqkFAfEFlCtdsAnje6YtlwUJxvfEolIHp+uzljwqeJ7ydYWq5+YDdNMs61RVkkuARAt0fav6vDBxNEK3znLlzFS/V6o2n6IldmVXzirChrYoyvmchjfMnUSiNbScrHWWbTYvsZorkMFcLSfiInQfkul0GNkXgYwf8H99CzLtKzdnGKbTn1HiHhmm02QahRxDe5YhdO9+nBgCGUiOobMZ3OP66CGMTzXcR3f3fA39uV0Yfo4M/lx/Tgx//wH4SwB/yg8AAA==\"}!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
import "@teamflow/server/../design/dist/components/Modal/Modal.css.js.vanilla.css!=!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"@teamflow/server/../design/dist/components/Modal/Modal.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W25KiMBB9n6/Ii1VYNXEDijrMy/zJViCNxAmEDaA4W/PvGxIvAdRxp+ZBLumT0336Enx7h0OqaA4V2m7zWHwQ9PcJITIxN4RkSRNeHyJEXvX7p/75ZGz0rfHz6W1I59+jQ6hWtKhSqfLIPgpag4dDMnlGZIqqhArwZi/L6ZfOH6PypzcDDb4daHf9uVhdtku4Mxvk3PDFNHnfKNkUDCdSSBWhTDN4RAvVBItO8Gxutpay4jWXRYRS3gLrlnhRQW1knVkXD7NacR2SKVnilIsaNDAWjfIWZTt1SENDWlLGeLGJ0I4qD+Pf6/I9bIC6wOUXwLMN17Ic2Ocu0crKkIqBsli/bFElBWenXav8z2E5X7ms90JbG8acqg0vjp3wcJxugl8MD+NVKahuglRA2+G7O2ZcQWJrpNPe5MXr1Vr0BCxsGaxSRRlvqkFAwcFA5A5UKuQetxGiTS17azqSjDMG1qNscZVRJvem7l5AligIJmg1Qb/QPNQtSXQy/e4yX+sL7h6fx9CAuNCAGGhYtje6cVxSMIELSHWT6lEwoHuT3QH2nNVZhF7Ibv9qCtbi45JPgoV13i1mwDeZ5l2Hu8wtT5TKpKlMkWRTC15AhApZgIOh1nrMXaTHSEuhwkHEBmEEfR23mXJnc3LZTC76ia1MXcv8+KKsAOKo1sfMFdnHtZPibiEbpuG86ARqZY+aiwx7ZJAeduMI6bVtYop1q6HPXNCfOoRtCc/29BFf/RE56j0dB47p2t6VG83mf2d33GhuqSYOdXbneCHDA6b/3guRX+tcN0PDso7Kt/0uwygz+suaA+MUeaWCFFSFFbAmAYZzaTNVSGxNUCQwNY57XzeEaMFzasGn/yR+SPIKJU3MExzDBwflkZm/fEa++djp+/mz2ztzr5D5j5J1ZRMCJxktNhBdhmTgKL7lKPhhR8kPpOfzH59xjJjwCQAA\"}!../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var blurBackground = 'jjmblz4';
export var body = 'jjmblz8';
export var close = 'jjmblzj';
export var content = 'jjmblz9';
export var dark = 'jjmblzd';
export var description = 'jjmblzh';
export var footerNoDivider = 'jjmblz6';
export var footerWithDivider = 'jjmblz7';
export var fullScreen = 'jjmblzc';
export var header = 'jjmblz5';
export var modalToolbar = 'jjmblze';
export var nonScrollableContent = 'jjmblza';
export var overlay = 'jjmblz3';
export var sideBySideBar = 'jjmblzf';
export var sideBySideContent = 'jjmblzg';
export var trigger = 'jjmblzi';
export var verticallyCenteredContent = 'jjmblzb';