import { createRequest } from '../request'

export const create = createRequest<'POST /api/marketing'>(
    'POST',
    '/api/marketing'
)

export default {
    create,
}
