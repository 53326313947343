import debounce from 'lodash/debounce'

import { api } from '@teamflow/client-api'

export default debounce(
    function updateUserOrgPosition(
        x: number,
        y: number,
        roomId: string | null
    ) {
        return api.userOrg.update({
            x,
            y,
            currentRoomId: roomId || null,
        })
    },
    500,
    {
        maxWait: 1000,
    }
)
