export default {
    aCompleteAddressIsRequired: 'aCompleteAddressIsRequired',
    noPaymentMethodWasCreated: 'noPaymentMethodWasCreated',
    somethingWentWrongPleaseTryAgainLater:
        'somethingWentWrongPleaseTryAgainLater',
    updatePaymentMethod: 'updatePaymentMethod',
    useThisFormToUpdateThePaymentMethodForOrgName:
        'useThisFormToUpdateThePaymentMethodForOrgName',
    update: 'update',
}
