import * as types from '@teamflow/types'

export class ParticipantFaceCenteringChangedCommand
    implements types.ICommand<types.IParticipantFaceCenteringChanged>
{
    readonly messageType = types.ClientMessage.ParticipantFaceCenteringChanged

    constructor(
        private scale: number,
        private offsetX: number,
        private offsetY: number
    ) {}

    execute() {
        return {
            scale: this.scale,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
        }
    }
}
