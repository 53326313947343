const HUDDLE_HEADER_HEIGHT = 104

export default function Content({
    centerVertical = false,
    children,
}: {
    centerVertical?: boolean
    children: React.ReactNode
}): JSX.Element {
    return (
        <div
            style={{
                boxSizing: 'border-box',
                width: '100%',
                maxWidth: '488px',
                margin: '0 auto',
                padding: 20,
                marginTop: 50,
                ...(centerVertical && {
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: -HUDDLE_HEADER_HEIGHT,
                }),
            }}
        >
            {children}
        </div>
    )
}
