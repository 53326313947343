import { makeAutoObservable } from 'mobx'

import {
    IFrontendOrganization,
    BETA_IRoom,
    IRoomSession,
    RoomSessionFrontendModel,
    Product,
} from '@teamflow/types'

const APP_URL = process.env.NEXT_PUBLIC_APP_URL

export type RoomSessions = (IRoomSession & RoomSessionFrontendModel)[]

export class OrganizationStore {
    data: IFrontendOrganization | undefined
    rooms: BETA_IRoom[] | undefined
    constructor() {
        makeAutoObservable(this)
    }

    reset() {
        this.data = undefined
        this.rooms = undefined
    }

    update(changes: Partial<IFrontendOrganization>) {
        this.data = {
            ...this.data,
            ...changes,
        } as IFrontendOrganization
    }

    setRooms(rooms: BETA_IRoom[]) {
        this.rooms = rooms
    }

    get teamJoinUrl() {
        if (!this.data) return ''
        const { slug, inviteCode } = this.data
        return `${APP_URL}/join/${slug}/${inviteCode}`
    }

    get guestJoinUrl() {
        if (!this.data) return ''
        const { slug, floorInviteCode } = this.data
        return `${APP_URL}/${slug}-${floorInviteCode}`
    }

    get isVirtualOffice() {
        return (
            (this.data?.product ?? Product.VirtualOffice) ===
            Product.VirtualOffice
        )
    }

    get isSalesFloor() {
        return this.data?.product === Product.SalesFloor
    }
}
