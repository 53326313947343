import { makeAutoObservable, observable } from 'mobx'

import { lerp, AVATAR_SIZE } from '@teamflow/lib'
import generateShortUuid from '@teamflow/lib/src/uuid'
import rootStore from '@teamflow/store'
import { IEphemeralReaction } from '@teamflow/types'

import {
    EPHEMERAL_REACTION_LIFESPAN_MILLIS,
    EPHEMERAL_REACTION_COOLDOWN,
    EPHEMERAL_REACTION_SIZE_RANGE,
    EPHEMERAL_REACTION_RADIUS_FACTOR,
} from '../../constants'

import { UIStore } from '../uiStore'

const REACTION_RADIUS = AVATAR_SIZE * EPHEMERAL_REACTION_RADIUS_FACTOR

// Map Emoji's to their animation
const emojiAnimClasses: Record<string, string> = {
    '🎉': 'ephemeral-reaction-animation-confetti',
}
function getEmojiAnimClass(emoji: string): string {
    return emojiAnimClasses[emoji] ?? 'ephemeral-reaction-animation'
}

export const CSS_CLASS_FLIP = 'ephemeral-reaction-flip-horizontally'

// '👍' and '👎' are rendered differently than they look like in code
const emojiToFlipIfRight = new Set(['👎', '👋', '👏'])
const emojiToFlipIfLeft = new Set(['👍'])

export class EphemeralReactions {
    readonly uiStore: UIStore
    // associate a user with an array of reactions
    ephemeralReactionsByUserId = observable<string, IEphemeralReaction[]>(
        new Map()
    )

    // clean up stale ephemeral reactions
    ephemeralReactionsByUserIdCleanup = observable<string, number>(new Map())

    // Map for the time of the last reaction received by the user
    lastReactionByUserId = observable<string, number>(new Map())

    // For instantaneous UI feedback
    localEphemeralReactions: IEphemeralReaction[] = []

    // clean up stale local ephemeral reaction
    localEphemeralReactionsCleanup: number | undefined = undefined

    // Time of last local reaction
    lastReactionLocal: number

    constructor(uiStore: UIStore) {
        this.uiStore = uiStore
        this.lastReactionLocal = Date.now() // Initialize the reaction to the time right now
        makeAutoObservable(this, { uiStore: false })
    }

    generateReactionGeometry() {
        /**
         * Randomly gets an angle that we will place the reaction around
         * angle = 0:               X right  Y center
         * angle = Math.PI * 0.5:   X center Y top
         * angle = Math.PI:         X left   Y center
         * angle = Math.PI * 1.5:   X center Y bottom
         * angle = Math.PI * 2:     X right  Y center
         */
        const angle = Math.random() * Math.PI * 2
        return [
            Math.cos(angle) * REACTION_RADIUS + 20, // X coordinate of the reaction (+20 is the offset to get to the center of the avatar)
            Math.sin(angle) * REACTION_RADIUS - 5, // Y coordinate of the reaction (-5 is the offset to get to the center of the avatar)
            angle,
        ] as const
    }

    addEphemeralReaction(userId: string, emoji: string) {
        /**
         * Cleanup code to make sure we don't have extra DOM nodes hanging around
         */
        if (
            !(
                Date.now() - (this.lastReactionByUserId.get(userId) || 0) >
                EPHEMERAL_REACTION_COOLDOWN
            )
        ) {
            return
        }
        this.lastReactionByUserId.set(userId, Date.now())
        const cleanupId = this.ephemeralReactionsByUserIdCleanup.get(userId)
        if (cleanupId !== undefined) {
            window.clearTimeout(cleanupId)
        }
        const newCleanupId = window.setTimeout(() => {
            let reactions = this.ephemeralReactionsByUserId.get(userId) || []
            reactions = reactions.filter(
                (reaction) =>
                    Date.now() - reaction.added <
                    EPHEMERAL_REACTION_LIFESPAN_MILLIS
            )
            this.ephemeralReactionsByUserId.set(userId, reactions)
            this.ephemeralReactionsByUserIdCleanup.delete(userId)
        }, EPHEMERAL_REACTION_LIFESPAN_MILLIS + 50)
        this.ephemeralReactionsByUserIdCleanup.set(userId, newCleanupId)

        /**
         * End up cleanup code
         */

        const reactions = this.ephemeralReactionsByUserId.get(userId) || []
        const geometry = this.generateReactionGeometry()
        const angle = geometry[2]
        const isAtRightHalf = angle < Math.PI * 0.5 || angle > Math.PI * 1.5

        reactions.push({
            id: generateShortUuid(),
            participantId: userId,
            emoji,
            added: Date.now(),
            left: geometry[0],
            bottom: geometry[1],
            fontSize:
                lerp(...EPHEMERAL_REACTION_SIZE_RANGE, Math.random()) *
                AVATAR_SIZE,
            className: rootStore.settings.lite ? '' : getEmojiAnimClass(emoji),
            needsHorizontalFlip:
                (emojiToFlipIfLeft.has(emoji) && !isAtRightHalf) ||
                (emojiToFlipIfRight.has(emoji) && isAtRightHalf),
        })
        this.ephemeralReactionsByUserId.set(userId, reactions)
    }

    reactionsByUserId(userId: string) {
        return this.ephemeralReactionsByUserId.get(userId) || []
    }

    addLocalEphemeralReaction(localParticipantId: string, emoji: string) {
        /**
         * Cleanup code to make sure we don't have extra DOM nodes hanging around
         */
        if (
            !(Date.now() - this.lastReactionLocal > EPHEMERAL_REACTION_COOLDOWN)
        ) {
            return
        }
        this.lastReactionLocal = Date.now()
        const cleanupId = this.localEphemeralReactionsCleanup
        if (cleanupId !== undefined) {
            window.clearTimeout(cleanupId)
        }
        const newCleanupId = window.setTimeout(() => {
            this.localEphemeralReactions = this.localEphemeralReactions.filter(
                (reaction) =>
                    Date.now() - reaction.added <
                    EPHEMERAL_REACTION_LIFESPAN_MILLIS
            )
            this.localEphemeralReactionsCleanup = undefined
        }, EPHEMERAL_REACTION_LIFESPAN_MILLIS + 50)

        this.localEphemeralReactionsCleanup = newCleanupId

        /**
         * End up cleanup code
         */
        const geometry = this.generateReactionGeometry()
        const angle = geometry[2]
        const isAtRightHalf = angle < Math.PI * 0.5 || angle > Math.PI * 1.5

        this.localEphemeralReactions.push({
            participantId: localParticipantId,
            id: generateShortUuid(),
            emoji,
            added: Date.now(),
            left: geometry[0],
            bottom: geometry[1],
            fontSize:
                lerp(...EPHEMERAL_REACTION_SIZE_RANGE, Math.random()) *
                AVATAR_SIZE,
            className: rootStore.settings.lite ? '' : getEmojiAnimClass(emoji),
            needsHorizontalFlip:
                (emojiToFlipIfLeft.has(emoji) && !isAtRightHalf) ||
                (emojiToFlipIfRight.has(emoji) && isAtRightHalf),
        })
    }
}
