import { DataChange } from '@colyseus/schema'

import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

/**
 * Put or remove entity in hash based on its location-ID.
 *
 * @private
 */
export const putOrRemoveFromHash = <
    T extends { id: string; locationId: string }
>(
    item: T,
    putInHash: t.Consumer<T>
) => {
    if (item.locationId === rootStore.commons.viewingSpace) {
        putInHash(item)
    } else {
        rootStore.spatialHash.delete(item.id)
    }
}

export const putOrRemoveFromHashBasedOnSessionId = (
    item: t.IParticipant,
    putInHash: t.Consumer<t.IParticipant>
) => {
    const currentSpace = rootStore.commons.viewingSpace
    const currentSessionId =
        currentSpace !== ''
            ? rootStore.rooms.roomsById.get(currentSpace)?.sessionId
            : ''

    if (item.locationId === currentSessionId) {
        putInHash(item)
    } else {
        rootStore.spatialHash.delete(item.id)
    }
}

export const changesAccumulator = (
    changes: { [field: string]: DataChange<any> },
    moreChanges: DataChange<any>[]
) => {
    if (!changes) {
        changes = {}
    }
    moreChanges.forEach((change) => {
        changes[change.field] = change
    })
    return changes
}

export const changesTransformer = (changes: {
    [field: string]: DataChange<any>
}) => {
    return [Object.values(changes)]
}

// NOTE: throttle realtime onChange events so that we can batch
// them together to avoid multiple unnecessary renders close together
export const throttleOptions = { leading: false, trailing: true }
export const throttleWait = 100
