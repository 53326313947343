import * as t from '@teamflow/types'

export default class RoomSessionLockedCommand
    implements t.ICommand<t.IRoomSessionLocked>
{
    readonly messageType = t.ClientMessage.RoomSessionLocked

    private publicRoomSessionId: string
    private privateRoomSession: t.RoomSessionForRealTime

    constructor(
        publicRoomSessionId: string,
        privateRoomSession: t.RoomSessionForRealTime
    ) {
        this.publicRoomSessionId = publicRoomSessionId
        this.privateRoomSession = {
            _id: privateRoomSession._id,
            roomId: privateRoomSession.roomId,
            roomName: privateRoomSession.roomName,
            roomSlugName: privateRoomSession.roomSlugName,
            roomInviteCode: privateRoomSession.roomInviteCode,
            orgId: privateRoomSession.orgId.toString(),
            participantIds: privateRoomSession.participantIds,
            waitlistIds: privateRoomSession.waitlistIds,
            isActive: privateRoomSession.isActive,
            isKnockingEnabled: privateRoomSession.isKnockingEnabled,
            status: privateRoomSession.status,
            backgroundImage: privateRoomSession.backgroundImage,
            backgroundPattern: privateRoomSession.backgroundPattern,
            spatialAudio: privateRoomSession.spatialAudio,
            isPrivate: privateRoomSession.isActive,
            startInFullScreenMode: privateRoomSession.startInFullScreenMode,
            invitedIds: privateRoomSession.invitedIds,
            whoCanJoin: privateRoomSession.whoCanJoin,
            owner: privateRoomSession.owner,
            knockingAllowedUserIds: privateRoomSession.knockingAllowedUserIds,
            templateId: privateRoomSession.templateId,
            personalOffice: privateRoomSession.personalOffice,
            furnished: privateRoomSession.furnished,
            type: privateRoomSession.type,
        }
    }

    execute() {
        return {
            publicRoomSessionId: this.publicRoomSessionId,
            privateRoomSession: this.privateRoomSession,
        }
    }
}
