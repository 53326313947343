import { makeAutoObservable, observable } from 'mobx'

export class MarketingStore {
    utm: { [index: string]: string } = {}
    email: string | null = null
    extendTrial = false

    constructor() {
        makeAutoObservable(this, {
            utm: observable.ref,
        })
    }

    reset() {
        this.utm = {}
        this.email = null
        this.extendTrial = false
    }

    setUtmParameters(utm: { [index: string]: string }) {
        this.utm = utm
    }

    setEmail(email: string | null) {
        this.email = email
    }

    setExtendTrial(extendTrial: boolean) {
        this.extendTrial = extendTrial
    }
}
