import { makeAutoObservable } from 'mobx'

import { UIStore } from '../uiStore'

export class TopHeader {
    readonly uiStore: UIStore

    invitePulse = false
    meetPulse = false

    constructor(uiStore: UIStore) {
        this.uiStore = uiStore

        makeAutoObservable(this, { uiStore: false })
    }

    setInvitePulse(enabled: boolean) {
        this.invitePulse = enabled
    }

    setMeetPulse(enabled: boolean) {
        this.meetPulse = enabled
    }
}
