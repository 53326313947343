import { createRequest, saveAccessToken, saveRefreshToken } from '../request'

const access = createRequest<'POST /api/join/access'>(
    'POST',
    '/api/join/access'
)
const asNewGuest = createRequest<'PUT /api/join/guest'>(
    'PUT',
    '/api/join/guest',
    [saveAccessToken('idToken'), saveRefreshToken('refreshToken')]
)

export default {
    access,
    asNewGuest,
}
