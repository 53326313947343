import { useEffect } from 'react'

import type { IEventRemover } from '@teamflow/lib'

export function useEvent<D extends Array<any>, TString extends string>(
    fn: (...args: D) => void,
    event: TString | TString[],
    eventSource:
        | undefined
        | null
        | {
              addListener: (
                  ev: TString,
                  fn: (...args: D) => any
              ) => IEventRemover
          }
        | {
              addListener: (ev: TString, fn: (...args: any[]) => void) => void
              removeListener: (
                  ev: TString,
                  fn: (...args: any[]) => void
              ) => void
          }
        | EventTarget,
    additionalDeps?: Array<any>
) {
    useEffect(() => {
        function singleEffect(event: TString) {
            if (!eventSource) {
                return
            }

            if ('addEventListener' in eventSource) {
                eventSource.addEventListener(
                    event,
                    fn as (...args: any[]) => void
                )

                return () => {
                    eventSource.removeEventListener(
                        event,
                        fn as (...args: any[]) => void
                    )
                }
            }

            const ret = eventSource.addListener(
                event,
                fn as (...args: any[]) => void
            )

            if (ret && ret.remove) {
                return ret.remove
            } else {
                return () => {
                    ;(
                        eventSource as {
                            removeListener: Function
                        }
                    ).removeListener(event, fn)
                }
            }
        }

        const events = typeof event === 'string' ? [event] : event
        const disposers = events.map((e) => singleEffect(e))

        return () => {
            disposers.forEach((fn) => fn && fn())
        }
    }, [
        fn,
        eventSource,
        event,
        // NOTE: Instead of this array, the passed in function should be wrapped in
        // useCallback() with the required deps. Then this function can update based
        // on `fn` alone.
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...(additionalDeps ?? []),
    ])
}
