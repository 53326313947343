import { createRequest, request } from '../request'

const get = ({ uid }: { uid: string }) =>
    request<'GET /api/triggers/:uid'>('GET', `/api/triggers/${uid}`)

const list = createRequest<'GET /api/triggers'>('GET', '/api/triggers')
const create = createRequest<'POST /api/triggers'>('POST', '/api/triggers')
const del = ({ triggerId }: { triggerId: string }) =>
    request<'DELETE /api/triggers/:triggerId'>(
        'DELETE',
        `/api/triggers/${triggerId}`
    )

export default {
    get,
    list,
    create,
    delete: del,
}
