import * as Sentry from '@sentry/react'
import { ErrorCode as ColyseusErrorCode } from 'colyseus.js'
import EventEmitter from 'eventemitter3'
import throttle from 'lodash/throttle'
import { reaction, runInAction } from 'mobx'

import {
    castRoomSessionToRoom,
    REALTIME_CONNECTED,
    REALTIME_RECONNECTED,
    ThrottledEmitter,
    RS_SYNC_INTERVAL,
} from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'
import { PendingUpdateState, TriggerAction } from '@teamflow/types'

import { SubscribeToRoomSubstateCommand } from '../../commands/SubscribeToRoomSubstateCommand'
import { UnsubscribeToRoomSubstateCommand } from '../../commands/UnsubscribeToRoomSubstateCommand'
import Events from '../../events'
import type { ISharedState } from '../../sharedState'

import type { State, StateMachine } from '../types'

import {
    readRegistry,
    readSubstateRegistery,
    StateConnector,
    Substate,
    SubstateConnector,
} from './StateConnector'

import type { Room } from 'colyseus.js'

import './AppConnector'
import './AssetConnector'
import './AudioZoneConnector'
import './FurnitureConnector'
import './ParticipantGlobalConnector'
import './ParticipantRoomSpecificConnector'
import './PinConnector'
import './PortalConnector'
import './PresentationConnector'
import './RoomConnector'
import './SpaceConnector'
import './SpeakerCircleConnector'

type Data = {
    room: Room<t.IVerseState>
    isReconnect: boolean
}

// NOTE: throttle realtime onChange events so that we can batch
// them together to avoid multiple unnecessary renders close together
const throttleOptions = { leading: false, trailing: true }
const throttleWait = 100

let schemaVersion: number

export const SCHEMA_REFRESHED_CHECK_KEY = 'schema-version-mismatch-refreshed'

/**
 * {@link Connected} subscribes to Colyseus state and forwards server messages to events exposed in the realtime
 * service. It also publishes changes into the global application state (@teamflow/store).
 *
 * # rootStore.spatialHash
 *
 * The {@code rootStore.spatialHash} is managed exclusively by {@link Connected}. The spatial hash is
 * cleared and repopulated when switching spaces; it is also updated whenever individual entities are
 * moved.
 *
 * # Connectors
 *
 * All parts of multiplayer state are managed using a {@link StateConnector}. State connectors are also the
 * "publishers" for events on the {@link IRealtimeService}.
 *
 * | State Connector                           | Side Effects                                                                  |
 * |-------------------------------------------|-------------------------------------------------------------------------------|
 * | {@link AppConnector}                      | {@link AppStore}, {@link SpatialHashStore}, onAppOpened, onAppClosed          |
 * | {@link AssetConnector}                    | AssetAppended event                                                           |
 * | {@link AudioZoneConnector}                | {@link SpatialHashStore}, onAudioZone... events                               |
 * | {@link ParticipantGlobalConnector}        | {@link ParticipantStore}, {@link SpatialHashStore}, & onParticipant... events |
 * | {@link ParticipantRoomSpecificConnector}  | Same as above                                                                 |
 * | {@link FurnitureConnector}                | {@link SpatialHashStore}, onFurniture... events                               |
 * | {@link SpeakerCircleConnector}            | {@link SpatialHashStore}, onSpeakerCircle... events                           |
 * | {@link PortalConnector}                   | {@link SpatialHashStore}, onPortal... events                                  |
 * | {@link PinConnector}                      | No spatial hash. onPin... events                                              |
 * | {@link AdminOptionsConnector}             | {@link AdminOptionsStore}                                                     |
 * | {@link SpaceConnector}                    | Wall[Added:Changed:Removed:Updated] events. Tiles not managed as multiplayer. |
 * | {@link PresentationConnector}             | {@link PresentationStore}                                                     |
 * | {@link RoomConnector}                     | {@link RoomStore}                                                             |
 *
 * @group Connection States
 */
export class Connected implements State {
    private readonly realtime: t.IRealtimeService
    private readonly events: EventEmitter
    private readonly sharedState: ISharedState
    private readonly logger: t.ILogger
    private readonly flags: t.IFeatureFlagService
    private readonly analytics: t.IAnalytics

    private readonly throttledEmitter: ThrottledEmitter

    private readonly actionQueue = new Array<() => void>()
    private destroyed = false

    private connectors: { [K in keyof t.IVerseState]?: StateConnector<K> }
    private substateConnectors: {
        [K in keyof t.IVerseState]?: {
            [S in keyof Substate<K>]?: SubstateConnector<K, S>
        }
    }

    constructor(
        realtime: t.IRealtimeService,
        logger: t.ILogger,
        sharedState: ISharedState,
        events: EventEmitter,
        flags: t.IFeatureFlagService,
        analytics: t.IAnalytics
    ) {
        this.realtime = realtime
        this.logger = logger
        this.sharedState = sharedState
        this.events = events
        this.flags = flags
        this.analytics = analytics

        this.throttledEmitter = new ThrottledEmitter(
            this.events,
            RS_SYNC_INTERVAL
        )

        this.connectors = Object.fromEntries(
            Object.entries(readRegistry()).map(([key, Connector]) => [
                key,
                new Connector(
                    realtime,
                    this.sharedState,
                    this.events,
                    this.throttledEmitter,
                    this.actionQueue,
                    this.flags,
                    this.analytics
                ),
            ])
        )

        this.substateConnectors = Object.fromEntries(
            Object.entries(readSubstateRegistery()).map(([key, substates]) => [
                key,
                Object.fromEntries(
                    Object.entries(substates).map(
                        ([substateKey, Connector]) => [
                            substateKey,
                            new Connector(
                                realtime,
                                this.sharedState,
                                this.events,
                                this.throttledEmitter,
                                this.actionQueue,
                                this.flags,
                                this.analytics
                            ),
                        ]
                    )
                ),
            ])
        )

        reaction(
            () => rootStore.commons.viewingSpace,
            (viewingSpace, prevViewingSpace) => {
                if (this.destroyed) return

                const room = this.sharedState.activeRoom

                rootStore.spatialHash.clear()

                if (!room) return

                // mostly needed for preview room, we need to subscribe to that state
                // when switching viewing space. will be ignored if already subscribed
                this.realtime.dispatch(
                    new SubscribeToRoomSubstateCommand(viewingSpace)
                )

                // we also unsubscribe from previous space if that space is not the av
                // connected space to unsub from previewing space
                if (prevViewingSpace !== rootStore.commons.viewingSpace) {
                    this.realtime.dispatch(
                        new UnsubscribeToRoomSubstateCommand(prevViewingSpace)
                    )
                }

                for (const key in this.connectors) {
                    const _key = key as keyof Omit<
                        t.IVerseState,
                        'schemaVersion' | 'appCount'
                    >

                    this.connectors[_key]!.onSwitchSpace(
                        room.state[_key] as any
                    )
                }

                // We can make this handle other substates in the future if need be
                const substateConnectors =
                    this.substateConnectors['roomSubstates']
                if (substateConnectors) {
                    for (const [key, substateConnector] of Object.entries(
                        substateConnectors
                    )) {
                        const _key = key as keyof Substate<'roomSubstates'>
                        const substate =
                            room.state.roomSubstates.get(viewingSpace)?.[_key]

                        if (substate) {
                            substateConnector.onSwitchSpace(substate as any)
                        }
                    }
                }
            }
        )
    }

    async onEnter(_machine: StateMachine, data: Data) {
        this.destroyed = false
        // this.connection = ConnectionState.Connected
        const { room, isReconnect = false } = data
        this.analytics.track(
            isReconnect ? REALTIME_RECONNECTED : REALTIME_CONNECTED
        )

        room.onStateChange((state) => {
            if (
                state.appCount !== undefined &&
                state.appCount !== rootStore.app.globalAppCount
            ) {
                runInAction(() => {
                    rootStore.app.globalAppCount = state.appCount
                })
            }

            if (!schemaVersion && state.schemaVersion) {
                schemaVersion = state.schemaVersion
                return
            }

            if (state.schemaVersion !== schemaVersion) {
                // logger may not flush in time, use local storage to log once reload is done
                // see verse.tsx for effect that does actual log
                window.localStorage.setItem(SCHEMA_REFRESHED_CHECK_KEY, 'true')
                window.location.reload()
            }
        })

        this.replaceRoom(room)
    }

    onExit() {
        this.destroyed = true
        if (!this.sharedState.activeRoom) {
            return
        }

        this.sharedState.activeRoom.removeAllListeners()
        for (const key in this.connectors) {
            const _key = key as keyof Omit<
                t.IVerseState,
                'schemaVersion' | 'appCount'
            >

            this.connectors[_key]!.onDetach(
                this.sharedState.activeRoom.state[_key] as any
            )
        }
    }

    private replaceRoom(room: Room<t.IVerseState>) {
        if (this.sharedState.activeRoom) {
            // clear all attached listeners
            // this includes for onMessage, onStateChange, onLeave, onError
            this.sharedState.activeRoom.removeAllListeners()
        }

        this.sharedState.setActiveRoom(room)

        this.listenForStateChanges()
        this.listenForMessages()
    }

    private listenForStateChanges() {
        const room = this.sharedState.activeRoom
        if (!room) {
            return
        }

        room.onStateChange(
            throttle(
                (state) => {
                    while (this.actionQueue.length > 0) {
                        const action = this.actionQueue.shift() as () => void
                        action()
                    }

                    this.events.emit(Events.StateChanged, state)
                },
                throttleWait,
                throttleOptions
            )
        )

        room.state.listen('organizationTierState', (value) => {
            rootStore.tier.updateTierState(value)
        })

        room.state.listen('activeEvent', (value) => {
            this.events.emit(Events.OnActiveEventChanged, value)
        })

        room.state.listen('freeTrial', (value) => {
            rootStore.tier.updateFreeTrial({
                trial: value.active,
                trialDaysLeft: value.daysLeft,
                trialPeriod: value.period,
            })
        })

        room.state.listen('avProvider', (value) => {
            rootStore.audioVideo.updateProvider(value)
        })

        room.state.roomSubstates.onAdd = (roomSubstate) => {
            const substateConnectors = this.substateConnectors['roomSubstates']
            if (substateConnectors) {
                for (const [key, substateConnector] of Object.entries(
                    substateConnectors
                )) {
                    const _key = key as keyof Substate<'roomSubstates'>
                    substateConnector.onAttach(roomSubstate[_key] as any)
                }
            }
        }

        for (const key in this.connectors) {
            const _key = key as keyof Omit<
                t.IVerseState,
                'schemaVersion' | 'appCount'
            >

            this.connectors[_key]!.onAttach(room.state[_key] as any)
        }

        room.state.deployment.onChange = (changes) => {
            changes.forEach((change) => {
                const { field, value } = change

                switch (field) {
                    case 'state': {
                        rootStore.commons.updateDeploymentState(value)

                        if (value === t.DeploymentState.Starting) {
                            const data = room?.state.deployment?.data
                            this.events.emit(Events.OnDeployStarting, data)
                        } else if (value === t.DeploymentState.None) {
                            this.events.emit(Events.OnDeployCancelled)
                        }
                        break
                    }
                }
            })
        }
    }

    private listenForMessages() {
        const room = this.sharedState.activeRoom
        if (!room) {
            return
        }

        room.onError((code, message) => {
            const unsentBytes = (
                this.sharedState.activeRoom?.connection.transport as
                    | undefined
                    | { ws: WebSocket }
            )?.ws?.bufferedAmount

            const err = new Error(
                `Colyseus ${code ?? 'Unknown Code'} → ${
                    message ?? 'Unknown Message'
                }${unsentBytes ? ` | ${unsentBytes} bytes left behind!` : ''}`
            )

            this.logger.error(err)
            Sentry.captureException(err, {
                tags: {
                    is_colyseus_on_error: true,
                    // for a list of error codes, see: https://github.com/colyseus/colyseus.js/blob/b2dd971ff76746c3421307794cfadf2e1cc503a7/src/Protocol.ts#L15-L24
                    colyseus_error_code: code,
                    // this will be true if OnJoin throws in the realtime-server, see: https://github.com/colyseus/colyseus/blob/f696dcb132ddaa4e44b3d45711b54eb4da975706/packages/core/src/Room.ts#L356-L371
                    is_realtime_server_error:
                        code === ColyseusErrorCode.APPLICATION_ERROR,
                },
                // to make sure those errors are always going to be grouped in the same issue on Sentry
                fingerprint: ['conneted', 'room', 'on_error'],
            })
        })

        room.onMessage(
            t.ServerMessage.Acknowledge,
            (message: t.ClientMessage) => {
                this.events.emit(Events.OnAcknowledge, message)
            }
        )

        room.onMessage(
            t.ServerMessage.ParticipantJoined,
            (participant: t.IParticipant) => {
                rootStore.participants.put(participant)

                this.events.emit(Events.OnJoined, participant)
            }
        )

        room.onMessage(
            t.ServerMessage.ParticipantLeft,
            (participant: t.IParticipant) => {
                this.events.emit(Events.OnLeft, participant)
            }
        )

        room.onMessage(
            t.ServerMessage.InviteCodeChanged,
            (message: t.InviteCodeChanged) => {
                if (message.inviteCode) {
                    rootStore.organization.update({
                        inviteCode: message.inviteCode,
                    })
                }
                if (message.floorInviteCode) {
                    rootStore.organization.update({
                        floorInviteCode: message.floorInviteCode,
                    })
                }
            }
        )

        room.onMessage(
            t.ServerMessage.BackgroundImageChanged,
            (message: {
                backgroundImage?: string
                backgroundPattern?: t.BackgroundPattern
            }) => {
                // NOTE: always emit the message
                this.events.emit(Events.onBackgroundImageChanged, message)

                if (!message.backgroundImage) {
                    return
                }

                this.sharedState.setBackgroundImage(message.backgroundImage)
            }
        )

        room.onMessage(t.ServerMessage.AppClosed, (message) => {
            this.events.emit(Events.OnAppClosed, message)
        })

        room.onMessage(t.ServerMessage.PromoteGuest, (message) => {
            this.events.emit(Events.PromoteGuest, message)
        })

        room.onMessage(t.ServerMessage.ProfileUpdated, (message) => {
            rootStore.users.updateUserProfile(message)
        })

        room.onMessage(t.ServerMessage.GetUser, (message) => {
            this.events.emit(Events.GetUser, message)
        })

        room.onMessage(t.ServerMessage.InviteToRoom, (message) => {
            this.events.emit(Events.onInviteToRoom, message)
        })

        room.onMessage(t.ServerMessage.TapUser, (message) => {
            this.events.emit(Events.TapUser, message)
        })

        room.onMessage(t.ServerMessage.NotifyNearbyScreenShare, (message) => {
            this.events.emit(Events.NotifyNearbyScreenShare, message)
        })

        room.onMessage(t.ServerMessage.PresentationEnded, (message) => {
            this.events.emit(Events.PresentationEnded, message)
        })

        room.onMessage(t.ServerMessage.MeetLater, (message) => {
            this.events.emit(Events.MeetLater, message)
        })

        room.onMessage(t.ServerMessage.MuteUser, (message) => {
            this.events.emit(Events.MuteUser, message)
        })

        room.onMessage(
            t.ServerMessage.ParticipantEphemeralReactionBroadcast,
            (message) => {
                this.events.emit(Events.EphemeralReaction, message)
            }
        )

        room.onMessage(
            t.ServerMessage.RoomSessionLocked,
            ({ roomSession, ...rest }) => {
                const room = castRoomSessionToRoom(roomSession)
                this.events.emit(Events.OnRoomLocked, { room, ...rest })
            }
        )

        room.onMessage(
            t.ServerMessage.RoomSessionUnlocked,
            ({ roomSession, ...rest }) => {
                const room = castRoomSessionToRoom(roomSession)
                this.events.emit(Events.OnRoomUnlocked, {
                    room,
                    ...rest,
                })
            }
        )

        room.onMessage(
            t.ServerMessage.RoomKnock,
            ({ roomSessionId, ...rest }) => {
                // TODO(Ryan) remove once RT is refactored to not use roomSession
                const room = rootStore.rooms.roomsBySessionId.get(roomSessionId)
                if (!room) return
                this.events.emit(Events.OnRoomKnock, {
                    roomId: room.id,
                    ...rest,
                })
            }
        )

        room.onMessage(t.ServerMessage.FloorKnock, (message) => {
            this.events.emit(Events.OnFloorKnock, message)
        })

        room.onMessage(t.ServerMessage.RoomKnockResponse, (message) => {
            this.events.emit(Events.OnRoomKnockResponse, message)
        })

        room.onMessage(t.ServerMessage.FloorKnockResponse, (message) => {
            this.events.emit(Events.OnFloorKnockResponse, message)
        })

        room.onMessage(t.ServerMessage.MoveToFloor, () => {
            this.events.emit(Events.OnMoveToFloor)
        })

        room.onMessage(t.ServerMessage.ParticipantBlocked, (message) => {
            this.events.emit(Events.OnParticipantBlocked, message)
        })

        room.onMessage(t.ServerMessage.PortalDeleteDenied, (message) => {
            this.events.emit(Events.OnPortalDeleteDenied, message)
        })

        room.onMessage(t.ServerMessage.ParticipantWasMoved, (message) => {
            this.events.emit(Events.ParticipantWasMoved, message)
        })

        room.onMessage(t.ServerMessage.RoomDeleteDenied, (message) => {
            this.events.emit(Events.OnRoomDeleteDenied, message)
        })

        room.onMessage(
            t.ServerMessage.FlagChanged,
            (message: t.IFlagChanged<string | number | boolean>) => {
                if (!message) {
                    return
                }

                // this is a flag update from realtime to keep everyone in sync
                // flagsmith polls which may take a different time per
                // connected client; this just helps keep flags that need to be
                // synced, in sync
                this.flags.updateFlagValue(
                    message.flag,
                    message.enabled,
                    message.value ?? undefined
                )
            }
        )

        room.onMessage(
            t.ServerMessage.ParticipantDelete,
            (message: t.IParticipantDelete) => {
                this.events.emit(Events.OnParticipantDelete, message.id)
            }
        )

        room.onMessage(t.ServerMessage.Ping, () => {
            // respond with pong immediately for latency calc
            room?.send(t.ClientMessage.Pong)
        })

        room.onMessage(t.ServerMessage.GoToFloorError, (message) => {
            this.events.emit(Events.OnGoToFloorError, message)
        })

        room.onMessage(t.ServerMessage.GoToRoomError, (message) => {
            this.events.emit(Events.OnGoToRoomError, message)
        })

        room.onMessage(t.ServerMessage.RoomError, (message) => {
            this.events.emit(Events.OnRoomError, message)
        })

        room.onMessage(
            t.ServerMessage.InviteRemoved,
            (message: t.IInviteRemoved) => {
                this.events.emit(Events.OnInviteRemoved, message.email)
            }
        )

        room.onMessage(
            t.ServerMessage.YoutubeVideoChanged,
            (message: t.YouTubePayload) => {
                this.events.emit(Events.OnYoutubeVideoChanged, message)
            }
        )

        room.onMessage(
            t.ServerMessage.PermissionUpdated,
            (message: t.IBroadcastWithUser) => {
                this.events.emit(Events.OnPermissionUpdated, message)
            }
        )

        room.onMessage(t.ServerMessage.SpaceInvalidation, (msg) => {
            this.events.emit(Events.SpaceInvalidation, msg)
        })

        room.onMessage(t.ServerMessage.SpaceInvalidationAll, (msg) => {
            this.events.emit(Events.SpaceInvalidationAll, msg)
        })

        room.onMessage(
            t.ServerMessage.InvokeTriggerAction,
            (message: { name: TriggerAction; payload: unknown }) => {
                this.events.emit(Events.InvokeTriggerAction, message)
            }
        )

        /**
         * We do `toString()` as we only send this message manually from the RT
         * monitor, and it sends strings instead of numbers.
         *
         * Deprecated -- use AppUpdateOptional and AppUpdateCritical instead.
         */
        room.onMessage(
            t.ServerMessage.DEPRECATED_ForceReload.toString(),
            () => {
                const shouldReload = window.confirm(
                    'Please reload to get the latest version!'
                )
                if (shouldReload) {
                    window.location.reload()
                }
            }
        )

        /**
         * We do `toString()` as we only send this message manually from the RT
         * monitor, and it sends strings instead of numbers.
         */
        room.onMessage(t.ServerMessage.AppUpdateOptional.toString(), () => {
            if (
                rootStore.commons.pendingUpdateState ===
                PendingUpdateState.Critical
            ) {
                return
            }

            rootStore.commons.updatePendingUpdateState(
                PendingUpdateState.Optional
            )
        })

        /**
         * We do `toString()` as we only send this message manually from the RT
         * monitor, and it sends strings instead of numbers.
         */
        room.onMessage(t.ServerMessage.AppUpdateCritical.toString(), () => {
            rootStore.commons.updatePendingUpdateState(
                PendingUpdateState.Critical
            )
        })

        room.onMessage(t.ServerMessage.EventOnboardingFinished, () => {
            room.send(
                t.ClientMessage.Acknowledge,
                t.ServerMessage.EventOnboardingFinished
            )
            this.events.emit(Events.EventOnboardingFinished)
        })
    }
}
